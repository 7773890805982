import { useMutation, useQueryClient } from '@tanstack/react-query';
import { request } from '~/lib/axios';
import { useMemo } from 'react';
import { API } from '..';
import { defineMutation } from '../helper';
import { reqParamsToBody } from '../utils';
import type { Tags } from './types';

export const update = defineMutation(async (params: { id: string; name: string }) => {
  const data = await request.post<string>('/v1/tag/update', reqParamsToBody(params));
  return data;
});

export const useUpdateTagMutation = () => {
  const queryClient = useQueryClient();

  const tagListQueryKey = useMemo(() => API.tags.list().key, []);

  return useMutation(update, {
    async onMutate(newTag) {
      await queryClient.cancelQueries(tagListQueryKey);
      const prevTags = queryClient.getQueryData<Tags>(tagListQueryKey);

      queryClient.setQueryData<Tags>(tagListQueryKey, (old) => {
        if (!old) return;
        const nextTags = old.map((tag) => {
          if (tag.id === newTag.id) {
            return {
              ...tag,
              name: newTag.name,
            };
          }

          return tag;
        });

        return nextTags;
      });

      return {
        prevTags,
      };
    },
    onSettled() {
      queryClient.invalidateQueries(tagListQueryKey);
    },
    onError(_e, _, context) {
      const prevTags = context?.prevTags;
      prevTags && queryClient.setQueryData(tagListQueryKey, prevTags);
    },
  });
};
