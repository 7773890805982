import { reqParamsToBody } from '~/api/utils';
import { request } from '~/lib/axios';

export type SpeakerList = {
  id: string;
  userId: string;
  noteId: string;
  name: string;
  code: string;
  color: string;
}[];

export function speakerListReq(params: { noteId: string }) {
  return request.post<SpeakerList>('/v2/note/speaker/list', reqParamsToBody(params));
}
