import { request } from '~/lib/axios';
import { reqParamsToBody } from './utils';

export declare module Tag {
  type TagInfo = {
    id: string;
    userId: string;
    tag: string;
    noteCount: number;
  };
}

function getById(params: { noteId: string }) {
  return request.post('/v2/tag/list', reqParamsToBody(params));
}

function addByNoteId(params: { noteId: string; tag: string }) {
  return request.post('/v2/tag/add', reqParamsToBody(params));
}

function deleteByNoteId(params: { noteId: string; tag: string }) {
  return request.post('/v2/tag/remove', reqParamsToBody(params));
}

function getListByFoderId(params: { folderId: string }) {
  return request.post<Tag.TagInfo[]>('/v2/tag/cluster', reqParamsToBody(params));
}

function modifyNoteTag(params: { noteId: string; before: string; tag: string }) {
  return request.post('/v2/tag/update', reqParamsToBody(params));
}

function getListByNoteId(params: { noteId: string }) {
  return request.post<string[]>('/v2/note/tags', reqParamsToBody(params));
}

export const tagReq = {
  key: (fn: Function) => {
    return `tagReq-${fn.name}`;
  },
  getById,
  addByNoteId,
  deleteByNoteId,
  getListByFoderId,
  modifyNoteTag,
  getListByNoteId,
};
