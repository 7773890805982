import { useEffect, useState } from 'react';
import { ClassValue } from 'tailwind-variants';

type Props = {
  progress: number;
  radius: number;
  className?: string;
};

const CircleProgressBar = ({ progress: progressOut, radius, className }: Props) => {
  //
  let progress = Math.min(progressOut, 1);
  const strokeWith = 10;
  const perimeter = radius * 2 * Math.PI;

  return (
    // width={(strokeWith + radius) * 2} height={(strokeWith + radius)  * 2}
    <svg
      style={{ transform: 'rotate(-90deg)', transformOrigin: 'center center' }}
      width={(strokeWith + radius) * 2}
      height={(strokeWith + radius) * 2}
      className={className || 'k'}
    >
      <circle
        r={radius}
        cx={radius + strokeWith}
        cy={radius + strokeWith}
        fill="none"
        stroke="#ccc"
        strokeWidth={strokeWith}
      />
      {progress == -0.01 ? null : (
        <circle
          r={radius}
          cx={radius + strokeWith}
          cy={radius + strokeWith}
          fill="none"
          stroke="#00A2AF"
          strokeWidth={strokeWith}
          strokeDasharray={`${perimeter * progress},${perimeter * (1 - progress)}`}
          strokeLinecap="round"
        />
      )}
    </svg>
  );
};

export default CircleProgressBar;
