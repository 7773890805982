import { GoogleOAuthProvider } from '@react-oauth/google';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App';
import './index.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { DevTools } from 'jotai-devtools';
import { Toaster } from 'react-hot-toast';
import '/node_modules/flag-icons/css/flag-icons.min.css';
import { isMobile, isPC, Logger } from './utils/utils';

declare global {
  interface Window {
    Logger: typeof Logger;
    myGlobal: {
      historyLen: number;
    };
  }
}

window.Logger = Logger;

let historyLen = +(sessionStorage.getItem('historyLen') || history.length);
sessionStorage.setItem('historyLen', historyLen + '');
window.myGlobal = {
  historyLen: historyLen,
};

export const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

window.onerror = function (a: any, b: any, c: any) {
  Logger.error(a, b, c);

  return false;
};

ReactDOM.createRoot(document.getElementById('root')!).render(
  // <React.StrictMode>
  <>
    <GoogleOAuthProvider clientId={import.meta.env.VITE_OAUTH_GOOGLE_CLIENT_ID}>
      <QueryClientProvider client={queryClient}>
        <App />
        <Toaster
          toastOptions={{
            className: 'rounded-[20px] text-[13px] bg-clean text-black',
          }}
        />
        {/* <ReactQueryDevtools initialIsOpen={false} /> */}
      </QueryClientProvider>
    </GoogleOAuthProvider>
    <DevTools theme="light" />
  </>
  // </React.StrictMode>
);
