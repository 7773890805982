import { checkIsUpdateReq } from '~/api/checkIsUpdate';
import { SecondConfirmation } from '~/components/deleteConfrim';
import { DeviceBind } from '~/components/deviceConnection/comp/DeviceBind';
import DeviceManage from '~/components/deviceConnection/ShowFile';
import { GlobalLayout } from '~/components/globalLayout';
import GlobalLoad from '~/components/globalLoad';
import { Header } from '~/components/header';
import MobileSearch from '~/components/header/MobileSearch';
import { LoginModal } from '~/components/modal';
import { MyToast } from '~/components/MyToast/MyToast';
import { UserGuide } from '~/components/userGuide/UserGuide';
import RetrivePassword, { RetrivePasswordMobile } from '~/pages/retrievePassword/RetrivePassword';
import TestPage from '~/pages/testPage/TestPage';
import { useNeedUpdate } from '~/store/isUpdate';
import { isMobile } from '~/utils/utils';
import { atom, useAtom } from 'jotai';
import React, { Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';

function UpdateTime() {
  const [, setNeedUpdate] = useNeedUpdate();
  useEffect(() => {
    let timer: null | NodeJS.Timeout = null;
    let preTime: string | null = null;
    const exec = () => {
      timer = setTimeout(() => exec(), 30 * 1000);
      checkIsUpdateReq().then((s) => {
        if (preTime == null) preTime = s;
        else if (preTime != s) setNeedUpdate(true);
      });
    };
    exec();
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);
  return null;
}

export const showUpgradeTipAtom = atom(false);

function UpgradeTip() {
  const Element = React.lazy(() => import('~/components/upgradeTip'));
  const [show, setShow] = useAtom(showUpgradeTipAtom);
  if (show) {
    return (
      <Suspense fallback={null}>
        <Element onClose={() => setShow(false)} />
      </Suspense>
    );
  }
}

export const LayoutRoot = () => {
  return (
    <>
      {/* <VoiceSubtitle/> */}
      {/* <Test/> */}
      {isMobile() ? <RetrivePasswordMobile /> : <RetrivePassword />}
      <Header />
      <Outlet />
      <LoginModal />
      <MobileSearch />
      <UserGuide />
      <SecondConfirmation />
      <DeviceManage />
      <UpdateTime />
      <TestPage />
      <DeviceBind />
      <UpgradeTip />
      <GlobalLayout />
      <GlobalLoad />
      <MyToast />
    </>
  );
};
