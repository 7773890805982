import { atom, useAtom } from 'jotai';
import { Loading } from './Loading';

export const showGlobalLoadingAtom = atom(false);

export default function () {
  const [loading] = useAtom(showGlobalLoadingAtom);

  if (!loading) {
    return null;
  }

  return (
    <div className="fixed inset-0 z-[99999] flex items-center justify-center bg-[rgba(0,0,0,0.3)] drop-shadow-md">
      <Loading />
    </div>
  );
}
