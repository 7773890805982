import { clsxm } from '@hidock/utils';
import { Tag, tagReq } from '~/api/tag';
import { useFocus } from '~/hooks/useFocus';
import { useMount, useRequest } from 'ahooks';
import { atom, getDefaultStore, useAtom } from 'jotai';
import { CSSProperties, SVGProps, useEffect, useMemo, useRef, useState, useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { noteStore } from '../store';

const Svg = {
  rightArrowSvg(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width="12.833333969116211"
        height="11"
        viewBox="0 0 12.833333969116211 11"
        {...props}
      >
        <g transform="matrix(0,1,-1,0,12.833333969116211,-12.833333969116211)">
          <path
            d="M17.68515396911621,0.268485C18.043133969116212,-0.0894952,18.62353396911621,-0.0894952,18.98151396911621,0.268485C18.98151396911621,0.268485,23.56483396911621,4.85182,23.56483396911621,4.85182C23.922833969116212,5.2098,23.922833969116212,5.7902,23.56483396911621,6.14818C23.20693396911621,6.50616,22.626453969116213,6.50616,22.26849396911621,6.14818C22.26849396911621,6.14818,18.33333396911621,2.21303,18.33333396911621,2.21303C18.33333396911621,2.21303,14.39818396911621,6.14818,14.39818396911621,6.14818C14.040203969116211,6.50616,13.459799969116212,6.50616,13.101818969116211,6.14818C12.743838769116211,5.7902,12.743838769116211,5.2098,13.101818969116211,4.85182C13.101818969116211,4.85182,17.68515396911621,0.268485,17.68515396911621,0.268485C17.68515396911621,0.268485,17.68515396911621,0.268485,17.68515396911621,0.268485ZM13.101818969116211,11.2685C13.101818969116211,11.2685,17.68515396911621,6.68515,17.68515396911621,6.68515C18.043133969116212,6.32717,18.62353396911621,6.32717,18.98151396911621,6.68515C18.98151396911621,6.68515,23.56483396911621,11.2685,23.56483396911621,11.2685C23.922833969116212,11.6265,23.922833969116212,12.2069,23.56483396911621,12.5648C23.20693396911621,12.9228,22.626453969116213,12.9228,22.26849396911621,12.5648C22.26849396911621,12.5648,18.33333396911621,8.62969,18.33333396911621,8.62969C18.33333396911621,8.62969,14.39818396911621,12.5648,14.39818396911621,12.5648C14.040203969116211,12.9228,13.459799969116212,12.9228,13.101818969116211,12.5648C12.743838769116211,12.2069,12.743838769116211,11.6265,13.101818969116211,11.2685C13.101818969116211,11.2685,13.101818969116211,11.2685,13.101818969116211,11.2685Z"
            fillRule="evenodd"
          />
        </g>
      </svg>
    );
  },
  delete(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width="18.529414187363415"
        height="18.488371861622753"
        viewBox="0 0 18.529414187363415 18.488371861622753"
        {...props}
      >
        <g transform="matrix(-1,5.2146120310681e-8,5.2146120310681e-8,1,37.0588264465332,-9.662370112267836e-7)">
          <path
            d="M32.4265132232666,1.84884L37.0588132232666,1.84884L37.0588132232666,3.69767L35.205913223266606,3.69767L35.205913223266606,17.564C35.205913223266606,18.0745,34.791113223266606,18.4884,34.2794132232666,18.4884L21.3088232232666,18.4884C20.7971532232666,18.4884,20.382353223266602,18.0745,20.382353223266602,17.564L20.382353223266602,3.69767L18.5294132232666,3.69767L18.5294132232666,1.84884L23.1617632232666,1.84884L23.1617632232666,0L32.4265132232666,0L32.4265132232666,1.84884ZM25.0147032232666,6.47093L25.0147032232666,13.8663L26.8676532232666,13.8663L26.8676532232666,6.47093L25.0147032232666,6.47093ZM28.720613223266604,6.47093L28.720613223266604,13.8663L30.573513223266602,13.8663L30.573513223266602,6.47093L28.720613223266604,6.47093Z"
            fill="#3A3A3A"
          />
        </g>
      </svg>
    );
  },
};

const refreshListAtom = atom(0);

export const homePageTagRefresh = () => {
  getDefaultStore().set(refreshListAtom, (pre) => pre + 1);
};

function TabBar2() {
  const [tagRefreshNum] = useAtom(refreshListAtom);
  const [noteSelect, setNoteSelect] = useAtom(noteStore.select);
  const { t } = useTranslation();
  const defaultTagInfo: Tag.TagInfo = {
    id: '',
    userId: '',
    tag: t('user.adddTag.All'),
    noteCount: 999,
  };

  const listQuery = useRequest(() => tagReq.getListByFoderId({ folderId: noteSelect.folderId }), {
    refreshDeps: [noteSelect.folderId, tagRefreshNum],
  });
  const list = [defaultTagInfo, ...(listQuery.data || [])];

  // const [list, setList] = useState<Tag.TagInfo[]>([defaultTagInfo]);
  const copyRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerCss, setContainerCss] = useState<CSSProperties>({
    visibility: 'hidden',
  });
  const [cutPos, setCutPos] = useState(1);
  const [moreShow, setMoreShow] = useState(false);
  const moreShowClick = useFocus((state) => {
    setMoreShow(state == 'focus');
  });

  function showTagNumHandle() {
    const copy = copyRef.current;
    copy!.style.width = containerRef.current!.clientWidth + 'px';
    if (copy) {
      const len = copy.clientWidth;
      const arr = Array.from(copy.children);
      const endNodeLen = arr.pop()!.clientWidth;
      let idx = 0;
      for (let i = arr.length - 1; i >= 0; i--) {
        //@ts-ignore
        if (arr[i].offsetLeft + arr[i].clientWidth + endNodeLen < len) {
          idx = i;
          break;
        }
      }
      if (idx + 1 < arr.length) setCutPos(idx);
      else setCutPos(arr.length);
      setContainerCss({
        visibility: 'visible',
      });
    }
  }

  useEffect(() => {
    window.addEventListener('resize', showTagNumHandle);

    return () => {
      window.removeEventListener('resize', showTagNumHandle);
    };
  }, []);

  //处理展示多少个
  useEffect(() => {
    showTagNumHandle();
  }, [listQuery.data, copyRef.current]);

  const listEls = list.map((item) => {
    const isSelect = noteSelect.tagId == item.id;
    return (
      <span
        className=" mr-[20px] block h-[22px] cursor-pointer text-ellipsis whitespace-pre border-b-2 text-[18px] leading-[20px] text-[#686868]"
        style={{
          borderColor: isSelect ? '#00A2AF' : 'transparent',
          color: isSelect ? '#00A2AF' : '#686868',
        }}
        key={item.id}
        onClick={() => {
          setNoteSelect((state) => {
            return { ...state, tagId: item.id };
          });
        }}
      >
        #{item.tag}
      </span>
    );
  });

  const more_than = (
    <div className=" relative">
      <div
        className=" mx-[10px] flex h-[32px] w-[32px] cursor-pointer  items-center justify-center rounded-full fill-[#686868] hover:bg-[#DEDEDE] hover:fill-[#3A3A3A]"
        onClick={() => !moreShow && moreShowClick()}
        style={{
          display: listEls.slice(cutPos).length ? '' : 'none',
        }}
      >
        <Svg.rightArrowSvg />
      </div>
      {moreShow && listEls.slice(cutPos).length && (
        <div
          className={clsxm(
            ' absolute right-1/2 top-[calc(100%+10px)] z-[999] max-h-[500px] w-[180px] overflow-y-auto overflow-x-hidden text-ellipsis bg-white pl-[24px] pr-[14px] shadow-[0px_0px_4px_rgba(0,0,0,0.1)]',
            ' rounded-[12px] border-[0.5px] border-[#9C9C9C] backdrop-blur-[8px]',
            'scrollbar scrollbar-thumb-gray-500 scrollbar-track-gray-200 overflow-y-scroll'
          )}
        >
          {list.slice(cutPos).map((item) => {
            return (
              <>
                <p
                  className=" my-[4px] flex h-[40px] cursor-pointer items-center rounded-[8px] px-[7px]  py-[10px] text-[18px]"
                  style={{
                    background: noteSelect.tagId == item.id ? '#DEDEDE' : '',
                  }}
                  key={item.tag}
                  onClick={() => {
                    setNoteSelect((state) => {
                      return { ...state, tagId: item.id };
                    });
                  }}
                >
                  <span
                    className=" w-[142px] flex-1 overflow-hidden overflow-ellipsis whitespace-pre"
                    style={{
                      color: noteSelect.tagId == item.id ? '#3A3A3A' : '#686868',
                    }}
                  >
                    #{item.tag}
                  </span>
                  {/* <Svg.delete className=" ml-[5px]" /> */}
                </p>
                <div className=" my-[4px] h-[1px] bg-[#DDDDE1]" />
              </>
            );
          })}
        </div>
      )}
    </div>
  );

  return (
    <>
      <div
        className=" relative flex w-[80%] flex-wrap items-center"
        translate="no"
        style={containerCss}
        ref={containerRef}
      >
        {listEls.slice(0, cutPos)}
        {more_than}
      </div>
      <div className=" fixed left-full top-full flex w-[80%] flex-wrap items-center">
        <div className=" relative flex w-full items-center" ref={copyRef}>
          {listEls.slice(0, 100)}
          {more_than}
        </div>
      </div>
    </>
  );
}

export default TabBar2;
