import { request } from '~/lib/axios';

export type Sku = {
  type: string;
  code: string;
  quota: number;
  unitPrice: string;
  currency: string;
  symbol: string;
  discount: string;
  name: string;
  introduce: string;
  originalPrice: string;
};

export type Promotion = {
  id: number;
  sku: string;
  minQuantity: number;
  buyQuantity: number;
  giftQuantity: number;
  giftAmount: null | number;
  name: string;
  title: string;
};

export function paymentSkuReq() {
  return request.post<{
    skus: Sku[];
    promotions: Promotion[];
  }>('/v1/payment/skus', null);
}
