import { clsxm } from '@hidock/utils';
import { useAPIQuery } from '~/api/helper';
import { Note2, queryInfo2, useInfo2 } from '~/api/note2s/info2';
import { speakerListReq } from '~/api/note2s/transcirption/speakerList';
import { Transcription2, transcription2 } from '~/api/note2s/transcirption/transcriptions2';
import { UserType } from '~/api/users/types';
import i18n from '~/i18n';
import { formatSecondsToProgress } from '~/pages/record/utils';
import { useRequest } from 'ahooks';
import { useEffect, useMemo, useState } from 'react';
import { SpeakerList } from '../note-result2/note-result2';
import { ParagraphData, Sentece, Word } from '../note-result2/Transcription';

function transcriptToSentence(transcript: Transcription2) {
  const sentence: Sentece = {
    ...transcript,
    marks: [],
    words: [],
  };
  if (!transcript.tokens) {
    sentence.words.push({
      word: sentence.sentence,
      beginTime: sentence.beginTime,
      endTime: sentence.endTime,
      transcription: sentence,
    });
    return sentence;
  }
  const tokens: any = JSON.parse(transcript.tokens);

  //适配给token增加空格
  let k = 0;
  let sentenceText = transcript.sentence;
  for (let i = 0; i < tokens.length; i += 3) {
    let s = '';
    for (let j = 0; j < tokens[i].length; j++) {
      while (k < sentenceText.length && sentenceText[k] != tokens[i][j]) s = s + sentenceText[k++];
      // if(k >= sentenceText.length)debugger;
      if (k < sentenceText.length) s = s + sentenceText[k++];
    }
    while (k < sentenceText.length && sentenceText[k] == ' ') s = s + sentenceText[k++];
    if (i + 3 >= tokens.length && s[s.length - 1] != ' ') s = s + ' ';
    tokens[i] = s;
  }

  // const firstTime = tokens[1];
  // for (let i = 1; i < tokens.length; i += 3)tokens[i] -= firstTime;
  // for (let i = 2; i < tokens.length; i += 3)tokens[i] += tokens[i - 1];
  for (let i = 0; i < tokens.length; i += 3) {
    const s = tokens[i],
      beginTime = tokens[i + 1],
      endTime = beginTime + tokens[i + 2];
    if (!s) continue;
    const word = {
      word: s,
      beginTime,
      endTime,
      transcription: sentence,
    };
    sentence.words.push(word);
  }

  return sentence;
}

function sentencesToParagraphs(sentences: Sentece[], cluster: number = 0) {
  const paragraphDatas: ParagraphData[] = [];
  let preSpeaker: string | null = '';
  let sens: Sentece[] = [];
  let marks: number[] = [];
  let wordLen = 0;
  const defaultName = i18n.t('note.info.notSpeaker');
  for (let i = 0; i < sentences.length; i++) {
    const sentence = sentences[i];
    if (
      (wordLen >= cluster && wordLen > 0) ||
      (i != 0 && preSpeaker != sentence.speaker) ||
      (!preSpeaker && sens.length)
    ) {
      paragraphDatas.push({
        speaker: preSpeaker || '',
        sentences: sens,
        marks,
        words: ([] as Word[]).concat(...sens.map((sen) => sen.words)),
        beginTime: sens[0].beginTime,
        endTime: sens[sens.length - 1].endTime,
      });
      marks = [];
      sens = [];
      wordLen = 0;
    }
    wordLen += sentence.words.length;
    sens.push(sentence);
    marks.push(...sentence.marks);
    preSpeaker = sentence.speaker;
  }
  if (sens.length)
    paragraphDatas.push({
      speaker: preSpeaker || '',
      sentences: sens,
      marks,
      words: ([] as Word[]).concat(...sens.map((sen) => sen.words)),
      beginTime: sens[0].beginTime,
      endTime: sens[sens.length - 1].endTime,
    });
  return paragraphDatas;
}

function useGetParagraphs(noteId: string, info2?: Note2 | null) {
  const res = useState<ParagraphData[]>([]);
  const [sentences, setSentences] = useState<Sentece[]>([]);
  const [paragrpahs, setParagraphs] = res;
  //给段落增加mark
  const marks = useMemo(() => {
    return info2 ? info2.marks.map((item) => item.markTime) : [];
    // return []
  }, [info2]);

  const sentenceQuery = useRequest(() => transcription2({ noteId }), {
    cacheKey: noteId,
    refreshDeps: [noteId],
    staleTime: 5 * 60 * 1000,
    onSuccess: (data) => {
      const sentences: Sentece[] = data.map(transcriptToSentence);
      setSentences(sentences);
    },
  });

  useEffect(() => {
    if (sentenceQuery.data?.length && !sentences.length) {
      const sentences = sentenceQuery.data.map(transcriptToSentence);
      setSentences(sentences);
    }
  }, []);

  useEffect(() => {
    let timeArr: number[] = [];
    for (let i = 0; i < sentences.length; i++) {
      const cur = sentences[i];
      const next = sentences[i + 1];
      if (!next) timeArr.push(1e9);
      else timeArr.push(cur.endTime + (next.beginTime - cur.beginTime) / 2);
    }
    let MarkOp = 0;
    for (let i = 0; i < sentences.length; i++) {
      const sentence = sentences[i];
      const curMarks: number[] = [];
      while (MarkOp < marks.length) {
        const timestampe = marks[MarkOp];
        if (timestampe <= timeArr[i]) {
          curMarks.push(timestampe);
          MarkOp++;
        } else break;
      }
      sentence.marks = curMarks;
      if (MarkOp >= marks.length) break;
    }
    const paragraphs = sentencesToParagraphs(sentences, 150);
    setParagraphs(paragraphs);
  }, [sentences, marks]);
  console.log('useGetParagraphs', sentences);
  return res;
}

function abbreviationName(name: string | null) {
  if (!name) return '';
  if (/^Speaker (\d{1,2})$/.test(name)) return `S${name.split(' ')[1]}`;
  const words = name.split(' ').filter((word) => word);
  let s = '';
  if (words.length >= 2) {
    s += words[0][0];
    s += words[1][0];
  } else {
    s = name.slice(0, 2);
  }
  return s.toUpperCase();
}

function Paragraph(props: { Paragraph: ParagraphData; color: string; info?: Note2 }) {
  const { Paragraph, color } = props;
  // const { words, marks } = Paragraph;
  const renderWord: (
    | (Word & { isHigh: boolean })
    | {
        word: string;
        isHigh: boolean;
      }
  )[] = [];

  Paragraph.sentences.forEach((sens) => {
    let markIdx = 0;
    let isHigh = !!sens.marks.length;
    const { words, marks } = sens;
    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      while (markIdx < marks.length && marks[markIdx] <= word.beginTime) {
        renderWord.push({
          word: '📌',
          isHigh,
        });
        markIdx++;
      }
      renderWord.push({
        ...word,
        isHigh,
      });
      // const isHigh = isSelect && !!word.beginTime && !!word.endTime && word.beginTime <= currentTime && currentTime <= word.endTime;
      if (i + 1 == words.length) {
        while (markIdx < marks.length) {
          renderWord.push({ word: '📌', isHigh });
          markIdx++;
        }
      }
    }
  });

  function HeaderEl() {
    return (
      <div className=" mt-[30px] flex items-center ">
        <div
          className="mr-[11px] flex aspect-square w-[38px] items-center justify-center rounded-full  text-[16px] font-[700]"
          style={{
            background: color,
            display: props.info?.level != 'pro' && !Paragraph.speaker ? 'none' : '',
          }}
        >
          {abbreviationName(Paragraph.speaker)}
        </div>
        <span className=" mr-[10px] text-[16px] font-[700]">{Paragraph.speaker}</span>
        <span className=" text-[14px] font-[600] text-[#939394]">
          {formatSecondsToProgress((Paragraph.beginTime / 1000) | 0, true)}
        </span>
      </div>
    );
  }

  // console.log('Paragraph',Paragraph)
  //"scrollbar overflow-y-scroll scrollbar-thumb-gray-500 scrollbar-track-gray-200"
  function contentEl() {
    return (
      <div
        className={clsxm(
          ' mt-[12px] flex-1 pl-[12px] pr-[35px] text-[14px] font-[700] leading-[24px] ',
          'scrollbar scrollbar-thumb-gray-500 scrollbar-track-gray-200 overflow-y-scroll'
        )}
      >
        {renderWord.map((word, idx) => {
          return (
            <em
              className="whitespace-pre-wrap not-italic"
              key={idx}
              style={{ backgroundColor: word.isHigh ? '#0BF7E9' : '' }}
            >
              {word.word}
            </em>
          );
        })}
      </div>
    );
  }
  return (
    <div className=" flex  w-full">
      <div className=" flex-1">
        {HeaderEl()}
        {contentEl()}
      </div>
    </div>
  );
}

export default function (params: { noteId: string }) {
  const [speakerList, setSpeakerList] = useState<SpeakerList>([]);
  const { data: info2 } = useAPIQuery(queryInfo2(params.noteId));
  // const [info2, setInfo2] = useInfo2({ id: params.noteId });
  const [paragraphs] = useGetParagraphs(params.noteId, info2);

  useEffect(() => {
    async function exec() {
      const res = await speakerListReq(params);
      const speakers = res.map((i) => {
        return { speaker: i.name, color: i.color };
      });
      console.log('speakers', speakers);
      setSpeakerList(speakers);
    }
    exec();
  }, []);

  return (
    <div className=" flex-1">
      {paragraphs.map((paragraph) => {
        const color =
          speakerList.find((i) => {
            return i.speaker == paragraph.speaker;
          })?.color || '#888888';
        return (
          <Paragraph
            key={paragraph.sentences.map((i) => i.id).join(',')}
            Paragraph={paragraph}
            color={color}
            info={info2}
          />
        );
      })}
    </div>
  );
}
