import { proExperience } from '~/api/users/proExperience';
import { UserType } from '~/api/users/types';
import { useUserInfo } from '~/store/user';
import { parseDoubleBracketSyntax2 } from '~/utils/utils';
import { useRequest } from 'ahooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { myMessage } from '../MyToast/MyToast';

const CardBtnText = styled.span`
  background-image: linear-gradient(180deg, #fdeddb 0%, #d69c68 100%); /* 从左到右的红色至黄色线性渐变 */
  -webkit-background-clip: text; /* Safari/Chrome特定样式，将渐变应用于文字区域 */
  -webkit-text-fill-color: transparent; /* Safari/Chrome特定样式，透明化文字内容 */
`;

function PlanBottom() {
  const contentKey = 'user.identity.bottomContent';
  const { t } = useTranslation();
  const arr = parseDoubleBracketSyntax2(t(contentKey));
  const ElHash = {
    '.link'(text: string) {
      return (
        <span
          className="mx-1 cursor-pointer text-[#00A2AF] underline"
          onClick={() => window.open('https://www.hidock.com/', '_blank')}
          key={'privacy-policy'}
        >
          {text}
        </span>
      );
    },
    '.link2'(text: string) {
      return (
        <a
          className="mx-1 cursor-pointer text-[#00A2AF] underline"
          onClick={() => window.open('mailto:support@hidock.com')}
          key={'term-of-use'}
        >
          {text}
        </a>
      );
    },
  };
  return arr.map((text) => {
    //@ts-ignore
    if (text.type == 'BracketContent') return ElHash[text.content]?.(t(contentKey + text.content)) || text.content;
    if (text.type == 'text') return text.content;
  });
}

type Role = 'basic' | 'membership' | 'membershipPro';

//@ts-ignore
function getRole(type: UserType = UserType.free): Role {
  if (['trial', 'free'].includes(type)) return 'basic';
  if (['membership', 'kickstarter'].includes(type)) return 'membership';
  if (['pro'].includes(type)) return 'membershipPro';
}

const Plan = () => {
  const { t } = useTranslation();
  const userInfo = useUserInfo();
  const userRole = getRole(userInfo.data?.type);
  const navigate = useNavigate();
  const [hasExperience, setHasExperience] = useState(false);
  const hasExperienceQuery = useRequest(() => proExperience.check());

  const roleInfo = {
    free: {
      role: 'free',
      title: t('user.identity.basic.name'),
      describe: t('user.identity.basic.describe'),
      mobile: (t('user.identity.basic.role.mobile') as string).split('\n'),
      desktop: (t('user.identity.basic.role.desktop') as string).split('\n'),
      onClick() {
        // window.open('https://www.hidock.com/products/hidock-h1-audio-dock')
      },
    },
    membership: {
      role: 'membership',
      title: t('user.identity.Membership.name'),
      describe: t('user.identity.Membership.describe'),
      mobile: (t('user.identity.Membership.role.mobile') as string).split('\n'),
      desktop: (t('user.identity.Membership.role.desktop') as string).split('\n'),
      onClick() {
        window.open('https://www.hidock.com/products/hidock-h1-audio-dock');
      },
    },
    ProMembership: {
      role: 'membershipPro',
      title: t('user.identity.pro.name'),
      describe: t('user.identity.pro.describe'),
      mobile: (t('user.identity.pro.role.mobile') as string).split('\n'),
      desktop: (t('user.identity.pro.role.desktop') as string).split('\n'),
      onClick() {
        navigate('/notes/payment?from=plans');
      },
    },
  };
  const FreeEl = (data: (typeof roleInfo)['free'], role: Role) => {
    //@ts-ignore
    const isSelect =
      (userRole == 'basic' && role == 'membership') ||
      (userRole == 'membership' && role == 'membershipPro') ||
      (userRole == 'membershipPro' && role == 'membershipPro');
    const getTag = (info: string) => {
      if (info == 'membershipPro')
        return (
          <img src="/userRole/membershipPro.png" className=" absolute right-[15px] top-[6px] aspect-[2/1] w-[47px]" />
        );
      return null;
    };
    function getBtn() {
      let text = '';
      if (userRole == 'basic') text = t('misc.Upgrade');
      else if (userRole == 'membership') text = t('misc.Upgrade');
      else if (userRole == 'membershipPro') text = t('user.identity.more');
      //@ts-ignore
      const btnBgColor = () => {
        //@ts-ignore
        if (isSelect) return 'linear-gradient(180deg, #878584 0%, #000000 37%)';
        return 'black';
      };
      return (
        <button
          className=" absolute -bottom-1 left-1/2   h-[69px] w-[280px] -translate-x-1/2 rounded-[12px] bg-black text-center text-[20px] font-[600] leading-[63px]"
          onClick={() => {
            data.onClick();
          }}
          style={{
            background: btnBgColor(),
            visibility: isSelect ? 'visible' : 'hidden',
          }}
        >
          <CardBtnText>{text}</CardBtnText>
        </button>
      );
    }

    return (
      <div
        className=" relative mb-[23px] mr-[22px] box-border h-[510px] w-[268px] rounded-[16px] border border-transparent bg-[rgba(247,249,250,0.7)]"
        style={{
          boxShadow: role == userRole ? '0px 6px 20px 0px rgba(30, 77, 77, 0.4)' : '',
          borderColor: role == userRole ? '#DBE7E5' : 'transparent',
        }}
      >
        <div
          className=" absolute left-1/2 top-1/2 box-border -translate-x-1/2 -translate-y-1/2  rounded-[12px] bg-[#F7F8F9]"
          style={{
            width: 0 ? 268 - 7 * 2 : 268,
            height: 0 ? 510 - 8 * 2 : 510,
            paddingLeft: 0 ? 17 : 20,
            background: role == userRole ? 'linear-gradient(0deg, #DEE3E5 5%, #F9FBFC 50%)' : '',
          }}
        >
          <div className="h-[120px]">
            <h1
              className=" relative mt-[10px] text-[24px] font-[600] leading-[26px] text-black "
              style={{ paddingTop: 0 ? 25 : 25 + 8 }}
            >
              {data.title}
              {getTag(data.role)}
            </h1>
            <p className=" mt-[20px] text-[18px] font-[400] leading-[22px]">{data.describe}</p>
          </div>
          <div className="">
            <h3 className=" mt-[15px] text-[14px] font-[400]">Mobile:</h3>
            {data.mobile.map((item) => {
              return (
                <p className="mt-[6px] flex" key={item}>
                  <img
                    src={`/duigou${role == 'membership' || role == 'membershipPro' ? '-gradient' : ''}.svg`}
                    className="mr-[8px] mt-[1.5px] h-[12px] w-[12px] "
                  />
                  <span className=" whitespace-pre-wrap text-[14px] font-[400] leading-[16px] text-[#686868]">
                    {item}
                  </span>
                </p>
              );
            })}
          </div>
          <h3 className=" mt-[20px] text-[14px] font-[400]">Desktop:</h3>
          {data.desktop.map((item) => {
            return (
              <p className="mt-[6px] flex" key={item}>
                <img
                  src={`/duigou${role == 'membership' || role == 'membershipPro' ? '-gradient' : ''}.svg`}
                  className="mr-[8px] mt-[1.5px] h-[12px] w-[12px] "
                />
                <span className=" box-border whitespace-pre-wrap pr-[5px] text-[14px] font-[400] leading-[16px] text-[#686868]">
                  {item}
                </span>
              </p>
            );
          })}
        </div>
        {getBtn()}
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center ">
      <p className=" mb-[30px] w-full text-left text-[28px] font-[600]">{t('misc.plan')}</p>
      <div className=" mr-[-22px] flex">
        {FreeEl(roleInfo.free, 'basic')}
        {FreeEl(roleInfo.membership, 'membership')}
        {FreeEl(roleInfo.ProMembership, 'membershipPro')}
      </div>
      <p className="colol-[#A4A4A4] mb-[14px] mt-[0px] whitespace-pre-wrap  text-center  text-[12px] leading-[16px]">
        <PlanBottom />
      </p>
      <p className=" mt-[6px] flex w-full items-center justify-between rounded-[14px] bg-[#EFF7F7] px-[20px] py-[14px]">
        {t('user.plans.quota-experience')}
        <button
          className=" rounded-full bg-[#00A2AF] px-[48.5px] py-[10px] text-[16px] text-white"
          style={
            !hasExperienceQuery.loading && hasExperienceQuery.data
              ? {
                  background: '#00A2AF',
                }
              : {
                  background: '#E1E3E5',
                  pointerEvents: 'none',
                }
          }
          onClick={() => {
            proExperience.claim().then((res) => {
              if (!res) {
                myMessage.success(t('user.plans.getQuota'), 2000);
                userInfo.refetch();
                hasExperienceQuery.refresh();
              }
            });
          }}
        >
          {t('user.plans.claim')}
        </button>
      </p>
    </div>
  );
};

export function Plans() {
  const { t } = useTranslation();

  const header = <div className="text-[28px] font-[600]">{t('user.info.basic-info')}</div>;
  return (
    <div>
      <Plan />
    </div>
  );
}
