export const IconSave = ({ className }: { className?: string }) => {
  return (
    <svg className={className} fill="none" viewBox="0 0 20 20">
      <path
        d="M4.16667 17.5C3.70833 17.5 3.31583 17.3367 2.98917 17.01C2.6625 16.6833 2.49945 16.2911 2.5 15.8333V4.16667C2.5 3.70833 2.66333 3.31583 2.99 2.98917C3.31667 2.6625 3.70889 2.49945 4.16667 2.5H13.4792C13.7014 2.5 13.9133 2.54167 14.115 2.625C14.3167 2.70833 14.4936 2.82639 14.6458 2.97917L17.0208 5.35417C17.1736 5.50695 17.2917 5.68417 17.375 5.88583C17.4583 6.0875 17.5 6.29917 17.5 6.52083V15.8333C17.5 16.2917 17.3367 16.6842 17.01 17.0108C16.6833 17.3375 16.2911 17.5006 15.8333 17.5H4.16667ZM15.8333 6.54167L13.4583 4.16667H4.16667V15.8333H15.8333V6.54167ZM10 15C10.6944 15 11.2847 14.7569 11.7708 14.2708C12.2569 13.7847 12.5 13.1944 12.5 12.5C12.5 11.8056 12.2569 11.2153 11.7708 10.7292C11.2847 10.2431 10.6944 10 10 10C9.30556 10 8.71528 10.2431 8.22917 10.7292C7.74306 11.2153 7.5 11.8056 7.5 12.5C7.5 13.1944 7.74306 13.7847 8.22917 14.2708C8.71528 14.7569 9.30556 15 10 15ZM5.83334 8.33333H11.6667C11.9028 8.33333 12.1008 8.25333 12.2608 8.09333C12.4208 7.93333 12.5006 7.73556 12.5 7.5V5.83333C12.5 5.59722 12.42 5.39917 12.26 5.23917C12.1 5.07917 11.9022 4.99945 11.6667 5H5.83334C5.59722 5 5.39917 5.08 5.23917 5.24C5.07917 5.4 4.99945 5.59778 5 5.83333V7.5C5 7.73611 5.08 7.93417 5.24 8.09417C5.4 8.25417 5.59778 8.33389 5.83334 8.33333ZM4.16667 6.54167V15.8333V4.16667V6.54167Z"
        fill="currentColor"
      />
    </svg>
  );
};
