import { useMutation } from '@tanstack/react-query';
import { request } from '~/lib/axios';
import type { SharedMutationHookOptions } from '../helper';
import { defineMutation } from '../helper';
import { reqParamsToBody } from '../utils';

type Params = {
  id: string;
  level: number;
  remark?: string;
};

export const rate = defineMutation(async (params: Params) => {
  const data = await request.post('/v1/note/rate', reqParamsToBody(params));
  return data;
});

export const useRateNoteMutation = (options?: SharedMutationHookOptions) => {
  return useMutation(rate, options);
};
