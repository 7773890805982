import { clsxm } from '@hidock/utils';
import { SearchContent, SearchReq } from '~/api/note2s/search.ts';
import { UserAvatar } from '~/components/header';
import { useMyNavigate } from '~/hooks/useNavigate.ts';
import { ListSvg, SearchSvg, TilingSvg } from '~/svg/svg.tsx';
import { useMyDebounce } from '~/utils/utils.ts';
import { useKeyPress, useLatest } from 'ahooks';
import { useAtom, useSetAtom } from 'jotai';
import { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';
import tags from '../noteType/tags';
import { noteStore } from '../store';
import TabBar from './TabBar';
import TabBar2 from './TabBar2';

function Search() {
  const { t } = useTranslation();
  const [keyword, setKeyword] = useState('');
  const keywordLatest = useLatest(keyword);
  const [searchContent, setSearchContent] = useState<SearchContent[]>([]);
  const [contentStyle, setContentStyle] = useState<CSSProperties>({});
  const [isFocus, setIsFocus] = useState(false);
  const navigate = useMyNavigate();
  const searchHandle = useMyDebounce((keyword: string) => {
    if (keyword.trim().length) {
      SearchReq({
        keyword: keyword,
      }).then((data) => {
        if (keywordLatest.current == keyword) setSearchContent(data);
      });
    } else setSearchContent([]);
  }, 200);

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    searchHandle(keyword);
  }, [keyword]);

  useEffect(() => {
    setContentStyle({
      height: isFocus && searchContent.length ? Math.min(searchContent.length, 10) * 40 + 10 : 0,
      zIndex: 100,
      paddingBottom: isFocus && searchContent.length ? 10 : 0,
    });
  }, [searchContent, isFocus]);
  console.log(searchContent);

  useEffect(() => {
    const exec = (e: KeyboardEvent) => {
      if (e.key == '/' && ['/notes', '/notes/'].includes(window.location.pathname)) {
        setTimeout(() => {
          inputRef.current?.focus();
        });
      }
    };
    window.addEventListener('keypress', exec);

    return () => {
      window.removeEventListener('keypress', exec);
    };
  }, []);

  return (
    <div
      className=" relative mr-[24px] flex h-[50px] min-w-[300px] rounded-full border border-[#E3E3E3] bg-white"
      style={{
        borderRadius: '30px',
      }}
    >
      <input
        className=" placeholder:#BDBDBD box-border h-full  flex-1 rounded-l-full pl-[30px] text-[18px]  font-[500] outline-none"
        ref={inputRef}
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
        placeholder={t('user.note.search.placeholder')}
        onFocus={(e) => {
          searchHandle(e.target.value);
          setIsFocus(true);
        }}
        onBlur={(e) => {
          setTimeout(() => setIsFocus(false), 230);
        }}
      />
      <div className=" flex h-full w-[42px] cursor-pointer items-center justify-center" onClick={() => {}}>
        <SearchSvg />
      </div>
      <div
        className={clsxm(
          ' absolute left-0 top-full z-[999] w-full overflow-hidden overflow-y-scroll rounded-[12px] bg-white shadow-[0px_0px_20px_0px_rgba(0,0,0,0.3)]',
          'scrollbar  scrollbar-thumb-gray-500 scrollbar-track-gray-200 overflow-x-hidden overflow-y-scroll'
        )}
        style={contentStyle}
      >
        {(isFocus ? searchContent : []).map((content) => {
          const { title, type, id } = content;
          //new URL(tags[type],import.meta.url).href
          let tag = tags[type] || `/white.png`;
          const contents: string[] = [];
          let s = title;
          const keyallotment = new RegExp(keyword, 'i');
          while (keyword.length) {
            let idx = s.search(keyallotment);
            if (idx == -1) break;
            contents.push(s.slice(0, idx));
            contents.push(s.slice(idx, idx + keyword.length));
            s = s.slice(idx + keyword.length);
          }
          contents.push(s);
          return (
            <div
              className="hover:rgba(245,245,245,1) box-border flex h-[40px] w-full cursor-pointer items-center p-[0_30px] hover:bg-[rgba(245,245,245,1)]"
              key={id}
              onClick={(e) => {
                console.log('id', id);
                navigate(`/notes/${id}`);
                setSearchContent([]);
                setKeyword('');
              }}
            >
              <img className="w-[18px]" src={tag} />
              <span className=" ml-[10px] flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
                {contents.map((text, idx) => {
                  if (keyallotment.test(text))
                    return (
                      <em
                        className=" not-italic text-black"
                        style={{
                          display: idx + 1 == contents.length ? 'none' : '',
                        }}
                      >
                        {text}
                      </em>
                    );
                  return <em className=" not-italic text-[#aaa]">{text}</em>;
                })}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default function () {
  const [showType, setShowType] = useAtom(noteStore.showType);

  return (
    <>
      <header className="relative mb-[5px] ml-[18px] mr-[23px] mt-[15px] shrink-0">
        <div className="flex h-[60px] w-[calc(100%-24px)] items-center">
          <TabBar />
          <div className=" flex-1" />
          <Search />
          <UserAvatar className=" aspect-square w-[47px]" />
        </div>
        <div className=" mt-[15px]">
          <TabBar2 />
        </div>
        <div className=" flex items-center justify-end pr-[24px]">
          <TilingSvg
            className=" mr-[15px] cursor-pointer"
            style={{ fill: showType == 'tiling' ? '#191A1A' : '#939393' }}
            onClick={() => setShowType('tiling')}
          />
          <ListSvg
            className="cursor-pointer"
            onClick={() => setShowType('list')}
            style={{ fill: showType == 'list' ? '#191A1A' : '#939393' }}
          />
        </div>
      </header>
    </>
  );
}
