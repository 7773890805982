import { request } from '~/lib/axios';
import { reqParamsToBody } from '../utils';

export type deviceRenameParams = {
  deviceSn: string;
  name: string;
};

export function deviceRenameReq(params: deviceRenameParams) {
  return request.post('/v1/user/device/rename', reqParamsToBody(params));
}
