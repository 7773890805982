import { noteStore } from '~/pages/notes/store';
import { useAtom } from 'jotai';
import { defineQuery, useAPIInfiniteQuery } from '../helper';
import type { NoteReq } from '../note';
import { noteReq } from '../note';

type Params = {
  tagId?: string;
  pageSize?: number;
  count?: number;
};

export const recentlyList = (params?: NoteReq.noteInfo) => {
  return defineQuery(
    ['notes.recentlyList', params],
    async () => {
      return noteReq.getRecentlyList({
        ...params,
        count: params?.count ?? 3,
      });
    },
    {
      keyIndex: 1,
    }
  );
};

export const useRecentlyNoteList = (params: Params & { enabled?: boolean }) => {
  const [noteSelect] = useAtom(noteStore.select);

  return useAPIInfiniteQuery(
    recentlyList({
      folderId: noteSelect.folderId,
      tagId: noteSelect.tagId,
      count: params.count,
    }),
    {}
  );
};
