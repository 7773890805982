import { confirmEmail } from './email-confirm';
import { info } from './info';
import { resetPassword } from './password-reset';
import { updatePassword } from './password-update';
import { signUpWithEmail } from './signin';

export const users = {
  signInWithEmail: signUpWithEmail,
  info,
  resetPassword,
  updatePassword,
  confirmEmail,
};
