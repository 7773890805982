export const IconMemo = ({ className }: { className?: string }) => {
  return (
    <svg className={className} fill="none" viewBox="0 0 12 12">
      <path
        d="M7.87476 3.93738H3.95825V2.95825H7.87476V3.93738ZM7.87476 4.9165H3.95825V5.89563H7.87476V4.9165ZM9.83301 1.97913V7.85388L6.89563 10.7913H2.97913C2.71945 10.7913 2.4704 10.6881 2.28678 10.5045C2.10316 10.3209 2 10.0718 2 9.81213V1.97913C2 1.71945 2.10316 1.4704 2.28678 1.28678C2.4704 1.10316 2.71945 1 2.97913 1H8.85388C9.11356 1 9.36261 1.10316 9.54623 1.28678C9.72985 1.4704 9.83301 1.71945 9.83301 1.97913ZM8.85388 6.87476V1.97913H2.97913V9.81213H5.9165V7.85388C5.9165 7.5942 6.01966 7.34516 6.20328 7.16154C6.38691 6.97791 6.63595 6.87476 6.89563 6.87476H8.85388Z"
        fill="currentColor"
      />
    </svg>
  );
};
