import { useResample } from '~/api/note2s/audio/resample';
import { Note2, queryInfo2, useInfo2 } from '~/api/note2s/info2';
import { speakerRename } from '~/api/note2s/speakerRename';
import { changeName } from '~/api/note2s/transcirption/changeNmae';
import { speakerListReq } from '~/api/note2s/transcirption/speakerList';
import { Transcription2, transcription2 } from '~/api/note2s/transcirption/transcriptions2';
import { updateSentenceReq } from '~/api/note2s/transcirption/updateSentence';
import { updateTitle } from '~/api/note2s/updateTitle';
import { useGetTicketInfo } from '~/api/notes/getByTicket';
import { UserType } from '~/api/users/types';
import { queryClient } from '~/main';
import { sleep } from '~/utils/sleep';
import { generalErrorHandle } from '~/utils/utils';
import i18next from 'i18next';
import { atom, getDefaultStore, useAtom, useSetAtom } from 'jotai';
import { MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { myMessage } from '../MyToast/MyToast';
import { NoteListCardDataRefetchList } from '../note';
import Player from '../player/player';
import Header from './Header/Header';
import { NormalizeContnent, SelectionInfo, selectionInfoAtom } from './markingWords/selectionBtn';
import SummaryEditor from './SummaryEditor';
import Transcription, { ParagraphData, Sentece, TranscriptionProps } from './Transcription';
import { recentlyNoteRefresh } from '~/components/note/store.ts';

const prefix = 'custom_dictionary_dom_id_';

export enum custom_dictionary_dom_class {
  title = prefix + 'title',
  paragraph = prefix + 'paragraph',
}

export const PlayerParagraphAtom = atom<null | {
  endTime: number;
  ids: string[];
}>(null);

export const noteStateAtom = atom({
  hasEditor: true,
  isPro: false,
  userType: UserType.free as UserType,
});
export const sentencesAtom = atom<Sentece[]>([]);

export type SpeakerList = {
  speaker: string;
  color: string;
}[];

type Props = {
  className?: string;
  ticket?: string;
  noteId?: string;
  hideNoteWhenDeleting?: boolean;
  allowRating?: boolean;
  action?: 'save' | 'copy';

  onClick?: React.MouseEventHandler;
  onCloseClick?: React.MouseEventHandler;
  onDeleteClick?: () => boolean | Promise<boolean | undefined>;
  onDeleted?: () => void;
  onTranscriptLoaded?: () => void;
};

export type SpeakerNoteGlobalData = {
  summary: {
    copyContent?: () => void;
    getSummaryContent?: () => Record<string, string | (string | string[])[]> & {
      title?: string;
    };
  };
  transcription: {
    // getTextContent?: () => { beginTime: string; endTime: string; speaker: string; sentence: string; }[],
    getParagraphDatas?: () => ParagraphData[];
  };
  header: {};
};

function NoteResult2(props: Props) {
  const { t } = useTranslation();
  const noteGlobalDataRef = useRef<SpeakerNoteGlobalData>({
    summary: {},
    transcription: {},
    header: {},
  });
  const [noteState, setNoteState] = useAtom(noteStateAtom);
  const {
    className,
    ticket = '',
    noteId = '',
    hideNoteWhenDeleting = true,
    allowRating = true,
    action = 'save',
    onClick,
    onCloseClick,
    onDeleteClick,
    onDeleted,
    onTranscriptLoaded,
  } = props;
  const [contentHover, setContentHover] = useState<string[]>([]);
  const [sentences, setSentences] = useAtom(sentencesAtom);
  const [player, setPlayer] = useState({
    // 音频总时长，单位为毫秒
    duration: 0,
    // 当前播放的时间
    currentTime: 0,
    // 播放状态，如有：ready/playing/pause/stop
    playState: 'pause',
    segments: [
      {
        start: 0,
        end: 332172,
        code: '1',
      },
      {
        start: 332173,
        end: 332172 * 2,
        code: '2',
      },
    ],
  });
  const [globalSelectSpeaker, setGlobalSelectSpeaker] = useState('');
  const [playParagraph, setPlayerParagraph] = useAtom(PlayerParagraphAtom);
  const [speakerList, setSpeakerList] = useState<SpeakerList>([]);
  const playerRef = useRef<{
    seakAudioPos: (time: number) => void;
  } | null>(null);
  const latestMarksRef = useRef<number[]>([]);
  // const { data } = useGetTicketInfo(ticket,0)

  //playler使用
  // const noteId = '4705102941655273472';
  const [waves, setWaves] = useResample({ noteId });
  const info2Query = useInfo2({ id: noteId });
  const { data: info2 } = info2Query;
  const getHighlightBlocks = () => {
    const items = sentences.filter((item) => contentHover.includes(item.id + ''));
    const left = items[0];
    const right = items[items.length - 1];
    if (!left) return [];
    return [
      {
        start: left.beginTime,
        end: right.endTime,
      },
    ];
  };
  const [highligihtMarks, setHighligihtMarks] = useState<number[]>([]);

  const getHighlightWaves = (speaker: string) => {
    const items = sentences.filter((item) => item.speaker == speaker);

    return items.map((item) => {
      return {
        start: item.beginTime,
        end: item.endTime,
      };
    });
  };
  const marks = useMemo(() => {
    return info2 ? info2.marks.map((item) => item.markTime) : [];
    // return []
  }, [info2]);
  latestMarksRef.current = marks;
  const dataset = {
    // voice mark信息，值为相对于音频开始的偏移时间，单位为毫秒
    marks,
    // 音波频谱图，有多少个，分辨率是多少待定
    waves: waves,
    // 需要高亮的区块
    highlightBlocks: getHighlightBlocks(),
    highlightWaves: globalSelectSpeaker ? getHighlightWaves(globalSelectSpeaker) : [],
    highligihtMarks,
  };

  useEffect(() => {
    setNoteState({
      hasEditor: info2?.ownership == 'myself',
      isPro: info2?.level == 'pro',
      userType: info2?.level || UserType.free,
    });
    return () => {
      setNoteState(noteStateAtom.init);
    };
  }, [info2?.ownership, info2?.level]);

  //给传入的sentences加入mark数据
  function sentenceAddMark(sentences: Sentece[]) {
    const marks = latestMarksRef.current;
    //这里存放的是每个句子中mark的拥有权
    let timeArr: number[] = [];
    for (let i = 0; i < sentences.length; i++) {
      const cur = sentences[i];
      const next = sentences[i + 1];
      if (!next) timeArr.push(1e9);
      else timeArr.push(cur.endTime + (next.beginTime - cur.endTime) / 2);
    }
    let MarkOp = 0;
    for (let i = 0; i < sentences.length; i++) {
      const sentence = sentences[i];
      const curMarks: number[] = [];
      while (MarkOp < marks.length) {
        const timestampe = marks[MarkOp];
        if (timestampe <= timeArr[i]) {
          curMarks.push(timestampe);
          MarkOp++;
        } else break;
      }
      sentence.marks = curMarks;
      if (MarkOp >= marks.length) break;
    }
  }

  function updateSentence() {
    transcription2({
      noteId,
    }).then(async (data) => {
      const sentences: Sentece[] = data.map((item) => {
        const target: Sentece = {
          ...item,
          marks: [],
          words: [],
        };
        if (!item.tokens) {
          target.words.push({
            word: target.sentence,
            preWord: target.sentence,
            beginTime: target.beginTime,
            endTime: target.endTime,
            transcription: target,
            idx: 0,
          });
          return target;
        }
        const tokens: any = JSON.parse(item.tokens);

        //适配给token增加空格
        let k = 0;
        let sentenceText = item.sentence;
        if (sentenceText[sentenceText.length - 1] != ' ') sentenceText = sentenceText + ' ';
        for (let i = 0; i < tokens.length; i += 3) {
          let nextToken = tokens[i + 3];
          let idx = sentenceText.indexOf(tokens[i], k);
          k = idx + tokens[i].length;
          const chr = sentenceText[k];
          if (chr == ' ' && nextToken?.[0] != ' ') tokens[i] = tokens[i] + ' ';
        }

        // const firstTime = tokens[1];
        // for (let i = 1; i < tokens.length; i += 3)tokens[i] -= firstTime;
        // for (let i = 2; i < tokens.length; i += 3)tokens[i] += tokens[i - 1];
        let idx = 0;
        for (let i = 0; i < tokens.length; i += 3) {
          const s = tokens[i],
            beginTime = tokens[i + 1],
            endTime = beginTime + tokens[i + 2];
          if (!s) continue;
          const word = {
            word: s,
            preWord: s,
            beginTime,
            endTime,
            beforeFixing: '',
            transcription: target,
            idx: idx++,
          };
          target.words.push(word);
        }

        return target;
      });

      sentenceAddMark(sentences);
      setSentences(sentences);
      const res = await speakerListReq({ noteId });
      if (res instanceof Array) {
        //首次排序
        setSpeakerList(
          res
            .map((i) => {
              return {
                speaker: i.name,
                color: i.color,
              };
            })
            .sort(function (item, item2) {
              let a = item.speaker,
                b = item2.speaker;
              const ra = a.match(/^speaker([ _])\d+$/gi);
              const rb = b.match(/^speaker([ _])\d+$/gi);
              if (ra && rb) {
                let na = parseInt(a.replace(/^speaker([ _])(\d+)$/gi, '$2'));
                let nb = parseInt(b.replace(/^speaker([ _])(\d+)$/gi, '$2'));
                return na - nb;
              }
              if (ra && rb == null) return 1;
              if (ra == null && rb) return -1;
              return a < b ? -1 : 1;
            })
        );
      } else {
        //@ts-ignore
        if (res && res.error) generalErrorHandle(res, t, 2000);
      }
    });
  }

  //处理sentences
  useEffect(() => {
    updateSentence();

    return () => {
      setSentences([]);
    };
  }, []);

  useEffect(() => {
    if (playParagraph && player.currentTime > playParagraph.endTime) {
      setPlayerParagraph(null);
      player.playState = 'pause';
      setPlayer({ ...player });
    }
  }, [player.currentTime, playParagraph]);

  let clusterGeneralNum = 150;
  const [tranState, setTranState] = useState({
    ParagraphCluster: clusterGeneralNum,
    loading: false,
  });
  const tranEventRef = useRef<TranscriptionProps['event']>({});
  const tranLasetEv: TranscriptionProps['event'] = {
    async updateName(sentenceId, newNmae, oldName) {
      console.log('modeName(sentenceId, newNmae, oldName)', sentenceId, newNmae, oldName);
      let res: {
        name: string;
        color: string;
      }[] = [];
      //我没有考虑修改失败的情况
      for (let i = 0; i < sentenceId.length; i++) {
        res = await changeName({
          noteId,
          sentenceId: sentenceId[i],
          name: newNmae,
        });
        //@ts-ignore
        if (res?.error) {
          //@ts-ignore
          generalErrorHandle(res, t);
          continue;
        }
        const target = sentences.find((item) => item.id + '' == sentenceId[i])!;
        target.speaker = newNmae;
      }

      let len = speakerList.length;
      const newSpeakerList: SpeakerList = [];
      console.log('res', res);
      for (let i = 0; i < res.length; i++) {
        let idx = speakerList.findIndex((item) => item.speaker == res[i].name);
        //检查是不是修改后的名字，如果是放到原来修改前的位置上
        if (res[i].name == newNmae && res.findIndex((item) => item.name == oldName) == -1)
          idx = speakerList.findIndex((item) => item.speaker == oldName);
        const targetIdx = idx == -1 ? len++ : idx;
        newSpeakerList[targetIdx] = {
          speaker: res[i].name,
          color: res[i].color,
        };
      }
      setSpeakerList(newSpeakerList.filter(Boolean));
      setSentences([...sentences]);
    },
    play(sentenceIds) {
      const items = sentences.filter((item) => sentenceIds.includes(item.id + ''));
      const startTime = items[0].beginTime;
      const endTime = items[items.length - 1].endTime;
      playerRef.current?.seakAudioPos(startTime);
      setPlayerParagraph({
        ids: sentenceIds,
        endTime: endTime,
      });
      setPlayer({
        ...player,
        currentTime: startTime,
        playState: 'start',
      });
    },
    updateText(noteId, sentenceId, index, newToken) {
      updateSentenceReq({
        noteId,
        sentenceId,
        index,
        token: newToken,
      }).then((data: any) => {
        if (data?.error) {
          //@ts-ignore
          generalErrorHandle(data, i18next.t);
        }
      });
    },
    globalUpdateText(sentenceId, oldWord, newWord) {
      const symbols = ['.', ',', '!', '?', '，', '。', '！', '？'];
      const orginNewWord = newWord;
      if (symbols.includes(oldWord[oldWord.length - 1])) oldWord = oldWord.slice(0, -1);
      if (symbols.includes(newWord[newWord.length - 1])) newWord = newWord.slice(0, -1);
      for (let i = 0; i < sentences.length; i++) {
        const sentence = sentences[i];
        sentence.words.forEach((word, index) => {
          const back = word.word[word.word.length - 1];
          const state = symbols.includes(back) ? 1 : 0;
          if (state + oldWord.length == word.word.length && oldWord == word.word.slice(0, oldWord.length)) {
            const fatherSentences = word.transcription;
            const newWordObj = { ...word, word: orginNewWord };
            fatherSentences.words[index] = newWordObj;
          }
        });
      }
      setSentences([...sentences]);
    },
    contentHover(sentenceIds, state: boolean) {
      if (!state && JSON.stringify(sentenceIds) == JSON.stringify(contentHover)) {
        setContentHover([]);
      } else setContentHover(sentenceIds);
    },
    markHover(sentenceId, timestamp) {
      if (!sentenceId) {
        setHighligihtMarks([]);
        return;
      }
      const marks = info2!.marks;
      let index = marks.findIndex((item) => item.markTime == timestamp);
      setHighligihtMarks(index == -1 ? [] : [index]);
    },
    pause() {
      setPlayerParagraph(null);
      setPlayer((player) => {
        return {
          ...player,
          playState: 'pause',
        };
      });
    },
  };
  Object.assign(tranEventRef.current, tranLasetEv);

  useEffect(() => {
    sentenceAddMark(sentences);
  }, [marks]);

  const setSelection = useSetAtom(selectionInfoAtom);
  //划词事件
  useEffect(() => {
    const mouseDownData = {
      x: -1,
      y: -1,
    };
    const store = getDefaultStore();
    function transcriptionHandle(e: globalThis.MouseEvent) {
      const selection = window.getSelection();
      const normalizeSelection = NormalizeContnent(selection?.toString() || '');
      const noteState = store.get(noteStateAtom);

      if (
        !selection ||
        normalizeSelection.length < 2 ||
        normalizeSelection.toString().length > 50 ||
        !noteState.hasEditor
      )
        return;
      let prographEls = Array.from(document.querySelectorAll('#transcription-prograph'));

      prographEls = prographEls.filter((i) => selection.containsNode(i, true));

      function openCustomDict(props: {
        preContent: string;
        content: string;
        openMethod?: SelectionInfo['openMethod'];
      }) {
        const { preContent, content, openMethod } = props;
        let x = e.clientX;
        let y = e.clientY;

        return setSelection({
          x: x,
          y: y,
          show: true,
          preContent,
          openMethod,
          content,
          event: {
            refreshNote() {
              updateSentence();
              info2Query.refetch();
            },
          },
        });
      }

      if (prographEls.length == 1) {
        const [prograph] = prographEls;
        let tokenEls = Array.from(prograph.querySelectorAll('#token'));
        let anchorNode: any = null;
        let focusNode: any = null;
        tokenEls = tokenEls.filter((tokenEl) => {
          if (selection.anchorNode && tokenEl?.contains(selection.anchorNode)) anchorNode = tokenEl;
          if (selection.focusNode && tokenEl?.contains(selection.focusNode)) focusNode = tokenEl;
          if (selection.containsNode(tokenEl, true)) return true;
        });

        if (anchorNode && focusNode) {
          const preContent = tokenEls
            .map((el) => {
              //@ts-ignore
              return el.dataset['beforeFixing'] || el.textContent;
            })
            .join('');

          let isConstant = preContent == selection.toString();

          openCustomDict({
            preContent: preContent,
            openMethod: 'transcript',
            content: isConstant ? '' : selection.toString(),
          });
        }
      }

      const titleEl = document.querySelector(`.${custom_dictionary_dom_class.title}`);
      if (titleEl && titleEl.contains(selection.focusNode) && titleEl.contains(selection.anchorNode)) {
        openCustomDict({
          preContent: selection.toString(),
          content: '',
        });
      }
      const prographs = Array.from(document.querySelectorAll(`.${custom_dictionary_dom_class.paragraph}`));
      for (const prograph of prographs) {
        if (prograph.contains(selection.focusNode) && prograph.contains(selection.anchorNode)) {
          openCustomDict({
            preContent: selection.toString(),
            content: '',
            openMethod: 'summary',
          });
        }
      }
    }

    function mouseDownHandle(e: globalThis.MouseEvent) {
      setSelection(selectionInfoAtom.init);
      mouseDownData.x = e.clientX;
      mouseDownData.y = e.clientY;
    }

    window.addEventListener('mousedown', mouseDownHandle);

    async function exec(e: globalThis.MouseEvent) {
      await sleep(80);
      setSelection({
        x: 0,
        y: 0,
        show: false,
        preContent: '',
        content: '',
        event: {
          refreshNote() {
            updateSentence();
            info2Query.refetch();
          },
        },
      });
      transcriptionHandle(e);
      // summaryHandle(e);
    }

    window.addEventListener('mouseup', exec);

    return () => {
      window.removeEventListener('mouseup', exec);
      window.removeEventListener('mousedown', mouseDownHandle);
    };
  }, []);

  const middleRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    function localSaveRadio() {
      const key = 'global-save-note-tran-summary-radio';
      return {
        get() {
          const objStr = localStorage.getItem(key) || '{}';
          let obj: any = JSON.parse(objStr);
          return obj as undefined | { left: number; right: number };
        },
        set(left: number, right: number) {
          const objStr = localStorage.getItem(key) || '{}';
          let obj: any = JSON.parse(objStr);
          obj = {
            left,
            right,
          };
          localStorage.setItem(key, JSON.stringify(obj));
        },
      };
    }

    const saveData = localSaveRadio();

    const middle = middleRef.current!;
    let isMouseDown = false;
    let startPos = {
      x: 0,
      y: 0,
    };
    let mousePos = {
      x: 0,
      y: 0,
    };
    let leftNodeW = 0;
    const childens: any[] = Array.from(middle.children);
    let leftW = 0;
    let rightW = 0;

    function syncUI() {
      const minWidth = 400;
      const totalWidth = middle.clientWidth;
      const offsetX = mousePos.x - startPos.x;
      leftW = leftNodeW + offsetX;
      if (leftW < minWidth) leftW = minWidth;
      rightW = totalWidth - leftW - 9;
      if (rightW < minWidth) {
        rightW = minWidth;
        leftW = totalWidth - rightW - 9;
      }

      const leftVal = leftW / totalWidth;
      const rightVal = rightW / totalWidth;
      saveData.set(leftW / totalWidth, rightW / totalWidth);
      childens[0].style.width = leftVal * 100 + '%';
      childens[2].style.width = rightVal * 100 + '%';
    }

    const getMousePosHandle = (e: globalThis.MouseEvent) => {
      mousePos = {
        x: e.screenX,
        y: e.screenY,
      };
      if (isMouseDown) syncUI();
    };
    const mouseUpHandle = function () {
      isMouseDown = false;
      document.body.style.cursor = '';
    };

    window.addEventListener('mousemove', getMousePosHandle);
    window.addEventListener('mouseup', mouseUpHandle);

    if (childens.length != 3) {
      debugger;
      return;
    }

    if (saveData.get()) {
      const data = saveData.get()!;
      childens[0].style.width = data?.left * 100 + '%';
      childens[2].style.width = data?.right * 100 + '%';
    }

    const midNode = childens[1];

    midNode.onmousedown = function () {
      startPos = mousePos;
      leftNodeW = childens[0].clientWidth;
      isMouseDown = true;
      document.body.style.cursor = 'w-resize';
    };

    return () => {
      window.removeEventListener('mousemove', getMousePosHandle);
      window.removeEventListener('mouseup', mouseUpHandle);
    };
  }, []);

  const [, setRecentlyNoteRefresh] = useAtom(recentlyNoteRefresh);

  return (
    <>
      <div className="absolute inset-0 bottom-[0px] left-[0px] right-[0px] top-[0px] rounded-[0px] bg-[rgba(0,0,0,0.3)] blur-[8px]" />
      <div className="border-indigo-[rgba(68, 68, 68, 1)] absolute bottom-[30px] left-[30px] right-[30px] top-[20px] rounded-[24px] bg-gradient-to-b from-[#F5F5F6] to-[#E9EBF0] p-[10px] pb-0 backdrop-blur-[10px]">
        <div className=" z-30 h-[150px] w-[100%]">
          <Header
            noteGlobalDataRef={noteGlobalDataRef}
            event={{
              onHoverTarget(name) {
                setGlobalSelectSpeaker(name);
              },
              async onBlur(title) {
                if (info2) {
                  info2.title = title;
                  const key = queryInfo2('').key;
                  await queryClient.cancelQueries(key);
                  queryClient.setQueryData(key, () => {
                    return info2;
                  });
                }
                updateTitle({
                  noteId,
                  title,
                }).then((res) => {
                  if (res != null) myMessage.error(t('note.info.summary.title-modified-failed'), 2000);
                  if (info2) {
                    setRecentlyNoteRefresh((num) => num + 1);
                    NoteListCardDataRefetchList(info2.id);
                  }
                });
              },
              onChangeName(newName, oldName) {
                if (newName == oldName) {
                  console.log('name not change ignore');
                  return;
                }
                //未处理失败情况
                speakerRename({
                  noteId,
                  oldName,
                  newName,
                }).then(() => {
                  //报错处理
                  sentences.forEach((item) => {
                    if (item.speaker == oldName) item.speaker = newName;
                  });
                  const target = speakerList.find((i) => i.speaker == newName);
                  const idx = speakerList.findIndex((i) => i.speaker == oldName);
                  if (target?.speaker) {
                    speakerList.splice(idx, 1);
                  } else {
                    speakerList[idx].speaker = newName;
                  }
                  setSpeakerList([...speakerList]);
                  setSentences([...sentences]);
                });
              },
              setCluster(state) {
                setTranState({
                  ...tranState,
                  ParagraphCluster: state == 'open' ? clusterGeneralNum : 0,
                });
              },
            }}
            state={{
              openCluster: clusterGeneralNum == tranState.ParagraphCluster,
            }}
            dataset={{
              title: info2?.title || '',
              info2: info2 || null,
              subTitle: info2?.createTime
                ? new Date(info2.createTime).toLocaleString(navigator.language, {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric',
                    weekday: 'long',
                    hour: 'numeric',
                    minute: 'numeric',
                    second: 'numeric',
                    timeZoneName: 'short',
                    localeMatcher: 'lookup',
                    hour12: true,
                    formatMatcher: 'basic',
                  })
                : '',
              speakerList: speakerList,
              noteId,
            }}
          />
        </div>
        {/* 修改下面的模块时，要注意左右滚动的功能 */}
        <div className="flex h-[calc(100%-247px)] w-[100%] justify-between" ref={middleRef}>
          <Transcription
            dataset={{
              sentencess: [sentences],
              speakerList: speakerList,
              playState: player.playState,
              currentTime: player.currentTime,
              hasEditor: info2?.ownership == 'myself',
            }}
            noteGlobalDataRef={noteGlobalDataRef}
            event={tranEventRef.current}
            state={tranState}
          />
          <div className=" h-full w-[9px] cursor-w-resize" />
          <SummaryEditor noteId={noteId} noteGlobalDataRef={noteGlobalDataRef} />
        </div>
        <div className=" h-[100px] w-[100%]">
          <Player
            dataset={dataset}
            onClick={(radio) => {
              player.currentTime = radio * player.duration;
              setPlayer({ ...player });
            }}
            player={player}
            onState={(state) => {
              player.playState = state;
              if (state == 'pause') setPlayerParagraph(null);
              setPlayer({ ...player });
            }}
            onMarkHover={() => {}}
            noteId={noteId}
            setPlayer={setPlayer}
            onMouseHover={() => {}}
            ref={playerRef}
          />
        </div>
      </div>
    </>
  );
}

export default NoteResult2;
