import { clsxm } from '@hidock/utils';
import { UserType } from '~/api/users/types';
import { useMyNavigate } from '~/hooks/useNavigate';
import { useUserInfo } from '~/store/user';
import { getWindow_WH, parseDoubleBracketSyntax, parseDoubleBracketSyntax2 } from '~/utils/utils';
import { useMount } from 'ahooks';
import { atom, useAtom } from 'jotai';
import { FC, ReactNode, useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ClassValue } from 'tailwind-variants';
import MyModal from '../general/Modal';
import { Avatar } from '../modal';
import { HelpCenter } from './helpCenter';
import icon from './icon';
import { Language } from './language';
import { MyHidock } from './myHidock';
import { Plans } from './plans';
import { Profile } from './profile';
import { RedemptionCode } from './redemptionCode';
import { Summary } from './summary';
import { returnOpenTemplateInfoAtom, TemplateLibrary } from './templateLibrary/templateLibrary';
import { Vocalbulary } from './vocalbulary';

export type SiderBarSelectType =
  | 'profile'
  | 'device'
  | 'plans'
  | 'vocalbulary'
  | 'language'
  | 'summary'
  | 'redemptionCode'
  | 'helpCenter'
  | 'tempalteLibrary';

export const userInfoTabAtom = atom<SiderBarSelectType>('profile');

const TabBarComp: Record<SiderBarSelectType, FC> = {
  profile: Profile,
  language: Language,
  vocalbulary: Vocalbulary,
  plans: Plans,
  device: MyHidock,
  summary: Summary,
  redemptionCode: RedemptionCode,
  helpCenter: HelpCenter,
  tempalteLibrary: TemplateLibrary,
};

function Role() {
  const { data: userInfo } = useUserInfo();
  const { t } = useTranslation();

  if (userInfo?.type == UserType.pro) {
    const words = parseDoubleBracketSyntax2(t('user.info.pro.describe'));
    const describe = words.map((i) => {
      if (i.type == 'BracketContent' && i.content == 'time')
        return <span className=" text-[#00A2AF]">{userInfo.timeLimit - userInfo.totalNoteDuration}</span>;
      return i.content;
    });
    return (
      <>
        <div className=" mt-[30px] flex items-center pl-[21px]">
          <img src="/User/time.png" className=" ml-[8px] mr-[13px] aspect-[26/31] h-[30px]" />
          <div>
            <p className=" relative text-[22px] font-[600] leading-[18.72px]">
              {t('user.identity.pro.name')}
              {userInfo?.kickstarter ? (
                <img src="/userRole/kickstarter.png" className="ml-[4px] w-[16px]" style={{ display: 'inline' }} />
              ) : (
                ''
              )}
            </p>
            {userInfo && <p className=" mt-[2px] text-[14px] font-[600] leading-[18.72px]">{describe}</p>}
          </div>
        </div>
      </>
    );
  }

  if (userInfo?.type == UserType.Membership || userInfo?.type == UserType.kickstarter) {
    return (
      <>
        <div className=" mt-[30px] flex items-center pl-[21px]">
          <img src="/User/time.png" className=" ml-[8px] mr-[13px] aspect-[26/31] h-[30px]" />
          <div>
            <p className=" relative text-[22px] font-[600] leading-[18.72px]">
              {t('user.identity.Membership.name')}
              {userInfo.type == UserType.kickstarter && (
                <img
                  src="/userRole/kickstarter.png"
                  className=" absolute left-[calc(100%+6px)] top-[2px] h-[15px] w-[13px]"
                />
              )}
            </p>
          </div>
        </div>
      </>
    );
  }

  if (userInfo?.type == UserType.free || userInfo?.type == UserType.Trail) {
    const words = parseDoubleBracketSyntax2(t('user.info.basic.describe'));
    const describe = words.map((i) => {
      if (i.type == 'BracketContent' && i.content == 'totalCount')
        return <span className=" text-[#00A2AF]">{userInfo.totalNoteCount}</span>;
      if (i.type == 'BracketContent' && i.content == 'countLimit')
        return <span className=" text-[#00A2AF]">{userInfo.countLimit}</span>;
      return i.content;
    });

    return (
      <>
        <div className=" mt-[30px] flex items-center pl-[21px]">
          <img src="/User/time2.png" className=" ml-[8px] mr-[13px] aspect-[26/31] h-[30px]" />
          <div>
            <p className=" relative text-[22px] font-[600] leading-[18.72px]">{t('user.identity.basic.name')}</p>
            {userInfo && <p className=" mt-[2px] text-[14px] leading-[18.72px]">{describe}</p>}
          </div>
        </div>
      </>
    );
  }
  return null;
}

export function Role2() {
  const { data: userInfo } = useUserInfo();
  const { t } = useTranslation();
  if (userInfo?.type == UserType.pro) {
    return (
      <>
        <p className=" relative text-[17px] font-[700] leading-[18.72px]">
          {t('user.identity.pro.name')}
          {userInfo?.kickstarter ? (
            <img src="/userRole/kickstarter.png" className="ml-[4px] w-[16px]" style={{ display: 'inline' }} />
          ) : (
            ''
          )}
        </p>
      </>
    );
  }

  if (userInfo?.type == UserType.Membership || userInfo?.type == UserType.kickstarter) {
    return (
      <>
        <p className=" relative text-[17px] font-[700] leading-[18.72px]">
          {t('user.identity.Membership.name')}
          {userInfo?.kickstarter ? (
            <img src="/userRole/kickstarter.png" className="ml-[4px] w-[16px]" style={{ display: 'inline' }} />
          ) : (
            ''
          )}
        </p>
      </>
    );
  }

  if (userInfo?.type == UserType.free || userInfo?.type == UserType.Trail) {
    return (
      <>
        <p className=" relative text-[17px] font-[700] leading-[18.72px]">{t('user.identity.basic.name')}</p>
      </>
    );
  }
  return null;
}

function TabBarItem(props: {
  onClick?: () => void;
  icon?: ReactNode;
  title: string;
  backgroundColor?: string;
  color?: string;
  className?: ClassValue;
}) {
  const { onClick, icon, title, color, backgroundColor, className } = props;
  return (
    <div
      className={clsxm(' mb-[10px] flex h-[40px] w-[310px] cursor-pointer items-center rounded-[12px]', className)}
      style={{ backgroundColor }}
      onClick={() => {
        onClick?.();
      }}
    >
      <div className="mr-[16px] flex w-[40px] justify-center">{icon}</div>
      {icon ? (
        <span className=" text-[22px] font-[600]" style={{ color }}>
          {title}
        </span>
      ) : (
        <span className=" text-[18px] font-[600]" style={{ color }}>
          {title}
        </span>
      )}
    </div>
  );
}

const ScrollHidden = styled.div`
  &::-webkit-scrollbar {
    display: none;
  }
  overflow-y: scroll;
`;

function SiderBar(props: { value: SiderBarSelectType; onChange: (value: SiderBarSelectType) => void }) {
  const { data: userInfo } = useUserInfo();
  const { t } = useTranslation();
  const header = (
    <div className=" flex w-full items-center pl-[15px]">
      <Avatar className=" mr-[14px] w-[54px]" />
      <div className="">
        <span className=" relative text-[22px] font-bold leading-[18.72px]">{userInfo?.name || ' '}</span>
        <p className=" w-[200px] break-words pt-[8px] text-[17px] text-[#00A2AF]">
          <Role2 />
        </p>
      </div>
    </div>
  );

  const tabBarConfig: {
    value: string;
    label: string;
    icon?: ReactNode;
    children?: { value: string; label: string }[];
  }[] = [
    {
      label: t('user.info.acccount'),
      value: 'account',
      icon: <img src={icon['profile']} className={clsxm(' ml-[18px] w-[20px]')} />,
      children: [
        {
          label: t('user.info.profile'),
          value: 'profile',
        },
        {
          label: t('user.info.plans'),
          value: 'plans',
        },
        {
          label: t('user.info.redemption-code'),
          value: 'redemptionCode',
        },
      ],
    },
    {
      label: t('user.info.preferences'),
      value: 'preferences',
      icon: <img src={icon.preferences} className={clsxm(' ml-[18px] w-[20px]')} />,
      children: [
        {
          label: t('user.info.language'),
          value: 'language',
        },
        {
          label: t('user.info.summary'),
          value: 'summary',
        },
        {
          label: t('user.info.vocabulary'),
          value: 'vocalbulary',
        },
        {
          label: t('user.info.template.library'),
          value: 'tempalteLibrary',
        },
      ],
    },
    {
      label: t('user.info.device'),
      value: 'my-hidock',
      icon: <img src={icon['device']} className={clsxm(' ml-[18px] w-[24px]')} />,
      children: [
        {
          label: t('user.info.my-hidock'),
          value: 'device',
        },
      ],
    },
    {
      label: t('user.info.help-center'),
      value: 'helpCenter',
      icon: <img src={icon['help']} className={clsxm(' ml-[18px] w-[24px]')} />,
      children: [
        {
          label: t('user.info.help-center'),
          value: 'helpCenter',
        },
      ],
    },
  ];

  function TabBar() {
    return (
      <ScrollHidden className=" mt-[30px] max-h-[543px] overflow-hidden">
        {tabBarConfig.map((item) => {
          //是否高亮
          let isSelect = false;
          const children = item.children;
          if (children) for (let i = 0; i < children.length; i++) if (children[i].value == props.value) isSelect = true;

          const childrenEls = item.children?.map((i) => {
            return (
              <TabBarItem
                color={props.value == i.value ? '#00A2AF' : ''}
                onClick={() => {
                  //@ts-ignore
                  props.onChange?.(i.value);
                }}
                title={i.label}
              />
            );
          });

          return (
            <>
              <TabBarItem
                icon={item.icon}
                title={item.label}
                className=" cursor-auto"
                backgroundColor={'#F0F0F0'}
                key={item.value}
              />
              {childrenEls}
            </>
          );
        })}
      </ScrollHidden>
    );
  }

  function getQuotaEl() {
    if (userInfo?.type == UserType.pro) {
      const words = parseDoubleBracketSyntax2(t('user.info.pro.describe'));
      return (
        <div className=" mb-[30px] ml-[36px] mt-[12px] flex items-center">
          <img src="/User/time.png" className=" mr-[8px] inline aspect-[17/20]  h-[20px]" />
          {userInfo && (
            <div className="mt-[2px] text-[18px] font-[600] leading-[18.72px]">
              <p className=" mb-[4px]">
                {words.map((i) => {
                  if (i.type == 'BracketContent' && i.content == 'time')
                    return <span className=" text-[#00A2AF]">{userInfo.timeLimit - userInfo.totalNoteDuration}</span>;
                  return i.content;
                })}
              </p>
            </div>
          )}
        </div>
      );
    }

    if (userInfo?.type == UserType.free || userInfo?.type == UserType.Trail) {
      const words = parseDoubleBracketSyntax2(t('user.info.basic.describe'));
      const describe = words.map((i) => {
        if (i.type == 'BracketContent' && i.content == 'totalCount')
          return <span className=" text-[#00A2AF]">{userInfo.totalNoteCount}</span>;
        if (i.type == 'BracketContent' && i.content == 'countLimit')
          return <span className=" text-[#00A2AF]">{userInfo.countLimit}</span>;
        return i.content;
      });
      return (
        <div className=" mb-[30px] ml-[36px] mt-[12px] flex items-center">
          <img src="/User/time.png" className=" mr-[8px] inline h-[20px] w-[17px]" />
          {userInfo && <p className=" mt-[2px] text-[18px] font-[600] leading-[18.72px]">{describe}</p>}
        </div>
      );
    }
    return null;
  }

  return (
    <div className=" w-[354px] min-w-[354px]">
      {header}
      {getQuotaEl()}
      {userInfo?.membershipEndDate && (
        <div className=" mt-[9px] h-[32px] w-[310px] rounded-[8px] border border-[#D9D9D9] py-[6px] text-center text-[14px] font-bold text-[#686868]">
          {parseDoubleBracketSyntax(t('user.sidebar.unlimited-until-time'), {
            time: new Intl.DateTimeFormat('default', { year: 'numeric', month: 'short', day: 'numeric' }).format(
              new Date(userInfo.membershipEndDate)
            ),
          })}
        </div>
      )}
      {TabBar()}
    </div>
  );
}

export function UserInfo() {
  const location = useLocation();
  const [tab, setTab] = useAtom(userInfoTabAtom);
  const naviage = useMyNavigate();
  const Comp = TabBarComp[tab] || null;
  const [zoomRatio, setZoomRatio] = useState(1);
  const [show, setShow] = useState(false);
  const [returnOpenTemplateInfo, setReturnOpenTemplateInfo] = useAtom(returnOpenTemplateInfoAtom);

  useLayoutEffect(() => {
    let tab = location.state?.tab;
    //@ts-ignore
    if (TabBarComp[tab]) setTab(tab);
    setShow(true);
  }, []);

  useEffect(() => {
    function exec() {
      const height = getWindow_WH().windowHeight;
      setZoomRatio(() => {
        if (height >= 790) return 1;
        return height / 790;
      });
    }
    exec();
    window.addEventListener('resize', exec);

    return () => {
      window.removeEventListener('resize', exec);
    };
  }, []);

  if (!show) return null;

  return (
    <MyModal
      className=" flex h-[790px] w-[1280px] max-w-[100vw] scale-50 rounded-[28px] bg-white p-[70px_38px_60px_37px] leading-[18.72px]"
      style={{
        transform: `scale(${zoomRatio})`,
      }}
      onClose={() => {
        if (returnOpenTemplateInfo.code) {
          setTab('tempalteLibrary');
          setReturnOpenTemplateInfo({
            code: returnOpenTemplateInfo.code,
            open: true,
          });
        } else {
          naviage(-1);
        }
      }}
    >
      <SiderBar
        value={tab}
        onChange={(val) => {
          setReturnOpenTemplateInfo(returnOpenTemplateInfoAtom.init);
          setTab(val);
        }}
      />
      {Comp && <Comp />}
    </MyModal>
  );
}
