import { Hero } from '~/components/hero';
import { useIsLargeScreen } from '~/hooks/useIsLargeScreen';
import { Outlet } from 'react-router-dom';

export const LayoutAudio = () => {
  const isLg = useIsLargeScreen();
  return (
    <div className="flex flex-col items-center overflow-hidden" style={{ height: window.innerHeight }}>
      <Hero className="mt-[88px] lg:mt-[167px]" size={isLg ? 'lg' : 'sm'} />
      <Outlet />
    </div>
  );
};
