import { request } from '~/lib/axios';
import { setGi } from '~/store/user';
import { defineMutation } from '../helper';
import { reqParamsToBody } from '../utils';

type Params = {
  email: string;
  password: string;
  password2: string;
  activateCode: string;
};

export const signUpWithEmail = defineMutation(async (params: Params) => {
  try {
    const data = await request.post<string>('/v1/user/register', reqParamsToBody(params));

    return data;
  } catch (error) {
    setGi('');
  }
});
