import { paymentSkuReq, Promotion } from '~/api/payment/paymentSku';
import GeneralContainer from '~/components/generalContainer';
import { useMyNavigate } from '~/hooks/useNavigate';
import { useUserInfo } from '~/store/user';
import { Select } from 'antd';
import { TFunction } from 'i18next';
import { useAtom, useSetAtom } from 'jotai';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import Loading from './loading';
import PaymentRight from './PaymentRight';
import { clientSecretInfoAtom, paymentSelectAtom, paymentSkuAtom, showPaySuccessAtom } from './paymentStore';
import { parseDoubleBracketSyntax } from '~/utils/utils';

const PaymentConatiner = styled.div`
  &
    > .ant-select-focused:where(.css-dev-only-do-not-override-xu9wm8).ant-select:not(.ant-select-disabled):not(
      .ant-select-customize-input
    ):not(.ant-pagination-size-changer)
    .ant-select-selector {
    border-color: rgba(0, 0, 0, 0);
  }
`;

export function getTotal(unitPrice: string, num: number) {
  const decimallen = unitPrice.split('.')[1]?.toString().length || 0;
  // debugger
  if (decimallen == 0) unitPrice = unitPrice.replace(/(\d+)$/gi, '$100');
  if (decimallen == 1) unitPrice = unitPrice.replace(/(\d+)\.(\d)$/gi, '$1$20');
  if (decimallen == 2) unitPrice = unitPrice.replace(/(\d+)\.(\d{2})$/gi, '$1$2');
  return (+unitPrice * num + '').replace(/^(\d+)?(\d{2})$/gi, '$1.$2');
}

function Translation2(t: TFunction<'translation', undefined>, key: string, prefix: string): string {
  const absKey = prefix + '.' + key;
  //@ts-ignore
  return t(absKey) == absKey ? key : t(absKey);
}

function PaymentPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [paymentSku, setPaymentSku] = useAtom(paymentSkuAtom);
  const [paymentSelect, setPaymentSelect] = useAtom(paymentSelectAtom);
  const totalPrice = paymentSelect.sku ? getTotal(paymentSelect.sku.unitPrice, paymentSelect.num) : '0';
  const [promotions, setPromotions] = useState<Promotion[]>([]);
  const { promotion } = paymentSelect;
  //设置用户名
  // const useInfo = useUserInfo();
  // useEffect(() => {
  //   const username = useInfo.data?.name;
  //   if (username && !paymentSelect.name)
  //     setPaymentSelect((state) => {
  //       return {
  //         ...state,
  //         name: username,
  //       };
  //     });
  // }, [useInfo, paymentSelect.name]);

  //设置默认支付选项
  useEffect(() => {
    paymentSkuReq().then((data) => {
      //@ts-ignore
      if (data?.error) return;
      const { skus, promotions } = data;
      setPaymentSku(skus);
      setPromotions(promotions);
      const promotion = promotions.find((i) => i.sku == skus[0].code);
      setPaymentSelect((paymentSelect) => {
        return {
          ...paymentSelect,
          sku: skus[0],
          promotion,
        };
      });
    });
  }, []);

  //计算优惠
  useEffect(() => {
    if (promotion?.giftAmount) {
      setPaymentSelect({
        ...paymentSelect,
        giftQuota: promotion.giftAmount * paymentSelect.num,
      });
    } else if (paymentSelect.sku && promotion) {
      const { num, sku } = paymentSelect;
      const { quota } = sku;
      const { giftQuantity, buyQuantity, minQuantity } = promotion;
      setPaymentSelect({
        ...paymentSelect,
        giftQuota: num < minQuantity ? 0 : quota * ((num * giftQuantity) / buyQuantity),
      });
    }
  }, [paymentSelect.num, paymentSelect.sku?.quota, promotion?.giftQuantity, promotion?.buyQuantity]);

  const selectPayInfo = useMemo(() => {
    return paymentSku.find((item) => item.code == paymentSelect.sku?.code);
  }, [paymentSelect.sku?.code]);

  function leftContent() {
    return (
      <div className="mr-[50px] flex min-h-[467px] w-[520px] flex-col">
        <p className=" mt-[20px] text-[24px] font-bold text-black">{t('payment.choose-plan')}</p>

        {paymentSku.map((item) => {
          const isSelect = item.code === paymentSelect.sku?.code;

          return (
            <div
              className=" relative mt-[12px] flex aspect-[6/1] cursor-pointer items-center rounded-[8px] bg-[#F4F6F7]"
              style={{
                borderWidth: 2,
                borderColor: isSelect ? '#00a2af' : 'transparent',
              }}
              onClick={() => {
                const promotion = promotions.find((i) => i.sku === item.code);
                setPaymentSelect({
                  ...paymentSelect,
                  sku: item,
                  promotion,
                });
              }}
              key={item.code}
            >
              <div
                className="flex items-center border-[1px] justify-center ml-[12px] aspect-square min-w-[16px]  rounded-full"
                style={{
                  borderColor: isSelect ? '#00A2AF' : 'rgb(204,204,204)',
                }}
              >
                {isSelect && (
                  <i
                    style={{
                      borderRadius: '50%',
                      display: 'inline-block',
                      width: '8px',
                      height: '8px',
                      backgroundColor: '#00A2AF',
                    }}
                  ></i>
                )}
              </div>
              <div className=" mx-[12px] flex-1">
                <p className=" text-[18px] font-bold">{item.name}</p>
                <p className=" mt-[4px] text-[14px] text-[rgb(153,153,153)] ">{item.introduce}</p>
              </div>

              <div className="justify-center text-center mr-[10px]">
                <p style={{ fontWeight: 600 }} className=" text-[22px] text-[#00A2AF]">
                  <span className="">{item.symbol}</span>
                  <span>{item.unitPrice}</span>
                </p>

                <p className="p-[0_10px] min-h-[18px] min-w-[68px] rounded-[4px] bg-[rgba(0,162,175,0.2)] text-center text-[12px] text-[#00A2AF]">
                  <span>
                    <span style={{ textDecoration: 'line-through' }} className="text-[#686868]">
                      {item.symbol}
                      {item.originalPrice}
                    </span>{' '}
                    Save {item.discount}
                  </span>
                </p>
              </div>
            </div>
          );
        })}

        <div className="mt-[16px] flex w-full items-center justify-between">
          <span className=" text-[16px] font-[600] text-black">{t('payment.quantity')}</span>
          <Select
            labelInValue
            defaultValue={paymentSelect.num}
            onChange={(value) => {
              setPaymentSelect((paymentSelect) => {
                return {
                  ...paymentSelect,
                  //@ts-ignore
                  num: value.value,
                };
              });
            }}
            options={new Array(20).fill(0).map((a, idx) => {
              return {
                label: `x${idx + 1}`,
                value: idx + 1,
              };
            })}
          />
        </div>
        <div className="mt-[12px] w-full border-b-[1px] border-[#D8D8D8]"></div>
        {paymentSelect.sku && promotion && (
          <div className="mt-[10px] flex w-full justify-between text-[16px] font-[600] text-black">
            <span>{Translation2(t, promotion.name, 'payment')}</span>
            <span>
              {paymentSelect.giftQuota} {t('misc.mins')}
            </span>
          </div>
        )}
        <div className=" mt-[12px] flex w-full justify-between text-[16px] font-[600] text-black">
          <span>{t('payment.total')}</span>
          <span> {(paymentSelect.sku?.symbol || '') + totalPrice}</span>
        </div>

        {/* <p
          className=" mt-[12px] h-[48px] text-ellipsis text-[16px] font-bold text-black"
          title={selectPayInfo?.introduce || t('payment.package')}
        >
          {selectPayInfo?.introduce || t('payment.package')}
        </p>
        <div className=" mt-[20px] flex ">
          {paymentSku.map((item, idx) => {
            const isSelect = item.code == paymentSelect.sku?.code
            return (
              <div
                className=" mr-[18px] flex h-[152px] w-[161px] cursor-pointer flex-col items-center rounded-[16px] border-[2px] shadow-[0_8px_16px_0_rgba(0,0,0,0.1)]"
                style={{
                  marginRight: paymentSku.length == idx + 1 ? 0 : '',
                  borderColor: isSelect ? '#00A2AF' : 'transparent',
                }}
                key={item.code + item.unitPrice + item.symbol}
                onClick={() => {
                  const promotion = promotions.find((i) => i.sku == item.code)
                  setPaymentSelect({
                    ...paymentSelect,
                    sku: item,
                    promotion,
                  })
                }}
              >
                <p className=" mt-[30px] flex items-center">
                  <span className=" text-[18px]">{item.name}</span>
                </p>
                <p className=" text-[32px] font-[600] text-[#00A2AF]">
                  <span className=" text-[18px]">{item.symbol}</span>
                  <span>{item.unitPrice}</span>
                </p>
                {item.discount && (
                  <p className="mb-[29px] min-h-[18px] min-w-[68px] rounded-[4px] bg-[rgba(0,162,175,0.2)] text-center text-[12px] text-[#00A2AF]">
                    Save {item.discount}
                  </p>
                )}
              </div>
            )
          })}
        </div> */}
        {/* <div className=" flex-1" /> */}
        {/* <div className="mt-[30px] flex w-full justify-between">
          <span className=" text-[16px] font-[600] text-black">
            {t('payment.quantity')}
          </span>
          <Select
            labelInValue
            defaultValue={paymentSelect.num}
            onChange={(value) => {
              setPaymentSelect((paymentSelect) => {
                return {
                  ...paymentSelect,
                  //@ts-ignore
                  num: value.value,
                }
              })
            }}
            options={new Array(20).fill(0).map((a, idx) => {
              return {
                label: `x${idx + 1}`,
                value: idx + 1,
              }
            })}
          />
        </div>
        <div className="mt-[20px] w-full border-b-[1px] border-[#D8D8D8]"></div>
        {paymentSelect.sku && promotion && (
          <div className="mt-[10px] flex w-full justify-between text-[16px] font-[600] text-black">
            <span>{Translation2(t, promotion.name, 'payment')}</span>
            <span>
              {paymentSelect.giftQuota} {t('misc.mins')}
            </span>
          </div>
        )}
        <div className=" mt-[10px] flex w-full justify-between text-[16px] font-[600] text-black">
          <span>{t('payment.total')}</span>
          <span> {(paymentSelect.sku?.symbol || '') + totalPrice}</span>
        </div> */}
      </div>
    );
  }

  function rightContent() {
    return (
      <div className=" w-[485px] rounded-[18px] mt-[66px]">
        <PaymentRight />
      </div>
    );
  }

  const from = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('from');
  }, []);

  return (
    <PaymentConatiner className=" relative max-h-screen overflow-y-auto rounded-[28px] bg-white pb-[70px] pt-[30px]">
      <div className=" absolute top-[20px] flex h-[50px] w-full justify-between">
        {from === 'plans' && (
          <span
            className=" ml-[30px] h-[32px] cursor-pointer leading-[32px] text-[#4E5A58]"
            onClick={() => navigate(-1)}
          >
            {`< ${t('payment.back')}`}
          </span>
        )}
        <h1 className=" text-[30px] font-bold">{Translation2(t, promotion?.title || '', 'payment')}</h1>
        <div
          className=" mr-[30px] flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full hover:bg-[#E8EAEA]"
          onClick={() => navigate('/notes')}
        >
          <img src="/share/close.png" className="w-[12.5px]" />
        </div>
      </div>

      <div className=" mt-[30px] flex px-[60px]">
        {leftContent()}
        {rightContent()}
      </div>
    </PaymentConatiner>
  );
}

function PaySuccessTip() {
  const [show, setShow] = useAtom(showPaySuccessAtom);
  const { t } = useTranslation();
  const naviage = useMyNavigate();

  const [paymentSelect, setPaymentSelect] = useAtom(paymentSelectAtom);
  const [paymentSku, setPaymentSku] = useAtom(paymentSkuAtom);
  const selectPayInfo = useMemo(() => {
    return paymentSku.find((item) => item.code == paymentSelect.sku?.code);
  }, [paymentSelect.sku?.code]);
  if (!show || !selectPayInfo) return null;

  return (
    <div className=" flex-shrink-1 absolute left-1/2 top-1/2 flex h-[552px] w-[890px] -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center rounded-[30px] bg-white shadow-[0px_0px_30px_0_rgba(0,0,0,0.3)]">
      <Loading />
      <p className=" mt-[50px] text-[24px] font-bold text-[#00A2AF]">{t('payment.successful')}</p>
      <p className=" mt-[6px] text-[14px]">
        {parseDoubleBracketSyntax(t('payment.successful.describe'), {
          plan: selectPayInfo.type == 'quota' ? 'quota' : 'plan',
        })}
      </p>

      <button
        className=" mt-[20px] flex h-[50px] w-[150px] items-center justify-center rounded-[8px] bg-[#00A2AF] text-[18px] font-bold text-white"
        onClick={() => {
          setShow(false);
          naviage(-1);
        }}
      >
        {t('payment.successful.button')}
      </button>
    </div>
  );
}

export default function () {
  return (
    <GeneralContainer>
      <PaymentPage />
      <PaySuccessTip />
    </GeneralContainer>
  );
}
