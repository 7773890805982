import { request } from '~/lib/axios';
import type { SharedQueryHookOptions } from '../helper';
import { defineQuery, useAPIQuery } from '../helper';
import { reqParamsToBody } from '../utils';

type Params = {
  noteId: string;
};

export type Transcription = {
  beginTime: number;
  endTime: number;
  duration: number;
  sentence: string;
  highlighted?: boolean;
  speaker: string;
};

export const transcription = ({ noteId }: Params) =>
  defineQuery(['notes.transcription', noteId], async () => {
    const data = await request.post<Transcription[]>('/v1/note/transcription', reqParamsToBody({ id: noteId }));
    return data;
  });

export const useNodeTranscription = (params: Params, options?: SharedQueryHookOptions) => {
  return useAPIQuery(transcription(params), options);
};
