import { clsxm } from '@hidock/utils';
import { helpReq, Question } from '~/api/help';
import { useMyNavigate } from '~/hooks/useNavigate';
import i18n from '~/i18n';
import { DetailedHTMLProps, HTMLAttributes, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';

function RightArror(props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return (
    <div
      {...props}
      className={clsxm(
        ' mr-[40px] flex aspect-square w-[20px] cursor-pointer items-center justify-center rounded-full bg-[var(--arrowBg)]',
        props.className
      )}
    >
      <img src="/User/right-arrow.png" className=" h-[8px] w-[5px]" />
    </div>
  );
}

const QuestionContainer = styled.div`
  --color: black;
  --arrowBg: #acacac;
  &:hover {
    --color: #00a2af;
    --arrowBg: #00a2af;
  }
`;

export function HelpCenter() {
  const { t } = useTranslation();
  const header = <div className="mb-[20px] pt-[5px] text-[28px] font-[600]">{t('user.info.help-center')}</div>;

  const navigate = useMyNavigate();

  const [questions, setQuestions] = useState<Question[]>([]);

  useEffect(() => {
    helpReq
      .helpQuestion({
        language: i18n.language,
      })
      .then((res) => {
        //@ts-ignore
        if (res?.error) return;
        setQuestions(res);
      });
  }, []);

  return (
    <div className="  overflow-auto">
      {header}
      <QuestionContainer
        className=" mb-[10px] flex h-[40px] w-[828px] items-center rounded-[12px] text-[20px] font-[600] leading-[18.72px] text-[var(--color)]"
        onClick={() => {}}
      >
        <img src="/User/chat.png" className=" mr-[10px] aspect-square w-[20px]" />
        <span className=" cursor-pointer" onClick={() => navigate('/notes/customer-support')}>
          {t('user.info.online-customers-service')}
        </span>
        <div className=" flex-1"></div>
        <RightArror onClick={() => navigate('/notes/customer-support')} />
      </QuestionContainer>
      <h2 className="mb-[20px] mt-[39px] text-[20px] font-[700] leading-[18.72px]">{t('user.info.hot-questions')}</h2>
      {questions.map((question, idx) => {
        return (
          <QuestionContainer
            className="  mb-[10px] flex h-[30px] w-[828px]  items-center rounded-[12px] text-[20px] font-[600] leading-[18.72px] text-[var(--color)]"
            key={question.title}
          >
            <div className="mr-[10px] w-[20px] text-center">{idx + 1}.</div>
            <span onClick={() => open(question.url)} className=" cursor-pointer">
              {question.title}
            </span>
            <div className=" flex-1"></div>
            <RightArror onClick={() => open(question.url)} />
          </QuestionContainer>
        );
      })}
      <QuestionContainer className=" mb-[10px] flex h-[30px] w-[828px] items-center rounded-[12px] text-[20px] font-[600] leading-[18.72px] text-[var(--color)]">
        <img src="/User/help.png" className="mr-[10px] aspect-square w-[20px]" />
        <span onClick={() => open('https://www.hidock.com/blogs/user-manual')} className=" cursor-pointer">
          {t('user.info.all-guidances')}
        </span>
        <div className=" flex-1"></div>
        <RightArror onClick={() => open('https://www.hidock.com/blogs/user-manual')} />
      </QuestionContainer>
    </div>
  );
}
