import { clsxm } from '@hidock/utils';
import * as Dialog from '@radix-ui/react-dialog';
import { motion } from 'framer-motion';
import type { FC, PropsWithChildren } from 'react';

export type ModalProps = PropsWithChildren & Dialog.DialogProps;

const Root: FC<ModalProps & { className?: string }> = ({ children, className, ...rest }) => {
  return (
    children && (
      <Dialog.Root modal open {...rest}>
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 z-[3] backdrop-blur-sm" />
          <Dialog.Content>
            <motion.div
              initial={{ opacity: 0, y: 10, x: '-50%' }}
              animate={{ opacity: 1, y: '-50%', x: '-50%' }}
              exit={{ opacity: 0, y: -10 }}
              className={clsxm(
                'fixed left-1/2 top-1/2 ',
                'rounded-3xl border border-white bg-white',
                'shadow-[0px_6px_12px_-2px_rgba(0,0,0,0.11)]',
                'p-6',
                'flex flex-col items-center',
                'z-[999]',
                className
              )}
            >
              {children}
            </motion.div>
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    )
  );
};

const Title: FC<PropsWithChildren<{ className?: string }>> = ({ className, children }) => {
  return <h3 className={clsxm('text-solid text-2xl font-semibold', className)}>{children}</h3>;
};

export const Modal = {
  Root,
  Title,
};
