import { PaymentInfo } from '~/api/payment/payment';
import { Promotion, Sku } from '~/api/payment/paymentSku';
import { paymentState } from '~/api/payment/paymentState';
import { atom } from 'jotai';

export const paymentSkuAtom = atom<Sku[]>([]);
export const promotionsAtom = atom<Promotion[]>([]);

export const clientSecretInfoAtom = atom<PaymentInfo>({
  id: '',
  clientSecret: '',
});

export const paymentStateAtom = atom<paymentState | ''>('');

export const paymentSelectAtom = atom<
  {
    sku?: Sku;
    num: number;
    name: string;
    refrechNum: number;
    giftQuota: number;
    promotion?: Promotion;
  } & { name: string }
>({
  num: 1,
  name: '',
  refrechNum: 0,
  giftQuota: 0,
});

export const showPaySuccessAtom = atom(false);
