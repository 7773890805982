import { request } from '~/lib/axios';
import { useEffect, useState } from 'react';
import { reqParamsToBody } from '../utils';

export type Params = {
  version: number;
  model: string;
};

export type DeviceLatest = {
  id: string;
  model: string;
  versionCode: string;
  versionNumber: number;
  signature: string;
  fileName: string;
  fileLength: number;
  remark: string;
  createTime: number;
  state: string;
  nextHint?: string;
  nextStep?: string;
  nextTitle?: string;
};

export function latest(params: Params) {
  return request.post<DeviceLatest>('/v2/device/firmware/latest', reqParamsToBody(params));
}

export function useLatest(params: Params) {
  const res = useState<DeviceLatest | null>(null);

  useEffect(() => {
    latest(params).then((d) => {
      res[1](d);
    });
  }, []);

  return res;
}
