import { request } from '~/lib/axios';
import { reqParamsToBody } from '../utils';

export type deviceUnbindParams = {
  deviceSn: string;
};

export function deviceUnbindReq(params: deviceUnbindParams) {
  return request.post<boolean>('/v1/user/device/unbind', reqParamsToBody(params));
}
