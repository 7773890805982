import { Note2 } from '~/api/note2s/info2';
import { Transcription2 } from '~/api/note2s/transcirption/transcriptions2';
import { Sentece } from './Transcription';

export const abbreviationName = (name: string) => {
  if (/^Speaker (\d{1,2})$/.test(name)) return `S${name.split(' ')[1]}`;
  const words = name.split(' ').filter((word) => word);
  let s = '';
  if (words.length >= 2) {
    s += words[0][0];
    s += words[1][0];
  } else {
    s = name.slice(0, 2);
  }
  return s;
};

export function getStrHash(s: string) {
  var hash = 0,
    i,
    chr;
  if (s.length === 0) return hash;
  for (i = 0; i < s.length; i++) {
    chr = s.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return Math.abs(hash);
}
