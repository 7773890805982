import { request } from '~/lib/axios';
import { reqParamsToBody } from '../utils';

type Params = {
  email: string;
  authcode: string;
  password: string;
  password2: string;
};

export function resetSaveReq(params: Params) {
  return request.post<any>('/v1/user/reset/save', reqParamsToBody(params));
}
