import { clsxm } from '@hidock/utils';
import { Fit, Layout, useRive } from '@rive-app/react-canvas';
import { motion } from 'framer-motion';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ProcessContainer } from './containers';

export const ProcessingBlock: FC<{ label: string; onCancel?: () => void; className?: string }> = ({
  label,
  onCancel,
  className,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <ProcessContainer.Root className={clsxm('flex flex-col items-center', className)}>
        <motion.div className="aspect-square w-[120px]">
          <Loading />
        </motion.div>
        <p className="mb-4">{label}</p>
        <button
          className="mb-5 flex items-center rounded-xl bg-white/[0.26] px-3 py-1 text-white"
          onClick={() => onCancel?.()}
        >
          <p id="note-processing-cancel">{t('note.processing.cancel')}</p>
        </button>
      </ProcessContainer.Root>
    </>
  );
};

export const ProcessingLabel: FC<{ label: string }> = ({ label }) => (
  <p className={clsxm('text-solid mb-[10px] text-center text-[13px] opacity-[0.53]')}>
    {/* TODO i18n */}
    Please keep the <b>Screen On</b> while {label}
  </p>
);

const Loading = () => {
  const { RiveComponent } = useRive({
    src: '/loading.riv',
    autoplay: true,
    stateMachines: 'State Machine 1',
    layout: new Layout({
      fit: Fit.Cover,
    }),
  });

  return <RiveComponent />;
};
