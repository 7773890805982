import { ProcessingLabel } from '~/components/ProcessingBlock';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Uploading } from './Uploading';

export const PageUpload = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    const tempFile = location.state?.file;

    if (tempFile) {
      setFile(tempFile);
      navigate('.', { replace: true });
    }
  }, [location.state?.file, navigate]);

  return (
    <>
      <Uploading
        file={file}
        onUploaded={(ticket) => {
          navigate('/audio/generating/' + ticket, { replace: true });
        }}
        onCancel={() => navigate(-1)}
        onError={() => navigate(-1)}
      />
      <ProcessingLabel label="uploading" />
    </>
  );
};
