import { useIsLargeScreen } from '~/hooks/useIsLargeScreen';
import { getIsLogin, useIsUserLogin } from '~/store/user';
import { isMobile, isPC } from '~/utils/utils';
import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { NotesLarge } from './NotesLarge';
import { NotesMobile } from './NotesMobile';

export const PageNotes = () => {
  // const [isLg, setIsLg] = useState(false)
  // const isLgRaw = useIsLargeScreen()

  // useEffect(() => {
  //   setIsLg(isLgRaw)
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])
  const isUserLogin = useIsUserLogin();
  if (!isUserLogin) return <Navigate to="/" />;
  return <>{!isMobile() ? <NotesLarge /> : <NotesMobile />}</>;
};
