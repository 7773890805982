import { clsxm } from '@hidock/utils';
import { FC, ReactNode } from 'react';
import { ClassValue } from 'tailwind-variants';

const GeneralContainer: FC<{
  onClick?: () => void;
  children?: ReactNode;
  className?: ClassValue;
}> = (props) => {
  const { onClick, children, className } = props;

  return (
    <div
      className="fixed bottom-0 left-0 right-0 top-0 z-10 flex items-center justify-center bg-[rgba(0,0,0,0.3)]"
      style={{
        backgroundColor: 'rgba(0,0,0,0.3)',
      }}
      onClick={(e) => {
        onClick?.();
      }}
    >
      <div className={clsxm(className)} onClick={(e) => e.stopPropagation()}>
        {children}
      </div>
    </div>
  );
};

export default GeneralContainer;
