export const IconReplay = ({ className }: { className?: string }) => {
  return (
    <svg className={className} fill="none" viewBox="0 0 20 20">
      <path
        d="M9.99967 16.6665C8.13856 16.6665 6.56217 16.0206 5.27051 14.729C3.97884 13.4373 3.33301 11.8609 3.33301 9.9998C3.33301 8.13869 3.97884 6.5623 5.27051 5.27063C6.56217 3.97896 8.13856 3.33313 9.99967 3.33313C10.958 3.33313 11.8747 3.53091 12.7497 3.92646C13.6247 4.32202 14.3747 4.88813 14.9997 5.6248V3.33313H16.6663V9.16646H10.833V7.4998H14.333C13.8886 6.72202 13.2808 6.11091 12.5097 5.66646C11.7386 5.22202 10.9019 4.9998 9.99967 4.9998C8.61079 4.9998 7.43023 5.48591 6.45801 6.45813C5.48579 7.43035 4.99967 8.61091 4.99967 9.9998C4.99967 11.3887 5.48579 12.5692 6.45801 13.5415C7.43023 14.5137 8.61079 14.9998 9.99967 14.9998C11.0691 14.9998 12.0344 14.6942 12.8955 14.0831C13.7566 13.472 14.3608 12.6665 14.708 11.6665H16.458C16.0691 13.1387 15.2775 14.3401 14.083 15.2706C12.8886 16.2012 11.5275 16.6665 9.99967 16.6665Z"
        fill="currentColor"
      />
    </svg>
  );
};
