import { clsxm } from '@hidock/utils';
import { isMobile } from '~/utils/utils';
import { motion } from 'framer-motion';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import type { VariantProps } from 'tailwind-variants';
import { tv } from 'tailwind-variants';

const heroVariant = tv({
  slots: {
    container: 'space-y-2 text-center',
    name: 'text-solid inline-block relative font-[900]',
    badge: ['bg-primary inline-block rounded-[40px] px-3 py-1 text-xs font-semibold text-white', 'absolute'],
    slogan: ['text-solid  font-bold opacity-80'],
    description: ['text-solid  font-semibold opacity-70'],
  },
  variants: {
    size: {
      lg: {
        name: 'text-[56px]',
        badge: 'right-0 top-1 translate-x-1/2',
        slogan: 'text-[28px] whitespace-nowrap',
        description: 'scale-100 lg:text-sm',
      },
      sm: {
        name: 'text-[32px]',
        badge: 'translate-y-1 translate-x-1/90',
        slogan: 'whitespace-pre text-xl text-[18px]',
        description: 'scale-[0.916] text-xs',
      },
    },
  },
  defaultVariants: {
    size: 'lg',
  },
});

export const Hero: FC<{
  className?: string;
  size: VariantProps<typeof heroVariant>['size'];
  name?: boolean;
  slogan?: boolean;
  description?: boolean;
}> = ({ className, size, name = true, slogan = true, description = true }) => {
  const { t } = useTranslation();
  const {
    container: containerSlot,
    name: nameSlot,
    badge: badgeSlot,
    slogan: sloganSlot,
    description: descriptionSlot,
  } = heroVariant({
    size,
  });
  return (
    <section
      // layout
      // layoutId="hero"
      className={containerSlot({ className })}
    >
      {name && (
        <h1 className={nameSlot()} style={{ fontFamily: 'Poppins' }}>
          {t('app.name')}
          <div className={clsxm(badgeSlot(), '3xl:text-[12px] text-[8px]')}>{isMobile() ? 'mobile' : 'desktop'}</div>
        </h1>
      )}
      {slogan && <h2 className={clsxm(sloganSlot())}>{isMobile() ? t('app.slogan-mobile') : t('app.slogan')}</h2>}
      {description && (
        <h2 className={descriptionSlot()}>{t(isMobile() ? 'app.description.mobile' : 'app.description')}</h2>
      )}
    </section>
  );
};
