import { SearchContent, SearchReq } from '~/api/note2s/search';
import { Tag } from '~/components/note/Tag';
import { TagOld } from '~/components/note/TagOld';
import tags from '~/pages/notes/noteType/tags';
import { useMyDebounce } from '~/utils/utils';
import { useFocusWithin } from 'ahooks';
import { CSSProperties, useEffect, useMemo, useRef, useState, useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

export default function () {
  const [keyword, setKeyword] = useState('');
  const keywordLatestRef = useRef(keyword);
  keywordLatestRef.current = keyword;
  const { t } = useTranslation();
  const [searchContent, setSearchContent] = useState<SearchContent[]>([]);
  const [contentStyle, setContentStyle] = useState<CSSProperties>({});
  const [isFocus, setIsFocus] = useState(false);
  const searchRef = useRef<HTMLInputElement | null>(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const searchHandle = useMyDebounce((keyword: string) => {
    if (keyword) {
      SearchReq({
        keyword: keyword,
      }).then((data) => {
        if (keywordLatestRef.current == keyword) setSearchContent(data);
      });
    } else setSearchContent([]);
  }, 100);

  useEffect(() => {
    if (!searchRef.current) return;
    const rect = searchRef.current!.getBoundingClientRect();
    setContentStyle({
      width: rect.width,
      height: isFocus && searchContent.length ? Math.min(searchContent.length, 10) * 32 : 0,
      paddingBottom: isFocus && searchContent.length ? 10 : 0,
    });
  }, [searchContent, isFocus]);
  const isShow = !/^\/notes\/search$/.test(pathname);

  useEffect(() => {
    if (isShow) searchRef.current?.focus();
  }, [isShow]);

  if (isShow) return null;
  return (
    <div className=" fixed bottom-0 left-0 right-0 top-0 z-[1000] bg-[rgba(0,0,0,0.4)]">
      <div className=" relative mx-3 mt-[8px] flex justify-center" onClick={(e) => e.stopPropagation()}>
        <div
          className=" relative mr-[10px] flex-1 bg-white"
          style={{
            borderRadius: searchContent.length ? '12px 12px 0 0' : '12px',
          }}
        >
          <input
            className="h-[32px] w-full pl-[10px] outline-none"
            style={{
              borderRadius: searchContent.length ? '12px 12px 0 0' : '12px',
            }}
            value={keyword}
            onChange={(e) => {
              setKeyword(e.target.value);
              searchHandle(e.target.value);
            }}
            ref={searchRef}
            onFocus={() => setIsFocus(true)}
          />
          <div
            className=" absolute left-0 top-full w-full overflow-y-scroll rounded-[0_0_12px_12px] bg-white"
            style={contentStyle}
          >
            {(isFocus ? searchContent : []).map((content) => {
              const { title, type, id } = content;
              //new URL(tags[type],import.meta.url).href
              let tag = tags[type] || `/white.png`;
              const contents = title.split(keyword);
              return (
                <div
                  className="hover:rgba(245,245,245,1) box-border flex h-[32px] w-full cursor-pointer items-center px-[5px] hover:bg-[rgba(245,245,245,1)]"
                  key={id}
                  onClick={(e) => {
                    console.log('点击');
                    navigate(`/notes/${id}`);
                    setSearchContent([]);
                    setKeyword('');
                  }}
                >
                  <img className="w-[18px]" src={tag} />
                  <span className=" ml-[10px] flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
                    {contents.map((text, idx) => {
                      return (
                        <>
                          <em className=" not-italic text-[#aaa]">{text}</em>
                          <em
                            className=" not-italic text-black"
                            style={{
                              display: idx + 1 == contents.length ? 'none' : '',
                            }}
                          >
                            {keyword}
                          </em>
                        </>
                      );
                    })}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
        <button className=" text-[16px] font-[600] text-white" onClick={() => navigate(-1)}>
          {t('misc.modal.cancel')}
        </button>
      </div>
    </div>
  );
}
