import { request } from '~/lib/axios';

export type DeviceList = {
  accessibility: 'public' | 'private';
  deviceSn: string;
  firmwareVersion: string;
  model: string;
  name: string;
  warranty: number;
}[];

export function deviceListReq() {
  return request.post<DeviceList>('/v1/user/device/list');
}
