import { devceBindParams, devceBindReq } from '~/api/device/deviceBind';
import { deviceRenameParams } from '~/api/device/deviceRename';
import { MyModal } from '~/components/general/myModal';
import { Loading } from '~/components/Loading';
import { myMessage } from '~/components/MyToast/MyToast';
import { generalErrorHandle, parseDoubleBracketSyntax2 } from '~/utils/utils';
import { atom, useAtom, useSetAtom } from 'jotai';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';
import { deviceStatusAtom, useDeviceInfo } from '../deviceState';
import Loading2 from './loading';

export const bindModalShowAtom = atom<'normal' | 'start' | 'rename' | 'complete' | 'warning'>('normal');

function BindStart() {
  const setBindModalShow = useSetAtom(bindModalShowAtom);
  const { t } = useTranslation();
  const header = <div className=" mt-[70px] text-center text-[32px] font-bold">{t('wu.bind.start.title')}</div>;

  const config = [
    {
      label: t('wu.bind.start.hidock-manage'),
      img: '/device/hidock-manage.png',
    },
    {
      label: t('wu.bind.start.long-audio-transcribe'),
      img: '/device/long-audio-transcribe.png',
    },
    {
      label: t('wu.bind.start.unlimited-notes'),
      img: '/device/unlimited-notes.png',
    },
  ];

  const body = (
    <div className=" mt-[79px] flex w-full justify-center px-[110px]">
      {config.map((item, idx) => {
        return (
          <div
            className=" flex flex-col items-center"
            style={{ marginRight: idx < config.length ? '50px' : '' }}
            key={item.label}
          >
            <img src={item.img} className=" h-[100px] w-[142px]" />
            <p className=" mt-[25px] whitespace-pre text-[20px] font-bold">{item.label}</p>
          </div>
        );
      })}
    </div>
  );

  const select = (
    <div className=" mt-[80px] flex w-full justify-center text-[18px] font-bold">
      <button
        className=" mr-[18px] h-[50px] w-[150px] rounded-[8px] bg-[#00A2AF] text-white"
        onClick={() => setBindModalShow('rename')}
      >
        {t('wu.bind.Bind')}
      </button>
      <button
        className=" h-[50px] w-[150px] rounded-[8px] bg-[#E8EAEA] text-[#686868]"
        onClick={() => setBindModalShow('normal')}
      >
        {t('wu.bind.Cancel')}
      </button>
    </div>
  );

  return (
    <MyModal
      className=" h-[556px] w-[890px] rounded-[30px] leading-[24px]"
      onClose={() => {
        setBindModalShow('normal');
      }}
    >
      {header}
      {body}
      {select}
      <p className=" mt-[25px] text-center text-[16px] font-bold text-[#686868]">{t('wu.bind.setRole')}</p>
    </MyModal>
  );
}

type DeviceRenameParams = {
  info: {
    name: string;
    accessibility: 'public' | 'private';
  };
  onChnage: (params: { name: string; accessibility: 'public' | 'private' }) => void;
};

function DeviceRename(props: DeviceRenameParams) {
  const setBindModalShow = useSetAtom(bindModalShowAtom);
  const [deviceInfo, setDeviceInfo] = useDeviceInfo();
  const [loading, setLoading] = useState(false);
  const setDeviceStatus = useSetAtom(deviceStatusAtom);
  const { t } = useTranslation();
  const { info, onChnage } = props;
  const header = <div className=" mt-[119px] text-center text-[32px] font-bold">{t('wu.bind.rename.title')}</div>;

  const NameInput = styled.input`
    &[type='checkbox'] {
      accent-color: #00a2af;
    }
  `;

  return (
    <MyModal
      className=" flex h-[556px] w-[890px] flex-col items-center rounded-[30px] leading-[24px]"
      onClose={() => {
        setBindModalShow('normal');
      }}
    >
      {header}
      <input
        className=" mt-[48px] box-border h-[60px] w-[486px] rounded-[10px] border border-[#ACACAC] py-[22px] text-center text-[22px] font-bold outline-none placeholder:text-[#ACACAC]"
        placeholder={t('wu.bind.start.my-home-hidock')}
        onChange={(e) => onChnage({ ...info, name: e.target.value })}
        value={info.name}
      />
      <div className=" mt-[30px] flex">
        <NameInput
          type="checkbox"
          className=" mr-[8px] h-[16px] w-[16px] cursor-pointer text-center"
          checked={info.accessibility == 'public'}
          onChange={(e) =>
            onChnage({
              ...info,
              accessibility: e.target.checked ? 'public' : 'private',
            })
          }
        />
        <p className="  text-[14px] leading-[16px] text-[#686868]">{t('wu.bind.rename.setRole')}</p>
      </div>
      <button
        className=" mt-[106px] h-[50px] w-[150px] rounded-[8px] bg-[#00A2AF] text-[18px] font-bold text-white"
        onClick={() => {
          setLoading(true);
          devceBindReq({
            deviceSn: deviceInfo.sn,
            firmwareVersion: deviceInfo.version,
            name: info.name,
            accessibility: info.accessibility,
          }).then((err) => {
            // @ts-ignore
            if (err?.error) {
              setBindModalShow('normal');
            } else {
              setDeviceStatus({
                accessibility: 'read-write',
                owner: null,
                ownership: 'mine',
                name: info.name,
              });
              setBindModalShow('complete');
            }
            setLoading(false);
          });
        }}
      >
        {loading ? <Loading /> : t('user.guide.next')}
      </button>
    </MyModal>
  );
}

function Complete() {
  const setBindModalShow = useSetAtom(bindModalShowAtom);
  const { t } = useTranslation();
  const header = <div className=" mt-[70px] text-center text-[32px] font-bold">{t('wu.bind.complete.title')}</div>;

  return (
    <MyModal
      className=" flex h-[556px] w-[890px] flex-col items-center rounded-[30px] leading-[24px]"
      onClose={() => {
        setBindModalShow('normal');
      }}
    >
      {header}
      <Loading2 />
      <p className=" mt-[15px] whitespace-pre-wrap text-center text-[20px] font-bold text-[#686868]">
        {t('wu.bind.complete.describe')}
      </p>
    </MyModal>
  );
}

function Warning() {
  const setBindModalShow = useSetAtom(bindModalShowAtom);
  const [deviceStatus] = useAtom(deviceStatusAtom);
  const { t } = useTranslation();
  const header = <div className=" mt-[90px] text-center text-[32px] font-bold">{t('misc.warning')}</div>;

  function bottomDescribe() {
    const contents = parseDoubleBracketSyntax2(t('wu.bind.warning.bottom-describe'));
    return contents.map((content) => {
      if (content.type == 'text') return content.content;
      if (content.content == 'email')
        return (
          <a href={`mailto:${content.type}`} style={{ color: 'rgb(0,0,238)', textDecoration: 'revert-layer' }}>
            {deviceStatus?.owner}
          </a>
        );
    });
  }

  return (
    <MyModal
      className=" flex h-[556px] w-[890px] flex-col items-center rounded-[30px] leading-[24px]"
      onClose={() => {
        setBindModalShow('normal');
      }}
    >
      {header}
      <div>
        <p className=" mt-[30px] whitespace-pre text-[20px] font-bold leading-[24px] text-[#686868]">
          {t('wu.bind.warning.describe')}
        </p>
        <p className=" mt-[30px] text-[20px] font-bold leading-[24px] text-[#686868]">{t('wu.bind.warning.please')}</p>
      </div>
      <p className=" mt-[30px] text-[24px] font-bold text-[#686868]">{deviceStatus?.owner}</p>
      <p className=" mt-[20px]">{bottomDescribe()}</p>
      <button
        className=" mt-[100px] h-[50px] w-[150px] rounded-[8px] bg-[#00A2AF] text-[18px] font-bold text-white"
        onClick={() => setBindModalShow('normal')}
      >
        {t('misc.ok')}
      </button>
    </MyModal>
  );
}

export function DeviceBind() {
  const [state, setState] = useAtom(bindModalShowAtom);
  const [info, setInfo] = useState<DeviceRenameParams['info']>({
    name: '',
    accessibility: 'private',
  });
  console.log('info', info);
  if (state == 'start') return <BindStart />;
  if (state == 'rename') return <DeviceRename info={info} onChnage={setInfo} />;
  if (state == 'complete') return <Complete />;
  if (state == 'warning') return <Warning />;
  return null;
}
