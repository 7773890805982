import { useMutation, useQueryClient } from '@tanstack/react-query';
import { request } from '~/lib/axios';
import { API } from '..';
import { defineMutation } from '../helper';
import { reqParamsToBody } from '../utils';

export const create = defineMutation(async (name: string) => {
  const data = await request.post('/v1/tag/create', reqParamsToBody({ name }));
  return data;
});

export const useCreateTagMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(create, {
    onSuccess() {
      queryClient.invalidateQueries(API.tags.list().key);
    },
  });
};
