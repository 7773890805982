import { clsxm } from '@hidock/utils';
import { redemptionReq, RedemptionVerifyHint } from '~/api/redemption';
import { Loading as Loading2 } from '~/components/Loading';
import { useUserInfo } from '~/store/user';
import { generalErrorHandle, parseDoubleBracketSyntax } from '~/utils/utils';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../animation/loading';
import { myMessage } from '../MyToast/MyToast';
import { CloseBtn } from './general';
import { GeneralPage } from './generalConfigPage';

export function RedemptionCode() {
  const { t } = useTranslation();
  const [info, setInfo] = useState<RedemptionVerifyHint | null>(null);
  const [quota, setQuota] = useState(0);
  const [redeemResult, setRedeemResult] = useState('');
  const [redemptionCode, setRedemptionCode] = useState('');
  const [verifyCode, setVerifyCode] = useState('');
  const [showSuccess, setShowSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const { refetch } = useUserInfo();
  function handleRedemption(target: 'redeem' | 'verify') {
    if (loading) return;
    target == 'redeem' ? setLoading(true) : setLoading2(true);
    redemptionReq
      .redemption({
        redemptionCode,
        verifyCode,
      })
      .then(async (res) => {
        //@ts-ignore
        if (res?.error) {
          //@ts-ignore
          if (res.error == 16005) {
            const info = await redemptionReq.info({ redemptionCode });
            setInfo(info);
          } else {
            //@ts-ignore
            generalErrorHandle(res, t, 2000);
          }
        } else {
          setShowSuccess(true);
          setInfo(null);
          setVerifyCode('');
          setRedemptionCode('');

          // setQuota(res)
          showRedeemResult(res.type, res.delta);

          refetch();
        }
        target == 'redeem' ? setLoading(false) : setLoading2(false);
      });
  }

  const showRedeemResult = (type: string, delta: number) => {
    let msg = '';
    if (type == 'quota') {
      msg = parseDoubleBracketSyntax(t('user.info.redemption.complete.hint'), { num: delta });
    } else {
      let years = Math.round(delta / 365);
      let ext = years > 1 ? 's' : '';
      msg = parseDoubleBracketSyntax(t('user.info.redemption.complete.hint2'), { years: years, ext: ext });
    }
    setRedeemResult(msg);
  };

  const header = <div className="text-[28px] font-[600]">{t('user.info.redemption-code')}</div>;

  const inputEl = (
    <>
      <div className=" mt-[127px] flex">
        <input
          value={redemptionCode}
          onChange={(e) => {
            setRedemptionCode(e.target.value);
            setInfo(null);
            setVerifyCode('');
          }}
          className={clsxm(
            'mr-[10px] h-[50px] w-[253px] rounded-[8px] bg-[#F3F3F3] outline-none',
            'border border-[#CDD5D6] text-[20px] font-[700] text-[#00A2AF]',
            'text-center focus-within:border-[#00A2AF] focus-within:bg-[#EFF7F7]'
          )}
          placeholder={t('user.info.redemption.Redeem.placeholder')}
        />
        {
          <button
            className=" flex h-[50px] w-[174px] items-center justify-center rounded-[8px] bg-[#E8EAEA] text-[20px] font-[700] hover:bg-[#00A2AF]  hover:text-white"
            onClick={() => handleRedemption('redeem')}
          >
            {loading ? <Loading2 className=" h-8 w-8" /> : t('user.info.redemption.Redeem.btn')}{' '}
          </button>
        }
      </div>
      {info && (
        <>
          <div className=" mt-[15px] flex">
            <input
              value={verifyCode}
              onChange={(e) => setVerifyCode(e.target.value)}
              className={clsxm(
                'mr-[10px] h-[50px] w-[253px] rounded-[8px] bg-[#F3F3F3] outline-none',
                'border border-[#CDD5D6] text-center text-[20px] font-[700] text-[rgb(156,163,175)]',
                ' placeholder:font-[400]'
              )}
              placeholder={info.hint}
            />
            <button
              className=" flex h-[50px] w-[174px] items-center justify-center rounded-[8px] bg-[#FA5B65] text-[20px] font-[700] text-white"
              onClick={() => handleRedemption('verify')}
            >
              {' '}
              {loading2 ? <Loading2 className=" h-8 w-8" /> : t('user.info.redemption.verify.btn')}{' '}
            </button>
          </div>
          <div className=" mt-[12px] whitespace-pre-wrap text-center text-[16px] font-[500] leading-[22px] text-[#686868]">
            {info.description}
          </div>
        </>
      )}
    </>
  );

  const completeEl = (
    <>
      <div className=" mt-[59px] text-center text-[30px] font-bold text-[#00A2AF]">
        {t('user.info.redemption.complete.title')}
      </div>
      <Loading className=" w-[336px]" />
      <div className="whitespace-pre-wrap text-center text-[18px] font-[600] leading-[26px]">{redeemResult}</div>

      <button
        className=" mt-[38px] h-[50px] w-[158px] rounded-[8px] bg-[#E8EAEA] text-[16px] font-bold leading-[18.72px] hover:bg-[#00A2AF] hover:text-white"
        onClick={() => setShowSuccess(false)}
      >
        {t('user.guide.Done')}
      </button>
    </>
  );

  return (
    <div className=" w-[832px]">
      {header}
      <div className=" relative mt-[30px] flex h-[443px] flex-col items-center rounded-[16px] bg-[#F7F9FA]">
        {showSuccess ? completeEl : inputEl}
      </div>
    </div>
  );
}
