import { clsxm } from '@hidock/utils';
import { FC } from 'react';
import { BtnState, DeviceContainerProps } from './deviceType';

const DeviceContainer: FC<DeviceContainerProps> = (props) => {
  const { onClick, setBtnState, children, className } = props;

  return (
    <div
      className="fixed bottom-0 left-0 right-0 top-0 z-[9999] flex items-center justify-center bg-[rgba(0,0,0,0.3)]"
      style={{
        backgroundColor: 'rgba(0,0,0,0.3)',
      }}
      onClick={(e) => {
        onClick ? onClick?.() : setBtnState?.(BtnState.connected);
      }}
    >
      <div
        className={clsxm(
          'box-border h-[840px]  max-h-[90vh] w-[1200px] max-w-[90vw] rounded-[20px] bg-white p-[20px_20px]',
          className
        )}
        onClick={(e) => e.stopPropagation()}
      >
        {children}
      </div>
    </div>
  );
};

export default DeviceContainer;
