import { notionAuth } from '~/api/conntionNotion/notionAuth';
import { NotionItem, notionList } from '~/api/conntionNotion/notionList';
import { notionStatus } from '~/api/conntionNotion/notionStatus';
import { notionTransfer } from '~/api/conntionNotion/notionTransfer';
import { useAPIQuery } from '~/api/helper';
import { queryInfo2, useInfo2 } from '~/api/note2s/info2';
import { noteTemplate } from '~/api/templateReq';
import { UserType } from '~/api/users/types';
import i18n from '~/i18n';
import { showUpgradeTipAtom } from '~/layouts/RootLayout';
import { useUserInfo } from '~/store/user';
import { sleep } from '~/utils/sleep';
import { generalErrorHandle } from '~/utils/utils';
import { useSetState } from 'ahooks';
import { getDefaultStore, useSetAtom } from 'jotai';
import { memo, ReactNode, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Loading } from '../Loading';
import { myMessage } from '../MyToast/MyToast';
import { noteStateAtom } from '../note-result2/note-result2';
import { MyTreeSelect } from '../treeSelect';
import SelectPlatform from './SelectPlatform';
import { Platform } from './type';
import { share_docs } from './utils/googleshare';
import { oneNoteShare } from './utils/oneNote';

type Props = {
  isShow: boolean;
  onExit: () => void;
  noteId: string;
  fields?: noteTemplate.detail['fields'];
};

type ShowState = 'select' | 'notion' | 'google';
function ShareBox(props: Props) {
  const { t } = useTranslation();
  const { isShow, onExit, noteId, fields } = props;
  const [notionSelectInfo, setNotionSelectInfo] = useState<NotionItem[]>([]);
  const [showState, setShowState] = useState<ShowState>('select');
  const [notionSlectId, setNotionSelectId] = useState('');
  const [pageTitle, setPageTitle] = useState('');
  const [showSelect, setShowSelect] = useState(false);
  const [loading, setLoading] = useState(false);
  const { data: info2 } = useAPIQuery(queryInfo2(noteId));
  const setShowUpgradeTip = useSetAtom(showUpgradeTipAtom);
  const { data: userInfo } = useUserInfo();
  useEffect(() => {
    if (info2?.title) setPageTitle(info2?.title);
  }, [info2?.title]);

  const exitHandle = () => {
    if (loading) return;
    setShowState('select');
    onExit();
  };

  useEffect(() => {
    const exec = () => {
      setShowSelect(false);
    };
    document.addEventListener('click', exec);

    return () => {
      document.removeEventListener('click', exec);
    };
  }, []);
  const LinkShare = `${location.protocol}//${location.host}/s/${info2?.shortId}`;
  function notionSelect() {
    return (
      <div
        className=" h-[370px] w-[600px] rounded-[18px] bg-white p-[0_22px]"
        onClick={(e) => {
          e.stopPropagation();
          setShowSelect(false);
        }}
      >
        <div className="flex h-[53px] w-full items-center border-b-[1px] border-[rgba(217,217,217,1)] text-[20px] font-bold">
          {' '}
          {t('note.share.notion.header')}
        </div>
        <p className=" mt-[28px] text-[16px] font-bold">{t('note.share.notion.to-page')}:</p>
        <MyTreeSelect
          selectKey={notionSlectId}
          options={notionSelectInfo.map((item) => {
            return {
              id: item.id,
              parentId: item.parentId,
              label: item.title,
              data: item,
            };
          })}
          onChange={(target) => {
            setNotionSelectId(target.id);
          }}
        />
        <p className=" mt-[22px] text-[16px] font-bold">{t('note.share.notion.title')}:</p>
        <input
          className="mt-[6px] box-border h-[44px] w-full rounded-[8px] border border-[rgb(172,172,172)] pl-[10px] text-[14px] outline-none"
          placeholder="Enter title"
          value={pageTitle}
          onChange={(e) => setPageTitle(e.target.value)}
        ></input>
        <div className="mt-[59px] flex justify-end ">
          <button
            className="] mr-[14px] h-[38px] w-[109px] rounded-[8px] bg-[rgba(232,234,234,1)] text-[16px] font-bold"
            onClick={() => setShowState('select')}
          >
            {t('note.share.notion.cancel')}
          </button>
          <button
            className="h-[38px] w-[109px] rounded-[8px] bg-[rgba(0,162,175,1)] text-[16px] font-bold text-white"
            onClick={() => {
              const hash: Record<string, string> = {
                zhCN: 'zh',
              };
              const language = hash[i18n.language] || i18n.language;

              notionTransfer({
                noteId: noteId,
                pageId: notionSlectId,
                title: pageTitle,
                language,
              }).then((data: any) => {
                console.log('state', data);
                if (data == null) {
                  myMessage.success(t('note.share.submitted-successfully'), 2000);
                  setShowState('select');
                } else {
                  myMessage.error(t('note.share.Submission-Failed'), 2000);
                  //@ts-ignore
                  if (data?.error) generalErrorHandle(data, t, 2000);
                }
              });
            }}
          >
            {t('note.share.notion.save')}
          </button>
        </div>
      </div>
    );
  }

  if (!isShow) return;
  const selectHandle = async (platform: Platform) => {
    const noteState = getDefaultStore().get(noteStateAtom);
    if (platform == 'notion') {
      if (userInfo?.type != UserType.pro && !noteState.isPro) {
        setShowUpgradeTip(true);
        return;
      }
      let [url, status] = await Promise.all([notionAuth(), notionStatus()]);
      if (!status) {
        window.open(url);
        while (!status) {
          await sleep(2000);
          status = await notionStatus();
        }
      }
      let list = await notionList();
      if (!list.length) {
        window.open(url);
        while (!list.length) {
          await sleep(2000);
          list = await notionList();
        }
      }
      setLoading(true);
      setNotionSelectInfo(list);
      if (list.length) setNotionSelectId(list[0].id);
      setShowState('notion');
    }

    if (platform == 'twitter') {
      let text =
        'HiNotes is an AI voice transcription and summary tool. It supports long recording with fast transcription, user real-time marks and highlighted summaries.';
      const url =
        'https://twitter.com/intent/tweet?text=' +
        encodeURIComponent(text) +
        '&url=' +
        `${encodeURIComponent(LinkShare)}`;
      window.open(url);
    }
    if (platform == 'facebook') {
      let text =
        'HiNotes is an AI voice transcription and summary tool. It supports long recording with fast transcription, user real-time marks and highlighted summaries.';
      const url =
        'https://www.facebook.com/sharer/sharer.php?quote=' +
        encodeURIComponent(text) +
        '&u=' +
        encodeURIComponent(LinkShare);
      window.open(url);
    }
    if (platform == 'linkdin') {
      const url = 'https://www.linkedin.com/sharing/share-offsite/?url=' + encodeURIComponent(LinkShare);
      window.open(url);
    }
    if (platform == 'whatsapp') {
      let text =
        'HiNotes is an AI voice transcription and summary tool. It supports long recording with fast transcription, user real-time marks and highlighted summaries. (' +
        LinkShare +
        ')';
      const url = 'https://api.whatsapp.com/send/?text=' + encodeURIComponent(text) + '&type=custom_url&app_absent=0';
      window.open(url);
    }

    if (platform == 'google docs') {
      if (userInfo?.type != UserType.pro && !noteState.isPro) {
        setShowUpgradeTip(true);
        return;
      }
      if (info2) {
        // {
        //     "title": "Persiapan Pameran Kerjaya dan Kebimbangan Kesihatan",
        //     "summary": "Panggilan berkisar tentang membincangkan demam tinggi yang tiba-tiba, kebimbangan kesihatan yang berpotensi, dan persiapan untuk acara pameran kerjaya. Butiran tentang susun atur acara, profil peserta, pilihan harga, dan diskaun promosi juga dibincangkan.",
        //     "key_information": [
        //         "🤒Permulaan tiba-tiba demam tinggi sekitar 39.6 darjah Celsius.",
        //         "😰Kebimbangan tentang demam tidak surut dengan ubat dan merasai ketidakselesaan di kepala dan perut bawah.",
        //         "🚫Tiada pengesahan COVID-19 selepas ujian.",
        //         "📅Acara pameran kerjaya dijadualkan pada 24-25 Ogos dengan syarikat terkenal seperti Singapore Airlines dan ST Engineering berpartisipasi.",
        //         "🧑Fokus pada menarik calon berumur 25-40 tahun dengan 1-7 tahun pengalaman.",
        //         "💰Butiran harga untuk ruang booth: Platinum pada $3,400, Gold dengan pilihan penyesuaian, dan Silver dengan penubuhan asas.",
        //         "🎉Diskaun promosi untuk pengesahan awal dan rujukan.",
        //         "🛠Pilihan untuk penyesuaian booth, termasuk kerjasama vendor.",
        //         "🏗Pertimbangan penubuhan booth Shell Scheme untuk acara tersebut."
        //     ],
        //     "decisions_conclusions": [
        //         "📞Tiada keputusan akhir dibuat semasa panggilan; perbincangan lanjut diperlukan mengenai penubuhan booth dan bilangan peserta.",
        //         "🗣Penekanan pada komunikasi awal untuk keutamaan booth untuk mendapatkan manfaat promosi."
        //     ],
        //     "tasks_follow_ups": [
        //         "🤝Perbincangan lanjut diperlukan mengenai penubuhan booth dan bilangan peserta yang perlu dibawa ke acara pameran kerjaya.",
        //         "📺Tindak balas tentang pemasangan TV yang berpotensi, keperluan powerpoint, pencahayaan, dll., dengan penganjur acara.",
        //         "📊Pembahagian deck persembahan selepas panggilan untuk maklumat tambahan.",
        //         "📢Peserta perlu memberitahu awal mengenai keutamaan booth untuk manfaat promosi."
        //     ],
        //     "overall_tone": "Nada percakapan adalah profesional dan kolaboratif, dengan fokus pada menangani kebimbangan kesihatan dan mempersiapkan dengan berkesan untuk acara pameran kerjaya yang akan datang. Peserta memberikan perhatian dan terlibat dalam membincangkan logistik acara dan peluang promosi."
        // }
        const res = await share_docs(JSON.parse(info2?.json), info2?.title, fields, info2?.createTime);
        if (res.status) myMessage.success(t('note.share.submitted-successfully'), 2000);
        else myMessage.error(t('note.share.Submission-Failed'), 2000);
      }
    }

    if (platform == 'oneNote') {
      if (userInfo?.type != UserType.pro && !noteState.isPro) {
        setShowUpgradeTip(true);
        return;
      }
      setLoading(true);
      const status = await oneNoteShare(
        info2?.title || '',
        document.querySelector('#speaker-note-share-container')?.innerHTML || ''
      );
      setLoading(false);
      if (status) myMessage.success(t('note.share.submitted-successfully'), 2000);
      else myMessage.error(t('note.share.Submission-Failed'), 2000);
    }
    setLoading(false);
  };

  if (!info2 || loading)
    return (
      <div className="fixed bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center bg-[rgba(0,0,0,0.3)]">
        <Loading />
      </div>
    );

  return (
    <div
      className="fixed bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center bg-[rgba(0,0,0,0.3)]"
      onClick={exitHandle}
    >
      {showState == 'select' ? (
        <SelectPlatform onClick={selectHandle} onExit={exitHandle} info2={info2} LinkShare={LinkShare} />
      ) : null}

      {showState == 'notion' ? notionSelect() : null}
    </div>
  );
}

export default memo(ShareBox);

function useMsal(): { instance: any; accounts: any } {
  throw new Error('Function not implemented.');
}
