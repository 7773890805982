import { clsxm } from '@hidock/utils';
import { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import { ClassValue } from 'tailwind-variants';

function CloseBtn(props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return (
    <div
      {...props}
      className={clsxm(
        'absolute right-[30px] top-[30px] z-[999] flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-[50%] hover:bg-[#E8EAEA]',
        props.className
      )}
    >
      <img src="/connectDevice/wrong.png" className="h-[16px] w-[16px]" />
    </div>
  );
}

export function MyModal(props: { onClose?: () => void; children?: ReactNode; className: ClassValue }) {
  return (
    <div className=" fixed left-0 top-0 z-[9999999] h-screen w-screen bg-[rgba(0,0,0,0.3)] backdrop-blur-[10px]">
      <div
        className={clsxm(
          'fixed left-1/2 top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 rounded-[24px] bg-white shadow-[0px_4px_30px_0px_rgba(0,0,0,0.3)] ',
          props.className
        )}
      >
        {props.onClose && <CloseBtn className=" right-[30px] top-[30px]" onClick={() => props.onClose?.()} />}
        {props.children}
      </div>
    </div>
  );
}
