import { request } from '~/lib/axios';
import { reqParamsToBody } from '../utils';

type Params = {
  noteId: string;
  oldName: string;
  newName: string;
};

export const speakerRename = async (params: Params) => {
  const data = await request.post('/v2/note/speaker/rename', reqParamsToBody(params));
  return data;
};
