import { useMutation } from '@tanstack/react-query';
import { request } from '~/lib/axios';
import { queryClient } from '~/main';
import { API } from '..';
import type { SharedMutationHookOptions } from '../helper';
import { defineMutation } from '../helper';
import { reqParamsToBody } from '../utils';

type Params = {
  id: string;
};

export const save = defineMutation(async (params: Params) => {
  const data = await request.post('/v1/note/save', reqParamsToBody(params));
  return data;
});

export const useSaveNoteMutation = (options?: SharedMutationHookOptions) => {
  return useMutation(save, {
    ...options,
    onSettled(data, error, variables, context) {
      options?.onSettled?.(data, error, variables, context);

      queryClient.invalidateQueries(API.notes.list().rootKey);
    },
  });
};
