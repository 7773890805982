import { request } from '~/lib/axios';
import { reqParamsToBody } from '../utils';

type Params = {
  noteId: string;
  pageId: string;
  title?: string;
  language?: string;
};

export function notionTransfer(params: Params) {
  return request.post('/v2/integration/notion/transfer', reqParamsToBody(params));
}
