import logo0 from './logo_00000.png';
import logo1 from './logo_00001.png';
import logo2 from './logo_00002.png';
import logo3 from './logo_00003.png';
import logo4 from './logo_00004.png';
import logo5 from './logo_00005.png';
import logo6 from './logo_00006.png';
import logo7 from './logo_00007.png';
import logo8 from './logo_00008.png';
import logo9 from './logo_00009.png';
import logo10 from './logo_00010.png';
import logo11 from './logo_00011.png';
import logo12 from './logo_00012.png';
import logo13 from './logo_00013.png';
import logo14 from './logo_00014.png';
import logo15 from './logo_00015.png';
import logo16 from './logo_00016.png';
import logo17 from './logo_00017.png';
import logo18 from './logo_00018.png';
import logo19 from './logo_00019.png';
import logo20 from './logo_00020.png';
import logo21 from './logo_00021.png';
import logo22 from './logo_00022.png';
import logo23 from './logo_00023.png';
import logo24 from './logo_00024.png';
import logo25 from './logo_00025.png';
import logo26 from './logo_00026.png';
import logo27 from './logo_00027.png';
import logo28 from './logo_00028.png';
import logo29 from './logo_00029.png';
import logo30 from './logo_00030.png';
import logo31 from './logo_00031.png';
import logo32 from './logo_00032.png';
import logo33 from './logo_00033.png';
import logo34 from './logo_00034.png';
import logo35 from './logo_00035.png';
import logo36 from './logo_00036.png';
import logo37 from './logo_00037.png';
import logo38 from './logo_00038.png';
import logo39 from './logo_00039.png';
import logo40 from './logo_00040.png';
import logo41 from './logo_00041.png';
import logo42 from './logo_00042.png';
import logo43 from './logo_00043.png';
import logo44 from './logo_00044.png';
import logo45 from './logo_00045.png';
import logo46 from './logo_00046.png';
import logo47 from './logo_00047.png';

const arr = [
  logo0,
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  logo7,
  logo8,
  logo9,
  logo10,
  logo11,
  logo12,
  logo13,
  logo14,
  logo15,
  logo16,
  logo17,
  logo18,
  logo19,
  logo20,
  logo21,
  logo22,
  logo23,
  logo24,
  logo25,
  logo26,
  logo27,
  logo28,
  logo29,
  logo30,
  logo30,
  logo30,
  logo31,
  logo32,
  logo33,
  logo34,
  logo35,
  logo36,
  logo37,
  logo38,
  logo39,
  logo40,
  logo41,
  logo42,
  logo43,
  logo44,
  logo45,
  logo46,
  logo47,
];

export const tagArr = arr;
const tags: Record<string, string> = {};
for (let i = 0; i < arr.length; i++) {
  tags[i] = arr[i];
}

export default tags;
