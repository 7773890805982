import { clsxm } from '@hidock/utils';
import { useRateNoteMutation } from '~/api/notes/rate';
import { Button } from '~/components/Button';
import { motion, stagger, useAnimate } from 'framer-motion';
import { useEffect, useRef, useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';

export const Rating: FC<{
  className?: string;
  noteId: string;
  onClose?: () => void;
}> = ({ className, noteId, onClose }) => {
  const { t } = useTranslation();
  const [currentScore, setScore] = useState(4);
  const [showThanks, setShowThanks] = useState(false);
  const [isRated, setIsRated] = useState(false);
  const timerRef = useRef<any>();
  const { mutate, isLoading } = useRateNoteMutation({
    onMutate() {
      setShowThanks(true);
      timerRef.current = setTimeout(() => {
        setShowThanks(false);
        setIsRated(true);
      }, 2000);
    },
  });
  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (isRated) onClose?.();
  }, [isRated]);

  useEffect(() => {
    return () => clearTimeout(timerRef.current);
  }, []);

  return (
    <div
      className={clsxm(
        'text-solid  text-center text-xs font-medium',
        'h-[67px]',
        'rounded-2xl border border-white bg-[#f6e4db] p-2',
        'flex flex-col items-center',
        isRated && 'pointer-events-none opacity-0',
        className
      )}
      onClick={(e) => e.stopPropagation()}
    >
      {!showThanks && (
        <>
          <p>{t('note.rating.title')}</p>
          <div className="mt-2 flex justify-center space-x-[18px]" ref={scope}>
            {[1, 2, 3, 4, 5].map((score) => (
              <Button
                key={score}
                onMouseEnter={(e) => {
                  if (isLoading) return;
                  e.stopPropagation();
                  setScore(score);
                }}
                onClick={(e) => {
                  if (isLoading) return;
                  e.stopPropagation();
                  setScore(score);

                  animate('button', { scale: [0.85, 1.1] }, { delay: stagger(0.05) }).then(() => {
                    noteId && setTimeout(() => mutate({ id: noteId, level: score }), 500);
                  });
                }}
              >
                <svg
                  viewBox="0 0 21 20"
                  className={clsxm('w-[28px] text-white transition-colors', score <= currentScore && 'text-orange-500')}
                >
                  <path
                    d="M10.0245 0.463526C10.1741 0.00287056 10.8259 0.00287008 10.9755 0.463525L13.0819 6.9463C13.1488 7.15232 13.3408 7.2918 13.5574 7.2918H20.3738C20.8582 7.2918 21.0596 7.9116 20.6677 8.1963L15.1531 12.2029C14.9779 12.3302 14.9046 12.5559 14.9715 12.7619L17.0779 19.2447C17.2276 19.7053 16.7003 20.0884 16.3085 19.8037L10.7939 15.7971C10.6186 15.6698 10.3814 15.6698 10.2061 15.7971L4.69153 19.8037C4.29967 20.0884 3.77243 19.7053 3.92211 19.2447L6.02849 12.7619C6.09543 12.5559 6.0221 12.3302 5.84685 12.2029L0.332272 8.1963C-0.0595852 7.9116 0.141802 7.2918 0.626164 7.2918H7.44256C7.65917 7.2918 7.85115 7.15232 7.91809 6.9463L10.0245 0.463526Z"
                    fill="currentColor"
                  />
                </svg>
              </Button>
            ))}
          </div>
        </>
      )}
      {showThanks && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="flex flex-col items-center"
        >
          <svg className="mt-[3px]" width="47" height="23" viewBox="0 0 47 23" fill="none">
            <g filter="url(#filter0_bdi_1361_5020)">
              <path
                d="M6.69484 1.96454C6.88653 1.55177 7.47335 1.55177 7.66504 1.96454L8.82247 4.45683C8.90041 4.62465 9.05954 4.74026 9.24322 4.76252L11.9712 5.09315C12.423 5.1479 12.6043 5.706 12.271 6.01586L10.2584 7.88681C10.1228 8.01278 10.0621 8.19985 10.0977 8.38142L10.6262 11.0781C10.7137 11.5247 10.239 11.8696 9.84129 11.6483L7.43998 10.3123C7.27829 10.2224 7.08159 10.2224 6.91991 10.3123L4.51859 11.6483C4.12089 11.8696 3.64615 11.5247 3.73368 11.0781L4.26223 8.38142C4.29782 8.19985 4.23704 8.01278 4.10152 7.88681L2.08888 6.01586C1.75555 5.706 1.93688 5.1479 2.38868 5.09315L5.11666 4.76252C5.30034 4.74026 5.45947 4.62464 5.53741 4.45683L6.69484 1.96454Z"
                fill="#FB923C"
                fillOpacity="0.4"
                shapeRendering="crispEdges"
              />
            </g>
            <g filter="url(#filter1_bdi_1361_5020)">
              <path
                d="M39.335 1.96454C39.5267 1.55177 40.1135 1.55177 40.3052 1.96454L41.4626 4.45683C41.5405 4.62465 41.6997 4.74026 41.8834 4.76252L44.6113 5.09315C45.0631 5.1479 45.2445 5.706 44.9111 6.01586L42.8985 7.88681C42.763 8.01278 42.7022 8.19985 42.7378 8.38142L43.2663 11.0781C43.3539 11.5247 42.8791 11.8696 42.4814 11.6483L40.0801 10.3123C39.9184 10.2224 39.7217 10.2224 39.56 10.3123L37.1587 11.6483C36.761 11.8696 36.2863 11.5247 36.3738 11.0781L36.9024 8.38142C36.938 8.19985 36.8772 8.01278 36.7417 7.88681L34.729 6.01586C34.3957 5.706 34.577 5.1479 35.0288 5.09315L37.7568 4.76252C37.9405 4.74026 38.0996 4.62464 38.1775 4.45683L39.335 1.96454Z"
                fill="#FB923C"
                fillOpacity="0.4"
                shapeRendering="crispEdges"
              />
            </g>
            <g filter="url(#filter2_bdi_1361_5020)">
              <path
                d="M32.0229 3.64559C32.2588 3.13757 32.9811 3.13757 33.217 3.64559L34.6415 6.71303C34.7374 6.91957 34.9333 7.06186 35.1594 7.08926L38.5169 7.49619C39.0729 7.56358 39.2961 8.25046 38.8859 8.63183L36.4088 10.9345C36.242 11.0896 36.1672 11.3198 36.211 11.5433L36.8615 14.8622C36.9692 15.4119 36.3849 15.8364 35.8955 15.5641L32.94 13.9198C32.741 13.8091 32.4989 13.8091 32.2999 13.9198L29.3444 15.5641C28.855 15.8364 28.2707 15.4119 28.3784 14.8622L29.0289 11.5433C29.0727 11.3198 28.9979 11.0896 28.8311 10.9345L26.354 8.63183C25.9438 8.25046 26.1669 7.56358 26.723 7.49619L30.0805 7.08926C30.3066 7.06186 30.5024 6.91957 30.5984 6.71303L32.0229 3.64559Z"
                fill="#FB923C"
                fillOpacity="0.6"
                shapeRendering="crispEdges"
              />
            </g>
            <g filter="url(#filter3_bdi_1361_5020)">
              <path
                d="M13.7829 3.64559C14.0188 3.13757 14.7411 3.13757 14.977 3.64559L16.4015 6.71303C16.4974 6.91957 16.6933 7.06186 16.9194 7.08926L20.2769 7.49619C20.8329 7.56358 21.0561 8.25046 20.6459 8.63183L18.1688 10.9345C18.002 11.0896 17.9272 11.3198 17.971 11.5433L18.6215 14.8622C18.7292 15.4119 18.1449 15.8364 17.6555 15.5641L14.7 13.9198C14.501 13.8091 14.2589 13.8091 14.0599 13.9198L11.1044 15.5641C10.615 15.8364 10.0307 15.4119 10.1384 14.8622L10.7889 11.5433C10.8327 11.3198 10.7579 11.0896 10.5911 10.9345L8.11403 8.63183C7.70378 8.25046 7.92696 7.56358 8.48302 7.49619L11.8405 7.08926C12.0666 7.06186 12.2625 6.91957 12.3584 6.71303L13.7829 3.64559Z"
                fill="#FB923C"
                fillOpacity="0.6"
                shapeRendering="crispEdges"
              />
            </g>
            <g filter="url(#filter4_di_1361_5020)">
              <path
                d="M22.8469 4.72614C23.1049 4.1705 23.8949 4.17049 24.1529 4.72614L26.051 8.81321C26.1559 9.03912 26.3701 9.19475 26.6174 9.22472L31.0909 9.76691C31.6991 9.84062 31.9432 10.5919 31.4945 11.009L28.194 14.0772C28.0116 14.2467 27.9298 14.4986 27.9777 14.743L28.8444 19.1651C28.9623 19.7664 28.3232 20.2307 27.7878 19.9328L23.8499 17.742C23.6323 17.6209 23.3675 17.6209 23.1499 17.742L19.212 19.9328C18.6766 20.2307 18.0375 19.7664 18.1554 19.1652L19.0221 14.743C19.07 14.4986 18.9882 14.2467 18.8058 14.0772L15.5053 11.009C15.0566 10.5919 15.3007 9.84062 15.9089 9.76691L20.3824 9.22472C20.6297 9.19475 20.8439 9.03912 20.9488 8.81321L22.8469 4.72614Z"
                fill="#FB923C"
              />
            </g>
            <defs>
              <filter
                id="filter0_bdi_1361_5020"
                x="0.491195"
                y="0.694968"
                width="13.3775"
                height="13.8748"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.48" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1361_5020" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="1.42629" />
                <feGaussianBlur stdDeviation="0.713143" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.984314 0 0 0 0 0.572549 0 0 0 0 0.235294 0 0 0 0.11 0" />
                <feBlend mode="normal" in2="effect1_backgroundBlur_1361_5020" result="effect2_dropShadow_1361_5020" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1361_5020" result="shape" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="1.06971" />
                <feGaussianBlur stdDeviation="0.534857" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.39 0" />
                <feBlend mode="normal" in2="shape" result="effect3_innerShadow_1361_5020" />
              </filter>
              <filter
                id="filter1_bdi_1361_5020"
                x="33.1313"
                y="0.694968"
                width="13.3775"
                height="13.8748"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.48" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1361_5020" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="1.42629" />
                <feGaussianBlur stdDeviation="0.713143" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.984314 0 0 0 0 0.572549 0 0 0 0 0.235294 0 0 0 0.11 0" />
                <feBlend mode="normal" in2="effect1_backgroundBlur_1361_5020" result="effect2_dropShadow_1361_5020" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1361_5020" result="shape" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="1.06971" />
                <feGaussianBlur stdDeviation="0.534857" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.39 0" />
                <feBlend mode="normal" in2="shape" result="effect3_innerShadow_1361_5020" />
              </filter>
              <filter
                id="filter2_bdi_1361_5020"
                x="20.3831"
                y="-2.49541"
                width="24.4736"
                height="23.9042"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.88" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1361_5020" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="1.75543" />
                <feGaussianBlur stdDeviation="0.877714" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.984314 0 0 0 0 0.572549 0 0 0 0 0.235294 0 0 0 0.17 0" />
                <feBlend mode="normal" in2="effect1_backgroundBlur_1361_5020" result="effect2_dropShadow_1361_5020" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1361_5020" result="shape" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="1.31657" />
                <feGaussianBlur stdDeviation="0.658286" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.39 0" />
                <feBlend mode="normal" in2="shape" result="effect3_innerShadow_1361_5020" />
              </filter>
              <filter
                id="filter3_bdi_1361_5020"
                x="2.14308"
                y="-2.49541"
                width="24.4736"
                height="23.9042"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.88" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1361_5020" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="1.75543" />
                <feGaussianBlur stdDeviation="0.877714" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.984314 0 0 0 0 0.572549 0 0 0 0 0.235294 0 0 0 0.17 0" />
                <feBlend mode="normal" in2="effect1_backgroundBlur_1361_5020" result="effect2_dropShadow_1361_5020" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1361_5020" result="shape" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="1.31657" />
                <feGaussianBlur stdDeviation="0.658286" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.39 0" />
                <feBlend mode="normal" in2="shape" result="effect3_innerShadow_1361_5020" />
              </filter>
              <filter
                id="filter4_di_1361_5020"
                x="13.3547"
                y="4.30939"
                width="20.2904"
                height="19.5561"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="1.92" />
                <feGaussianBlur stdDeviation="0.96" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0.992157 0 0 0 0 0.729412 0 0 0 0 0.454902 0 0 0 0.2 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1361_5020" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1361_5020" result="shape" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="1.44" />
                <feGaussianBlur stdDeviation="0.72" />
                <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.39 0" />
                <feBlend mode="normal" in2="shape" result="effect2_innerShadow_1361_5020" />
              </filter>
            </defs>
          </svg>
          <p className="mt-2">{t('rating.thank')}</p>
        </motion.div>
      )}
    </div>
  );
};
