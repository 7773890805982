import { assign } from './assign';
import { deleteNote } from './delete';
import { getByTicket } from './getByTicket';
import { info } from './info';
import { list } from './list';
import { recentlyList } from './recentlyList';
import { mindmap } from './mindmap';
import { rate } from './rate';
import { transcription } from './transcription';
import { upload } from './upload';

export const notes = {
  assign,
  delete: deleteNote,
  getByTicket,
  info,
  list,
  recentlyList,
  mindmap,
  rate,
  transcription,
  upload,
};
