import { request } from '~/lib/axios';
import { FC, SVGProps } from 'react';
import { reqParamsToBody } from './utils';

function create(params: { name: string }) {
  return request.post('/v1/folder/create', reqParamsToBody(params));
}

function modify(params: { folderId: string; name: string }) {
  return request.post('/v1/folder/rename', reqParamsToBody(params));
}

function remove(params: { folderId: string }) {
  return request.post('/v1/folder/remove', reqParamsToBody(params));
}

function list() {
  return request.post<Folder.FolderInfo[]>('/v1/folder/list');
}

function assignNote(params: { noteId: string; folderId: string }) {
  return request.post('/v1/folder/assign', reqParamsToBody(params));
}

export declare module Folder {
  type FolderInfo = {
    id: string;
    userId: string;
    icon: string | null;
    type: string;
    name: string;
    noteCount: number;
  };
}

export const folderReq = {
  name: 'folderReq',
  create,
  modify,
  remove,
  list,
  assignNote,
};
