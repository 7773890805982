import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import './i18n';
import { atom, getDefaultStore, useAtomValue } from 'jotai';
import VConsole from 'vconsole';
import ChangePassword, { ChangePasswordMobile } from './components/changePassword/changePassword';
import { DeviceGlobalShow, DeviceUpdatePage } from './components/deviceConnection/DeviceConnection';
import { Loading } from './components/Loading';
import { NoteModal } from './components/modal';
import ProductLog from './components/productLog';
import { CustomerSupport } from './components/userInfo/customerSupport';
import { LayoutNotes } from './layouts/NotesLayout';
import { LayoutPolicy } from './layouts/PolicyLayout';
import { LayoutAudio } from './layouts/ProcessLayout';
import { LayoutRoot } from './layouts/RootLayout';
import { PageGenerating } from './pages/generating';
import { PageImage } from './pages/image';
import { PageIndex } from './pages/index';
import { PageNotes } from './pages/notes';
import PaymentPage from './pages/payment/PaymentPage';
import { PageFAQ } from './pages/policy/FAQ';
import { PagePrivacyPolicy } from './pages/policy/PrivacyPolicy';
import { PageTermOfService } from './pages/policy/TermOfService';
import { PageRecord } from './pages/record';
import { PageResult } from './pages/result';
import { PageUpload } from './pages/upload';
import { useUserInfo } from './store/user';
import { isMobile } from './utils/utils';

import.meta.env.VITE_CONSOLE && new VConsole();

// @ts-expect-error ignore
window.recaptchaOptions = {
  useRecaptchaNet: true,
};

export const router = createBrowserRouter([
  {
    path: '/',
    element: <LayoutRoot />,
    children: [
      {
        index: true,
        element: <PageIndex />,
      },
      {
        path: 'audio',
        element: <LayoutAudio />,
        children: [
          { path: 'record', element: <PageRecord /> },
          { path: 'upload', element: <PageUpload /> },
          { path: 'generating/:ticket', element: <PageGenerating /> },
        ],
      },
      {
        path: 'audio',
        children: [{ path: 'result/:noteId', element: <PageResult /> }],
      },
      {
        path: 'notes',
        element: (
          <LayoutNotes>
            <PageNotes />
          </LayoutNotes>
        ),
        children: [
          {
            path: 'search',
            element: <span />,
          },
          {
            path: 'changepassword',
            element: isMobile() ? <ChangePasswordMobile /> : <ChangePassword />,
          },
          {
            path: ':noteId',
            element: <NoteModal />,
          },
          {
            path: 'payment',
            element: <PaymentPage />,
          },
          {
            path: 'device',
            element: <DeviceGlobalShow />,
            children: [
              {
                index: true,
                element: null,
              },
              {
                path: 'update',
                element: <DeviceUpdatePage />,
              },
            ],
          },
          {
            path: 'productLog',
            element: <ProductLog />,
          },
          {
            path: 'customer-support',
            element: <CustomerSupport />,
          },
          {
            path: 'productLog',
            element: <ProductLog />,
          },
        ],
      },
    ],
  },
  {
    path: 'image/:noteId',
    element: <PageImage />,
  },
  {
    element: <LayoutPolicy />,
    children: [
      { path: '/privacy-policy', element: <PagePrivacyPolicy /> },
      { path: '/term-of-service', element: <PageTermOfService /> },
      { path: '/faq', element: <PageFAQ /> },
    ],
  },
]);

export const appLoadingAtom = atom(false);
export const setAppLoading = (loading: boolean) => {
  getDefaultStore().set(appLoadingAtom, loading);
};
export const App = () => {
  const { isLoading } = useUserInfo();
  const appLoading = useAtomValue(appLoadingAtom);

  if (isLoading || appLoading)
    return (
      <div className="fixed inset-0 flex items-center justify-center">
        <Loading />
      </div>
    );

  return <RouterProvider router={router} />;
};
