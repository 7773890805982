import { useFocus } from '~/hooks/useFocus';
import i18n from '~/i18n';
import { MoreThanSvg } from '~/svg/svg';
import { transform } from 'framer-motion';
import { useEffect, useState } from 'react';

const langs = [
  {
    lang: 'en',
    name: 'English',
    icon: 'lang-en.png',
  },
  {
    lang: 'ja',
    name: '日本語',
    icon: 'lang-ja.png',
  },
  {
    lang: 'zhCN',
    name: '繁体中文',
    icon: 'lang-zh.png',
  },
];

export function LanguageSelectMobile(props: { direction: 'left' | 'right'; scale?: number }) {
  const { scale = 1 } = props;
  const [show, setShow] = useState(false);

  const [selectLang, setSelectLang] = useState(i18n.language);
  const clickState = (lang: string) => {
    localStorage.setItem('userLang', lang);
    setSelectLang(lang);
    setShow(false);
    i18n.changeLanguage(lang);
  };

  const targetClick = useFocus((state) => {
    setShow(state == 'blur' ? false : true);
  });
  let cur = langs.find((item) => item.lang == selectLang);
  return (
    <span className=" relative z-10">
      <span
        className={`origin-center cursor-pointer text-[14px]`}
        style={{ transform: `scale(${scale})` }}
        onClick={() => targetClick()}
      >
        <img
          src={cur!.icon}
          style={{
            width: '18px',
            height: '18px',
            display: 'inline',
            marginRight: '4px',
            verticalAlign: 'text-bottom',
          }}
        />
        {cur!.name}
      </span>
      <div
        className=" absolute right-0 top-[calc(100%+10px)] w-[150px] rounded-[4px] bg-white"
        style={{
          boxShadow: '0 0 4px rgba(0,0,0,0.2)',
          display: show ? '' : 'none',
          left: props.direction == 'left' ? 0 : '',
          right: props.direction == 'right' ? 0 : '',
        }}
      >
        {langs.map((item, idx) => {
          return (
            <div
              className="flex h-[38px] w-full cursor-pointer items-center hover:bg-[#F5F5F5] "
              key={item.lang}
              onClick={() => clickState(item.lang)}
            >
              <span className={' ml-[16px] mr-[12px] origin-center shadow-[0_0_5px_rgba(0,0,0,0.3)]'}>
                <img src={item.icon} style={{ width: '20px', height: '20px' }} />
              </span>
              <span className="pl-[10px]">{item.name}</span>
            </div>
          );
        })}
      </div>
    </span>
  );
}

export function LanguageSelect() {
  const [show, setShow] = useState(false);

  const [selectLang, setSelectLang] = useState(i18n.language);
  const clickState = (lang: string) => {
    localStorage.setItem('userLang', lang);
    setSelectLang(lang);
    setShow(false);
    i18n.changeLanguage(lang);
  };

  const targetClick = useFocus((state) => {
    setShow(state == 'blur' ? false : true);
  });
  const select = () => {
    return (
      <div className=" scrollbar absolute bottom-0 left-0 max-h-[200px] w-full cursor-pointer overflow-auto rounded-[10px] bg-white">
        {langs.map((item, idx) => {
          return (
            <div
              key={item.lang}
              className=" flex h-[40px] w-full items-center pl-[15px] hover:bg-[#F5F5F5]"
              onClick={() => {
                targetClick();
                clickState(item.lang);
              }}
            >
              <span className={'fi fi-' + item.icon} />
              <span className="pl-[10px]">{langs[idx].name}</span>
            </div>
          );
        })}
      </div>
    );
  };
  const selectItem = langs.find((i) => i.lang == selectLang) || langs[0];
  return (
    <div className=" relative h-[40px] w-[210px] rounded-[10px] bg-white  ">
      <div
        className="relative flex h-[40px] w-full cursor-pointer items-center pl-[15px]"
        onClick={() => targetClick()}
      >
        <span className={'fi fi-' + selectItem?.icon} />
        <span className="rounded-[5px] pl-[10px]">{selectItem.name}</span>
      </div>
      {show && select()}
      <div
        className=" absolute right-[12px] top-[9px] flex h-[22px] w-[22px] cursor-pointer items-center justify-center rounded-full bg-[#F0F0F0]"
        onClick={() => targetClick()}
      >
        <MoreThanSvg
          className="aspect-[6/9] w-[7px] origin-center -rotate-90 duration-300"
          style={{ transform: show ? 'rotate(90deg)' : '' }}
        />
      </div>
    </div>
  );
}
