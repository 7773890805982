export const IconCredit = ({ className }: { className?: string }) => {
  return (
    <svg className={className} viewBox="0 0 12 13" fill="none">
      <g opacity="0.7">
        <path
          d="M10.231 6.88477H1.76925C1.3444 6.88477 1 7.22917 1 7.65401V10.731C1 11.1558 1.3444 11.5002 1.76925 11.5002H10.231C10.6558 11.5002 11.0002 11.1558 11.0002 10.731V7.65401C11.0002 7.22917 10.6558 6.88477 10.231 6.88477Z"
          stroke="currentColor"
          strokeWidth="0.769247"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.06921 7.33063L2.4077 2.09975C2.44596 1.93013 2.54055 1.77847 2.67606 1.6695C2.81156 1.56054 2.97999 1.5007 3.15387 1.49974H8.8463C9.02412 1.4954 9.19795 1.55281 9.33821 1.66221C9.47846 1.7716 9.57647 1.92623 9.61555 2.09975L10.954 7.33063M3.30772 9.19221H5.61546"
          stroke="currentColor"
          strokeWidth="0.769247"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.69248 9.57686C8.90491 9.57686 9.07711 9.40466 9.07711 9.19224C9.07711 8.97982 8.90491 8.80762 8.69248 8.80762C8.48006 8.80762 8.30786 8.97982 8.30786 9.19224C8.30786 9.40466 8.48006 9.57686 8.69248 9.57686Z"
          stroke="currentColor"
          strokeWidth="0.769247"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
