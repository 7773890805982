import { throttle } from '~/utils/utils';
import { ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export function Select2(props: {
  select?: string;
  showNull?: ReactNode;
  onChange?: (info: { label: string; value: string }) => void;
  onClose?: () => void;
  data: {
    name: string;
    children: {
      label: string;
      value: string;
    }[];
  }[];
}) {
  const [selectLetter, setSelectLetter] = useState('A');
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [keyword, setKeyword] = useState('');
  const { t } = useTranslation();

  const searchHeader = (
    <div className=" flex w-full justify-center pb-[25px] pt-[29px]">
      <div className=" box-border flex h-[40px] w-[400px] rounded-[10px] bg-[#F0F0F0] px-[20px] py-[10px]">
        <img src="/device/search.png" className=" h-[20px] w-[20px]" />
        <input
          className=" ml-[18px] flex-1 bg-transparent text-[18px] font-bold leading-[21.06px] outline-none placeholder:text-[#BDBDBD]"
          placeholder={t('user.info.search')}
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
      </div>
    </div>
  );

  const data = useMemo(() => {
    const temp: typeof props.data = [];
    props.data.forEach((item) => {
      const children = item.children.filter((i) => new RegExp(keyword, 'i').test(i.label));
      if (children.length) {
        temp.push({
          name: item.name,
          children,
        });
      }
    });
    return temp;
  }, [props.data, keyword]);

  const letterSelect = () => {
    return (
      <div className=" fixed right-[31px] top-[104px] z-[5] flex w-[14px] flex-col items-center text-[14px] font-[400] leading-[21px]">
        {letters.map((letter) => {
          return (
            <span
              className=" cursor-pointer"
              style={{
                color: selectLetter == letter ? '#00A2AF' : '',
                fontWeight: selectLetter == letter ? 'bold' : '',
              }}
              key={letter}
              onClick={() => {
                const target = containerRef.current!;
                let arr = Array.from(target.querySelectorAll<HTMLDivElement>('#letter'));
                const letterEl = arr.find((i) => i?.dataset?.letter == letter)!;
                target.scrollTo(0, letterEl.offsetTop);
              }}
            >
              {letter}
            </span>
          );
        })}
      </div>
    );
  };

  const letters = data.map((i) => i.name);
  const selectContent = data.map((item) => {
    return (
      <div
        className=" box-border w-full pb-[12px] pl-[40px] pr-[30px]"
        key={item.name}
        style={{ background: props.select?.[0] == item.name ? '#F7F9FA' : '' }}
      >
        <p className=" pb-[21px] pt-[24px] text-left text-[24px] font-[600]" data-letter={item.name} id="letter">
          {item.name}
        </p>
        <div className="flex flex-wrap">
          {item.children.map((item) => {
            if (item.value == props.select) {
              return (
                <div
                  className="mb-[12px] mr-[10px] cursor-pointer rounded-[6px] bg-[#EFF6F6] p-[12px_15px] text-[18px] text-[#00A2AF]"
                  key={item.value}
                >
                  {item.label}
                </div>
              );
            }

            return (
              <div
                className=" mb-[12px] mr-[10px] cursor-pointer rounded-[6px] bg-[#E8EAEA] p-[12px_15px] text-[18px]"
                key={item.value}
                onClick={() => {
                  props.onChange?.(item);
                }}
              >
                {item.label}
              </div>
            );
          })}
        </div>
      </div>
    );
  });

  const scrollEvent = useCallback(
    throttle(
      () => {
        const container = containerRef.current!;
        let arr = Array.from(container.querySelectorAll<HTMLDivElement>('#letter'));
        for (const item of arr) {
          if (item.offsetTop + 1 > container.scrollTop) {
            //@ts-ignore
            setSelectLetter(item.dataset.letter);
            break;
          }
        }
      },
      100,
      {
        immediatelyExec: false,
      }
    ),
    []
  );

  const showSelect = (
    <div className=" fixed left-1/2 top-1/2 z-[20] box-border h-[690px] w-[871px] -translate-x-1/2 -translate-y-1/2 overflow-hidden rounded-[28px] bg-white leading-[18.72px] shadow-[0px_4px_50Px_0_rgba(0,0,0,0.4)]">
      {searchHeader}
      <div
        className=" absolute right-[30px] top-[30px] z-20 flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-[50%] hover:bg-[#E8EAEA]"
        onClick={() => {
          props.onClose?.();
        }}
      >
        <img src="/connectDevice/wrong.png" className="h-[16px] w-[16px]" />
      </div>
      {letterSelect()}
      <div className=" relative h-[594px] overflow-y-scroll" ref={containerRef} onScroll={scrollEvent}>
        {selectContent}
      </div>
    </div>
  );

  return showSelect;
}
