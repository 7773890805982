import { clsxm } from '@hidock/utils';
import { useAPIQuery } from '~/api/helper';
import { info2, Note2, queryInfo2, useInfo2 } from '~/api/note2s/info2';
import { rerunReq } from '~/api/note2s/rerun';
import { changeTitle } from '~/api/note2s/summary/changeTitle';
import { updateSummary } from '~/api/note2s/summary/updateSummary';
import { templateReq } from '~/api/templateReq';
import { sleep } from '~/utils/sleep';
import { generalErrorHandle, pasteRich } from '~/utils/utils';
import { useRequest } from 'ahooks';
import { atom, getDefaultStore, useAtom, useSetAtom } from 'jotai';
import { Key } from 'lucide-react';
import React, { FocusEventHandler, MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';
import { myMessage } from '../MyToast/MyToast';
import { NoteListCardDataRefetchList } from '../note/List';
import CopyBtn from './copyBtn';
import Loading from './Loading/Loading';
import { selectionInfoAtom } from './markingWords/selectionBtn';
import { custom_dictionary_dom_class, sentencesAtom, SpeakerNoteGlobalData } from './note-result2';
import css from './note-result2.module.css';

export const summaryKey = [
  'key_info',
  'key_information',

  'summary',
  'key_learnings',
  'core_message',

  'voiceMark',

  'outline',
  'action_items',
  'summary_extraction',
  'key_outline',
  'homework_assignment',
  'todo_list',
  'to_do_list',
  'actionable_insights',
  'questions_or_clarifications',
  'important_concepts',
  'homework_and_assignments',
  'upcoming_tests_or_quizzes',
  'decisions_conclusions',
  'tasks_follow_ups',
  'overall_tone',
];

type ListData = (ListData | string)[];
type EditBoxProps = {
  data: {
    title: string;
    content: ListData | string;
  };
  event?: {
    onInput?: (content: string | string[]) => void;
    onBlur?: (content: string | ListData) => void;
  };
  index: number;
  hasEditor?: boolean;
};

const ListContainer = styled.ol`
  & li {
    margin-left: 14px;
  }
`;

function EditBox(props: EditBoxProps) {
  const { data, event = {}, index, hasEditor } = props;
  const isList = data.content instanceof Array;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const ListEl = (content: ListData) => {
    function getListHTMLString(
      data: ListData | string,
      options: {
        deep?: number;
      } = {}
    ) {
      const { deep = 0 } = options || {};
      if (typeof data == 'string') {
        return `<li id="item-${deep}">${data}</li>`;
      } else {
        const content = data as ListData;
        let text = '';
        for (let i = 0; i < content.length; ) {
          const item = content[i];
          const nextItem = content[i + 1];

          let childrenText = '';

          if (i + 1 < content.length && typeof item == 'string' && nextItem instanceof Array) {
            childrenText = getListHTMLString(nextItem, {
              deep: deep + 1,
            });

            childrenText = `<ul style="list-style-type: square; margin-left: ${
              20 * (deep + 1)
            }px;" id="list-${deep + 1}">
                                ${childrenText}
                        </ul>`;

            i++;
          }

          text += `
                        <li id="item-${deep}">
                            ${item}
                            ${childrenText}
                        </li>
                        `;
          i++;
        }
        return text;
      }
    }

    let HTMLText = getListHTMLString(content);

    return (
      <ListContainer
        className="text-[20 px] ml-[1em] list-decimal rounded-[10px]  border border-transparent p-[10px] pl-[20px] pt-0 outline-none focus-visible:border-white"
        contentEditable={hasEditor}
        suppressContentEditableWarning
        id="list-0"
        key="list-0"
        onBlur={(e) => {
          const container = containerRef.current!;

          type Node = {
            text?: string;
            children: Node[];
          };

          function dfs(dom: Element, deep = 0) {
            const node: Node = {
              children: [],
            };
            const firstChild = dom.firstChild;
            if (firstChild && firstChild.nodeType == 3) node.text = firstChild.textContent?.trim() || '';
            const list = Array.from(dom.querySelectorAll(`#item-${deep}`));
            for (let i = 0; i < list.length; i++) {
              const child = dfs(list[i], deep + 1);
              node.children.push(child);
            }
            return node;
          }
          const root = dfs(container.querySelector('ol') as Element);

          function getArr(node: Node) {
            const { children } = node;
            const arr: ListData = [];
            for (let i = 0; i < children.length; i++) {
              const child = children[i];
              arr.push(child.text!);
              arr.push(getArr(child));
            }
            return arr;
          }

          event.onBlur?.(root.text ? [root.text] : getArr(root));
        }}
        dangerouslySetInnerHTML={{
          __html: HTMLText,
        }}
      />
    );
  };

  const contentEl = (content: string) => {
    return (
      <div
        className=" reading-[36px] whitespace-pre-wrap rounded-[10px] border border-transparent p-[10px] pl-[20px] pt-0 text-[16px] outline-none focus-visible:border-white"
        contentEditable={hasEditor}
        id="content"
        suppressContentEditableWarning
        onBlur={(e) => {
          const container = containerRef.current!;
          event.onBlur?.(container.querySelector('#content')?.textContent || '');
        }}
      >
        {content}
      </div>
    );
  };
  const render = isList ? ListEl(data.content as ListData) : contentEl(data.content as string);

  return (
    <div>
      <h2
        id="title"
        className="mb-[16px] ml-[1em] text-[18px] font-[700] leading-[27px]"
        style={{
          marginTop: index == 0 ? 0 : 25,
        }}
      >
        {data.title}:
      </h2>
      <div ref={containerRef} className={`${custom_dictionary_dom_class.paragraph}`} id="paragraph" translate="no">
        {render}
      </div>
    </div>
  );
}

type SummaryData = {
  title: string;
  summary: string;
  action_items: ListData;
  key_info: ListData;
  outline: ListData;
};

type SummaryEditorProps = {
  noteId: string;
  noteGlobalDataRef: MutableRefObject<SpeakerNoteGlobalData>;
};

export const summaryStateAtom = atom<'retrying' | 'normal'>('normal');

function SummaryEditor(props: SummaryEditorProps) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'note.info.summary',
  });
  const setSelection = useSetAtom(selectionInfoAtom);
  const { t: globalT } = useTranslation();
  const { noteId, noteGlobalDataRef } = props;
  // const [info, setInfo] = useState<Note2 | null>(null);
  const [state, setState] = useAtom(summaryStateAtom);
  const containerRef = useRef<HTMLDivElement | null>(null);
  async function rerunHandle() {
    //没有做错误处理
    const res = (
      await rerunReq({
        noteId,
      })
    ).data;
    setState('retrying');
    //@ts-ignore
    if (res?.error) generalErrorHandle(res, globalT);
  }
  const loopingRef = useRef<boolean>(false);

  const { data: info, refetch: infoRefetch } = useAPIQuery(queryInfo2(noteId));

  const detailQuery = useRequest(() => templateReq.detail({ id: noteId }), {
    cacheKey: `templateReq.detail-${noteId}`,
    manual: true,
  });

  useEffect(() => {
    if (info?.state == 'summarized' || info?.state == 'saved') {
      detailQuery.refreshAsync();
    }
  }, [info?.state]);

  useEffect(() => {
    if (state == 'normal' && (info?.state == 'summarized' || info?.state == 'saved')) return;
    async function exec() {
      loopingRef.current = true;
      const { data: info } = await infoRefetch();
      //@ts-ignore
      if (info?.state != 'summarized' && info?.state != 'saved') {
        await sleep(2000);
        exec();
      } else {
        setState('normal');
        loopingRef.current = false;
      }
    }
    if (!loopingRef.current) exec();
  }, [state]);

  useEffect(() => {
    if (!info?.json) return;
    try {
      const data = JSON.parse(info?.json) as SummaryData;
      setSummaryData(data);
    } catch (err) {
      console.error(err);
    }
  }, [info?.json]);

  const [summaryData, setSummaryData] = useState<SummaryData | null>(null);

  //处理笔记全局信息
  useEffect(() => {
    if (containerRef.current) {
      noteGlobalDataRef.current.summary.copyContent = () => {
        const res = pasteRich(containerRef.current!);
        if (res) myMessage.success(globalT('note.info.summary.copied-successfully'), 2000);
        else myMessage.error(globalT('note.info.summary.copied-failed'), 2000);
      };
      noteGlobalDataRef.current.summary.getSummaryContent = () => {
        return summaryData || {};
      };
    }

    return () => {
      noteGlobalDataRef.current.summary = {};
    };
  });

  //总结部分报错
  if (info?.id && info.error && state != 'retrying') {
    return (
      <div
        className={`relative flex h-[100%] w-[calc(50%-15px)]  flex-col items-center justify-center rounded-[16px] border border-[#E1E1E1] bg-[#FFFFFF] p-[16px_4px_16px_5px]`}
        ref={containerRef}
      >
        <img src={`/notes/note-${info.error == '15000' ? 'error' : 'warn'}.png`} width={60} />
        <span className=" mt-[18px] text-[14px] text-[#ACACAC]">{globalT(`note.err.${info.error}`)}</span>
        {info.error == '15001' && (
          <button
            className=" mt-[20px] min-h-[36px] min-w-[146px] rounded-full bg-[#08ABB8] text-[16px] text-[#181C26]"
            onClick={rerunHandle}
          >
            {globalT('note.retry')}
          </button>
        )}
      </div>
    );
  }
  //总结的加载状态
  //@ts-ignore
  if (!summaryData || state == 'retrying' || detailQuery.data?.error) {
    return (
      <div
        className={`relative flex h-[100%] w-[calc(50%-15px)]  items-center justify-center rounded-[16px] border border-[#E1E1E1] bg-[#FFFFFF] p-[16px_4px_16px_5px]`}
        ref={containerRef}
      >
        <Loading />
        {/* <img src="/notes/summaryLoading.png" className=' w-[128px] h-[128px]' /> */}
        <span className=" mt-[10px] text-[16px] text-white">{globalT('misc.loading')}</span>
      </div>
    );
  }

  return (
    <div className="relative h-[100%] w-[calc(50%-15px)] rounded-[12px]  border border-[#E1E1E1] bg-[#FFFFFF] p-[16px_4px_16px_5px]">
      <div
        className={clsxm(
          ` h-full w-full pt-[16px]`,
          `scrollbar scrollbar-thumb-gray-500 scrollbar-track-gray-200 overflow-y-scroll`
        )}
        id="speaker-note-share-container"
        onScroll={(e) => {
          const store = getDefaultStore();
          if (store.get(selectionInfoAtom).openMethod == 'summary') {
            setSelection(selectionInfoAtom.init);
          }
        }}
        ref={containerRef}
      >
        {detailQuery.data?.fields.map((field, index) => {
          //@ts-ignore
          const content = summaryData[field.name];

          if (!content) return;

          return (
            <EditBox
              key={field.name}
              index={index}
              data={{
                //@ts-ignore
                title: field.value,
                content,
              }}
              event={{
                async onBlur(newWcontent) {
                  if (
                    //@ts-ignore
                    JSON.stringify(summaryData[field.name]) != JSON.stringify(newWcontent)
                  ) {
                    //@ts-ignore
                    summaryData[field.name] = newWcontent;
                    setSummaryData({
                      ...summaryData,
                    });

                    const res = await updateSummary({
                      noteId,
                      json: JSON.stringify(summaryData),
                    });
                    if (res != null) myMessage.error(t('fail-to-edit'), 2000);
                    else {
                      if (info) NoteListCardDataRefetchList(info.id);
                    }
                  }
                },
              }}
              hasEditor={info?.ownership == 'myself'}
            />
          );
        })}
      </div>
    </div>
  );
}

export default SummaryEditor;
