import { request } from '~/lib/axios';
import type { SharedQueryHookOptions } from '../helper';
import { defineQuery, useAPIQuery } from '../helper';
import { info2 } from '../note2s/info2';
import type { Note } from './types';

type Params = {
  noteId: string;
};

export const info = ({ noteId }: Params) => defineQuery(['notes.info', noteId], () => info2({ id: noteId }));

export const useNoteInfo = (params: Params, options?: SharedQueryHookOptions) => {
  const { data, ...rest } = useAPIQuery(info(params), options);

  return {
    data,
    ...rest,
  };
};
