import { request } from '~/lib/axios';
import { reqParamsToBody } from '../utils';

type Params = {
  gt: string;
};

export type EntryInfo = {
  quote: {
    author: string;
    content: string;
  };
  visited: boolean;
  announcement: {
    expireTime: number;
    link: string;
    text: string;
  } | null;
};

export function entryInfoReq(params: Params) {
  return request.post<EntryInfo>('/v1/entry/info', reqParamsToBody(params));
}
