import { clsxm } from '@hidock/utils';
import { VocabularyItem, VocabularyReq } from '~/api/vocabulary';
import { generalErrorHandle } from '~/utils/utils';
import { useHover, useSetState } from 'ahooks';
import { Tooltip } from 'antd';
import { t } from 'i18next';
import { useAtom, useSetAtom } from 'jotai';
import { SVGProps, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showConfirmAtom } from '../deleteConfrim';
import { replaceVocalbularyInfoAtom } from '../note-result2/markingWords/replaceVocalbulary';

type Word = VocabularyItem & { isDelete?: boolean };

const Svg = {
  add(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width="10.500000953674316"
        height="11.666667938232422"
        viewBox="0 0 10.500000953674316 11.666667938232422"
        {...props}
      >
        <g>
          <path
            d="M4.5,5L4.5,0L6,0L6,5L10.5,5L10.5,6.66667L6,6.66667L6,11.6667L4.5,11.6667L4.5,6.66667L0,6.66667L0,5L4.5,5Z"
            fill="#FFFFFF"
          />
        </g>
      </svg>
    );
  },
};

function Table(props: {
  words: Word[];
  event: {
    onDelete: (id: string) => void;
  };
}) {
  const { words, event } = props;
  const deleteInfo = {
    color: /*bg-[#f5f5f5] bg-white*/ '#f5f5f5',
  };
  const { t } = useTranslation();
  const deleteRef = useRef<HTMLDivElement | null>(null);
  const hover = useHover(deleteRef);
  const setShowConfirm = useSetAtom(showConfirmAtom);

  function handleTableWidth(dom: HTMLDivElement) {
    const cols = [30, '1fr', '1fr', 200];
    const totalWidth = dom.clientWidth;
    const chidrens = Array.from(dom.childNodes);
    let fixedWidth = 0;
    let frNum = 0;
    for (let i = 0; i < cols.length; i++)
      if (typeof cols[i] == 'number') {
        //@ts-ignore
        fixedWidth += cols[i];
      } else {
        frNum += 1;
      }
    const fr = (totalWidth - fixedWidth) / frNum;

    let widths: number[] = [];

    for (let i = 0; i < cols.length; i++) {
      if (typeof cols[i] == 'number') widths.push(cols[i] as number);
      else widths.push(fr);
    }

    for (let i = 0; i < chidrens.length && i < widths.length; i++) {
      const chidren = chidrens[i];
      //@ts-ignore
      chidren.style.width = widths[i] + 'px';
    }
  }

  const header = (
    <div
      className=" grid  grid-cols-[30px_1fr_1fr_200px] place-items-center border-b border-[#D8D8D8] pb-[20px] text-[22px] font-[600] text-[#686868]"
      ref={(dom) => {
        if (dom) handleTableWidth(dom);
      }}
    >
      <p className=" pl-[20px]">#</p>
      <p className=" grow break-words px-[40px]">{t('user.info.vocabulary.title.word')}</p>
      <p className=" grow break-words px-[40px]">{t('user.info.vocabulary.title.replacement')}</p>
    </div>
  );

  const body = words
    .filter((i) => !i.isDelete)
    .map((item, idx) => {
      return (
        <div
          className="grid grid-cols-[30px_1fr_1fr_200px] place-items-center border-b border-[#D8D8D8] py-[10px] text-[18px] font-[600]"
          key={item.id}
          ref={(dom) => {
            if (dom) handleTableWidth(dom);
          }}
        >
          <p className=" pl-[20px]">{idx + 1}</p>
          <p className=" grow whitespace-pre-wrap break-words px-[40px]">{item.word}</p>
          <p className=" grow whitespace-pre-wrap break-words px-[40px]">{item.replacement}</p>
          <div className=" flex justify-center">
            <Tooltip title={t('user.info.vocalbulary.delete.tooltip')}>
              <div
                ref={deleteRef}
                onClick={() => {
                  setShowConfirm({
                    isShow: true,
                    message: t('user.info.vocabulary.confirm.message'),
                    confirm() {
                      event.onDelete(item.id);
                      setShowConfirm(showConfirmAtom.init);
                    },
                    cancel() {
                      setShowConfirm(showConfirmAtom.init);
                    },
                    confirmText: t('user.info.vocabulary.confirm.Yes'),
                    cancelText: t('user.info.vocabulary.confirm.No'),
                  });
                }}
                className={clsxm(
                  `mr-[15px] flex h-[45px] w-[45px] cursor-pointer items-center justify-center rounded-[8px] hover:shadow-lg`,
                  `bg-[${deleteInfo.color}]`
                )}
              >
                <img className="w-[24px]" src={`/connectDevice/${!hover ? 'delete' : 'delete2'}.png`} />
              </div>
            </Tooltip>
          </div>
        </div>
      );
    });

  return (
    <div className=" mt-[40px] flex w-full flex-col flex-1">
      {header}
      <div className=" flex-1  w-[calc(100%+13px)] relative">
        <div
          className={clsxm(
            ' absolute w-full h-full left-0 top-0',
            'scrollbar  scrollbar-thumb-gray-500 scrollbar-track-gray-200 overflow-x-hidden overflow-y-scroll'
          )}
        >
          {body}
        </div>
      </div>
    </div>
  );
}

export function Vocalbulary() {
  const [words, setWords] = useState<Word[]>([]);
  const { t } = useTranslation();
  const setState = useSetAtom(replaceVocalbularyInfoAtom);
  useEffect(() => {
    VocabularyReq.list().then((res) => {
      setWords(res);
    });
  }, []);
  const header = <div className=" text-[28px] font-[600] leading-[28px] text-left">{t('user.info.vocalbulary')}</div>;
  const navBar = (
    <div className=" mt-[30px] flex w-full items-center  justify-between">
      <p className=" text-[22px] font-bold text-[#686868]">{t('user.info.vocabulary.describe')}</p>

      <button
        className=" flex h-[50px] items-center rounded-full bg-[#00A2AF] p-[10px_15px] font-bold"
        onClick={() => {
          setState({
            ...replaceVocalbularyInfoAtom.init,
            show: true,
            to: '',
            replace: '',
            onAddSuccess() {
              VocabularyReq.list().then((res) => {
                setWords(res);
              });
            },
          });
        }}
      >
        <p className=" text-white">{t('user.info.vocalbulary.addBtn')}</p>
      </button>
    </div>
  );

  return (
    <div className=" flex flex-col box-content w-[850px] h-full pe-[10px] pr-[10px] pt-[3px] text-center leading-[18.72px]">
      {header}
      {navBar}
      <Table
        words={words}
        event={{
          onDelete(id) {
            const target = words.find((i) => i.id == id);
            target!.isDelete = true;
            setWords([...words]);
            VocabularyReq.delete({ id }).then((res) => {
              //@ts-ignore
              if (res?.error) {
                //@ts-ignore
                generalErrorHandle(res);
                target!.isDelete = false;
                target!.isDelete = true;
                setWords((words) => ({ ...words }));
              }
            });
          },
        }}
      />
    </div>
  );
}
