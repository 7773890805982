export const IconLevel = ({ className }: { className?: string }) => {
  return (
    <svg className={className} viewBox="0 0 12 13" fill="none">
      <g opacity="0.7">
        <path
          d="M1.88542 1.479H10.1146C10.3494 1.479 10.5746 1.57229 10.7407 1.73834C10.9067 1.90438 11 2.12959 11 2.36442V4.92744C10.9998 5.09759 10.9505 5.26407 10.8582 5.40696C10.7658 5.54986 10.6342 5.66311 10.4792 5.73317L7.21875 7.21494C7.70151 7.48509 8.08103 7.90774 8.29787 8.41668C8.51471 8.92562 8.55661 9.49211 8.41702 10.0274C8.27743 10.5627 7.96422 11.0366 7.52647 11.3749C7.08871 11.7131 6.55113 11.8966 5.99792 11.8966C5.44471 11.8966 4.90712 11.7131 4.46937 11.3749C4.03161 11.0366 3.71841 10.5627 3.57881 10.0274C3.43922 9.49211 3.48113 8.92562 3.69796 8.41668C3.9148 7.90774 4.29432 7.48509 4.77708 7.21494L1.52083 5.73317C1.36578 5.66311 1.2342 5.54986 1.14183 5.40696C1.04946 5.26407 1.00022 5.09759 1 4.92744V2.36442C1 2.12959 1.09328 1.90438 1.25933 1.73834C1.42538 1.57229 1.65059 1.479 1.88542 1.479ZM4.33333 2.31234V6.09723L6 6.85452L7.66667 6.09723V2.31234H4.33333ZM10.1667 4.92744V2.36442C10.1667 2.35061 10.1612 2.33736 10.1514 2.32759C10.1416 2.31782 10.1284 2.31234 10.1146 2.31234H8.5V5.71807L10.1359 4.97484C10.1451 4.97073 10.1528 4.96407 10.1583 4.95567C10.1637 4.94726 10.1666 4.93746 10.1667 4.92744ZM6 11.0623C6.32964 11.0623 6.65187 10.9646 6.92595 10.7815C7.20003 10.5983 7.41365 10.338 7.5398 10.0335C7.66595 9.72893 7.69895 9.39382 7.63464 9.07052C7.57033 8.74722 7.4116 8.45025 7.17851 8.21716C6.94542 7.98407 6.64845 7.82534 6.32515 7.76103C6.00185 7.69672 5.66674 7.72972 5.36219 7.85587C5.05765 7.98202 4.79735 8.19564 4.61422 8.46972C4.43108 8.7438 4.33333 9.06603 4.33333 9.39567C4.33333 9.8377 4.50893 10.2616 4.82149 10.5742C5.13405 10.8867 5.55797 11.0623 6 11.0623ZM1.83333 4.92744C1.83335 4.93746 1.83626 4.94726 1.84171 4.95567C1.84717 4.96407 1.85492 4.97073 1.86406 4.97484L3.5 5.71807V2.31234H1.88542C1.8716 2.31234 1.85836 2.31782 1.84859 2.32759C1.83882 2.33736 1.83333 2.35061 1.83333 2.36442V4.92744Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
