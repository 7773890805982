import { clsxm } from '@hidock/utils';
import { info2, Note2, useInfo2 } from '~/api/note2s/info2';
import { Tag, tagReq } from '~/api/tag';
import { useFocus } from '~/hooks/useFocus';
import { InputModify } from '~/pages/notes/Header/general';
import { homePageTagRefresh } from '~/pages/notes/Header/TabBar2';
import { noteStore } from '~/pages/notes/store';
import { useKeyPress, useMount, useRequest } from 'ahooks';
import { getDefaultStore, useAtom } from 'jotai';
import { CSSProperties, SVGProps, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { styled } from 'styled-components';
import Svg from './svg';

function CloseSvg(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="none"
      version="1.1"
      width="16.66666603088379"
      height="16.66666603088379"
      viewBox="0 0 16.66666603088379 16.66666603088379"
      {...props}
    >
      <g>
        <path
          d="M8.33333,16.6667C3.73083,16.6667,0,12.9358,0,8.33333C0,3.73083,3.73083,0,8.33333,0C12.9358,0,16.6667,3.73083,16.6667,8.33333C16.6667,12.9358,12.9358,16.6667,8.33333,16.6667ZM8.33333,7.155L5.97667,4.7975L4.7975,5.97667L7.155,8.33333L4.7975,10.69L5.97667,11.8692L8.33333,9.51167L10.69,11.8692L11.8692,10.69L9.51167,8.33333L11.8692,5.97667L10.69,4.7975L8.33333,7.155Z"
          fill="#686868"
        />
      </g>
    </svg>
  );
}

function AddSvg(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="10.500000953674316"
      height="10.500000953674316"
      viewBox="0 0 10.500000953674316 10.500000953674316"
      {...props}
    >
      <g>
        <path d="M4.5,4.5L4.5,0L6,0L6,4.5L10.5,4.5L10.5,6L6,6L6,10.5L4.5,10.5L4.5,6L0,6L0,4.5L4.5,4.5Z" />
      </g>
    </svg>
  );
}

const TagContainer = styled.span`
  --close-hover: none;
  &:hover {
    --close-hover: auto;
  }
`;

export function TagSelect(params: { noteId: string; info2: Note2 }) {
  const listQuery = useRequest(() => tagReq.getListByNoteId({ noteId: params.noteId }));
  const { data: list = [] } = listQuery;
  const [tagName, setTagName] = useState('');
  const [containerCss, setContainerCss] = useState<CSSProperties>({
    visibility: 'hidden',
  });

  const allTagQuery = useRequest(() => tagReq.getListByFoderId({ folderId: '' }));

  const [cutPos, setCutPos] = useState(1);
  const [moreShow, setMoreShow] = useState(false);
  const [select, setSelect] = useState('');
  const [showInput, setShowInput] = useState(false);
  const copyRef = useRef<HTMLDivElement | null>(null);
  const { noteId } = params;

  /**是否展示更多内容弹窗 */
  const moreShowClick = useFocus((state) => {
    setMoreShow(state == 'focus');
  });

  useEffect(() => {
    if (['summarized', 'saved'].includes(params.info2.state)) listQuery.run();
  }, [params.info2.state]);

  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);

  function addTag(name: string) {
    name = name.trim();
    const idx = list.findIndex((tag) => tag == name);
    setShowInput(false);
    if (idx != -1 || !name) return;
    list.push(name);
    listQuery.mutate([...list]);
    allTagQuery.mutate((list) => {
      if (!list) return list;
      return [
        ...list,
        {
          id: '0',
          userId: '0',
          noteCount: 0,
          tag: name,
        },
      ];
    });
    tagReq
      .addByNoteId({
        noteId,
        tag: name,
      })
      .then((res) => {
        //@ts-ignore
        if (res?.error) {
          listQuery.refresh();
        } else {
          homePageTagRefresh();
        }
      });
  }

  function saveHandle() {
    addTag(tagName);
    setTagName('');
  }

  const targetClick = useFocus((state) => {
    if (state == 'focus') {
      setShowInput(true);
    } else {
      saveHandle();
    }
  });

  //处理展示多少个
  useEffect(() => {
    const copy = copyRef.current;
    if (copy) {
      const len = copy.clientWidth;
      const arr = Array.from(copy.children);
      const endNodeLen = arr.pop()!.clientWidth;
      const btnWidth = arr.pop()!.clientWidth;
      let idx = 0;
      //@ts-ignore
      while (idx + 1 < arr.length && arr[idx + 1].offsetLeft + arr[idx + 1].clientWidth + endNodeLen + btnWidth < len)
        idx++;
      setCutPos(idx + 1);
      setContainerCss({
        visibility: 'visible',
      });
    }
  }, [listQuery.data, copyRef.current]);

  useEffect(() => {
    if (showInput) inputRef.current?.focus();
  }, [showInput]);

  /**
   * 功能
   * 1.查
   * 2.添加
   * 3.删除
   * 4.修改
   * 5.展示更多
   * 6.展示输入框
   *   -失去焦点保存
   *   -点击加号保存
   */

  const listEls = list.map((tag, idx) => {
    return (
      <TagContainer
        className=" relative mr-[20px] block cursor-pointer text-ellipsis whitespace-pre border-b-2 border-[transparent] text-[18px] leading-[20px] text-[#686868] hover:border-[#00A2AF] hover:text-[#00A2AF]"
        key={tag + idx}
        onClick={() => {
          setSelect(tag);
        }}
      >
        <span>#</span>
        <span
          className=" outline-none"
          dangerouslySetInnerHTML={{
            __html: tag,
          }}
          onClick={(e) => {
            e.currentTarget.contentEditable = 'true';
            e.currentTarget.focus();
          }}
          onKeyDown={(e) => {
            const preText = e.currentTarget.textContent;
            if (e.key.toLocaleLowerCase() == 'enter') e.currentTarget.blur();
            else if (['backspace', 'delete', 'arrowleft', 'arrowright'].includes(e.key.toLocaleLowerCase())) {
            } else if (preText?.length && preText.length >= 20) {
              e.preventDefault();
            }
          }}
          onBlur={(e) => {
            e.currentTarget.contentEditable = 'false';
            const newTag = e.currentTarget.textContent || '';
            const preTag = tag;
            if (tag && tag != newTag) {
              let idx = list.findIndex((i) => i == preTag);
              if (idx != -1) {
                list[idx] = newTag;
                listQuery.mutate([...list]);
              }
              tagReq
                .modifyNoteTag({
                  noteId,
                  before: preTag,
                  tag: newTag,
                })
                .then((res) => {
                  //@ts-ignore
                  if (res?.error && target && tag && idx != -1) {
                    listQuery.refresh();
                  } else {
                    homePageTagRefresh();
                    allTagQuery.mutate((list) => {
                      if (!list) return list;
                      const idx = list.findIndex((i) => i.tag == tag);
                      if (idx != -1) list[idx].tag = newTag;
                      return [...list];
                    });
                  }
                });
            }
            setTagName('');
          }}
        />
        <CloseSvg
          className=" translate-x-1/10 hover-show absolute bottom-full left-full translate-y-1/2"
          style={{
            display: 'var(--close-hover)',
          }}
          onClick={() => {
            const idx = list.findIndex((i) => i == tag);
            list.splice(idx, 1);
            listQuery.mutate(list);
            tagReq
              .deleteByNoteId({
                noteId: noteId!,
                tag: tag,
              })
              .then((res) => {
                //@ts-ignore
                if (res?.error) {
                  listQuery.refresh();
                } else {
                  homePageTagRefresh();
                  allTagQuery.mutate((list) => {
                    if (!list) return list;
                    const idx = list.findIndex((i) => i.tag == tag);
                    if (idx != -1) list.splice(idx, 1);
                    return [...list];
                  });
                }
              });
          }}
        />
      </TagContainer>
    );
  });

  const more_than = (
    <div className=" relative ml-[18px]">
      <div
        className=" flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full fill-[#939393] hover:bg-[#DEDEDE] hover:fill-[#3A3A3A]"
        onClick={() => !moreShow && moreShowClick()}
        style={{
          display: listEls.slice(cutPos).length ? '' : 'none',
        }}
      >
        <Svg.downArrow2 />
      </div>
      {moreShow && !!listEls.slice(cutPos).length && (
        <div
          className={clsxm(
            'absolute left-[calc(100%-20px)] top-[calc(100%+10px)]  z-[999] max-h-[500px] bg-white p-[10px] shadow-[0px_0px_4px_rgba(0,0,0,0.1)]',
            ' rounded-[12px] border-[0.5px] border-[#9C9C9C] backdrop-blur-[8px]',
            'scrollbar scrollbar-thumb-gray-500 scrollbar-track-gray-200 overflow-y-scroll'
          )}
        >
          {list.slice(cutPos).map((tag, idx) => {
            return (
              <>
                <p
                  className=" my-[4px] flex cursor-pointer items-center rounded-[8px]  px-[7px] text-[16px] font-[600]"
                  key={tag + idx}
                >
                  <span>#</span>
                  <span
                    className=" h-[40px] w-[142px] flex-1 overflow-hidden overflow-ellipsis whitespace-pre leading-[40px] outline-none"
                    dangerouslySetInnerHTML={{
                      __html: tag,
                    }}
                    onClick={(e) => {
                      moreShowClick();
                      e.currentTarget.contentEditable = 'true';
                      e.currentTarget.focus();
                    }}
                    onKeyDown={(e) => {
                      const preText = e.currentTarget.textContent;
                      if (e.key.toLocaleLowerCase() == 'enter') e.currentTarget.blur();
                      else if (['backspace', 'delete', 'arrowleft', 'arrowright'].includes(e.key.toLocaleLowerCase())) {
                      } else if (preText?.length && preText.length >= 20) {
                        e.preventDefault();
                      }
                    }}
                    onBlur={(e) => {
                      e.currentTarget.contentEditable = 'false';
                      const newTag = e.currentTarget.textContent || '';
                      const preTag = tag;
                      if (tag && tag != newTag) {
                        let idx = list.findIndex((i) => i == preTag);
                        if (idx != -1) {
                          list[idx] = newTag;
                          listQuery.mutate([...list]);
                        }
                        tagReq
                          .modifyNoteTag({
                            noteId,
                            before: preTag,
                            tag: newTag,
                          })
                          .then((res) => {
                            //@ts-ignore
                            if (res?.error && target && tag && idx != -1) {
                              listQuery.refresh();
                            } else {
                              homePageTagRefresh();
                              allTagQuery.mutate((list) => {
                                if (!list) return list;
                                const idx = list.findIndex((i) => i.tag == tag);
                                if (idx != -1) list[idx].tag = newTag;
                                return [...list];
                              });
                            }
                          });
                      }
                      setTagName('');
                    }}
                    style={{
                      color: select == tag ? '#3A3A3A' : '#686868',
                    }}
                  />
                  <Svg.delete
                    className=" ml-[5px]"
                    onClick={() => {
                      moreShowClick();
                      const idx = list.findIndex((i) => i == tag);
                      list.splice(idx, 1);
                      listQuery.mutate(list);
                      tagReq
                        .deleteByNoteId({
                          noteId: noteId!,
                          tag: tag,
                        })
                        .then((res) => {
                          //@ts-ignore
                          if (res?.error) {
                            listQuery.refresh();
                          } else {
                            homePageTagRefresh();
                            allTagQuery.mutate((list) => {
                              if (!list) return list;
                              const idx = list.findIndex((i) => i.tag == tag);
                              if (idx != -1) list.splice(idx, 1);
                              return [...list];
                            });
                          }
                        });
                    }}
                  />
                </p>
                <div className=" my-[4px] h-[1px] bg-[#DDDDE1]" />
              </>
            );
          })}
        </div>
      )}
    </div>
  );

  const TipItemArr = allTagQuery.data
    ?.filter((i) => !list.includes(i.tag))
    .map((item, index) => {
      const text = item.tag;
      const textLocaleLower = text.toLocaleLowerCase();
      const textArr: {
        isHigh: boolean;
        text: string;
      }[] = [];
      let idx = 0;
      let hasHigh = false;
      while (idx != -1 && typeof idx == 'number' && tagName) {
        const preIdx = idx;
        idx = textLocaleLower.indexOf(tagName.toLocaleLowerCase(), idx);
        if (idx != -1) {
          textArr.push({
            isHigh: false,
            text: text.slice(preIdx, idx),
          });
          textArr.push({
            isHigh: true,
            text: text.slice(idx, idx + tagName.length),
          });
          hasHigh = true;
          idx += tagName.length;
        } else {
          textArr.push({
            isHigh: false,
            text: text.slice(preIdx, text.length),
          });
        }
      }

      if (!hasHigh) return null;

      return (
        <div
          className=" flex h-[32px] w-full items-center rounded-[8px] pl-[12px] align-middle hover:bg-[#DEDEDE] "
          onClick={(e) => {
            e.stopPropagation();
            setTagName(item.tag);
          }}
          key={index}
        >
          <p className=" flex-1 overflow-hidden text-ellipsis whitespace-pre">
            {textArr.map(({ text, isHigh }) => {
              return (
                <em className=" not-italic" style={{ color: isHigh ? '#00A2AF' : '#686868' }}>
                  {text}
                </em>
              );
            })}
          </p>
        </div>
      );
    });

  const TipEl = (
    <div
      className={clsxm(
        ' absolute left-0 top-[calc(100%+10px)] w-full rounded-[12px]  border-[0.5px] border-[#9C9C9C] bg-white py-[5px] text-[16px] font-[600] backdrop-blur'
      )}
    >
      <div
        className={clsxm(
          ' max-h-[300px] w-full pl-[12px] pr-[3px]',
          'scrollbar  scrollbar-thumb-gray-500 scrollbar-track-gray-200 overflow-x-hidden overflow-y-scroll'
        )}
      >
        {TipItemArr}
      </div>
    </div>
  );

  return (
    <>
      <div className=" relative flex w-[40%] items-center" style={containerCss} translate="no">
        {listEls.slice(0, cutPos)}
        {showInput ? (
          <button
            className=" relative flex h-[40px] flex-shrink-0 items-center rounded-full border border-[#00A2AF] bg-white"
            onClick={() => {
              targetClick();
            }}
          >
            <input
              ref={inputRef}
              className=" ml-[15px] mr-[11px] bg-transparent outline-none"
              value={tagName}
              onChange={(e) => setTagName(e.target.value)}
              maxLength={20}
              onKeyDown={(e) => {
                console.log(e.key.toLowerCase());
                if (e.key.toLowerCase() == 'enter') saveHandle();
              }}
            />
            <AddSvg
              className=" mr-[18px] fill-[#00A2AF] hover:scale-[1.3]"
              onClick={(e) => {
                e.stopPropagation();
                setShowInput(false);
              }}
            />
            {!!allTagQuery.data?.length && tagName && !!TipItemArr?.filter(Boolean).length && TipEl}
          </button>
        ) : (
          <div
            className=" flex h-[32px] w-[32px] flex-shrink-0 cursor-pointer items-center justify-center rounded-full fill-[#939393] hover:bg-[#DEDEDE] hover:fill-[#3A3A3A]"
            onClick={() => {
              targetClick();
              setShowInput(true);
            }}
          >
            <AddSvg />
          </div>
        )}
        {more_than}
      </div>
      <div className=" fixed left-full top-full flex w-[40%] items-center" ref={copyRef}>
        {listEls.slice(0, 20)}
        <AddSvg />
        {more_than}
      </div>
    </>
  );
}
