import { useFocus } from '~/hooks/useFocus';
import { DownloadSvg } from '~/svg/svg';
import { useAtom } from 'jotai';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { downloadQueueAtom, useFileInfos } from '../deviceState';

export function DownLoad() {
  const [q, setQ] = useAtom(downloadQueueAtom);
  const { t } = useTranslation();
  const containerRef = useRef<SVGSVGElement | null>(null);
  const [show, setShow] = useState(false);
  const targetClick = useFocus((state) => {
    setShow(state == 'focus');
  });
  //floatWindow
  const floatWindow = () => {
    //header 42px
    const header = (
      <div className=" box-border flex h-[42px] justify-between pb-[10px] pl-[12px] pt-[14px] text-[18px] text-black">
        <span>{t('misc.download')}</span>
        <div
          className="relative flex aspect-square w-[18px] cursor-pointer items-center justify-center rounded-full hover:bg-[#E8EAEA]"
          onClick={(e) => {
            e.stopPropagation();
            setShow(false);
          }}
        >
          <img src="/connectDevice/wrong.png" className="h-[10px] w-[10px] " />
        </div>
      </div>
    );
    //content every 30px
    const content = q
      .filter((item) => (item.progress | 0) != 1 && item.file.fileDownLoading)
      .map(({ file, progress }) => {
        const fileStateEl = () => {
          console.log('progress', progress);
          if (progress == 0) return <span>waiting</span>;
          if (progress == 1) return <img className="w-4" src="/connectDevice/duigou2.png" />;
          if (progress) return <span className="text-[#29BEFE]">{`${((progress || 0) * 100) | 0}%`}</span>;
        };
        return (
          <div
            className=" box-border h-[30px] w-full p-[6px_4px_6px_12px] text-[14px] text-black"
            style={{ backgroundColor: file.progress == 1 ? '#F0F0F0' : '' }}
          >
            <span className="inline-block w-[200px] overflow-hidden text-ellipsis whitespace-nowrap">
              {file.title || file.name}
            </span>
            <span className="float-right ">{fileStateEl()}</span>
          </div>
        );
      });

    return (
      <div
        className=" absolute right-0 top-[calc(100%-15px)] z-30 w-[290px] rounded-[8px] border border-[#ACACAC] bg-white p-[0px_12px_0_0]"
        style={{ boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.3)' }}
      >
        {header}
        <div className="scrollbar h-[120px] overflow-auto">{content}</div>
      </div>
    );
  };

  const len = q.filter((item) => item.file.fileDownLoading).length;
  return (
    <span
      className=" relative cursor-pointer"
      onClick={() => !show && targetClick()}
      style={{
        visibility: len ? 'visible' : 'hidden',
        // pointerEvents:len ? 'none' : 'auto',
      }}
    >
      <span className=" relative">
        <DownloadSvg
          className="mr-[20px] mt-[7px]  h-[24px] w-[26.04px] cursor-pointer  hover:fill-[#29BEFE]"
          style={{ fill: len ? '#4E5A58' : 'rgb(78,90,88)' }}
          ref={containerRef}
        />
        {len ? (
          <div className=" absolute right-0 top-0 flex h-[18px] w-[18px] items-center justify-center rounded-full bg-[#FA5B65] text-[12px] text-white ">
            {len}
          </div>
        ) : null}
      </span>
      {show && floatWindow()}
    </span>
  );
}
