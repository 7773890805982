import { myMessage } from '~/components/MyToast/MyToast';
import { getAccessToken, setGi } from '~/store/user';
import { generalErrorHandle } from '~/utils/utils';
import axiosBase, { AxiosRequestConfig, AxiosResponse } from 'axios';
import i18next from 'i18next';
import { toast } from 'react-hot-toast';
import type { APIResponse } from './type';

const axios = axiosBase.create({
  baseURL: import.meta.env.VITE_API_BASE_URL,
});

axios.interceptors.request.use((request) => {
  const accessToken = getAccessToken();
  accessToken && request.headers.set('Access-Token', accessToken);
  return request;
});

//&  {__response:AxiosResponse<T, any>}
type postParams = Parameters<typeof axios.post>;

export const request = {
  post: async <T>(
    ...params: [
      url: string,
      data?: unknown,
      config?: (AxiosRequestConfig<unknown> & { notShowErr?: boolean }) | undefined,
    ]
  ): Promise<T> => {
    const response = await axios.post(...params);
    const data = response.data as APIResponse<any>;
    if (!data.error) {
      const res = data.data;
      return res;
    }

    if (data.error === 10000 && location.pathname != '/') {
      setGi('');
      window.location.href = `/?${import.meta.env.VITE_NOTE_VERSION}`;
      //@ts-ignore
      return;
    }

    //@ts-ignore
    if (data.error === 90000) return data;

    !params[2]?.notShowErr && generalErrorHandle(data, i18next.t, 2000);
    return response.data;
  },
  get: <T>(...params: Parameters<typeof axios.get>) => axios.get<T>(...params),
};
