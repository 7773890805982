import { clsxm } from '@hidock/utils';
import { useNoteInfo } from '~/api/notes/info';
import { Loading } from '~/components/Loading';
import { Markdown } from '~/components/Markdown';
import { toPng } from 'html-to-image';
import { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

export const PageImage = () => {
  const { noteId } = useParams();
  const ref = useRef<HTMLDivElement>(null);
  const isGeneratedRef = useRef(false);
  const [url, setUrl] = useState('');

  const { data: note } = useNoteInfo({ noteId: noteId || '' });

  if (url) return <img className="lg:max-w-4xl" src={url} />;

  return (
    <>
      <div ref={ref} className={clsxm('bg-solid w-[750px]', !note && 'opacity-0')}>
        {note?.markdown && (
          <div
            ref={() => {
              if (!ref.current || isGeneratedRef.current) return;
              toPng(ref.current, { cacheBust: true, pixelRatio: 2 })
                .then((dataUrl) => {
                  if (isGeneratedRef.current) return;
                  isGeneratedRef.current = true;
                  setUrl(dataUrl);

                  // const link = document.createElement('a')
                  // link.download = `${note.title}.webp`
                  // link.href = dataUrl
                  // link.click()

                  // document.removeChild(link)
                })
                .catch((err) => {
                  console.error(err);
                });
            }}
          />
        )}
        {note?.markdown && <Markdown className="prose-invert p-6" markdown={note?.markdown} />}
        <div className="flex justify-center">
          <div
            className={clsxm(
              'inline-flex items-center',
              'text-xs font-semibold text-white',
              'bg-primary-600',
              'rounded-tl-lg rounded-tr-lg',
              'px-2 py-0.5',
              'opacity-30'
            )}
          >
            <img src="/logo.png" className="w-4" />
            <span className="w-4"></span> hidock.com
          </div>
        </div>
      </div>
      <div className="bg-zinc fixed inset-0 flex items-center justify-center">
        <Loading />
      </div>
    </>
  );
};
