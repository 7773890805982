import { request } from '~/lib/axios';
import { reqParamsToBody } from './utils';

export type LogData = {
  version: string;
  publishDate: number;
  changeLogs: string;
};

function hidock(params: { pageIndex: number }) {
  return request.post<LogData[]>('/v2/device/firmware/list', reqParamsToBody(params));
}

function hinotes(params: { pageIndex: number }) {
  return request.post<LogData[]>('/v1/changes', reqParamsToBody(params));
}

export const productLogReq = {
  hidock,
  hinotes,
};
