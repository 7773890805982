import { request } from '~/lib/axios';
import type { SharedQueryHookOptions } from '../helper';
import { defineQuery, useAPIQuery } from '../helper';
import type { Tags } from './types';

export const list = () =>
  defineQuery(['tag.list'], async () => {
    const data = await request.post<Tags>('/v1/tag/list');
    return data.sort((a, b) => b.noteCount - a.noteCount);
  });

export const useTagList = (options?: SharedQueryHookOptions) => {
  return useAPIQuery(list(), options);
};
