import { clsxm } from '@hidock/utils';
import { useAPIQuery } from '~/api/helper';
import { info2, queryInfo2, useInfo2 } from '~/api/note2s/info2';
import { useRateNoteMutation } from '~/api/notes/rate';
import { myMessage } from '~/components/MyToast/MyToast';
import useUpdate from '~/hooks/useUpdate';
import i18n from '~/i18n';
import { queryClient } from '~/main';
import { useUserInfo } from '~/store/user';
import { sleep } from '~/utils/sleep';
import { useMount, useUnmount } from 'ahooks';
import { ReactNode, SVGProps, useEffect, useMemo, useRef, useState, useTransition } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useLocalStorage } from 'usehooks-ts';
import { number } from 'zod';

function StarStroke(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="22.000059127807617"
      height="21.020004272460938"
      viewBox="0 0 22.000059127807617 21.020004272460938"
      {...props}
    >
      <g>
        <path
          d="M11,0C11.3806,0,11.7283,0.216072,11.8967,0.557376C11.8967,0.557376,14.7543,6.34647,14.7543,6.34647C14.7543,6.34647,21.1447,7.28051,21.1447,7.28051C21.5212,7.33555,21.8339,7.59956,21.9513,7.96157C22.0687,8.32358,21.9704,8.72083,21.6978,8.98636C21.6978,8.98636,17.0746,13.4894,17.0746,13.4894C17.0746,13.4894,18.1656,19.851,18.1656,19.851C18.23,20.2261,18.0757,20.6053,17.7678,20.8291C17.4598,21.0528,17.0515,21.0823,16.7146,20.9051C16.7146,20.9051,11,17.8998,11,17.8998C11,17.8998,5.28548,20.9051,5.28548,20.9051C4.94856,21.0823,4.54027,21.0528,4.2323,20.8291C3.92432,20.6053,3.77007,20.2261,3.83442,19.851C3.83442,19.851,4.92551,13.4894,4.92551,13.4894C4.92551,13.4894,0.302296,8.98636,0.302296,8.98636C0.0296782,8.72083,-0.0685946,8.32358,0.0487831,7.96157C0.166161,7.59956,0.478841,7.33555,0.855401,7.28051C0.855401,7.28051,7.24577,6.34647,7.24577,6.34647C7.24577,6.34647,10.1033,0.557376,10.1033,0.557376C10.2718,0.216072,10.6194,0,11,0C11,0,11,0,11,0ZM11,3.25925C11,3.25925,8.80674,7.70262,8.80674,7.70262C8.6612,7.99747,8.38001,8.20193,8.05466,8.24949C8.05466,8.24949,3.14844,8.9666,3.14844,8.9666C3.14844,8.9666,6.69776,12.4236,6.69776,12.4236C6.93364,12.6534,7.0413,12.9845,6.98564,13.309C6.98564,13.309,6.14821,18.1917,6.14821,18.1917C6.14821,18.1917,10.5346,15.8849,10.5346,15.8849C10.826,15.7317,11.1741,15.7317,11.4655,15.8849C11.4655,15.8849,15.8518,18.1917,15.8518,18.1917C15.8518,18.1917,15.0144,13.309,15.0144,13.309C14.9588,12.9845,15.0664,12.6534,15.3023,12.4236C15.3023,12.4236,18.8516,8.9666,18.8516,8.9666C18.8516,8.9666,13.9454,8.24949,13.9454,8.24949C13.62,8.20193,13.3389,7.99747,13.1933,7.70262C13.1933,7.70262,11,3.25925,11,3.25925C11,3.25925,11,3.25925,11,3.25925Z"
          fillRule="evenodd"
          fill="#F9CF44"
        />
      </g>
    </svg>
  );
}
function StarFill(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="22.000059127807617"
      height="21.020004272460938"
      viewBox="0 0 22.000059127807617 21.020004272460938"
      {...props}
    >
      <g>
        <path
          d="M11,0C11.3806,0,11.7283,0.216072,11.8967,0.557376C11.8967,0.557376,14.7543,6.34647,14.7543,6.34647C14.7543,6.34647,21.1447,7.28051,21.1447,7.28051C21.5212,7.33555,21.8339,7.59956,21.9513,7.96157C22.0687,8.32358,21.9704,8.72083,21.6978,8.98636C21.6978,8.98636,17.0746,13.4894,17.0746,13.4894C17.0746,13.4894,18.1656,19.851,18.1656,19.851C18.23,20.2261,18.0757,20.6053,17.7678,20.8291C17.4598,21.0528,17.0515,21.0823,16.7146,20.9051C16.7146,20.9051,11,17.8998,11,17.8998C11,17.8998,5.28548,20.9051,5.28548,20.9051C4.94856,21.0823,4.54027,21.0528,4.2323,20.8291C3.92432,20.6053,3.77007,20.2261,3.83442,19.851C3.83442,19.851,4.92551,13.4894,4.92551,13.4894C4.92551,13.4894,0.302296,8.98636,0.302296,8.98636C0.0296782,8.72083,-0.0685946,8.32358,0.0487831,7.96157C0.166161,7.59956,0.478841,7.33555,0.855401,7.28051C0.855401,7.28051,7.24577,6.34647,7.24577,6.34647C7.24577,6.34647,10.1033,0.557376,10.1033,0.557376C10.2718,0.216072,10.6194,0,11,0C11,0,11,0,11,0Z"
          fillRule="evenodd"
          fill="#F9CF44"
        />
      </g>
    </svg>
  );
}

function Rectangle(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      {...props}
    >
      <g>
        <path d="M10,1.42109e-15L18.6603,15L1.33975,15L10,1.42109e-15Z" fill="white" fill-opacity="1" />
      </g>
    </svg>
  );
}

export function Rating(params: { noteId: string }) {
  const { data: info } = useAPIQuery(queryInfo2(params.noteId));
  const preScoreRef = useRef<number>(info?.score || 0);
  const [remark, setRemark] = useState('');
  const [showNoteCnt, setShowNoteCnt] = useLocalStorage('showNoteCnt', 0);
  const update = useUpdate();
  const [showAnimation, setShowAnimation] = useState(false);
  const [openComment, setOpenComment] = useState(false);
  const [isBright, setIsBright] = useState(true);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const { t } = useTranslation();
  const isCanScore = info?.score && !openComment;
  let starEls: ReactNode[] = [];

  //维护showNoteCnt
  useUnmount(() => {
    setShowNoteCnt(showNoteCnt + 1);
  });

  function modifyStar(score: number) {
    const key = queryInfo2(params.noteId).key;
    queryClient.cancelQueries({
      queryKey: key,
    });
    queryClient.setQueryData<typeof info>(key, (old) => {
      if (old) {
        preScoreRef.current = old.score || 0;
        old.score = score;
        update();
        return { ...old };
      }

      return old;
    });
  }

  function scoreSelectHandle(score: number) {
    if (isCanScore) return;
    modifyStar(score);
    if (preScoreRef.current != score) setOpenComment(true);
  }

  //星星闪烁功能实现
  useEffect(() => {
    if (!showAnimation) return;
    (async function () {
      for (let i = 0; i < 6; i++) {
        setIsBright(!!(i % 2));
        await sleep(300);
      }
      setShowAnimation(false);
    })();
  }, [showAnimation]);

  useEffect(() => {
    if (showNoteCnt < 5) setShowAnimation(true);
    if (showNoteCnt == 0) setOpenComment(true);
  }, []);

  const { mutate, isLoading } = useRateNoteMutation({
    onMutate() {},
    onError() {
      modifyStar(preScoreRef.current);
      setOpenComment(true);
    },
    onSuccess(data) {
      //@ts-ignore
      if (data?.error) {
        modifyStar(preScoreRef.current);
        setOpenComment(true);
      }
    },
  });

  useEffect(() => {
    if (openComment) textareaRef.current?.focus();
  }, [openComment]);

  if (showAnimation) {
    for (let i = 0; i < 5; i++) {
      let Star = isBright ? StarFill : StarStroke;
      starEls.push(<Star key={i + `${isBright}`} className=" mr-[6px] origin-center cursor-pointer" />);
    }
  } else {
    for (let i = 0; i < 5; i++) {
      let Star = i < (info?.score || 0) ? StarFill : StarStroke;
      let score = i + 1;
      starEls.push(
        <Star
          className={` mr-[6px] cursor-pointer ${!isCanScore ? `hover:scale-[1.3]` : ''}`}
          key={i}
          onClick={() => {
            scoreSelectHandle(score);
          }}
        />
      );
    }
  }

  const commentEl = (
    <div className=" absolute right-0 top-[calc(100%+22px)] h-[135px] w-[337px] rounded-[8px] bg-white p-[30px_20px_20px_30px] shadow-[0_4px_50px_0_rgba(0,0,0,0.2)]">
      <Rectangle className=" absolute bottom-[96%] right-[20px]" />
      <textarea
        className=" h-[48px] w-full resize-none outline-none"
        placeholder={t('note.remark.placeholder')}
        maxLength={500}
        value={remark}
        onChange={(e) => {
          setRemark(e.target.value);
        }}
        ref={textareaRef}
      ></textarea>
      <div className=" flex justify-end">
        <button
          className=" rounded-[8px] bg-[#00A2AF] p-[8px_19px] text-center text-[16px] font-[600] leading-[18.72px] text-white"
          onClick={() => {
            if (!info?.score) {
              myMessage.info(t('note.remark.not-score.submit.tip'), 2000);
              return;
            }
            modifyStar(info?.score || 0);
            mutate({
              id: params.noteId,
              level: info?.score || 0,
              remark: remark,
            });
            setOpenComment(false);
          }}
        >
          {t('note.remark.submit')}
        </button>
      </div>
    </div>
  );

  return (
    <div className=" relative flex">
      {starEls}
      {openComment && commentEl}
    </div>
  );
}
