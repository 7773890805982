import { clsxm } from '@hidock/utils';
import { useGoogleLogin } from '@react-oauth/google';
import { useSignInWithGoogleMutation } from '~/api/users/signin-google';
import { RecordButton, UploadButton } from '~/components/actions';
import { Divider } from '~/components/Divider';
import { useIsUserModalVisible } from '~/components/header/Header';
import { Hero } from '~/components/hero';
import { setLoginModalVisible } from '~/components/modal';
import { TagOld } from '~/components/note/TagOld';
import { useIsLargeScreen } from '~/hooks/useIsLargeScreen';
import { getIsLogin, useIsUserLogin } from '~/store/user';
import { getWindow_WH, isMobile, parseDoubleBracketSyntax2 } from '~/utils/utils';
import { atom, getDefaultStore } from 'jotai';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Navigate } from 'react-router-dom';
import Balancer from 'react-wrap-balancer';

/**
 * 临时实现的一套登录后直接重定向到录音的机制
 */
const instantRecordAtom = atom(false);
export const enableInstantRecord = () => {
  if (getIsLogin()) return;
  getDefaultStore().set(instantRecordAtom, true);
};

export function PageIndex() {
  const isLg = useIsLargeScreen();
  const isUserLogin = useIsUserLogin();

  const { t } = useTranslation();
  if (isUserLogin) {
    location.href = `/notes?${import.meta.env.VITE_NOTE_VERSION}`;
    return;
  }

  const WH = getWindow_WH();
  return (
    <>
      <div
        className={'flex h-[100vh] flex-shrink-0 flex-col items-center justify-between pt-[10px] 2xl:pt-[167px]'}
        style={{
          height: isMobile() ? WH.windowHeight : undefined,
        }}
      >
        <Hero size={isLg ? 'lg' : 'sm'} className="" />
        <section className={clsxm('space-y-8', 'flex flex-col', 'lg:flex-row lg:space-x-16 lg:space-y-0')}>
          {!isMobile() ? (
            <UploadButton size="lg" describe={t('misc.upload.support.describe2')} />
          ) : (
            <RecordButton size="lg" />
          )}
        </section>
        <span>
          <Suspense>
            <SignIn />
          </Suspense>
          <div className="h-[10px] max-2xl:h-0"></div>
          <Divider />
        </span>
      </div>
      {/* <Cards /> */}
      <Intro />
      <Footer />
    </>
  );
}

export const SignIn = () => {
  const isUserLogin = useIsUserLogin();
  const isLogin = useIsUserLogin();
  const [, setUserModalVisible] = useIsUserModalVisible();
  const { mutate } = useSignInWithGoogleMutation();
  const login = useGoogleLogin({
    onSuccess(res) {
      mutate({ credential: res.access_token });
    },
  });
  const { t } = useTranslation();
  function policyStatementEl() {
    const contentKey = 'user.policy';
    const policyConent = parseDoubleBracketSyntax2(t(contentKey));
    const ElHash = {
      '.privacy-policy'(text: string) {
        return (
          <Link className="mx-1 underline" to={'/privacy-policy'}>
            {text}
          </Link>
        );
      },
      '.term-of-use'(text: string) {
        return (
          <Link className="mx-1 underline" to={'/term-of-service'}>
            {text}
          </Link>
        );
      },
    };
    return policyConent.map((text) => {
      //@ts-ignore
      if (text.type == 'BracketContent') return ElHash[text.content]?.(t(contentKey + text.content)) || text.content;
      if (text.type == 'text') return text.content;
    });
  }
  if (isUserLogin) return null;

  return (
    <section className=" flex flex-col items-center">
      <div className="relative flex w-full justify-center">
        <div
          className=" flex h-[40px] w-[200px] cursor-pointer items-center justify-center rounded-xl border border-[#ACACAC] px-2 py-4 text-[14px] font-bold text-[#686868] hover:border-[#00A2AF] hover:text-[#00A2AF]"
          onClick={() => {
            login();
          }}
        >
          <svg
            className="mr-1"
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M17.6496 10.3713C17.6496 9.75677 17.5998 9.30832 17.4918 8.84326H10.4744V11.617H14.5935C14.5105 12.3063 14.062 13.3444 13.0654 14.042L13.0514 14.1348L15.2702 15.8537L15.424 15.869C16.8357 14.5652 17.6496 12.6468 17.6496 10.3713Z"
              fill="#4285F4"
            />
            <path
              d="M10.4741 17.6793C12.4921 17.6793 14.1863 17.0149 15.4237 15.8689L13.0652 14.0418C12.434 14.482 11.5869 14.7893 10.4741 14.7893C8.49762 14.7893 6.82008 13.4855 6.22208 11.6833L6.13443 11.6908L3.82729 13.4763L3.79712 13.5602C5.0262 16.0017 7.55084 17.6793 10.4741 17.6793Z"
              fill="#34A853"
            />
            <path
              d="M6.22219 11.6832C6.06441 11.2182 5.97309 10.7198 5.97309 10.205C5.97309 9.69004 6.06441 9.19178 6.21389 8.72672L6.20971 8.62768L3.87366 6.81348L3.79723 6.84983C3.29067 7.86302 3 9.00079 3 10.205C3 11.4091 3.29067 12.5469 3.79723 13.56L6.22219 11.6832Z"
              fill="#FBBC05"
            />
            <path
              d="M10.4741 5.62073C11.8776 5.62073 12.8243 6.22697 13.3641 6.73359L15.4735 4.67401C14.178 3.46983 12.4921 2.73071 10.4741 2.73071C7.55084 2.73071 5.0262 4.40825 3.79712 6.84982L6.21378 8.72671C6.82008 6.92459 8.49762 5.62073 10.4741 5.62073Z"
              fill="#EB4335"
            />
          </svg>
          {t('user.auth.google')}
        </div>
      </div>
      <div className="relative mt-[26px] flex w-full justify-center xl:mt-[10px]">
        <div
          className=" flex h-[40px] w-[200px] cursor-pointer items-center justify-center rounded-xl border  border-[#ACACAC] px-2 py-4 text-[14px] font-bold text-[#686868] hover:border-[#00A2AF] hover:text-[#00A2AF]"
          onClick={() => {
            if (isLogin) {
              setUserModalVisible(true);
            } else {
              setLoginModalVisible(true);
            }
          }}
        >
          {t('app.more-options')}
        </div>
      </div>
      <div className="text-solid mt-2 whitespace-pre text-center text-xs opacity-70">{policyStatementEl()}</div>
    </section>
  );
};

const Intro = () => {
  return (
    <section className="mt-[44px] text-center text-[#283040]">
      <img className="m-[30px_auto_0] lg:hidden" src="/intro-mobile.png?version=1" />
      <img className="mx-auto mt-[30px] hidden lg:block" src="/intro-pc.jpeg" />
    </section>
  );
};

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className={clsxm('flex justify-center')}>
      <ul
        className={clsxm(
          'text-solid flex grow justify-between pb-6 text-xs font-semibold opacity-30',
          'mx-[23px] mt-[22px]',
          'lg:mt-[93px] lg:grow-0 lg:justify-normal lg:space-x-6'
        )}
      >
        <li>© HiDock.com</li>
        <li className="bg-solid w-px"></li>
        <li>
          <Link to={'/privacy-policy'}>{t('misc.privacy-policy')}</Link>
        </li>
        <li className="bg-solid w-px"></li>
        <li>
          <Link to={'/term-of-service'}>{t('misc.term-of-use')}</Link>
        </li>
        <li className="bg-solid w-px"></li>
        <li>
          <Link to={'/faq'}>FAQ</Link>
        </li>
      </ul>
    </div>
  );
};
