import i18n from '../../../i18n';

function createList(index, arr) {
  let text = '';
  arr.forEach((item) => {
    if (typeof item == 'string') text += item + '\n';
    else {
      for (let i = 0; i < item.length; i++) {
        text += '\t' + item[i] + '\n';
      }
    }
  });
  return [
    {
      insertText: {
        text: '\n' + text,
        location: {
          index,
        },
      },
    },
    {
      createParagraphBullets: {
        range: {
          startIndex: index + 1,
          endIndex: index + text.length + 1,
        },
        bulletPreset: 'NUMBERED_DECIMAL_NESTED',
      },
    },
  ];
}

function createH3(index, text) {
  return [
    {
      insertText: {
        text: '\n' + text,
        location: {
          index: index, // Modified
        },
      },
    },
    {
      updateTextStyle: {
        textStyle: {
          bold: true,
          fontSize: {
            magnitude: 18,
            unit: 'PT',
          },
        },
        fields: '*',
        range: {
          startIndex: index + 1,
          endIndex: index + text.length + 1,
        },
      },
    },
  ];
}

function createH1(index, text) {
  return [
    {
      insertText: {
        text: text + '\n',
        location: {
          index: index, // Modified
        },
      },
    },
    {
      updateTextStyle: {
        textStyle: {
          bold: true,
          fontSize: {
            magnitude: 24,
            unit: 'PT',
          },
        },
        fields: '*',
        range: {
          startIndex: index,
          endIndex: index + text.length + 1,
        },
      },
    },
  ];
}

function innerText(index, text) {
  return [
    {
      insertText: {
        text: '\n' + text + '\n',
        location: {
          index: index, // Modified
        },
      },
    },
  ];
}

function createContent(data, title, fields, createTime) {
  try {
    let reqs = [];
    [...fields].reverse().forEach((field) => {
      const value = data[field.name];
      if (!value) return;
      const content = value instanceof Array ? createList(1, value) : innerText(1, value);
      const titleJson = createH3(1, field.value);
      reqs.push(...content, ...titleJson);
    });
    if (createTime) {
      let time = `${i18n.t('note.info.summary.creation_time')}:${new Date(createTime).toLocaleDateString('zh-CN', {
        hour12: false,
      })}\n`;
      reqs.push({
        insertText: {
          text: time,
          location: {
            index: 1, // Modified
          },
        },
      });
    }

    if (title) {
      const titleReq = createH1(1, title);
      reqs.push(...titleReq);
    }
    return reqs;
  } catch (err) {
    console.log('err');
    return null;
  }
}

function google_auth() {
  return new Promise((res, rej) => {
    tokenClient.callback = async (resp) => {
      if (resp.error !== undefined) {
        //   throw (resp);
        res({
          status: false,
          message: resp,
        });
      } else {
        res({
          status: true,
        });
      }
    };
    if (gapi.client.getToken() === null) {
      // Prompt the user to select a Google Account and ask for consent to share their data
      // when establishing a new session.
      tokenClient.requestAccessToken({ prompt: 'consent' });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      tokenClient.requestAccessToken({ prompt: '' });
    }
  });
}

export async function share_docs(data, title, fields, createTime) {
  try {
    const authRes = await google_auth();
    const reqs = createContent(data, title, fields, createTime);
    if (authRes.status == false) return authRes;
    const response = await gapi.client.docs.documents.create({
      title,
    });
    console.log('google documents.create', response);
    const id = response.result.documentId;
    const info = await gapi.client.docs.documents.batchUpdate({
      documentId: id,
      requests: reqs,
    });
    console.log('google documents.batchUpdate', response);
    return {
      status: true,
      message: info,
    };
  } catch (err) {
    console.log('google share error', err);
    return {
      status: false,
      message: err,
    };
  }
}
