import { clsxm } from '@hidock/utils';
import { useLatest } from 'ahooks';
import { Tooltip } from 'antd';
import { CSSProperties, FC, SVGProps, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useHover } from 'usehooks-ts';

function DuiGou(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      width="16.97100257873535"
      height="12.020999908447266"
      viewBox="0 0 16.97100257873535 12.020999908447266"
      {...props}
    >
      <g>
        <path d="M6.364,9.193L15.556,0L16.971,1.414L6.364,12.021L0,5.657L1.414,4.243L6.364,9.193Z" />
      </g>
    </svg>
  );
}

export const CheckBox: FC<{
  className?: string;
  onChange: (value: boolean) => void;
  value?: boolean;
  style?: CSSProperties;
}> = (props) => {
  const { value, className, onChange } = props;

  return (
    <div
      className={clsxm(
        ' flex  aspect-square w-[26px] cursor-pointer items-center justify-center rounded-[4px] bg-[#00A2AF]',
        className
      )}
      style={{
        background: value ? '#00A2AF' : 'rgb(216,216,216)',
        ...props.style,
      }}
      onClick={() => onChange(!value)}
    >
      <DuiGou
        style={{
          fill: value ? '#FFFFFF' : '#ACACAC',
        }}
      />
    </div>
  );
};

export const FileName: FC<{
  name: string;
  date: string;
  className?: string;
  isRecording?: boolean;
  id?: string;
}> = (props) => {
  const { name, date, className, isRecording } = props;
  const navigate = useNavigate();
  return (
    <div className={clsxm(' whitespace-pre', className)}>
      <p
        className=" overflow-hidden text-ellipsis text-[18px] font-[600] leading-[1.5] text-black"
        style={{
          cursor: props.id ? 'pointer' : '',
        }}
        onClick={() => props.id && navigate(`/notes/${props.id}`)}
      >
        {name}
      </p>
      <p
        className=" mt-[6px] text-[14px] font-[400] text-[#888888]"
        style={{
          visibility: isRecording ? 'hidden' : 'visible',
        }}
      >
        {date}
      </p>
    </div>
  );
};

export const CloudState: FC<{
  className?: string;
  state: number | 'success' | 'fail' | null;
}> = (props) => {
  function getState() {
    if (props.state == 'success') {
      return <img className="w-6" src="/connectDevice/duigou.png" />;
    } else if (props.state == 'fail') {
      return <img className="w-6 scale-[0.78]" src="/connectDevice/fail.png" />;
    } else if (typeof props.state == 'number') {
      return `${(props.state * 100) | 0}%`;
    }
    return null;
  }

  return (
    <div className={clsxm(' w-[46px] text-center text-[16px] font-[600] text-[#29BEFE]', props.className)}>
      {getState()}
    </div>
  );
};

export const DeleteBtn: FC<{
  className?: string;
  type: 'bg-white' | 'bg-grey';
  isRecording?: boolean;
  onClick: () => void;
}> = (props) => {
  const { className, type, isRecording } = props;
  const trashRef = useRef<HTMLDivElement | null>(null);
  const ishover = useHover(trashRef);
  const { t } = useTranslation(undefined, {
    keyPrefix: 'wu.recordings',
  });

  return (
    <Tooltip title={t('delete.tip')}>
      <div
        className={clsxm(
          `flex h-[45px] w-[45px] flex-shrink-0 cursor-pointer items-center justify-center rounded-[8px] hover:shadow-lg`,
          `${type == 'bg-white' ? 'bg-[#f5f5f5]' : 'bg-white'}`,
          className
        )}
        style={isRecording ? { background: '#ACACAC', pointerEvents: 'none' } : undefined}
        ref={trashRef}
        onClick={props.onClick}
      >
        <img
          className="w-[24px]"
          src={`/connectDevice/${!ishover ? 'delete' : 'delete2'}${isRecording ? '-white' : ''}.png`}
        />
      </div>
    </Tooltip>
  );
};

export const NoteUpload: FC<{
  className?: string;
  isRecording?: boolean;
  state: 'general' | 'uploaded' | 'loading';
  cloudState: number | 'success' | 'fail' | null;
  completeHandle: () => void;
  notCompleteHandle: () => void;
}> = (props) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'wu.recordings',
  });

  const [showProgress, setShowProgress] = useState(0);
  const latestInfoRef = useLatest({
    cloudState: props.cloudState,
    showProgress,
  });
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (typeof props.cloudState == 'number') {
      const timeInterval = 100;
      function exec() {
        const latestInfo = latestInfoRef.current;
        if (latestInfo.cloudState != null && latestInfo.cloudState != 'fail' && latestInfo.cloudState != 'success') {
          let progress = Math.min(latestInfo.showProgress + 0.01, latestInfo.cloudState);
          setShowProgress(progress);
        }
        timerRef.current = setTimeout(exec, timeInterval);
      }
      timerRef.current = setTimeout(exec, timeInterval);
    } else {
      if (timerRef.current) clearTimeout(timerRef.current);
      setShowProgress(0);
    }
  }, [props.cloudState]);

  function getState() {
    if (props.cloudState == 'success') {
      return <img className="w-6" src="/connectDevice/duigou.png" />;
    } else if (props.cloudState == 'fail') {
      return <img className="w-6 scale-[0.78]" src="/connectDevice/fail.png" />;
    } else if (typeof props.cloudState == 'number') {
      return `${(showProgress * 100) | 0}%`;
    }
    return null;
  }

  return (
    <>
      {props.state == 'uploaded' && (
        <Tooltip title={t('complete')}>
          <div
            className={clsxm(
              `flex h-[45px] w-[45px] flex-shrink-0 cursor-pointer items-center justify-center rounded-[8px] `,
              props.className
            )}
            onClick={props.completeHandle}
          >
            <img className="h-[25px] w-[20px] cursor-pointer" src="/connectDevice/check.svg" />
          </div>
        </Tooltip>
      )}
      {props.state == 'loading' && (
        <div
          className={clsxm(
            'flex h-[45px] w-[45px] items-center justify-center text-center text-[16px] font-[600] text-[#29BEFE]',
            props.className
          )}
        >
          {getState()}
        </div>
      )}
      {props.state == 'general' && (
        <Tooltip title={t('upload.tip')}>
          <div
            className={clsxm(
              `flex h-[45px] w-[45px] flex-shrink-0 cursor-pointer items-center justify-center rounded-[8px] `,
              props.className
            )}
            style={{
              pointerEvents: props.isRecording ? 'none' : undefined,
            }}
            onClick={props.notCompleteHandle}
          >
            <img
              className="w-[26px]  cursor-pointer "
              src={`/connectDevice/upload${props.isRecording ? '-white.png' : '.svg'}`}
            />
          </div>
        </Tooltip>
      )}
    </>
  );
};

export const DownloadBtn: FC<{
  type: 'bg-white' | 'bg-grey';
  isRecording?: boolean;
  donwloadHandle: () => void;
  isDownloading?: boolean;
  className?: string;
}> = (props) => {
  const { type, isDownloading, isRecording, donwloadHandle } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'wu.recordings',
  });

  return (
    <Tooltip title={t('download.tip')}>
      <div
        className={clsxm(
          `mr-[30px] flex h-[45px] w-[45px] flex-shrink-0 cursor-pointer items-center justify-center rounded-[8px] hover:shadow-lg`,
          `${type == 'bg-white' ? 'bg-[#f5f5f5]' : 'bg-white'}`,
          props.className
        )}
        style={{
          backgroundColor: isRecording ? '#ACACAC' : '',
          pointerEvents: isRecording ? 'none' : undefined,
        }}
        onClick={donwloadHandle}
      >
        <img
          className="w-[24px]"
          src={isDownloading ? '/loading2.svg' : `/connectDevice/download${isRecording ? '-white' : ''}.png`}
        />
      </div>
    </Tooltip>
  );
};
