import { DeviceLatest } from '~/api/device/latest';
import { Dispatch, ReactNode, SetStateAction } from 'react';

export enum BtnState {
  notConnection = 'notConnection',
  notSupport = 'notSupport',
  connected = 'connected',
}

export type DiviceSettingKind = 'file.auto-upload' | 'webusb-notification';

export type FileInfo = {
  name: string;
  length: number;
  signature: string;
  title?: string;
  id?: string;
  state?: string;
  time: Date;
  createDate: string;
  createTime: string;
  duration: number;
  uploading?: boolean;
  isDelete?: boolean;
  progress?: number;
  updateState?: string;
  message?: string | null;
  fileDownLoading?: boolean;
  language?: string;
  checked?: boolean;
  template?: string;
};

export type ShowFileProps = {
  setBtnState: Dispatch<SetStateAction<BtnState>>;
  isShow: boolean;
};

export type DeviceContainerProps = {
  setBtnState?: (state: BtnState) => void;
  children?: ReactNode;
  className?: string;
  onClick?: () => void;
};

export type FileItemProps = {
  onDelete: () => void;
  fileInfo: FileInfo;
  setFilesInfos: Dispatch<SetStateAction<FileInfo[]>>;
  type: 'bg-white' | 'bg-grey';
  addUploadTask: (task: Function) => void;
};

export type FileItemRef = {
  onUpdate: () => Promise<boolean>;
  fileInfo: FileInfo;
  setUploading: (state: boolean) => void;
};

export type deviceUpdateProps = {
  setBtnState: Dispatch<SetStateAction<BtnState>>;
  version: string;
  describe: string;
  id: string;
  deviceInfo: {
    version: string;
    versionNumber: number;
    sn: string;
  };
};

export type DeviceUpdatingProps = {
  setBtnState: Dispatch<SetStateAction<BtnState>>;
  deviceLatestInfo: DeviceLatest;
};

export type DeviceUpdateEndProps = {
  setBtnState: Dispatch<SetStateAction<BtnState>>;
};

export type DeviceConfigInfo = {
  autoRecord: boolean;
  recordNotice: boolean;
  autoUpload: boolean;
  webusbNotification: boolean;
  blueToothTone: boolean
};
