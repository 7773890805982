import { NoteList } from '~/components/note';
import { useEntryInfo } from '~/store/entry';
import { useNeedUpdate } from '~/store/isUpdate';
import type { RefObject } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import Header from './Header';
import { NoteSidebar } from './Sidebar';
import { RecentlyNoteList } from '~/components/note/recentlyNoteList';

export const NotesLarge = () => {
  const { t } = useTranslation();
  const [containerRef, setContainerRef] = useState<RefObject<HTMLDivElement> | null>(null);
  const [entryInfo] = useEntryInfo();
  const [needUpdate] = useNeedUpdate();

  function publicText() {
    if (needUpdate) return t('misc.PageUpgradeTip');
    if (entryInfo.announcement) return <a href={entryInfo.announcement.link}>{entryInfo.announcement.text}</a>;
    return `"${entryInfo.quote?.content}" - ${entryInfo.quote?.author}`;
  }

  // 这里使用了 flex-row-reverse 防止出现图层问题
  return (
    <div className="h-[100vh] w-[100vw] bg-gradient-to-tl from-[#F4F5F6] to-[#EAECED]">
      <div className=" flex h-[60px] items-center justify-center bg-[#3A3A3A] text-[20px] text-[#FFFFFF]">
        {publicText()}
      </div>
      <div className="flex h-[calc(100vh-60px)] flex-shrink-0 flex-row-reverse items-stretch overflow-hidden">
        <div className="relative flex h-[100%] w-[calc(100vw-313px)] flex-shrink-0 flex-col">
          {/* modal用的 */}
          <Outlet />
          <Header />
          <main className="relative flex grow flex-col overflow-hidden">
            <div className="grow overflow-hidden">
              <div ref={containerRef} className="h-full w-full overflow-x-hidden overflow-y-scroll">
                <RecentlyNoteList />
                <NoteList
                  className="mb-2 mr-[20px] grid gap-[15px] p-[10px] lg:grid-cols-3 xl:grid-cols-4"
                  onBottomObserverInit={(ref) => setContainerRef(ref as RefObject<HTMLDivElement>)}
                />
              </div>
            </div>
          </main>
        </div>
        <NoteSidebar className="w-[313px]" />
      </div>
    </div>
  );
};
