import { myMessage } from '~/components/MyToast/MyToast';
import { TFunction } from 'i18next';
import { useCallback, useRef } from 'react';
import { addData, dbTable } from '~/lib/db';

export function deleteCookie(name: string) {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
}

export function EventLoop() {
  let queue: Function[] = [];
  let execing = false;
  let pas = false;
  const exec = async () => {
    if (execing || pas) return;
    execing = true;
    while (queue.length) {
      const fn = queue.shift()!;
      await fn();
    }
    execing = false;
  };

  return {
    addTask(task: Function) {
      queue.push(task);
      exec();
    },
    isExecuting() {
      return execing;
    },
    /**正在执行德函数不会暂停，后续队列德函数才会暂停 */
    pause() {
      pas = true;
    },
    start() {
      pas = false;
      exec();
    },
    init() {
      execing = false;
      queue = [];
    },
  };
}

/**直接将内容粘贴到剪切板中 */
export function pasteRich(dom: HTMLElement) {
  let range = document.createRange();
  const selection = window.getSelection();
  if (!selection) return false;
  selection.removeAllRanges(); //先清除掉选中区域
  range.selectNode(dom);
  selection.addRange(range);
  let res = document.execCommand('copy');
  selection.removeAllRanges();
  return true;
}

export async function loadingTimeControl<S>(
  promise: Promise<S>,
  minTime: number = 0,
  maxTime: number = 9999999
): Promise<'timeout' | S> {
  let state:
    | {
        result: S;
      }
    | null
    | 'minTimeOut' = null;
  return new Promise((resolve) => {
    setTimeout(() => {
      return resolve('timeout');
    }, maxTime);

    setTimeout(() => {
      if (state instanceof Object) resolve(state.result);
      else state = 'minTimeOut';
    }, minTime);

    promise.then((returnValue) => {
      if (state != 'minTimeOut') {
        state = {
          result: returnValue,
        };
      } else {
        resolve(returnValue);
      }
    });
  });
}

export function isPC() {
  var userAgentInfo = navigator.userAgent;
  var keywords = ['Windows', 'Macintosh', 'Linux', 'X11'];

  for (var i = 0; i < keywords.length; i++) {
    if (userAgentInfo.indexOf(keywords[i]) >= 0) {
      return true;
    }
  }

  return false;
}

/**
 *
 * @param fn 每次运行用的都是最新的
 * @param time
 * @returns
 */
export function useMyDebounce<T extends Array<any>, K>(fn: (...arg: T) => K, time: number) {
  let timerRef = useRef<null | NodeJS.Timeout>(null);
  let fnRef = useRef<typeof fn>(fn);
  fnRef.current = fn;
  return useCallback((...params: T) => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      fnRef.current(...params);
      timerRef.current = null;
    }, time);
  }, []);
}

export function parseDoubleBracketSyntax(input: string, data: Record<string, string | number | undefined>) {
  // 创建正则表达式模式来匹配双括号内容
  const pattern = /\{\{(.*?)\}\}/g;
  return input.replace(pattern, (text) => {
    const value = data[text.slice(2, -2)];
    //@ts-ignore
    if ([undefined, null].includes(value)) return text;
    return value + '';
  });
}
/** 解析成数组，里面带标识 */
export function parseDoubleBracketSyntax2(input: string) {
  const pattern = /\{\{(.*?)\}\}/g;
  const texts = input.match(pattern) || [];
  const data: {
    type: 'BracketContent' | 'text';
    content: string;
  }[] = [];
  let pre = 0;
  texts.forEach((text) => {
    const textIdx = input.indexOf(text);
    if (textIdx != 0)
      data.push({
        type: 'text',
        content: input.slice(pre, textIdx),
      });
    data.push({
      type: 'BracketContent',
      content: text.slice(2, -2),
    });
    pre = textIdx + text.length;
  });
  if (input.length != pre)
    data.push({
      type: 'text',
      content: input.slice(pre),
    });

  return data;
}

export function throttle<T extends any[]>(
  fn: (...params: T) => any,
  time = 1000,
  opiton?: { immediatelyExec?: boolean }
) {
  const { immediatelyExec = true } = opiton || {};
  let timer: null | NodeJS.Timeout = null;
  let p: T | null = null;
  return (...params: T) => {
    p = params;
    if (timer) return;
    if (immediatelyExec) fn(...p!);
    timer = setTimeout(() => {
      if (!immediatelyExec) fn(...p!);
      timer = null;
    }, time);
  };
}

export function generalErrorHandle(
  info: { error?: number; message?: string },
  t: TFunction<'translation', undefined>,
  time?: number,
  config: { directToast?: boolean } = { directToast: true }
) {
  if (!info.error) return;
  const key = `err.${info.error}`;
  //@ts-ignore
  let content = t(key) as string;
  if ((key == content || !content) && info.error) content = info?.message || key;
  if (config?.directToast) myMessage.error(content, time);
  return content;
}

export function isMobile() {
  if (/Mac OS/i.test(navigator.userAgent) && navigator.maxTouchPoints >= 1) return true;
  return /Mobi|Android|iPhone|iPod|iPad/i.test(navigator.userAgent);
}

export function reqParamsToBody(obj: Record<string, string | number>) {
  let pairs = [];
  for (let j in obj) {
    pairs.push(j + '=' + encodeURIComponent(obj[j]));
  }
  return pairs.join('&');
}

export function getWindow_WH() {
  let windowWidth = window.innerWidth;
  let windowHeight = window.innerHeight;
  if (typeof windowWidth !== 'number') {
    if (document.compatMode === 'CSS1Compat') {
      windowWidth = document.documentElement.clientWidth;
      windowHeight = document.documentElement.clientHeight;
    } else {
      windowWidth = document.body.clientWidth;
      windowHeight = document.body.clientHeight;
    }
  }
  return {
    windowWidth: windowWidth,
    windowHeight: windowHeight,
  };
}

enum Level {
  debug = 'debug',
  info = 'info',
  error = 'error',
}
type Message = {
  level: Level;
  module: string;
  procedure: string;
  message: string;
  time: number;
};

export const Logger = {
  messages: [] as Message[],
  consoleOutput: true,
  info(module: string, procedure: string, message: string) {
    this._append(Level.info, module, procedure, message);
  },
  debug(module: string, procedure: string, message: string) {
    this._append(Level.debug, module, procedure, message);
  },
  error(module: string, procedure: string, message: string) {
    this._append(Level.error, module, procedure, message);
  },
  save(module: string, procedure: string, message: any) {
    addData(dbTable.log, {
      module,
      procedure,
      message,
    });
  },
  _append(level: Level, module: string, procedure: string, message: string) {
    let log = {
      level,
      module,
      procedure,
      message: String(message),
      time: new Date().getTime(),
    };
    this.messages.push(log);
    if (this.consoleOutput) {
      this._print(log);
    }
    addData(dbTable.log, log);
    if (this.messages.length > 15000) this.messages.shift();
  },
  _print(log: Message) {
    let time = new Date(log.time);
    console.info(
      '[' +
        (log.level == 'error' ? 'x' : '*') +
        '][' +
        time.toLocaleString() +
        '](' +
        log.module +
        ' - ' +
        log.procedure +
        ') ' +
        log.message
    );
  },
  filter(module: string, procedure: string) {
    return this.messages.filter((i) => i.module == module && i.procedure == procedure);
  },
  enableConsoleOutput() {
    this.consoleOutput = true;
  },
  disableConsoleOutput() {
    this.consoleOutput = false;
  },
  peek(rows: number) {
    return this.messages.slice(-rows);
  },
  search(module: string, procedure: string, keyword?: string) {
    return this.messages.filter((i: Message) => {
      let r = i.module == module;
      if (!r) return false;
      if (procedure) {
        if (i.procedure != procedure) return false;
      }
      if (keyword) {
        if (i.message.indexOf(keyword) == -1) return false;
      }
      return true;
    });
  },
};

export function isEmojiCharacter(substring: string) {
  for (var i = 0; i < substring.length; i++) {
    var hs = substring.charCodeAt(i);
    if (0xd800 <= hs && hs <= 0xdbff) {
      if (substring.length > 1) {
        var ls = substring.charCodeAt(i + 1);
        var uc = (hs - 0xd800) * 0x400 + (ls - 0xdc00) + 0x10000;
        if (0x1d000 <= uc && uc <= 0x1f77f) {
          return true;
        }
      }
    } else if (substring.length > 1) {
      var ls = substring.charCodeAt(i + 1);
      if (ls == 0x20e3) {
        return true;
      }
    } else {
      if (0x2100 <= hs && hs <= 0x27ff) {
        return true;
      } else if (0x2b05 <= hs && hs <= 0x2b07) {
        return true;
      } else if (0x2934 <= hs && hs <= 0x2935) {
        return true;
      } else if (0x3297 <= hs && hs <= 0x3299) {
        return true;
      } else if (
        hs == 0xa9 ||
        hs == 0xae ||
        hs == 0x303d ||
        hs == 0x3030 ||
        hs == 0x2b55 ||
        hs == 0x2b1c ||
        hs == 0x2b1b ||
        hs == 0x2b50
      ) {
        return true;
      }
    }
  }
  return false;
}

export function parseEmojiChar(text: string) {
  const txtArr: {
    text: string;
    isEmoji: boolean;
  }[] = [];
  let temp = '';
  let preIsEmoji = false;
  for (let i = 0; i < text.length; i++) {
    let s = text[i];
    let isEmoji = isEmojiCharacter(s);
    if (!isEmoji && text[i + 1]) {
      s += text[i + 1];
      isEmoji = isEmojiCharacter(s);
      if (!isEmoji) s = text[i];
      else i++;
    }
    if (isEmoji == preIsEmoji) temp += s;
    else {
      txtArr.push({
        text: temp,
        isEmoji: preIsEmoji,
      });
      temp = s;
    }
    preIsEmoji = isEmoji;
  }
  txtArr.push({
    text: temp,
    isEmoji: preIsEmoji,
  });
  return txtArr;
}

export function locationHost() {
  return `${location.protocol}//${location.host}`;
}
