import { useMutation } from '@tanstack/react-query';
import { request } from '~/lib/axios';
import { setAccessToken, setGi } from '~/store/user';
import { defineMutation } from '../helper';

export const deleteUser = defineMutation(() => {
  return request.post('/v1/user/delete');
});

export const useDeleteUserModal = () =>
  useMutation(deleteUser, {
    onSuccess() {
      setAccessToken('');
      setGi('');
      window.location.href = `/?${import.meta.env.VITE_NOTE_VERSION}`;
    },
  });
