import { clsxm } from '@hidock/utils';
import type { Transcription2 } from '~/api/note2s/transcirption/transcriptions2';
import { UserType } from '~/api/users/types';
import i18n from '~/i18n';
import { showUpgradeTipAtom } from '~/layouts/RootLayout';
import { formatSecondsToProgress } from '~/pages/record/utils';
import { useUserInfo } from '~/store/user';
import { useHover, useInfiniteScroll, useUpdate, useUpdateEffect } from 'ahooks';
import { atom, getDefaultStore, useAtom, useSetAtom } from 'jotai';
import React, { memo, MutableRefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import HoverCard from '../hover-card/hover-card';
import CopyBtn from './copyBtn';
import Loading from './Loading/Loading';
import { SelectionBtn, selectionInfoAtom } from './markingWords/selectionBtn';
import ModeSpeaker from './mode-speaker/modeSpeaker';
import { noteStateAtom, PlayerParagraphAtom, SpeakerList, SpeakerNoteGlobalData } from './note-result2';
import css from './note-result2.module.css';
import { colors } from './pubilcInfo';
import { abbreviationName, getStrHash } from './utils';

const markChar = '📌';
const defaultPageSize = 100;

export type Word = {
  idx: number;
  word: string;
  preWord: string;
  transcription: Sentece;
  beginTime: number;
  endTime: number;
};

export type Sentece = Transcription2 & {
  words: Word[];
  marks: number[];
};

export type ParagraphData = {
  speaker: string;
  color?: string;
  sentences: Sentece[];
  marks: number[];
  words: Word[];
  beginTime: number;
  endTime: number;
};

export type TranscriptionProps = {
  dataset: {
    sentencess: Sentece[][];
    speakerList?: SpeakerList;
    currentTime: number;
    playState: string;
    hasEditor?: boolean;
  };
  event: {
    updateName?: (sentenceIds: string[], newName: string, oldName: string) => void;
    play?: (sentenceIds: string[]) => void;
    pause?: () => void;
    updateText?: (noteId: string, sentenceId: string, index: number, newToken: string) => void;
    globalUpdateText?: (sentenceId: string, oldWord: string, newWord: string) => void;
    contentHover?: (sentenceIds: string[], state: boolean) => void;
    markHover?: (sentenceId: string, timestamp: number) => void;
  };
  state?: {
    ParagraphCluster?: number;
    loading?: boolean;
  };
  noteGlobalDataRef: MutableRefObject<SpeakerNoteGlobalData>;
};

function Transcription(props: TranscriptionProps) {
  const { t } = useTranslation();
  const { dataset, event, state = {}, noteGlobalDataRef } = props;
  let { sentencess, speakerList = [], currentTime } = dataset;
  const setSelection = useSetAtom(selectionInfoAtom);
  const [pageIndex, setPageIndex] = useState(1);

  const paragraphDatass: ParagraphData[][] = sentencess.map((sentences, index) => {
    const defaultName = i18n.t('note.info.notSpeaker');
    const cluster = state.ParagraphCluster || 0;
    const paragraphDatas: ParagraphData[] = [];
    let preSpeaker: string | null = '';
    let sens: Sentece[] = [];
    let marks: number[] = [];
    let wordLen = 0;
    for (let i = 0; i < sentences.length; i++) {
      const sentence = sentences[i];
      /**
       * 三种情况停止句子的合并
       * 1.token大于cluster，并且一个段落必须有词
       * 2.前后说话人不一致
       */
      if ((wordLen >= cluster && wordLen > 0) || (i != 0 && preSpeaker != sentence.speaker)) {
        paragraphDatas.push({
          speaker: preSpeaker || '',
          sentences: sens,
          marks,
          words: ([] as Word[]).concat(...sens.map((sen) => sen.words)),
          beginTime: sens[0].beginTime,
          endTime: sens[sens.length - 1].endTime,
        });
        marks = [];
        sens = [];
        wordLen = 0;
      }
      wordLen += sentence.words.length;
      sens.push(sentence);
      marks.push(...sentence.marks);
      preSpeaker = sentence.speaker;
    }
    if (sens.length)
      paragraphDatas.push({
        speaker: preSpeaker || '',
        sentences: sens,
        marks,
        words: ([] as Word[]).concat(...sens.map((sen) => sen.words)),
        beginTime: sens[0].beginTime,
        endTime: sens[sens.length - 1].endTime,
      });
    return paragraphDatas;
  });

  const ContentEls: React.ReactNode[] = [];
  const contentDoms = useRef<
    {
      dom: HTMLDivElement | null;
      beginTime: number;
      endTime: number;
    }[]
  >([]).current;

  let paragCnt = 0;
  for (let i = 0, totalLen = 0; i < paragraphDatass.length; i++) {
    const paragraphDatas = paragraphDatass[i];
    if (!paragraphDatas.length) {
      const loading = (
        <div className="flex w-full items-center justify-center font-[40px] text-white" key={i}>
          {new Array(3).fill(0).map((a, idx) => {
            return <div className=" m-[10px] h-[20px] w-[20px] rounded-[50%] bg-white" key={idx} />;
          })}
        </div>
      );
      ContentEls.push(loading);
    } else {
      const Els = paragraphDatas.map((paragraphData, index) => {
        let curPos = totalLen;
        totalLen++;
        const isSelect = paragraphData.beginTime < currentTime && currentTime < paragraphData.endTime;
        return (
          <Paragraph
            playState={dataset.playState}
            paragraphData={paragraphData}
            speakerList={speakerList}
            event={event}
            isSelect={isSelect}
            key={i + ' ' + index}
            state={state}
            currentTime={currentTime}
            idx={paragCnt++}
            getDoms={(dom: HTMLDivElement | null) => {
              contentDoms[curPos] = {
                beginTime: paragraphData.beginTime,
                endTime: paragraphData.endTime,
                dom,
              };
            }}
          />
        );
      });
      ContentEls.push(...Els);
    }
  }

  //设置全局的dom元素
  const containerRef = useRef<HTMLDivElement | null>(null);

  //处理笔记也的全局信息
  useEffect(() => {
    noteGlobalDataRef.current.transcription.getParagraphDatas = () => {
      return ([] as ParagraphData[]).concat(...paragraphDatass);
    };

    return () => {
      noteGlobalDataRef.current.transcription = {};
    };
  });

  const preScorllTimeRef = useRef(-1);
  useEffect(() => {
    if (preScorllTimeRef.current != -1 && Date.now() - preScorllTimeRef.current < 3000) return;
    let targetDom: HTMLDivElement | null = null;
    const container = containerRef.current!;
    for (let i = 0; i < contentDoms.length; i++) {
      const { beginTime, endTime, dom } = contentDoms[i];
      if (beginTime <= currentTime && currentTime <= endTime) {
        targetDom = dom;
        break;
      }
    }
    if (!targetDom) {
      // container.scrollTo(0, container.scrollHeight);
    } else {
      container.scrollTo(0, targetDom.offsetTop - 70);
    }
  }, [currentTime]);
  const timeRef = useRef<NodeJS.Timeout | null>(null);
  const TimeScrollHandleRef = useRef<(() => void) | null>(null);
  const scrollHandle = (top: number, visibleHeight: number, height: number) => {
    if (top + visibleHeight > height - 70) setPageIndex(pageIndex + 1);
  };

  //临时增加的加载状态
  if (sentencess[0].length == 0) {
    return (
      <span className="relative h-[100%] w-[57.5%] overflow-hidden rounded-[16px] border border-[#E1E1E1] bg-[#FFFFFF]">
        <div
          className={`flex h-[100%] w-[100%] flex-col items-center justify-center  overflow-y-scroll rounded-[16px] p-[16px_4px_16px_16px] ${css.container} relative`}
          ref={containerRef}
        >
          <Loading />
          <span className=" mt-[10px] text-[16px] text-white">{t('misc.loading')}</span>
        </div>
      </span>
    );
  }

  return (
    <span className="relative h-[100%] w-[57.5%]  rounded-[12px] border  border-[#E1E1E1] bg-[#FFFFFF] p-[16px_4px_16px_16px]">
      <div
        className={`scrollbar scrollbar-thumb-gray-500 scrollbar-track-gray-200 relative h-[100%] w-[100%] overflow-x-hidden overflow-y-scroll`}
        translate="no"
        ref={containerRef}
        onScroll={(e) => {
          const store = getDefaultStore();
          if (store.get(selectionInfoAtom).openMethod == 'summary') {
            setSelection(selectionInfoAtom.init);
          }
          preScorllTimeRef.current = Date.now();
          const { scrollTop, clientHeight, scrollHeight } = e.currentTarget;
          TimeScrollHandleRef.current = () => {
            scrollHandle(scrollTop, clientHeight, scrollHeight);
          };
          if (!timeRef.current) {
            timeRef.current = setTimeout(() => {
              TimeScrollHandleRef.current?.();
              timeRef.current = null;
            }, 250);
          }
        }}
      >
        {ContentEls.slice(0, pageIndex * defaultPageSize)}
        <SelectionBtn />
      </div>
    </span>
  );
}

type ParagraphProps = {
  paragraphData: ParagraphData;
  speakerList: SpeakerList;
  event: TranscriptionProps['event'];
  state: TranscriptionProps['state'];
  getDoms: (dom: HTMLDivElement | null) => void;
  playState: string;
  isSelect?: boolean;
  currentTime: number;
  idx: number;
};

function Paragraph(props: ParagraphProps) {
  const { t } = useTranslation(undefined);
  const { paragraphData, speakerList, event, state, getDoms, isSelect, currentTime, idx, playState } = props;
  const [noteState] = useAtom(noteStateAtom);
  const speaker = paragraphData.speaker;
  const paragraphHeightRef = useRef<number>(0);
  const [isShowToken, setIsShowToekn] = useState(true);
  // const baColor = colors[speakerList.findIndex((item) => speaker == item)];
  const [PlayerParagraph] = useAtom(PlayerParagraphAtom);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const avatorRef = useRef<HTMLDivElement | null>(null);
  const isContentHover = useHover(contentRef);
  const setShowUpgradeTip = useSetAtom(showUpgradeTipAtom);
  const { data: userInfo } = useUserInfo();

  useUpdateEffect(() => {
    const sentenceIds = paragraphData.sentences.map((item) => item.id + '');
    event.contentHover?.(sentenceIds, isContentHover);
  }, [isContentHover]);

  //点击头像处理
  const isShowRef = useRef(false);
  const [showModeSpeaker, setShowModeSpeaker] = useState(false);
  // 全局点击重置rect.isShow,并更新
  useEffect(() => {
    const handleClick = () => {
      let isShow = isShowRef.current;
      setShowModeSpeaker(isShow);
      isShowRef.current = false;
    };
    window.addEventListener('click', handleClick);

    return () => {
      window.removeEventListener('click', handleClick);
    };
  }, []);

  const getModeSpeakerEl = () => {
    if (!showModeSpeaker) return null;
    const avator = avatorRef.current!;
    const rect = avator.getBoundingClientRect();
    return (
      <ModeSpeaker
        inputPlaceholder={t('note.info.change-speaker')}
        speakerList={speakerList}
        onSelect={(name) => {
          event.updateName?.(
            paragraphData.sentences.map((item) => item.id + ''),
            name,
            speaker
          );
          setShowModeSpeaker(false);
        }}
        defaultName={speaker}
        domRect={rect}
      />
    );
  };

  const showModifyName = () => {
    if (userInfo?.type != UserType.pro && !noteState.isPro) {
      setShowUpgradeTip(true);
      return;
    }
    isShowRef.current = true;
  };

  function AvatorElFn() {
    return (
      <div
        className={` mr-[12px] flex h-[50px] w-[50px] cursor-pointer items-center justify-center rounded-full text-[22px] font-[700] text-black`}
        style={{
          backgroundColor: speakerList.find((i) => i.speaker == speaker)?.color || '#888888',
          visibility: !noteState.isPro ? 'hidden' : undefined,
          pointerEvents: !noteState.isPro ? 'none' : undefined,
        }}
        ref={avatorRef}
        onClick={showModifyName}
      >
        {abbreviationName(speaker || t('note.info.notSpeaker')).toUpperCase()}
        {/* {showModeSpeaker && ModeSpecker()} */}
        {noteState.hasEditor && getModeSpeakerEl()}
      </div>
    );
  }

  useEffect(() => {
    const content = contentRef.current!;
    paragraphHeightRef.current = content.getBoundingClientRect().height;
    // setIsShowToekn(false);
  }, []);

  useEffect(() => {
    // const intersectionObserver = new IntersectionObserver(entries => {
    //     if(entries[0].intersectionRatio <= 0){
    //         setIsShowToekn(false);
    //         return;
    //     }
    //     setIsShowToekn(true);
    // });
    // intersectionObserver.observe(containerRef.current!);
    // return () => {
    //     intersectionObserver.unobserve(containerRef.current!);
    // }
  }, []);

  function ContentElFn() {
    const TokenEls: React.ReactNode[] = [];
    if (isShowToken) {
      paragraphData.sentences.forEach((sente, idx) => {
        let markIdx = 0;
        let marks = sente.marks;
        const words = sente.words;
        // #19C1CE
        for (let i = 0; i < words.length; i++) {
          const word = words[i];
          while (markIdx < marks.length && marks[markIdx] < word.beginTime) {
            TokenEls.push(
              <MarkFn
                key={`mark-${marks[markIdx]}`}
                event={event}
                sentenceId={word.transcription.id}
                timestamp={marks[markIdx]}
              />
            );
            markIdx++;
          }
          const isHigh =
            isSelect &&
            !!word.beginTime &&
            !!word.endTime &&
            word.beginTime <= currentTime &&
            currentTime <= word.endTime;
          TokenEls.push(
            <Token
              key={`token-${idx}-${i}`}
              event={event}
              data={word}
              state={state}
              isHigh={isHigh}
              hasToken={!!marks.length}
            />
          );
          if (i + 1 == words.length) {
            while (markIdx < marks.length) {
              TokenEls.push(
                <MarkFn
                  key={`mark-${marks[markIdx]}`}
                  event={event}
                  sentenceId={word.transcription.id}
                  timestamp={marks[markIdx]}
                />
              );
              markIdx++;
            }
          }
        }
      });
    }

    const isPlay =
      currentTime >= paragraphData.beginTime && currentTime <= paragraphData.endTime && playState != 'pause';
    return (
      <div className="flex-1" ref={contentRef}>
        <div className="flex h-[48px] items-center text-[20px]">
          <span className=" cursor-pointer font-[700]" onClick={() => showModifyName()}>
            {speaker || t('note.info.notSpeaker')}
          </span>
          <span className="ml-[16px] text-[#939394]">
            {formatSecondsToProgress((paragraphData.beginTime / 1000) | 0, true)}
          </span>
          <img
            src={isPlay ? '/paragraphPause.png' : '/paragraphPlay.png'}
            onClick={() => {
              if (isPlay) event.pause?.();
              else event.play?.(paragraphData.sentences.map((item) => item.id + ''));
            }}
            className="ml-[18px] h-[28px] scale-100 cursor-pointer duration-300 hover:scale-125"
            style={{
              visibility: 'visible',
            }}
          />
        </div>
        <div
          className="
                ml-[-10px] rounded-[12px] border-[1px] border-white
                border-opacity-0 p-[5px_10px]"
          key={'Paragraph content'}
          id="transcription-prograph"
        >
          {TokenEls}
        </div>
      </div>
    );
  }

  return (
    <div
      className="relative mb-[04px] flex content-between rounded-[14px] border border-transparent p-[18px_36px_18px_18px] "
      ref={(dom) => {
        containerRef.current = dom;
        getDoms(dom);
      }}
    >
      {AvatorElFn()}
      {ContentElFn()}
    </div>
  );
}

type WordProps = {
  data: Word;
  event: TranscriptionProps['event'];
  state: TranscriptionProps['state'];
  isHigh?: boolean;
  hasToken?: boolean;
};

const Token = React.memo(function (props: WordProps) {
  const { data, event, state, isHigh, hasToken } = props;
  const [noteState] = useAtom(noteStateAtom);
  const { noteId } = useParams();
  const [openEdit, setOpenEdit] = useState(false);
  const spanRef = useRef<HTMLSpanElement | null>(null);

  //打开时自动焦距
  useEffect(() => {
    if (openEdit) spanRef.current?.focus();
  }, [openEdit]);

  function getBgColor() {
    if ((openEdit || isHigh) && noteState.hasEditor) return '#0BF7E9';
    if (hasToken) return '#0BF7E9';
    return '#FFFFFF';
  }

  return (
    <em
      style={{
        background: getBgColor(),
      }}
    >
      <em
        className={` relative whitespace-pre-wrap text-[16px] not-italic leading-[32px] outline-0 focus-within:border-[rgb(189,189,189)]`}
        id="token"
        data-idx={data.transcription.id + '-' + data.idx}
        data-before-fixing={data.preWord}
        contentEditable={openEdit && noteState.hasEditor}
        suppressContentEditableWarning
        ref={spanRef}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            spanRef.current?.blur();
          }
        }}
        onBlur={() => {
          if (spanRef.current!.innerText != data.word) {
            // spanRef.current!.dataset['beforeFixing'] = data.word;
            event.updateText?.(
              noteId!,
              data.transcription.id,
              data.transcription.words.findIndex((i) => i == data),
              spanRef.current!.innerText
            );
            data.word = spanRef.current!.innerText;
          }
          setOpenEdit(false);
        }}
        onClick={() => {
          const selection = window.getSelection();
          if (state?.loading) return;
          if (selection?.toString().length != 0) return;
          setOpenEdit(true);
          spanRef.current?.focus();
        }}
        dangerouslySetInnerHTML={{
          __html: data.word,
        }}
      />
    </em>
  );
});

type MarkProps = {
  event: TranscriptionProps['event'];
  sentenceId: string;
  timestamp: number;
};

function MarkFn(props: MarkProps) {
  const { event, sentenceId, timestamp } = props;
  const spanRef = useRef<HTMLDialogElement | null>(null);
  const ishover = useHover(spanRef);

  useEffect(() => {
    if (ishover) {
      event.markHover?.(sentenceId, timestamp);
    } else {
      event.markHover?.('', timestamp);
    }
  }, [ishover]);

  return (
    <span className={clsxm([css.word, 'cursor-pointer bg-[#0BF7E9]'])} ref={spanRef}>
      {markChar}
    </span>
  );
}

export default memo(Transcription);
