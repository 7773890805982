import { NavigateFunction, useNavigate as useNavigateCore } from 'react-router-dom';

export function useMyNavigate(...params: Parameters<typeof useNavigateCore>): NavigateFunction {
  const navigate = useNavigateCore(...params);

  return function (...params) {
    if (typeof params[0] == 'number' && history.length + params[0] < window.myGlobal.historyLen) {
      return navigate('/notes');
    } else {
      return navigate(...params);
    }
  };
}
