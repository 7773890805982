import type { QueryFunctionContext } from '@tanstack/react-query';
import { noteStore } from '~/pages/notes/store';
import { useAtom } from 'jotai';
import { defineQuery, useAPIInfiniteQuery } from '../helper';
import type { NoteReq } from '../note';
import { noteReq } from '../note';

type Params = {
  tagId?: string;
  pageSize?: number;
};

export const list = (params?: Omit<NoteReq.noteInfo, 'pageIndex'>) => {
  return defineQuery(
    ['notes.list', params],
    async ({ pageParam: pageIndex }: QueryFunctionContext) => {
      return noteReq.getList({
        ...params,
        pageSize: params?.pageSize || 10,
        pageIndex: pageIndex || 0,
      });
    },
    {
      keyIndex: 1,
    }
  );
};

export const useNoteList = (params: Params & { enabled?: boolean }) => {
  const [noteSelect] = useAtom(noteStore.select);

  return useAPIInfiniteQuery(
    list({
      folderId: noteSelect.folderId,
      tagId: noteSelect.tagId,
      pageSize: params.pageSize,
    }),
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.last) return undefined;
        return lastPage.number + 1;
      },
    }
  );
};
