import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { useAtom, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import CheckoutForm from './CheckoutForm';
import { getTotal } from './PaymentPage';
import { clientSecretInfoAtom, paymentSelectAtom, paymentSkuAtom } from './paymentStore';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

export default function () {
  const [paySelect, setPaySelect] = useAtom(paymentSelectAtom);
  return (
    paySelect.sku && (
      <Elements
        options={{
          mode: 'payment',
          amount: +getTotal(paySelect.sku.unitPrice, paySelect.num).split('.').join(''),
          currency: paySelect.sku.currency,
          appearance: {
            theme: 'flat',
            variables: { colorPrimaryText: '#262626' },
          },
        }}
        stripe={stripePromise}
      >
        <CheckoutForm />
      </Elements>
    )
  );
}
