import { helpReq } from '~/api/help';
import { useMyNavigate } from '~/hooks/useNavigate';
import { useUserInfo } from '~/store/user';
import { generalErrorHandle } from '~/utils/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UAParser } from 'ua-parser-js';
import Loading from '../animation/loading';
import { UploadFile } from '../general/general';
import { MyModal } from '../general/myModal';
import { Loading as Loading2 } from '../Loading';

function FeedForm(props: { successHandle?: () => void }) {
  const { t } = useTranslation();
  const { data: userInfo } = useUserInfo();
  const [imgs, setImgs] = useState<
    {
      url: string;
      file: File;
    }[]
  >([]);
  const [title, setTitle] = useState('');
  const navigate = useMyNavigate();
  const contentMaxLen = 2000;
  const [content, setContent] = useState('');
  const [loading, setLoading] = useState(false);
  async function submitHandle() {
    setLoading(true);
    const parser = new UAParser(navigator.userAgent);
    const osInfo = parser.getOS();
    const browser = parser.getBrowser();

    const res = await helpReq.support({
      content,
      files: imgs.map((i) => i.file),
      email: userInfo?.email || '',
      title,
      os: osInfo.name + ' ' + osInfo.version,
      browser: browser.name + ' ' + browser.version,
    });
    if (!res) {
      props.successHandle?.();
    }
    //@ts-ignore
    if (res?.error == 90000) {
      generalErrorHandle(res, t, 2000);
    }
    setLoading(false);
  }

  /** 加入更多的照片的组件 */
  const addMoreImg = (
    <UploadFile
      onDrop={(files) => {
        // video-two.png
        console.log('files', files);
        const newImgs = files.map((file) => {
          if (file.type.slice(0, 5) == 'image') {
            const url = URL.createObjectURL(file);
            return {
              url,
              file,
            };
          } else {
            return {
              url: '/video-two.png',
              file,
            };
          }
        });
        setImgs((imgs) => {
          return [...imgs, ...newImgs].slice(0, 6);
        });
      }}
      accept={{
        'video/mp4': ['.mp4'],
        'video/x-msvideo': ['.avi'],
        'video/webm': ['.webm'],
        'video/mpeg': ['.mpeg', '.mpg'],
        'video/x-ms-wmv': ['.wmv'],
        'video/quicktime': ['.mov'],
        'application/octet-stream': ['.mkv'],
      }}
    >
      <div className=" mt-[16px] flex h-[96px] w-[140px] flex-shrink-0 cursor-pointer items-center justify-center rounded-[8px] border border-dashed border-[#A1AAAD] bg-[#F3F3F3]">
        <img src="/User/add2.png" className=" h-[22px] w-[20px]" />
      </div>
    </UploadFile>
  );

  /** 展示照片的组件 */
  function showImage(url: string) {
    return (
      <div
        className=" relative mr-[16px] mt-[16px] flex h-[96px] w-[140px] flex-shrink-0 items-center justify-center overflow-hidden rounded-[8px] border border-[#A1AAAD] bg-white"
        key={url}
      >
        <span
          className=" absolute right-[0px] top-[0px] z-50 cursor-pointer text-[16px] font-[500] leading-none text-white"
          onClick={() => {
            const idx = imgs.findIndex((i) => i.url == url);
            if (idx != -1) imgs.splice(idx, 1);
            setImgs([...imgs]);
          }}
        >
          x
        </span>
        <img src={url} className=" max-h-[96px]"></img>
        <div className=" pointer-events-none absolute h-full w-full bg-[rgba(0,0,0,0.1)]" />
      </div>
    );
  }

  return (
    <MyModal
      className=" z-[9999] flex max-h-screen w-[688px] flex-col items-center overflow-y-auto overflow-x-hidden rounded-[20px] px-[30px] text-[24px] font-bold"
      onClose={() => navigate(-1)}
    >
      <h1 className=" mt-[55px]">{t('user.info.customer-support')}</h1>
      <div className="  mt-[50px]  w-full">
        <div className=" mr-[30px] text-[18px] font-bold">{t('user.info.contact-email')}</div>
        <div className=" mt-[20px] flex h-[40px] w-full items-center rounded-[8px]  border border-[#CDD5D6] bg-[#EFF7F7] pl-[12px] text-[16px] font-[500]">
          {userInfo?.email}
        </div>
      </div>

      <div className="  mt-[20px]  w-full">
        <div className=" mr-[30px] text-[18px] font-bold">{t('user.info.customer-support.title')}</div>
        <input
          className=" mt-[20px] flex h-[40px] w-full items-center rounded-[8px]  border border-[#CDD5D6] bg-[#EFF7F7] pl-[12px] text-[16px] font-[500] outline-none"
          value={title}
          maxLength={100}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>

      <h2 className=" mt-[20px] w-full text-[18px] font-bold">{t('user.info.questions-or-ideas')}</h2>

      <div className=" relative mt-[20px] inline-block w-full rounded-[8px]">
        <textarea
          className=" h-[160px] w-full flex-shrink-0 rounded-[8px] border border-[#CDD5D6] bg-[#EFF7F7] p-[16px] text-[16px] font-[500] outline-none"
          maxLength={contentMaxLen}
          value={content}
          onChange={(e) => {
            setContent(e.target.value.slice(0, contentMaxLen));
          }}
        />
        <p className=" pointer-events-none absolute bottom-3 right-3 text-[14px] font-[400]">
          {content.length}/{contentMaxLen}
        </p>
      </div>
      <h2 className=" mt-[20px] w-full text-[18px] font-bold">{t('user.info.Screenshots--images')}</h2>
      <div className="flex w-full flex-wrap">
        {imgs.map((img) => showImage(img.url))}
        {imgs.length < 6 && addMoreImg}
      </div>

      <div className=" mt-[94px] flex w-[calc(100%+60px)] justify-center border-t border-[#E8EAEA] pb-[40px] pt-[30px] text-[16px] font-bold">
        <button
          className=" mr-[14px] h-[50px] w-[158px] rounded-[8px] bg-[#E8EAEA] text-black"
          onClick={() => navigate(-1)}
        >
          {t('misc.modal.cancel')}
        </button>
        <button
          className=" h-[50px] w-[158px] rounded-[8px] bg-[#A1AAAD] text-white hover:bg-[#00A2AF]"
          onClick={submitHandle}
        >
          {loading ? <Loading2 /> : t('user.info.submit')}
        </button>
      </div>
    </MyModal>
  );
}

function Success(props: { onClose?: () => void }) {
  const { t } = useTranslation();
  return (
    <MyModal
      className=" flex h-[533px] w-[688px] flex-col items-center rounded-[20px] px-[20px]"
      onClose={props.onClose}
    >
      <Loading className=" mt-[135px]" />
      <p className=" mt-[75px] whitespace-pre-wrap text-center text-[20px] font-[600] leading-[28px]">
        {t('user.info.feedback.submit-success')}
      </p>
    </MyModal>
  );
}

export function CustomerSupport() {
  const [showSuccess, setShowSuccess] = useState(false);
  const navigate = useMyNavigate();
  return showSuccess ? (
    <Success onClose={() => navigate(-1)} />
  ) : (
    <FeedForm successHandle={() => setShowSuccess(true)} />
  );
}
