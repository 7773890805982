import { clsxm } from '@hidock/utils';
import { fileInfoReq, newNoteInfo } from '~/api/device/fileInfo';
import { templateReq } from '~/api/templateReq';
import OverflowHiddenText from '~/components/general/Text';
import { myMessage } from '~/components/MyToast/MyToast';
import { formatSecondsToProgress } from '~/pages/record/utils';
import { downloadFile } from '~/utils/general';
import { sleep } from '~/utils/sleep';
import { EventLoop, generalErrorHandle, Logger, parseDoubleBracketSyntax, throttle } from '~/utils/utils';
import { useHover, useRequest } from 'ahooks';
import { Tooltip } from 'antd';
import { getDefaultStore, useAtom, useSetAtom } from 'jotai';
import React, {
  CSSProperties,
  FC,
  forwardRef,
  ReactNode,
  SVGProps,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClassValue } from 'tailwind-variants';
import { showConfirmAtom, useShowConfirm } from '../../deleteConfrim';
import { globalAddEl } from '../../globalLayout';
import { NoteListCardDataRefetchList } from '../../note';
import { Select, Select2 } from '../../userInfo/general';
import {
  allowOptimizeAtom,
  btnStateAtom,
  cardInfoAtom,
  deviceInfoAtom,
  downloadQueueAtom,
  fileInfosAtom,
  pauseGetFileInfos,
  useDeviceInfo,
} from '../deviceState';
import { BtnState, FileInfo, FileItemProps, FileItemRef } from '../deviceType';
import { DeviceOptimize } from '../Optimize';
import { deviceTool, fileUpload, getDeviceFileArrayBuffer, jensen } from '../utils';
import { CloudState, DeleteBtn, DownloadBtn, FileName, NoteUpload } from './comp';
import { uploadFileQueue } from './deviceManage';
import { TemplateSelect } from './templateSelect';

const colWidths = [76, 303.5, 74 + 77.5, 80 + 100, 210 + 67, 31 + 54];
const Svg = {
  download(props: React.SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width="25.000001907348633"
        height="22.5"
        viewBox="0 0 25.000001907348633 22.5"
        {...props}
      >
        <g>
          <path
            d="M13.0175,2.5L23.75,2.5C24.4404,2.5,25,3.05964,25,3.75L25,21.25C25,21.9404,24.4404,22.5,23.75,22.5L1.25,22.5C0.559644,22.5,0,21.9404,0,21.25L0,1.25C0,0.559644,0.559644,0,1.25,0L10.5175,0L13.0175,2.5ZM13.75,12.5L13.75,7.5L11.25,7.5L11.25,12.5L7.5,12.5L12.5,17.5L17.5,12.5L13.75,12.5Z"
            fill="#30B8F3"
            fill-opacity="1"
          />
        </g>
      </svg>
    );
  },
  delete(props: React.SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        fill="none"
        version="1.1"
        width="16"
        height="18"
        viewBox="0 0 16 18"
        {...props}
      >
        <g>
          <path
            d="M10.6789,2.75482Q10.6789,2.78158,10.6766,2.808L15.3232,2.808Q15.6035,2.808,15.8018,2.99433Q16,3.18065,16,3.44415Q16,3.70765,15.8018,3.89398Q15.6035,4.0803,15.3232,4.0803L14.295,4.0803L13.4148,15.5249Q13.334,16.5533,12.6045,17.2618Q11.8465,17.9981,10.7987,18L5.14164,18Q4.10916,17.994,3.35769,17.2727Q2.63405,16.5781,2.53943,15.5654L1.63407,5.83012Q1.63149,5.80225,1.63149,5.77469Q1.63149,5.51119,1.82973,5.32487Q2.02797,5.13854,2.30832,5.13854Q2.57154,5.13854,2.7656,5.30569Q2.95956,5.47275,2.98258,5.7193L3.88795,15.4546Q3.94015,16.0133,4.32488,16.3826Q4.68165,16.725,5.14929,16.7277L10.7984,16.7277Q11.2716,16.7268,11.6317,16.3771Q12.0201,15.9998,12.0648,15.4314L12.9378,4.0803L0.676832,4.0803Q0.396479,4.0803,0.19824,3.89398Q0,3.70765,0,3.44415Q0,3.18065,0.19824,2.99433Q0.396479,2.808,0.676832,2.808L5.30386,2.808Q5.30152,2.78158,5.30152,2.75482Q5.30152,1.63467,6.06268,0.833101Q6.4388,0.437011,6.93539,0.220392Q7.44062,0,7.99021,0Q8.5398,0,9.04504,0.220392Q9.54162,0.437011,9.91775,0.833101Q10.6786,1.63437,10.6789,2.75482ZM6.65518,2.75482Q6.65518,2.78158,6.65284,2.808L9.32758,2.808Q9.32524,2.78158,9.32524,2.75482Q9.32524,2.11987,8.90782,1.68029Q8.5204,1.2723,7.99021,1.2723Q7.46002,1.2723,7.0726,1.68029Q6.65518,2.11987,6.65518,2.75482Z"
            fill="#686868"
          />
        </g>
      </svg>
    );
  },
  duigou(props: React.SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        fill="none"
        version="1.1"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        {...props}
      >
        <defs>
          <clipPath id="master_svg0_2523_14953/2465_46461">
            <rect x="0.76904296875" y="0.76953125" width="18.461538314819336" height="18.461538314819336" rx="0" />
          </clipPath>
        </defs>
        <g>
          <g>
            <rect x="0" y="0" width="20" height="20" rx="4" />
          </g>
          <g clip-path="url(#master_svg0_2523_14953/2465_46461)">
            <g>
              <path
                d="M8.461542109375,12.438727499999999L15.532362109375,5.3671875L16.620762109375,6.4548775L8.461542109375,14.6141075L3.566162109375,9.7187275L4.653852109375,8.6310375L8.461542109375,12.438727499999999Z"
                fill="#FFFFFF"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  },
  duigouSelect(props: React.SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        fill="none"
        version="1.1"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        {...props}
      >
        <defs>
          <clipPath id="master_svg0_2523_14954/2465_46461">
            <rect x="0.76904296875" y="0.76953125" width="18.461538314819336" height="18.461538314819336" rx="0" />
          </clipPath>
        </defs>
        <g>
          <g>
            <rect x="0" y="0" width="20" height="20" rx="4" fill="#FFFFFF" fill-opacity="1" />
            <rect
              x="0.5"
              y="0.5"
              width="19"
              height="19"
              rx="3.5"
              fill-opacity="0"
              stroke-opacity="1"
              stroke="#00A2AF"
              fill="none"
              stroke-width="1"
            />
          </g>
          <g clip-path="url(#master_svg0_2523_14954/2465_46461)">
            <g>
              <path
                d="M8.46129796875,12.438727499999999L15.53211796875,5.3671875L16.62051796875,6.4548775L8.46129796875,14.6141075L3.56591796875,9.7187275L4.65360796875,8.6310375L8.46129796875,12.438727499999999Z"
                fill="#00A2AF"
              />
            </g>
          </g>
        </g>
      </svg>
    );
  },
  downArrow(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        version="1.1"
        width="11.666666030883789"
        height="6.666666507720947"
        viewBox="0 0 11.666666030883789 6.666666507720947"
        {...props}
      >
        <g>
          <path
            d="M0.244077,0.244077C0.569514,-0.0813592,1.09715,-0.0813592,1.42259,0.244077C1.42259,0.244077,5.83333,4.65483,5.83333,4.65483C5.83333,4.65483,10.2441,0.244077,10.2441,0.244077C10.5695,-0.0813592,11.0972,-0.0813592,11.4226,0.244077C11.748,0.569514,11.748,1.09715,11.4226,1.42259C11.4226,1.42259,6.42259,6.42259,6.42259,6.42259C6.09715,6.74802,5.56952,6.74802,5.24407,6.42259C5.24407,6.42259,0.244077,1.42259,0.244077,1.42259C-0.0813592,1.09715,-0.0813592,0.569514,0.244077,0.244077C0.244077,0.244077,0.244077,0.244077,0.244077,0.244077Z"
            fillRule="evenodd"
            fill="#767676"
          />
        </g>
      </svg>
    );
  },
  rightArrow(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        fill="none"
        version="1.1"
        width="6.666666507720947"
        height="11.666666030883789"
        viewBox="0 0 6.666666507720947 11.666666030883789"
        {...props}
      >
        <g>
          <path
            d="M0.244077,0.244077C0.569514,-0.0813592,1.09715,-0.0813592,1.42259,0.244077C1.42259,0.244077,6.42259,5.24407,6.42259,5.24407C6.74802,5.56952,6.74802,6.09715,6.42259,6.42259C6.42259,6.42259,1.42259,11.4226,1.42259,11.4226C1.09715,11.748,0.569514,11.748,0.244077,11.4226C-0.0813592,11.0972,-0.0813592,10.5695,0.244077,10.2441C0.244077,10.2441,4.65483,5.83333,4.65483,5.83333C4.65483,5.83333,0.244077,1.42259,0.244077,1.42259C-0.0813592,1.09715,-0.0813592,0.569514,0.244077,0.244077C0.244077,0.244077,0.244077,0.244077,0.244077,0.244077Z"
            fill-rule="evenodd"
            fill="#767676"
            fill-opacity="1"
          />
        </g>
      </svg>
    );
  },
};

const CheckBox: FC<{
  className?: string;
  onChange: (value: boolean) => void;
  value?: boolean;
  style?: CSSProperties;
}> = (props) => {
  const { value, className, onChange } = props;

  return (
    <div
      className={clsxm(
        ' flex aspect-square w-[20px] cursor-pointer items-center justify-center rounded-[4px]',
        className
      )}
      style={{
        ...props.style,
      }}
      onClick={() => onChange(!value)}
    >
      <Svg.duigou
        style={{
          fill: value ? '#00A2AF' : '#D8D8D8',
        }}
      />
    </div>
  );
};

declare module ShowFile {
  type ShoFileEvent = {
    select: {
      allSelect: () => void;
      signSelect: () => void;
    };
    batchDownload: () => void;
    batchDelete: () => void;
    onChange: (lang: string) => void;
    template: (code: string) => void;
  };
}

export function getLanguageOptions(hasAuto: boolean = true) {
  const options = [
    {
      name: 'A',
      children: [
        {
          label: 'Auto',
          value: 'auto',
          show: hasAuto,
        },
        {
          label: 'Arabic',
          value: 'ar',
        },
      ],
    },
    {
      name: 'C',
      children: [
        {
          label: 'Simplified Chinese',
          value: 'zh',
        },
        {
          label: 'Traditional Chinese',
          value: 'hz',
        },
        {
          label: 'Czech',
          value: 'cs',
        },
      ],
    },
    {
      name: 'D',
      children: [
        {
          label: 'Danish',
          value: 'da',
        },
        {
          label: 'Dutch',
          value: 'nl',
        },
      ],
    },
    {
      name: 'E',
      children: [
        {
          label: 'English',
          value: 'en',
        },
      ],
    },
    {
      name: 'F',
      children: [
        {
          label: 'Finnish',
          value: 'fi',
        },
        {
          label: 'French',
          value: 'fr',
        },
      ],
    },
    {
      name: 'G',
      children: [
        {
          label: 'German',
          value: 'de',
        },
        {
          label: 'Greek',
          value: 'el',
        },
      ],
    },
    {
      name: 'I',
      children: [
        {
          label: 'Icelandic',
          value: 'is',
        },
        {
          label: 'Italian',
          value: 'it',
        },
      ],
    },
    {
      name: 'J',
      children: [
        {
          label: 'Japanese',
          value: 'ja',
        },
      ],
    },
    {
      name: 'K',
      children: [
        {
          label: 'Korean',
          value: 'ko',
        },
      ],
    },
    {
      name: 'N',
      children: [
        {
          label: 'Norwegian',
          value: 'no',
        },
      ],
    },
    {
      name: 'P',
      children: [
        {
          label: 'Polish',
          value: 'pl',
        },
        {
          label: 'Portuguese',
          value: 'pt',
        },
      ],
    },
    {
      name: 'S',
      children: [
        {
          label: 'Spanish',
          value: 'es',
        },
        {
          label: 'Swedish',
          value: 'sv',
        },
        {
          label: 'Slovak',
          value: 'sk',
        },
        {
          label: 'Slovenian',
          value: 'sl',
        },
      ],
    },
    {
      name: 'T',
      children: [
        {
          label: 'Thai',
          value: 'th',
        },
        {
          label: 'Turkish',
          value: 'tr',
        },
      ],
    },
  ];

  for (const opt of options) {
    opt.children = opt.children.filter((i) => i.show != false);
  }

  return options;
}

function LanguageSelect(props: {
  select?: string;
  onChange?: (value: string) => void;
  className?: string;
  style?: CSSProperties;
  isOptional?: boolean;
}) {
  const { t } = useTranslation();
  const options = getLanguageOptions();

  const Button = useCallback(
    (props: {
      text: ReactNode;
      showEvent: () => void;
      className?: ClassValue;
      style?: CSSProperties;
      isoptional?: boolean;
    }) => {
      if (!props.isoptional) {
        return (
          <div
            className={clsxm(
              ' pointer-events-none flex h-[34px] w-[100px] cursor-pointer items-center rounded-[8px] border border-[#D9D9D9] bg-[#EDEDED] px-[15px] py-[5px] font-bold text-[rgb(26,26,26)]',
              props.className
            )}
            style={{
              ...props.style,
            }}
            onClick={() => {
              props.showEvent();
            }}
          >
            <OverflowHiddenText className="w-full  flex-1 text-center" text={props.text} overflow="hidden" />
          </div>
        );
      }

      return (
        <div
          className={clsxm(
            ' flex h-[34px] w-[100px] cursor-pointer items-center rounded-[8px] border border-[#D9D9D9] bg-white px-[15px] py-[5px] font-bold text-[rgb(26,26,26)]',
            props.className
          )}
          style={props.style}
          onClick={() => {
            props.showEvent();
          }}
        >
          <OverflowHiddenText className="max-w-[56px]  flex-1 text-center" text={props.text} overflow="hidden" />
          <Svg.downArrow className="" />
        </div>
      );
    },
    []
  );

  return (
    <Select2
      select={props.select}
      data={options}
      showNull={t('misc.auto')}
      btnTextClass={clsxm(' w-[120px] mr-[-10px] flex justify-center', props.className)}
      onChange={(info) => {
        props.onChange?.(info.value);
        console.log('select language', info);
      }}
      style={props.style}
      tooltip={t('wu.recordings.lang.tip')}
      btnComp={Button}
      isoptional={props.isOptional}
    />
  );
}

export const FileItem = forwardRef(function (props: FileItemProps, ref) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'wu.recordings',
  });
  const { t: tGlobal } = useTranslation();
  const setDowloadnQueue = useSetAtom(downloadQueueAtom);
  const { onDelete, fileInfo, setFilesInfos, type, addUploadTask } = props;

  const setFileInfo = (newData: Partial<FileInfo>) => {
    setFilesInfos((state) => {
      const target = state.find((i) => i.signature == fileInfo.signature);
      if (!target) return state;
      Object.assign(target, newData);
      return [...state];
    });
  };
  const [deviceInfo] = useDeviceInfo();
  const { duration } = fileInfo;
  const { id, title, name, signature } = fileInfo;
  let progress = fileInfo.progress || 0;
  const setProgress = (action: number | ((progress: number) => number)) => {
    if (action instanceof Function) {
      setFilesInfos((files) => {
        const file = files.find((i) => i.signature == fileInfo.signature)!;
        file.progress = action(file.progress || 0);
        return [...files];
      });
    } else {
      setFilesInfos((files) => {
        const file = files.find((i) => i.signature == fileInfo.signature)!;
        file.progress = action;
        return [...files];
      });
    }
  };

  const navigage = useNavigate();
  const setUploading = (state: boolean) => {
    setFilesInfos((fileInfos) => {
      const item = fileInfos.find((item) => item.signature == fileInfo.signature)!;
      if (!!item.uploading == state) return fileInfos;
      item.uploading = state;
      return [...fileInfos];
    });
  };

  const onUpdate = async (isAlreadyUpload: boolean = false) => {
    const currentFile = fileInfo;
    if (currentFile.state == 'saved' || currentFile.id) return;

    setUploading(true);

    let startTime = -1;
    let endTime = -1;
    if (!isAlreadyUpload && currentFile.state != 'transcribed' && currentFile.state != 'created') {
      const ticket = await fileUpload(
        currentFile,
        deviceInfo.sn,
        {
          templateCode: fileInfo.template || '',
        },
        (progress) => {
          setProgress(progress * 0.3);
        }
      );

      if (ticket?.error) {
        const errorInfo = generalErrorHandle(ticket, tGlobal);
        setFileInfo({
          updateState: 'failed',
          message: errorInfo,
          uploading: false,
          progress: 0,
        });
        return false;
      } else {
        while (1) {
          const res = await fileInfoReq.estimateTime({ ticket });
          if (typeof res == 'number') {
            startTime = Date.now();
            if (res == 0) {
              endTime = startTime + 3000;
            } else if (res == -1) {
              continue;
            } else {
              endTime = startTime + res;
            }
            break;
          }
          await sleep(1000);
        }
      }
    }
    type tranState = 'saved' | 'failed' | 'created' | 'transcribed' | 'error';
    async function onState(onFn?: (state: tranState, info: newNoteInfo) => void): Promise<newNoteInfo> {
      let result: newNoteInfo | null = null;
      while (result?.state != 'saved' && result?.state != 'error' && result?.state != 'failed') {
        try {
          result = (
            await fileInfoReq.fileInfo({
              signatures: signature,
            })
          )?.[0];
          if (result) onFn?.(result.state as tranState, result);
        } catch (err) {
          console.log('device file get state err', err);
        }
        await sleep(2000);
      }
      return result;
    }

    let latestFileInfo: newNoteInfo;
    const syncTimeP = new Promise((res) => {
      function syncTime() {
        const newProgress = 0.31 + ((Date.now() - startTime) / (endTime - startTime)) * 0.7;
        console.log('newProgess', newProgress);

        if (latestFileInfo?.state == 'saved' && newProgress >= 1) {
          setProgress(1);
          res(null);
          return;
        }
        if (latestFileInfo?.state == 'failed' || latestFileInfo?.state == 'error') {
          res(null);
          return;
        }
        setProgress(Math.min(0.99, newProgress));
        setTimeout(syncTime, Math.max((endTime - startTime) / 100, 100));
      }
      setTimeout(syncTime);
    });

    latestFileInfo = await onState(async (state, info) => {});
    await syncTimeP;

    if (latestFileInfo.state == 'error' || latestFileInfo.state == 'failed') {
      setProgress(0);
      setFileInfo({
        updateState: 'failed',
      });
      setUploading(false);
      return false;
    }
    let curPrgress: number = 0;
    while (curPrgress < 1) {
      await sleep(100);
      setProgress((progress) => {
        curPrgress = Math.min(1, progress + 0.05);
        return curPrgress;
      });
    }

    setFilesInfos((filesInfos) => {
      const item = filesInfos.find((item) => item.signature == latestFileInfo?.signature);
      if (!item) return filesInfos;
      item.id = latestFileInfo!.noteId;
      item.state = latestFileInfo!.state;
      item.title = latestFileInfo!.title;
      item.language = latestFileInfo.language;
      NoteListCardDataRefetchList([item.id]);
      return [...filesInfos];
    });
    setUploading(false);
    return true;
  };

  useEffect(() => {
    if (fileInfo.state == 'created' || 'transcribed' == fileInfo.state) {
      if (fileInfo.uploading) return;
      onUpdate(true);
    }
  }, []);

  useImperativeHandle(ref, () => {
    const res = {
      onUpdate,
      fileInfo,
      setUploading,
    };
    return res;
  }, [onUpdate, fileInfo]);

  const isRecording = fileInfo.signature == '0'.repeat(32);

  const defaultName = `${fileInfo.createDate.replace(
    /\//g,
    ''
  )}-${fileInfo.createTime.replace(/:/g, '')}-${fileInfo.name.split('-').pop()}`;
  const [year, , day] = fileInfo.createDate.split('/');
  const FileNameEl = (
    <FileName
      name={fileInfo.title || defaultName}
      date={`${fileInfo.time.toLocaleString('en-US', {
        month: 'short',
      })}/${day}/${year}  ${fileInfo.createTime}`}
      id={fileInfo.id}
    />
  );

  function getCloudState() {
    if (fileInfo.uploading) return progress;
    if (id) return 'success';
    if (fileInfo.updateState == 'failed') return 'fail';
    return null;
  }

  const cloudStateEl = <CloudState className=" ml-[56px]" state={getCloudState()} />;

  function getUploadState() {
    if (id) return 'uploaded';
    if (fileInfo.uploading) return 'loading';
    return 'general';
  }
  const uploadBtn = (
    <NoteUpload
      className=""
      isRecording={isRecording}
      state={getUploadState()}
      cloudState={getCloudState()}
      completeHandle={() => {
        navigage(`/notes/${id}`);
      }}
      notCompleteHandle={() => {
        if (!fileInfo.duration) return;
        if (fileInfo.uploading) return;
        setUploading(true);
        addUploadTask(onUpdate);
      }}
    />
  );

  return (
    <div
      className=" flex h-[80px] items-center rounded-[8px]"
      style={{
        background: type == 'bg-grey' ? '#F7F7F7' : '',
      }}
    >
      <div
        className=" flex justify-center"
        style={{
          width: colWidths[0],
        }}
      >
        <CheckBox
          style={
            fileInfo.signature == '0'.repeat(32) || deviceInfo.versionNumber < 327936
              ? {
                  visibility: 'hidden',
                  pointerEvents: 'none',
                }
              : undefined
          }
          onChange={(value) => {
            setFileInfo({
              checked: value,
            });
          }}
          value={fileInfo.checked}
        />
      </div>
      <div
        className=" pr-[30px]"
        style={{
          width: colWidths[1],
        }}
      >
        {FileNameEl}
      </div>

      <div
        className=" flex "
        style={{
          width: colWidths[2],
        }}
      >
        <p className=" w-[74px] text-center text-[16px] font-[500] text-black">
          {isRecording ? 'recording' : formatSecondsToProgress((duration / 1000) | 0, true)}
        </p>
      </div>

      <div
        className=" flex text-[14px] "
        style={{
          width: colWidths[3],
          pointerEvents: id ? 'none' : 'auto',
        }}
      >
        <LanguageSelect
          onChange={(lang) => setFileInfo({ language: lang })}
          select={fileInfo.language}
          isOptional={!id && !fileInfo.uploading}
        />
      </div>

      <div
        className=" flex  text-center  text-[14px]"
        style={{
          width: colWidths[4],
        }}
      >
        <TemplateSelect
          select={fileInfo.template}
          onChange={(select) => {
            setFileInfo({
              template: select,
            });
          }}
          isOptional={!id && !fileInfo.uploading}
          className=" w-[210px]"
        />
      </div>

      {uploadBtn}
    </div>
  );
});

export function ShowFile(props: {
  fileInfosRef: React.MutableRefObject<Record<string, FileItemRef>>;
  isShow: boolean;
}) {
  const { fileInfosRef, isShow } = props;
  const module = 'showFile';
  const [deviceInfo] = useAtom(deviceInfoAtom);
  const [fileInfos, setFileInfos] = useAtom(fileInfosAtom);
  const setDowloadnQueue = useSetAtom(downloadQueueAtom);
  const setShowConfirm = useSetAtom(showConfirmAtom);
  const [cardInfo, setCardInfo] = useAtom(cardInfoAtom);
  const [allowOptimize, setAllowOptimize] = useAtom(allowOptimizeAtom);

  const { t } = useTranslation();
  const isBatchDownloadRef = useRef<boolean>(false);

  function showOptimizeHandle() {
    const store = getDefaultStore();

    const Comp = (
      <DeviceOptimize
        event={{
          onClose() {
            store.set(pauseGetFileInfos, false);
            order.remove();
          },
        }}
      />
    );

    const order = globalAddEl(Comp);
  }

  const bottomShow = (
    <div className=" flex h-[80px] w-full items-center border-t border-[#D9D9D9] px-[30px]">
      <div className=" flex-1" />
      <div
        className=" flex h-full flex-nowrap items-center"
        style={{ display: deviceInfo.versionNumber >= 327936 ? '' : 'none' }}
      >
        <span className=" text-[16px] font-[600] text-[#686868]">{t('wu.recordings.storage')}</span>
        <div className=" mx-[12px] h-[8px] w-[200px] overflow-hidden rounded-full bg-[#d9d9d9]">
          {cardInfo && (
            <div
              className=" h-full w-[50%] bg-[#00A2AF]"
              style={{
                width: `${((cardInfo.capacity - cardInfo.used) / cardInfo.capacity) * 100}%`,
              }}
            />
          )}
        </div>
        <span className="mr-[10px] text-[16px] text-[#686868]">
          {' '}
          {fileInfos.filter((i) => !i.isDelete).length} {t('wu.recordings.recordings')}
        </span>

        <button
          className=" flex h-[40px] items-center rounded-[8px] border border-[#D9D9D9]  bg-white p-[9px_12px] text-[15px] font-[700] text-[#3A3A3A]"
          style={{
            display: allowOptimize ? '' : 'none',
          }}
          onClick={() => allowOptimize && showOptimizeHandle()}
        >
          {t('wu.optimize')}
          <Svg.rightArrow className=" ml-[12px]" />
        </button>
      </div>
    </div>
  );

  //如果为没有文件
  if (!fileInfos.filter((i) => !i.isDelete).length) {
    return (
      <>
        <div
          className="flex h-full w-full flex-col"
          style={{
            display: isShow ? '' : 'none',
          }}
        >
          <div className=" flex h-[615px] max-h-[calc(100vh-140px)] flex-1 flex-col items-center justify-center">
            <img className="w-[218px]" src="/empty.png" />
            <div className="mt-[34px] text-center text-[24px] font-[700] text-[rgba(104,104,104,1)]">
              {t('wu.recordings.no-files')}
            </div>
          </div>
          {bottomShow}
        </div>
      </>
    );
  }

  return (
    <div
      className="flex h-full w-full flex-1 flex-col"
      style={{
        display: isShow ? '' : 'none',
      }}
    >
      <div className=" flex h-[80px] items-center pl-[40px] pr-[60px] pt-[14px] text-[18px] font-[500] leading-[16px]">
        <div
          className=" flex justify-center"
          style={{
            width: colWidths[0],
          }}
        >
          <CheckBox
            style={
              deviceInfo.versionNumber >= 327936
                ? undefined
                : {
                    visibility: 'hidden',
                    pointerEvents: 'none',
                  }
            }
            value={!fileInfos.filter((i) => !i.isDelete && i.signature != '0'.repeat(32)).some((i) => !i.checked)}
            onChange={(state) => {
              fileInfos
                .filter((i) => i.signature != '0'.repeat(32) && !i.isDelete)
                .forEach((file) => (file.checked = state));
              setFileInfos([...fileInfos]);
            }}
          />
        </div>

        <div
          className=" flex items-center"
          style={{
            width: colWidths[1],
          }}
        >
          <p className=" mr-[22px]">{t('wu.recordings.list.title.select')}</p>
          <Tooltip title={t('wu.recordings.download.tooltip')}>
            <Svg.download
              className=" mr-[14px] w-[20px] cursor-pointer"
              onClick={async () => {
                const procedure = 'Batch download';
                const selectFiles = fileInfos.filter((i) => i.checked && deviceTool.donwloadable(i));

                if (!selectFiles.length) return;
                if (isBatchDownloadRef.current) return;
                isBatchDownloadRef.current = true;
                selectFiles.forEach((i) => (i.checked = false));
                setFileInfos((files) => [...files]);

                selectFiles.forEach((i) => (i.fileDownLoading = true));

                setFileInfos([...fileInfos]);
                setDowloadnQueue((q) => {
                  const signs = selectFiles.map((i) => i.signature);
                  q = q.filter((i) => !signs.includes(i.file.signature));
                  q.push(
                    ...selectFiles.map((file) => {
                      return {
                        file,
                        progress: 0,
                      };
                    })
                  );
                  return q;
                });

                let directory: any = null;
                try {
                  //@ts-ignore
                  directory = await window.showDirectoryPicker({
                    mode: 'readwrite',
                    startIn: 'downloads',
                  });
                } catch (err: any) {
                  Logger.info(module, procedure, `get folder role fail,errInfo:${err.name}`);
                  if (err?.name == 'AbortError') {
                  } else if (err?.name == 'SecurityError') {
                    myMessage.error(`${t('wu.optimize.optimizing.getFolderRole.fail')}:${err?.message}`);
                  } else {
                    myMessage.error(t('wu.optimize.optimizing.getFolderRole.fail'));
                    console.error(err?.message);
                  }
                  setFileInfos((files) => {
                    const set = new Set(selectFiles.map((i) => i.signature));
                    files.forEach((file) => {
                      if (set.has(file.signature)) file.fileDownLoading = false;
                    });
                    return [...files];
                  });
                  isBatchDownloadRef.current = false;
                  return;
                }

                let cnt = 0;
                selectFiles.forEach((fileInfo) => {
                  uploadFileQueue.addTask(async () => {
                    const setProgress = throttle(
                      (progress: number) => {
                        setDowloadnQueue((q) => {
                          const target = q.find((i) => i.file.signature == fileInfo.signature)!;
                          if (typeof progress == 'number') target.progress = progress;
                          return [...q];
                        });
                      },
                      100,
                      { immediatelyExec: false }
                    );
                    const sps = fileInfo.name.split('.');
                    const endfix = sps.pop()!;
                    sps.push(endfix == 'hda' ? 'mp3' : endfix);
                    const fileHandler = await directory.getFileHandle(sps.join('.'), {
                      create: true,
                    });
                    const writabelStream = await fileHandler.createWritable();

                    await getDeviceFileArrayBuffer(
                      fileInfo,
                      (progress, type, data) => {
                        writabelStream.write(data);
                      },
                      setProgress
                    );

                    writabelStream.close();

                    setFileInfos((files) => {
                      const item = files.find((i) => i.signature == fileInfo.signature);
                      if (item) item.fileDownLoading = false;
                      return [...files];
                    });
                    setDowloadnQueue((q) => {
                      const idx = q.findIndex((i) => i.file.signature == fileInfo.signature);
                      if (idx != -1) q.splice(idx, 1);
                      return [...q];
                    });

                    cnt++;
                    if (cnt == selectFiles.length) {
                      isBatchDownloadRef.current = false;
                    }
                  });
                });
              }}
              style={
                deviceInfo.versionNumber >= 327936
                  ? undefined
                  : {
                      visibility: 'hidden',
                      pointerEvents: 'none',
                    }
              }
            />
          </Tooltip>
          <Tooltip title={t('wu.recordings.all-delete.tooltip')}>
            <Svg.delete
              className=" w-[16px] cursor-pointer"
              onClick={async () => {
                if (!fileInfos.filter((i) => i.checked && !i.isDelete && i.signature != '0'.repeat(32)).length) return;

                setShowConfirm({
                  isShow: true,
                  message: t('wu.recordings.all-delete.tip'),
                  confirm() {
                    deleteHandle();
                    setShowConfirm((state) => {
                      return {
                        ...state,
                        isShow: false,
                      };
                    });
                  },
                  cancel() {
                    setShowConfirm((state) => {
                      return {
                        ...state,
                        isShow: false,
                      };
                    });
                  },
                });

                async function deleteHandle() {
                  const selectFiles = fileInfos.filter(
                    (i) => i.checked && !i.isDelete && i.signature != '0'.repeat(32)
                  );
                  const deletePromises = selectFiles.map((file) => {
                    return jensen.deleteFile(file.name);
                  });

                  const delteRes = await Promise.all(deletePromises);

                  const deleteSigns: string[] = [];

                  delteRes.map((returnInfo, idx) => {
                    if (returnInfo.result != 'success') {
                      jensen.getCardInfo?.()?.then((res) => {
                        if (res) setCardInfo(res);
                      });
                      myMessage.error(t('wu.failed-to-delete'), 2000);
                      return;
                    } else {
                      deleteSigns.push(selectFiles[idx].signature);
                    }
                  });

                  setFileInfos((files) => {
                    files.forEach((item) => {
                      if (deleteSigns.includes(item.signature)) item.isDelete = true;
                    });
                    return [...files];
                  });
                }
              }}
              style={
                deviceInfo.versionNumber >= 327936
                  ? undefined
                  : {
                      visibility: 'hidden',
                      pointerEvents: 'none',
                    }
              }
            />
          </Tooltip>
        </div>
        <div
          className=" flex"
          style={{
            width: colWidths[2],
          }}
        >
          <p className=" flex w-[74px] justify-center">{t('wu.recordings.list.title.duration')}</p>
        </div>
        <div
          className=" flex"
          style={{
            width: colWidths[3],
          }}
        >
          <span className=" w-[100px] text-center">{t('wu.recordings.list.title.language')}</span>
        </div>
        <div
          className=" flex"
          style={{
            width: colWidths[4],
          }}
        >
          <span className=" w-[210px] text-center">{t('wu.recordings.template.title')}</span>
        </div>
      </div>

      <div
        id="device-file-list-container"
        className={clsxm([
          ` mr-[12px] flex-1 overflow-auto px-[40px] leading-[16px]`,
          'scrollbar  scrollbar-thumb-gray-500 scrollbar-track-gray-200 overflow-x-hidden overflow-y-scroll',
        ])}
      >
        {fileInfos
          .filter((i) => !i.isDelete)
          .map((item, index) => {
            const { signature, name } = item;
            return (
              <FileItem
                fileInfo={item}
                type={index % 2 ? 'bg-white' : 'bg-grey'}
                key={signature + '_' + name}
                onDelete={async () => {
                  const returnInfo = await jensen.deleteFile(item.name);
                  if (returnInfo.result != 'success') {
                    jensen.getCardInfo?.()?.then((res) => {
                      if (res) setCardInfo(res);
                    });
                    myMessage.error(t('wu.failed-to-delete'), 2000);
                    return;
                  }
                  setFileInfos((files) => {
                    const target = files.find((i) => i.signature == item.signature)!;
                    target.isDelete = true;
                    return [...files];
                  });
                }}
                ref={(item: FileItemRef) => {
                  if (!item?.fileInfo.signature) return;
                  //@ts-ignore
                  fileInfosRef.current[item.fileInfo.signature] = item;
                }}
                addUploadTask={uploadFileQueue.addTask}
                setFilesInfos={setFileInfos}
              />
            );
          })}
      </div>
      {bottomShow}
    </div>
  );
}
