import { clsxm } from '@hidock/utils';
import React, { CSSProperties, FC } from 'react';
import { ClassValue } from 'tailwind-variants';
import { CloseBtn } from '../userInfo/general';

export type ModalContainer = {
  onClose?: () => void;
  children?: React.ReactNode;
  className?: ClassValue;
  style?: CSSProperties;
  btnClssName?: ClassValue;
};

const ModalContainer: FC<ModalContainer> = (props) => {
  const { children, onClose, className, style = {}, btnClssName } = props;

  return (
    <div
      className="fixed bottom-0 left-0 right-0 top-0 z-[999] flex items-center justify-center bg-[rgba(0,0,0,0.3)]"
      style={{
        backgroundColor: 'rgba(0,0,0,0.3)',
      }}
    >
      <div className={clsxm('relative', className)} style={style} onClick={(e) => e.stopPropagation()}>
        {onClose && <CloseBtn className={clsxm('right-[30px] top-[30px]', btnClssName)} onClick={() => onClose?.()} />}
        {children}
      </div>
    </div>
  );
};

export default ModalContainer;
