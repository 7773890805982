import { QueryKey } from '@tanstack/react-query';
import { NoteListCardDataRefetchList } from '~/components/note';
import { request } from '~/lib/axios';
import { sleep } from '~/utils/sleep';
import { useEffect, useState } from 'react';
import { defineQuery, useAPIQuery } from '../helper';
import { useSaveNoteMutation } from '../notes/save';
import type { Note } from '../notes/types';
import { reqParamsToBody } from '../utils';

type Params = {
  id: string;
};

export type Note2 = Note & {
  json: string;
  html: string;
  marks: {
    id: string;
    sessionId: string;
    markTime: number;
  }[];
  shortId: string;
  ownership: 'myself' | 'otherwise';
};

export const info2 = (params: Params) => {
  return request.post<Note2>('/v2/note/info', reqParamsToBody(params));
};

export const queryInfo2 = (noteId: string) =>
  defineQuery(['note.info2', noteId], () => {
    return info2({ id: noteId });
  });

export function getNoteInfoQueryParmas(id: string | null | undefined) {
  return {
    queryKey: ['note.info2'] as QueryKey,
    queryFn: () => {
      return info2({ id: id || '' });
    },
    enabled: !!id,
  };
}

export const useInfo2 = (params: Params) => {
  const APIQuery = useAPIQuery(queryInfo2(params.id));
  const { refetch, data: info } = APIQuery;
  useEffect(() => {
    if (info?.state == 'summarized' || info?.state == 'saved') return;
    let hasUpdate = false;
    let isStop = false;
    async function exec() {
      if (isStop) return;
      const { data: info } = await refetch();
      if ((info?.state == 'summarized' || info?.state == 'saved') && !hasUpdate) {
        hasUpdate = true;
        if (info.ownership == 'myself') NoteListCardDataRefetchList(info.id);
        return;
      }
      await sleep(2000);
      exec();
    }
    exec();
    return () => {
      isStop = true;
    };
  }, []);
  return APIQuery;
};
