import { clsxm } from '@hidock/utils';
import { RecordButtonBase, UploadButtonBase } from '~/components/actions';
import { Hero } from '~/components/hero';
import { myMessage } from '~/components/MyToast/MyToast';
import { NoteListCardDataRefetchList } from '~/components/note';
import { useEntryInfo } from '~/store/entry';
import { getMaxUploadSize } from '~/store/user';
import { parseDoubleBracketSyntax } from '~/utils/utils';
import { atom, getDefaultStore, useAtom, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useState, type FC } from 'react';
import type { DropzoneOptions } from 'react-dropzone';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import DeviceConntion, { DeviceGlobalShow } from '../../components/deviceConnection/DeviceConnection';
import { Generating } from '../generating/Generating';
import { Recording } from '../record/Record';
import { Uploading } from '../upload/Uploading';
import { recentlyNoteRefresh } from '~/components/note/store.ts';

export const NOTE_SIDEBAR_WIDTH = '313px';

const HiddenScrollBar = styled.aside`
  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const NoteSidebar = (props: { className?: string }) => {
  const { t } = useTranslation();

  return (
    <div className={clsxm('aspect-[650/316] max-h-screen', props.className)}>
      <HiddenScrollBar
        className={clsxm([
          'relative h-full overflow-y-scroll 2xl:pt-[60px]',
          'flex flex-col',
          'scroll-y  flex-shrink-0',
        ])}
        id="layourt-sideBar"
      >
        <Hero size="sm" description={false} />
        <section className="mt-[10px] flex flex-1 flex-col justify-center">
          <ActionPanel className="mb-[14px] space-y-[52px]" />
          {/* <div className=' text-center text-[18px] mb-[30px] text-[#4E5A58]'>{t('misc.upload')}</div> */}
          <div className=" flex items-center justify-center">
            <DeviceConntion />
          </div>
        </section>
        {/* <div className=' absolute bottom-[90px] w-full flex justify-center'>
        <LanguageSelect />
      </div> */}
        <Link to="/notes/productLog" className=" mt-[6px] text-center text-[14px] underline xl:text-[12px]">
          HiNotes {import.meta.env.VITE_NOTE_VERSION}
        </Link>
        <div className=" mb-[30px] text-center text-[14px] text-[#686868] xl:mb-[10px] xl:text-[12px]">
          {t('app.all-right-reserved')}
        </div>
      </HiddenScrollBar>
    </div>
  );
};

let fileUpdateCall: null | Function = null;
export const fileUpdateTestCall = (files: File[]) => {
  return fileUpdateCall?.(files);
};

const ActionPanel: FC<{ className?: string }> = ({ className }) => {
  const navigate = useNavigate();
  const [recorderId, setRecorderId] = useState(0);
  const [state, setState] = useState<'idle' | 'recording' | 'uploading' | 'generating'>('idle');
  const [noteTicket, setNoteTicket] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const { t } = useTranslation();

  const onFileDrop: NonNullable<DropzoneOptions['onDrop']> = useCallback(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) return;

      const maxUploadSize = await getMaxUploadSize();

      if (maxUploadSize && file.size / 1024 / 1024 > maxUploadSize) {
        let content = t(`note.error.file_size_exceed`);
        myMessage.error(parseDoubleBracketSyntax(content, { size: maxUploadSize + 'mb' }));
        return;
      }

      setFile(file);
      setState('uploading');
    },
    [t]
  );

  useMemo(() => {
    fileUpdateCall = onFileDrop;
  }, [onFileDrop]);

  return (
    <div
      className={clsxm(
        'ml-[30px] mr-[20px] w-[calc(100%-50px)] flex flex-col items-center bg-white rounded-[14px] overflow-hidden aspect-[276/250]',
        className
      )}
    >
      {state === 'idle' && (
        <>
          {/* <RecordButtonBase
            size="lg"
            onClick={() => {
              setState('recording')
            }}
          /> */}
          <UploadButtonBase
            onDrop={onFileDrop}
            className=" bg-gradient-to-r from-[#24DCEB] to-[#00A2AF]"
            iconClassName={'text-white'}
          />
        </>
      )}
      {state === 'uploading' && (
        <Uploading
          file={file}
          className={'mt-[0] rounded-none h-full'}
          onUploaded={(ticket) => {
            if (!(typeof ticket == 'string')) {
              // myMessage.error(t('note.info.uploaded-failed'));
              setState('idle');
              return;
            }
            setState('generating');
            setNoteTicket(ticket);
          }}
          onCancel={() => setState('idle')}
          onError={() => setState('idle')}
        />
      )}
      {state === 'recording' && (
        <Recording
          key={recorderId}
          onCancel={() => setState('idle')}
          onStop={(ticket) => {
            if (!ticket) {
              setState('generating');
              return;
            }
            setState('generating');
            setNoteTicket(ticket);
          }}
          onRestart={() => {
            setRecorderId((id) => id + 1);
          }}
          onError={() => {
            setState('idle');
          }}
        />
      )}
      {state === 'generating' && (
        <Generating
          className={'mt-[0] rounded-none h-full'}
          ticket={noteTicket}
          onGenerated={(id) => {
            const curPathName = '/notes';
            if ([curPathName, curPathName + '/'].includes(location.pathname) && id) {
              navigate(`/notes/${id}?ticket=${noteTicket}`, {
                state: { mode: 'result' },
              });
              NoteListCardDataRefetchList(id);
            }
            setState('idle');
          }}
          onCancel={() => setState('idle')}
          onError={() => setState('idle')}
        />
      )}
    </div>
  );
};
