import { motion } from 'framer-motion';
import { forwardRef } from 'react';
import type { ComponentProps, ReactNode } from 'react';

export const Button = forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof motion.button> & {
    children?: ReactNode;
    className?: string;
  }
>((props, ref) => {
  const { children, ...rest } = props || {};

  return (
    <motion.button ref={ref} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.97 }} {...rest}>
      {children}
    </motion.button>
  );
});

Button.displayName = 'Buttonx';
