import { clsxm } from '@hidock/utils';
import { API } from '~/api';
import { avatorUpload } from '~/api/users/avatorUpload';
import { contryListReq } from '~/api/users/countryList';
import { useDeleteUserModal } from '~/api/users/delete';
import { useLogoutMutation } from '~/api/users/logout';
import { modifyUsernameReq } from '~/api/users/modifyUsername';
import { setAppLoading } from '~/App';
import i18n from '~/i18n';
import { queryClient } from '~/main';
import { useUserInfo } from '~/store/user';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { modifyRegion } from '../../api/users/modifyRegion';
import { roleUpdateReq } from '../../api/users/roleUpdate';
import { useShowConfirm } from '../deleteConfrim';
import { myMessage } from '../MyToast/MyToast';
import { Button, Select, Select2 } from './general';

const useChangePic = () => {
  async function onDrop(files: File[]) {
    console.log('files', files);
    if (files[0]) {
      const url = await avatorUpload(files[0]);
      if (typeof url == 'string') {
        await queryClient.cancelQueries({ queryKey: API.users.info().key });
        queryClient.setQueriesData<any>(API.users.info().key, (old: any) => {
          if (!old) old = {};
          return {
            ...old,
            avatar: url,
          };
        });
      }
    }
  }

  return useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'image/apng': ['.apng'],
      'image/bmp': ['.bmp'],
      'image/gif': ['.gif'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/svg+xml': ['.svg'],
      'image/tiff': ['.tif', '.tiff'],
    },
  });
};
function ChangePic() {
  const { getInputProps, getRootProps } = useChangePic();
  const { t } = useTranslation();
  return (
    <>
      <p className=" mt-[30px] text-[22px] font-bold">{t('user.info.profile.profile-picture')}</p>
      {/* 切换照片El */}
      <input className=" hidden" {...getInputProps()} />
      <div className=" mt-[18px] flex h-[140px] w-[100%] items-center justify-center rounded-[12px] bg-[#F0F0F0] p-[14px]">
        <div
          {...getRootProps({
            className:
              ' border border-dashed border-[#ACACAC] w-[100%] h-[100%] flex justify-center items-center cursor-pointer',
          })}
        >
          <div className="flex h-[68px] w-[68px] items-center justify-center rounded-full bg-[#E2E2E2]">
            <img src="/User/edit-white.png" className=" h-[40px] w-[40px]" />
          </div>
        </div>
      </div>
    </>
  );
}

function EditText(props: { onModify?: (value: string) => void; text: string }) {
  const [editable, setEditable] = useState(false);
  const textElRef = useRef<HTMLSpanElement | null>(null);

  return (
    <>
      <span
        className=" mr-[14px] text-[20px] outline-none"
        dangerouslySetInnerHTML={{ __html: props.text }}
        contentEditable={editable}
        onBlur={(e) => {
          props.onModify?.(e.target.innerText);
        }}
        ref={textElRef}
      />
      <img
        src="/User/edit-grey.png"
        className=" h-[20px] w-[18px] cursor-pointer"
        onClick={() => {
          setEditable(true);
          setTimeout(() => {
            textElRef.current!.focus();
          });
        }}
      />
    </>
  );
}

type CountryInfo = {
  name: string;
  children: { label: string; value: string }[];
}[];

function useCountryInfo() {
  const res = useState<CountryInfo>([]);
  const [, setContryInfo] = res;
  //请求国家信息
  useEffect(() => {
    contryListReq().then((data) => {
      const temp: Record<string, CountryInfo[number]> = {};
      data.forEach((i) => {
        const l = i.country[0];
        if (temp[l]) {
          temp[l].children.push({ label: i.country, value: i.alpha2 });
        } else
          temp[l] = {
            name: l,
            children: [{ label: i.country, value: i.alpha2 }],
          };
      });
      setContryInfo(Object.values(temp));
    });
  }, []);
  return res;
}

function CountrySelect(props: { defalutValue?: string; onChange?: (data: { label: string; value: string }) => void }) {
  const [value, setValue] = useState(props.defalutValue);
  const [countryInfo] = useCountryInfo();
  const { t } = useTranslation();
  return (
    <Select2
      data={countryInfo}
      btnClassName={' min-w-[0px] px-[0px]'}
      showNull={t('user.info.profile.select-region')}
      select={value}
      onChange={(data) => {
        setValue(data.value);
        props.onChange?.(data);
      }}
    />
  );
}

function DeleteConfrimBtn() {
  const { t } = useTranslation();
  const [deleteConfirm, setDeleteConfirm] = useShowConfirm();
  const { mutate, isLoading } = useDeleteUserModal();
  function deleteAccount() {
    if (isLoading) return;
    setAppLoading(true);
    mutate({});
  }
  return (
    <Button
      className=" bg-red-500 text-white shadow-[0px_0px_10px_rgba(0,0,0,0.2)]"
      onClick={() => {
        setDeleteConfirm({
          isShow: true,
          message: t('misc.confirmDelete'),
          confirm: () => {
            deleteAccount();
            setDeleteConfirm({
              ...deleteConfirm,
              isShow: false,
            });
          },
          cancel: () => {
            setDeleteConfirm({
              ...deleteConfirm,
              isShow: false,
            });
          },
          confirmText: t('note.modal.delete.confirm'),
          cancelText: t('note.modal.delete.cancel'),
        });
      }}
    >
      {t('misc.delete')}
    </Button>
  );
}

export function Profile() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { mutate: logout } = useLogoutMutation();
  const { data: userInfo } = useUserInfo();
  // console.log(`i18n.getDataByLanguage('en')`,i18n.getDataByLanguage('en'))
  console.log('userinfon', userInfo);
  const tabBarConfig = [
    {
      key: 'name',
      endfix: (
        <EditText
          text={userInfo?.name || ''}
          onModify={async (name) => {
            modifyUsernameReq({ name }).then((res) => {
              if (res) {
                myMessage.error(t('user.update.username.failed'), 200);
              }
              queryClient.cancelQueries({ queryKey: API.users.info().key });
              queryClient.setQueriesData<any>(API.users.info().key, (old: any) => {
                if (!old) old = {};
                return {
                  ...old,
                  name,
                };
              });
            });
          }}
        />
      ),
    },
    {
      key: 'role',
      endfix: (
        <>
          <Select
            btnClassName={' min-w-[0px] px-[0px]'}
            onChange={(data) => {
              roleUpdateReq({ role: data.value }).then((res) => {
                if (res) {
                  myMessage.error(t('user.professional-role.Pro.failed'), 2000);
                }
              });
            }}
            data={[
              {
                label: t('user.info.profile.role.manager'),
                value: 'Manager',
              },
              {
                label: t('user.info.profile.role.employee'),
                value: 'Employee',
              },
              {
                label: t('user.info.profile.role.writer'),
                value: 'Writer',
              },
              {
                label: t('user.info.profile.role.jounalist'),
                value: 'Journalist',
              },
              {
                label: t('user.info.profile.role.students'),
                value: 'Students',
              },
              {
                label: t('user.info.profile.role.teacher'),
                value: 'Teacher',
              },
              {
                label: t('user.info.profile.role.researcher'),
                value: 'Researcher',
              },
              {
                label: t('user.info.profile.role.official'),
                value: 'Public Officials',
              },
              {
                label: t('user.info.profile.role.media'),
                value: 'Media Personnel',
              },
              {
                label: t('user.info.profile.role.ferrlancer'),
                value: 'Freelancer',
              },
              {
                label: t('user.info.profile.role.others'),
                value: 'Others',
              },
            ]}
            defaultSelect={userInfo?.professionalRole || ''}
          />
        </>
      ),
    },
    {
      key: 'region',
      endfix: (
        <CountrySelect
          onChange={(value) => {
            modifyRegion({ region: value.value }).then((res) => {
              if (res) {
                myMessage.error(t('user.update.region.failed'), 2000);
              }
            });
          }}
          defalutValue={userInfo?.region || ''}
        />
      ),
    },
    {
      key: 'email',
      endfix: (
        <>
          <span className=" mr-[14px] text-[20px]">{userInfo?.email}</span>
          <img src="/User/duigou.png" className=" h-[22px] w-[18px]" />
        </>
      ),
    },
    {
      key: 'password',
      endfix: (
        <Button
          className=" bg-white shadow-[0px_0px_10px_rgba(0,0,0,0.2)]"
          onClick={() => navigate('/notes/changepassword')}
        >
          {t('misc.change')}
        </Button>
      ),
    },
    {
      key: 'Logout',
      endfix: (
        <Button className=" bg-white text-black shadow-[0px_0px_10px_rgba(0,0,0,0.2)]" onClick={() => logout({})}>
          {t('user.info.profile.Logout')}
        </Button>
      ),
    },
    {
      key: 'delete_account',
      endfix: <DeleteConfrimBtn />,
      describe: t('user.info.profile.delete_account.hint'),
    },
  ];

  const header = <div className=" text-[28px] font-[600] leading-[28px]">{t('user.info.basic-info')}</div>;

  const tabBar = (
    <>
      <p className=" mb-[20px] mt-[30px] text-[22px] font-[600]">{t('user.info.personal-information')}</p>

      {tabBarConfig.map((item, idx) => {
        return (
          <div
            className=" flex items-center w-[100%] rounded-[12px] p-[21px_30px]"
            style={{ backgroundColor: idx % 2 == 0 ? '#F0F0F0' : '' }}
            key={item.key}
          >
            <div>
              <p className=" text-[20px] font-[600]">
                {
                  // @ts-ignore
                  t(`user.info.profile.${item.key}`)
                }
              </p>
              {item.describe && <p className=" mt-[5px] text-[14px] text-[#686868]">{item.describe}</p>}
            </div>
            <div className="flex-1" />
            {item.endfix}
          </div>
        );
      })}
    </>
  );
  return (
    <>
      <div
        className={clsxm(
          ' box-content w-[850px] pe-[10px] pt-[3px]  leading-[18.72px]',
          'scrollbar  scrollbar-thumb-gray-500 scrollbar-track-gray-200 overflow-x-hidden overflow-y-scroll'
        )}
      >
        {header}
        <ChangePic />
        {tabBar}
      </div>
    </>
  );
}
