import { Note2 } from '~/api/note2s/info2';
import { savedReq } from '~/api/note2s/survey/save';
import { sleep } from '~/utils/sleep';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import { styled } from 'styled-components';
import { myMessage } from '../MyToast/MyToast';
import { Platform } from './type';

type ShareItemProps = {
  onClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  src: string;
  size?: string;
  undone?: boolean;
  title?: string;
};

const IconImg = styled.img<{ undone?: boolean }>`
  cursor: ${(p) => (!p.undone ? 'point' : 'auto')};
  &:hover {
    --val: ${(props) => (props.undone ? 1 : 1.3)};
    --tw-scale-x: var(--val);
    --tw-scale-y: var(--val);
  }
`;
function ShareItem(props: ShareItemProps) {
  const { onClick, src, size, undone, title } = props;
  return (
    <IconImg
      title={title}
      onClick={undone ? undefined : onClick}
      src={src}
      className={`aspect-square scale-100 transform cursor-pointer`}
      undone={undone}
      style={{
        transformOrigin: 'center center',
        width: size || '35px',
        height: size || '35px',
        transition: '.3s',
        filter: undone ? 'grayscale(200%)' : '',
        pointerEvents: undone ? 'none' : 'auto',
      }}
    />
  );
}

export default function SelectPlatform(props: {
  onClick?: (platform: Platform) => any;
  onExit?: () => void;
  info2?: Note2 | null;
  LinkShare: string;
}) {
  const { t } = useTranslation();
  const { onClick, onExit, info2, LinkShare } = props;
  const { noteId } = useParams();

  //header
  const headerEl = (
    <>
      <div
        className="absolute right-[30px] top-[30px] flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full hover:bg-[#E8EAEA]"
        onClick={onExit}
      >
        <img src="/share/close.png" className="w-[15.5px]" />
      </div>
      <div className="flex w-full items-center justify-center text-[20px] font-[600] leading-[39px] text-[#000000]">
        {t('note.share.send-to')}
        <img src="/share/send.png" className="ml-[7px] h-[20px] w-[25px]" />
      </div>
    </>
  );

  //platform share select
  const platforms: {
    platform: Platform;
    icon: string;
    size?: string;
    undone?: boolean;
    title?: string;
  }[] = [
    {
      icon: '/share/twitter.png',
      title: 'Twitter',
      platform: 'twitter',
    },
    {
      icon: '/share/facebook.png',
      platform: 'facebook',
      title: 'Facebook',
    },
    {
      icon: '/share/linkdin.png',
      platform: 'linkdin',
      title: 'LinkedIn',
      // undone: true
    },
    {
      icon: '/share/whatsapp.png',
      platform: 'whatsapp',
      title: 'WhatsApp',
      // undone: true
    },
    {
      icon: '/share/notion.png',
      platform: 'notion',
      title: 'Notion',
      // undone: true
    },
    ,
    // {
    //     icon: "/share/oneNote.png",
    //     platform: "oneNote",
    //     undone: true
    // }
    {
      icon: '/share/google-docs.png',
      platform: 'google docs',
      title: 'Google Docs',
      // undone: true
    },
  ];
  const platformEls = platforms.map((item) => {
    const { platform, icon, size, undone } = item;
    return (
      <ShareItem
        onClick={() => onClick?.(platform)}
        src={icon}
        key={platform}
        size={size}
        undone={undone}
        title={item.title}
      />
    );
  });
  const selectPlatformEl = (
    <>
      <div className="mt-[12px] flex justify-around">{platformEls}</div>
    </>
  );

  function hinoteShareHandle() {
    if (!LinkShare) {
      myMessage.success(t('note.info.summary.copied-successfully'), 2000);
      return;
    }
    window.navigator.clipboard
      .writeText(LinkShare)
      .then((res) => {
        myMessage.success(t('note.info.summary.copied-successfully'), 2000);
      })
      .catch((err) => {
        myMessage.error(err, 2000);
      });
  }

  //链接
  const shareEl = (
    <div>
      <div className="mt-5 flex w-full items-center justify-center text-[20px] font-[600] leading-[39px] text-[#000000]">
        {t('misc.share-link')}
        <img src="/share/link.png" className="ml-[7px] h-[20px] w-[25px]" />
      </div>
      <div className="relative mt-[15px] box-border flex h-[50px] w-full rounded-[8px] border-[1px] border-[#ACACAC] p-[13px_20px]">
        <span className="size-[16px] flex-1 text-[#686868]">{LinkShare}</span>
        <button
          className="absolute right-[-1px] top-0 h-[calc(100%+1px)] w-[66px] rounded-[8px] bg-[#00A2AF] text-[16px] text-[#fff]"
          onClick={hinoteShareHandle}
        >
          {t('misc.copy')}
        </button>
      </div>
    </div>
  );

  //questionnaire
  const [select, setSelect] = useState<number | null>(null);
  const [customizePoint, setCustomizePoint] = useState('');
  const sorces = [5, 10, 20, 30];
  const question = useMemo(() => {
    let defaultText = 'This note save me _ minutes!';
    const text = t('note.share.topic');
    const [prefix, endfix] = (typeof text == 'string' ? text : defaultText).split('_');
    return (
      <>
        {prefix}
        <span className=" w-[6em] border-b border-b-[#]" />
        {endfix}
      </>
    );
  }, [t]);
  const questionnaireEl = (
    <>
      <div className="mt-[35px] text-center text-[20px] font-[700] leading-[27px] text-[#000000]">
        {t('note.share.isHappy')}
      </div>
      <div className="mt-[3px] flex w-full justify-center text-[14px]">{question}</div>
    </>
  );

  //Rate
  function RateSumbitHandle() {
    if (select == null) {
      myMessage.error(t('note.share.submit-after-scoring'), 2000);
      return;
    }
    savedReq({
      minutes: select == -1 ? +(customizePoint || 0) : select,
      noteId: noteId!,
    }).then((data) => {
      if (!data) myMessage.success(t('note.share.submitted-successfully'), 2000);
      else myMessage.error(t('note.share.Submission-Failed'), 2000);
    });
  }
  const RateChildrenEl = {
    submit: (
      <div className="mt-3 flex w-full justify-center">
        <button
          className="h-[38px] w-[109px] rounded-[8px] bg-[#00A2AF] text-[16px] text-white"
          onClick={RateSumbitHandle}
        >
          {t('note.share.sumbit')}
        </button>
      </div>
    ),
    select: (
      <div className=" flex w-full justify-center">
        {sorces.map((point) => {
          return (
            <button
              className="size-[18px] mx-[9px] h-[28px] w-[37px] rounded-[4px] border-[1px] border-[#ACACAC] leading-[28px] hover:bg-[#00A2AF] hover:text-white"
              onClick={() => setSelect(point)}
              style={
                select == point
                  ? {
                      color: 'white',
                      backgroundColor: '#00A2AF',
                    }
                  : undefined
              }
            >
              {point}
            </button>
          );
        })}
        <div className=" relative flex">
          <input
            className="size-[18px] mx-[9px] h-[28px] w-[37px] rounded-[4px] border-[1px] border-[#ACACAC] text-center leading-[28px] outline-none"
            style={
              select == -1
                ? {
                    color: 'white',
                    backgroundColor: '#00A2AF',
                  }
                : undefined
            }
            onClick={() => {
              setSelect(-1);
            }}
            onChange={(e) => {
              const newValue = e.currentTarget.value;
              if (!newValue || /^\d{1,4}$/.test(newValue)) setCustomizePoint(e.currentTarget.value);
            }}
            value={customizePoint}
          />
          {select != -1 && !customizePoint && (
            <div className=" absolute bottom-[6px] left-1/2 h-[1px] w-[15px] -translate-x-1/2 bg-[#ACACAC]" />
          )}
        </div>
      </div>
    ),
  };
  const RateEl = (
    <div className="mt-[15px] w-full">
      {RateChildrenEl.select}
      {RateChildrenEl.submit}
    </div>
  );

  return (
    <div
      className="relative box-border w-[500px] rounded-[24px] bg-white p-[50px_35px]"
      onClick={(e) => e.stopPropagation()}
    >
      {headerEl}
      {selectPlatformEl}
      {shareEl}
      {questionnaireEl}
      {RateEl}
    </div>
  );
}
