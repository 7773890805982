import { atom, getDefaultStore, useAtom } from 'jotai';
import { ReactNode } from 'react';

const globalLayoutAtom = atom<
  {
    node: ReactNode;
    show?: boolean;
  }[]
>([]);

export function globalAddEl(
  el: ReactNode,
  options?: {
    isFullScreen?: boolean;
  }
) {
  options = {
    isFullScreen: true,
    ...options,
  };

  const store = getDefaultStore();
  const comp = (
    <div
      className={(options.isFullScreen ? ' absolute left-0 top-0 h-screen w-screen' : '') + 'z-[999999999]'}
      key={Date.now()}
    >
      {el}
    </div>
  );
  const data = {
    node: comp,
    show: true,
  };
  store.set(globalLayoutAtom, (els) => {
    return [...els, data];
  });

  return {
    remove() {
      store.set(globalLayoutAtom, (els) => {
        const idx = els.findIndex((cur) => cur.node == comp);
        if (idx != -1) els.splice(idx, 1);
        else return els;

        return [...els];
      });
    },
    hidden() {
      store.set(globalLayoutAtom, (els) => {
        data.show = false;
        return [...els];
      });
    },
    show() {
      store.set(globalLayoutAtom, (els) => {
        data.show = true;
        return [...els];
      });
    },
  };
}

export function GlobalLayout() {
  const [datas] = useAtom(globalLayoutAtom);

  return datas.filter((data) => data.show).map((i) => i.node);
}
