import { useFocus } from '~/hooks/useFocus';
import { showUpgradeTipAtom } from '~/layouts/RootLayout';
import { MoreThan2Svg } from '~/svg/svg';
import { color } from 'framer-motion';
import { useSetAtom } from 'jotai';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useHover } from 'usehooks-ts';
import { TabBar } from '../../general/TabBar';

const { SubMenu, MenuItem, Divider, ContextMenu } = TabBar;

const SvgContainer = styled.div`
  --color: #939393;
  &:hover {
    --color: #3a3a3a;
  }
`;

export function MoreThan(props: {
  event: {
    delete?: () => void;
    copy?: {
      transcript?: () => void;
      summary?: () => void;
    };
    exportTranscript?: {
      text?: () => void;
      csv?: () => void;
      srt?: () => void;
    };
    exportSummary?: {
      pdf?: () => void;
      word?: () => void;
    };
    exportAll?: {
      text?: () => void;
      pdf?: () => void;
      word?: () => void;
    };
    export?: () => void;
    translate?: () => void;
    cluster?: () => void;
  };
  openCluster?: boolean;
}) {
  const { event, openCluster } = props;
  const [isShow, setIsShow] = useState(false);
  const { t } = useTranslation();

  const focusHandle = useFocus((state) => {
    setIsShow(state == 'focus');
  });

  const Menu = (
    <div
      className=" absolute right-0 top-[calc(100%+15px)] z-[9999999] rounded-[12px] border-[0.5px] border-[#9C9C9C] bg-white p-[6px] shadow-[0px_0px_20px_rgba(0,0,0,0.3)] backdrop-blur"
      onClick={() => focusHandle()}
    >
      <ContextMenu className=" w-[310px]">
        <MenuItem
          title={t('note.info.more_than.copy')}
          imgSrc="/notes/copy.png"
          hoverImgSrc="/notes/copy-white.svg"
          subMenu={
            <SubMenu>
              <MenuItem
                title={t('note.info.more_than.copy.transcript')}
                onClick={() => {
                  event.copy?.transcript?.();
                  setTimeout(() => {
                    setIsShow(false);
                  }, 120);
                }}
              />
              <MenuItem
                title={t('note.info.more_than.copy.summary')}
                onClick={() => {
                  event.copy?.summary?.();
                  setTimeout(() => {
                    setIsShow(false);
                  }, 120);
                }}
              />
            </SubMenu>
          }
        />
        <Divider />
        <MenuItem
          title={t('note.info.more_than.export_transcript')}
          imgSrc="/notes/export.png"
          hoverImgSrc="/notes/export-white.svg"
          subMenu={
            <SubMenu>
              <MenuItem
                title={t('note.info.more_than.export_transcript.txt')}
                onClick={() => {
                  event.exportTranscript?.text?.();
                  setTimeout(() => {
                    setIsShow(false);
                  }, 120);
                }}
              />
              <MenuItem
                title={t('note.info.more_than.export_transcript.csv')}
                onClick={() => {
                  event.exportTranscript?.csv?.();
                  setTimeout(() => {
                    setIsShow(false);
                  }, 120);
                }}
              />
              <MenuItem
                title={t('note.info.more_than.export_transcript.srt')}
                onClick={() => {
                  event.exportTranscript?.srt?.();
                  setTimeout(() => {
                    setIsShow(false);
                  }, 120);
                }}
              />
            </SubMenu>
          }
        />
        <MenuItem
          title={t('note.info.more_than.export_summary')}
          imgSrc="/transparent.png"
          subMenu={
            <SubMenu>
              <MenuItem
                title={t('note.info.more_than.export_summary.pdf')}
                onClick={() => {
                  event.exportSummary?.pdf?.();
                  setTimeout(() => {
                    setIsShow(false);
                  }, 120);
                }}
              />
              <MenuItem
                title={t('note.info.more_than.export_summary.word')}
                onClick={() => {
                  event.exportSummary?.word?.();
                  setTimeout(() => {
                    setIsShow(false);
                  }, 120);
                }}
              />
            </SubMenu>
          }
        />
        <MenuItem
          title={t('note.info.more_than.export_all')}
          imgSrc="/transparent.png"
          subMenu={
            <SubMenu>
              <MenuItem
                title={t('note.info.more_than.export_all.txt')}
                onClick={() => {
                  event.exportAll?.text?.();
                  setTimeout(() => {
                    setIsShow(false);
                  }, 120);
                }}
              />
              <MenuItem
                title={t('note.info.more_than.export_all.pdf')}
                onClick={() => {
                  event.exportAll?.pdf?.();
                  setTimeout(() => {
                    setIsShow(false);
                  }, 120);
                }}
              />
              <MenuItem
                title={t('note.info.more_than.export_all.word')}
                onClick={() => {
                  event.exportAll?.word?.();
                  setTimeout(() => {
                    setIsShow(false);
                  }, 120);
                }}
              />
            </SubMenu>
          }
        />
        <MenuItem
          title={t('note.info.more_than.export_copy')}
          imgSrc="/notes/export.png"
          hoverImgSrc="/notes/export-white.svg"
          onClick={() => {
            event.export?.();
            setTimeout(() => {
              setIsShow(false);
            }, 120);
          }}
        />
        <Divider />
        <MenuItem
          title={t('note.info.more_than.translate_to')}
          imgSrc="/notes/translate.png"
          hoverImgSrc="/notes/translate-white.svg"
          onClick={() => {
            event.translate?.();
            setTimeout(() => {
              setIsShow(false);
            }, 120);
          }}
        />
        <MenuItem
          title={openCluster ? t('note.info.more_than.Separate') : t('note.info.more_than.Aggregate')}
          imgSrc="/notes/cluster.svg"
          hoverImgSrc="/notes/cluster-white.svg"
          onClick={() => {
            event.cluster?.();
            setTimeout(() => {
              setIsShow(false);
            }, 120);
          }}
        />
        <Divider />
        <MenuItem
          title={t('note.info.more_than.delete_note')}
          imgSrc="/notes/trash.png"
          hoverImgSrc="/notes/trash-white.svg"
          onClick={() => {
            event.delete?.();
            setTimeout(() => {
              setIsShow(false);
            }, 120);
          }}
        />
      </ContextMenu>
    </div>
  );

  return (
    <div className=" relative mr-[31px] w-[40px] ">
      <SvgContainer
        className=" hover: mr-[31px] flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-transparent hover:bg-[#DEDEDE] "
        // style={{ display: info2?.ownership == 'myself' ? '' : 'none' }}
        onClick={() => !isShow && focusHandle()}
      >
        <MoreThan2Svg />
      </SvgContainer>
      {isShow && Menu}
    </div>
  );
}
