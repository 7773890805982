import type { InfiniteData } from '@tanstack/react-query';
import { useMutation, useQuery } from '@tanstack/react-query';
import { tagReq } from '~/api/tag.ts';
import { fileInfosAtom } from '~/components/deviceConnection/deviceState';
import { request } from '~/lib/axios';
import type { AxiosCustomError } from '~/lib/axios/type';
import { queryClient } from '~/main';
import { homePageTagRefresh } from '~/pages/notes/Header/TabBar2';
import { noteStore } from '~/pages/notes/store.ts';
import { useRequest } from 'ahooks';
import { getDefaultStore, useSetAtom } from 'jotai';
import { API } from '..';
import type { SharedMutationHookOptions } from '../helper';
import { defineMutation } from '../helper';
import type { ListData } from '../types';
import { reqParamsToBody } from '../utils';
import type { Note } from './types';
import { useAtom } from 'jotai/index';
import { recentlyNoteRefresh } from '~/components/note/store.ts';

type Params = {
  noteId: string;
};

export const deleteNote = defineMutation(async ({ noteId }: Params) => {
  const data = await request.post('/v1/note/delete', reqParamsToBody({ id: noteId }));
  return data;
});

export const useDeleteNoteMutation = (options?: SharedMutationHookOptions<Params>) => {
  const setFileInfos = useSetAtom(fileInfosAtom);
  const [, setRecentlyNoteRefresh] = useAtom(recentlyNoteRefresh);

  return useMutation(deleteNote, {
    async onMutate(params) {
      await queryClient.cancelQueries({ queryKey: API.notes.list().rootKey });
      const [_, previousData] = queryClient.getQueriesData<InfiniteData<ListData<Note>>>(API.notes.list().rootKey)?.[0];

      queryClient.setQueriesData<InfiniteData<ListData<Note>>>(API.notes.list().rootKey, (old) => {
        if (!old) return;
        const nextPages = old?.pages.map((page) => {
          return {
            ...page,
            content: page.content.filter((note) => note.id !== params.noteId),
          };
        });
        setFileInfos((items) => {
          const target = items.find((i) => i.id == params.noteId);
          delete target?.id;
          delete target?.updateState;
          delete target?.progress;
          delete target?.title;
          delete target?.language;
          if (target) target.state = 'deleted';
          return [...items];
        });

        return {
          ...old,
          pages: nextPages,
        };
      });
      options?.onMutate?.(params);

      return { previousData };
    },
    onSuccess(data, variables, context) {
      options?.onSuccess?.(data as any, variables as any, context);
      queryClient.invalidateQueries(API.notes.info({ noteId: variables.noteId }).key);
      setRecentlyNoteRefresh((num) => num + 1);
      homePageTagRefresh();
    },
    onError(error, variables, context) {
      const previousData = context?.previousData;
      if (!previousData) return;
      options?.onError?.(error as AxiosCustomError, variables, context);

      queryClient.setQueriesData(API.notes.list().rootKey, () => previousData);
    },
    onSettled(data, error, variables, context) {
      queryClient.invalidateQueries(API.notes.list().rootKey);

      options?.onSettled?.(data as any, error as any, variables, context);
    },
  });
};
