import { setAppLoading } from '~/App';
import { request } from '~/lib/axios';
import { defineQuery } from '../helper';
import type { User } from './types';

export const info = () =>
  defineQuery(['user.info.self'], async () => {
    const data = await request.post<User>('/v1/user/info', null, {});
    setAppLoading(false);
    return data;
  });
