import { clsxm } from '@hidock/utils';
import { useIsLargeScreen } from '~/hooks/useIsLargeScreen';
import { RecordButton } from '../actions';

export const FloatActions = () => {
  const isLarge = useIsLargeScreen();
  return (
    <div
      className={clsxm(
        'fixed inset-x-0 bottom-0 pb-[20px]',
        'h-20 lg:h-[100px]',
        'backdrop-blur-[2px]',
        'bg-gradient-to-t  from-[#f0f4fa]',
        'flex items-center justify-center',
        'gap-[35px] lg:gap-[25px]'
      )}
    >
      <RecordButton size={isLarge ? 'md' : 'sm'} />
      {/* <UploadButton size={isLarge ? 'md' : 'sm'} /> */}
    </div>
  );
};
