import { sleep } from '~/utils/sleep';

export async function queryDom(
  id: string,
  option?: {
    isConditionTrue?: (dom: HTMLElement) => boolean;
    tryTime?: number;
  }
) {
  const { isConditionTrue = () => true, tryTime = 30 } = option || {};
  let doms: HTMLElement[] = Array.from(document.querySelectorAll(id));
  let dom = doms.find((i) => isConditionTrue(i));
  for (let i = 0; i < tryTime && !dom; i++) {
    await sleep(1000);
    doms = Array.from(document.querySelectorAll(id));
    dom = doms.find((i) => isConditionTrue(i));
  }
  return dom;
}

type ErrorFn = (errorInfos: string[]) => void;

const fns: ErrorFn[] = [];
let isStartLoopCheck = false;
async function errorLoopCheck() {
  if (isStartLoopCheck) return;
  isStartLoopCheck = true;
  let errorInfoEl = Array.from(document.querySelectorAll<HTMLElement>('#my-toast-error-text'));
  while (isStartLoopCheck) {
    if (errorInfoEl.length) {
      const errorInfos = errorInfoEl.map((i) => i.innerText);
      try {
        fns.forEach((fn) => fn(errorInfos));
      } catch (err) {
        console.error(err);
      }
    }
    await sleep(1000);
    errorInfoEl = Array.from(document.querySelectorAll<HTMLElement>('#my-toast-error-text'));
  }
}

export function onToastError(fn: ErrorFn) {
  fns.push(fn);
  errorLoopCheck();
  return () => {
    const idx = fns.findIndex((i) => i == fn);
    if (idx != -1) fns.splice(idx, 1);
    if (fns.length == 0) {
      isStartLoopCheck = false;
    }
  };
}
