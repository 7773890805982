import { clsxm } from '@hidock/utils';
import { FloatActions } from '~/components/floatActions';
import { Hero } from '~/components/hero';
import { NoteResultCard } from '~/components/note';
import { useIsLargeScreen } from '~/hooks/useIsLargeScreen';
import { getWindow_WH } from '~/utils/utils';
import { useEffect, useRef, useState, type FC, type ReactNode } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

export const PageResult = () => {
  const params = useParams();
  const noteId = params?.noteId || '';
  const [query] = useSearchParams();
  const ticket = query.get('ticket');
  // const [showRating, setShowRating] = useState(false)
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [containerHeight, setContainerHeight] = useState(0);
  const navigate = useNavigate();

  return (
    <NoteResultCard
      className={clsxm(
        'z-50 flex flex-col'
        // 'lg:mx-0 lg:mt-16 lg:max-w-[800px]'
      )}
      ticket={ticket || ''}
      noteId={noteId}
      hideNoteWhenDeleting={false}
      onDeleteClick={async () => {
        return true;
      }}
      onDeleted={() => {
        navigate('/', { replace: true });
      }}
      // onTranscriptLoaded={() => setShowRating(true)}
    />
  );
};

const Layout: FC<{ children: ReactNode }> = ({ children }) => {
  const isLg = useIsLargeScreen();
  const WH = getWindow_WH();
  return (
    <div className="flex flex-col items-center overflow-hidden" style={{ minHeight: WH.windowHeight }}>
      <Hero className="mt-[66px] lg:mt-[40px]" size={isLg ? 'lg' : 'sm'} />
      {children}
    </div>
  );
};
