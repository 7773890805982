export const IconUpload = ({ className }: { className?: string }) => {
  return (
    <svg className={className} fill="none" viewBox="0 0 73 72">
      <path
        fill="currentColor"
        d="M18.117 60.51c-1.685 0-3.128-.6-4.33-1.8-1.2-1.202-1.8-2.644-1.798-4.327v-9.191h6.128v9.191h36.766v-9.191h6.127v9.191c0 1.685-.6 3.128-1.801 4.33-1.201 1.2-2.643 1.8-4.326 1.798H18.117zm15.32-12.254v-24.97L25.47 31.25l-4.29-4.442 15.32-15.32 15.319 15.32-4.29 4.442-7.965-7.966v24.97h-6.128z"
      ></path>
    </svg>
  );
};
