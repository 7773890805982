import { useKeyPress } from 'ahooks';
import { atom, useAtom } from 'jotai';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type eventPublicReturned = {
  isClose?: boolean;
};

function getDefaultData() {
  return {
    isShow: false,
    message: '',
    confirm: () => {},
    cancel: () => {},
    confirmText: '',
    cancelText: '',
  };
}

export const showConfirmAtom = atom<{
  isShow: boolean;
  message: string;
  confirm: () => Promise<eventPublicReturned | void> | eventPublicReturned | void;
  cancel: () => Promise<eventPublicReturned | void> | eventPublicReturned | void;
  confirmText?: string;
  cancelText?: string;
  title?: string;
}>(getDefaultData());
export function useShowConfirm() {
  return useAtom(showConfirmAtom);
}

export function SecondConfirmation() {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'misc.modal.delete',
  });
  const { t: tx } = useTranslation(undefined, {
    keyPrefix: 'misc.modal',
  });
  const [showConfirmInfo, setShowConfirmInfo] = useShowConfirm();
  useKeyPress('enter', () => {
    if (showConfirmInfo.isShow) showConfirmInfo.confirm();
  });
  return (
    <div
      className="fixed bottom-0 left-0 right-0 top-0 z-[99999999999] flex items-center justify-center bg-[rgba(0,0,0,0.3)]"
      style={{ display: showConfirmInfo.isShow ? 'block' : 'none' }}
    >
      <div
        className="absolute left-1/2 top-1/2 box-border w-[474px] -translate-x-1/2 -translate-y-1/2 transform rounded-[24px] bg-white p-[30px]"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <div className="flex w-full items-center justify-between ">
          <p className="text-[26px] font-[700] leading-[16px] text-black">{showConfirmInfo.title || tx('attention')}</p>
        </div>
        <div className="mt-[20px] whitespace-pre-wrap text-[20px] font-[400]">{showConfirmInfo.message}</div>
        <div className="mt-[30px] flex justify-end">
          <div
            className="mr-[14px] flex h-[38px] max-w-[200px] cursor-pointer items-center justify-center rounded-[8px] border-[1px] border-[#D9D9D9] bg-[#FFFFFF] px-[8px] text-[16px] font-[500] hover:border-[rgba(0,0,0,0)] hover:bg-[#E8EAEA]"
            onClick={async () => {
              showConfirmInfo.cancelText = '';
              showConfirmInfo.confirmText = '';
              const info = await showConfirmInfo.cancel();
              if (info?.isClose) setShowConfirmInfo(getDefaultData());
            }}
          >
            {showConfirmInfo.cancelText || t('cancel')}
          </div>
          <div
            className="flex h-[38px] max-w-[200px] cursor-pointer items-center justify-center rounded-[8px] bg-[#FA5B65] px-[8px] text-[16px] font-[500] text-[#fff] hover:shadow-lg hover:shadow-[#FA5B654D]"
            onClick={async () => {
              showConfirmInfo.cancelText = '';
              showConfirmInfo.confirmText = '';
              const info = await showConfirmInfo.confirm();
              if (info?.isClose) setShowConfirmInfo(getDefaultData());
            }}
          >
            {showConfirmInfo.confirmText || t('confirm')}
          </div>
        </div>
      </div>
    </div>
  );
}
