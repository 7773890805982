import { request } from '~/lib/axios';

type CountryList = {
  id: string;
  country: string;
  alpha2: string;
  alpha3: string;
};

export function contryListReq() {
  return request.post<CountryList[]>('/v1/user/country/list');
}
