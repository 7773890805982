import { request } from '~/lib/axios';
import { reqParamsToBody } from '../utils';

type Params = {
  oldPassword: string;
  password: string;
  password2: string;
};

export function passwordUpdateReq(params: Params) {
  return request.post('/v1/user/password/update', reqParamsToBody(params));
}
