export const IconRecord = ({ className }: { className?: string }) => {
  return (
    <svg className={className} fill="none" viewBox="0 0 73 72">
      <g fill="#fff" filter="url(#filter0_d_999_29093)">
        <path d="M46.346 18.756c0-5.906-4.837-10.693-10.804-10.693-5.968 0-10.805 4.787-10.805 10.693v24.18c0 5.906 4.837 10.693 10.805 10.693 5.967 0 10.804-4.787 10.804-10.692V18.756z"></path>
        <path d="M53.586 42.446h-3.531c0 3.81-1.53 7.462-4.25 10.156a14.59 14.59 0 01-10.262 4.206 14.59 14.59 0 01-10.262-4.206 14.286 14.286 0 01-4.25-10.156H17.5a17.686 17.686 0 004.757 11.821 18.042 18.042 0 0011.52 5.651v3.809h3.531v-3.809a18.042 18.042 0 0011.52-5.65 17.687 17.687 0 004.758-11.822z"></path>
      </g>
      <defs>
        <filter
          id="filter0_d_999_29093"
          width="44.086"
          height="63.875"
          x="13.5"
          y="8.063"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            result="hardAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="2"></feGaussianBlur>
          <feComposite in2="hardAlpha" operator="out"></feComposite>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.11 0"></feColorMatrix>
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_999_29093"></feBlend>
          <feBlend in="SourceGraphic" in2="effect1_dropShadow_999_29093" result="shape"></feBlend>
        </filter>
      </defs>
    </svg>
  );
};
