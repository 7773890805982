import { reqParamsToBody } from '~/api/utils';
import { request } from '~/lib/axios';

type Params = {
  noteId: string;
  minutes: number;
};

export function savedReq(params: Params) {
  return request.post('/v2/note/survey/save', reqParamsToBody(params));
}
