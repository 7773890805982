import { useEffect, useMemo, useRef, useState } from 'react';
import type { DetailedHTMLProps, HTMLAttributes } from 'react';
import { clsxm } from '@hidock/utils';
import { MoreThanSvg } from '~/svg/svg';
import { sleep } from '~/utils/sleep';
import { atom, useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useHover } from 'usehooks-ts';
import { jensen } from './utils';
import { btnStateAtom } from './deviceState';

const H1LeftImgInfo = {
  earPhone: {
    src: '/connectDevice/guide/ear-phone.png',
    x: 471,
    y: 113,
    w: 167,
    h: 143,
    block: [
      {
        x: 110,
        y: -2,
        content: 'ear-phone.fn-key',
      },
      {
        x: 73,
        y: 110,
        content: 'ear-phone.fn-tap',
      },
    ],
  },
  back: {
    src: '/connectDevice/guide/jensen-back.png',
    x: 103,
    y: 334,
    w: 252,
    h: 105,
    block: [
      {
        x: 18,
        y: 80,
        content: 'back-view.power-input',
      },
      {
        x: 37,
        y: 80,
        content: 'back-view.usb-c-upstream',
      },
      {
        x: 67,
        y: 80,
        content: 'back-view.hdmi-2.0-4k-60hz',
      },
      {
        x: 98,
        y: 80,
        content: 'back-view.hdmi-2.0-4k-60hz',
      },
      {
        x: 125,
        y: 80,
        content: 'back-view.usb-c2',
      },
      {
        x: 151,
        y: 80,
        content: 'back-view.usb-3.2-gen2',
      },
      {
        x: 180,
        y: 80,
        content: 'back-view.usb-3.1-gen1',
      },
      {
        x: 208,
        y: 80,
        content: 'back-view.2.5-gigabit-ethernet',
      },
    ],
  },
  left: {
    src: '/connectDevice/guide/jensen-left.png',
    x: 486,
    y: 321,
    w: 151,
    h: 118,
    block: [
      {
        x: 6,
        y: 91,
        content: 'left-view.bluetooth-paring-btn',
      },
      {
        x: 41 - 7,
        y: 92,
        content: 'left-view.usb-3.2.gen-2-pd',
      },
      {
        x: 76 - 7,
        y: 96,
        content: 'left-view.micro-sd2',
      },
      {
        x: 90 - 7,
        y: 89,
        content: 'left-view.micro-sd',
      },
    ],
  },
  right: {
    src: '/connectDevice/guide/jensen-right.png',
    x: 95,
    y: 85,
    w: 268,
    h: 171,
    block: [
      {
        x: 145,
        y: 103,
        content: 'front-view.bnc-slider',
      },
      {
        x: 183,
        y: 110,
        content: 'front-view.volume',
      },
      {
        x: 185,
        y: 79,
        content: 'front-view.end-call',
      },
      {
        x: 205,
        y: 53,
        content: 'front-view.ans-call',
      },
      {
        x: 213,
        y: 87,
        content: 'front-view.mute',
      },
      {
        x: 236,
        y: 62,
        content: 'front-view.hidock-key',
      },
      {
        x: 247,
        y: 106,
        content: 'right-view.pogo-pin',
      },
    ],
  },
} as const;

const H1ELeftImgInfo = {
  earPhone: {
    src: '/connectDevice/guide/ear-phone-2.png',
    className: 'rotate-[-9.71deg]',
    x: 471,
    y: 113,
    w: 227,
    h: 179,
    block: [
      {
        x: 138,
        y: 10,
        content: 'ear-phone.fn-key',
      },
      {
        x: 108,
        y: 128,
        content: 'ear-phone.fn-tap',
      },
    ],
  },
  back: {
    src: '/connectDevice/guide/jensen-back-2.png',
    x: 103,
    y: 334,
    w: 252,
    h: 105,
    block: [
      {
        x: 20,
        y: 80,
        content: 'back-view.power-switch',
      },
      {
        x: 45,
        y: 80,
        content: 'back-view.power-input',
      },
      {
        x: 63,
        y: 80,
        content: 'back-view.h1e-usb-c-upstream',
      },
      {
        x: 94,
        y: 80,
        content: 'back-view.hdmi-2.0-4k-60hz',
      },
      {
        x: 122,
        y: 80,
        content: 'back-view.usb-c2',
      },
      {
        x: 150,
        y: 80,
        content: 'back-view.usb-3.2-gen2',
      },
      {
        x: 179,
        y: 80,
        content: 'back-view.usb-3.1-gen1',
      },
      {
        x: 208,
        y: 80,
        content: 'back-view.1-gigabit-ethernet',
      },
    ],
  },
  left: {
    src: '/connectDevice/guide/jensen-left-2.png',
    x: 486,
    y: 321,
    w: 151,
    h: 118,
    block: [
      {
        x: 7,
        y: 91,
        content: 'left-view.bluetooth-paring-btn',
      },
      {
        x: 41 - 7,
        y: 91,
        content: 'left-view.usb-3.2.gen-2-pd',
      },
    ],
  },
  right: {
    src: '/connectDevice/guide/jensen-right-2.png',
    x: 95,
    y: 85,
    w: 268,
    h: 171,
    block: [
      {
        x: 145,
        y: 103,
        content: 'front-view.bnc-slider',
      },
      {
        x: 180,
        y: 110,
        content: 'front-view.volume',
      },
      {
        x: 184,
        y: 78,
        content: 'front-view.end-call',
      },
      {
        x: 206,
        y: 53,
        content: 'front-view.ans-call',
      },
      {
        x: 214,
        y: 87,
        content: 'front-view.mute',
      },
      {
        x: 235,
        y: 62,
        content: 'front-view.hidock-key',
      },
      {
        x: 246,
        y: 102,
        content: 'right-view.pogo-pin',
      },
    ],
  },
} as const;

const DisPersionContainer = styled.svg<{ r: string | string }>`
  --r: ${(props) => props.r};
  --b: 1;
  @keyframes morph {
    0% {
      --b: 1;
      stroke-opacity: 0.1;
    }

    25% {
      --b: 2;
      stroke-opacity: 0.5;
    }

    60% {
      --b: 3;
      stroke-opacity: 0.3;
    }

    75% {
      --b: 1;
      stroke-opacity: 0.3;
    }

    100% {
      --b: 1;
      stroke-opacity: 0;
    }
  }
  .loading-circle-1 {
    /* -webkit-animation: morph 1s ease infinite; */
    animation: morph 2s linear infinite;
    stroke-width: calc(var(--r) * var(--b) * 5 / 3);
  }
`;

function DisPersion() {
  const color = '#0BEFE3';
  const r = 3;

  return (
    <DisPersionContainer
      xmlns="http://www.w3.org/2000/svg"
      className={clsxm(' pointer-events-none relative -left-[30px] -top-[30px]')}
      version="1.1"
      r={r + ''}
    >
      <circle className="loading-circle-1" cx="40" cy="40" r={r} stroke={color} fill={color} />
    </DisPersionContainer>
  );
}

function Circle(
  props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    onHover?: (state: boolean) => void;
    text: string;
  }
) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'wu.guide',
  });
  const containerRef = useRef<null | HTMLDivElement>(null);
  const isHover = useHover(containerRef);

  return (
    <div {...props} ref={containerRef}>
      <DisPersion />
      <div className=" absolute bottom-full left-[10px] " style={{ visibility: isHover ? 'visible' : 'hidden' }}>
        <div className=" absolute bottom-full left-1/2 flex   w-[300px] -translate-x-1/2 justify-center text-[12px] font-[600] text-white">
          <span className="whitespace-pre bg-[rgba(0,0,0,0.5)] p-[10px] text-center">
            {
              //@ts-ignore
              t(props.text)
            }
          </span>
        </div>

        <div
          className={`h-[0px] w-[2px] bg-[#0BEFE3] duration-[.35s]`}
          style={{
            height: isHover ? 80 : 0,
          }}
        />
      </div>
    </div>
  );
}

const isBncStartAtom = atom(false);
export default function GuidePage() {
  const [leftLg, setLeftLg] = useState(true);
  const { t } = useTranslation();
  const [btnState] = useAtom(btnStateAtom);

  const leftConfigInfo = useMemo(() => {
    let info: any = H1LeftImgInfo;
    info = jensen.getModel() === 'hidock-h1e' ? H1ELeftImgInfo : H1LeftImgInfo;
    return info as typeof H1ELeftImgInfo | typeof H1LeftImgInfo;
  }, [btnState]);

  const leftGuideEl = (
    <div
      className=" relative col-[1/2] row-[4/5] rounded-[10px] bg-[#F0F0F0] duration-300"
      style={{
        backgroundColor: leftLg ? '#F0F0F0' : 'rgba(0,0,0,0.2)',
        overflow: leftLg ? '' : 'hidden',
      }}
    >
      <div className=" relative">
        {Object.entries(leftConfigInfo).map(([key, item]) => {
          const { x, y } = item;
          const raidoEls = item.block.map((radioInfo) => {
            return (
              <Circle
                className=" absolute h-[20px] w-[20px] cursor-pointer rounded-full duration-[.35s]"
                data-name={radioInfo.content}
                data-pos={`${x + radioInfo.x}-${y + radioInfo.y}`}
                style={{
                  left: x + radioInfo.x,
                  top: y + radioInfo.y,
                  zIndex: 2,
                }}
                text={radioInfo.content}
              />
            );
          });
          return (
            <>
              <img
                src={item.src}
                key={item.src}
                className={clsxm(' absolute', item.clssName)}
                style={{
                  left: item.x,
                  top: item.y,
                  width: item.w,
                  height: item.h,
                }}
              />
              {leftLg && raidoEls}
            </>
          );
        })}
      </div>
      <div
        className=" absolute left-1/2 top-full flex h-[40px] w-[40px] origin-center -translate-x-1/2 -translate-y-1/2 cursor-pointer items-center justify-center rounded-full bg-white"
        style={{
          boxShadow: '0 0 20px rgba(0,0,0,0.15)',
          display: leftLg ? '' : 'none',
        }}
        onClick={() => setLeftLg(false)}
      >
        <MoreThanSvg />
      </div>
    </div>
  );

  const [isBncStart, setIsBncStart] = useAtom(isBncStartAtom);
  //k = 44  y属于66~110
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const bncBtn = () => {
    if (!isBncStart) {
      return (
        <div
          className="m-[44px_auto_0] h-[46px] w-[186px] cursor-pointer rounded-[8px] bg-white text-center text-[16px] font-[600] leading-[46px] text-[#00A2AF] hover:bg-[#00A2AF] hover:text-white"
          onClick={() => {
            jensen.beginBNC().then((res) => {
              console.log(res);
              if (res?.result == 'success') {
                setIsBncStart(true);
                sliderRef.current!.style.transition = '0.3s';
                sliderRef.current!.style.top = '66px';
                sliderRef.current!.style.left = '252px';
              }
            });
          }}
        >
          {t('wu.guide.bnc.btn.begin')}
        </div>
      );
    }
    return (
      <div
        className="m-[44px_auto_0] h-[46px] w-[186px] cursor-pointer rounded-[8px] bg-white text-center text-[16px] font-[600] leading-[46px] text-[#00A2AF] hover:bg-[#00A2AF] hover:text-white"
        onClick={() => {
          jensen.endBNC().then((res) => {
            console.log(res);
            if (res?.result == 'success') {
              sliderRef.current!.style.transition = '0.3s';
              sliderRef.current!.style.top = '110px';
              sliderRef.current!.style.left = '253px';
              setIsBncStart(false);
            }
          });
        }}
      >
        {t('wu.guide.bnc.btn.end')}
      </div>
    );
  };

  useEffect(() => {
    let stopTime = Date.now() + 99 * 1000; //bnc播放时间;
    let isContince = true;
    async function exec() {
      if (isBncStart) {
        while (Date.now() < stopTime) {
          await sleep((stopTime - Date.now()) / 2);
          if (!isContince) return;
        }
        setIsBncStart(false);
      }
    }
    exec();
    return () => {
      isContince = false;
    };
  }, [isBncStart]);

  const rightHiDockImg = useMemo(() => {
    return jensen.getModel() === 'hidock-h1e' ? '/connectDevice/guide/jensen-2.png' : '/connectDevice/guide/jensen.png';
  }, []);

  const rightHiDockSliderImg = useMemo(() => {
    return jensen.getModel() === 'hidock-h1e' ? '/connectDevice/guide/slider-2.png' : '/connectDevice/guide/slider.png';
  }, []);

  const rightGuideEl = (
    <div
      className=" relative col-[2/3] row-[4/5] rounded-[10px] bg-[#F0F0F0]"
      style={{
        backgroundColor: !leftLg ? '#F0F0F0' : 'rgba(0,0,0,0.2)',
        overflow: !leftLg ? '' : 'hidden',
      }}
    >
      <div className=" relative w-full p-[0_20px]">
        <p
          className=" mt-[71px] text-center text-[22px] font-[600] text-[#3D3D3D]"
          style={{ display: leftLg ? 'none' : '' }}
        >
          {t('wu.guide.bnc.describe')}
        </p>
        <div
          style={{ backgroundImage: `url(${rightHiDockImg})` }}
          className={`relative m-[62px_auto_0] h-[176px] w-[376px] bg-[url('${rightHiDockImg}')] bg-cover`}
        >
          <div
            ref={sliderRef}
            className={`absolute left-[252.5px] top-[110] h-[20px] w-[10px] cursor-pointer bg-[url('${rightHiDockSliderImg}')] bg-contain`}
            style={{
              left: 253,
              top: 110,
              backgroundImage: `url(${rightHiDockSliderImg})`,
            }}
          />
        </div>
        {/* <img src="" width="376px" height="176px" className="m-[62px_auto_0]" /> */}
        {!leftLg && bncBtn()}
      </div>
      <div
        className="  absolute left-1/2 top-full flex h-[40px] w-[40px] -translate-x-1/2 -translate-y-1/2 rotate-180 cursor-pointer items-center justify-center rounded-full bg-white"
        style={{
          boxShadow: '0 0 20px rgba(0,0,0,0.15)',
          display: !leftLg ? '' : 'none',
        }}
        onClick={() => setLeftLg(true)}
      >
        <MoreThanSvg />
      </div>
    </div>
  );

  return (
    <div className=" flex justify-center">
      <div
        className="box-border grid w-[1068px] grid-rows-[15px_auto_15px_501px] justify-center gap-x-[16px]"
        style={{
          gridTemplateColumns: leftLg ? '761px 1fr' : '291px 1fr',
        }}
      >
        <div
          className=" col-[1/2] row-[2/3] text-center text-[26px] font-[600]"
          style={{ color: leftLg ? '#00A2AF' : '#686868' }}
        >
          {t('wu.guide.title2')}
        </div>
        {leftGuideEl}
        <div
          className="col-[2/3] row-[2/3] text-center text-[26px] font-[600]"
          style={{ color: !leftLg ? '#00A2AF' : '#686868' }}
        >
          {t('wu.guide.bnc.title')}
        </div>
        {rightGuideEl}
      </div>
    </div>
  );
}
