import { clsxm } from '@hidock/utils';
import { sleep } from '~/utils/sleep';
import { useEffect, useState } from 'react';
import loadImgObj, { tagArr } from './img';

const loadImgs = tagArr;

export default function (props: { className?: string }) {
  const [visibleIdx, setVisibleIdx] = useState(0);
  useEffect(() => {
    let isRun = true;
    async function exec() {
      for (let i = 0; i < loadImgs.length; i++) {
        if (!isRun) return;
        setVisibleIdx(i);
        await sleep(34);
      }
    }
    exec();
    return () => {
      isRun = false;
    };
  }, []);

  return (
    <div className={clsxm(' relative aspect-[320/225] w-[320px] flex-shrink-0', props.className)}>
      {loadImgs.map((src, idx) => {
        return (
          <img
            src={src}
            style={{ visibility: visibleIdx == idx ? 'visible' : 'hidden' }}
            key={src}
            className=" absolute left-0 top-0  h-full w-full"
          />
        );
      })}
    </div>
  );
}
