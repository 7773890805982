import { request } from '~/lib/axios';
import { Note2 } from './note2s/info2';
import { reqParamsToBody } from './utils';

export declare module noteTemplate {
  type list = {
    content: {
      id: number;
      code: string;
      title: string;
      category: string;
      description: string;
      score: number | null;
      rolePlayed: null;
      audience: null;
      createTime: null | number;
      level: string;
      default: boolean;
      hasTemplateAccess?: boolean;
      tags?: string[];
    }[];
  };
  type detail = {
    note: Note2;
    fields: {
      name: string;
      value: string;
    }[];
  };

  type Info = {
    template: {
      id: number;
      code: string;
      title: string;
      category: string;
      description: string;
      score: null;
      rolePlayed: null;
      audience: null;
      createTime: null;
      level: string;
      default: boolean;
      hasTemplateAccess?: boolean;
    };
    fields: {
      id: number;
      code: null;
      name: string;
      type: null;
      level: 'pro' | null;
      description: null;
      prompt: string;
      userId: null;
      showIndex: number;
    }[];
  };
}

export const templateReq = {
  list(params: { pageSize?: number; type?: string }) {
    return request.post<noteTemplate.list>('/v1/template/list', reqParamsToBody(params));
  },
  info(params: { code: string }) {
    return request.post<noteTemplate.Info>('/v1/template/info', reqParamsToBody(params));
  },
  detail(params: { id: string }) {
    return request.post<noteTemplate.detail>('/v2/note/detail', reqParamsToBody(params));
  },
  setDefault(params: { code: string }) {
    return request.post('/v1/template/setDefault', reqParamsToBody(params));
  },
};
