import { VocabularyReq } from '~/api/vocabulary';
import { globalAddEl } from '~/components/globalLayout';
import { myMessage } from '~/components/MyToast/MyToast';
import { atom, useAtom } from 'jotai';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ModalContainer from '../../general/Modal';
import { NormalizeContnent } from './selectionBtn';

export const replaceWordInfoAtom = atom({
  show: false,
  replace: '',
  to: '',
  event: {
    refreshNote() {},
  },
});

function ReplaceWordbulary() {
  const [state, setState] = useAtom(replaceWordInfoAtom);
  const { noteId = '' } = useParams();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  async function replaceHandle(word: string, replacement: string) {
    if (word.length < 2 || word.length > 50 || replaceHandle.length < 2 || replaceHandle.length > 50) {
      myMessage.error(t('note.content-repalce.wordLen_Illegal'), 2000);
      return;
    }
    setLoading(true);

    let res: any = await VocabularyReq.replace({
      noteId,
      before: NormalizeContnent(word),
      after: NormalizeContnent(replacement),
    });

    if (res?.error) {
    } else {
      myMessage.success(t('note.content-replace.success'), 2000);
      state.event.refreshNote();
    }

    setState({
      ...state,
      show: false,
    });
    setLoading(false);
  }

  if (!state.show) return null;

  return (
    <ModalContainer
      className=" flex flex-col items-center rounded-[16px]  bg-white"
      btnClssName="right-[15px] top-[15px]"
      onClose={() => setState((state) => ({ ...state, show: false }))}
    >
      <div className=" mb-[14px] mt-[42px] text-[28px] font-[600] leading-[48px]">
        {t('note.content-replace.modal.title')}
      </div>
      <div className=" flex flex-col px-[96px]">
        <div className=" pr-[10px] text-[20px] font-[600] leading-[48px] text-[#3D3D3D]">
          {t('note.content-replace.modal.replace')}
        </div>
        <input
          value={state.replace}
          maxLength={30}
          className=" h-[60px] w-[272px] rounded-[8px] border border-[#9C9C9C] text-center text-[20px] outline-none"
          onChange={(e) => {
            state.replace = e.currentTarget.value;
            setState({ ...state });
          }}
        />

        <div className=" pr-[10px]  text-[20px] font-[600] leading-[48px] text-[#3D3D3D]">
          {t('note.content-replace.modal.to')}
        </div>
        <input
          className=" h-[60px] w-[272px] rounded-[8px] border border-[#9C9C9C] text-center text-[20px] outline-none"
          value={state.to}
          maxLength={30}
          onChange={(e) => {
            state.to = e.currentTarget.value;
            setState({ ...state });
          }}
        />
        <div className="mb-[50px] mt-[46px] flex items-center justify-center">
          <button
            className=" flex items-center justify-center rounded-[8px] bg-[#00A2AF] p-[12px_53px] text-[16px] font-[600] leading-[24px] text-white"
            onClick={() => {
              replaceHandle(state.replace, state.to);
            }}
          >
            {loading ? <img src="/loading.svg" /> : t('note.content-replace.modal.save')}
          </button>
        </div>
      </div>
    </ModalContainer>
  );
}

globalAddEl(<ReplaceWordbulary />, {
  isFullScreen: false,
});
