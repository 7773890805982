import { useNavigate, useParams } from 'react-router-dom';
import { Generating } from './Generating';

export const PageGenerating = () => {
  const navigate = useNavigate();
  const { ticket = '' } = useParams();

  return (
    <>
      <Generating
        ticket={ticket}
        onCancel={() => navigate('/', { replace: true })}
        onGenerated={(id) =>
          navigate(`/audio/result/${id}?ticket=${ticket}`, {
            state: {
              mode: 'result',
            },
            replace: true,
          })
        }
        onError={() => navigate('/')}
      />
    </>
  );
};
