import { request } from '~/lib/axios';
import type { Note, RecentlyNote } from './notes/types';
import type { ListData } from './types';
import { reqParamsToBody } from './utils';

export declare module NoteReq {
  type noteInfo = {
    folderId?: string;
    tagId?: string;
    pageIndex?: number;
    pageSize?: number;
    count?: number;
  };
}

function getList(params: NoteReq.noteInfo) {
  return request.post<ListData<Note>>('/v2/note/list', reqParamsToBody(params));
}

function getRecentlyList(params: NoteReq.noteInfo) {
  return request.post<RecentlyNote[]>('/v2/note/latest', reqParamsToBody(params));
}

export const noteReq = {
  getList,
  getRecentlyList,
};
