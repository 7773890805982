import { useMutation } from '@tanstack/react-query';
import { request } from '~/lib/axios';
import { setAccessToken, setGi } from '~/store/user';
import { deleteCookie } from '~/utils/utils';
import { defineMutation } from '../helper';

export const logout = defineMutation(() => {
  return request.get('/v1/user/logout');
});

export const useLogoutMutation = () =>
  useMutation(logout, {
    onMutate() {
      setAccessToken('');
      deleteCookie('accesstoken');
      deleteCookie('gi');
      setGi('');
      window.location.href = `/?${import.meta.env.VITE_NOTE_VERSION}`;
    },
  });
