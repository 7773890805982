import { authCodeReq } from '~/api/users/authCode';
import { resetSaveReq } from '~/api/users/resetSave';
import { myMessage } from '~/components/MyToast/MyToast';
import { generalErrorHandle } from '~/utils/utils';
import { atom, useAtom } from 'jotai';
import { ChangeEvent, ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const showRetrivePasswordAtom = atom(false);

export function RetrivePasswordMobile() {
  const { t } = useTranslation();
  const [show, setShow] = useAtom(showRetrivePasswordAtom);
  const backHandle = () => {
    history.back();
    setShow(false);
  };
  const showRef = useRef(show);
  showRef.current = show;
  const initData = {
    email: '',
    authcode: '',
    password: '',
    password2: '',
  };
  const [data, setData] = useState(initData);

  useEffect(() => {
    if (!show) setData(initData);
  }, [show]);

  useEffect(() => {
    function pushHistory() {
      console.log('pushHistory');
      var state = { title: 'title', url: '#' };
      window.history.pushState(state, 'title', '#');
    }
    if (show) pushHistory();
  }, [show]);

  useEffect(() => {
    function handle() {
      if (showRef.current) setShow(false);
    }
    window.addEventListener('popstate', handle, false);
    return () => {
      window.removeEventListener('popstate', handle, false);
    };
  }, []);

  async function getCode() {
    const res = await authCodeReq({ email: data.email });
    if (!res) myMessage.success(t('misc.sent-success'), 2000);
  }

  async function changeHandle() {
    const res = await resetSaveReq(data);
    if (!res) myMessage.success(t('misc.change-success'), 2000);
  }

  if (!show) return;
  function bindInputProps(key: keyof typeof data) {
    return {
      value: data[key],
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.value);
        setData({
          ...data,
          [key]: e.target.value,
        });
      },
    };
  }

  const h1El = <h1 className="mx-auto mt-[70px] text-[26px] font-[600]">{t('user.resetPassowrd.title')}</h1>;
  const describeEl = (
    <p className=" whitespace-wrap mt-[30px] text-center text-[16px] leading-[20px] text-black">
      {t('user.resetPassowrd.desrcibe')}
    </p>
  );
  const emailVerify = (
    <div className="mt-[45px] flex w-full flex-col">
      <p className=" ml-[10px] text-[16px] font-[600]">{t('user.email')}</p>

      <input
        className="mt-[10px] h-[45px] w-full rounded-full border border-[#ACACAC] pl-[12px] outline-none"
        {...bindInputProps('email')}
      />
      <div className=" mb-[20px] mt-[20px] flex justify-center">
        <button className=" mx-auto  text-[16px] font-[18px] text-[#00A2AF] underline" onClick={() => getCode()}>
          {t('user.get-code')}
        </button>
      </div>
      {/* <p className=" ml-[10px] font-[600] text-[16px]">{t('user.verify-code')}</p> */}
      <p className=" ml-[10px] mt-[10px] w-full text-[16px] font-[600]">{t('user.verify-code')}</p>
      <input
        className="mt-[10px] h-[45px] w-full rounded-full border border-[#ACACAC] pl-[12px] outline-none"
        {...bindInputProps('authcode')}
        autoComplete="off"
      />
    </div>
  );

  const password = (
    <div className="">
      <p className="ml-[10px] mt-[30px] text-[16px] font-[600] leading-[16px]">{t('user.newPassword')}</p>
      <input
        {...bindInputProps('password')}
        type="password"
        className="mt-[10px] h-[45px] w-full rounded-full border border-[#ACACAC] pl-[12px] outline-none"
        placeholder={t('user.resetPassword.password.placeholder')}
        autoComplete="off"
      />
      <input
        {...bindInputProps('password2')}
        type="password"
        className="mt-[10px] h-[45px] w-full rounded-full border border-[#ACACAC] pl-[12px] outline-none"
        placeholder={t('user.resetPassword.confirm.placeholder')}
        autoComplete="off"
      />
    </div>
  );

  return (
    <div
      className=" fixed bottom-0 left-0 right-0 top-0 z-[1000] flex items-center justify-center  bg-[rgba(0,0,0,0.3)]"
      onClick={(e) => backHandle()}
    >
      <div
        className=" flex-shrink-1 relative flex max-h-screen w-[100%] flex-col items-center overflow-auto bg-white px-[40px]"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className=" absolute right-5 top-5 z-20 mt-[3px] flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-[50%] hover:bg-[#E8EAEA]"
          onClick={() => backHandle()}
        >
          <img src="/connectDevice/wrong.png" className="h-[16px] w-[16px]" />
        </div>
        {h1El}
        {describeEl}
        {emailVerify}
        {password}
        <button
          className=" mb-[14px] mt-[40px] w-[216px] rounded-full bg-[#E8EAEA] text-[18px] font-[600] leading-[45px] text-black"
          onClick={changeHandle}
        >
          {t('user.resetPassword.change.btn')}
        </button>
      </div>
    </div>
  );
}

export default function RetrivePassword() {
  const { t } = useTranslation();
  const [show, setShow] = useAtom(showRetrivePasswordAtom);
  const initData = {
    email: '',
    authcode: '',
    password: '',
    password2: '',
  };
  const [data, setData] = useState(initData);

  useEffect(() => {
    if (!show) setData(initData);
  }, [show]);

  async function getCode() {
    const res = await authCodeReq({ email: data.email });
    if (!res) myMessage.success(t('misc.sent-success'), 2000);
  }

  async function changeHandle() {
    const res = await resetSaveReq(data);
    if (!res) myMessage.success(t('misc.change-success'), 2000);
  }

  if (!show) return;
  function bindInputProps(key: keyof typeof data) {
    return {
      value: data[key],
      onChange: (e: ChangeEvent<HTMLInputElement>) => {
        console.log(e.target.value);
        setData({
          ...data,
          [key]: e.target.value,
        });
      },
    };
  }

  const h1El = <h1 className="mt-[35px] text-[34px] font-[600]">{t('user.resetPassowrd.title')}</h1>;
  const describeEl = (
    <p className="w-[742px] whitespace-pre-wrap text-[14px] leading-[24px]">{t('user.resetPassowrd.desrcibe')}</p>
  );
  const emailVerify = (
    <div className="mt-[40px] grid w-[646px] grid-cols-[auto_auto] grid-rows-[auto_1fr]">
      <p className="text-[24px] font-[600]">{t('user.email')}</p>
      <p className="text-[24px] font-[600]">{t('user.verify-code')}</p>
      <span className=" relative mt-[16px] h-[58px] w-[417px] ">
        <input
          className="h-full w-full rounded-[12px] border border-[#ACACAC] pl-[12px] outline-none"
          {...bindInputProps('email')}
        />
        <button
          className=" absolute right-0 top-0 h-[58px] w-[112px] rounded-[12px] bg-[#00A2Af] leading-[58px] text-white"
          onClick={() => getCode()}
        >
          {t('user.get-code')}
        </button>
      </span>

      <input
        className=" mt-[16px] h-[58px] w-[217px] rounded-[12px] border border-[#ACACAC] pl-[12px] outline-none"
        {...bindInputProps('authcode')}
      />
    </div>
  );

  const password = (
    <div className=" w-[646px]">
      <p className="mt-[30px] text-[24px] font-[600] leading-[16px]">{t('user.newPassword')}</p>
      <input
        {...bindInputProps('password')}
        type="password"
        className=" mt-[17px] h-[58px] w-full rounded-[12px] border border-[#ACACAC] pl-[12px] outline-none"
        placeholder={t('user.resetPassword.password.placeholder')}
      />
      <input
        {...bindInputProps('password2')}
        type="password"
        className=" mt-[15px] h-[58px] w-full rounded-[12px] border border-[#ACACAC] pl-[12px] outline-none"
        placeholder={t('user.resetPassword.confirm.placeholder')}
      />
    </div>
  );

  return (
    <div
      className=" fixed bottom-0 left-0 right-0 top-0 z-[1000] flex items-center justify-center  bg-[rgba(0,0,0,0.3)]"
      onClick={(e) => setShow(false)}
    >
      <div
        className=" relative flex w-[970px] flex-col items-center rounded-[28px] bg-white"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          className=" absolute right-5 top-5 z-20 mt-[3px] flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-[50%] hover:bg-[#E8EAEA]"
          onClick={() => setShow(false)}
        >
          <img src="/connectDevice/wrong.png" className="h-[16px] w-[16px]" />
        </div>
        {h1El}
        {describeEl}
        {emailVerify}
        {password}
        <button
          className=" mb-[20px] mt-[40px] h-[50px] w-[268px] rounded-full bg-[#E8EAEA] text-[18px] text-black"
          onClick={changeHandle}
        >
          {t('user.resetPassword.change.btn')}
        </button>
      </div>
    </div>
  );
}
