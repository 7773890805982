import { clsxm } from '@hidock/utils';
import type { Note } from '~/api/notes/types';
import type { FC } from 'react';
import { Markdown } from '../Markdown';
import type { TagVariant } from './Tag';
import { Tag } from './Tag';
import { motion } from 'framer-motion';

export const NoteListCard: FC<{
  className?: string;
  note?: Note;
  onClick?: () => void;
}> = ({ className, note, onClick }) => {
  if (!note) return null;
  return (
    <div
      className={clsxm(
        ' relative rounded-[14px] pb-10 pl-6 pr-6 pt-6',
        ' text-solid aspect-square bg-white',
        className
      )}
      onClick={onClick}
    >
      <motion.div
        key={note.id}
        whileHover={{
          scale: 1.05,
        }}
        whileTap={{ scale: 0.97 }}
        className="cursor-pointer"
      >
        <header className="flex justify-between border-b border-[#D8D8D8] pb-[22px]">
          <Tag
            variant={(note?.type || '') as TagVariant}
            className={' h-[24px]'}
            labelClassName={'w-[40px] flex justify-center items-center'}
          />
          {note.level == 'pro' && <img src="/userRole/membershipPro.png" className=" aspect-[2/1] h-[20px] w-[40px]" />}
        </header>
        <Markdown
          className={clsxm('mt-3', 'scrollbar-none h-[256px] overflow-y-auto')}
          markdown={`## ${note.title} \n\n ${note.conciseSummary || ''}`}
        />
      </motion.div>
      <div className=" absolute bottom-[10px] right-[30px] text-[13px] text-[#555555]">
        {new Date(note.createTime).toLocaleString()}
      </div>
    </div>
  );
};
