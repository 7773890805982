import { request } from '~/lib/axios';
import { defineMutation } from '../helper';
import { reqParamsToBody } from '../utils';

type Params = {
  email: string;
  captcha?: string;
};

export const sendEmailActivateCode = defineMutation(async (params: Params) => {
  const data = await request.post<string>('/v1/user/activateCode/send', reqParamsToBody(params));

  return data;
});
