import { defaultShouldDehydrateMutation } from '@tanstack/react-query';
import { userSettingReq } from '~/api/userSetting';
import { request } from '~/lib/axios';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ControlInfo, ControlKind, GeneralPage, GeneralPageProps } from './generalConfigPage';

export function Summary() {
  const [options, setOptions] = useState<GeneralPageProps['config']>([]);
  const { t } = useTranslation();
  useEffect(() => {
    userSettingReq.list({ group: 'note' }).then((res) => {
      const { definitions, settings } = res;
      const defaultValueHash: Record<string, string> = {};
      settings.forEach((item) => {
        defaultValueHash[`${item.groupCode} ${item.code}`] = item.currentValue;
      });

      const temp: typeof options = [];
      for (const definition of definitions) {
        let { title, type, code, groupCode, hint, defaultValue } = definition;
        const name = `${groupCode} ${code}`;
        if (defaultValue == 'auto') defaultValue = '';
        if (type == ControlKind.switch) {
          temp.push({
            title: title,
            hint: hint || '',
            name,
            control: {
              type: type,
              data: {
                defaultValue: defaultValueHash[name] || defaultValue,
              },
            },
          });
        }

        if (type == ControlKind.select) {
          const options: any = JSON.parse(definition.options);
          if (defaultValue == 'auto') defaultValue = '';
          options.options.forEach((item: any) => {
            item.children = item.children.map((i: any) => {
              return {
                label: i.name,
                value: i.value,
              };
            });
          });
          temp.push({
            title,
            hint: hint || '',
            name,
            control: {
              type: ControlKind.select2,
              data: {
                defaultValue: defaultValueHash[name] || defaultValue,
                options: options.options,
              },
            },
          });
        }
      }
      setOptions(temp);
    });
  }, []);

  if (!options.length) return null;

  return (
    <GeneralPage
      title={t('user.info.summary')}
      config={options.map((item) => {
        const word = item.name.split(' ').join('.');
        const k = `user.info.${word}.`;
        return {
          ...item,
          //@ts-ignore
          title: t(k + 'title') == k + 'title' ? item.hint : t(k + 'title'),
          //@ts-ignore
          hint: t(k + 'hint') == k + 'hint' ? item.hint : t(k + 'hint'),
        };
      })}
      onChange={(key, value) => {
        const [group, code] = key.split(' ');
        userSettingReq.save({
          group,
          code,
          value,
        });
      }}
    />
  );
}
