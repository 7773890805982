import { SVGProps } from 'react';

export default function (props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="4"
      height="7"
      viewBox="0 0 4 7"
      stroke="#0BF7E9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_940_4763)">
        <path d="M0.5 0.5L3.24 3.24L0.5 5.98" strokeLinecap="round" strokeLinejoin="round" />
      </g>
      <defs>
        <clipPath id="clip0_940_4763">
          <rect width="3.74" height="6.48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
