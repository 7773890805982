import { cancelReq } from '~/api/notes/cancel';
import { useGetTicketInfo } from '~/api/notes/getByTicket';
import { NoteListCardDataRefetchList } from '~/components/note';
import { ProcessingBlock } from '~/components/ProcessingBlock';
import type { FC } from 'react';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

export const Generating: FC<{
  ticket: string;
  onGenerated?: (noteId: string) => void;
  onCancel?: () => void;
  onError?: () => void;
  className?: string;
}> = ({ ticket, onGenerated, onCancel, onError, className }) => {
  const { t } = useTranslation();

  const { data, cancelHandleRef } = useGetTicketInfo(ticket, 0);
  const isCancelRef = useRef<boolean>(false);
  useEffect(() => {
    if (isCancelRef.current) return;
    if (data?.error) {
      onError?.();
    }
  }, [data?.error, data?.state, onError]);

  useEffect(() => {
    const wakeLock = navigator.wakeLock;
    if (!wakeLock) return;
    const lockPromise = wakeLock.request('screen');
    return () => {
      lockPromise.then((lock) => lock.release());
    };
  }, []);

  useEffect(() => {
    if (isCancelRef.current) return;
    if (['transcribed', 'summarized', 'saved'].includes(data?.state || '') && data?.id) {
      onGenerated?.(data.id);
      NoteListCardDataRefetchList(data.id);
    }
  }, [data?.state, onGenerated]);

  return (
    <>
      <ProcessingBlock
        label={t('note.processing.generating') + ' ...'}
        className={className}
        onCancel={() => {
          onCancel?.();
          cancelReq({
            ticket,
          }).then((res) => {
            //@ts-ignore
            if (res?.error) generalErrorHandle(res, t);
          });
          cancelHandleRef.current();
          console.log('cancel uploading');
          isCancelRef.current = true;
        }}
      />
    </>
  );
};
