import { request } from '~/lib/axios';

export type NotionItem = {
  id: string;
  type: string;
  title: string;
  parentId: string;
  parentType: string;
};

export function notionList() {
  return request.post<NotionItem[]>('/v2/integration/notion/list');
}
