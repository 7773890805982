import { clsxm } from '@hidock/utils';
import { LogData, productLogReq } from '~/api/product';
import { useMyNavigate } from '~/hooks/useNavigate';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { ClassValue } from 'tailwind-variants';
import { MyModal } from '../general/myModal';

enum Product {
  hinotes = 'HiNotes',
  hidock = 'HiDock',
}

const ProductSelect: FC<{
  className?: ClassValue;
  onChange?: (value: Product) => void;
  value: Product;
}> = (props) => {
  const { className, onChange, value } = props;
  const config = [
    {
      product: Product.hinotes,
    },
    {
      product: Product.hidock,
    },
  ];
  const arr: ReactNode[] = [];
  for (let i = 0; i < config.length; i++) {
    const { product } = config[i];
    const productEl = (
      <p
        onClick={() => onChange?.(product)}
        className=" cursor-pointer"
        key={product}
        style={{ color: product == value ? '#00A2AF' : '' }}
      >
        {product}
      </p>
    );
    const el = <div key={i} className=" mx-[24px] h-[1em] w-[1px] translate-y-[-5px] bg-[#686868]" />;
    arr.push(productEl, el);
  }
  if (arr.length) arr.pop();

  return <div className={clsxm(' flex justify-center text-[28px] font-[700] leading-[18.72px]', className)}>{arr}</div>;
};

const Log: FC<{
  product: Product;
}> = (props) => {
  const [list, setList] = useState<LogData[]>([]);
  const [pageIdx, setPageIdx] = useState(0);
  const preProductRef = useRef<Product>(props.product);
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (preProductRef.current != props.product) {
      preProductRef.current = props.product;
      setList([]);
    }

    if (props.product == Product.hidock) {
      productLogReq.hidock({ pageIndex: pageIdx }).then((res) => {
        setList((list) => {
          return [...list, ...res];
        });
      });
    }
    if (props.product == Product.hinotes) {
      productLogReq.hinotes({ pageIndex: pageIdx }).then((res) => {
        setList((list) => {
          return [...list, ...res];
        });
      });
    }
  }, [pageIdx, props.product]);

  const Logs = list.map((logData, idx) => {
    const { version, publishDate, changeLogs } = logData;
    return (
      <div className=" w-full" key={version}>
        {!!idx && <div className=" mb-[15px] h-[1px] w-full bg-[#D8D8D8]" />}
        <div className=" flex w-full items-center">
          <div className=" mr-[12px] rounded-[6px] bg-[#00A2AF] px-[15px] py-[6px] text-[16px] font-[700] leading-[18.72px] text-[#FFFFFF]">
            v{version}
          </div>
          <div className=" text-[16px] font-[500] text-[#ACACAC]">{new Date(publishDate).toLocaleString()}</div>
        </div>
        <div className=" my-[16px] whitespace-pre-wrap text-[16px] leading-[20px] text-[#3D3D3D]">{changeLogs}</div>
      </div>
    );
  });

  return (
    <div className="flex justify-center">
      <div
        className=" mt-[45px] box-content h-[450px] w-[528px] overflow-auto pr-[20px]"
        ref={containerRef}
        onScroll={(e) => {
          const target = containerRef.current!;
          if (target.scrollTop + target.clientHeight + 100 > target.scrollHeight) {
            setPageIdx((list.length / 20) | 0);
          }
        }}
      >
        {Logs}
      </div>
    </div>
  );
};

function ProductLog() {
  const navigate = useMyNavigate();
  const [select, setSelect] = useState<Product>(Product.hinotes);

  return (
    <MyModal className="h-[650px] w-[680px] rounded-[20px] bg-white" onClose={() => navigate(-1)}>
      <ProductSelect className=" mt-[72px]" value={select} onChange={setSelect} />
      <Log product={select} />
    </MyModal>
  );
}

export default ProductLog;
