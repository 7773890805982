/* eslint-disable */
import { sleep } from '~/utils/sleep';
import { useSessionStorageState } from 'ahooks';
import { useEffect, useRef, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { fileUpdateTestCall } from '../notes/Sidebar';
import { onToastError, queryDom } from './utils';

function downloadFile(filename: string, content: string) {
  const blob = new Blob([content], {
    type: 'text/plain;charset=utf-8',
  });
  // 根据 blob生成 url链接
  const objectURL = URL.createObjectURL(blob);

  // 创建一个 a 标签Tag
  const aTag = document.createElement('a');
  // 设置文件的下载地址
  aTag.href = objectURL;
  // 设置保存后的文件名称
  aTag.download = filename;
  // 给 a 标签添加点击事件
  aTag.click();
  // 释放一个之前已经存在的、通过调用 URL.createObjectURL() 创建的 URL 对象。
  // 当你结束使用某个 URL 对象之后，应该通过调用这个方法来让浏览器知道不用在内存中继续保留对这个文件的引用了。
  URL.revokeObjectURL(objectURL);
}

function OTATest() {
  let versions = [
    ['5113311416107012096', 393474, '6.1.2'],
    ['5113278973836660736', 393473, '6.1.1'],
    ['5103710172241596416', 393230, '6.0.14'],
    ['5100770065532456960', 393229, '6.0.13'],
    ['5096482629193175040', 393228, '6.0.12'],
    ['5096413654987968512', 327937, '5.1.1'],
    ['5093507028891078656', 393227, '6.0.11'],
    ['5090545869409882112', 393226, '6.0.10'],
    ['5086298888424525824', 393225, '6.0.9'],
    ['5083440705364430848', 393224, '6.0.8'],
    ['5080473181026914304', 393223, '6.0.7'],
    ['5076100040913719296', 393222, '6.0.6'],
    ['5073214754613497856', 393221, '6.0.5'],
    ['5070348445106180096', 393220, '6.0.4'],
    ['5063227436842487808', 393219, '6.0.3'],
    ['5063226854236884992', 327936, '5.1.0'],
    ['5049401154761527296', 393218, '6.0.2'],
    ['5032681522679910400', 327733, '5.0.53'],
    ['4994445263885176832', 327732, '5.0.52'],
    ['4994338976212062208', 327731, '5.0.51'],
    ['4932638743992602626', 327727, '5.0.47'],
    ['4932638743992602625', 327726, '5.0.46'],
    ['4893666797850595328', 327723, '5.0.43'],
    ['4890791073808519168', 327722, '5.0.42'],
    ['4883461734364483584', 327721, '5.0.41'],
    ['4880644471353643008', 327720, '5.0.40'],
    ['4878662789247406080', 327719, '5.0.39'],
    ['4878381888655462400', 327718, '5.0.38'],
    ['4860298936394584064', 327717, '5.0.37'],
    ['4857141030216142848', 327716, '5.0.36'],
    ['4851080429094178816', 327715, '5.0.35'],
  ];

  let [num, setNum] = useState(0);
  let [open, setOpen] = useState(false);
  let [localKey, setLocalKey] = useState('');
  return (
    <div className=" h-[100px] border p-[10px]">
      <p>OTA测试</p>
      版本降级版本
      <select
        className=" mr-[10px] border"
        onChange={(e) => {
          setNum(+e.currentTarget.value);
        }}
      >
        {versions.map((item, idx) => {
          return <option value={idx}>v{item[2]}</option>;
        })}
      </select>
      {!open && (
        <button
          className=" border"
          onClick={async () => {
            let version = versions[num];
            //@ts-ignore
            const dataKey = await window.my.testUpgrade({
              versionNumber: version[1],
              id: version[0],
              version: version[2],
            });
            setOpen(true);
            setLocalKey(dataKey);
          }}
        >
          开启
        </button>
      )}
      <button
        className=" border"
        onClick={() => {
          let data = JSON.parse(localStorage.getItem(localKey) || '[]') as any;
          const targetData = data.map((item: any) => {
            let obj: any = {};
            //@ts-ignore
            let version = versions.find((i) => i[1] == item.versionNumber)?.[2] || item.versionNumber + '';
            obj.version = version;
            obj.startTime = item.startTime;
            let startStateTime = 0;
            //@ts-ignore
            item.stateTime.forEach((i) => {
              let time = new Date(i.time);
              if (i.state == 'start') {
                obj.startTime = i.time;
                startStateTime = time.getTime();
              }
              obj[i.state + `StateTime`] = ((time.getTime() - startStateTime) / 1000) | 0;
              // obj[i.state + `StateTime`]  = i.time
            });
            return obj;
          });
          let keyset = new Set();
          //@ts-ignore
          targetData.forEach((item) => {
            Object.keys(item).forEach((i) => keyset.add(i));
          });
          let keys = [...keyset];
          let cvs = keys.join(',') + '\n';
          //@ts-ignore
          cvs += targetData
            .map((item) => {
              //@ts-ignore
              return keys.map((key) => item[key] + '').join(',');
            })
            .join('\n');
          downloadFile('data.csv', cvs);
        }}
      >
        下载数据
      </button>
    </div>
  );
}

function AudioUploadTest() {
  const [files, setFiles] = useState<File[]>([]);
  const [maxNum, setMaxNum] = useState(0);
  let [cnt, setCnt] = useState(0);
  const dataRef = useRef<
    {
      filename: string;
      startDate: string;
      endDate: string;
      errorInfo: string;
    }[]
  >([]);
  const [open, setOpen] = useState(false);
  return (
    <div className=" border p-[10px]">
      <p>文件上传测试</p>
      <div>
        <span>选择多个文件，将文件进行轮询使用</span>
        <br />
        <input
          type="file"
          multiple
          onChange={(e) => {
            e.target.files && setFiles([...e.target.files]);
          }}
        />
      </div>
      {!open && (
        <div>
          <span>输入运行次数</span>
          <input className="border" value={maxNum} onChange={(e) => setMaxNum(+e.target.value || 0)} />
        </div>
      )}
      {open && <span>当前运行次数{cnt}</span>}
      <div>
        {!!files.length && !open && (
          <button
            className=" border px-[10px]"
            onClick={async () => {
              if (open) return;
              setOpen(true);
              const arr = dataRef.current;
              let cnt = 0;
              while (cnt < maxNum) {
                console.log('运行自动上传');
                let startDate = new Date();
                let file = files[cnt % files.length];
                fileUpdateTestCall([file]);
                let noteClosBtn = document.querySelector('#note-close-btn');
                let notSpeakerSaveBtn = document.querySelector('#not-speaker-note-save');
                let errorInfo = document.querySelector('#my-toast-error-text');
                while (!noteClosBtn && !errorInfo && !notSpeakerSaveBtn) {
                  await sleep(2000);
                  noteClosBtn = document.querySelector('#note-close-btn');
                  errorInfo = document.querySelector('#my-toast-error-text');
                  notSpeakerSaveBtn = document.querySelector('#not-speaker-note-save');
                }
                arr.push({
                  filename: file.name,
                  startDate: startDate.toLocaleString(),
                  endDate: new Date().toLocaleString(),
                  errorInfo: Array.from(document.querySelectorAll('#my-toast-error-text'))
                    .map((i) => i.innerHTML)
                    .join('|'),
                });
                while (notSpeakerSaveBtn || noteClosBtn || document.querySelectorAll('#my-toast-error-close')?.length) {
                  //@ts-ignore
                  noteClosBtn ? noteClosBtn?.click?.() : notSpeakerSaveBtn?.click();
                  //@ts-ignore
                  Array.from(document.querySelectorAll('#my-toast-error-close')).forEach((i) => i?.click?.());
                  await sleep(2000);
                  notSpeakerSaveBtn = document.querySelector('#not-speaker-note-save');
                  noteClosBtn = document.querySelector('#note-close-btn');
                }
                localStorage.setItem('audioUpdateTest', JSON.stringify(arr));
                cnt++;
                setCnt(cnt);
              }
            }}
          >
            开启
          </button>
        )}
        {open && (
          <button
            className=" border px-[10px]"
            onClick={() => {
              let arr = dataRef.current.map((item) => {
                const { startDate, endDate, errorInfo, filename } = item;
                const start = new Date(startDate);
                const end = new Date(endDate);
                let costTime = ((end.getTime() - start.getTime()) / 1000) | 0;
                return {
                  filename,
                  startDate,
                  endDate,
                  errorInfo,
                  costTime,
                };
              });
              let keyset = new Set();
              /* @ts-nocheck */
              arr.forEach((item) => {
                Object.keys(item).forEach((i) => keyset.add(i));
              });
              let keys = [...keyset];
              let cvs = keys.join(',') + '\n';
              cvs += arr
                .map((item) => {
                  //@ts-ignore
                  return keys.map((key) => (item[key] + '').replace(',', ' ')).join(',');
                })
                .join('\n');
              downloadFile('audioUpload.csv', cvs);
            }}
          >
            下载数据
          </button>
        )}
      </div>
    </div>
  );
}

function DeviceConnectionStabilityTest() {
  const [maxNum, setMaxNum] = useState(0);
  const [testNum, setTestNum] = useSessionStorageState<number>('deviceConnectionTest', {
    defaultValue: -1,
  });
  const [open, setOpen] = useState(false);
  //@ts-ignore
  const dataRef = useRef<
    {
      hasFile: string;
      time: string | undefined;
      startTime: string;
      curentTime: string;
      errorInfo: string;
      syncSuccess?: string;
    }[]
  >(JSON.parse(localStorage.getItem('deviceConnectionTest') || '[]') as any);

  useEffect(() => {
    (async function () {
      if (testNum == -1) return;
      setOpen(true);
      if (testNum == 0) {
        setTestNum(-1);
        dowloadRes();
        return;
      }
      await sleep(5000);
      if (testNum && testNum > 0) {
        //@ts-ignore
        my.showScript(true);
        connectionTest();
      }
    })();
  }, []);

  async function connectionTest() {
    const connectStateInfo = document.querySelector<HTMLElement>('p#connect-state-info');
    if (connectStateInfo?.innerText == 'Connected') {
      connectStateInfo.click();
      let startTime = new Date();
      await sleep(2000);
      const showFileTabBar = document.querySelector<HTMLElement>('#show-file-tab-bar');
      const configurations = showFileTabBar?.querySelector<HTMLElement>('#configurations');
      const filenameEls = document.querySelectorAll<HTMLElement>('#device-file-name');
      const filenames = Array.from(filenameEls)
        .map((i) => i.innerText)
        .join('|');
      configurations?.click();
      await sleep(1000);
      const describeEls = document.querySelectorAll<HTMLElement>('#device-config-describe');
      const endFixed = '(at page loaded)';
      let resetTime = Array.from(document.querySelectorAll<HTMLElement>('#device-config-button')).find(
        (i) => i.innerText == 'Set with PC Time'
      );
      let timeEl = Array.from(describeEls).find((i) => {
        return i.innerText.slice(-endFixed.length) == endFixed;
      });

      for (let i = 0; i < 10 && !timeEl; i++) {
        await sleep(1000);
        timeEl = Array.from(describeEls).find((i) => {
          return i.innerText.slice(-endFixed.length) == endFixed;
        });
      }

      const preTime = timeEl?.innerText;
      resetTime?.click();
      let errorInfoEl = document.querySelectorAll('#my-toast-error-text');
      let reloadCnt = 100;
      while (
        timeEl &&
        /*通过文字检查是否还处于加载中 */ resetTime?.innerText != ' Sync time' &&
        preTime == timeEl?.innerText &&
        !errorInfoEl.length &&
        --reloadCnt
      ) {
        await sleep(100);
        errorInfoEl = document.querySelectorAll('#my-toast-error-text');
      }
      let errorInfos = Array.from(errorInfoEl).map((i) => i.innerHTML);
      if (!reloadCnt) errorInfos.push('等待时间同步超时');
      //@ts-ignore
      const syncTime = new Date(timeEl?.innerText.slice(0, -endFixed.length));
      let syncSuccess = '';
      if (syncTime.toString() != 'Invalid Date' && reloadCnt)
        syncSuccess = new Date().getTime() - syncTime.getTime() < 60 * 1000 ? 'YES' : 'NO';
      dataRef.current.push({
        startTime: startTime.toLocaleString(),
        hasFile: filenames.trim() ? 'YES' : 'NO',
        time: timeEl?.innerText.slice(0, -endFixed.length),
        curentTime: new Date().toLocaleString(),
        errorInfo: errorInfos.join('|'),
        syncSuccess,
      });
      localStorage.setItem('deviceConnectionTest', JSON.stringify(dataRef.current));
      await sleep(2000);
      document.querySelector<HTMLElement>('#show-file-close')?.click();
      setTestNum((n) => (n ? n - 1 : 0));
      setTimeout(() => location.reload(), 1000);
    } else {
      dataRef.current.push({
        startTime: '',
        hasFile: 'NO',
        time: '',
        curentTime: new Date().toLocaleString(),
        errorInfo: `connect fail,state:${connectStateInfo?.innerText}`,
      });
      localStorage.setItem('deviceConnectionTest', JSON.stringify(dataRef.current));
      await sleep(2000);
      document.querySelector<HTMLElement>('#show-file-close')?.click();
      setTestNum((n) => (n ? n - 1 : 0));
      setTimeout(() => location.reload(), 1000);
    }
  }

  function dowloadRes() {
    const arr = dataRef.current;
    let keyset = new Set();
    arr.forEach((item) => {
      Object.keys(item).forEach((i) => keyset.add(i));
    });
    let keys = [...keyset];
    let cvs = keys.join(',') + '\n';
    cvs += arr
      .map((item) => {
        //@ts-ignore
        return keys.map((key) => (item[key] + '').replace(',', ' ')).join(',');
      })
      .join('\n');
    downloadFile('deviceConnectionTest.csv', cvs);
  }

  return (
    <div className=" border p-[10px]">
      <p>HiDock与web端连接稳定性测试</p>
      {!open && (
        <div>
          <span>输入运行次数</span>
          <input className="border" value={maxNum} onChange={(e) => setMaxNum(+e.target.value || 0)} />
        </div>
      )}
      <div>
        {!open && (
          <button
            className=" border px-[10px]"
            onClick={() => {
              setTestNum(maxNum);
              localStorage.setItem('deviceConnectionTest', '[]');
              location.reload();
            }}
          >
            开启
          </button>
        )}
        {
          <button className=" border px-[10px]" onClick={dowloadRes}>
            下载数据
          </button>
        }
      </div>
    </div>
  );
}

function MobileRecordUploadTest() {
  const [testNum, setTestNum] = useSessionStorageState<number>('recordUploadTest', {
    defaultValue: -1,
  });
  const dataRef = useRef<
    {
      startRecordTime: string;
      stopRecordTime: string;
      errorInfo: string;
      transfromRecordResult: string;
      noteId?: string;
      params?: string;
    }[]
  >(JSON.parse(localStorage.getItem('recordUploadTest') || '[]') as any);
  const [search, setSearch] = useSearchParams();
  useEffect(() => {
    const v = search.get('recordUploadTest');
    if (v && !isNaN(+v)) {
      search.delete('recordUploadTest');
      localStorage.setItem('recordUploadTest', '[]');
      setSearch(search);
      setTestNum(+v);
      setTimeout(() => location.reload());
    }
    if (v == 'download') {
      dowloadRes();
    }
  }, [location.pathname, search.get('recordUploadTest')]);
  /*
     * 1. 点击录音 #record-guide
     * 2. 等待十分钟
     * 3. 停止 #stop-record
     * 4. 查看判断内容 #not_speaker_summary_container #coffee-load-picture #noteError
     * 5. 删除 #normal-note-delete #note-delte-confirm
    错误处理
    */
  const testRecordStartRef = useRef(false);
  async function testRecordUpload() {
    if (testRecordStartRef.current) return;
    testRecordStartRef.current = true;
    let errorInfos: string[] = [];
    const removeOnToast = onToastError((infos) => {
      errorInfos.push(...infos);
    });

    console.log('testRecordUpload', 'start');
    const recordButtonEl = await queryDom('#record-guide', {
      tryTime: 30,
    });
    recordButtonEl?.click();
    console.log('testRecordUpload', 'record-btn-click');
    const startRecordTime = new Date();
    await sleep(1000 * 60 * 10);

    const stopRecordEl = await queryDom('#stop-record', {
      tryTime: 30,
    });
    stopRecordEl?.click();
    const stopRecordTime = new Date();
    console.log('testRecordUpload', 'stop-btn-click');

    //4. 查看判断内容 #not_speaker_summary_container #coffee-load-picture #noteError
    const waitMaxTime = Date.now() + 10 * 60 * 1000;

    let noteErrorEl = document.querySelector('#noteError');
    let noteContainerEl = document.querySelector('#not_speaker_summary_container');
    while (Date.now() < waitMaxTime && !noteErrorEl && !noteContainerEl) {
      await sleep(1000);
      noteErrorEl = document.querySelector('#noteError');
      noteContainerEl = document.querySelector('#not_speaker_summary_container');
    }
    let noteState = '';
    if (Date.now() > waitMaxTime) noteState = 'timeout';
    else if (noteErrorEl) noteState = 'failed';
    else noteState = 'successful';
    // if (noteErrorEl || !noteContainerEl) {
    //     console.log('testRecordUpload', 'summary failed')
    // }

    // if (noteContainerEl) {
    //     console.log('testRecordUpload', 'summary success')
    // }
    //5. 删除 #normal-note-delete #note-delte-confirm #not-speaker-note-save
    const [noteId, params] = location.href.split('/').pop()?.split('?') || [];
    if (noteState != 'timeout') {
      const saveBtn = await queryDom('#not-speaker-note-save', {
        tryTime: 10,
      });
      saveBtn?.click();
      console.log('recordUploadTest', 'save');
    } else {
      const cancelBtn = await queryDom('#note-processing-cancel', {
        tryTime: 10,
      });
      cancelBtn?.click();
      console.log('recordUploadTest', 'cancel');
    }

    dataRef.current.push({
      startRecordTime: startRecordTime.toLocaleString(),
      stopRecordTime: stopRecordTime.toLocaleString(),
      errorInfo: errorInfos.join('|'),
      transfromRecordResult: noteState,
      noteId: `'${noteId}'`,
      params,
    });
    localStorage.setItem('recordUploadTest', JSON.stringify(dataRef.current));
    testRecordStartRef.current = false;
    removeOnToast();
    setTestNum((num) => (num || 0) - 1);
  }
  function dowloadRes() {
    const arr = dataRef.current;
    let keyset = new Set();
    arr.forEach((item) => {
      Object.keys(item).forEach((i) => keyset.add(i));
    });
    let keys = [...keyset];
    let cvs = keys.join(',') + '\n';
    cvs += arr
      .map((item) => {
        //@ts-ignore
        return keys.map((key) => (item[key] + '').replace(',', '，')).join(',');
      })
      .join('\n');
    console.log(cvs);
    downloadFile('recordUploadTest.csv', cvs);
  }
  useEffect(() => {
    if (testNum && testNum > 0) {
      testRecordUpload();
    } else if (testNum == 0) {
      dowloadRes();
      setTestNum(-1);
    }
  }, [testNum]);

  return (
    <button
      style={{
        display: testNum == -1 || search.get('recordUploadTest') ? 'none' : 'block',
      }}
      className=" fixed right-0 top-0 z-[9999999999999] bg-orange-600"
      onClick={dowloadRes}
    >
      下载录音测试结果
    </button>
  );
}

function Page() {
  const [btnShow, setBtnShow] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    //@ts-ignore
    window.my.showScript = setBtnShow;

    return () => {
      //@ts-ignore
      window.my.showScript = null;
    };
  }, []);

  return (
    <>
      <button
        className=" fixed right-[10px] top-[10px] z-[99999] rounded-[4px] bg-lime-500 px-[20px] py-[5px]"
        onClick={() => setShow(!show)}
        style={{ display: btnShow ? '' : 'none' }}
      >
        {show ? '关闭' : '开启'}
      </button>
      <MobileRecordUploadTest />
      <div
        className=" fixed left-0 top-0 z-[99998] flex h-screen w-screen bg-white"
        style={{ display: show ? '' : 'none' }}
      >
        <OTATest />
        <AudioUploadTest />
        <DeviceConnectionStabilityTest />
      </div>
    </>
  );
}

export default function () {
  return <>{<Page />}</>;
}
