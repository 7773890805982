import { queryDom } from '../../pages/testPage/utils';
import { onDeviceUpgradeState } from './DeviceConnection';
import { deviceInfoAtom } from './deviceState';
import { deviceUpgrade, jensen } from './utils';

function sleep(awit) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, awit);
  });
}
let startTime = 0;
const storeObjs = [];
let errLogs = [];
let stateTime = [];
const localkey = `testUpgrade${new Date().toLocaleString()}`;
let oldVersionInfo = {
  versionNumber: 327715,
  id: '4851080429094178816',
  version: '5.0.36',
};
function autoClickUpgrade() {
  startTime = Date.now();
  const removeOn = onDeviceUpgradeState((state) => {
    stateTime.push({
      state,
      time: new Date().toLocaleString(),
    });
    if (state == 'failed' || state == 'download-fail') {
      errLogs.push(...Logger.messages.slice(-1000));
    }
  });
  async function exec() {
    stateTime.push({
      state: 'start',
      time: new Date().toLocaleString(),
    });
    const learn_more = await queryDom('#learn_more');
    learn_more.click();
    await sleep(1000);
    const my_Proceed = await queryDom('#my_Proceed');
    my_Proceed.click();
    await sleep(1000);
    let upgradeEnd = null;
    while (!upgradeEnd) {
      upgradeEnd = await queryDom('#upgradeEnd');
      await sleep(2000);
    }
    upgradeEnd.click();
    return true;
  }

  return new Promise(async (resolve) => {
    exec().then(() => {
      resolve('success');
      removeOn();
    });
  });
}

function downGrade(onState) {
  return new Promise((resolve) => {
    startTime = Date.now();
    stateTime.push({
      state: 'start',
      time: new Date().toLocaleString(),
    });
    deviceUpgrade(
      oldVersionInfo,
      (state) => {
        stateTime.push({
          state,
          time: new Date().toLocaleString(),
        });
        if (state == 'failed' || state == 'download-fail') {
          errLogs.push(...Logger.messages.slice(-1000));
          resolve(state);
        } else if (state == 'complete') resolve('success');
      },
      (error) => {
        errLogs.push(error);
      }
    );
  });
}
let lock = false;
async function testUpgrade(oldversion) {
  Object.assign(oldVersionInfo, oldversion);
  setTimeout(async () => {
    try {
      console.log('测试开启·');
      while (1) {
        if (lock) {
          await sleep(1000);
          console.log('出现未运行完成后，就进行下一次运行的情况');
          continue;
        }
        lock = true;
        let info = await jensen.getDeviceInfo(5);
        while (!info) {
          info = await jensen.getDeviceInfo(5);
          console.log('reacquire deviceInfo', info);
          sleep(500);
        }
        const versionNumber = info.versionNumber;
        let isLatest = versionNumber != oldVersionInfo.versionNumber;
        const state = isLatest ? await downGrade() : await autoClickUpgrade();
        info = await jensen.getDeviceInfo(5);
        while (!info) {
          info = await jensen.getDeviceInfo(5);
          console.log('reacquire deviceInfo', info);
          sleep(500);
        }
        storeObjs.push({
          versionNumber: info.versionNumber,
          startTime: new Date(startTime).toLocaleString(),
          costTime: (Date.now() - startTime) / 1000,
          isSuccess: state,
          stateTime,
          errLogs,
        });
        stateTime = [];
        errLogs = [];
        localStorage.setItem(localkey, JSON.stringify(storeObjs));
        lock = false;
      }
    } catch (err) {
      console.error(err);
    }
  });
  return localkey;
}
// const finallyExec = addTryCatch(exec);
Object.assign(window.my, {
  testUpgrade,
  jensen,
  playRate: 1,
});

export default {};
