import { useLogoutMutation } from '~/api/users/logout';
import { UserType } from '~/api/users/types';
import { useIsLargeScreen } from '~/hooks/useIsLargeScreen';
import { useMyNavigate } from '~/hooks/useNavigate';
import { useUserInfo } from '~/store/user';
import { parseDoubleBracketSyntax2 } from '~/utils/utils';
import { useSetAtom } from 'jotai';
import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useIsUserModalVisible } from '.';
import { Avatar as Avator2, UserModal } from '../modal/UserModal';
import { SiderBarSelectType, userInfoTabAtom } from '../userInfo';
import Svg from './Svg';

const CardBtnText = styled.span`
  background-image: linear-gradient(180deg, #fdeddb 0%, #d69c68 100%); /* 从左到右的红色至黄色线性渐变 */
  -webkit-background-clip: text; /* Safari/Chrome特定样式，将渐变应用于文字区域 */
  -webkit-text-fill-color: transparent; /* Safari/Chrome特定样式，透明化文字内容 */
`;

function Role() {
  const { data: userInfo } = useUserInfo();
  const { t } = useTranslation();
  const navigate = useNavigate();
  function buyBtn(text: string, options: { onClik?: () => void; prefix?: ReactNode } = {}) {
    const { onClik, prefix } = options;
    return (
      <button
        className="   mb-[16px] mt-[0px] flex h-[58px] w-[310px] items-center justify-center rounded-[12px] bg-black text-[20px] font-[600] leading-[58px]"
        onClick={() => onClik?.()}
        style={{
          background: 'linear-gradient(180deg, #878584 0%, #000000 37%)',
        }}
      >
        {prefix}
        <CardBtnText>{text}</CardBtnText>
      </button>
    );
  }

  if (userInfo?.type == UserType.pro) {
    const words = parseDoubleBracketSyntax2(t('user.info.pro.describe'));
    return (
      <>
        <div className=" mt-[0px] flex items-center">
          <Avator2 className=" ml-[10px] mr-[14px] w-[60px]" />
          <div>
            <span className=" relative text-[24px] font-bold leading-[18.72px]">{userInfo?.name}</span>
            <p className=" relative text-[17px] font-[700] leading-[18.72px] text-[#00A2AF]">
              {t('user.identity.pro.name')}
              {userInfo?.kickstarter ? (
                <img src="/userRole/kickstarter.png" className="ml-[4px] w-[16px]" style={{ display: 'inline' }} />
              ) : (
                ''
              )}
            </p>
          </div>
        </div>
        <div className=" mb-[30px] ml-[36px] mt-[12px] flex items-center">
          <img src="/User/time.png" className=" mr-[8px] inline h-[20px] w-[17px]" />
          {userInfo && (
            <p className=" mt-[2px] text-[18px] font-[600] leading-[18.72px]">
              {words.map((i) => {
                if (i.type == 'BracketContent' && i.content == 'time')
                  return <span className=" text-[#00A2AF]">{userInfo.timeLimit - userInfo.totalNoteDuration}</span>;
                return i.content;
              })}
            </p>
          )}
        </div>
        {buyBtn(t('user.plans.special-offer'), {
          onClik() {
            navigate('/notes/payment');
          },
          prefix: <Svg.gift className="mr-[10px]" />,
        })}
      </>
    );
  }

  if (userInfo?.type == UserType.Membership || userInfo?.type == UserType.kickstarter) {
    return (
      <>
        <div className=" mb-[10px] mt-[0px] flex items-center">
          <Avator2 className=" ml-[10px] mr-[14px] w-[60px]" />
          <div>
            <span className=" relative text-[24px] font-bold leading-[18.72px]">{userInfo?.name}</span>
            <p className=" relative text-[17px] font-[700] leading-[18.72px] text-[#00A2AF]">
              {t('user.identity.Membership.name')}
              {userInfo?.kickstarter ? (
                <img src="/userRole/kickstarter.png" className="ml-[4px] w-[16px]" style={{ display: 'inline' }} />
              ) : (
                ''
              )}
            </p>
          </div>
        </div>
        {buyBtn(t('user.plans.special-offer'), {
          onClik() {
            navigate('/notes/payment');
          },
          prefix: <Svg.gift className="mr-[10px]" />,
        })}
      </>
    );
  }

  if (userInfo?.type == UserType.free || userInfo?.type == UserType.Trail) {
    const words = parseDoubleBracketSyntax2(t('user.info.basic.describe'));
    const describe = words.map((i) => {
      if (i.type == 'BracketContent' && i.content == 'totalCount')
        return <span className=" text-[#00A2AF]">{userInfo.totalNoteCount}</span>;
      if (i.type == 'BracketContent' && i.content == 'countLimit')
        return <span className=" text-[#00A2AF]">{userInfo.countLimit}</span>;
      return i.content;
    });
    return (
      <>
        <div className=" mt-[0px] flex items-center">
          <Avator2 className=" ml-[10px] mr-[14px] w-[60px]" />
          <div>
            <span className=" relative text-[24px] font-bold leading-[18.72px]">{userInfo?.name}</span>
            <p className=" relative text-[17px] font-[700] leading-[18.72px] text-[#00A2AF]">
              {t('user.identity.basic.name')}
            </p>
          </div>
        </div>
        <div className=" mb-[30px] ml-[36px] mt-[12px] flex items-center">
          <img src="/User/time.png" className=" mr-[8px] inline h-[20px] w-[17px]" />
          {userInfo && <p className=" mt-[2px] text-[18px] font-[600] leading-[18.72px]">{describe}</p>}
        </div>
        {buyBtn(t('misc.Upgrade'), {
          onClik() {
            window.open('https://www.hidock.com/products/hidock-h1-audio-dock');
          },
        })}
      </>
    );
  }
  return null;
}

export function HoverModal() {
  const { data: userInfo } = useUserInfo();
  const { mutate: logout } = useLogoutMutation();
  const [visibleUserInfo, setVisibleUserInfo] = useIsUserModalVisible();
  const { t } = useTranslation();
  const setUserInfoTab = useSetAtom(userInfoTabAtom);

  const tabBarConfig: { label: string; value: SiderBarSelectType }[] = [
    {
      label: t('user.info.language'),
      value: 'language',
    },
    {
      label: t('user.info.template.library.title'),
      value: 'tempalteLibrary',
    },
    {
      label: t('user.info.my-hidock'),
      value: 'device',
    },
    {
      label: t('user.info.plans'),
      value: 'plans',
    },
  ];

  const tabBar = (
    <div className=" border-y border-[#D8D8D8] px-[21px] pb-[10px] pt-[15px] text-[20px] font-bold leading-[18.72px] text-[#686868]">
      {tabBarConfig.map((item, key) => {
        return (
          <p
            className=" cursor-pointer rounded-[8px] py-[15px] pl-[24px] hover:bg-[#00A2AF] hover:text-white"
            key={key}
            onClick={() => {
              setVisibleUserInfo(true);
              setUserInfoTab(item.value);
            }}
          >
            <span>{item.label}</span>
          </p>
        );
      })}
    </div>
  );

  return (
    <div className=" absolute  right-0 top-full z-[3] w-[352px]">
      <div className=" mt-[10px]" />
      <div className=" w-full rounded-[12px] bg-white pt-[30px] shadow-[0px_4px_30px_0px_rgba(0,0,0,0.3)]">
        <div className="pl-[21px]">
          <Role />
        </div>
        {tabBar}
        <div className=" flex w-full px-[20px] pb-[30px] pt-[15px]">
          <button
            className=" h-[47px] flex-1 rounded-[8px] bg-[#E8EAEA] py-[14px] pl-[23px] text-left text-[20px] font-bold leading-[18.72px] text-[#686868]"
            onClick={() => logout({})}
          >
            {t('user.logout')}
          </button>
        </div>
      </div>
    </div>
  );
}
