import pdfmark from 'pdfmake/build/pdfmake';

pdfmark.fonts = {
  Roboto: {
    normal: new URL('./roboto-font/Roboto-Regular.ttf', import.meta.url).href,
    bold: new URL('./roboto-font/Roboto-Medium.ttf', import.meta.url).href,
    italics: new URL('./roboto-font/Roboto-Italic.ttf', import.meta.url).href,
    bolditalics: new URL('./roboto-font/Roboto-MediumItalic.ttf', import.meta.url).href,
    // normal:getUrl('/public/font/roboto-font/Roboto-Regular.ttf') ,
    // bold:getUrl('/public/font/roboto-font/Roboto-Medium.ttf'),
    // italics:getUrl('/public/font/roboto-font/Roboto-Italic.ttf'),
    // bolditalics:getUrl('/public/font/roboto-font/Roboto-MediumItalic.ttf'),
    // normal:Object.values(import.meta.glob('/public/font/roboto-font/Roboto-Regular.ttf',{eager:true}))[0].default,
    // bold:Object.values(import.meta.glob('/public/font/roboto-font/Roboto-Medium.ttf',{eager:true}))[0].default,
    // italics:Object.values(import.meta.glob('/public/font/roboto-font/Roboto-Italic.ttf',{eager:true}))[0].default,
    // bolditalics:Object.values(import.meta.glob('/public/font/roboto-font/Roboto-MediumItalic.ttf',{eager:true}))[0].default
  },
  NotoSansSC: {
    normal: new URL('./notoSansSc-font/notoSansSc-Regular.ttf', import.meta.url).href,
    bold: new URL('./notoSansSc-font/notoSansSc-Medium.ttf', import.meta.url).href,
    italics: new URL('./notoSansSc-font/notoSansSc-SemiBold.ttf', import.meta.url).href,
    bolditalics: new URL('./notoSansSc-font/notoSansSc-ExtraBold.ttf', import.meta.url).href,
    // normal:getUrl('/public/font/notoSansSc-font/notoSansSc-Regular.ttf'),
    // bold:getUrl('/public/font/notoSansSc-font/notoSansSc-Medium.ttf'),
    // italics:getUrl('/public/font/notoSansSc-font/notoSansSc-SemiBold.ttf'),
    // bolditalics:getUrl('/public/font/notoSansSc-font/notoSansSc-ExtraBold.ttf'),
    // normal:Object.values(import.meta.glob('/public/font/notoSansSc-font/notoSansSc-Regular.ttf',{eager:true}))[0].default,
    // bold:Object.values(import.meta.glob('/public/font/notoSansSc-font/notoSansSc-Medium.ttf',{eager:true}))[0].default,
    // italics:Object.values(import.meta.glob('/public/font/notoSansSc-font/notoSansSc-SemiBold.ttf',{eager:true}))[0].default,
    // bolditalics:Object.values(import.meta.glob('/public/font/notoSansSc-font/notoSansSc-ExtraBold.ttf',{eager:true}))[0].default
  },
  NoteEmoji: {
    normal: new URL('./noteEmoji-font/NotoEmoji-Regular.ttf', import.meta.url).href,
    bold: new URL('./noteEmoji-font/NotoEmoji-Medium.ttf', import.meta.url).href,
    italics: new URL('./noteEmoji-font/NotoEmoji-SemiBold.ttf', import.meta.url).href,
    bolditalics: new URL('./noteEmoji-font/NotoEmoji-Bold.ttf', import.meta.url).href,
    // normal:getUrl('/public/font/noteEmoji-font/NotoEmoji-Regular.ttf'),
    // bold:getUrl('/public/font/noteEmoji-font/NotoEmoji-Medium.ttf'),
    // italics:getUrl('/public/font/noteEmoji-font/NotoEmoji-SemiBold.ttf'),
    // bolditalics:getUrl('/public/font/noteEmoji-font/NotoEmoji-Bold.ttf'),
    // normal:Object.values(import.meta.glob('/public/font/noteEmoji-font/NotoEmoji-Regular.ttf',{eager:true}))[0].default,
    // bold:Object.values(import.meta.glob('/public/font/noteEmoji-font/NotoEmoji-Medium.ttf',{eager:true}))[0].default,
    // italics:Object.values(import.meta.glob('/public/font/noteEmoji-font/NotoEmoji-SemiBold.ttf',{eager:true}))[0].default,
    // bolditalics:Object.values(import.meta.glob('/public/font/noteEmoji-font/NotoEmoji-Bold.ttf',{eager:true}))[0].default
  },
};

export default pdfmark;
