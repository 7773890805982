import { request } from '~/lib/axios';

function check() {
  return request.post<boolean>('/v1/user/trial/check');
}

function claim() {
  return request.post<null>('/v1/user/trial/claim');
}

export const proExperience = {
  check,
  claim,
};
