export enum NoteUserGuideTargetId {
  avatar = 'avatar-guide',
  device = 'device-guide',
  record = 'record-guide',
  upload = 'upload-guide',
}

export type Direction = 'left' | 'top' | 'right' | 'bottom';
export type ConfigItem = {
  name: 'avator' | 'device' | 'upload';
  id: NoteUserGuideTargetId;
  direction: Direction;
  shape?: 'square' | 'circle';
};

export const NotesNewUserGuideConig: ConfigItem[] = [
  {
    name: 'avator',
    id: NoteUserGuideTargetId.avatar,
    direction: 'left',
  },
  {
    name: 'device',
    id: NoteUserGuideTargetId.device,
    direction: 'right',
    shape: 'square',
  },
  // {
  //     title: "Quick Record ",
  //     describe: 'Turn Any Conversation.  ',
  //     id: NoteUserGuideTargetId.record,
  //     direction:'right'
  // },
  {
    name: 'upload',
    id: NoteUserGuideTargetId.upload,
    direction: 'right',
    shape: 'square',
  },
];
