import { request } from '~/lib/axios';
import { useEffect, useState } from 'react';

type Params = {
  id: string;
  deviceSn: string;
};

export function firmwareGet(
  params: Params,
  onMessage?: (slice: ArrayBuffer | 0) => void,
  onProgress?: (downLen: number) => void
) {
  const { id } = params;
  // 通过XMLHttpRequest下载文件，并得到arraybuffer以便于在线处理
  var xhr = new XMLHttpRequest();
  xhr.open('GET', `/v2/device/firmware/get?id=${id}`);
  xhr.setRequestHeader('Content-Type', 'application/octet-stream');
  xhr.responseType = 'arraybuffer';
  function checkOffline() {
    onMessage?.(0);
    xhr.abort();
    window.removeEventListener('offline', checkOffline);
  }
  if (!window.navigator.onLine) onMessage?.(0);
  window.addEventListener('offline', checkOffline);
  xhr.onload = function (e) {
    if (this.status != 200) return onMessage?.(0);
    // 将this.response组装成消息包，发送到设备端
    onMessage?.(this.response);
    window.removeEventListener('offline', checkOffline);
  };

  xhr.onreadystatechange = function () {
    // console.log('down ota request State',xhr.readyState,this.status);
    if (xhr.readyState == 4 && this.status == 0) {
      onMessage?.(0);
      window.removeEventListener('offline', checkOffline);
    }
  };
  xhr.addEventListener(
    'progress',
    function (event) {
      onProgress?.(event.loaded);
    },
    false
  );
  xhr.send();
}
