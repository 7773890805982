import { useIsLargeScreen } from '~/hooks/useIsLargeScreen';
import { useEntryInfo } from '~/store/entry';
import { sleep } from '~/utils/sleep';
import { isPC } from '~/utils/utils';
import { t } from 'i18next';
import { CSSProperties, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Direction, NotesNewUserGuideConig } from './config';

export const guideVersion = 'guide-20231226';

export function UserGuide() {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [curId, setCurId] = useState(0);
  const [targetDom, setTargetDom] = useState<Element | null>(null);
  let { pathname } = useLocation();
  const isLgRaw = useIsLargeScreen();
  const [entryInfo, setEntryInfo] = useEntryInfo();
  useEffect(() => {
    if (/^\/notes$/.test(pathname) && isLgRaw && !entryInfo.visited) {
      setOpen(true);
    } else {
      setCurId(0);
      setOpen(false);
    }
  }, [pathname, entryInfo.visited]);
  useEffect(() => {
    const exec = async () => {
      let dom: null | Element = null;
      while (1) {
        const id = NotesNewUserGuideConig[curId].id;
        dom = document.querySelector(`#${id}`);
        if (dom) break;
        await sleep(100);
      }
      setTargetDom(dom);
    };
    exec();
  }, [curId]);

  const rect = targetDom?.getBoundingClientRect();

  if (!rect) return null;

  const circle = {
    x: rect.x + rect.width / 2,
    y: rect.y + rect.height / 2,
    radius: Math.sqrt((rect.width / 2) ** 2 + (rect.height / 2) ** 2),
  };
  const onExit = () => {
    setOpen(false);
    setCurId(0);
    setEntryInfo({
      ...entryInfo,
      visited: true,
    });
  };
  const item = NotesNewUserGuideConig[curId];
  function infoEl() {
    const cardCss: CSSProperties =
      item.direction == 'left'
        ? {
            left: circle.x - circle.radius - 12,
            top: circle.y,
            transform: 'translate(-100%,-50%)',
          }
        : {
            left: circle.x + circle.radius,
            top: circle.y,
            transform: 'translate(12px,-50%)',
          };

    const arrowCss: CSSProperties =
      item.direction == 'left'
        ? {
            borderTop: '10px solid transparent',
            borderLeft: `10px solid transparent`,
            borderBottom: '10px solid transparent',
            borderRight: `10px solid white`,
            left: '100%',
          }
        : {
            borderTop: '10px solid transparent',
            borderLeft: `10px solid transparent`,
            borderBottom: '10px solid transparent',
            borderRight: `10px solid white`,

            right: '100%',
          };

    return (
      <div className=" absolute box-border w-[400px] rounded-[8px] bg-white p-[17px_16px]" style={cardCss}>
        <div
          className="absolute right-[8px] top-[8px] flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full hover:bg-[#E8EAEA]"
          onClick={onExit}
        >
          <img src="/share/close.png" className="w-[15.5px]" />
        </div>
        <h3 className="text-[18px] font-[600] text-[#00A2AF]">{t(`user.guide.${item.name}.title`)}</h3>
        <p className="mt-[12px] text-[14px] font-[400] leading-[21px] text-[#000000]">
          {t(`user.guide.${item.name}.describe`)}
        </p>
        <div className="mt-[9px] flex items-end justify-between">
          <div className="flex w-[32px] justify-between">
            {curId + 1}/{NotesNewUserGuideConig.length}
          </div>

          <span>
            <button
              className="gb-[#00A2AF] mr-[12px] h-[30px] w-[84px] rounded-[4px] border border-[#D9D9D9] text-[16px] text-[#686868]"
              onClick={() => setCurId(curId - 1)}
              style={{
                display: curId > 0 ? '' : 'none',
              }}
            >
              {t('user.guide.go-back')}
            </button>
            <button
              className="gb-[#00A2AF] h-[30px] w-[84px] rounded-[4px] bg-[#00A2AF] text-[16px] text-white"
              onClick={() => {
                if (curId + 1 == NotesNewUserGuideConig.length) {
                  onExit();
                } else {
                  setCurId(curId + 1);
                }
              }}
            >
              {curId + 1 != NotesNewUserGuideConig.length ? t('user.guide.next') : t('user.guide.Done')}
            </button>
          </span>
        </div>
        <div
          className="absolute  top-1/2 -translate-y-1/2 transform  border-[10px] border-indigo-500"
          style={arrowCss}
        ></div>
      </div>
    );
  }

  let renderItem = (
    <div
      className="absolute bottom-0 left-0 right-0 top-0 z-50"
      style={{
        display: open ? '' : 'none',
        background: `radial-gradient(circle at ${circle.x}px ${
          circle.y
        }px, transparent ${circle.radius}px, rgba(0,0,0,0.4) ${circle.radius + 1}px)`,
      }}
    >
      {infoEl()}
    </div>
  );
  if (item.shape == 'square') {
    renderItem = (
      <div
        className="absolute bottom-0 left-0 right-0 top-0 z-50"
        style={{
          display: open ? '' : 'none',
        }}
      >
        {/* 左 */}
        <div
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: rect.x + 'px',
            height: '100vh',
            background: 'rgba(0,0,0,0.4)',
          }}
        />
        {/* 上 */}
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: rect.x,
            width: `calc(100vw - ${rect.x}px)`,
            height: rect.y,
            background: 'rgba(0,0,0,0.4)',
          }}
        />
        {/* 右 */}
        <div
          style={{
            position: 'absolute',
            top: rect.y,
            left: rect.right,
            width: `calc(100vw - ${rect.right}px)`,
            height: `calc(100vh - ${rect.y}px)`,
            background: 'rgba(0,0,0,0.4)',
          }}
        />
        {/* 下 */}
        <div
          style={{
            position: 'absolute',
            top: rect.bottom,
            left: rect.left,
            width: rect.width,
            height: `calc(100vh - ${rect.bottom}px)`,
            background: 'rgba(0,0,0,0.4)',
          }}
        />
        {infoEl()}
      </div>
    );
  }

  return renderItem;
}
