export type fileCarryReqParamsType = 'head' | 'body' | 'tail' | 'whole';

type Params = {
  data: ArrayBuffer;
  fileName: string;
  signature: string;
  deviceSn: string;
  type: fileCarryReqParamsType;
  createTime: number;
  language?: string;
  template: string;
};

export function fileCarryReq(params: Params) {
  const { data, fileName, signature, deviceSn, type, createTime, language, template } = params;
  return new Promise<XMLHttpRequest>((resolve) => {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', '/v2/device/file/carry');
    xhr.setRequestHeader('accept', 'application/json');
    xhr.onload = function () {
      resolve(this);
    };
    let dlayer = new Uint8Array(data);
    const fd = new FormData();
    fd.append('file', new Blob([dlayer], {}));
    fd.append('fileName', fileName);
    fd.append('signature', signature);
    fd.append('deviceSn', deviceSn);
    fd.append('createTime', createTime + '');
    fd.append('type', type);
    fd.append('template', template);
    fd.append('tzOffset', new Date().getTimezoneOffset() + '');
    if (language) fd.append('language', language);
    xhr.send(fd);
  });
}
