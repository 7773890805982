import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Stripe, StripeError } from '@stripe/stripe-js';
import { PaymentReq } from '~/api/payment/payment';
import { paymentState, paymentStateReq } from '~/api/payment/paymentState';
import { myMessage } from '~/components/MyToast/MyToast';
import { sleep } from '~/utils/sleep';
import { parseDoubleBracketSyntax } from '~/utils/utils';
import { Checkbox } from 'antd';
import { useAtom, useSetAtom } from 'jotai';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { styled } from 'styled-components';
import { getTotal } from './PaymentPage';
import { clientSecretInfoAtom, paymentSelectAtom, showPaySuccessAtom } from './paymentStore';
import i18n from '~/i18n';

const InputContainer = styled.div`
  &:focus-within {
    box-shadow:
      0 0 0 3px hsla(211, 100%, 50%, 25%),
      0 0 0 1px hsla(211, 100%, 50%, 50%);
  }
`;

export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [agreePolicy, setAgreePolicy] = useState(false);
  const [paySelect, setPaySelect] = useAtom(paymentSelectAtom);
  const setShowSuccess = useSetAtom(showPaySuccessAtom);
  const [isNameEmpty, setIsNameEmpty] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!paySelect.sku) return;

    if (!agreePolicy) {
      myMessage.error(t('payment.agree.policy'), 3000);
      return;
    }

    if (!paySelect.name) {
      setIsNameEmpty(true);
      return;
    }

    if (!stripe || !elements || isLoading) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    let submitError: StripeError | undefined = undefined;
    while (1) {
      try {
        const { error } = await elements.submit();
        submitError = error;
        break;
      } catch (err) {
        await sleep(2000);
        console.log('err', err);
      }
    }

    if (submitError) {
      // handleError(submitError);
      // debugger
      console.log(submitError);
      return;
    }

    setIsLoading(true);
    const clientSecretInfo = await PaymentReq({
      sku: paySelect.sku.code,
      quantity: paySelect.num,
      name: paySelect.name,
      totalPrice: getTotal(paySelect.sku.unitPrice, paySelect.num),
      promotionId: (paySelect.promotion?.id || '') + '',
      giftQuota: paySelect.giftQuota,
    });

    const paymentReturn = await stripe.confirmPayment({
      clientSecret: clientSecretInfo.clientSecret,
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: 'https://' + location.hostname + '/notes/payment',
      },
      redirect: 'if_required',
    });
    console.log('paymentReturn', paymentReturn);
    if (paymentReturn.error) {
      myMessage.error(paymentReturn.error.message || '');
      setIsLoading(false);
      return;
    }

    let status: null | paymentState = null;
    let message = '';
    while (status == 'created' || status == null) {
      const res = await paymentStateReq({
        id: clientSecretInfo.id,
      });
      status = res.result;
      message = res.code || '';
      await sleep(2000);
    }
    if (status == 'success') {
      setPaySelect((state) => {
        return {
          ...state,
          refrechNum: state.refrechNum + 1,
        };
      });
      setShowSuccess(true);
      myMessage.info(parseDoubleBracketSyntax(t('payment.success'), { reason: message }), 5000);
    }

    if (status == 'failure') myMessage.info(t('payment.failed'), 5000);

    setIsLoading(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit} className="flex h-full w-full flex-col">
      <label
        className="box-border block text-[0.93rem] font-[500] text-[#000000]"
        style={{
          fontFamily:
            '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
        }}
      >
        {t('payment.name')}
      </label>
      <div className="mb-[4px]">
        <InputContainer className="mt-[8px] h-auto w-full flex-shrink-0 rounded-[8px] bg-[#ffffff]">
          <input
            className="p-[0_12px] rounded-[8px] h-[40px] w-full bg-transparent font-[400] text-[#30313d] outline-none placeholder:text-[rgb(108,109,121)]"
            placeholder={t('payment.name.placeholder')}
            onBlur={(e) => {
              setPaySelect((paySelect) => {
                return {
                  ...paySelect,
                  name: e.target.value.trim(),
                };
              });
            }}
            style={{
              border: '1px solid #D8D8D8',
              fontFamily:
                '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
            }}
          />
        </InputContainer>
        <span
          style={{
            opacity: isNameEmpty ? 1 : 0,
            color: '#df1b41',
            fontSize: '0.93rem',
            paddingTop: '4px',
            userSelect: 'none',
          }}
        >
          {t('payment.name.empty')}
        </span>
      </div>

      <PaymentElement
        id="payment-element"
        options={{
          layout: {
            type: 'tabs',
            defaultCollapsed: false,
          },
        }}
      />

      <div className=" my-[20px] flex items-center">
        <input type="checkbox" onChange={(e) => setAgreePolicy(e.target.checked)} className=" cursor-pointer" />
        <p className="ml-[8px] text-[12px]">
          {t('payment.policy.1')}
          <Link to={'/term-of-service'} className="cursor-pointer] text-[#00A2AF] underline">
            {t('payment.policy.2')}
          </Link>
          {t('payment.policy.3')}
          <Link to={'/privacy-policy'} className="cursor-pointer] text-[#00A2AF] underline">
            {t('payment.policy.4')}
          </Link>
        </p>
      </div>

      <button
        className="h-[48px] w-full flex-shrink-0 rounded-[8px] bg-[#EAF5F4] text-[16px] font-bold text-[#000000] cursor-pointer hover:bg-[#00A2AF]  hover:text-white"
        disabled={isLoading || !stripe || !elements}
        id="submit"
      >
        <span id="button-text">
          {isLoading ? (
            <div className="spinner" id="spinner">
              {t('payment.paying')}
            </div>
          ) : (
            t('payment.pay')
          )}
        </span>
      </button>
    </form>
  );
}
