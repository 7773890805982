import { clsxm } from '@hidock/utils';
import * as Dialog from '@radix-ui/react-dialog';
import { useAPIQuery } from '~/api/helper';
import { info2 as info2Req, Note2, queryInfo2 } from '~/api/note2s/info2';
import { useIsLargeScreen } from '~/hooks/useIsLargeScreen';
import { useMyNavigate } from '~/hooks/useNavigate';
import { getIsUserLogin } from '~/store/user';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { setLoginModalVisible } from '.';
import { Loading } from '../Loading';
import { NoteResultCard } from '../note';
import NoteResult2 from '../note-result2/note-result2';

export const NoteModal = () => {
  const isLg = useIsLargeScreen();
  const params = useParams();

  const { data: info2 } = useAPIQuery(queryInfo2(params.noteId || ''), {
    enabled: typeof params.noteId == 'string',
  });
  const loading = params.noteId != info2?.id;
  const [query] = useSearchParams();
  const ticket = query.get('ticket') || '';
  const location = useLocation();
  const mode = location.state?.mode || 'detail';
  const navigate = useMyNavigate();

  const userLoginGuard = async (callback: Function, mode?: string) => {
    if (!(await getIsUserLogin())) {
      setLoginModalVisible(true, mode);
      return false;
    }
    callback();
  };

  const MobileNote = (
    <>
      {mode === 'detail' ? (
        <NoteResultCard
          className="flex flex-col lg:my-0"
          allowRating={false}
          action="copy"
          noteId={params.noteId || ''}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onCloseClick={() => {
            // userLoginGuard(() => navigate('/notes', { replace: true }))
            navigate(-1);
          }}
          onDeleteClick={() => {
            return userLoginGuard(() => {});
          }}
          onDeleted={() => {
            navigate(-1);
          }}
        />
      ) : null}
      {mode === 'result' ? (
        <NoteResultCard
          className="flex flex-col lg:my-0"
          ticket={ticket}
          noteId={params.noteId || ''}
          onDeleteClick={() => {
            return userLoginGuard(() => navigate(-1));
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
          onDeleted={() => {
            userLoginGuard(() => navigate(-1));
          }}
        />
      ) : null}
    </>
  );
  const PcNote = (
    <NoteResult2
      className="my-10 flex flex-col lg:my-0"
      allowRating={false}
      action="copy"
      noteId={params.noteId || ''}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onCloseClick={() => {
        // userLoginGuard(() => navigate('/notes', { replace: true }))
        navigate(-1);
      }}
      onDeleteClick={() => {
        return userLoginGuard(() => {});
      }}
      onDeleted={() => {
        navigate(-1);
      }}
    />
  );

  function showContent() {
    if (loading) {
      return (
        <div className=" flex h-screen w-screen items-center justify-center py-10 backdrop-blur-md">
          <Loading />
        </div>
      );
    } else if (info2) {
      if (isLg) {
        return PcNote;
      } else {
        return MobileNote;
      }
    } else {
      return null;
    }
  }

  return (
    <Dialog.Root modal={false} open>
      <Dialog.Portal>
        <Dialog.Content
          className={clsxm('fixed inset-0 z-[50] min-w-[50vw] overflow-hidden', isLg ? '' : 'backdrop-blur-md')}
          style={{ minHeight: window.innerHeight, zIndex: 10 }}
        >
          {showContent()}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
