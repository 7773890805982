import { IconCredit, IconEmail, IconLevel } from '@hidock/ui';
import { clsxm } from '@hidock/utils';
import { useDeleteUserModal } from '~/api/users/delete';
import { useLogoutMutation } from '~/api/users/logout';
import { Identify, useIdentityQuotas } from '~/api/users/quotas';
import { setAppLoading } from '~/App';
import { useIsLargeScreen } from '~/hooks/useIsLargeScreen';
import { useScreenSize } from '~/hooks/useScreenSize';
import { useUserInfo } from '~/store/user';
import { CopySvg, Duigou } from '~/svg/svg';
import { isMobile, parseDoubleBracketSyntax, parseDoubleBracketSyntax2 } from '~/utils/utils';
import { useAtom, useSetAtom } from 'jotai';
import { ReactNode, useEffect, useRef, useState, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useDeviceInfo } from '../deviceConnection/deviceState';
import { LanguageSelectMobile } from '../LanguageSelect/languageSelect';
import { myMessage } from '../MyToast/MyToast';
import { UserInfo } from '../userInfo';
import type { ModalProps } from './Base';
import { Modal } from './Base';

export const UserModal: FC<ModalProps> = (props = {}) => {
  const { t } = useTranslation();
  const lg = useIsLargeScreen();
  const { mutate: logout } = useLogoutMutation();
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();
  const userInfo = useUserInfo();

  const mobile = (
    <div
      className={`relative lg:min-w-[${lg ? 520 : 320}px] max-h-[100vh]  ${
        lg ? 'w-[320px]' : 'w-[90vw]'
      } z-50 pt-[57px]`}
      style={{
        marginLeft: !lg ? 0 : 40,
      }}
    >
      {!lg && (
        <div
          className=" absolute right-2 top-2 z-20 flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-[50%] hover:bg-[#E8EAEA]"
          onClick={() => props.onOpenChange?.(false)}
        >
          <img src="/connectDevice/wrong.png" className="h-[16px] w-[16px]" />
        </div>
      )}
      <Avatar className=" m-[0_auto]" />
      <div className=" mt-[16px] text-center text-[24px]">
        {parseDoubleBracketSyntax(t('misc.welcome'), {
          name: userInfo.data?.name || '',
        })}
      </div>
      <Profile />
      {/* <Device /> */}
      <section className=" mt-[14px] flex flex-col items-center justify-center">
        <button
          className={`text-solid h-[42px] w-[240px] whitespace-nowrap rounded-[40px] border border-[#ACACAC] bg-white px-9 py-1.5 text-sm font-bold`}
          style={{ marginTop: lg ? 30 : 0 }}
          onClick={() => {
            props.onOpenChange?.(false);
            navigate('/notes/changepassword', { replace: true });
          }}
        >
          {t('misc.changePassword.btn')}
        </button>
        <button
          className="bg-clean text-solid mt-[14px] h-[42px] w-[240px] whitespace-nowrap rounded-[40px] px-9 py-1.5 text-sm font-bold"
          onClick={() => {
            logout({});
            props.onOpenChange?.(false);
          }}
        >
          {t('user.logout')}
        </button>
        <button
          className="mt-[30px] text-[13px] text-[#686868] underline"
          onClick={() => {
            setModalVisible(true);
          }}
        >
          {t('user.delete')}
        </button>
      </section>
    </div>
  );

  if (lg) {
    return <UserInfo />;
  }

  return (
    <>
      {!modalVisible && <Modal.Root {...props}>{mobile}</Modal.Root>}
      {modalVisible && <UserDeleteModal onCancel={() => setModalVisible(false)} />}
    </>
  );
};

const card = {
  height: 620,
};

export const Avatar: FC<{ className?: string }> = ({ className }) => {
  const { data: userInfo } = useUserInfo();
  return (
    <section
      className={clsxm(
        'border-gray relative flex aspect-square w-[80px] items-center justify-center rounded-full ',
        !userInfo?.avatar && 'border-gray border-2 bg-white',
        className
      )}
    >
      {userInfo?.avatar ? (
        <img src={userInfo.avatar} className="rounded-full" />
      ) : (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="28" cy="27.9998" r="21" stroke="#283040" strokeWidth="4.66667" />
          <circle cx="28" cy="21" r="7" fill="#283040" />
          <path
            d="M28 30.3335C20.268 30.3335 14 36.6015 14 44.3335C19.0556 48.6113 31.7333 54.6002 42 44.3335C42 36.6015 35.732 30.3335 28 30.3335Z"
            fill="#283040"
          />
        </svg>
      )}
    </section>
  );
};

const LEVEL = {
  trial: 'Free',
  membership: 'Membership',
  ksmember: 'Kickstarter Membership',
} as any;

const Profile = () => {
  const userInfo = useUserInfo();
  const { t } = useTranslation();

  const typeToLevel = {
    trial: 'Basic',
    free: 'Basic',
    membership: 'Membership',
    kickstarter: 'Membership',
    pro: 'Pro Membership',
  };

  return (
    <>
      {/* <h3 className="mt-3 text-center text-[20px] font-extrabold text-black">
        {userInfo.data?.name || ''}
      </h3> */}
      <section className="mt-8 w-full max-w-full">
        <h3 className="ml-3 text-sm font-bold text-black">{t('user.profile')}</h3>
        <ul className="border-gray text-solid mt-2 space-y-2 rounded-xl border p-3 text-[13px]">
          <li className="flex items-center space-x-4">
            <div className="text-solid/70 flex min-w-[64px] items-center font-normal">
              <IconEmail className="mr-2 h-3 w-3" />
              {t('user.email')}
            </div>
            <div className=" w-[165px] break-words">{userInfo.data?.email}</div>
          </li>
          <li className="flex items-center space-x-4">
            <div className="text-solid/70 relative flex min-w-[64px] items-center font-normal">
              <IconLevel className="mr-2 h-3 w-3" />
              {t('misc.user-plan')}
            </div>
            <div className=" flex items-center">
              {
                //@ts-ignore
                typeToLevel[userInfo.data?.type || ''] || ''
              }
              {
                //@ts-ignore
                userInfo.data?.kickstarter && (
                  <img src="/userRole/kickstarter.png" className=" ml-[7px] aspect-[12/14] h-[14px] w-[12px]" />
                )
              }
            </div>
          </li>
          <li className="flex items-center space-x-4 whitespace-pre-wrap">
            <div className="text-solid/70 flex min-w-[64px] items-center font-normal">
              <IconCredit className="mr-2 h-3 w-3" />
              {t('user.quota')}
            </div>
            {userInfo.data?.type && (
              <div>
                {`${
                  ['trial', 'free'].includes(userInfo.data.type)
                    ? ''
                    : userInfo.data.timeLimit - userInfo.data.totalNoteDuration + ` ${t('misc.mins')}`
                } ${
                  userInfo.data.countLimit ? userInfo.data.countLimit - userInfo.data.totalNoteCount + 'notes' : ''
                } `}
              </div>
            )}
          </li>
        </ul>
      </section>
    </>
  );
};

const dataStyle = clsxm(`text-[9px] text-solid/70 lg:text-xs`);

const Device = () => {
  const { t } = useTranslation();
  const [quotas] = useIdentityQuotas();
  const userInfo = useUserInfo();
  const [deviceInfo] = useDeviceInfo();
  const storeKey = `deviceInfo${userInfo.data?.email}`;

  const localDeviceInfo = JSON.parse(localStorage.getItem(storeKey) || '{}') as typeof deviceInfo;
  useEffect(() => {
    if (deviceInfo.versionNumber != -1) localStorage.setItem(storeKey, JSON.stringify(deviceInfo));
  }, [deviceInfo.version, deviceInfo.sn, storeKey]);
  const renderDeviceInfo = deviceInfo.versionNumber == -1 ? localDeviceInfo : deviceInfo;

  function copyHandle() {
    window.navigator.clipboard
      .writeText(
        `
${t('wu.configurations.device-sn')}:${renderDeviceInfo.sn}
${t('wu.configurations.firmware-version')}:${renderDeviceInfo.version}`
      )
      .then((res) => {
        myMessage.success(t('note.info.summary.copied-successfully'), 2000);
      })
      .catch((err) => {
        myMessage.error(err, 2000);
      });
  }
  return (
    <section className="mt-4 w-full max-w-full">
      <h3 className="ml-3 text-sm font-bold text-black">{t('user.device')}</h3>
      <ul className="border-gray text-solid mt-2 space-y-3 rounded-xl border p-3 text-[13px]">
        <li className="flex items-center space-x-4">
          <div className="text-solid/70 flex w-[120px] min-w-[64px] items-center font-normal">
            {t('wu.configurations.device-sn')}
          </div>
          <div className="max-w-[190px] break-words lg:max-w-[380px]">{renderDeviceInfo.sn}</div>
        </li>
        <li className="flex items-center space-x-4">
          <div className="text-solid/70 flex w-[120px] min-w-[64px] items-center font-normal">
            {t('wu.configurations.firmware-version')}
          </div>
          <div>{renderDeviceInfo.version}</div>
          <div className=" flex-1">
            <CopySvg
              className=" float-right mr-[0px] cursor-pointer fill-[#686868] hover:fill-[#00A2AF]"
              onClick={copyHandle}
            />
          </div>
        </li>
      </ul>
    </section>
  );
};

const UserDeleteModal: FC<{ onCancel: () => void }> = ({ onCancel }) => {
  const { t } = useTranslation();

  const { mutate, isLoading } = useDeleteUserModal();

  return (
    <Modal.Root className="z-[1000] space-y-4 text-center" open={true}>
      <div className="p-[18px]" onClick={(e) => e.stopPropagation()}>
        <Modal.Title className={'text-xl'}>{'Are you sure?'}</Modal.Title>

        <div className="mt-9 flex justify-center space-x-6 whitespace-nowrap rounded-[42px] text-xs">
          <button
            className="bg-clean text-solid rounded-[inherit]  px-6 py-2"
            onClick={(e) => {
              e.stopPropagation();
              if (isLoading) return;
              onCancel?.();
            }}
          >
            {t('note.modal.delete.cancel')}
          </button>
          <button
            className="rounded-[inherit] bg-red-400 px-6 py-2 text-white"
            onClick={() => {
              if (isLoading) return;
              setAppLoading(true);
              mutate({});
            }}
          >
            {t('note.modal.delete.confirm')}
          </button>
        </div>
      </div>
    </Modal.Root>
  );
};
