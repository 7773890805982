export const IconUser = ({ className }: { className?: string }) => {
  return (
    <svg className={className} fill="none" viewBox="0 0 24 24">
      <path
        d="M5.85024 17.1002C6.70024 16.4502 7.65024 15.9376 8.70024 15.5622C9.75024 15.1869 10.8502 14.9996 12.0002 15.0002C13.1502 15.0002 14.2502 15.1879 15.3002 15.5632C16.3502 15.9386 17.3002 16.4509 18.1502 17.1002C18.7336 16.4169 19.1879 15.6419 19.5132 14.7752C19.8386 13.9086 20.0009 12.9836 20.0002 12.0002C20.0002 9.78358 19.2212 7.89591 17.6632 6.33724C16.1052 4.77858 14.2176 3.99958 12.0002 4.00024C9.78358 4.00024 7.89591 4.77924 6.33724 6.33724C4.77858 7.89524 3.99958 9.78291 4.00024 12.0002C4.00024 12.9836 4.16291 13.9086 4.48824 14.7752C4.81358 15.6419 5.26758 16.4169 5.85024 17.1002ZM12.0002 13.0002C11.0169 13.0002 10.1876 12.6629 9.51224 11.9882C8.83691 11.3136 8.49958 10.4842 8.50024 9.50024C8.50024 8.51691 8.83758 7.68758 9.51224 7.01224C10.1869 6.33691 11.0162 5.99958 12.0002 6.00024C12.9836 6.00024 13.8129 6.33758 14.4882 7.01224C15.1636 7.68691 15.5009 8.51624 15.5002 9.50024C15.5002 10.4836 15.1629 11.3129 14.4882 11.9882C13.8136 12.6636 12.9842 13.0009 12.0002 13.0002ZM12.0002 22.0002C10.6169 22.0002 9.31691 21.7376 8.10024 21.2122C6.88358 20.6869 5.82524 19.9746 4.92524 19.0752C4.02524 18.1752 3.31291 17.1169 2.78824 15.9002C2.26358 14.6836 2.00091 13.3836 2.00024 12.0002C2.00024 10.6169 2.26291 9.31691 2.78824 8.10024C3.31358 6.88358 4.02591 5.82524 4.92524 4.92524C5.82524 4.02524 6.88358 3.31291 8.10024 2.78824C9.31691 2.26358 10.6169 2.00091 12.0002 2.00024C13.3836 2.00024 14.6836 2.26291 15.9002 2.78824C17.1169 3.31358 18.1752 4.02591 19.0752 4.92524C19.9752 5.82524 20.6879 6.88358 21.2132 8.10024C21.7386 9.31691 22.0009 10.6169 22.0002 12.0002C22.0002 13.3836 21.7376 14.6836 21.2122 15.9002C20.6869 17.1169 19.9746 18.1752 19.0752 19.0752C18.1752 19.9752 17.1169 20.6879 15.9002 21.2132C14.6836 21.7386 13.3836 22.0009 12.0002 22.0002Z"
        fill="currentColor"
      />
    </svg>
  );
};
