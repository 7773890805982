import { Divider } from '~/components/Divider';
import { FloatActions } from '~/components/floatActions';
import { Hero } from '~/components/hero';
import { NoteList } from '~/components/note';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';

export const NotesMobile = () => {
  const { t } = useTranslation();

  return (
    <>
      {/* 给modal用的 */}
      <Outlet />
      <Hero className="mt-[88px]" description={false} size="sm" />
      <Divider className="my-8" />
      <h3 className="text-center text-[22px] font-bold text-black">{t('note.notes.all')}</h3>

      <NoteList className="mx-6 mt-4 space-y-4 pb-24" />

      <FloatActions />
    </>
  );
};
