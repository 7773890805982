import { SVGProps } from 'react';

function downArrow(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="none"
      version="1.1"
      width="14"
      height="8"
      viewBox="0 0 14 8"
      {...props}
    >
      <g>
        <path
          d="M0.292893,0.292893C0.683417,-0.0976311,1.31658,-0.0976311,1.70711,0.292893C1.70711,0.292893,7,5.58579,7,5.58579C7,5.58579,12.2929,0.292893,12.2929,0.292893C12.6834,-0.0976311,13.3166,-0.0976311,13.7071,0.292893C14.0976,0.683417,14.0976,1.31658,13.7071,1.70711C13.7071,1.70711,7.70711,7.70711,7.70711,7.70711C7.31658,8.09763,6.68342,8.09763,6.29289,7.70711C6.29289,7.70711,0.292893,1.70711,0.292893,1.70711C-0.0976311,1.31658,-0.0976311,0.683417,0.292893,0.292893C0.292893,0.292893,0.292893,0.292893,0.292893,0.292893Z"
          fillRule="evenodd"
          fill="#000000"
          fillOpacity="1"
        />
      </g>
    </svg>
  );
}

function downArrow2(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      fill="none"
      version="1.1"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      {...props}
    >
      <defs>
        <clipPath id="master_svg0_2364_01190">
          <rect x="22" y="0" width="22" height="22" rx="0" />
        </clipPath>
      </defs>
      <g transform="matrix(0,1,-1,0,22,-22)" clipPath="url(#master_svg0_2364_01190)">
        <g>
          <path
            d="M32.351820000000004,4.85198109375C32.7098,4.49400089375,33.2902,4.49400089375,33.648179999999996,4.85198109375C33.648179999999996,4.85198109375,38.2315,9.43531609375,38.2315,9.43531609375C38.5895,9.793296093750001,38.5895,10.373696093749999,38.2315,10.73167609375C37.873599999999996,11.089656093750001,37.29312,11.089656093750001,36.935159999999996,10.73167609375C36.935159999999996,10.73167609375,33,6.79652609375,33,6.79652609375C33,6.79652609375,29.06485,10.73167609375,29.06485,10.73167609375C28.70687,11.089656093750001,28.126466,11.089656093750001,27.768485,10.73167609375C27.4105048,10.373696093749999,27.4105048,9.793296093750001,27.768485,9.43531609375C27.768485,9.43531609375,32.351820000000004,4.85198109375,32.351820000000004,4.85198109375C32.351820000000004,4.85198109375,32.351820000000004,4.85198109375,32.351820000000004,4.85198109375ZM27.768485,15.85199609375C27.768485,15.85199609375,32.351820000000004,11.26864609375,32.351820000000004,11.26864609375C32.7098,10.910666093749999,33.2902,10.910666093749999,33.648179999999996,11.26864609375C33.648179999999996,11.26864609375,38.2315,15.85199609375,38.2315,15.85199609375C38.5895,16.20999609375,38.5895,16.790396093749997,38.2315,17.148296093749998C37.873599999999996,17.506296093750002,37.29312,17.506296093750002,36.935159999999996,17.148296093749998C36.935159999999996,17.148296093749998,33,13.21318609375,33,13.21318609375C33,13.21318609375,29.06485,17.148296093749998,29.06485,17.148296093749998C28.70687,17.506296093750002,28.126466,17.506296093750002,27.768485,17.148296093749998C27.4105048,16.790396093749997,27.4105048,16.20999609375,27.768485,15.85199609375C27.768485,15.85199609375,27.768485,15.85199609375,27.768485,15.85199609375Z"
            fillRule="evenodd"
            fill="#939393"
          />
        </g>
      </g>
    </svg>
  );
}

export default {
  downArrow,
  downArrow2,
  delete(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width="18.529414187363415"
        height="18.488371861622753"
        viewBox="0 0 18.529414187363415 18.488371861622753"
        {...props}
      >
        <g transform="matrix(-1,5.2146120310681e-8,5.2146120310681e-8,1,37.0588264465332,-9.662370112267836e-7)">
          <path
            d="M32.4265132232666,1.84884L37.0588132232666,1.84884L37.0588132232666,3.69767L35.205913223266606,3.69767L35.205913223266606,17.564C35.205913223266606,18.0745,34.791113223266606,18.4884,34.2794132232666,18.4884L21.3088232232666,18.4884C20.7971532232666,18.4884,20.382353223266602,18.0745,20.382353223266602,17.564L20.382353223266602,3.69767L18.5294132232666,3.69767L18.5294132232666,1.84884L23.1617632232666,1.84884L23.1617632232666,0L32.4265132232666,0L32.4265132232666,1.84884ZM25.0147032232666,6.47093L25.0147032232666,13.8663L26.8676532232666,13.8663L26.8676532232666,6.47093L25.0147032232666,6.47093ZM28.720613223266604,6.47093L28.720613223266604,13.8663L30.573513223266602,13.8663L30.573513223266602,6.47093L28.720613223266604,6.47093Z"
            fill="#3A3A3A"
          />
        </g>
      </svg>
    );
  },
};
