import { request } from '~/lib/axios';
import { reqParamsToBody } from '../utils';

export type paymentState = 'created' | 'success' | 'failure';

export function paymentStateReq(params: { id: string }) {
  return request.post<{
    result: paymentState;
    code: null | string;
  }>('/v1/payment/get', reqParamsToBody(params));
}
