import { clsxm } from '@hidock/utils';
import { Folder, folderReq } from '~/api/folder';
import { showConfirmAtom } from '~/components/deleteConfrim';
import { useFocus } from '~/hooks/useFocus';
import i18n from '~/i18n';
import { MoreThanSvg2 } from '~/svg/svg';
import { useHover, useMount, useRequest, useSetState } from 'ahooks';
import { useAtom, useSetAtom } from 'jotai';
import { Leaf } from 'lucide-react';
import { CSSProperties, FC, SVGProps, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { noteStore } from '../store';
import { InputModify } from './general';

const Svg: Record<string, FC<SVGProps<SVGSVGElement>>> = {
  AddSvg(props) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width="13.999999046325684"
        height="13.999999046325684"
        viewBox="0 0 13.999999046325684 13.999999046325684"
        {...props}
      >
        <g>
          <path d="M6,6L6,0L8,0L8,6L14,6L14,8L8,8L8,14L6,14L6,8L0,8L0,6L6,6Z" />
        </g>
      </svg>
    );
  },
  DownArrowSvg(props) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width="14.000001579282639"
        height="8.000002763744618"
        viewBox="0 0 14.000001579282639 8.000002763744618"
        {...props}
      >
        <g transform="matrix(1,-1.974103298607588e-7,1.974103298607588e-7,1,-5.455917367002703e-13,0)">
          <path
            d="M0.292893,0.29289576374461807C0.683417,-0.09762833625538195,1.31658,-0.09762833625538195,1.70711,0.29289576374461807C1.70711,0.29289576374461807,7,5.585792763744618,7,5.585792763744618C7,5.585792763744618,12.2929,0.29289576374461807,12.2929,0.29289576374461807C12.6834,-0.09762833625538195,13.3166,-0.09762833625538195,13.7071,0.29289576374461807C14.0976,0.6834197637446181,14.0976,1.3165827637446181,13.7071,1.707112763744618C13.7071,1.707112763744618,7.70711,7.707112763744618,7.70711,7.707112763744618C7.31658,8.097632763744619,6.68342,8.097632763744619,6.29289,7.707112763744618C6.29289,7.707112763744618,0.292893,1.707112763744618,0.292893,1.707112763744618C-0.0976311,1.3165827637446181,-0.0976311,0.6834197637446181,0.292893,0.29289576374461807C0.292893,0.29289576374461807,0.292893,0.29289576374461807,0.292893,0.29289576374461807Z"
            fillRule="evenodd"
          />
        </g>
      </svg>
    );
  },
  book(props) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        {...props}
      >
        <g>
          <path d="M6,0L6,20L2,20L2,16L0,16L0,14L2,14L2,11L0,11L0,9L2,9L2,6L0,6L0,4L2,4L2,0L6,0ZM18.005,0C19.107,0,20,0.898,20,1.99L20,18.01C20,19.109,19.107,20,18.005,20L8,20L8,0L18.005,0Z" />
        </g>
      </svg>
    );
  },
  deleteSvg2(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width="18.529414187363415"
        height="18.488371861622753"
        viewBox="0 0 18.529414187363415 18.488371861622753"
        {...props}
      >
        <g transform="matrix(1,5.2146120310681e-8,-5.2146120310681e-8,1,0,-5.027390840443712e-14)">
          <path d="M13.897100964096813,1.84884L18.529400964096812,1.84884L18.529400964096812,3.69767L16.676500964096814,3.69767L16.676500964096814,17.564C16.676500964096814,18.0745,16.261700964096814,18.4884,15.750000964096813,18.4884L2.779410964096813,18.4884C2.267740964096813,18.4884,1.852940964096813,18.0745,1.852940964096813,17.564L1.852940964096813,3.69767L9.64096813049764e-7,3.69767L9.64096813049764e-7,1.84884L4.632350964096813,1.84884L4.632350964096813,0L13.897100964096813,0L13.897100964096813,1.84884ZM6.485290964096813,6.47093L6.485290964096813,13.8663L8.338240964096814,13.8663L8.338240964096814,6.47093L6.485290964096813,6.47093ZM10.191200964096813,6.47093L10.191200964096813,13.8663L12.044100964096813,13.8663L12.044100964096813,6.47093L10.191200964096813,6.47093Z" />
        </g>
      </svg>
    );
  },
  editSvg(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width="18"
        height="17.97078514099121"
        viewBox="0 0 18 17.97078514099121"
        {...props}
      >
        <g>
          <path d="M6.243,15.9708L18,15.9708L18,17.9708L0,17.9708L0,13.7278L9.9,3.82779L14.142,8.07179L6.242,15.9708L6.243,15.9708ZM11.313,2.41479L13.435,0.292786C13.8255,-0.0975955,14.4585,-0.0975955,14.849,0.292786L17.678,3.12179C18.0684,3.51229,18.0684,4.14529,17.678,4.53579L15.556,6.65679L11.313,2.41479L11.313,2.41479Z" />
        </g>
      </svg>
    );
  },
};

function TabBarItem(props: {
  data: Folder.FolderInfo;
  pos: number;
  isSelect?: boolean;
  event: {
    onChange?: () => void;
    onRename?: () => void;
    onDelete?: () => void;
  };
  style?: CSSProperties;
  className?: string;
  icon?: null | string;
}) {
  const { data, isSelect, event, pos, style = {} } = props;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isHover = useHover(containerRef);
  const [show, setShow] = useState(false);
  const clickTarget = useFocus((state) => {
    setShow(state == 'focus');
  });
  const { t } = useTranslation();
  const setShowConfirm = useSetAtom(showConfirmAtom);

  let rect: DOMRect | null = null;
  if (show && containerRef.current) {
    rect = containerRef.current.getBoundingClientRect();
  }

  const IconSvg = Svg[props.icon || ''] || null;

  return (
    <div
      className={clsxm(
        ' relative flex h-[50px] cursor-pointer items-center justify-center whitespace-pre rounded-t-[14px] bg-white px-[40px] text-[16px] font-[700] shadow-[4px_0px_4px_0_rgba(0,0,0,0.1)]',
        props.className
      )}
      key={data.id}
      style={{
        marginLeft: pos ? -25 : 0,
        color: isSelect ? 'white' : '#686868',
        background: isSelect ? '#00A2AF' : '',
        zIndex: 99 - pos,
        ...style,
      }}
      ref={containerRef}
      onClick={() => {
        event.onChange?.();
      }}
    >
      {IconSvg && <IconSvg className=" mr-[10px] " style={{ fill: isSelect ? 'white' : '#686868' }} />}
      {data.name}
      <span
        className="absolute right-[5px] top-[8px] p-[8px] "
        onClick={(e) => clickTarget()}
        style={{ display: data.id == '' ? 'none' : '' }}
      >
        <MoreThanSvg2
          className="cursor-pointer"
          style={{
            fill: isSelect ? 'white' : '#939393',
            visibility: !isHover ? 'hidden' : 'visible',
          }}
        />
      </span>
      {show && rect && (
        <div
          className="fixed z-[999] rounded-[12px] bg-white p-[7px] shadow-[0px_0px_4px_rgba(0,0,0,0.1)] "
          style={{
            top: rect.bottom - 10,
            left: rect.right - 20,
          }}
        >
          <div
            className=" item-center flex min-w-[166px] rounded-[8px] fill-[#686868] px-[10px] py-[11px] text-[#686868] hover:bg-[#DEDEDE] hover:fill-[#3A3A3A] hover:text-[#3A3A3A] "
            onClick={(e) => {
              e.stopPropagation();
              event.onRename?.();
            }}
          >
            <Svg.editSvg className="mr-[15px] mt-[3px]" /> {t('user.note.folder.rename')}
          </div>
          <div className=" my-[4px] h-[1px] w-[166px] bg-[#DDDDE1]" />
          <div
            className=" item-center flex min-w-[166px] rounded-[8px] fill-[#686868] px-[10px] py-[11px] text-[#686868] hover:bg-[#DEDEDE] hover:fill-[#3A3A3A] hover:text-[#3A3A3A]"
            onClick={(e) => {
              e.stopPropagation();
              setShowConfirm({
                message: t('user.note.folder.deleteConfirm.message'),
                isShow: true,
                confirm: () => {
                  event.onDelete?.();
                  setShowConfirm((state) => {
                    return { ...state, isShow: false };
                  });
                },
                cancel: () => {
                  setShowConfirm((state) => {
                    return { ...state, isShow: false };
                  });
                },
                confirmText: t('user.note.folder.deleteConfirm.confirm'),
                cancelText: t('user.note.folder.deleteConfirm.cancel'),
              });
            }}
          >
            <Svg.deleteSvg2 className=" mr-[15px] mt-[3px]" />
            {t('user.note.folder.delete')}
          </div>
          <div className=" my-[4px] h-[1px] w-[166px] bg-[#DDDDE1]" />
        </div>
      )}
    </div>
  );
}

function TabBar() {
  const [showCreateFolder, setShowCreateFolder] = useState(false);
  const [containerCss, setContainerCss] = useState<CSSProperties>({
    visibility: 'hidden',
  });
  const copyRef = useRef<HTMLDivElement | null>(null);
  const [noteSelect, setNoteSelect] = useAtom(noteStore.select);
  const [renameData, setRenameData] = useSetState<{
    show: boolean;
    targetFolder: null | Folder.FolderInfo;
  }>({
    show: false,
    targetFolder: null,
  });
  const [cutPos, setCutPos] = useState(1);
  const { t } = useTranslation();
  const [moreShow, setMoreShow] = useState(false);
  const moreThanTargetClick = useFocus((state) => {
    setMoreShow(state == 'focus');
  });

  const allNoteFolder: Folder.FolderInfo = {
    id: '',
    userId: '0',
    icon: 'book',
    type: 'custom',
    name: t('user.createFolder.AllNotes'),
    noteCount: 0,
  };

  const listQuery = useRequest(folderReq.list, {
    cacheKey: `${folderReq.name}-${folderReq.list.name}`,
  });

  const list = useMemo(() => {
    return [allNoteFolder, ...(listQuery.data || [])];
  }, [listQuery.data, allNoteFolder.name]);

  const selectIdx = list.findIndex((i) => i.id == noteSelect.folderId);

  const listEl = list.map((item, idx) => {
    const isSelect = selectIdx == idx;
    return (
      <>
        <TabBarItem
          data={item}
          pos={idx}
          key={item.id}
          isSelect={isSelect}
          icon={item.icon}
          event={{
            onChange: () => {
              setNoteSelect({
                tagId: '',
                folderId: item.id,
              });
            },
            onRename: () => {
              setRenameData({
                show: true,
                targetFolder: item,
              });
            },
            onDelete: () => {
              listQuery.mutate((list) => {
                if (!list) return [];
                const idx = list.findIndex((i) => i.id == item.id);
                list.splice(idx, 1);
                return [...list];
              });

              if (noteSelect.folderId == item.id) {
                setNoteSelect({
                  ...noteSelect,
                  folderId: '',
                });
              }

              folderReq
                .remove({
                  folderId: item.id,
                })
                .then(() => {
                  listQuery.refresh();
                });
            },
          }}
        />
      </>
    );
  });

  //设置默认展示个数
  useEffect(() => {
    const copyEl = copyRef.current;
    if (copyEl) {
      const arr = Array.from(copyEl.children);
      const endNodeLen = arr.pop()!.clientWidth;
      let idx = 0;
      const len = copyEl.clientWidth;
      //@ts-ignore
      while (idx + 1 < arr.length && arr[idx + 1].offsetLeft + arr[idx + 1].clientWidth + endNodeLen - 30 < len) idx++;
      setCutPos(idx + 1);
      setContainerCss({
        visibility: 'visible',
      });
    }
  }, [copyRef, list]);

  return (
    <>
      <div className=" fixed right-full top-full  z-[3] flex h-[50px] w-[50%]" key="copyEl" ref={copyRef}>
        {listEl.slice(0, 20)}
        <div className="-z-50 ml-[-20px] box-border flex  items-center rounded-t-[14px] bg-white  pl-[20px]">
          <div
            className=" ml-[10px] flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full fill-[#939393] hover:bg-[#DEDEDE] hover:fill-[#3A3A3A]"
            style={{
              display: list.length > cutPos ? '' : 'none',
            }}
          >
            <Svg.DownArrowSvg onClick={() => moreThanTargetClick()} />
          </div>
          <div className="mx-[10px] flex h-[32px] w-[32px] cursor-pointer  items-center justify-center rounded-full fill-[#939393] hover:bg-[#DEDEDE] hover:fill-[#3A3A3A]">
            <Svg.AddSvg onClick={() => setShowCreateFolder(true)} />
          </div>
        </div>
      </div>
      <div className=" relative z-[3] flex h-[50px]" key="mainEl" style={containerCss} translate="no">
        {listEl.slice(0, cutPos)}
        <div className="-z-50 ml-[-20px] box-border flex  items-center rounded-t-[14px] bg-white  pl-[20px]">
          <div
            className=" ml-[10px] flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-full fill-[#939393] hover:bg-[#DEDEDE] hover:fill-[#3A3A3A]"
            style={{
              display: list.length > cutPos ? '' : 'none',
            }}
            onClick={() => {
              if (moreShow) return;
              moreThanTargetClick();
            }}
          >
            <Svg.DownArrowSvg />
          </div>
          <div
            className="mx-[10px] flex h-[32px] w-[32px] cursor-pointer  items-center justify-center rounded-full fill-[#939393] hover:bg-[#DEDEDE] hover:fill-[#3A3A3A]"
            onClick={() => setShowCreateFolder(true)}
          >
            <Svg.AddSvg />
          </div>
        </div>
        {moreShow && (
          <div
            className={clsxm(
              'absolute right-[0px] top-full max-h-[500px] w-[300px] rounded-[8px] bg-white',
              'scrollbar scrollbar-thumb-gray-500 scrollbar-track-gray-200 overflow-y-scroll'
            )}
            onClick={() => moreThanTargetClick()}
          >
            {list.slice(cutPos).map((item, idx) => {
              return (
                <TabBarItem
                  className="w-full text-ellipsis rounded-t-[0]"
                  pos={idx}
                  data={item}
                  key={item.id}
                  isSelect={item.id == noteSelect.folderId}
                  style={{
                    marginLeft: 0,
                  }}
                  event={{
                    onChange: () => {
                      setNoteSelect({
                        tagId: '',
                        folderId: item.id,
                      });
                    },
                    onRename: () => {
                      setRenameData({
                        show: true,
                        targetFolder: item,
                      });
                    },
                    onDelete: () => {
                      setCutPos(Math.max(cutPos - 1, 0));
                      listQuery.mutate((list) => {
                        if (!list) return [];
                        const idx = list.findIndex((i) => i.id == item.id);
                        list.splice(idx, 1);
                        return [...list];
                      });
                      if (noteSelect.folderId == item.id) {
                        setNoteSelect({
                          ...noteSelect,
                          folderId: '',
                        });
                      }

                      folderReq
                        .remove({
                          folderId: item.id,
                        })
                        .then(() => {
                          listQuery.refresh();
                        });
                    },
                  }}
                />
              );
            })}
          </div>
        )}
      </div>
      {showCreateFolder && (
        <InputModify
          title={t('user.createFolder.title')}
          placeholder={t('user.createFolder.placeholder')}
          onCancel={() => setShowCreateFolder(false)}
          onConfirm={async (name) => {
            const res = await folderReq.create({
              name,
            });
            //@ts-ignore
            if (res?.error) {
            } else {
              listQuery.refresh();
            }
            setShowCreateFolder(false);
          }}
        />
      )}
      {renameData.show && (
        <InputModify
          title={t('user.note.tag.rename.title')}
          placeholder={t('user.note.tag.rename.placeholder')}
          onCancel={() => setRenameData({ show: false })}
          onConfirm={async (name) => {
            if (!renameData.targetFolder?.id) {
              return;
            }
            const res = await folderReq.modify({
              folderId: renameData.targetFolder?.id,
              name,
            });
            //@ts-ignore
            if (res?.error) {
            } else {
              listQuery.refresh();
            }
            setRenameData({ show: false });
          }}
        />
      )}
    </>
  );
}

export default TabBar;
