import { reqParamsToBody } from '~/api/utils';
import { request } from '~/lib/axios';

type Params = {
  noteId: string;
  json: string;
};

export const updateSummary = (params: Params) => {
  return request.post<Params>('/v2/note/update', reqParamsToBody(params));
};
