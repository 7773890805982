import { clsxm } from '@hidock/utils';
import { CSSProperties, Dispatch, MutableRefObject, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import CircleProgressBar from '../../pages/notes/CircleProgress';
import {
  allowOptimizeAtom,
  btnStateAtom,
  cardInfoAtom,
  deviceConfigAtom,
  deviceInfoAtom,
  deviceStatusAtom,
  fileInfosAtom,
  latestDeviceInfoAtom,
  pauseGetFileInfos,
  useDeviceConfigInfo,
  useDeviceInfo,
} from './deviceState';
import { BtnState } from './deviceType';
import DeviceContainer from './showContainer';
import css from './style.module.css';
import { additionalfileInfos, deviceTool, deviceUpgradeState, getRecordingFileByFlieName, jensen } from './utils';
//@ts-ignore
import './test';
import { deviceSettingReq } from '~/api/device/deviceSettingReq';
import { deviceStatusReq } from '~/api/device/deviceStatus';
import { firmwareGet } from '~/api/device/firmwareGet';
import { DeviceLatest, latest } from '~/api/device/latest';
import { sdOptimizeReq } from '~/api/device/sdOptimize';
import { settingReq } from '~/api/device/setting';
import { deviceLogReq } from '~/api/log';
import { UserType } from '~/api/users/types';
import { useMyNavigate } from '~/hooks/useNavigate';
import { useEntryInfo } from '~/store/entry';
import { useUserInfo } from '~/store/user';
import { sleep } from '~/utils/sleep';
import { Logger } from '~/utils/utils';
import { motion } from 'framer-motion';
import { DeviceInfo } from 'jensen';
import { atom, getDefaultStore, useAtom, useSetAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import defaultTheme from 'tailwindcss/defaultTheme';
import { useMediaQuery } from 'usehooks-ts';
import { showConfirmAtom } from '../deleteConfrim';
import { MyModal } from '../general/myModal';
import { globalAddEl } from '../globalLayout';
import { myMessage } from '../MyToast/MyToast';
import { NoteUserGuideTargetId } from '../userGuide/config';
import { bindModalShowAtom } from './comp/DeviceBind';
import { DeviceOptimize } from './Optimize';
import { uploadFileQueue } from './ShowFile/deviceManage';

let fns: ((device: deviceUpgradeState) => any)[] = [];
export function onDeviceUpgradeState(fn: (device: deviceUpgradeState) => any) {
  fns.push(fn);

  return () => {
    const idx = fns.findIndex((target) => target == fn);
    if (idx != -1) fns.splice(idx, 1);
  };
}

const upgradeTipAtom = atom(false);

const useUpgradeTip = () => {
  return useAtom(upgradeTipAtom);
};

const Comps = {
  upgrade: DeviceUpdate,
  upgrading: DeviceUpdating,
  upgradeEnd: DeviceUpdateEnd,
  failed: UpgradeFailed,
};

type publicProps = {
  setUpgrade: Dispatch<SetStateAction<keyof typeof Comps>>;
  latestDeviceInfo: DeviceLatest | null;
};

function DeviceUpdate(props: publicProps) {
  const { setUpgrade } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'wu.ota',
  });
  const [latestDeviceInfo, setLatestDeviceInfo] = useAtom(latestDeviceInfoAtom);
  const [deviceInfo, setDeviceInfo] = useDeviceInfo();
  const [, setIsTip] = useUpgradeTip();
  const navigate = useNavigate();
  useEffect(() => {
    setIsTip(false);
  }, []);
  function ProceedHandle() {
    if (uploadFileQueue.isExecuting()) {
      myMessage.error(t('hasFileUpload'), 2000);
      return;
    }
    uploadFileQueue.pause();
    setUpgrade('upgrading');
  }

  if (!latestDeviceInfo) {
    //待处理
    return null;
  }

  return (
    <DeviceContainer
      onClick={() => navigate(-1)}
      className="relative flex max-h-screen w-[1200px] flex-col items-center p-[20px_20px] pb-0"
    >
      <div className="pt-[105px] text-center text-[34px] font-[600]">{t('upgrade.title')}</div>
      <div className="mt-[60px] flex h-[70px] w-[70px] items-center justify-center rounded-[8px] border-[4px] border-[#ACACAC]">
        <img className="w-[46px]" src="/dc.png" />
      </div>
      <div className=" mt-[12px] rounded-[6px] border-[1px] border-[#00A2AF] bg-[#00A2AF] p-[2px_7px] text-[16px] font-[500] text-[white] ">
        V{latestDeviceInfo.versionCode}
      </div>
      <div className="mt-[5px] text-[12px] font-[500] leading-[22px] text-[#ACACAC]">V{deviceInfo.version}</div>
      <div className="scrollbar scrollbar-thumb-gray-500 scrollbar-track-gray-200 mt-[0px] h-[260px] min-w-[800px] overflow-auto overflow-y-scroll p-[20px_18px]">
        <ReactMarkdown className={`${css.childListDe} whitespace-pre-wrap`} children={latestDeviceInfo?.remark} />
      </div>
      <div className="mt-[100px] flex h-[48px] w-[306px]   text-[18px] font-[600] ">
        <div
          id="my_Proceed"
          className="h-full w-[50%] cursor-pointer rounded-[30px_0_0_30px] bg-[#00A2AF] text-center leading-[48px]  text-white hover:shadow-lg hover:shadow-[rgba(0,162,175,0.35)]"
          onClick={ProceedHandle}
        >
          {t('upgrade.proceed')}
        </div>
        <div
          className=" box-border h-full w-[50%] cursor-pointer rounded-[0_30px_30px_0] border-y-[1px] border-r-[1px] border-[#ACACAC] bg-white text-center leading-[48px] text-[#ACACAC] hover:border-[#E8EAEA] hover:bg-[#E8EAEA]"
          onClick={() => {
            navigate(-1);
          }}
        >
          {t('upgrade.cancel')}
        </div>
      </div>
    </DeviceContainer>
  );
}
function DeviceUpdating(props: publicProps) {
  const { setUpgrade } = props;
  const upgradeLogger = (procedure: string, info: string, level?: string) => {
    if (level) {
      if (level == 'debug') Logger.debug('ota', procedure, info);
      else if (level == 'error') Logger.error('ota', procedure, info);
    } else Logger.info('ota', procedure, info);
  };
  const { t } = useTranslation(undefined, {
    keyPrefix: 'wu.ota',
  });
  const [btnState] = useAtom(btnStateAtom);
  const [latestDeviceInfo] = useAtom(latestDeviceInfoAtom);
  const [progress, setProgress] = useState(0);
  const [deviceInfo] = useDeviceInfo();
  const [upgradeState, setUpgradeState] = useState<'downloading' | 'upgrading' | 'waiting'>('downloading');

  const [, setIsTip] = useUpgradeTip();

  useEffect(() => {
    setIsTip(false);
  }, []);

  //升级处理
  useEffect(() => {
    if (!latestDeviceInfo) return;
    const store = getDefaultStore();
    let fileBuffer: null | ArrayBuffer = null;
    let isLeave = false;
    store.set(pauseGetFileInfos, true);

    async function requestUpgrade(versionNumber: number, fileLen: number, config: { tryTimes?: number }) {
      const { tryTimes = 0 } = config;
      let reqUpgradeRes: {
        result: 'fail' | 'accepted';
      } | null = null;

      for (let i = 0; i < tryTimes + 1 && !reqUpgradeRes; i++) {
        reqUpgradeRes = await jensen.requestFirmwareUpgrade(versionNumber, fileLen, 15);
        await sleep(1000);
        upgradeLogger('request-upgrade', JSON.stringify(reqUpgradeRes));
        if (isLeave) break;
      }
      return reqUpgradeRes;
    }

    const downloadProcedure = {
      name: 'downloading',
      state: '',
      progress: -1,
      limitTime: 90,
      retryTimes: 3,
      proportion: 0.3,
      init() {
        this.state = '';
        this.progress = 0;
      },
      async execute() {
        const self = this;
        self.init();
        let state = this.state;

        const downloadLogger = (info: string, level?: string) => {
          return upgradeLogger('downloading', info, level);
        };

        return new Promise<boolean>((resolve) => {
          //这里相当于开了三个线程 超时 进度 文件结果 页面关闭卸载进程
          //只要有结果后，其它线程都应该结束
          let timeoutTime = Date.now() + self.limitTime * 1000;
          const exec = async () => {
            await sleep(1000);
            //超时处理
            if (Date.now() > timeoutTime && !state && !isLeave) {
              downloadLogger('timeout');
              self.state = 'timeout';
              state = self.state;
              resolve(false);
            }
            if (!state && !isLeave) exec();
          };
          exec();

          function handleDownResult(res: ArrayBuffer | 0) {
            if (state && isLeave) return;
            if (res == 0) {
              self.state = 'fail';
              state = 'fail';
              resolve(false);
              downloadLogger('fail');
            } else {
              fileBuffer = res;
              self.state = 'success';
              state = 'success';
              resolve(true);
              downloadLogger('success');
            }
          }
          firmwareGet(
            {
              ...latestDeviceInfo!,
              deviceSn: deviceInfo.sn,
            },
            handleDownResult,
            (downLen) => {
              if (!state) {
                self.progress = downLen / latestDeviceInfo!.fileLength;
                downloadLogger(`progress:${parseInt(String(self.progress * 100))}`);
              }
            }
          );
        });
      },
    };

    const uploadProcedure = {
      name: 'upgrading',
      state: '',
      retryTimes: 1,
      progress: -1,
      limitTime: 450,
      proportion: 0.3,
      estimatedTime: 70,
      init() {
        this.progress = 0;
        this.state = '';
      },
      execute(): Promise<boolean> {
        const uploadLogger = (info: string) => {
          return Logger.info('upgrade', 'upload', info);
        };

        return new Promise(async (resolve) => {
          this.init();
          let state = this.state;
          //超时，进度线程 结果处理线程
          let timeoutTime = Date.now() + this.limitTime * 1000;
          const exec = async () => {
            await sleep(1000);
            //超时处理
            if (Date.now() > timeoutTime && !state && !isLeave) {
              uploadLogger('timeout');
              this.state = 'timeout';
              state = this.state;
              resolve(false);
            }
            if (!isLeave) this.progress = Math.min(100 / this.estimatedTime / 100 + this.progress, 1);
            if (!state && !isLeave) exec();
          };
          exec();

          let reqUpgrade = await jensen.requestFirmwareUpgrade(
            latestDeviceInfo.versionNumber,
            latestDeviceInfo.fileLength,
            30
          );
          //结果处理
          let res: {
            result: 'failed' | 'success';
          } | null = null;

          if (reqUpgrade?.result == 'accepted') {
            while (!res) {
              if (state || isLeave) return;
              res = await jensen.uploadFirmware(Array.from(new Uint8Array(fileBuffer!)), this.limitTime);
              await sleep(2000);
            }
          }

          if (res!.result == 'failed') {
            this.state = this.state || 'fail';
            state = this.state;
            resolve(false);
            uploadLogger('fail');
            return;
          } else {
            this.state = 'success';
            state = this.state;
            resolve(true);
            uploadLogger('success');
          }
        });
      },
    };

    const verifyProcedure = {
      name: 'waiting',
      state: '',
      /*由于这里设置了一分钟没有获取新的设备信息就会事变，所以重试次数要变成0了 */
      retryTimes: 0,
      progress: -1,
      limitTime: 360,
      proportion: 0.4,
      estimatedTime: 120,
      init() {
        this.progress = 0;
        this.state = '';
      },
      async execute(): Promise<boolean> {
        const verifyLogger = (info: string) => {
          return Logger.info('upgrade', 'verify', info);
        };

        return new Promise(async (resolve) => {
          this.init();
          let state = this.state;
          //超时，进度，卸载线程 结果处理线程
          let cntNum = 0;
          let timeoutTime = Date.now() + this.limitTime * 1000;
          const exec = async () => {
            await sleep(1000);
            //超时处理
            if (Date.now() > timeoutTime && !state && !isLeave) {
              resolve(false);
              this.state = 'timeout';
              state = this.state;
              verifyLogger('timeout');
              jensen.isStopConnectionCheck = false;
            }
            //进度处理
            if (!state) this.progress = Math.min(++cntNum / this.estimatedTime, 0.98);
            if (!state && !isLeave) exec();
          };
          exec();

          await sleep(10 * 1000);
          if (!isLeave) jensen.isStopConnectionCheck = true;

          //结果线程
          let res: DeviceInfo | null = await Promise.race([jensen.getDeviceInfo(), sleep(5000)]);
          let getInfoTime = Date.now();
          while (res?.versionNumber != latestDeviceInfo.versionNumber && Date.now() - getInfoTime < 1000 * 90) {
            await sleep(2000);
            verifyLogger('tryconnection start');
            await jensen.tryconnect(true);
            verifyLogger('tryconnection end');
            res = await Promise.race([jensen.getDeviceInfo(), sleep(5000)]);
            verifyLogger(`getDevice:${JSON.stringify(res)}`);
            if (res) getInfoTime = Date.now();
            if (state || isLeave) {
              verifyLogger('isLeave or hasState');
              return;
            }
          }
          //拿到结果后的处理
          if (res?.versionNumber == latestDeviceInfo.versionNumber) {
            const { sn, versionNumber, versionCode } = res!;
            store.set(deviceInfoAtom, {
              versionNumber,
              sn,
              version: versionCode,
            });
            this.state = 'success';
            state = this.state;
            this.progress = 1;
            verifyLogger('success');
            //成功后进入1
            this.progress = 1;
            resolve(true);
          } else {
            this.state = 'fail';
            state = this.state;
            verifyLogger('fail');
            resolve(false);
          }
          jensen.isStopConnectionCheck = false;
        });
      },
    };

    const procedures = [downloadProcedure, uploadProcedure, verifyProcedure];

    (async function () {
      let step = 0;
      let state = '';
      //显示处理 实际运行处理
      async function loopRenderPage() {
        const curStep = Math.min(step, procedures.length - 1);
        //时间和进度
        await sleep(50);
        let progress = 0;
        for (let i = 0; i < curStep; i++) {
          const procedure = procedures[i];
          progress += procedure.proportion;
        }
        //@ts-ignore
        setUpgradeState(procedures[curStep].name);
        const procedure = procedures[curStep];
        progress += procedure.progress == -1 ? 0 : procedure.progress * procedure.proportion;
        setProgress((preProgress) => {
          return Math.max(preProgress, progress);
        });
        if (!state && !isLeave) loopRenderPage();
      }

      loopRenderPage();

      const reqUpgradeRes = await requestUpgrade(latestDeviceInfo.versionNumber, latestDeviceInfo.fileLength, {
        tryTimes: 3,
      });
      if (isLeave) return;

      if (reqUpgradeRes?.result != 'accepted') {
        setUpgrade('upgrade');
        // 要改提示
        myMessage.error(t('upgrade-failed'));
        upgradeLogger('request-upgrade', 'failed');
        return;
      }

      upgradeLogger('request-upgrade', 'accepted');

      for (step = 0; step < procedures.length; step++) {
        let retryCnt = -1;
        let procedureState = false;
        const procedure = procedures[step];

        upgradeLogger(
          'procedure',
          'Current Procedure Name: ' + procedure.name + ', RetryTimes: ' + procedure.retryTimes
        );

        while (!procedureState && retryCnt < procedure.retryTimes) {
          retryCnt++;
          procedureState = await procedure.execute();
          if (!procedureState) {
            upgradeLogger('retry', 'Procedure: ' + procedure.name + ', Retry: ' + retryCnt);
            await sleep(3000);
          }
          if (isLeave) return;
        }

        //给测试脚本相关提示信息
        if (procedureState) {
          const hash = {
            downloading: 'downloaded',
            upgrading: 'uploaded',
            waiting: 'complete',
          };
          //@ts-ignore
          fns.forEach((fn) => fn(hash[procedure.name] || procedure.name));
        }

        //升级失败
        if (!procedureState && retryCnt >= procedure.retryTimes) {
          setUpgrade('failed');
          //@ts-ignore
          myMessage.error(t(`${procedure.name}-${procedure.state}`));

          upgradeLogger('upgrade-failed', 'failed at ' + procedure.name + ' after ' + retryCnt + ' times retry');

          //用于OTA升降级收集数据使用
          fns.forEach((fn) => fn('failed'));
          return;
        }
      }

      upgradeLogger('upgrade', 'finished');

      await sleep(1000);
      setUpgrade('upgradeEnd');
      deviceSettingReq({
        deviceSn: deviceInfo.sn,
        version: deviceInfo.version,
      }).then((res) => {
        Logger.info('upgrade', 'sync_service_device_version', JSON.stringify(res) + '');
      });
    })();

    return () => {
      isLeave = true;
      store.set(pauseGetFileInfos, false);
      jensen.isStopConnectionCheck = false;
      uploadFileQueue.start();
      upgradeLogger('close upgrade', `isLeave:${isLeave},jensen.isStopConnctionCheck:${jensen.isStopConnectionCheck}`);
    };
  }, []);

  //是否能阻止屏幕休眠
  useEffect(() => {
    const wakeLock = navigator.wakeLock;
    if (!wakeLock) return;
    const lockPromise = wakeLock.request('screen');

    return () => {
      lockPromise.then((lock) => lock.release());
    };
  }, []);

  return (
    <DeviceContainer className="relative flex flex-col items-center justify-center">
      <div>
        <div className="relative flex items-center justify-center">
          <CircleProgressBar radius={94} progress={((progress * 100) | 0) / 100} />
          <div className=" absolute bottom-0 left-0 right-0 top-0 flex flex-col items-center justify-center text-[44px] font-[500] text-[#00A2AF]">
            <span data-progress={progress}>{(progress * 100) | 0}%</span>
            <span className=" text-[18px]">{t(upgradeState)}</span>
          </div>
        </div>
        <div className="mt-[20px] text-center text-[34px] font-[800]">{t('upgradeing.title')}</div>

        <div className="bg-[rgba(104, 104, 104, 1)] mt-[5px] text-[24px] text-[rgba(104,104,104,1)]">
          {t('upgradeing.describe')}
        </div>
      </div>
    </DeviceContainer>
  );
}
function DeviceUpdateEnd(props: publicProps) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'wu.ota',
  });
  const navigate = useNavigate();
  const setConfim = useSetAtom(showConfirmAtom);
  function leaveHandle() {
    const { latestDeviceInfo } = props;
    if (latestDeviceInfo?.nextStep == 'optimize' && getDefaultStore().get(allowOptimizeAtom)) {
      setConfim({
        title: latestDeviceInfo.nextTitle,
        isShow: true,
        message: latestDeviceInfo.nextHint!,
        confirm() {
          const store = getDefaultStore();
          store.set(pauseGetFileInfos, true);
          const order = globalAddEl(
            <DeviceOptimize
              event={{
                onClose() {
                  store.set(pauseGetFileInfos, false);
                  order.remove();
                },
              }}
            />
          );
          setConfim((state) => {
            return {
              ...state,
              isShow: false,
            };
          });
          navigate('/notes', { replace: true });
        },
        cancel() {
          setConfim((state) => {
            return {
              ...state,
              isShow: false,
            };
          });
          navigate('/notes', { replace: true });
        },
        cancelText: t('succes.skip.cancel'),
        confirmText: t('succes.skip.confirm'),
      });
    } else {
      navigate('/notes', { replace: true });
    }
  }

  return (
    <DeviceContainer className="relative flex flex-col items-center justify-center">
      <div
        id="upgradeEnd"
        className=" absolute right-[15px] top-[15px] z-20 flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-[50%] hover:bg-[#E8EAEA]"
        onClick={leaveHandle}
      >
        <img src="/connectDevice/wrong.png" className="h-[16px] w-[16px]" />
      </div>
      <div>
        <img className="w-[228px]" src="/finish.png" />
      </div>
      <p className="mt-[35px] text-center  text-[34px] font-[700]">{t('upgradeEnd.title')}</p>
      <div className="mt-[25px]font-[700] pb-[40px] text-[24px] text-[rgba(104,104,104,1)]">
        {t('upgradeEnd.describe')}
      </div>
      {/* <div className="flex-1"></div> */}
      <div className=" absolute bottom-[30px] flex w-full flex-col items-center">
        <button className=" h-[50px] w-[150px] rounded-[15px] bg-[#00A2AF] font-bold text-white" onClick={leaveHandle}>
          {t('done')}
        </button>
        <p className="  m-[12px] mb-[30px] whitespace-pre-wrap text-center text-[14px] font-[400] not-italic leading-[16px] text-[#686868]">
          {t('upgradeEnd.describe2')}
        </p>
      </div>
    </DeviceContainer>
  );
}

function UpgradeFailed(props: publicProps) {
  const navigate = useNavigate();
  const closeHandle = () => navigate('/notes', { replace: true });
  const { t } = useTranslation();
  //1. 标题
  const headerEl = (
    <h1 className=" mt-[60px] text-[28px] font-[700] leading-[24px] ">{t('wu.ota.failed-guide.title')}</h1>
  );

  //2.内容
  const prographEl = (
    <p className=" mt-[40px] whitespace-pre-wrap text-[20px] font-[500] leading-[30px]">
      {t('wu.ota.failed-guide.content')}
    </p>
  );

  //3.操作步骤
  const stepsEl = (
    <div className=" mt-[30px] whitespace-pre-wrap text-[18px] font-[600] leading-[30px] text-[#3D3D3D]">
      {t('wu.ota.failed-guide.steps')}
    </div>
  );

  //Done按钮

  const doneEl = (
    <button
      className=" mt-[116px] h-[44px] rounded-[8px] bg-[#00A2AF] p-[11px_20px] text-[15px] font-bold text-white"
      onClick={closeHandle}
    >
      {t('wu.ota.failed-guide.done')}
    </button>
  );

  //使用列表左右居中 顶部向前
  return (
    <MyModal
      className=" flex h-[500px] w-[720px] flex-col items-center rounded-[20px] bg-white px-[20px] text-[#3A3A3A] shadow-[0px_4px_50px_0px_rgba(0,0,0,0.2)]"
      onClose={closeHandle}
    >
      {headerEl}
      {prographEl}
      {stepsEl}
      {doneEl}
    </MyModal>
  );
}

export function DeviceUpdatePage() {
  const [state, setState] = useState<keyof typeof Comps>('upgrade');
  const { t } = useTranslation();
  const [latestDeviceInfo] = useAtom(latestDeviceInfoAtom);
  const latestDeviceInfoRef = useRef(latestDeviceInfo);
  const [btnState] = useAtom(btnStateAtom);
  const navigate = useMyNavigate();
  const Comp = Comps[state];

  useEffect(() => {
    if (BtnState.notConnection == btnState && state != 'upgradeEnd') {
      Logger.info('DeviceUpdatePage', 'onConnection', btnState);
      if (state == 'upgrading') myMessage.error(t('wu.ota.device-disconnection'));
      navigate('/notes', { replace: true });
    }
  }, [btnState]);

  return <Comp latestDeviceInfo={latestDeviceInfoRef.current} setUpgrade={setState} />;
}

export function DeviceGlobalShow() {
  return (
    <>
      <Outlet />
    </>
  );
}

/**
 * 处理是否关闭设备请求打开网页的弹窗
 */
function useCloseToast() {
  //
  const [deviceConfig] = useDeviceConfigInfo();
  const [deviceStatus] = useAtom(deviceStatusAtom);
  const [deviceInfo] = useAtom(deviceInfoAtom);
  useEffect(() => {
    if (deviceInfo.sn)
      jensen.getSettings().then((res) => {
        if (res?.notification && deviceConfig.webusbNotification && deviceStatus?.ownership == 'mine') {
          jensen.setNotification(false);
        }
      });
  }, [deviceConfig.webusbNotification, deviceStatus?.ownership, deviceInfo.sn]);
}

/**
 * 检查是否需要更新
 */
function useControlTip() {
  const [firstTip, setFirstTip] = useState(true);
  const [tip, setTip] = useAtom(upgradeTipAtom);
  const [deviceInfo] = useDeviceInfo();
  const [latestDeviceInfo, setLatestDeviceInfo] = useAtom(latestDeviceInfoAtom);
  const [deviceStatus, setDeviceStatus] = useAtom(deviceStatusAtom);
  useEffect(() => {
    const isUpgradable = deviceTool.isUpgradable(deviceInfo.versionNumber, latestDeviceInfo, deviceStatus);
    if (isUpgradable && firstTip) {
      setTip(true);
      setFirstTip(false);
    }
  }, [firstTip, deviceInfo, latestDeviceInfo, deviceStatus]);
}

export default function DeviceConntion() {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'wu',
  });
  useCloseToast();
  useControlTip();
  const { data: userInfo } = useUserInfo();
  const [btnState, setBtnState] = useAtom(btnStateAtom);
  const setDeviceConfig = useSetAtom(deviceConfigAtom);
  const [filesInfos, setFilesInfos] = useAtom(fileInfosAtom);
  const [isTip, setIsTip] = useUpgradeTip();
  const [deviceInfo, setDeviceInfo] = useDeviceInfo();
  const [latestDeviceInfo, setLatestDeviceInfo] = useAtom(latestDeviceInfoAtom);
  const [deviceStatus, setDeviceStatus] = useAtom(deviceStatusAtom);
  const setDeviceBindInfo = useSetAtom(bindModalShowAtom);
  const [entryInfo] = useEntryInfo();
  const navigate = useNavigate();
  const setAllowOptimize = useSetAtom(allowOptimizeAtom);
  async function CheckVersion(versionNumber: number) {
    try {
      const model = await jensen.getModel();
      const data = await latest({ version: versionNumber, model });
      console.log('latestDeviceInfo', data);
      setLatestDeviceInfo(data);
    } catch (err: any) {
      console.error(err);
    }
  }

  //获取设备文件防止打开文件页出现闪屏
  async function getDeviceFile() {
    let infos = await jensen.listFiles(5);
    const { recording, createDate, createTime } = (await jensen.getRecordingFile()) || {};
    console.log('device files', infos);
    if (!infos) return;
    if (!infos.length) return;
    infos = infos.filter((i) => /(\d{14}REC\d{2}\.wav)|(.*\.hda)/.test(i.name));
    const res = await additionalfileInfos(infos);

    if (recording) setFilesInfos([getRecordingFileByFlieName(recording, createDate, createTime), ...res]);
    else setFilesInfos(res);
  }

  //监听设备连接事件
  useEffect(() => {
    //@ts-ignore
    if (!navigator.usb) return;
    let module = 'jensen-event';

    let isSnChange = false;

    async function connectHandle() {
      const procedure = 'onconnect';
      Logger.info(module, procedure, 'trigger');
      let data: null | DeviceInfo = null;
      console.log('deviceInfo', data);
      while (!data) {
        data = await jensen.getDeviceInfo(5);
        if (!data) await sleep(1000 * 3);
      }
      const { sn, versionCode, versionNumber } = data;

      const deviceStatus = await deviceStatusReq({ deviceSn: sn });
      if (deviceStatus?.xsn && !isSnChange) {
        let res = await jensen.writeSerialNumber(deviceStatus.xsn);
        while (1) {
          res = await jensen.writeSerialNumber(deviceStatus.xsn);
          if (res.result != 'success') await sleep(3000);
          else {
            isSnChange = true;
            deviceLogReq({
              deviceSn: data.sn,
              activity: 'xsn',
              additional: deviceStatus.xsn,
            });
            connectHandle();
            return;
          }
        }
      }

      if (sn)
        sdOptimizeReq
          .checkOptimize({
            deviceSn: sn,
          })
          .then((res) => {
            //@ts-ignore
            if (typeof res == 'boolean' && res) {
              setAllowOptimize(true);
            }
          });

      setBtnState((btnState) => {
        if (btnState == BtnState.notConnection) return BtnState.connected;
        return btnState;
      });

      Logger.info(module, procedure, `deviceInfo:${JSON.stringify(data)}`);
      console.log('deviceInfo', data);

      Logger.info(module, procedure, `deviceStatus:${JSON.stringify(deviceStatus)}`);
      //@ts-ignore
      if (deviceStatus?.error) return;
      setDeviceStatus(deviceStatus);

      CheckVersion(versionNumber);
      setDeviceInfo((deviceInfo) => {
        return {
          ...deviceInfo,
          sn,
          version: versionCode,
          versionNumber,
        };
      });
      await getDeviceFile();
    }

    jensen.onconnect = connectHandle;
    jensen.ondisconnect = async function () {
      const procedure = 'ondisconnect';
      const audio = document.getElementById('test_audio') as HTMLAudioElement;
      audio && audio.remove();
      Logger.info(module, procedure, 'trigger');
      setBtnState(BtnState.notConnection);
      setDeviceStatus(null);
      setDeviceInfo(deviceInfoAtom.init);
      setIsTip(false);
      setFilesInfos([]);
      uploadFileQueue.init();
      setAllowOptimize(false);
    };
  }, []);

  //检查是否以连接
  useEffect(() => {
    setTimeout(() => {
      jensen.init();
    }, 2000);
  }, []);

  //检查兼容性问题
  useEffect(() => {
    //@ts-ignore
    if (!window.navigator.usb) setBtnState(BtnState.notSupport);
  }, []);

  //获取设备相关配置
  useEffect(() => {
    if (deviceInfo?.sn) {
      settingReq({
        deviceSn: deviceInfo.sn,
        version: deviceInfo.version,
      }).then((res) => {
        setDeviceConfig((state) => {
          for (let i = 0; i < res.length; i++) {
            if (res[i].setting == 'file.auto-upload') state.autoUpload = res[i].value == 'on' ? true : false;
            if (res[i].setting == 'webusb-notification') state.webusbNotification = res[i].value == 'on' ? true : false;
          }
          return { ...state };
        });
      });
    }
  }, [deviceInfo]);

  const upgradeTipRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [upgradeTipCss, setUpgradeTipCss] = useState<CSSProperties>({
    position: 'fixed',
    left: -999999,
    top: -99999,
  });

  const upgradeTip = (
    <div
      className=" h-[100px] w-[300px] min-w-[300px] rounded-[10px] border-[1px] border-[#cccccc] bg-white p-[10px] "
      onClick={(e) => e.stopPropagation()}
      style={{
        display:
          isTip &&
          entryInfo.visited &&
          btnState != BtnState.notConnection &&
          latestDeviceInfo?.versionNumber != deviceInfo.versionNumber
            ? ''
            : 'none',
        ...upgradeTipCss,
      }}
      ref={upgradeTipRef}
    >
      <div className="pl-[20px]">{t('ota.tip.message')}</div>
      <div className="absolute bottom-0 right-0 flex">
        <div
          id="learn_more"
          className="cursor-pointer rounded-[10px_0_0_0] border-l-[1px] border-t-[1px] border-[#cccccc] p-[5px_10px] text-[13px]"
          onClick={() => {
            navigate('/notes/device/update');
            setIsTip(false);
          }}
        >
          {t('ota.upgrade.proceed')}
        </div>
        <div
          className="cursor-pointer border-l-[1px] border-t-[1px] border-[#cccccc] p-[5px_10px] text-[13px]"
          onClick={() => setIsTip(false)}
        >
          {t('ota.tip.later')}
        </div>
      </div>
      <div className={`${css.triangle} left-[-40px] top-[50%] -translate-y-1/2`}>
        <div
          className={`${css.triangle} left-[-17px] top-[-20px] border-[20px]`}
          style={{ borderRightColor: 'white' }}
        />
      </div>
    </div>
  );

  async function ClickHandle() {
    if (deviceStatus?.accessibility == 'denied') {
      setDeviceBindInfo('warning');
    }
    if (deviceStatus?.ownership == 'new') {
      setDeviceBindInfo('start');
    }
    if (btnState == BtnState.notSupport) {
      window.open('https://chromeenterprise.google/browser/download/');
      return;
    }
    if (
      (btnState == BtnState.connected && deviceStatus?.accessibility == 'read-only') ||
      deviceStatus?.ownership == 'mine'
    ) {
      navigate('/notes/device');
    }
    if (BtnState.notConnection == btnState) {
      jensen.connect();
    }
  }

  const isMore2xl = !useMediaQuery(`(max-width: ${defaultTheme.screens['2xl']})`);
  function showImg(btnState: BtnState) {
    const obj: Record<BtnState, JSX.Element> = {
      notSupport: (
        <div
          className="relative flex h-[103px] w-[103px] items-center justify-center rounded-full text-center"
          onClick={() => window.open('https://chromeenterprise.google/browser/download/')}
        >
          <img className="w-[70px]" src="/chrome.png" />
        </div>
      ),
      notConnection: (
        <div className=" relative">
          <div className="relative  flex w-[103px] items-center justify-around rounded-full text-center">
            <img src="/hidock.png?id=1" className=" aspect-[1.6486486486486487] w-[100px]" />
          </div>
        </div>
      ),
      connected: (
        <div className=" relative">
          <div className="  relative flex w-[100px] items-center justify-around rounded-full text-center">
            <img src="/hidock.png?id=1" className="aspect-[1.6486486486486487]" />
          </div>
        </div>
      ),
    };

    return obj[btnState];
  }

  const showText = () => {
    const Btn = () => {
      if (deviceStatus?.ownership == 'new' || deviceStatus?.accessibility == 'denied') {
        return (
          <div className=" mt-[21px] flex flex-col items-center justify-center text-center text-[18px] text-black">
            <div className=" flex h-[34px] w-[150px] items-center justify-center rounded-full bg-gradient-to-r from-[#00A2AF] via-[#02A4B2] to-[#24DCEB]">
              <span className=" text-[14px] leading-[20px]">{t('state.unbind')}</span>
            </div>
          </div>
        );
      }

      if (
        (btnState == BtnState.connected && deviceStatus?.accessibility == 'read-only') ||
        deviceStatus?.ownership == 'mine'
      ) {
        return (
          <>
            <div className=" flex flex-col items-center justify-center text-center text-[18px] text-black">
              <p className=" my-[10px] text-[14px] font-bold leading-[16px] text-[#00A2AF]">{deviceStatus.name}</p>
              <div className=" flex h-[34px] w-[150px] items-center justify-center rounded-full border border-[#00A2AF]">
                <span className=" text-[14px] leading-[20px]">{t('state.connected')}</span>
                <img src="/connectDevice/connect.png?version=1" className=" ml-[5px] w-[20px]" />
              </div>
            </div>
          </>
        );
      }

      // 如果不支持WebUSB
      if (btnState == BtnState.notSupport) {
        return t('state.not-supported');
      } else {
        // 如果未连接
        if (btnState == BtnState.notConnection) {
          return (
            <div className=" mt-[21px] flex flex-col items-center justify-center text-center text-[18px] text-black">
              <div className=" flex h-[34px] w-[150px] items-center justify-center rounded-full bg-[#E8EAEA]">
                <p className=" text-[14px] leading-[20px]">{t('state.not-connect')}</p>
              </div>
            </div>
          );
        }
        // 如果已连接
        else {
          // 如果是
        }
      }

      return null;
    };

    return Btn();
  };

  function bottomText() {
    if (
      (deviceStatus?.ownership == 'new' || deviceStatus?.ownership == 'otherwise') &&
      userInfo?.type == UserType.Membership
    )
      return t('state.not-connection.membership.');
    if (
      (deviceStatus?.ownership == 'new' || deviceStatus?.ownership == 'otherwise') &&
      (userInfo?.type == UserType.Trail || userInfo?.type == UserType.free)
    )
      return t('state.not-connection.basic.describe');
    return t('state.describe');
  }

  useEffect(() => {
    const scrollDom = document.querySelector('#layourt-sideBar') as HTMLElement;

    function scrollHandle() {
      const container = containerRef.current!;
      const upgradeTip = upgradeTipRef.current!;
      const contaienrRect = container.getBoundingClientRect();
      const x = contaienrRect.x + contaienrRect.width + 8;
      const y = contaienrRect.y + contaienrRect.height / 2 - 50;

      setUpgradeTipCss({
        position: 'fixed',
        left: x,
        top: y,
      });
    }
    scrollHandle();
    scrollDom.onscroll = scrollHandle;
    window.addEventListener('resize', scrollHandle);
    return () => {
      scrollDom.onscroll = null;
      window.removeEventListener('resize', scrollHandle);
    };
  }, []);

  return (
    <div className=" relative w-full" ref={containerRef}>
      <div
        className={clsxm(
          'flex aspect-[276/250] h-full cursor-pointer flex-col  items-center justify-center rounded-[14px] bg-white',
          'relative ml-[30px] mr-[20px] w-[calc(100%-50px)] cursor-pointer'
        )}
        id={NoteUserGuideTargetId.device}
        onClick={ClickHandle}
      >
        <motion.button whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.97 }} className=" ">
          <div className=" 3xl:pt-[21px] relative flex justify-center">{showImg(btnState)}</div>
          <p
            className=" relative m-[0px_auto_0] text-center text-[18px] font-[600] leading-[16px]"
            id="connect-state-info"
          >
            {showText()}
          </p>
          <p className=" m-[12px] whitespace-pre-wrap text-center text-[14px] font-[400] not-italic leading-[16px] text-[#686868]">
            {bottomText()}
          </p>
        </motion.button>
      </div>

      {upgradeTip}
    </div>
  );
}
