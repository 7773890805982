import path from 'path';
import editImg from '~/../public/edit.png';
import { useAPIQuery } from '~/api/helper';
import { getNoteInfoQueryParmas, Note2, queryInfo2 } from '~/api/note2s/info2';
import { useDeleteNoteMutation } from '~/api/notes/delete';
import { noteTranslateReq } from '~/api/noteTranslate.ts';
import { templateReq } from '~/api/templateReq.ts';
import { UserType } from '~/api/users/types.ts';
import { globalAddEl } from '~/components/globalLayout.tsx';
import { showGlobalLoadingAtom } from '~/components/globalLoad.tsx';
import { useMyNavigate } from '~/hooks/useNavigate';
import { showUpgradeTipAtom } from '~/layouts/RootLayout.tsx';
import { queryClient } from '~/main';
import { formatSecondsToProgress } from '~/pages/record/utils.ts';
import { useUserInfo } from '~/store/user.ts';
import { CloseSvg, ShareSvg } from '~/svg/svg.tsx';
import { downloadFile } from '~/utils/general';
import pdfmark from '~/utils/pdfmark.ts';
import { sleep } from '~/utils/sleep.ts';
import { isEmojiCharacter, locationHost, parseEmojiChar } from '~/utils/utils';
import { useHover, useRequest, useSetState } from 'ahooks';
import DocxTemplater from 'docxtemplater';
import { getDefaultStore, useAtom, useSetAtom } from 'jotai';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import PizZip from 'pizzip';
import PizZipUils from 'pizzip/utils';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { styled } from 'styled-components';
import { useCopyToClipboard } from 'usehooks-ts';
import { useShowConfirm } from '../../deleteConfrim/index.tsx';
import { getLanguageOptions } from '../../deviceConnection/ShowFile/ShowFile.tsx';
import { Select2 } from '../../general/Select2.tsx';
import { myMessage } from '../../MyToast/MyToast.tsx';
import { NoteDeleteModal } from '../../note/DeleteModal.tsx';
import { ShareBox } from '../../shareBox/index.ts';
import { hidockImg } from '../hidock.ts';
import ModeSpeaker from '../mode-speaker/modeSpeaker.tsx';
import { ExportModal } from '../more_than/ExportModal.tsx';
import { MoreThan } from '../more_than/more_than.tsx';
import {
  custom_dictionary_dom_class,
  noteStateAtom,
  sentencesAtom,
  SpeakerList,
  SpeakerNoteGlobalData,
} from '../note-result2.tsx';
import { colors } from '../pubilcInfo.ts';
import { summaryKey, summaryStateAtom } from '../SummaryEditor.tsx';
import { ParagraphData } from '../Transcription.tsx';
import { abbreviationName, getStrHash } from '../utils.ts';
import FolderSelect from './FolderSelect.tsx';
import { Rating } from './Rating.tsx';
import { TagSelect } from './Tag.tsx';
import { style } from '~/store/player.ts';

type HeaderProps = {
  dataset?: {
    title?: string;
    subTitle?: string;
    speakerList?: SpeakerList;
    noteId: string;
    info2: Note2 | null;
  };
  event: {
    onHoverTarget?: (name: string) => void;
    onBlur?: (title: string) => void;
    onChangeName?: (newName: string, oldName: string) => void;
    onCloseClick?: () => void;
    setCluster?: (state: 'open' | 'close') => void;
  };
  noteGlobalDataRef: MutableRefObject<SpeakerNoteGlobalData>;
  state?: {
    openCluster?: boolean;
  };
};

const Avator = (props: {
  name: string;
  color: string;
  onHover?: (name: string, state: boolean) => void;
  onChangeName?: HeaderProps['event']['onChangeName'];
  speakerList: SpeakerList;
  isEditor?: boolean;
}) => {
  const { name, onHover, onChangeName, speakerList, isEditor, color } = props;
  const { t } = useTranslation();
  const avatorRef = useRef<HTMLDivElement | null>(null);
  const ishover = useHover(avatorRef);

  useEffect(() => {
    onHover?.(name, ishover);
  }, [ishover]);
  const [showMode, setShowMode] = useState(false);
  const showModeRef = useRef(false);
  useEffect(() => {
    const clickHandle = () => {
      setShowMode(showModeRef.current);
      showModeRef.current = false;
    };
    window.addEventListener('click', clickHandle);

    return () => {
      window.removeEventListener('click', clickHandle);
    };
  }, []);

  const getModeSpeakerEl = () => {
    if (!showMode || !avatorRef.current || !isEditor) return null;
    const avator = avatorRef.current;
    const rect = avator.getBoundingClientRect();
    return (
      <ModeSpeaker
        inputPlaceholder={t('note.info.rename-speaker')}
        speakerList={speakerList}
        domRect={rect}
        defaultName={name}
        onSelect={(newName) => {
          setShowMode(false);
          onChangeName?.(newName, name);
        }}
      />
    );
  };

  return (
    <span className=" relative">
      <div
        className="z-10  ml-[-10px] flex aspect-square w-[48px] cursor-pointer items-center justify-center whitespace-nowrap rounded-full text-[20px] font-[700] text-black outline-none hover:z-20"
        style={{
          backgroundColor: color,
          // boxShadow: "0 4px 20px rgba(0,0,0,0.25)"
        }}
        ref={avatorRef}
        onClick={(e) => {
          showModeRef.current = true;
        }}
      >
        {abbreviationName(name).toUpperCase()}
      </div>
      {getModeSpeakerEl()}
    </span>
  );
};

const useConfimDeleteNote = (noteId: string) => {
  const res = useState(false);
  const { t } = useTranslation();
  const { mutate } = useDeleteNoteMutation();
  const [, setShowConfirm] = useShowConfirm();
  const navigate = useNavigate();
  useMemo(() => {
    setShowConfirm({
      message: t('note.modal.delete.label'),
      isShow: res[0],
      confirm: () => {
        mutate({ noteId }), res[1](false);
        navigate(-1);
      },
      cancel: () => {
        res[1](false);
      },
    });
  }, [res[0]]);

  return res;
};

function SelectLanguage(props: {
  onChange?: (info: { label: string; value: string }) => void;
  onClose?: () => void;
  select?: string;
}) {
  const options = getLanguageOptions(false);
  return <Select2 select={props.select} data={options} onChange={props.onChange} onClose={props.onClose} />;
}

const SvgContainer = styled.div`
  --color: #939393;
  &:hover {
    --color: #3a3a3a;
  }
`;

export default function Header(props: HeaderProps) {
  const { dataset, event, noteGlobalDataRef, state } = props;
  const { onHoverTarget, onBlur, onCloseClick } = event;
  const { title, subTitle, noteId = '', speakerList = [], info2 } = dataset || {};
  const { data: userInfo } = useUserInfo();
  const preNameRef = useRef('');
  const titleElRef = useRef<HTMLDivElement | null>(null);
  const navigate = useMyNavigate();
  const preHeaderRef = useRef(title);
  const deleteRef = useRef<HTMLImageElement | null>(null);
  const setSummaryState = useSetAtom(summaryStateAtom);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useConfimDeleteNote(noteId);
  const [_, copy] = useCopyToClipboard();
  const { t } = useTranslation();
  const [noteState] = useAtom(noteStateAtom);
  const [showExportModal, setShowExportModal] = useState(false);
  const setGlobalLoading = useSetAtom(showGlobalLoadingAtom);
  const detailQuery = useRequest(() => templateReq.detail({ id: noteId }), {
    cacheKey: `templateReq.detail-${noteId}`,
    manual: true,
  });
  const fields = detailQuery.data?.fields || [];

  useEffect(() => {
    preHeaderRef.current = titleElRef.current?.innerText || title;
  }, [title]);

  function mergeSpeakerHandle(paragraphDatas: ParagraphData[]) {
    return paragraphDatas.map((item) => {
      return {
        beginTime: formatSecondsToProgress((item.beginTime / 1000) | 0, true) + `.${item.beginTime % 1000}`,
        endTime: formatSecondsToProgress((item.endTime / 1000) | 0, true) + `.${item.endTime % 1000}`,
        speaker: item.speaker,
        sentence: item.words.map((item) => item.word).join(''),
      };
    });
  }
  function NoMergeSpeakerHandle(paragraphDatas: ParagraphData[]) {
    const data: {
      beginTime: string;
      endTime: string;
      speaker: string;
      sentence: string;
    }[] = [];
    for (let i = 0; i < paragraphDatas.length; i++)
      for (let j = 0; j < paragraphDatas[i].sentences.length; j++) {
        const sentence = paragraphDatas[i].sentences[j];
        const { beginTime, endTime, speaker } = sentence;
        data.push({
          beginTime: formatSecondsToProgress((beginTime / 1000) | 0, true) + `.${beginTime % 1000}`,
          endTime: formatSecondsToProgress((endTime / 1000) | 0, true) + `.${endTime % 1000}`,
          speaker: speaker || '',
          sentence: sentence.sentence,
        });
      }

    return data;
  }

  function getData() {
    const paragraphData = noteGlobalDataRef.current.transcription.getParagraphDatas?.();
    const summaryContent = noteGlobalDataRef.current.summary.getSummaryContent?.();

    return {
      noMergeTranContent: NoMergeSpeakerHandle(paragraphData || []),
      mergeTranContent: mergeSpeakerHandle(paragraphData || []),
      summaryContent,
    };
  }

  const exportCopyHandler = {
    async Copy(opitons: { hasSummary: boolean; hasTran: boolean }) {
      const { noMergeTranContent, mergeTranContent, summaryContent } = getData();
      const tranContent = mergeTranContent;

      let text = '';
      if (summaryContent && opitons.hasSummary) {
        text += `${info2?.title}\n`;
        if (info2?.createTime)
          text += `${t('note.info.summary.creation_time')}:${new Date(info2.createTime).toLocaleDateString('zh-CN', {
            hour12: false,
          })}\n\n`;
        fields?.map((field) => {
          const content = summaryContent[field.name];
          if (!content) return;
          //@ts-ignore
          text += `\n${field.value}\n`;
          if (typeof content == 'string') {
            text += content + '\n';
          } else {
            let level_1_idx = 0;
            for (let i = 0; i < content.length; i++) {
              const item = content[i];
              if (typeof item == 'string') text += `${++level_1_idx}. ${content[i]}\n`;
              else {
                for (const txt of item) text += `\t${txt}\n`;
              }
            }
          }
        });

        text += '\n\n\n';
      }

      if (opitons.hasTran && tranContent) {
        text += `${t('note.info.summary.transcription')}\n`;

        tranContent.forEach((item) => {
          const time = `${item.beginTime.split('.')[0]} - ${item.endTime.split('.')[0]}`;

          text += `${time} ${(item.speaker || t('note.info.notSpeaker')) + ':'}\n` + item.sentence + '\n\n';
        });
      }

      const res = await copy(text);
      if (res) myMessage.success(t('note.info.summary.copied-successfully'), 2000);
      else myMessage.error(t('note.info.summary.copied-failed'), 2000);
    },
    export: {
      pdf(opitons: { hasSummary: boolean; hasTran: boolean }) {
        if (userInfo?.type != UserType.pro && !noteState.isPro) {
          setShowUpgradeTip(true);
          return;
        }
        const { noMergeTranContent, mergeTranContent, summaryContent } = getData();
        const tranContent = mergeTranContent;

        const docContent: TDocumentDefinitions['content'] = [];
        // docContent.push({
        //     image: hidockImg,
        //     fit: [100, 100],
        //     margin: [430, -20, 0, 0]
        // })

        docContent.push({
          text: info2?.title || '',
          fontSize: 26,
          bold: true,
        });

        if (info2?.createTime) {
          docContent.push({
            text: `${t('note.info.summary.creation_time')}: ${new Date(info2.createTime).toLocaleDateString('zh-CN', {
              hour12: false,
            })}\n`,
            fontSize: 16,
            margin: [0, 15, 0, 0],
          });
        }

        if (summaryContent && opitons.hasSummary) {
          fields?.map((field) => {
            const content = summaryContent[field.name];
            console.log('!!!content', content);
            if (!content) return;

            docContent.push({
              //@ts-ignore
              text: `${field.value}\n`,
              fontSize: 20,
              margin: [0, 15, 0, 0],
              bold: true,
            });

            if (typeof content == 'string') {
              docContent.push({
                text: content + '\n',
                margin: [0, 4, 0, 0],
              });
            } else {
              let idx = 1;
              for (let i = 0; i < content.length; i++) {
                const item = content[i];
                if (typeof item == 'string') {
                  docContent.push({
                    text: parseEmojiChar(`${idx}. ${content[i]}\n`).map((i) => {
                      if (i.isEmoji) {
                        return {
                          text: i.text,
                          font: 'NoteEmoji',
                        };
                      } else {
                        return i.text;
                      }
                    }),
                    margin: [4, 4, 0, 0],
                  });
                  idx++;
                } else {
                  let layerCount = 2;
                  let innerLayerCount = 0;
                  const handler = (item: any[]) => {
                    for (const d of item) {
                      if (typeof d == 'string') {
                        docContent.push({
                          text: parseEmojiChar(`${d}\n`).map((i) => {
                            if (i.isEmoji) {
                              return {
                                text: '·' + i.text,
                                font: 'NoteEmoji',
                              };
                            } else {
                              return '·' + i.text;
                            }
                          }),
                          margin: [Math.max(layerCount + innerLayerCount - 1, 1) * 16, 0, 0, 0],
                        });
                      } else {
                        innerLayerCount++;
                        handler(d);
                      }
                    }
                    innerLayerCount = Math.max(0, --innerLayerCount);
                  };
                  handler(item);
                }
              }
            }
          });
        }

        if (opitons.hasTran && tranContent) {
          let text = '';
          if (tranContent) {
            tranContent.forEach((item) => {
              const time = `${item.beginTime.split('.')[0]} - ${item.endTime.split('.')[0]}`;
              text += `${time} ${(item.speaker || t('note.info.notSpeaker')) + ':'}\n` + item.sentence + '\n\n';
            });
          }

          docContent.push({
            text: t('note.info.summary.transcription'),
            fontSize: 26,
            bold: true,
            margin: [0, 30, 0, 0],
          });
          docContent.push({
            text: text,
          });
        }

        setGlobalLoading(true);
        const sl = sleep(500);
        const define: TDocumentDefinitions = {
          content: docContent,
          defaultStyle: {
            font: 'NotoSansSC',
          },
        };
        pdfmark.createPdf(define).getBlob(async (blob) => {
          await sl;
          downloadFile(blob, `${info2?.title}.pdf`);
          setGlobalLoading(false);
        });
      },
      word(opitons: { hasSummary: boolean; hasTran: boolean }) {
        if (userInfo?.type != UserType.pro && !noteState.isPro) {
          setShowUpgradeTip(true);
          return;
        }
        const { noMergeTranContent, mergeTranContent, summaryContent } = getData();
        const tranContent = mergeTranContent;
        PizZipUils.getBinaryContent(`${locationHost()}/summaryTemplate.docx`, (error, content) => {
          if (error) {
            myMessage.error(error.message, 2000);
          }
          const zip = new PizZip(content);
          const doc = new DocxTemplater(zip, {
            paragraphLoop: true,
            linebreaks: true,
          });
          //生成word的相关功能
          const define: any = {};

          define.title = info2?.title || '';

          if (info2?.createTime) {
            define.createTime = `\n${t('note.info.summary.creation_time')}:${new Date(
              info2.createTime
            ).toLocaleDateString('zh-CN', {
              hour12: false,
            })}\n`;
          }

          if (opitons.hasSummary && summaryContent) {
            define.summary = [];
            //生成list内容
            function getList(list: (string | string[])[]) {
              let curIdx = 0;
              let itemIdx = 0;
              const data = [];
              while (curIdx < list.length) {
                const cur = list[curIdx];
                const next = list[curIdx + 1];
                const list2 = [];

                if (next instanceof Array) {
                  for (let i = 0; i < next.length; i++) {
                    list2.push({
                      idx: i + 1 + '',
                      item: next[i],
                    });
                  }
                  curIdx++;
                }

                const item = {
                  idx: ++itemIdx + '',
                  item: cur,
                  list2,
                };
                data.push(item);
                curIdx++;
              }
              return data;
            }

            fields?.forEach((field) => {
              const value = summaryContent[field.name];
              if (!value) return;
              const item: any = {
                //@ts-ignore
                title: field.value,
                list: false,
                prograph: '',
              };
              if (typeof value == 'string') {
                // item.prograph = i18n.t(`note.info.summary.${key}`) + '\n'
                item.prograph = value + '\n';
              } else {
                item.list = getList(value);
              }
              define.summary.push(item);
            });
          }

          if (opitons.hasTran && tranContent) {
            let text = '';
            if (tranContent) {
              tranContent.forEach((item) => {
                const time = `${item.beginTime.split('.')[0]} - ${item.endTime.split('.')[0]}`;
                text += `${time} ${(item.speaker || t('note.info.notSpeaker')) + ':'}\n` + item.sentence + '\n\n';
              });
            }

            define.transcription = define.transcription = t('note.info.summary.transcription');
            define.transcriptionText = text;
          } else {
            define.transcription = '';
            define.transcriptionText = '';
          }

          console.log('define', define);
          doc.render(define);

          const blob = doc.getZip().generate({
            type: 'blob',
            mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            // compression: DEFLATE adds a compression step.
            // For a 50MB output document, expect 500ms additional CPU time
            compression: 'DEFLATE',
          });
          downloadFile(blob, `${info2?.title}.docx`);
        });
      },
      txt(opitons: { hasSummary: boolean; hasTran: boolean }) {
        if (
          userInfo?.type != UserType.pro &&
          userInfo?.type != UserType.Membership &&
          userInfo?.type != UserType.kickstarter &&
          !noteState.isPro
        ) {
          setShowUpgradeTip(true);
          return;
        }
        const { noMergeTranContent, mergeTranContent, summaryContent } = getData();
        const tranContent = mergeTranContent;

        let text = `${info2?.title}\n`;
        if (info2?.createTime)
          text += `${t('note.info.summary.creation_time')}:${new Date(info2.createTime).toLocaleDateString('zh-CN', {
            hour12: false,
          })}\n\n`;
        if (summaryContent && opitons.hasSummary) {
          fields?.map((field) => {
            const content = summaryContent[field.name];
            if (!content) return;
            //@ts-ignore
            text += `\n${field.value}\n`;
            if (typeof content == 'string') {
              text += content + '\n';
            } else {
              let level_1_idx = 0;
              for (let i = 0; i < content.length; i++) {
                const item = content[i];
                if (typeof item == 'string') text += `${++level_1_idx}. ${content[i]}\n`;
                else {
                  for (const txt of item) text += `\t${txt}\n`;
                }
              }
            }
          });

          text += '\n\n\n';
        }

        if (opitons.hasTran && tranContent) {
          text += `${t('note.info.summary.transcription')}\n`;

          tranContent.forEach((item) => {
            const time = `${item.beginTime.split('.')[0]} - ${item.endTime.split('.')[0]}`;

            text += `${time} ${(item.speaker || t('note.info.notSpeaker')) + ':'}\n` + item.sentence + '\n\n';
          });
        }

        downloadFile(text, `${info2?.title}.txt`, 'text/plain;charset=utf-8');
      },
    },
  };

  function getMoreThanEl() {
    if (info2?.ownership != 'myself') return null;

    return (
      <MoreThan
        event={{
          copy: {
            transcript() {
              exportCopyHandler.Copy({
                hasTran: true,
                hasSummary: false,
              });
            },
            summary() {
              exportCopyHandler.Copy({
                hasTran: false,
                hasSummary: true,
              });
            },
          },
          exportSummary: {
            pdf() {
              exportCopyHandler.export.pdf({ hasSummary: true, hasTran: false });
            },
            word() {
              exportCopyHandler.export.word({
                hasSummary: true,
                hasTran: false,
              });
            },
          },
          exportTranscript: {
            text() {
              exportCopyHandler.export.txt({ hasSummary: false, hasTran: true });
            },
            csv() {
              if (userInfo?.type != UserType.pro && !noteState.isPro) {
                setShowUpgradeTip(true);
                return;
              }
              const data = getData().mergeTranContent;
              if (data) {
                let text = `"Timestamp","Speaker","Content"\n`;
                text += data
                  .map((item) => {
                    return `"${item.beginTime.split('.')[0]}","${item.speaker.replace(
                      '"',
                      '""'
                    )}","${item.sentence.replace('"', '""')}"`;
                  })
                  .join('\n');
                downloadFile(text, `${info2?.title}.csv`, 'text/plain;charset=utf-8');
              }
            },
            srt() {
              if (userInfo?.type != UserType.pro && !noteState.isPro) {
                setShowUpgradeTip(true);
                return;
              }
              const data = getData().mergeTranContent;
              if (data) {
                let text = data
                  .map((item, idx) => {
                    return `\n${idx + 1}\n${item.beginTime} --> ${item.endTime}\n${item.speaker}: ${item.sentence}\n`;
                  })
                  .join('');
                downloadFile(text, `${info2?.title}.srt`, 'text/plain;charset=utf-8');
              }
            },
          },
          exportAll: {
            text() {
              exportCopyHandler.export.txt({ hasSummary: true, hasTran: true });
            },
            pdf() {
              exportCopyHandler.export.pdf({ hasSummary: true, hasTran: true });
            },
            word() {
              if (userInfo?.type != UserType.pro && !noteState.isPro) {
                setShowUpgradeTip(true);
                return;
              }
              exportCopyHandler.export.word({ hasSummary: true, hasTran: true });
            },
          },
          export() {
            setShowExportModal(true);
          },
          async translate() {
            if (userInfo?.type != UserType.pro && !noteState.isPro) {
              setShowUpgradeTip(true);
              return;
            }
            setShowLangSelect(true);
          },
          delete() {
            setIsDeleteModalVisible(true);
          },
          cluster() {
            event.setCluster?.(state?.openCluster ? 'close' : 'open');
          },
        }}
        openCluster={!!state?.openCluster}
      />
    );
  }

  const noteBtns = (
    <div className="flex items-center text-white">
      {getMoreThanEl()}

      <SvgContainer
        className=" hover: mr-[31px] flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-transparent hover:bg-[#DEDEDE] "
        style={{ display: info2?.ownership == 'myself' ? '' : 'none' }}
        onClick={() => setIsShow(true)}
      >
        <ShareSvg />
      </SvgContainer>

      <SvgContainer
        className=" hover: mr-[33px] flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-transparent hover:bg-[#DEDEDE]"
        id="note-close-btn"
        onClick={() => {
          navigate(-1);
        }}
      >
        <CloseSvg />
      </SvgContainer>
    </div>
  );
  const noteTitle = (
    <div className=" relative w-full text-[22px] font-[600]">
      <div className="  flex w-full justify-center">
        <span className=" relative">
          <p
            className={` break-all rounded-[5px] border border-transparent p-[3px] pb-[13px] pt-[12px] text-[26px] font-[700] leading-[16.38px] text-black shadow-none outline-none focus-visible:border-[#686868] ${custom_dictionary_dom_class.paragraph}`}
            style={{
              outlineWidth: 1,
            }}
            contentEditable={info2?.ownership == 'myself'}
            suppressContentEditableWarning
            ref={titleElRef}
            onFocus={(e) => {
              e.currentTarget.setAttribute('oldval', title ?? 'untitled');
            }}
            onBlur={(e) => {
              console.log(preHeaderRef.current, e.currentTarget.innerText);
              if (preHeaderRef.current === e.currentTarget.innerText) return;
              preHeaderRef.current = e.currentTarget.innerText;
              const text = e.currentTarget.innerText;
              text.length > 0 && e.currentTarget.setAttribute('oldval', text);
              if (text.length <= 0 && e.currentTarget) {
                myMessage.warn(t('note.title.empty'), 3000);
                const oldVal = e.currentTarget.getAttribute('oldval') as string;
                e.currentTarget.innerText = oldVal;
                preHeaderRef.current = oldVal;
              }
              text.length && onBlur?.(text);
            }}
            onKeyDown={(e) => {
              if (e.key == 'Enter') {
                e.preventDefault();
                titleElRef.current?.blur();
              }
            }}
            id={custom_dictionary_dom_class.title}
          >
            {title}
          </p>
          <img
            src={editImg}
            className=" absolute left-[calc(100%+12px)] top-1/2 aspect-[26.4/31.24] w-[18px] -translate-y-1/2 cursor-pointer"
            onClick={() => {
              titleElRef.current?.focus();
            }}
          />
        </span>
      </div>
      <p className="text-center text-[16px] font-[500]">{subTitle}</p>
      <div className=" absolute right-0 top-1/2 z-20 -translate-y-1/2">{noteBtns}</div>
    </div>
  );

  const [isShow, setIsShow] = useState(false);
  const [speakListExpand, setSpeakListExpand] = useState(false);
  const { refetch: info2Refersh } = useAPIQuery(queryInfo2(noteId));
  useEffect(() => {
    const globalClickHandle = () => {
      setSpeakListExpand(false);
    };
    document.addEventListener('click', globalClickHandle);

    return () => {
      document.removeEventListener('click', globalClickHandle);
    };
  }, []);

  async function translateHandle(language: string) {
    let res = await noteTranslateReq({
      noteId,
      language: language,
    });
    //@ts-ignore
    if (res?.error) return;
    if (res == 'pending') {
      setSummaryState('retrying');
    }
    if (res == 'success') {
      info2Refersh();
    }
  }

  /**发言人相关头像 */
  const Speakers = (
    <div className="z-10 flex h-full items-center ">
      <div
        className="flex  flex-wrap rounded-[8px]"
        style={{
          backgroundColor: speakListExpand ? 'black' : '',
        }}
        onClick={function (e) {
          if (userInfo?.type != UserType.pro && !noteState.isPro) {
            setShowUpgradeTip(true);
            return;
          }
          if (speakListExpand && e.currentTarget == e.nativeEvent.target) {
            e.stopPropagation();
          }
        }}
      >
        {speakerList.map(({ speaker: name, color }, idx) => {
          return (
            <Avator
              name={name}
              color={color}
              key={name}
              speakerList={speakerList}
              isEditor={info2?.ownership == 'myself'}
              onHover={(name, state) => {
                const preName = preNameRef.current;
                if (state == false && preName == name) {
                  onHoverTarget?.('');
                } else if (state) {
                  onHoverTarget?.(name);
                }
                if (state) preNameRef.current = name;
              }}
              onChangeName={event.onChangeName}
            />
          );
        })}
      </div>
    </div>
  );

  const setShowUpgradeTip = useSetAtom(showUpgradeTipAtom);

  const shareExitHandle = useCallback(() => setIsShow(false), [setIsShow]);
  const [showLangSelect, setShowLangSelect] = useState(false);

  function getFileSelect() {
    const isShow = info2?.ownership == 'myself';

    if (!isShow) return <div className=" w-[20px]"></div>;

    return <FolderSelect className=" mr-[40px]" noteId={noteId} />;
  }

  function getTagSelect() {
    const isShow = info2?.ownership == 'myself';

    if (!isShow) return <div className=" w-[20px]"></div>;
    return (
      <div className=" flex w-[50%] items-center ">
        <p className=" mr-[20px] text-[20px] font-bold">Tags:</p>
        <TagSelect noteId={noteId} info2={info2} />
      </div>
    );
  }

  return (
    <>
      <div className=" relative z-10 h-full w-full pt-[15px]">
        {noteTitle}
        <div className=" mt-[10px] flex items-center">
          {getFileSelect()}
          {getTagSelect()}
          <div className=" flex-1" />
          <div className=" h-[48px]">{Speakers}</div>
          <div
            className=" mx-[33px] "
            style={
              info2?.ownership != 'myself'
                ? {
                    visibility: 'hidden',
                    pointerEvents: 'none',
                  }
                : undefined
            }
          >
            <Rating noteId={noteId} />
          </div>
        </div>
      </div>
      {showLangSelect && (
        <SelectLanguage
          onClose={() => setShowLangSelect(false)}
          onChange={({ value, label }) => {
            setShowLangSelect(false);
            translateHandle(value);
          }}
        />
      )}
      <ShareBox isShow={isShow} onExit={shareExitHandle} noteId={noteId} fields={fields} />
      {showExportModal && (
        <ExportModal
          speakerNoteDataRef={noteGlobalDataRef}
          info2={info2}
          onClose={() => setShowExportModal(false)}
          fields={fields}
        />
      )}
    </>
  );
}
