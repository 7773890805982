import { useMutation, useQueryClient } from '@tanstack/react-query';
import { request } from '~/lib/axios';
import { useMemo } from 'react';
import { API } from '..';
import { defineMutation } from '../helper';
import { reqParamsToBody } from '../utils';
import type { Tags } from './types';

export const deleteTag = defineMutation(async (tagId: string) => {
  const data = await request.post('/v1/tag/delete', reqParamsToBody({ id: tagId }));
  return data;
});

export const useDeleteTagMutation = () => {
  const queryClient = useQueryClient();
  const tagListQueryKey = useMemo(() => API.tags.list().key, []);

  return useMutation(deleteTag, {
    async onMutate(newTag) {
      await queryClient.cancelQueries(tagListQueryKey);
      const prevTags = queryClient.getQueryData<Tags>(tagListQueryKey);

      queryClient.setQueryData<Tags>(tagListQueryKey, (old) => {
        if (!old) return;

        return [...old].filter((tag) => tag.id !== newTag);
      });

      return {
        prevTags,
      };
    },
    onSettled() {
      queryClient.invalidateQueries(tagListQueryKey);
    },
    onError(_e, _, context) {
      const prevTags = context?.prevTags;
      prevTags && queryClient.setQueryData(tagListQueryKey, prevTags);
    },
  });
};
