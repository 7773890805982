import { IconReplay, IconX } from '@hidock/ui';
import { clsxm } from '@hidock/utils';
import { ProcessContainer } from '~/components/containers';
import { myMessage, MyToast } from '~/components/MyToast/MyToast';
import { ProcessingLabel } from '~/components/ProcessingBlock';
import shareBox from '~/components/shareBox/shareBox';
import { useMyNavigate } from '~/hooks/useNavigate';
import i18n from '~/i18n';
import { getAccessToken, useAccessToken, useMaxRecordTime } from '~/store/user';
import { generalErrorHandle } from '~/utils/utils';
import { useAtom } from 'jotai';
// import Recorder from 'js-audio-recorder'
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useHotkeys } from 'react-hotkeys-hook';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import { WebSocketLike, WebSocketMessage } from 'react-use-websocket/dist/lib/types';
import WaveSurfer from 'wavesurfer.js';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
import RecordPlugin from 'wavesurfer.js/dist/plugins/record';
import { curMicroAtom } from './PageRecord';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Recorder from './recorder/recorder';
import { formatSecondsToProgress } from './utils';

type Props = {
  onRestart?: () => void;
  onCancel?: () => void;
  onStop?: (ticket?: string) => void;
  onError?: () => void;
};

type CloseAction = {
  action: 'close';
  marks: number[];
};
type CancelAction = {
  action: 'cancel';
};

type SendAction = {
  action: 'send';
  data: any;
};

type SendMessageProps = (CloseAction | CancelAction | SendAction) & {
  sendedCallback?: Function;
};

export const Recording: FC<Props> = (props) => {
  const [hasPermission, setHasPermission] = useState(false);
  const navigate = useMyNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    (async function () {
      try {
        await Recorder.getPermission();
        setHasPermission(true);
      } catch (err) {
        myMessage.error(t('misc.no-recording-permission'), 2000);
        navigate(-1);
      }
    })();
  }, []);

  return useAccessToken() && hasPermission && <RecordingImpl {...props} />;
};
type InitMessageOption = {
  onCloseBefore?: () => void;
  onCancelBefore?: () => void;
};

const UseSendMessage = (
  sendMessage: (message: WebSocketMessage, keep?: boolean | undefined) => void,
  getWebSocket: () => WebSocketLike | null,
  option: InitMessageOption = {}
) => {
  const sendMessagePropsListRef = useRef<SendMessageProps[]>([]);
  const websocket = getWebSocket();
  if (websocket)
    websocket.onopen = () => {
      sendMessagePropsListRef.current = [];
    };
  const exe = () => {
    const SendMessagePropsList = sendMessagePropsListRef.current;

    while (SendMessagePropsList.length) {
      const info = SendMessagePropsList.shift()!;
      const { action, sendedCallback } = info;
      if (action == 'cancel') {
        option.onCancelBefore?.();
        sendMessage(JSON.stringify(info));
      } else if (action == 'send') {
        sendMessage(info.data);
      } else if (action == 'close') {
        option.onCloseBefore?.();
        sendMessage(JSON.stringify(info));
        getWebSocket()?.close();
      }
      sendedCallback?.();
      if (['cancel', 'close'].includes(action)) return;
    }
    setTimeout(exe, 200);
  };

  useEffect(() => {
    const timer = setTimeout(exe, 200);
    return () => {
      //退出前做队列的处理或者清空内容
      clearTimeout(timer);
    };
  }, []);

  return useCallback(
    (sendMessageProps: SendMessageProps) => {
      sendMessagePropsListRef.current.push(sendMessageProps);
    },
    [sendMessage, getWebSocket]
  );
};

function TipText() {
  const { t } = useTranslation();

  return (
    <>
      <div className=" mt-[42px] flex w-full justify-center  px-[10px] text-center text-[18px] font-[600] leading-[28px]">
        {t('user.record.tip1')}
      </div>
      <div className=" mb-[35px] mt-[10px] whitespace-pre-wrap  px-[10px] text-center text-[14px] font-[600] leading-[18px]">
        {t('user.record.tip2')}
      </div>
    </>
  );
}
const RecordingImpl: FC<Props> = ({ onRestart, onCancel, onStop, onError }) => {
  const { t } = useTranslation();
  const ticketRef = useRef('');
  const markRef = useRef<number[] | null>(null);
  const startedAtRef = useRef<number>(0);
  const [hasAudioPermission, setHasAudioPermission] = useState(false);
  const [curMicroId, setCurMicroId] = useAtom(curMicroAtom);
  const [preMicroId, setPreMicrroId] = useState('');
  const timeoutRef = useRef<any>();
  useEffect(() => {
    const wakeLock = navigator.wakeLock;
    if (!wakeLock) return;
    const lockPromise = wakeLock.request('screen');

    return () => {
      lockPromise.then((lock) => lock.release());
    };
  }, []);
  const { getWebSocket, sendMessage: sendMessageCore } = useWebSocket(
    `${import.meta.env.VITE_SOCKET_PROT}://${
      import.meta.env.VITE_WS_BASE_URL || ''
    }/v1/note/record?accesstoken=${getAccessToken()}&tzOffset=${new Date().getTimezoneOffset()}`,
    {
      // onOpen(event) {
      //   console.log('onOpen', event)
      // },
      async onMessage(event) {
        const ws = getWebSocket();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        ws && (ws.binaryType = 'arraybuffer');

        const data =
          (JSON.parse(event.data || {}) as {
            data?: string;
            error?: string;
            message?: string;
          }) || '';

        if (data.error) {
          //@ts-ignore
          generalErrorHandle(data, i18n.t);
          onError?.();
          return;
        }

        ticketRef.current = data.data || '';
        try {
          await Recorder.getPermission();
          startRecording();
          sendAudioData();
          setHasAudioPermission(true);
        } catch (e) {
          console.log(e);
        }
      },
      onError(event) {
        console.error('onError', event);
        myMessage.error(t('note.error.recording.webscoket.error'));
        onError?.();
      },
      onClose() {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
      },
    }
  );

  const sendMessage = UseSendMessage(sendMessageCore, getWebSocket, {
    onCancelBefore() {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    },
    onCloseBefore() {
      if (timeoutRef.current) clearTimeout(timeoutRef.current);
    },
  });

  const recorder = useMemo(
    () =>
      new Recorder({
        sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
        sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
        numChannels: 1, // 声道，支持 1 或 2， 默认是1
        compiling: true,
      }) as any,
    []
  );

  useEffect(() => {
    if (curMicroId && curMicroId != preMicroId) {
      recorder?.switchSource?.(curMicroId).then(() => {
        setPreMicrroId(curMicroId);
      });
    }
  }, [curMicroId, preMicroId]);

  const startRecording = useCallback(() => {
    recorder.start();
    setPreMicrroId('default');
    setCurMicroId('default');
    markRef.current = [];
    startedAtRef.current = Date.now();
  }, [recorder]);

  const stopRecording = useCallback(() => {
    try {
      recorder.stop();
      recorder.stopStream();
    } catch (err) {
      console.error(err);
      myMessage.error(t('misc.no-recording-permission'), 2000);
      markRef.current = null;
      onCancel?.();
      return;
    }
    recorder.destroy();
    sendMessage({
      action: 'close',
      marks: markRef.current || [],
      sendedCallback() {
        markRef.current = null;

        const ticket = ticketRef.current;

        ticket && onStop?.(ticket);
      },
    });
  }, [recorder, sendMessage, onStop, hasAudioPermission]);

  const cancelRecording = useCallback(() => {
    try {
      recorder.stop();
      recorder.stopStream();
    } catch (err) {
      console.error(err);
      myMessage.error(t('misc.no-recording-permission'), 2000);
    }
    let isRun = false;
    sendMessage({
      action: 'cancel',
      sendedCallback() {
        if (isRun) return;
        isRun = true;
        markRef.current = null;
        onCancel?.();
      },
    });
    //至少要200毫秒后才会发消息，所以这里暂时等发完消息以后再取消
    if (!hasAudioPermission) {
      setTimeout(() => {
        if (isRun) return;
        isRun = true;
        markRef.current = null;
        onCancel?.();
      }, 300);
    }
  }, [recorder, sendMessage, onCancel, hasAudioPermission]);

  const restartRecording = useCallback(() => {
    try {
      recorder.stop();
      recorder.stopStream();
    } catch (err) {
      console.error(err);
      myMessage.error(t('misc.no-recording-permission'), 2000);
    }
    sendMessage({
      action: 'cancel',
      sendedCallback() {
        // 重新开始
        onRestart?.();
      },
    });
  }, [onRestart, recorder, sendMessage, hasAudioPermission]);

  const sendAudioData = () => {
    const data = recorder.getNextData();
    for (let i = 0; i < data.length; i++) {
      sendMessage({
        action: 'send',
        data: data[i].buffer,
      });
    }
    timeoutRef.current = setTimeout(sendAudioData, 200);
  };

  useHotkeys('space', () => {
    if (Array.isArray(markRef.current)) {
      markRef.current.push(Date.now() - startedAtRef.current);
    }
  });

  return (
    <>
      <ProcessContainer.Root className={clsxm('mt-8 pt-[25px]')}>
        {hasAudioPermission && <Timer onDurationExceed={stopRecording} />}
        <Progress className="mt-[10px]" />
        <TipText />
        <Actions className="mt-auto" onCancel={cancelRecording} onStop={stopRecording} onRestart={restartRecording} />
      </ProcessContainer.Root>
      <div className=" flex-1" />
      <ProcessingLabel label="recording" />
    </>
  );
};

const Timer: FC<{ className?: string; onDurationExceed?: () => void }> = ({ className, onDurationExceed }) => {
  const [duration, setDuration] = useState(0);
  const timerRef = useRef<any>();
  const readyRef = useRef(true);
  const maxRecordTime = useMaxRecordTime();

  useEffect(() => {
    if (timerRef.current) return;
    readyRef.current = true;
    timerRef.current = setInterval(() => {
      // 组件已经被卸载了，不用更新了
      if (!readyRef.current) return;
      setDuration((value) => value + 1);
    }, 1000);

    return () => {
      clearInterval(timerRef.current);
      timerRef.current = null;
      readyRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (maxRecordTime && duration >= maxRecordTime) {
      onDurationExceed?.();
      clearInterval(timerRef.current);
    }
  }, [onDurationExceed, maxRecordTime, duration]);

  return (
    <div className={clsxm('text-center text-2xl font-bold', className)}>
      {maxRecordTime && formatSecondsToProgress(maxRecordTime - duration)}
    </div>
  );
};

const Progress: FC<{ className?: string }> = ({ className }) => {
  const waveRef = useRef<WaveSurfer | null>();
  const recordPluginRef = useRef<any>();
  useEffect(() => {
    const wavesurfer = WaveSurfer.create({
      container: '#wave',
      height: 64,
      barRadius: 8,
      barGap: 4,
      barWidth: 4,
      barHeight: 2,
      cursorColor: 'transparent',
      cursorWidth: 2,
      waveColor: '#fff',
      normalize: false,
    });

    waveRef.current = wavesurfer;

    const recordPlugin = wavesurfer.registerPlugin(RecordPlugin.create());
    recordPlugin.startRecording();
    recordPluginRef.current = recordPlugin;
    recordPlugin.stopRecording();
    recordPlugin.stopMic();

    return () => {
      wavesurfer.stop();
      wavesurfer.destroy();
      recordPlugin.stopRecording();
      recordPlugin.stopMic();
      recordPlugin.destroy();
      waveRef.current = null;
      recordPluginRef.current = null;
    };
  }, []);

  return (
    <div className={clsxm('relative overflow-hidden', className)}>
      <div id="wave" className={'h-16'}></div>
      <div className={clsxm('absolute inset-y-0 -left-7 z-50 w-48', 'from-primary bg-gradient-to-r from-15%')} />

      <div className={clsxm('absolute inset-y-0 -right-7 z-50 w-48', 'from-primary bg-gradient-to-l from-15%')} />
    </div>
  );
};

const Actions: FC<{
  className?: string;
  onCancel?: () => void;
  onStop?: () => void;
  onRestart?: () => void;
}> = ({ className, onCancel, onStop, onRestart }) => {
  return (
    <section className={clsxm('mx-3 mb-2.5 flex justify-between', className)}>
      <div onClick={onRestart}>
        <IconReplay className="aspect-square w-4 cursor-pointer" />
      </div>
      <div onClick={onCancel}>
        <IconX className="aspect-square w-4 cursor-pointer" />
      </div>
      <div
        className={clsxm(
          'absolute bottom-0 left-1/2 -translate-x-1/2 translate-y-1/2',
          'aspect-square w-16 rounded-full bg-red-400',
          'flex items-center justify-center',
          'shadow-[0px_3.5px_7.1px_0px_rgba(0,0,0,0.13),0.6px_0.6px_0.3px_0px_rgba(255,255,255,0.32)_inset,-0.6px_-0.6px_0.3px_0px_rgba(0,0,0,0.07)_inset]',
          'cursor-pointer'
        )}
        id="stop-record"
        onClick={onStop}
      >
        <div className="aspect-square w-[25px] rounded bg-white" />
      </div>
    </section>
  );
};
