import { useMutation } from '@tanstack/react-query';
import { request } from '~/lib/axios';
import { defineMutation } from '../helper';
import { reqParamsToBody } from '../utils';

type Params = {
  noteId: string;
  tagId: string;
};

export const assign = defineMutation(async (params: Params) => {
  const data = await request.post('/v1/note/assign', reqParamsToBody(params));
  return data;
});

export const useNoteTagAssignMutation = () => {
  // TODO 乐观更新笔记列表
  return useMutation(assign);
};
