import mitt from 'mitt';

type Events = {
  ['before-sign-in']: undefined;
  ['signed-in']: undefined;
  ['signed-in-with-google']: undefined;
  ['signed-in-with-email']: undefined;
};

export const emitter = mitt<Events>();
