export const IconMeeting = ({ className }: { className?: string }) => {
  return (
    <svg className={className} viewBox="0 0 16 16" fill="none">
      <path
        d="M11.7056 14.4382C11.7056 13.211 10.0473 12.2163 8.00244 12.2163C5.95753 12.2163 4.29923 13.211 4.29923 14.4382M14.6682 12.2163C14.6682 11.3053 13.7543 10.5225 12.4463 10.1795M1.33667 12.2163C1.33667 11.3053 2.25062 10.5225 3.55859 10.1795M12.4463 7.20661C12.6657 7.01263 12.8446 6.77719 12.9727 6.51382C13.1008 6.25045 13.1756 5.96435 13.1927 5.67197C13.2099 5.3796 13.1691 5.08673 13.0726 4.81019C12.9762 4.53366 12.826 4.27892 12.6307 4.06062C12.4355 3.84233 12.199 3.66479 11.9349 3.53822C11.6708 3.41165 11.3842 3.33854 11.0918 3.3231C10.7993 3.30766 10.5067 3.35019 10.2307 3.44824C9.95474 3.5463 9.70087 3.69795 9.48372 3.89447M3.55859 7.20661C3.12332 6.8127 2.86162 6.26254 2.83067 5.6763C2.79972 5.09006 3.00202 4.5154 3.3934 4.07784C3.78477 3.64028 4.33341 3.37539 4.91946 3.34103C5.5055 3.30668 6.08133 3.50564 6.52116 3.89447M8.00244 9.99439C7.71065 9.99439 7.42172 9.93691 7.15215 9.82525C6.88257 9.71359 6.63763 9.54992 6.4313 9.3436C6.22498 9.13728 6.06131 8.89233 5.94965 8.62276C5.83799 8.35318 5.78052 8.06425 5.78052 7.77246C5.78052 7.48068 5.83799 7.19175 5.94965 6.92217C6.06131 6.65259 6.22498 6.40765 6.4313 6.20133C6.63763 5.995 6.88257 5.83134 7.15215 5.71967C7.42172 5.60801 7.71065 5.55054 8.00244 5.55054C8.59173 5.55054 9.15688 5.78463 9.57358 6.20133C9.99027 6.61802 10.2244 7.18317 10.2244 7.77246C10.2244 8.36175 9.99027 8.92691 9.57358 9.3436C9.15688 9.76029 8.59173 9.99439 8.00244 9.99439Z"
        stroke="#00A2AF"
        strokeWidth="1.48128"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
