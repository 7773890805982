import { useEffect, useRef } from 'react';

export function useFocus(onClick: (state: 'focus' | 'blur') => void) {
  const timerRef = useRef<null | NodeJS.Timeout>(null);
  const onClickRef = useRef(onClick);
  onClickRef.current = onClick;

  useEffect(() => {
    const globalClick = () => {
      timerRef.current = setTimeout(() => {
        onClickRef.current('blur');
        timerRef.current = null;
      }, 80);
    };
    document.addEventListener('click', globalClick, true);

    return () => {
      document.removeEventListener('click', globalClick, true);
    };
  }, []);

  return (state: boolean = true) => {
    if (state) onClickRef.current('focus');
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      timerRef.current = null;
    }
  };
}
