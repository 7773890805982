import { deviceStatusRes } from '~/api/device/deviceStatus';
import { DeviceLatest } from '~/api/device/latest';
import { atom, getDefaultStore, useAtom } from 'jotai';
import { BtnState, DeviceConfigInfo, FileInfo } from './deviceType';

export const deviceInfoAtom = atom({
  version: '--',
  versionNumber: -1,
  sn: '',
});
export function useDeviceInfo() {
  return useAtom(deviceInfoAtom);
}

export const latestDeviceInfoAtom = atom<DeviceLatest | null>(null);

export const fileInfosAtom = atom<FileInfo[]>([]);
export function useFileInfos() {
  return useAtom(fileInfosAtom);
}

export const deviceConfigAtom = atom<DeviceConfigInfo>({
  autoRecord: false,
  recordNotice: false,
  autoUpload: false,
  webusbNotification: true,
  blueToothTone: false,
});

export function useDeviceConfigInfo() {
  return useAtom(deviceConfigAtom);
}

export const btnStateAtom = atom<BtnState>(BtnState.notConnection);

export const downloadQueueAtom = atom<{ file: FileInfo; progress: number }[]>([]);

export const deviceStatusAtom = atom<deviceStatusRes | null>(null);

export const cardInfoAtom = atom<{
  used: number;
  capacity: number;
  status: string;
} | null>(null);

export const pauseGetFileInfos = atom(false);

export const allowOptimizeAtom = atom(false);
