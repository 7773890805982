import { clsxm } from '@hidock/utils';
import { fileInfoReq, newNoteInfo } from '~/api/device/fileInfo';
import { sdOptimizeReq } from '~/api/device/sdOptimize';
import { templateReq } from '~/api/templateReq';
import { useMyNavigate } from '~/hooks/useNavigate';
import { formatSecondsToProgress } from '~/pages/record/utils';
import { downloadFile } from '~/utils/general';
import { sleep } from '~/utils/sleep';
import { EventLoop, generalErrorHandle, Logger, parseDoubleBracketSyntax, throttle } from '~/utils/utils';
import { useHover, useLatest, useRequest } from 'ahooks';
import { Tooltip } from 'antd';
import { getDefaultStore, useAtom, useSetAtom } from 'jotai';
import jszip from 'jszip';
import React, { CSSProperties, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { ClassValue } from 'tailwind-variants';
import { showConfirmAtom, useShowConfirm } from '../../deleteConfrim';
import { myMessage } from '../../MyToast/MyToast';
import { NoteListCardDataRefetchList } from '../../note';
import { Select, Select2 } from '../../userInfo/general';
import { DownLoad } from '../comp/Download';
import ConfigPage from '../ConfigPage';
import {
  allowOptimizeAtom,
  btnStateAtom,
  cardInfoAtom,
  deviceInfoAtom,
  downloadQueueAtom,
  fileInfosAtom,
  pauseGetFileInfos,
  useDeviceConfigInfo,
  useDeviceInfo,
} from '../deviceState';
import { BtnState, FileInfo, FileItemProps, FileItemRef } from '../deviceType';
import GuidePage from '../GuidePage';
import { DeviceOptimize } from '../Optimize';
import {
  additionalfileInfos,
  deviceTool,
  getDeviceFileArrayBuffer,
  getRecordingFileByFlieName,
  jensen,
} from '../utils';
import { CheckBox } from './comp';
import { FileItem, ShowFile } from './ShowFile';

export const uploadFileQueue = EventLoop();

export function getLanguageOptions(hasAuto: boolean = true) {
  const options = [
    {
      name: 'A',
      children: [
        {
          label: 'Auto',
          value: 'auto',
          show: hasAuto,
        },
        {
          label: 'Arabic',
          value: 'ar',
        },
      ],
    },
    {
      name: 'C',
      children: [
        {
          label: 'Simplified Chinese',
          value: 'zh',
        },
        {
          label: 'Traditional Chinese',
          value: 'hz',
        },
        {
          label: 'Czech',
          value: 'cs',
        },
      ],
    },
    {
      name: 'D',
      children: [
        {
          label: 'Danish',
          value: 'da',
        },
        {
          label: 'Dutch',
          value: 'nl',
        },
      ],
    },
    {
      name: 'E',
      children: [
        {
          label: 'English',
          value: 'en',
        },
      ],
    },
    {
      name: 'F',
      children: [
        {
          label: 'Finnish',
          value: 'fi',
        },
        {
          label: 'French',
          value: 'fr',
        },
      ],
    },
    {
      name: 'G',
      children: [
        {
          label: 'German',
          value: 'de',
        },
        {
          label: 'Greek',
          value: 'el',
        },
      ],
    },
    {
      name: 'I',
      children: [
        {
          label: 'Icelandic',
          value: 'is',
        },
        {
          label: 'Italian',
          value: 'it',
        },
      ],
    },
    {
      name: 'J',
      children: [
        {
          label: 'Japanese',
          value: 'ja',
        },
      ],
    },
    {
      name: 'K',
      children: [
        {
          label: 'Korean',
          value: 'ko',
        },
      ],
    },
    {
      name: 'N',
      children: [
        {
          label: 'Norwegian',
          value: 'no',
        },
      ],
    },
    {
      name: 'P',
      children: [
        {
          label: 'Polish',
          value: 'pl',
        },
        {
          label: 'Portuguese',
          value: 'pt',
        },
      ],
    },
    {
      name: 'S',
      children: [
        {
          label: 'Spanish',
          value: 'es',
        },
        {
          label: 'Swedish',
          value: 'sv',
        },
        {
          label: 'Slovak',
          value: 'sk',
        },
        {
          label: 'Slovenian',
          value: 'sl',
        },
      ],
    },
    {
      name: 'T',
      children: [
        {
          label: 'Thai',
          value: 'th',
        },
        {
          label: 'Turkish',
          value: 'tr',
        },
      ],
    },
  ];

  for (const opt of options) {
    opt.children = opt.children.filter((i) => i.show != false);
  }

  return options;
}

function DeviceManage() {
  const { t } = useTranslation();
  const [btnState, setBtnState] = useAtom(btnStateAtom);
  const filesInfosRefs = useRef<Record<string, FileItemRef>>({});
  const [deviceConfigInfo, setDeviceConfigInfo] = useDeviceConfigInfo();
  const [fileInfos, setFileInfos] = useAtom(fileInfosAtom);
  const fileInfoLatestRef = useRef(fileInfos);
  const [deviceInfo] = useDeviceInfo();
  const navigate = useMyNavigate();
  const location = useLocation();
  const isShow = location.pathname == '/notes/device';
  const isShowRef = useLatest(isShow);
  fileInfoLatestRef.current = fileInfos;
  const [showState, setShowState] = useState<'record' | 'config' | 'Guide'>('record');
  const [cardInfo, setCardInfo] = useAtom(cardInfoAtom);

  //设备管理界面
  useEffect(() => {
    if (BtnState.notConnection == btnState && isShow) {
      Logger.info('deviceManage', 'onConnection', btnState);
      navigate('/notes', { replace: true });
    }
  }, [btnState]);

  /**
   * 多个独立功能处理模块
   * 1. 打开页面要更新sd卡信息
   * 2. 查询是否有新音频
   * 3. 自动上传
   * 4. 更新音频的配置展示
   */

  //打开页面要更新sd卡信息
  useEffect(() => {
    if (btnState == BtnState.connected && isShow) {
      jensen.getCardInfo?.()?.then((res) => {
        if (res) setCardInfo(res);
      });
    }
  }, [btnState, isShow]);

  //轮询查看是否有新音频
  useEffect(() => {
    let isDestroy = false;
    let store = getDefaultStore();
    function getDeviceInfo() {
      return store.get(deviceInfoAtom);
    }
    /**负责设备文件的刷新处理 刷新方式是获取文件列表直接替换所有内容 */
    async function refreshFiles() {
      let infos = await jensen.listFiles(5);
      if (isDestroy) return;
      if (!infos) {
        await sleep(1000);
        refreshFiles();
        return;
      }
      //过滤掉非普通文件
      infos = infos.filter((i) => /(\d{14}REC\d{2}\.wav)|(.*\.hda)/.test(i.name));

      //检查是否有未知新文件出现
      let preFileInfos = fileInfoLatestRef.current;
      const fileInfomap = new Map<string, FileInfo>();
      for (let i = 0; i < preFileInfos.length; i++) fileInfomap.set(preFileInfos[i].signature, preFileInfos[i]);
      let newFileSigns: string[] = [];
      for (const info of infos) {
        const preFileInfo = fileInfomap.get(info.signature);
        if (!preFileInfo) newFileSigns.push(info.signature);
      }
      if (isDestroy) return;

      let newFileMoreInfo: newNoteInfo[] = [];
      if (newFileSigns.length) {
        newFileMoreInfo = await fileInfoReq.fileInfo({
          signatures: newFileSigns.join(','),
        });
      }

      jensen.getCardInfo?.()?.then((res) => {
        if (res) setCardInfo(res);
      });
      setFileInfos((preFileInfos) => {
        const fileInfos = infos.map((latestFile) => {
          const moreInfo = newFileMoreInfo.find((i) => i.signature == latestFile.signature);
          const preInfo = preFileInfos.find((i) => i.signature == latestFile.signature) || {};
          if (moreInfo?.noteId) {
            latestFile.id = moreInfo.noteId;
            latestFile.state = moreInfo.state;
            latestFile.title = moreInfo.title;
            latestFile.language = moreInfo.language;
            latestFile.template = moreInfo.template;
          }
          return {
            ...preInfo,
            ...latestFile,
          };
        });
        const newNoteSigns = fileInfos
          .filter((item) => newFileSigns.includes(item.signature) && item.id)
          .map((item) => item.signature);
        if (newFileMoreInfo.length) NoteListCardDataRefetchList(newNoteSigns);
        console.log('handled get files', fileInfos);
        //最后做排序
        return fileInfos.sort((a, b) => {
          return a.time.getTime() < b.time.getTime() ? 1 : -1;
        });
      });
    }
    if (btnState == BtnState.connected) {
      (async function () {
        const getfiles = () => store.get(fileInfosAtom);

        let preRecordFileName: string | undefined | null = getfiles().find((i) => i.signature == '0'.repeat(32))?.name;

        while (1) {
          let deviceInfo = getDeviceInfo();
          while (store.get(pauseGetFileInfos) || deviceInfo.versionNumber == -1) {
            await sleep(1000);
            deviceInfo = getDeviceInfo();
            preRecordFileName = getfiles().find((i) => i.signature == '0'.repeat(32))?.name;
            if (isDestroy) return;
          }

          if (getDeviceInfo().versionNumber > 327732) {
            const { recording, createDate, createTime } = (await jensen.getRecordingFile()) || {};
            if (preRecordFileName != recording) {
              if (isDestroy) return;

              if (recording) {
                setFileInfos((files) => {
                  const idx = files.findIndex((i) => i.signature == '0'.repeat(32));
                  if (idx != -1) files.splice(idx, 1);

                  return [getRecordingFileByFlieName(recording, createDate, createTime), ...files];
                });
              } else {
                await refreshFiles();
              }
            }
            preRecordFileName = recording;

            await sleep(2000);
          } else {
            refreshFiles();
            await sleep(2000);
            if (!isShowRef.current) await sleep(3000);
          }
        }
      })();
    }

    return () => {
      isDestroy = true;
    };
  }, [btnState]);

  //更新音频的配置展示
  useEffect(() => {
    if (showState == 'config' && btnState == BtnState.connected && deviceInfo.versionNumber >= 327714)
      jensen.getSettings().then((res) => {
        if (!res) return;
        setDeviceConfigInfo((state) => {
          return {
            ...state,
            ...res,
            blueToothTone: res.bluetoothTone,
            autoRecord: res.autoRecord,
            recordNotice: res.autoPlay,
          };
        });
      });
  }, [showState, btnState]);

  //自动上传 去除为0的音频
  useEffect(() => {
    if (deviceConfigInfo.autoUpload && btnState == BtnState.connected) {
      fileInfos
        .filter(
          (i) =>
            !i.isDelete &&
            !i.id &&
            i.updateState != 'failed' &&
            !i.uploading &&
            i.signature != '0'.repeat(32) &&
            i.duration &&
            !(i.state == 'deleted')
        )
        .forEach((item) => {
          const { onUpdate, setUploading } = filesInfosRefs.current[item.signature] || {};
          if (onUpdate) {
            setUploading(true);
            uploadFileQueue.addTask(onUpdate);
          }
        });
    }
  }, [deviceConfigInfo.autoUpload, fileInfos, btnState]);

  const HeaderEl = () => {
    const selectTabCss: CSSProperties = {
      borderColor: 'rgba(0, 162, 175, 1)',
      color: 'rgba(0, 162, 175, 1)',
      zIndex: 4,
    };
    const ItemCss: ClassValue =
      'mr-[10px] p-[0_10px] h-[60px] border-[rgba(217,217,217,1)] border-b-2 text-center text-[22px] font-[700] leading-[40px] cursor-pointer';
    return (
      <div className=" relative mt-[30px] flex w-full  justify-between" id="show-file-tab-bar">
        <div className="flex">
          <div
            id="recordings"
            className={clsxm(ItemCss, 'ml-[66px]')}
            style={showState == 'record' ? selectTabCss : {}}
            onClick={() => setShowState('record')}
          >
            {t('wu.recordings.title')}
          </div>
          <div
            id="configurations"
            className={ItemCss}
            style={showState == 'config' ? selectTabCss : {}}
            onClick={() => setShowState('config')}
          >
            {t('wu.configurations.title')}
          </div>

          <div
            className={ItemCss}
            style={showState == 'Guide' ? selectTabCss : {}}
            onClick={() => setShowState('Guide')}
          >
            {t('wu.guide.title')}
          </div>

          <div className="absolute bottom-0 z-[2] h-[2px] w-full border-[rgba(217,217,217,1)] bg-[#D9D9D9]"></div>
        </div>
        <div className="flex">
          <DownLoad />
          <div
            className=" z-20 mr-[30px] mt-[3px] flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-[50%] hover:bg-[#E8EAEA]"
            id="show-file-close"
            onClick={() => {
              setTimeout(() => {
                navigate(-1);
              });
            }}
          >
            <img src="/connectDevice/wrong.png" className="h-[16px] w-[16px]" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div
        className="fixed bottom-0 left-0 right-0 top-0 z-50 flex items-center justify-center bg-[rgba(0,0,0,0.3)]"
        style={{ display: isShow ? '' : 'none' }}
      >
        <div
          className="relative  box-border flex h-[690px] w-[1173px] origin-center flex-col overflow-hidden rounded-[30px] bg-white "
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {HeaderEl()}
          <div className=" h-[600px]">
            {<ShowFile fileInfosRef={filesInfosRefs} isShow={showState == 'record'} />}
            {showState == 'config' && <ConfigPage setBtnState={setBtnState} />}
            {showState == 'Guide' && <GuidePage />}
          </div>
        </div>
      </div>
    </>
  );
}

export default DeviceManage;
