import { request } from '~/lib/axios';
import { reqParamsToBody } from '../utils';

export type deviceStatusRes = {
  accessibility: 'denied' | 'read-only' | 'read-write';
  owner: null | string;
  ownership: 'new' | 'mine' | 'otherwise';
  name?: string;
  xsn?: string;
};

export function deviceStatusReq(params: { deviceSn: string }) {
  return request.post<deviceStatusRes>('/v1/user/device/status', reqParamsToBody(params));
}
