import { request } from '~/lib/axios';
import { reqParamsToBody } from '../utils';

export type PaymentReqParams = {
  sku: string;
  quantity: number;
  totalPrice: string;
  name: string;
  promotionId: string;
  giftQuota?: number;
};

export type PaymentInfo = {
  clientSecret: string;
  id: string;
};

export function PaymentReq(params: PaymentReqParams) {
  return request.post<PaymentInfo>('/v1/payment/create', reqParamsToBody(params));
}
