import type { InfiniteData } from '@tanstack/react-query';
import { API } from '~/api';
import { info2 } from '~/api/note2s/info2';
import { useNoteList } from '~/api/notes/list';
import type { Note } from '~/api/notes/types';
import type { ListData } from '~/api/types';
import i18n from '~/i18n';
import { queryClient } from '~/main';
import { noteStore } from '~/pages/notes/store';
import { sleep } from '~/utils/sleep';
import { motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { useEffect, type CSSProperties, type FC, type RefObject } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NoteListCard } from '.';
import { NoteTable } from './Table';
import { recentlyNoteRefresh } from '~/components/note/store.ts';

let globalRefetchList: Function | null = null;
let globalRefetchList2: Function | null = null;
export async function NoteListCardDataRefetchList(info: Note | string | string[]) {
  if (typeof info == 'string') {
    const id = info;
    while (1) {
      info = await info2({
        id,
      });
      if (['summarized', 'saved'].includes(info?.state)) break;
      await sleep(2000);
    }
  }
  globalRefetchList2?.();
}

export const NoteList: FC<{
  className?: string;
  style?: CSSProperties;
  onBottomObserverInit?: (containerRef: RefObject<HTMLElement>) => void;
}> = ({ className, style, onBottomObserverInit }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showType] = useAtom(noteStore.showType);
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isLoading,
    refetch: refetchList,
  } = useNoteList({
    pageSize: 10,
  });

  const [, setRecentlyNoteRefresh] = useAtom(recentlyNoteRefresh);

  const toRefetchList = async () => {
    setRecentlyNoteRefresh((num) => num + 1);
    await refetchList();
  };

  //触发笔记刷新的功能
  useEffect(() => {
    globalRefetchList2 = toRefetchList;
    globalRefetchList = async (notes: Note[]) => {
      await queryClient.cancelQueries({ queryKey: API.notes.list().rootKey });
      queryClient.setQueriesData<InfiniteData<ListData<Note>>>(API.notes.list().rootKey, (old) => {
        if (!old) return;
        const ids = notes.map((i) => i.id);
        const nextPages = old?.pages.map((page) => {
          const newPage: Note[] = [];
          page.content.forEach((note) => {
            if (ids.includes(note.id)) {
              const idx = notes.findIndex((i) => i.id === note.id)!;
              ids[idx] = '';
              newPage.push(notes[idx]);
            } else {
              newPage.push(note);
            }
          });
          return {
            ...page,
            content: newPage,
          };
        });

        const newNotes: Note[] = [];
        for (let i = 0; i < ids.length; i++)
          if (ids[i]) {
            newNotes.push(notes[i]);
          }

        if (!nextPages.length) refetchList();
        else {
          nextPages[0].content.splice(0, 0, ...newNotes);
        }
        return {
          ...old,
          pages: nextPages,
        };
      });
    };
    return () => {
      globalRefetchList = null;
      globalRefetchList2 = null;
    };
  }, [refetchList]);

  const containerRef = useBottomScrollListener(
    () => {
      if (isLoading || !hasNextPage) return;
      fetchNextPage();
    },
    { debounce: 500, offset: 200 }
  );

  useEffect(() => {
    onBottomObserverInit?.(containerRef);
  }, [containerRef, onBottomObserverInit]);

  useEffect(() => {
    const container = containerRef.current;
    if (container && container.scrollHeight <= container.clientHeight) {
      if (isLoading || !hasNextPage) return;
      fetchNextPage();
    }
  }, [containerRef, data?.pages.length]);

  const notes: Note[] = [];
  data?.pages.map((page) => {
    return page.content.map((note) => {
      return notes.push(note);
    });
  });

  const list1 = notes.map((note) => (
    <NoteListCard
      // className="min-h-[343px]"
      note={note}
      onClick={() => {
        navigate('/notes/' + note.id, {});
      }}
    />
  ));

  function getList2() {
    const map: Record<string, Note[]> = {};
    for (const note of notes) {
      const time = new Date(note.createTime).toLocaleString(i18n.language.slice(0, 2), {
        year: 'numeric',
        month: 'long',
      });
      if (!map[time]) map[time] = [];
      map[time].push(note);
    }

    return Object.entries(map).map(([time, notes], pos) => {
      return (
        <div key={time} className=" mt-[30px] w-full">
          <p className=" pl-[20px] text-[28px] font-bold leading-[48px] text-[#3D3D3D]">{time}</p>
          <NoteTable fixedHeader={false} notes={notes} key={time} showTitle={pos === 0} />
        </div>
      );
    });
  }

  if (!notes.length) {
    return (
      <div className=" flex h-full w-full flex-col items-center justify-center">
        <img src="/notes/empty-file.png" className=" h-[240px] w-[340px]" alt={t('user.note.notData')} />
        <p className="text-[24px] font-[700] text-[#939393]">{t('user.note.notData')}</p>
      </div>
    );
  }

  return showType === 'tiling' ? (
    <div className={className} style={style}>
      {list1}
    </div>
  ) : (
    getList2()
  );
};
