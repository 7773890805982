import { DiviceSettingKind } from '~/components/deviceConnection/deviceType';
import { request } from '~/lib/axios';
import { string } from 'zod';
import { reqParamsToBody } from '../utils';

type Params = {
  deviceSn: string;
  setting: DiviceSettingKind;
  value: string;
};

export function settingSaveReq(params: Params) {
  return request.post('/v2/device/setting/save', reqParamsToBody(params));
}
