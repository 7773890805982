import { clsxm } from '@hidock/utils';
import { Note2 } from '~/api/note2s/info2';
import { noteTemplate } from '~/api/templateReq.ts';
import { UserType } from '~/api/users/types.ts';
import { CheckBox } from '~/components/deviceConnection/ShowFile/comp';
import { MyModal } from '~/components/general/myModal';
import { showGlobalLoadingAtom } from '~/components/globalLoad.tsx';
import { myMessage } from '~/components/MyToast/MyToast';
import { useFocus } from '~/hooks/useFocus';
import i18n from '~/i18n';
import { showUpgradeTipAtom } from '~/layouts/RootLayout.tsx';
import { formatSecondsToProgress } from '~/pages/record/utils';
import { useUserInfo } from '~/store/user.ts';
import { downloadFile } from '~/utils/general';
import pdfmark from '~/utils/pdfmark.ts';
import { sleep } from '~/utils/sleep.ts';
import { locationHost, parseEmojiChar } from '~/utils/utils';
import DocxTemplater from 'docxtemplater';
import { useAtom, useSetAtom } from 'jotai';
import { TDocumentDefinitions } from 'pdfmake/interfaces';
import PizZip from 'pizzip';
import PizZipUils from 'pizzip/utils';
import { CSSProperties, MutableRefObject, ReactNode, SVGProps, useState } from 'react';
import { render } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'usehooks-ts';
import { boolean } from 'zod';
import { hidockImg } from '../hidock.ts';
import { noteStateAtom, SpeakerNoteGlobalData } from '../note-result2';
import { summaryKey } from '../SummaryEditor';
import { ParagraphData } from '../Transcription';

const Svg = {
  copy(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width="18.52941131591797"
        height="20.54265785217285"
        viewBox="0 0 18.52941131591797 20.54265785217285"
        {...props}
      >
        <g>
          <path
            d="M4.11765,4.10853L4.11765,1.02713C4.11765,0.459863,4.57853,0,5.14706,0L17.5,0C18.0685,0,18.5294,0.459863,18.5294,1.02713L18.5294,15.407C18.5294,15.9742,18.0685,16.4341,17.5,16.4341L14.4118,16.4341L14.4118,19.5155C14.4118,20.0825,13.9485,20.5426,13.3751,20.5426L1.03662,20.5426C0.465513,20.5461,0.000561055,20.0854,0,19.5155L0.00308826,5.13566C0.00308826,4.56868,0.466324,4.10853,1.03971,4.10853L4.11765,4.10853ZM6.17647,4.10853L14.4118,4.10853L14.4118,14.3798L16.4706,14.3798L16.4706,2.05426L6.17647,2.05426L6.17647,4.10853ZM4.11765,9.24419L4.11765,11.2985L10.2941,11.2985L10.2941,9.24419L4.11765,9.24419ZM4.11765,13.3527L4.11765,15.407L10.2941,15.407L10.2941,13.3527L4.11765,13.3527Z"
            fillOpacity="1"
          />
        </g>
      </svg>
    );
  },
  export(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width="16.676471710205078"
        height="18.488370895385742"
        viewBox="0 0 16.676471710205078 18.488370895385742"
        {...props}
      >
        <g>
          <path d="M12.0441,0L16.6765,4.62209L16.6765,17.5713C16.676,18.078,16.2642,18.4884,15.7565,18.4884L0.919985,18.4884C0.41372,18.4849,0.00403739,18.0765,0,17.5713L0,0.917023C0,0.410442,0.412279,0,0.919985,0L12.0441,0ZM8.33824,8.31977L4.63235,8.31977L4.63235,10.1686L8.33824,10.1686L8.33824,12.9419L12.0441,9.24419L8.33824,5.54651L8.33824,8.31977Z" />
        </g>
      </svg>
    );
  },
  uploadArrow(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width="10.000000149319622"
        height="6.681464418734848"
        viewBox="0 0 10.000000149319622 6.681464418734848"
        {...props}
      >
        <g transform="matrix(-2.2348338291067193e-8,-1,1,-2.2348338291067193e-8,-6.681464269415222,6.681464717374097)">
          <path
            d="M1.0775501493196222,16.681464418734848C0.7885121493196221,16.68077441873485,0.5114881493196222,16.577974418734847,0.3075721493196221,16.395634418734847C-0.0999998506803779,16.02517441873485,-0.0999998506803779,15.432214418734848,0.3075721493196221,15.061764418734848L4.093470149319622,11.688974418734848L0.3075721493196221,8.316184418734847C-0.10261085068037788,7.939224418734848,-0.10261085068037788,7.340212418734848,0.3075721493196221,6.963255418734848C0.7258491493196221,6.5875341187348475,1.4078301493196221,6.5875341187348475,1.8261101493196221,6.963255418734848L6.360580149319622,11.002974418734848C6.569450149319622,11.182264418734848,6.685640149319623,11.430624418734848,6.681460149319623,11.688974418734848C6.684860149319622,11.941644418734848,6.568150149319623,12.183834418734847,6.360580149319622,12.355904418734848L1.8261101493196221,16.395644418734847C1.6284601493196222,16.57511441873485,1.3595301493196221,16.67784441873485,1.0775501493196222,16.681464418734848Z"
            fill="#3A3A3A"
          />
        </g>
      </svg>
    );
  },
  duigou(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        version="1.1"
        width="16.97100257873535"
        height="12.020999908447266"
        viewBox="0 0 16.97100257873535 12.020999908447266"
        {...props}
      >
        <g>
          <path d="M6.364,9.193L15.556,0L16.971,1.414L6.364,12.021L0,5.657L1.414,4.243L6.364,9.193Z" fill="#3A3A3A" />
        </g>
      </svg>
    );
  },
};

function SelectItem(props: { value: boolean; onChange: (state: boolean) => void; text: string; marginLeft: number }) {
  const { marginLeft, value, onChange } = props;
  return (
    <div
      className=" flex h-[38px] cursor-pointer items-center rounded-[8px] px-[10px] hover:bg-[#E8E8E8]"
      onClick={() => onChange(!value)}
    >
      <div
        className=" mr-[10px] flex aspect-square w-[20px] items-center justify-center rounded-[3px] border-2 border-[#3A3A3A]"
        style={{ marginLeft }}
      >
        {value ? <Svg.duigou /> : ''}
      </div>
      <p className=" text-[16px] font-[600]">{props.text}</p>
    </div>
  );
}

export function ExportModal(props: {
  speakerNoteDataRef: MutableRefObject<SpeakerNoteGlobalData>;
  onClose?: () => void;
  fields?: noteTemplate.detail['fields'];
  info2: Note2 | null | undefined;
}) {
  const { speakerNoteDataRef, onClose, info2, fields } = props;
  const [_, copy] = useCopyToClipboard();
  const setGlobalLoading = useSetAtom(showGlobalLoadingAtom);
  const { data: userInfo } = useUserInfo();
  const [noteState] = useAtom(noteStateAtom);
  const setShowUpgradeTip = useSetAtom(showUpgradeTipAtom);

  function mergeSpeakerHandle(paragraphDatas: ParagraphData[]) {
    return paragraphDatas.map((item) => {
      return {
        beginTime: formatSecondsToProgress((item.beginTime / 1000) | 0, true) + `.${item.beginTime % 1000}`,
        endTime: formatSecondsToProgress((item.endTime / 1000) | 0, true) + `.${item.endTime % 1000}`,
        speaker: item.speaker,
        sentence: item.words.map((item) => item.word).join(''),
      };
    });
  }
  function NoMergeSpeakerHandle(paragraphDatas: ParagraphData[]) {
    const data: {
      beginTime: string;
      endTime: string;
      speaker: string;
      sentence: string;
    }[] = [];
    for (let i = 0; i < paragraphDatas.length; i++)
      for (let j = 0; j < paragraphDatas[i].sentences.length; j++) {
        const sentence = paragraphDatas[i].sentences[j];
        const { beginTime, endTime, speaker } = sentence;
        data.push({
          beginTime: formatSecondsToProgress((beginTime / 1000) | 0, true) + `.${beginTime % 1000}`,
          endTime: formatSecondsToProgress((endTime / 1000) | 0, true) + `.${endTime % 1000}`,
          speaker: speaker || '',
          sentence: sentence.sentence,
        });
      }

    return data;
  }

  function getData() {
    const paragraphData = speakerNoteDataRef.current.transcription.getParagraphDatas?.();
    const summaryContent = speakerNoteDataRef.current.summary.getSummaryContent?.();

    return {
      noMergeTranContent: NoMergeSpeakerHandle(paragraphData || []),
      mergeTranContent: mergeSpeakerHandle(paragraphData || []),
      summaryContent,
    };
  }

  const { t } = useTranslation();

  const [showExportType, setShowExportType] = useState(false);
  const targetClick = useFocus((state) => {
    setShowExportType(state == 'focus');
  });

  //最多两层
  const [options, setOptions] = useState({
    transcription: {
      text: t('note.info.export.transcript'),
      select: true,
      children: {
        timestampes: {
          select: true,
          text: t('note.info.export.transcript.timestampes'),
        },
        speakers: {
          select: true,
          text: t('note.info.export.transcript.speakers'),
        },
        mergeBySpeaker: {
          select: true,
          text: t('note.info.export.transcript.mergeBySpeaker'),
        },
      },
    },
    summary: {
      text: t('note.info.export.summary'),
      select: true,
    },
  });

  const event = {
    async Copy() {
      const { noMergeTranContent, mergeTranContent, summaryContent } = getData();
      const tranSelect = options.transcription.children;
      const tranContent = tranSelect.mergeBySpeaker.select ? mergeTranContent : noMergeTranContent;

      let text = `${info2?.title}\n`;
      if (info2?.createTime)
        text += `${t('note.info.summary.creation_time')}:${new Date(info2.createTime).toLocaleDateString('zh-CN', {
          hour12: false,
        })}\n\n`;
      if (summaryContent && options.summary.select) {
        fields?.map((field) => {
          const content = summaryContent[field.name];
          if (!content) return;
          //@ts-ignore
          text += `\n${field.value}\n`;
          if (typeof content == 'string') {
            text += content + '\n';
          } else {
            let level_1_idx = 0;
            for (let i = 0; i < content.length; i++) {
              const item = content[i];
              if (typeof item == 'string') text += `${++level_1_idx}. ${content[i]}\n`;
              else {
                for (const txt of item) text += `\t${txt}\n`;
              }
            }
          }
        });

        text += '\n\n\n';
      }

      if (options.transcription.select && tranContent) {
        text += `${t('note.info.summary.transcription')}\n`;

        tranContent.forEach((item) => {
          const time = `${item.beginTime.split('.')[0]} - ${item.endTime.split('.')[0]}`;

          text +=
            `${tranSelect.timestampes.select ? time + ' ' : ''}${
              tranSelect.speakers.select ? (item.speaker || t('note.info.notSpeaker')) + ':' : ''
            }\n` +
            item.sentence +
            '\n\n';
        });
      }

      const res = await copy(text);
      if (res) myMessage.success(t('note.info.summary.copied-successfully'), 2000);
      else myMessage.error(t('note.info.summary.copied-failed'), 2000);
    },
    export: {
      pdf() {
        if (userInfo?.type != UserType.pro && !noteState.isPro) {
          setShowUpgradeTip(true);
          return;
        }
        const { noMergeTranContent, mergeTranContent, summaryContent } = getData();
        const tranSelect = options.transcription.children;
        const tranContent = tranSelect.mergeBySpeaker.select ? mergeTranContent : noMergeTranContent;

        const docContent: TDocumentDefinitions['content'] = [];
        // docContent.push({
        //     image: hidockImg,
        //     fit: [100, 100],
        //     margin: [430, -20, 0, 0]
        // })

        docContent.push({
          text: info2?.title || '',
          fontSize: 26,
          bold: true,
        });

        if (info2?.createTime) {
          docContent.push({
            text: `${t('note.info.summary.creation_time')}: ${new Date(info2.createTime).toLocaleDateString('zh-CN', {
              hour12: false,
            })}\n`,
            fontSize: 16,
            margin: [0, 15, 0, 0],
          });
        }

        if (summaryContent && options.summary.select) {
          fields?.map((field) => {
            const content = summaryContent[field.name];
            if (!content) return;

            docContent.push({
              //@ts-ignore
              text: `${field.value}\n`,
              fontSize: 20,
              margin: [0, 15, 0, 0],
              bold: true,
            });

            if (typeof content == 'string') {
              docContent.push({
                text: content + '\n',
                margin: [0, 4, 0, 0],
              });
            } else {
              let idx = 1;
              for (let i = 0; i < content.length; i++) {
                const item = content[i];
                if (typeof item == 'string') {
                  docContent.push({
                    text: parseEmojiChar(`${idx}. ${content[i]}\n`).map((i) => {
                      if (i.isEmoji) {
                        return {
                          text: i.text,
                          font: 'NoteEmoji',
                        };
                      } else {
                        return i.text;
                      }
                    }),
                    margin: [4, 4, 0, 0],
                  });
                  idx++;
                } else {
                  let layerCount = 2;
                  let innerLayerCount = 0;
                  const handler = (item: any[]) => {
                    for (const d of item) {
                      if (typeof d == 'string') {
                        docContent.push({
                          text: parseEmojiChar(`${d}\n`).map((i) => {
                            if (i.isEmoji) {
                              return {
                                text: '·' + i.text,
                                font: 'NoteEmoji',
                              };
                            } else {
                              return '·' + i.text;
                            }
                          }),
                          margin: [Math.max(layerCount + innerLayerCount - 1, 1) * 16, 0, 0, 0],
                        });
                      } else {
                        innerLayerCount++;
                        handler(d);
                      }
                    }
                    innerLayerCount = Math.max(0, --innerLayerCount);
                  };
                  handler(item);
                }
              }
            }
          });
        }

        if (options.transcription.select && tranContent) {
          let text = '';
          if (tranContent) {
            tranContent.forEach((item) => {
              const time = `${item.beginTime.split('.')[0]} - ${item.endTime.split('.')[0]}`;
              text +=
                `${tranSelect.timestampes.select ? time + ' ' : ''}${
                  tranSelect.speakers.select ? (item.speaker || t('note.info.notSpeaker')) + ':' : ''
                }\n` +
                item.sentence +
                '\n\n';
            });
          }

          docContent.push({
            text: t('note.info.summary.transcription'),
            fontSize: 26,
            bold: true,
            margin: [0, 30, 0, 0],
          });
          docContent.push({
            text: text,
          });
        }

        setGlobalLoading(true);
        const sl = sleep(500);
        const define: TDocumentDefinitions = {
          content: docContent,
          defaultStyle: {
            font: 'NotoSansSC',
          },
        };
        pdfmark.createPdf(define).getBlob(async (blob) => {
          await sl;
          downloadFile(blob, `${info2?.title}.pdf`);
          setGlobalLoading(false);
        });
      },
      word() {
        if (userInfo?.type != UserType.pro && !noteState.isPro) {
          setShowUpgradeTip(true);
          return;
        }
        const { noMergeTranContent, mergeTranContent, summaryContent } = getData();
        const tranSelect = options.transcription.children;
        const tranContent = tranSelect.mergeBySpeaker.select ? mergeTranContent : noMergeTranContent;
        PizZipUils.getBinaryContent(`${locationHost()}/summaryTemplate.docx`, (error, content) => {
          if (error) {
            myMessage.error(error.message, 2000);
          }
          const zip = new PizZip(content);
          const doc = new DocxTemplater(zip, {
            paragraphLoop: true,
            linebreaks: true,
          });
          //生成word的相关功能
          const define: any = {};

          define.title = info2?.title || '';

          if (info2?.createTime) {
            define.createTime = `\n${t('note.info.summary.creation_time')}:${new Date(
              info2.createTime
            ).toLocaleDateString('zh-CN', {
              hour12: false,
            })}\n`;
          }

          if (options.summary.select && summaryContent) {
            define.summary = [];
            //生成list内容
            function getList(list: (string | string[])[]) {
              let curIdx = 0;
              let itemIdx = 0;
              const data = [];
              while (curIdx < list.length) {
                const cur = list[curIdx];
                const next = list[curIdx + 1];
                const list2 = [];

                if (next instanceof Array) {
                  for (let i = 0; i < next.length; i++) {
                    list2.push({
                      idx: i + 1 + '',
                      item: next[i],
                    });
                  }
                  curIdx++;
                }

                const item = {
                  idx: ++itemIdx + '',
                  item: cur,
                  list2,
                };
                data.push(item);
                curIdx++;
              }
              return data;
            }

            fields?.forEach((field) => {
              const value = summaryContent[field.name];
              if (!value) return;
              const item: any = {
                //@ts-ignore
                title: field.value,
                list: false,
                prograph: '',
              };
              if (typeof value == 'string') {
                // item.prograph = i18n.t(`note.info.summary.${key}`) + '\n'
                item.prograph = value + '\n';
              } else {
                item.list = getList(value);
              }
              define.summary.push(item);
            });
          }

          if (options.transcription.select && tranContent) {
            let text = '';
            if (tranContent) {
              tranContent.forEach((item) => {
                const time = `${item.beginTime.split('.')[0]} - ${item.endTime.split('.')[0]}`;
                text +=
                  `${tranSelect.timestampes.select ? time + ' ' : ''}${
                    tranSelect.speakers.select ? (item.speaker || t('note.info.notSpeaker')) + ':' : ''
                  }\n` +
                  item.sentence +
                  '\n\n';
              });
            }

            define.transcription = t('note.info.summary.transcription');
            define.transcriptionText = text;
          } else {
            define.transcription = '';
            define.transcriptionText = '';
          }

          console.log('define', define);
          doc.render(define);

          const blob = doc.getZip().generate({
            type: 'blob',
            mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            // compression: DEFLATE adds a compression step.
            // For a 50MB output document, expect 500ms additional CPU time
            compression: 'DEFLATE',
          });
          downloadFile(blob, `${info2?.title}.docx`);
        });
      },
      txt() {
        if (
          userInfo?.type != UserType.pro &&
          userInfo?.type != UserType.Membership &&
          userInfo?.type != UserType.kickstarter &&
          !noteState.isPro
        ) {
          setShowUpgradeTip(true);
          return;
        }
        const { noMergeTranContent, mergeTranContent, summaryContent } = getData();
        const tranSelect = options.transcription.children;
        const tranContent = tranSelect.mergeBySpeaker.select ? mergeTranContent : noMergeTranContent;

        let text = `${info2?.title}\n`;
        if (info2?.createTime)
          text += `${t('note.info.summary.creation_time')}:${new Date(info2.createTime).toLocaleDateString('zh-CN', {
            hour12: false,
          })}\n\n`;
        if (summaryContent && options.summary.select) {
          fields?.map((field) => {
            const content = summaryContent[field.name];
            if (!content) return;
            //@ts-ignore
            text += `\n${field.value}\n`;
            if (typeof content == 'string') {
              text += content + '\n';
            } else {
              let level_1_idx = 0;
              for (let i = 0; i < content.length; i++) {
                const item = content[i];
                if (typeof item == 'string') text += `${++level_1_idx}. ${content[i]}\n`;
                else {
                  for (const txt of item) text += `\t${txt}\n`;
                }
              }
            }
          });

          text += '\n\n\n';
        }

        if (options.transcription.select && tranContent) {
          text += `${t('note.info.summary.transcription')}\n`;

          tranContent.forEach((item) => {
            const time = `${item.beginTime.split('.')[0]} - ${item.endTime.split('.')[0]}`;

            text +=
              `${tranSelect.timestampes.select ? time + ' ' : ''}${
                tranSelect.speakers.select ? (item.speaker || t('note.info.notSpeaker')) + ':' : ''
              }\n` +
              item.sentence +
              '\n\n';
          });
        }

        downloadFile(text, `${info2?.title}.txt`, 'text/plain;charset=utf-8');
      },
    },
  };

  function sideBar() {
    const update = () => setOptions({ ...options });
    const doms: ReactNode[] = [];
    for (let data of Object.values(options)) {
      //@ts-ignore
      if (!data?.children) {
        doms.push(
          <SelectItem
            //@ts-ignore
            value={data.select}
            onChange={(state) => {
              data.select = state;
              update();
            }}
            text={data.text}
            marginLeft={13}
          />
        );
      } else {
        //@ts-ignore
        const arr: any[] = Object.values(data.children);

        doms.push(
          <SelectItem
            //@ts-ignore
            value={data.select}
            onChange={(state) => {
              data.select = state;
              arr.forEach((i) => (i.select = state));
              update();
            }}
            text={data.text}
            marginLeft={13}
          />
        );

        for (const d of arr) {
          doms.push(
            <SelectItem
              //@ts-ignore
              value={d.select}
              onChange={(state) => {
                if (state) data.select = true;
                d.select = state;
                update();
              }}
              text={d.text}
              marginLeft={43}
            />
          );
        }
      }
    }

    return (
      <div className=" mr-[20px] flex min-w-[231px] flex-col">
        <h1 className=" mb-[30px] text-[20px] font-bold text-[#3A3A3A]">{t('note.info.export.title')}</h1>
        {doms}
        <div className=" flex-1" />
        <div
          className=" flex h-[38px] cursor-pointer items-center rounded-[8px] fill-[#686868] pl-[13px] text-[14px] font-[600] text-[#686868] hover:bg-[#E8E8E8] hover:fill-[#3A3A3A] hover:text-[#3A3A3A]"
          onClick={() => event.Copy()}
        >
          <Svg.copy className=" mr-[12px]" />
          <p>{t('note.info.export.copy')}</p>
        </div>
        <div
          className=" relative flex h-[38px] cursor-pointer items-center rounded-[8px] fill-[#686868] pl-[13px] text-[14px] font-[600] text-[#686868] hover:bg-[#E8E8E8] hover:fill-[#3A3A3A] hover:text-[#3A3A3A]"
          onClick={() => {
            !showExportType && targetClick();
          }}
        >
          <Svg.export className=" mr-[12px] " />
          <p>{t('note.info.export.export')}</p>
          <Svg.uploadArrow className=" absolute right-[10px] top-1/2 -translate-y-1/2 " />

          {showExportType && (
            <div className=" absolute bottom-full right-0 h-[112px] w-[102px] rounded-[12px] bg-white p-[10px_6px] text-[16px] font-[600] shadow-[0_0_5px_rgba(0,0,0,0.2)]">
              <div
                className=" flex h-[32px] w-[90px] items-center rounded-[8px] p-[8px_5px] hover:bg-[#E8E8E8]"
                onClick={() => {
                  event.export.txt();
                }}
              >
                TXT
              </div>
              <div
                className=" flex h-[32px] w-[90px] items-center rounded-[8px] p-[8px_5px] hover:bg-[#E8E8E8]"
                onClick={() => {
                  event.export.word();
                }}
              >
                WORD
              </div>
              <div
                className=" flex h-[32px] w-[90px] items-center rounded-[8px] p-[8px_5px] hover:bg-[#E8E8E8]"
                onClick={() => {
                  event.export.pdf();
                }}
              >
                PDF
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }

  function content() {
    const { noMergeTranContent, mergeTranContent, summaryContent } = getData();
    const tranSelect = options.transcription.children;
    const tranContent = tranSelect.mergeBySpeaker.select ? mergeTranContent : noMergeTranContent;

    function renderList(title: string, data: (string | string[])[]) {
      return (
        <>
          <h2 className=" mt-[14px] text-[16px] font-bold text-[#3A3A3A]">{title}</h2>
          <ol className=" ml-[13px] list-decimal">
            {data.map((item) => {
              if (item instanceof Array) {
                return (
                  <ul className=" ml-[13px] list-disc">
                    {item.map((itemItem) => {
                      return <li className="mt-[10px] text-[14px]">{itemItem}</li>;
                    })}
                  </ul>
                );
              }

              return <li className="mt-[10px] text-[14px]"> {item}</li>;
            })}
          </ol>
        </>
      );
    }

    function renderContent(title: string, data: string) {
      return (
        <>
          <h2 className=" mt-[14px] text-[16px] font-bold text-[#3A3A3A]">{title}</h2>
          <p className=" my-[10px] text-[14px]">{data}</p>
        </>
      );
    }

    function renderTranscription() {
      return (
        <>
          <h2 className=" mt-[10px] text-[16px] font-bold text-[#3A3A3A]">{t('note.info.summary.transcription')}</h2>
          <p className={clsxm('my-[10px]')}>
            {tranContent.slice(0, 40).map((item) => {
              const time = `${item.beginTime.split('.')[0]} - ${item.endTime.split('.')[0]}`;

              return (
                <div className=" mb-[15px]">
                  <p className=" text-[13px] font-bold">{`${tranSelect.timestampes.select ? time : ''} ${
                    tranSelect.speakers.select ? (item.speaker || t('note.info.notSpeaker')) + ':' : ''
                  }`}</p>
                  <p className=" text-[14px]">{item.sentence}</p>
                </div>
              );
            })}
          </p>
        </>
      );
    }

    //"scrollbar  overflow-x-hidden overflow-y-scroll scrollbar-thumb-gray-500 scrollbar-track-gray-200"
    return (
      <div
        className={clsxm(
          ' h-full w-[661px] flex-1 rounded-[8px] bg-white',
          'scrollbar scrollbar-thumb-gray-500  scrollbar-track-gray-200 overflow-auto overflow-x-hidden overflow-y-scroll'
        )}
      >
        <h1 className=" p-[16px] text-[16px] font-[600] text-[#3D3D3D]">{t('note.info.export.output-preview')}</h1>
        <div className=" h-[1px] w-full bg-[#D8D8D8]" />
        <div className=" flex-1 overflow-hidden py-[10px] pl-[30px] pr-[42px]">
          <h1 className=" text-[18px] font-[600] text-[#3A3A3A]">{summaryContent?.title}</h1>
          {info2?.createTime && (
            <p className=" whitespace-pre text-[14px]">{`${t('note.info.summary.creation_time')}:${new Date(
              info2.createTime
            ).toLocaleDateString('zh-CN', {
              hour12: false,
            })}\n\n`}</p>
          )}
          {summaryContent && options.summary.select && (
            <div className=" mb-[50px]">
              {fields?.map((field) => {
                const data = summaryContent[field.name];
                //@ts-ignore
                const title = `${field.value}\n`;
                if (!data) return;
                if (data instanceof Array) return renderList(title, data);
                else return renderContent(title, data);
              })}
            </div>
          )}

          {options.transcription.select && renderTranscription()}
        </div>
      </div>
    );
  }

  return (
    <MyModal
      className=" flex h-[645px] rounded-[8px] bg-[rgb(244,244,244)] px-[30px] pb-[36px] pt-[65px]"
      onClose={() => onClose?.()}
    >
      {sideBar()}
      {content()}
    </MyModal>
  );
}
