import { useEffect } from 'react';

export const PageFAQ = () => {
  useEffect(() => window.scrollTo({ left: 0, top: 0 }), []);

  return (
    <>
      <h3 className="text-solid mb-[58px] text-center text-lg font-[900]">FAQ</h3>
      <section>
        <h2>1. Is the service free or paid?</h2>
        <p>
          A: We have free version for HiDock Membership users to use. Paid version will be more powerful. Currently our
          service is in Beta, so we have not finalized the service tier. Please stay tuned for future updates.
        </p>
      </section>
      <section>
        <h2>2. How many languages does it support?</h2>
        <p>
          A: We are in beta phase. Currently we support 8 languages. They are: English, French, German, Hebrew, Italian,
          Mandarin Chinese, Portuguese, Spanish
        </p>
        <p>
          In the future we will integrate more language support, please email us on your language requirements:
          <a href="mailto:support@hidock.com">support@hidock.com</a>
        </p>
        <p>
          [total 57 Languages, they are: Afrikaans, Arabic, Armenian, Azerbaijani, Belarusian, Bosnian, Bulgarian,
          Catalan, Chinese, Croatian, Czech, Danish, Dutch, English, Estonian, Finnish, French, Galician, German, Greek,
          Hebrew, Hindi, Hungarian, Icelandic, Indonesian, Italian, Japanese, Kannada, Kazakh, Korean, Latvian,
          Lithuanian, Macedonian, Malay, Marathi, Maori, Nepali, Norwegian, Persian, Polish, Portuguese, Romanian,
          Russian, Serbian, Slovak, Slovenian, Spanish, Swahili, Swedish, Tagalog, Tamil, Thai, Turkish, Ukrainian,
          Urdu, Vietnamese, and Welsh.]
        </p>
      </section>
      <section>
        <h2>3. How do you protect my data security?</h2>
        <p>A: Brief list our architecture, HTTPS, Azure Cloud security</p>
      </section>
      <section>
        <h2>4. Is your service GDPR compatible?</h2>
        <p>A: Yes, our service is GDPR compatible. You can delete all your data and opt out your account.</p>
      </section>
      <section>
        <h2>5. Do you use ChatGPT?</h2>
        <p>
          A: ChatGPT is an application developed by OpenAI. We do not use ChatGPT directly. We use the AI service from
          Microsoft Azure Cloud.
        </p>
      </section>
    </>
  );
};
