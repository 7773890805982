import { clsxm } from '@hidock/utils';
import { useQuery } from '@tanstack/react-query';
import { Folder, folderReq } from '~/api/folder';
import { getNoteInfoQueryParmas } from '~/api/note2s/info2';
import { useFocus } from '~/hooks/useFocus';
import { queryClient } from '~/main';
import { generalErrorHandle } from '~/utils/utils';
import { useHover, useMount, useRequest } from 'ahooks';
import { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputModify } from '../../../pages/notes/Header/general';
import { TabBar } from '../../general/TabBar';
import Svg from './svg';

//菜单容器
function ContextMenu(props: { children: React.ReactNode | React.ReactNode[] }) {
  const { children } = props;
  return <div className=" min-w-[100%] rounded-[8px] shadow-[0px_0px_30px_0px,rgba(0,0,0,0.3)]">{children}</div>;
}

//分割线UI
function Divider() {
  return <div className=" my-[4px] h-[1px] bg-[#DDDDE1]" />;
}

type MenuItemProps = {
  subMenu?: React.ReactNode;
  title: string;
  imgSrc?: string;
  hoverImgSrc?: string;
  onClick?: () => void;
};

//菜单元素
function MenuItem(props: MenuItemProps) {
  let { subMenu, title, imgSrc, hoverImgSrc, onClick } = props;
  hoverImgSrc = hoverImgSrc || imgSrc;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isHover = useHover(containerRef);

  return (
    <div className=" relative font-[600]" ref={containerRef}>
      <div
        className=" flex h-[40px] cursor-pointer items-center rounded-[2px] font-[600] text-[#686868] hover:text-white"
        style={
          isHover
            ? {
                background: '#DEDEDE',
                color: '#3A3A3A',
              }
            : undefined
        }
        onClick={() => onClick?.()}
      >
        {/* {imgSrc && <img className=" ml-[19px] w-[19px]" src={(isHover ? hoverImgSrc : imgSrc)} />} */}
        <p className="mx-[20px] flex-1 whitespace-pre text-left text-[16px] ">{title}</p>
        {subMenu && <img className=" mr-[12px] w-[5px]" src="/notes/right-arrow-grey.svg" />}
      </div>
      {isHover && <div className=" absolute left-full top-0">{subMenu}</div>}
    </div>
  );
}

export default function (props: { className?: string; noteId?: string; style?: CSSProperties }) {
  const { t } = useTranslation();

  const listQuery = useRequest(folderReq.list, {
    cacheKey: `${folderReq.name}-${folderReq.list.name}`,
  });

  const { data: list = [] } = listQuery;

  // const [list, setList] = useState<Folder.FolderInfo[]>([])
  const [show, setShow] = useState(false);
  const [showCreateFolder, setShowCreateFolder] = useState(false);

  const focusHandle = useFocus((state) => setShow(state == 'focus'));

  const noteInfoQueryParams = getNoteInfoQueryParmas(props.noteId);
  const { data: noteInfo } = useQuery(noteInfoQueryParams);
  const select = useMemo(() => {
    return list.find((i) => i.id == noteInfo?.folderId);
  }, [noteInfo?.folderId]);

  //请求更新list数据
  function updateFolderList() {
    listQuery.refresh();
  }

  useMount(updateFolderList);

  return (
    <div className=" relative mr-[40px] inline-block min-w-[230px]" style={props.style} translate="no">
      <button
        className={clsxm(
          ' relative flex h-[40px] w-full items-center rounded-[12px] border-[0.5px] border-[#9C9C9C] bg-white text-[16px] font-[600] text-[#1A1A1A] ',
          props.className
        )}
        onClick={() => !show && focusHandle()}
      >
        <span className=" ml-[24px] mr-[40px] text-[#1A1A1A]">
          {select?.name || t('user.createFolder.defaultShow')}
        </span>
        <Svg.downArrow className=" absolute right-[21px] top-1/2 -translate-y-1/2" />
      </button>
      {show && (
        <div
          className={clsxm(
            ' absolute left-0 top-[calc(100%+15px)] z-[9999999] max-h-[400px] w-full rounded-[12px] border-[0.5px] border-[#9C9C9C] bg-white p-[6px] shadow-[0px_0px_20px_rgba(0,0,0,0.3)] backdrop-blur',
            'scrollbar scrollbar-thumb-gray-500 scrollbar-track-gray-200 overflow-y-scroll'
          )}
        >
          <ContextMenu>
            {list.map((item) => {
              return (
                <MenuItem
                  title={item.name}
                  onClick={() => {
                    const { queryKey } = noteInfoQueryParams;
                    let preFolderId: null | string = null;

                    queryClient.cancelQueries(queryKey);
                    queryClient.setQueryData(queryKey, (data: typeof noteInfo) => {
                      if (!data) {
                        return data;
                      } else {
                        preFolderId = data.folderId;
                        return {
                          ...data,
                          folderId: item.id,
                        };
                      }
                    });

                    folderReq
                      .assignNote({
                        noteId: props.noteId!,
                        folderId: item.id,
                      })
                      .then((res) => {
                        //@ts-ignore
                        if (res?.error) {
                          queryClient.cancelQueries(queryKey);
                          queryClient.setQueryData(queryKey, (data: typeof noteInfo) => {
                            if (!data) {
                              return data;
                            } else {
                              return {
                                ...data,
                                folderId: preFolderId,
                              };
                            }
                          });
                        }
                      });
                  }}
                />
              );
            })}
            <Divider />
            <MenuItem title={t('user.createFolder.addNew')} subMenu=" " onClick={() => setShowCreateFolder(true)} />
          </ContextMenu>
        </div>
      )}
      {showCreateFolder && (
        <InputModify
          title={t('user.createFolder.title')}
          placeholder={t('user.createFolder.placeholder')}
          onCancel={() => setShowCreateFolder(false)}
          onConfirm={async (name) => {
            const res = await folderReq.create({
              name,
            });
            //@ts-ignore
            if (res?.error) {
            } else {
              updateFolderList();
            }
            setShowCreateFolder(false);
          }}
        />
      )}
    </div>
  );
}
