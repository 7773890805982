const imgImports = import.meta.glob('./*.svg', { eager: true });

const tags: Record<string, string> = {};
Object.keys(imgImports).forEach((pathname) => {
  const name = pathname.slice(pathname.lastIndexOf('/') + 1).split('.')[0];
  //@ts-ignore
  tags[name] = imgImports[pathname].default;
});

export default tags;
