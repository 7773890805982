import { fileCarryReq, fileCarryReqParamsType } from '~/api/device/carry';
import { deviceStatusRes } from '~/api/device/deviceStatus';
import { fileInfoReq } from '~/api/device/fileInfo';
import { firmwareGet } from '~/api/device/firmwareGet';
import { DeviceLatest } from '~/api/device/latest';

import Jensen, { DeviceInfo } from 'jensen';
import { sleep } from '~/utils/sleep';
import { Logger } from '~/utils/utils';
import { FileInfo } from './deviceType';
import { addData, dbTable } from '~/lib/db';

//@ts-ignore
export const jensen = new Proxy(new Jensen(Logger), {
  get(target, key) {
    //@ts-ignore
    const value = target[key];
    if (value instanceof Function) {
      return (...params: any[]) => {
        //@ts-ignore
        const res = target[key](...params);

        if (res instanceof Promise) {
          res.then((value) => {
            addData(dbTable.jensenOrder, {
              action: 'execOrder',
              key,
              params: params.map((i) => String(i)),
              value,
              time: Date.now(),
            });
          });
        }

        return res;
      };
    } else {
      addData(dbTable.jensenOrder, {
        action: 'get',
        key,
        time: Date.now(),
      });

      return value;
    }
  },
  set(target, key, value) {
    //@ts-ignore
    target[key] = value;
    addData(dbTable.jensenOrder, {
      action: 'set',
      key,
      value: JSON.stringify(value),
      time: Date.now(),
    });
    return true;
  },
});

export async function additionalfileInfos(fileInfos: FileInfo[]) {
  const d: FileInfo[] = fileInfos.reverse().sort((a, b) => {
    return a.time.getTime() < b.time.getTime() ? 1 : -1;
  });
  const additionalInfos = await fileInfoReq.fileInfo({
    signatures: d.map((item: FileInfo) => item.signature).join(','),
  });

  for (let i = 0; i < additionalInfos.length; i++) {
    const item = d.find((it) => it.signature == additionalInfos[i].signature)!;
    if (item) {
      item.id = additionalInfos[i].noteId;
      item.state = additionalInfos[i].state;
      item.title = additionalInfos[i].title;
      item.language = additionalInfos[i].language;
      item.template = additionalInfos[i].template;
    }
  }
  return [...d];
}

export async function getDeviceFileArrayBuffer(
  fileInfo: FileInfo,
  onData?: (progress: number, type: fileCarryReqParamsType, data: ArrayBuffer | 'fail') => void,
  onProgress?: (progress: number) => void,
  options?: {
    debugInfo?: {
      module?: string;
    };
  }
): Promise<null | 'fail'> {
  const { debugInfo } = options || {};
  const { module = 'undefine' } = debugInfo || {};
  const procedure = 'getDeviceFileArrayBuffer';

  return new Promise((res, rej) => {
    if (fileInfo.duration == 0) {
      //保证与文件长度为非0的回调时间一致
      Logger.info(module, procedure, `returned null file`);
      setTimeout(() => {
        const buffer = new ArrayBuffer(0);
        onData?.(1, 'whole', buffer);
        onProgress?.(1);
        res?.(null);
      });
      return;
    }

    const fileBlocks: any[] = [];
    let recvBytes = 0;
    let blockType: fileCarryReqParamsType = 'head';
    function handleRequest(msg: Uint8Array | 'fail') {
      if (msg instanceof Uint8Array) {
        fileBlocks.push(msg);
        recvBytes += msg.length;
        const buffer = msg.buffer;
        if (recvBytes == fileInfo.length) {
          onData?.(recvBytes / fileInfo.length, blockType == 'head' ? 'whole' : 'tail', buffer);
          res(null);
        } else {
          onData?.(recvBytes / fileInfo.length, blockType, buffer);
          blockType = 'body';
        }
      } else {
        Logger.info(module, procedure, `handleRequest msg:${msg.toString()}`);
        res('fail');
      }
    }

    //@ts-ignore
    jensen.getFile(fileInfo.name, fileInfo.length, handleRequest, (readlyGetBytes: number) => {
      onProgress?.(readlyGetBytes / fileInfo.length);
    });
  });
}

export async function fileUpload(
  fileInfo: FileInfo,
  sn: string,
  options: {
    templateCode: string;
  },
  onProgress?: (progress: number) => void
) {
  return new Promise<any>((resolve) => {
    const { name, signature } = fileInfo;
    let uploadByteLen = 0;
    let updateBlocking = false;
    let queue: ArrayBuffer[] = [];

    const updateBlockHandle = async () => {
      //队列有内容，并且改函数当前没有运行
      if (updateBlocking || !queue.length) return;
      updateBlocking = true;
      //将队列数据合并
      const totalLen = queue.reduce((total, item) => {
        total += item.byteLength;
        return total;
      }, 0);
      const arrBuffer = new ArrayBuffer(totalLen);
      const arrUnit = new Uint8Array(arrBuffer);
      let len = 0;
      for (let i = 0; i < queue.length; i++) {
        const dataUnit = new Uint8Array(queue[i]);
        for (let j = 0; j < dataUnit.length; j++) arrUnit[len++] = dataUnit[j];
      }

      let type: fileCarryReqParamsType = 'body';
      if (uploadByteLen == 0) type = 'head';
      if (uploadByteLen == 0 && totalLen == fileInfo.length) type = 'whole';
      if (uploadByteLen + totalLen == fileInfo.length) type = 'tail';
      uploadByteLen += arrUnit.length;
      onProgress?.(uploadByteLen / fileInfo.length);
      //清空队列
      queue = [];
      //发送请求
      const res = await fileCarryReq({
        data: arrBuffer,
        type,
        fileName: name,
        signature,
        deviceSn: sn,
        createTime: fileInfo.time.getTime(),
        language: fileInfo.language,
        template: options.templateCode,
      });
      if (uploadByteLen == fileInfo.length) {
        try {
          const data: any = JSON.parse(res.responseText);
          console.log(data);
          if (data.error) resolve(data);
          else resolve(data?.data);
        } catch (err) {
          console.log(err);
        }
      }

      await sleep(500);
      updateBlocking = false;
      updateBlockHandle();
    };

    getDeviceFileArrayBuffer(fileInfo, (progress, type, data) => {
      queue.push(data);
      updateBlockHandle();
    });
  });
}

export type deviceUpgradeState = 'start' | 'downloaded' | 'uploaded' | 'complete' | 'failed' | 'download-fail';
type upgradeInfo = {
  id: string;
  sn: string;
  versionNumber: number;
};

export async function deviceUpgrade(
  upgradeInfo: upgradeInfo,
  onState?: (state: deviceUpgradeState, message?: string) => void,
  onError?: (value: string) => void
) {
  const { id, versionNumber, sn } = upgradeInfo;
  onError = (info) => {
    console.log(info);
  };
  let state: deviceUpgradeState = 'start';
  let setState = (s: deviceUpgradeState, message?: string) => {
    console.log('update state', s);
    onState?.(s, message);
    state = s;
  };
  let startTime = Date.now();
  console.log('firmwareGet run');
  firmwareGet({ id, deviceSn: sn }, async function (data) {
    console.log('firmwareGet data', data);
    if (data instanceof ArrayBuffer) {
      setState('downloaded');
    } else {
      if (data == 0) setState('download-fail');
      return false;
    }

    let reqUpgradeRes = await jensen.requestFirmwareUpgrade(versionNumber, data.byteLength);
    console.log('reqUpgradeRes', reqUpgradeRes);
    if (!reqUpgradeRes) {
      onError?.('reqUpgradeRes Retry');
      reqUpgradeRes = await jensen.requestFirmwareUpgrade(versionNumber, data.byteLength);
      sleep(500);
    }
    if (reqUpgradeRes.result != 'accepted') {
      setState('failed');
      return false;
    }
    let uploadRes = await jensen.uploadFirmware(Array.from(new Uint8Array(data)));
    while (!uploadRes) {
      uploadRes = await jensen.uploadFirmware(Array.from(new Uint8Array(data)));
      onError?.('uploadRes Retry');
      sleep(500);
    }
    console.log('uploadRes', uploadRes);
    if (uploadRes.result != 'success') {
      setState('failed');
      return false;
    } else setState('uploaded');
    jensen.isStopConnectionCheck = true;
    let isComplete = false;
    let deviceInfo: DeviceInfo | null = null;
    while (!isComplete) {
      await sleep(1000);
      if ((Date.now() - startTime) / 1000 / 60 > 10) isComplete = true;
      let res = await jensen.getDeviceInfo(5);
      console.log('deviceInfo', res);
      if (res == null) {
        jensen.tryconnect();
      } else {
        deviceInfo = res;
        if (deviceInfo?.versionNumber == versionNumber) isComplete = true;
      }
    }
    jensen.isStopConnectionCheck = false;
    console.log('upgrade End deviceInfo', deviceInfo);
    //@ts-ignore
    if (deviceInfo?.versionNumber == versionNumber) setState('complete');
    else setState('failed');
  });
}

/** 这个文件信息除了名字以外其它都是固定的 */
export function getRecordingFileByFlieName(recording: string, createDate: string, createTime: string) {
  return {
    name: recording,
    createDate: createDate,
    createTime: createTime,
    time: new Date(),
    duration: 0,
    length: 0,
    signature: '0'.repeat(32),
  };
}

export const deviceTool = {
  isUpgradable(versionNumber: number, latestInfo: DeviceLatest | null, deviceStatus: deviceStatusRes | null) {
    if (
      versionNumber != -1 &&
      latestInfo &&
      latestInfo?.versionNumber != versionNumber &&
      deviceStatus?.ownership == 'mine'
    ) {
      return true;
    }
    return false;
  },
  /**文件对于设备来说是否可下载 */
  donwloadable(file: FileInfo) {
    return !file.isDelete && file.signature != '0'.repeat(32);
  },
};
