import { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BarItem, Select, Select2, Switch as SwitchCore } from './general';

export enum ControlKind {
  select = 'select',
  select2 = 'select2',
  switch = 'switch',
}

export type ControlInfo =
  | {
      type: ControlKind.select;
      data: {
        defaultValue: string;
        options: {
          label: string;
          value: string;
        }[];
      };
    }
  | {
      type: ControlKind.select2;
      data: {
        defaultValue: string;
        options: {
          name: string;
          children: {
            label: string;
            value: string;
          }[];
        }[];
      };
    }
  | {
      type: ControlKind.switch;
      data: {
        defaultValue: string;
      };
    };

export type GeneralPageProps = {
  config: {
    title: string;
    hint: string;
    name: string;
    control: ControlInfo;
  }[];
  title: string;
  onChange?: (key: string, value: string) => void;
};

function MultiSelect(props: {
  defaultValue: string;
  options: {
    name: string;
    children: {
      label: string;
      value: string;
    }[];
  }[];
  onChange?: (value: string) => void;
}) {
  const [value, setValue] = useState(props.defaultValue);
  const { t } = useTranslation();

  return (
    <Select2
      select={value}
      onChange={(info) => {
        setValue(info.value);
        props.onChange?.(info.value);
      }}
      showNull={t('user.info.default')}
      data={props.options}
    />
  );
}

function Switch(props: { defaultValue: string; onChange?: (value: string) => void }) {
  const { defaultValue, onChange } = props;
  const [state, setState] = useState(defaultValue == 'on');
  return (
    <SwitchCore
      state={state}
      onClick={(state) => {
        setState(state);
        onChange?.(state ? 'on' : 'off');
      }}
      className=" cursor-pointer"
    />
  );
}

function ControlComp(props: { info: ControlInfo; onChange?: (value: string) => void }) {
  const { info, onChange } = props;
  switch (info.type) {
    case 'select': {
      const { defaultValue, options } = info.data;
      return (
        <Select
          defaultSelect={defaultValue}
          data={options}
          showNull={'auto'}
          onChange={(value) => {
            onChange?.(value.value);
          }}
        />
      );
    }
    case 'select2': {
      const { defaultValue, options } = info.data;
      return <MultiSelect defaultValue={defaultValue} options={options} onChange={onChange} />;
    }
    case 'switch': {
      const { defaultValue } = info.data;
      return (
        <Switch
          defaultValue={defaultValue}
          onChange={(v) => {
            onChange?.(v);
          }}
        />
      );
    }
  }
}

export function GeneralPage(props: GeneralPageProps) {
  const { config, title, onChange } = props;
  const header = (
    <div className=" mb-[30px] text-[28px] font-[600]" style={{ lineHeight: '28px' }}>
      {title}
    </div>
  );

  const curValue = useMemo(() => {
    const a: any = {};
    for (const item of config) {
      a[item.name] = item.control.data.defaultValue;
    }
    return a;
  }, []);

  const bars = config.map((item, idx) => {
    const Comp = (
      <ControlComp
        info={item.control}
        onChange={(value) => {
          curValue[item.name] = value;
          onChange?.(item.name, value);
        }}
      />
    );

    return (
      <BarItem
        title={item.title}
        describe={item.hint}
        endfix={Comp}
        color={idx % 2 == 0 ? '#F0F0F0' : ''}
        key={item.name}
      />
    );
  });

  return (
    <div className=" box-content w-[850px] overflow-y-auto pe-[10px] pt-[3px] leading-[18.72px]">
      {header}
      {bars}
    </div>
  );
}
