import type { RecentlyNote } from '~/api/notes/types';
import { noteStore } from '~/pages/notes/store';
import { useAtom } from 'jotai';
import { useMemo, type FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NoteTable } from './Table';
import { useRecentlyNoteList } from '~/api/notes/recentlyList.ts';
import { recentlyNoteRefresh } from '~/components/note/store.ts';

export const RecentlyNoteList: FC = () => {
  const { t } = useTranslation();
  const [showType] = useAtom(noteStore.showType);
  const { data, refetch } = useRecentlyNoteList({ count: 3 });

  const [count] = useAtom(recentlyNoteRefresh);

  useEffect(() => {
    count && refetch();
  }, [count]);

  const notes = useMemo(() => {
    const notes: RecentlyNote[] = [];
    data && data.pages.map((items) => items.map((note) => notes.push(note)));
    return notes;
  }, [data]);

  return (
    showType === 'list' &&
    notes.length > 0 && (
      <div className=" mt-[30px] w-full">
        <p className=" pl-[20px] text-[28px] font-bold leading-[48px] text-[#3D3D3D]">
          {t('note.recently.transcribed.title')}
        </p>
        <NoteTable notes={notes} showTitle={true} />
      </div>
    )
  );
};
