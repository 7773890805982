import { clsxm } from '@hidock/utils';
import type { FC } from 'react';

export const AvatarPlaceHolder: FC<{ className?: string }> = ({ className }) => {
  return (
    <div className={clsxm('relative flex flex-col items-center space-y-4', className)}>
      <section
        className={clsxm(
          'border-gray relative flex aspect-square w-[64px] items-center justify-center rounded-full ',
          'border-2 bg-white',
          'absolute -top-[24px] left-1/2 -translate-x-1/2 -translate-y-1/2'
        )}
      >
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="28" cy="27.9998" r="21" stroke="#283040" strokeWidth="4.66667" />
          <circle cx="28" cy="21" r="7" fill="#283040" />
          <path
            d="M28 30.3335C20.268 30.3335 14 36.6015 14 44.3335C19.0556 48.6113 31.7333 54.6002 42 44.3335C42 36.6015 35.732 30.3335 28 30.3335Z"
            fill="#283040"
          />
        </svg>{' '}
      </section>
    </div>
  );
};
