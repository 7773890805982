import { VocabularyReq } from '~/api/vocabulary';
import { globalAddEl } from '~/components/globalLayout';
import { sleep } from '~/utils/sleep';
import { atom, useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ModalContainer from '../../general/Modal';
import { myMessage } from '../../MyToast/MyToast';
import { NormalizeContnent } from './selectionBtn';

export const replaceVocalbularyInfoAtom = atom({
  show: false,
  replace: '',
  to: '',
  onAddSuccess: () => {},
  event: {
    refreshNote() {},
  },
});

function ReplaceVocalbulary() {
  const [state, setState] = useAtom(replaceVocalbularyInfoAtom);
  const { noteId = '' } = useParams();
  const [isReplace, setIsReplace] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  async function addDictionary(word: string, replacement: string, replace: boolean, noteId: string) {
    if (word.length < 2 || word.length > 50 || replacement.length < 2 || replacement.length > 50) {
      myMessage.error(t('note.content-repalce.wordLen_Illegal'), 2000);
      return;
    }
    setLoading(true);
    VocabularyReq.add({
      word: NormalizeContnent(word),
      replacement: NormalizeContnent(replacement),
      replace: replace + '',
      noteId,
    }).then(async (res) => {
      //@ts-ignore
      if (!res?.error) {
        await sleep(100);
        myMessage.success(t('note.add-dictionary.success'), 2000);
        state.onAddSuccess();
        setState(replaceVocalbularyInfoAtom.init);
        if (replace) state.event.refreshNote();
      }
      setLoading(false);
    });
  }

  useEffect(() => {
    if (!state.show) setIsReplace(false);
  }, [state.show]);

  if (!state.show) return null;

  return (
    <ModalContainer
      className=" flex flex-col items-center rounded-[16px]  bg-white"
      btnClssName="right-[15px] top-[15px]"
      onClose={() => setState((state) => ({ ...state, show: false }))}
    >
      <div className=" mb-[14px] mt-[42px] text-[28px] font-[600] leading-[48px]">
        {t('note.add-dictionary.modal.title')}
      </div>
      <div className=" flex flex-col px-[96px] ">
        <div className=" pr-[10px]  text-[20px] font-[600] leading-[48px] text-[#3D3D3D]">
          {t('note.add-dictionary.modal.replace')}
        </div>
        <input
          value={state.replace}
          maxLength={30}
          className=" h-[60px] w-[272px] rounded-[8px] border border-[#9C9C9C] text-center text-[20px] outline-none"
          onChange={(e) => {
            state.replace = e.currentTarget.value;
            setState({ ...state });
          }}
        />

        <div className=" pr-[10px]  text-[20px] font-[600] leading-[48px] text-[#3D3D3D]">
          {t('note.add-dictionary.modal.to')}
        </div>
        <input
          className=" h-[60px] w-[272px] rounded-[8px] border border-[#9C9C9C] text-center text-[20px] outline-none"
          value={state.to}
          maxLength={30}
          onChange={(e) => {
            state.to = e.currentTarget.value;
            setState({ ...state });
          }}
        />

        <div
          className="my-[22px] flex w-full items-center justify-end"
          style={{
            visibility: noteId ? 'visible' : 'hidden',
            pointerEvents: noteId ? 'auto' : 'none',
          }}
        >
          <div className=" flex w-[272px]">
            <input
              type="checkbox"
              className=" mr-[10px] cursor-pointer"
              checked={isReplace}
              onChange={(e) => setIsReplace(e.target.checked)}
            />
            <p className="">{t('note.add-dictionary.select.tip')}</p>
          </div>
        </div>

        <div className="mb-[50px] flex items-center justify-center">
          <button
            className="rounded-[8px] bg-[#00A2AF] p-[12px_53px] text-[16px] font-[600] leading-[24px] text-white"
            onClick={() => {
              addDictionary(state.replace, state.to, isReplace, noteId);
            }}
          >
            {loading ? <img src="/loading.svg" /> : t('note.add-dictionary.modal.save')}
          </button>
        </div>
      </div>
    </ModalContainer>
  );
}

globalAddEl(<ReplaceVocalbulary />, {
  isFullScreen: false,
});
