import { Select } from 'antd';
import { atom, useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Recording } from './Record';

export const curMicroAtom = atom('');

function SelectMicrophone() {
  const [options, setOptions] = useState<{ label: string; value: string }[]>([]);
  const [curMicro, setCurMicro] = useAtom(curMicroAtom);
  useEffect(() => {
    let timer: null | NodeJS.Timeout = null;
    async function loop() {
      const deviceInfos = await navigator.mediaDevices.enumerateDevices();
      const options = deviceInfos
        .filter((i) => i.kind == 'audioinput')
        .map((i) => {
          return {
            label: i.label,
            value: i.deviceId,
          };
        });
      setOptions(options);
      timer = setTimeout(loop, 500);
    }
    timer = setTimeout(loop, 500);
    // navigator.mediaDevices.enumerateDevices().then((v) => { console.log(v) });
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  return (
    <Select
      className=" w-[80%]"
      labelInValue
      defaultValue={'default'}
      onChange={(e) => {
        //@ts-ignore
        setCurMicro(e.value);
      }}
      options={options}
    />
  );
}

export const PageRecord = () => {
  const navigate = useNavigate();

  const [key, setKey] = useState(0);

  return (
    <>
      <Recording
        key={key}
        onRestart={() => setKey((k) => k + 1)}
        onStop={(ticket) => {
          navigate('/audio/generating/' + ticket, { replace: true });
        }}
        onCancel={() => navigate(-1)}
        onError={() => navigate('/')}
      />
    </>
  );
  // <SelectMicrophone/>
};
