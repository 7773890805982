import { request } from '~/lib/axios';
import { reqParamsToBody } from './utils';

export type VocabularyItem = {
  id: string;
  userId: string;
  word: string;
  replacement: string;
  type: 'replace';
};

function create(params: { word: string }) {
  return request.post('/v1/vocabulary/create', reqParamsToBody(params));
}

function Delete(params: { id: string }) {
  return request.post('/v1/vocabulary/delete', reqParamsToBody(params));
}

function add(params: { word: string; replacement: string; replace: string; noteId: string }) {
  return request.post('/v1/vocabulary/create', reqParamsToBody(params));
}

function list() {
  return request.post<VocabularyItem[]>('/v1/vocabulary/list');
}

function replace(params: { noteId: string; before: string; after: string }) {
  return request.post<any>('/v1/vocabulary/replace', reqParamsToBody(params));
}

export const VocabularyReq = {
  create,
  delete: Delete,
  list,
  add,
  replace,
};
