import { IconUser } from '@hidock/ui';
import { clsxm } from '@hidock/utils';
import { useLogoutMutation } from '~/api/users/logout';
import { emitter } from '~/lib/mitt';
import { useIsUserLogin, useUserInfo } from '~/store/user';
import { isMobile, isPC } from '~/utils/utils';
import { atom, useAtom, useSetAtom } from 'jotai';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { useHover } from 'usehooks-ts';
import { Button } from '../Button';
import { LanguageSelectMobile } from '../LanguageSelect/languageSelect';
import { setLoginModalVisible } from '../modal';
import { Avatar as Avator2, UserModal } from '../modal/UserModal';
import { NoteUserGuideTargetId } from '../userGuide/config';
import { SiderBarSelectType, userInfoTabAtom } from '../userInfo';
import { HoverModal } from './HoverModal';

const buttonClassname = clsxm('p-2 rounded-full bg-gray inline-block flex justify-center items-center');

export function useIsUserModalVisible() {
  const [params, setParams] = useSearchParams();
  const key = 'userInfo';
  const val = 'true';
  const isVisibleRef = useRef(params.get(key) == val);
  isVisibleRef.current = params.get(key) == val;
  const handleChange = useCallback((stateAction: boolean | ((state: boolean) => boolean)) => {
    function handle(state: boolean) {
      if (state) params.set(key, val);
      else params.delete(key);
      setParams(params);
    }
    if (stateAction instanceof Function) handle(stateAction(isVisibleRef.current));
    else handle(stateAction);
  }, []);

  return [isVisibleRef.current, handleChange] as [
    boolean,
    (stateAction: boolean | ((state: boolean) => boolean)) => void
  ];
}

export const Header = () => {
  const [isUserModalVisible, setUserModalVisible] = useIsUserModalVisible();
  const [visibleAvatarContainer, setVisibleAvatarContainer] = useState(false);
  const { t } = useTranslation();
  let { pathname } = useLocation();
  const naviagte = useNavigate();
  useEffect(() => {
    if (/^\/notes/.test(pathname) && !isMobile()) setVisibleAvatarContainer(false);
    else setVisibleAvatarContainer(true);
  }, [pathname]);
  useEffect(() => {
    const callback = () => {
      setUserModalVisible(false);
    };
    emitter.on('signed-in', callback);

    return () => {
      emitter.off('signed-in', callback);
    };
  }, []);
  const isLogin = useIsUserLogin();
  const showSearch = /^\/notes/.test(pathname) && isMobile();
  return (
    <>
      <header
        className="fixed inset-x-0 z-[10] flex items-center px-6 py-3"
        style={{
          visibility: visibleAvatarContainer ? 'visible' : 'hidden',
          backgroundColor: showSearch ? 'white' : '',
        }}
      >
        <div className="">
          <LanguageSelectMobile direction="left" />
        </div>
        <div
          className="mx-[15px] flex h-[32px] flex-1 items-center justify-center rounded-full bg-[#F0F0F0] text-[14px] text-[#BDBDBD]"
          style={{
            visibility: showSearch ? 'visible' : 'hidden',
          }}
          onClick={() => naviagte('/notes/search')}
        >
          {t('misc.search')}
        </div>
        <div className="flex items-center gap-4">
          <Button
            onClick={() => {
              if (isLogin) {
                setUserModalVisible(true);
              } else {
                setLoginModalVisible(true);
              }
            }}
          >
            <Avatar />
          </Button>
        </div>
      </header>

      {isUserModalVisible && visibleAvatarContainer && <UserModal onOpenChange={setUserModalVisible} />}
    </>
  );
};

export function UserAvatar(props: { className?: string }) {
  const [isUserModalVisible, setUserModalVisible] = useIsUserModalVisible();
  const avatorContainerRef = useRef<HTMLDivElement | null>(null);
  const [ishover, setIsHover] = useState(false);

  useEffect(() => {
    const target = avatorContainerRef.current;
    if (!target) return;
    target.addEventListener('mouseenter', () => {
      setIsHover(true);
    });
    target.addEventListener('mouseleave', () => {
      setIsHover(false);
    });
  }, [avatorContainerRef.current]);

  useEffect(() => {
    const callback = () => {
      setUserModalVisible(false);
      setIsHover(false);
    };
    emitter.on('signed-in', callback);

    return () => {
      emitter.off('signed-in', callback);
    };
  }, []);

  const isLogin = useIsUserLogin();
  return (
    <>
      <div className=" relative" ref={avatorContainerRef}>
        <div className="ml-auto flex items-center gap-4">
          {/* <div className={buttonClassname}>
            <IconSearch className="aspect-square w-6" />
          </div> */}
          <Button
            id={NoteUserGuideTargetId.avatar}
            onClick={() => {
              if (isLogin) {
                setUserModalVisible(true);
              } else {
                setLoginModalVisible(true);
              }
            }}
          >
            <Avatar className={props.className} />
          </Button>
        </div>
        {ishover && <HoverModal />}
      </div>
      {isUserModalVisible && <UserModal onOpenChange={setUserModalVisible} />}
    </>
  );
}

const Avatar = (props: { className?: string }) => {
  const { data } = useUserInfo();

  if (data?.avatar)
    return <img className={clsxm('aspect-square w-8 rounded-full', props.className)} src={data.avatar} />;

  return (
    <div className={clsxm(buttonClassname, props.className)}>
      <IconUser className="aspect-square w-6" />
    </div>
  );
};
