import { API } from '~/api';
import { useAPIQuery } from '~/api/helper';
import type { User } from '~/api/users/types';
import { UserType } from '~/api/users/types';
import { queryClient } from '~/main';
import { getDefaultStore, useAtomValue } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useEffect } from 'react';

const get: ReturnType<typeof getDefaultStore>['get'] = (atom) => getDefaultStore().get(atom);

const accessTokenAtom = atomWithStorage('accessToken', '');
export const getAccessToken = () => get(accessTokenAtom) || localStorage.getItem('accessToken');
export const setAccessToken = (token: string) => {
  getDefaultStore().set(accessTokenAtom, token);
};
export const useAccessToken = () => useAtomValue(accessTokenAtom);

// 游客标识
const giAtom = atomWithStorage('gi', '');
export const getGi = () => get(giAtom) || localStorage.getItem('gi');
export const setGi = (gi: string) => {
  getDefaultStore().set(giAtom, gi);
};

export const getUserInfo = () => queryClient.getQueryData<User | null>(API.users.info().key);
export const useUserInfo = () => {
  const { data, ...rest } = useAPIQuery(API.users.info(), { staleTime: 3000 });
  const token = getAccessToken();
  useEffect(() => {
    setGi(data?.gi || '');
    data?.accessToken && setAccessToken(data?.accessToken || '');
  }, [data]);

  return {
    data: !token ? undefined : data,
    ...rest,
  };
};

export const getIsLogin = () => !!getUserInfo()?.id;
export const getIsUserLogin = async () => {
  const info = getUserInfo();

  return getIsLogin() && info?.type !== UserType.Trail;
};
export const getIsTrailUserLogin = async () => {
  const info = await getUserInfo();

  return getIsLogin() && info?.type === UserType.Trail;
};
export const useIsLogin = () => {
  const { data } = useUserInfo();

  return !!data?.id;
};

export const useIsUserLogin = () => {
  const { data: userInfo } = useUserInfo();
  //@ts-ignore
  if (userInfo?.error) return;
  return userInfo && userInfo?.type !== UserType.Trail;
};
export const useIsTrailUserLogin = () => {
  const { data: userInfo } = useUserInfo();

  return userInfo && userInfo?.type === UserType.Trail;
};

export const useMaxNoteCount = () => {
  const { data: userInfo } = useUserInfo();
  const limitation = userInfo?.limitations.find((limitation) => limitation.tag === 'max.note.count');
  if (!limitation) return null;

  return limitation.limitation;
};
export const getMaxNoteCount = () => {
  const userInfo = getUserInfo();
  const limitation = userInfo?.limitations.find((limitation) => limitation.tag === 'max.note.count');
  if (!limitation) return null;

  return limitation.limitation;
};

export const useMaxRecordTime = () => {
  const { data: userInfo } = useUserInfo();
  const limitation = userInfo?.limitations.find((limitation) => limitation.tag === 'max.record.time');
  if (!limitation) return null;

  return limitation.limitation;
};
export const getMaxRecordTime = () => {
  const userInfo = getUserInfo();
  const limitation = userInfo?.limitations.find((limitation) => limitation.tag === 'max.record.time');
  if (!limitation) return null;

  return limitation.limitation;
};

export const getMaxUploadSize = () => {
  const userInfo = getUserInfo();

  const limitation = userInfo?.limitations.find((limitation) => limitation.tag === 'max.upload.size');
  if (!limitation) return null;

  return limitation.limitation;
};
