export enum dbTable {
  log = 'log',
  jensenOrder = 'jensenOrder',
}
const taskArr: {
  table: dbTable;
  obj: any;
}[] = [];

let db: any = null;
let openDbTime: number = -1;

let isExecing = false;

async function loop() {
  try {
    if (!db || !taskArr.length || isExecing) return;
    isExecing = true;
    let task = taskArr.shift()!;
    await db
      .transaction([task.table], 'readwrite')
      .objectStore(task.table)
      .add({
        open_db_time: openDbTime,
        ...task.obj,
      });
    isExecing = false;
    loop();
  } catch (err) {
    console.log('err', err);
  }
}

function getDB() {
  const Logger = 'Logger';
  const request = window.indexedDB.open(Logger, 1);
  request.onsuccess = function (event) {
    console.log('onsuccess');
    //@ts-ignore
    db = event.target.result;
    openDbTime = Date.now();
    loop();
  };
  request.onupgradeneeded = async function (event) {
    console.log('onupgradeneeded');
    //@ts-ignore
    const curDb = event.target.result;
    curDb.createObjectStore(dbTable.log, { keyPath: 'id', autoIncrement: true });
    curDb.createObjectStore(dbTable.jensenOrder, { keyPath: 'id', autoIncrement: true });
    db = curDb;
    openDbTime = Date.now();
    loop();
  };
}

getDB();

export function addData(table: dbTable, obj: any) {
  taskArr.push({
    table,
    obj,
  });
  loop();
}
