import useUpdate from '~/hooks/useUpdate';
import { formatSecondsToProgress } from '~/pages/record/utils';
import { useSize } from 'ahooks';
import { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import timetickPng from './timetick.png';

const timetcikDom = new Image();
const getTimeTick = () => {
  timetcikDom.style.objectFit = 'fill';
  timetcikDom.src = timetickPng;
  return new Promise<any>((res) => {
    timetcikDom.onload = function () {
      res(true);
    };
  });
};

type Block = {
  start: number;
  end: number;
};

type Dataset = {
  waves: number[];
  marks: number[];
  highlightBlocks: Block[];
  highlightWaves: Block[];
  highligihtMarks: number[];
};

export type Player = {
  duration: number;
  currentTime: number;
  playState: string;
  segments: {
    start: number;
    end: number;
    code: string;
  }[];
};

export type ProgressProps = {
  dataset: Dataset;
  player: Player;
  onClick?: (radio: number) => void;
  onMouseHover?: (radio: number, state: boolean) => void;
  onMarkHover?: (timestamp: number, index: number, state: boolean) => void;
  containerRef?: MutableRefObject<HTMLDivElement | null>;
};

function ProgressBar(props: ProgressProps) {
  const update = useUpdate();
  const { dataset, player, onClick, onMouseHover, onMarkHover } = props;
  const cavRef = useRef<HTMLCanvasElement | null>(null);
  const size = useSize(props.containerRef);
  const bodyInfo = useRef({
    width: 1000,
    height: 80,
    marginY: 10,
    marginX: 4,
    border: 1,
    radius: 12,
  }).current;

  const waveshrink = useMemo(() => {
    const waves = dataset.waves;
    const num = ((waves.length / bodyInfo.width) * 3) | 0;
    const data: number[] = [];
    let total = 0;
    let count = 0;
    for (let i = 0; i < waves.length; i++) {
      if (i % num == 0 && i != 0) {
        if (count) data.push(total / count);
        total = 0;
        count = 0;
      }
      count++;
      total += waves[i];
    }
    if (count) data.push(total / count);

    return data;
  }, [bodyInfo.width, dataset.waves]);

  const timeLenRadio = player.duration / bodyInfo.width;

  const markHornPos = useMemo(() => {
    const marks = dataset.marks;
    const res = marks.map((timestamp) => {
      const h = 13;
      const w = 10;
      const x = bodyInfo.marginX + timestamp / timeLenRadio;
      const y = bodyInfo.marginY + h / 2;
      return {
        bottom: {
          x,
          y,
        },
        left: {
          x: x - w / 2,
          y: y - h,
        },
        right: {
          x: x + w / 2,
          y: y - h,
        },
      };
    });
    return res;
  }, [dataset.marks, timeLenRadio, bodyInfo.marginX, bodyInfo.marginY]);

  const Color = {
    border: '#E1E1E1',
    progress: 'rgba(3, 160, 172, 1)',
    mark: '#0BF7E9',
    highlightBlock: '#EEF4F6',
    highlightWaves: '#1CD789',
    highlightMarks: 'red',
    wave: '#D9D9D9',
    progressWaves: '#00A2AF',
    mouseLine: '#00A2AF',
    progressEndLine: '#00A2AF',
    backgroundColor: '#FFFFFF',
  };

  let progressLen = bodyInfo.width * Math.min(player.currentTime / player.duration, 1);

  const highlightBlockRange = dataset.highlightWaves.map(({ start, end }) => {
    return {
      start: start / timeLenRadio,
      end: end / timeLenRadio,
    };
  });

  function PosInContent(x: number, y: number) {
    if (x < bodyInfo.marginX || x > bodyInfo.marginX + bodyInfo.width) return false;
    if (y < bodyInfo.marginY || y > bodyInfo.marginY + bodyInfo.height) return false;
    return true;
  }

  const curMouseInfo = useRef({
    inCav: false,
    x: 0,
    y: 0,
  }).current;

  function render(cav: HTMLCanvasElement) {
    const ctx = cav.getContext('2d')!;
    ctx.clearRect(0, 0, cav.width + 10, cav.height + 10);
    //宽高指的是内容区
    function setCavBaseAttrs() {
      const { width, height, marginX, marginY } = bodyInfo;
      cav.width = width + 2 * marginX;
      cav.height = height + 2 * marginY;
    }

    function clipBorderOverflow() {
      const { width, height, marginX, marginY, border, radius } = bodyInfo;
      const x = marginX - border - 1;
      const y = marginY - border - 1;
      const w = 2 * border + width + 3.5;
      const h = 2 * border + height + 2;
      ctx.lineWidth = border;

      ctx.save();
      ctx.strokeStyle = Color.border;
      ctx.roundRect(x, y, w, h, radius);
      ctx.stroke();
      ctx.clip();
    }

    function addBorder() {
      //处理掉半角外部露出的像素
      // ctx.beginPath();
      const { width, height, marginX, marginY, border, radius } = bodyInfo;
      const x = marginX - border;
      const y = marginY - border;
      const w = 2 * border + width;
      const h = 2 * border + height;
      // ctx.lineWidth = border;

      // ctx.strokeStyle = Color.border;
      // ctx.rect(x, y, w, h);
      // ctx.stroke();
      // ctx.closePath();

      //画边框
      ctx.beginPath();
      ctx.lineWidth = border;

      ctx.strokeStyle = Color.border;
      ctx.roundRect(x, y, w, h, radius);
      ctx.stroke();
      ctx.closePath();
    }

    /**添加刻度 */
    function addScales() {
      const { width } = bodyInfo;
      const scaleLen = 20 * 5;
      const scaleW = 2;
      const scaleH1 = 13;
      const scaleH2 = 7;
      let averageW = width / scaleLen;
      ctx.strokeStyle = Color.border;
      ctx.lineWidth = scaleW;
      for (let i = 1; i <= scaleLen; i++) {
        const x = bodyInfo.marginX - scaleW + i * averageW;
        const y = bodyInfo.marginY;
        const h = i % 5 ? scaleH2 : scaleH1;
        ctx.beginPath();
        ctx.moveTo(x, y);
        ctx.lineTo(x, y + h);
        ctx.stroke();
        ctx.closePath();
      }
    }

    function addProgress() {
      const { marginX, marginY, height } = bodyInfo;
      ctx.fillStyle = Color.progress;
      ctx.beginPath();
      ctx.rect(marginX, marginY, progressLen, height);
      ctx.fill();
      ctx.closePath();
    }

    function setBg() {
      const { width, height, marginX, marginY, border, radius } = bodyInfo;
      const x = marginX;
      const y = marginY;
      ctx.fillStyle = Color.backgroundColor;
      ctx.rect(x, y, width + 2 * border, height + 2 * border);
      ctx.fill();
      ctx.closePath();
    }

    function addMarks() {
      markHornPos.forEach(({ left, right, bottom }, idx) => {
        if (dataset.highligihtMarks.includes(idx)) ctx.fillStyle = Color.highlightMarks;
        else ctx.fillStyle = Color.mark;
        ctx.beginPath();
        ctx.moveTo(bottom.x, bottom.y);
        ctx.lineTo(left.x, left.y);
        ctx.lineTo(right.x, right.y);
        ctx.lineTo(bottom.x, bottom.y);
        ctx.fill();
        ctx.closePath();
      });
    }

    function addHighlightBlock() {
      ctx.fillStyle = Color.highlightBlock;
      dataset.highlightBlocks.forEach(({ start, end }) => {
        const x = bodyInfo.marginX + start / timeLenRadio;
        const y = bodyInfo.marginY;
        const width = (end - start) / timeLenRadio;
        const heigh = bodyInfo.height;
        ctx.beginPath();
        ctx.rect(x, y, width, heigh);
        ctx.fill();
        ctx.closePath();
      });
    }

    function addwaves() {
      ctx.lineWidth = 2;
      ctx.strokeStyle = Color.wave;
      const maxH = (bodyInfo.height * 3) / 4;
      const waveMaxV = Math.max(...waveshrink);
      const averageSpace = bodyInfo.width / waveshrink.length;
      const xhs = waveshrink.map((val, index) => {
        const x = index * averageSpace;
        const h = (val / waveMaxV) * maxH;
        return {
          x,
          h,
        };
      });
      let idx = 0;
      const data = xhs.map(({ x, h }) => {
        while (highlightBlockRange[idx] && x > highlightBlockRange[idx].end) idx++;
        let waveColor = Color.wave;
        if (x <= progressLen) waveColor = Color.progressWaves;
        if (idx < highlightBlockRange.length && highlightBlockRange[idx].start <= x) waveColor = Color.highlightWaves;
        return {
          x,
          h,
          color: waveColor,
        };
      });

      ctx.lineWidth = 2;
      data.forEach((item) => {
        ctx.beginPath();
        const { x, h, color } = item;
        //这里+1是防止底部白边
        ctx.moveTo(x + bodyInfo.marginX, bodyInfo.marginY + bodyInfo.height + 1.5);
        ctx.lineTo(x + bodyInfo.marginX, bodyInfo.marginY + bodyInfo.height - h);
        ctx.strokeStyle = color;
        ctx.stroke();
        ctx.closePath();
      });
    }

    function mouseLine() {
      ctx.strokeStyle = Color.mouseLine;
      ctx.lineWidth = 2;

      const x = curMouseInfo.x;
      const y = curMouseInfo.y;
      if (!PosInContent(x, y) || !curMouseInfo.inCav) return;
      ctx.beginPath();
      ctx.moveTo(x, bodyInfo.marginY);
      ctx.lineTo(x, bodyInfo.marginY + bodyInfo.height);
      ctx.stroke();
      ctx.closePath();
    }

    function progressEndLine() {
      const imgMargin = 10;
      ctx.strokeStyle = Color.progressEndLine;
      const x = progressLen + bodyInfo.marginX;
      const y = bodyInfo.marginY;
      ctx.beginPath();
      ctx.arc(x, bodyInfo.marginY, 4, 0, 2 * Math.PI);
      ctx.fillStyle = Color.progressEndLine;
      ctx.fill();
      ctx.closePath();
      ctx.beginPath();
      ctx.moveTo(x, bodyInfo.marginY);
      ctx.lineTo(x, bodyInfo.marginY + bodyInfo.height);
      ctx.stroke();
      ctx.stroke();
      ctx.closePath();
      //调节照片比例
      // const radio = bodyInfo.height / timetcikDom.height;
      // const width = radio * timetcikDom.width;
      // const height = radio * timetcikDom.height;
      // timetcikDom.height = height;
      // timetcikDom.width = width;
      // ctx.drawImage(timetcikDom, x - width / 2, y - imgMargin, width, bodyInfo.height + 2 * imgMargin);
    }

    setCavBaseAttrs();
    clipBorderOverflow();
    setBg();
    // addProgress();
    addHighlightBlock();
    // addScales();
    addwaves();
    // addBorder();
    ctx.restore();
    addMarks();
    if (player.currentTime <= player.duration) progressEndLine();
    mouseLine();
  }
  const animationTimerRef = useRef<number | null>(null);
  const renderRef = useRef(render);
  renderRef.current = render;

  useEffect(() => {
    const cav = cavRef.current!;
    const parentNode = cav.parentElement;
    if (parentNode) {
      const { marginX, marginY } = bodyInfo;
      const { width, height } = parentNode.getClientRects()[0];
      bodyInfo.height = height - 2 * marginY;
      bodyInfo.width = width - 2 * marginX;
    }
    const excute = () => {
      renderRef.current(cav);
      // animationTimerRef.current = requestAnimationFrame(excute);
    };
    //等待timetick加载完成刷新
    getTimeTick().then(() => {
      update(); //刷新组件，进行组件更新
      excute();
    });

    return () => {
      if (animationTimerRef.current) cancelAnimationFrame(animationTimerRef.current);
    };
  }, [size?.width]);

  if (cavRef.current) {
    if (animationTimerRef.current) cancelAnimationFrame(animationTimerRef.current);
    animationTimerRef.current = requestAnimationFrame(() => {
      render(cavRef.current!);
      animationTimerRef.current = null;
    });
  }

  const hoverRef = useRef({
    marks: [] as boolean[],
    mouse: false,
  });
  let showTimeCss: React.CSSProperties = {
    visibility: 'hidden',
    position: 'fixed',
  };
  if (PosInContent(curMouseInfo.x, curMouseInfo.y) && curMouseInfo.inCav && cavRef.current) {
    const cav = cavRef.current;
    const cavRect = cav.getBoundingClientRect();
    const x = cavRect.left + curMouseInfo.x;
    const y = cavRect.y;
    showTimeCss = {
      position: 'fixed',
      visibility: 'visible',
      left: x - 30,
      top: y - 12,
      transform: 'translate(-50%,-100%)',
      padding: 10,
      border: 'solid 1px rgba(245,245,245,1)',
      borderRadius: 5,
      backgroundColor: 'rgba(104, 117, 115, 1)',
      color: 'white',
    };
  }

  return (
    <>
      <div style={showTimeCss}>
        {formatSecondsToProgress(((timeLenRadio * (curMouseInfo.x - bodyInfo.marginX)) / 1000) | 0, true)}
      </div>
      <canvas
        className="absolute left-0 top-0 cursor-pointer"
        ref={cavRef}
        onClick={(e) => {
          const { offsetX, offsetY } = e.nativeEvent;
          if (PosInContent(offsetX, offsetY)) {
            const progressLen = e.nativeEvent.offsetX - bodyInfo.marginX;
            const radio = progressLen / bodyInfo.width;
            onClick?.(radio);
          }
        }}
        onMouseEnter={() => {
          curMouseInfo.inCav = true;
        }}
        onMouseMove={(e) => {
          const hover = hoverRef.current;
          const { offsetX, offsetY } = e.nativeEvent;
          curMouseInfo.x = offsetX;
          curMouseInfo.y = offsetY;
          update(); //更新进度条，更新鼠标hover位置
          const radio = (offsetX - bodyInfo.marginX) / bodyInfo.width;
          //判断在不在框框内
          if (
            offsetX < bodyInfo.marginX ||
            offsetX > bodyInfo.marginX + bodyInfo.width ||
            offsetY < bodyInfo.marginY ||
            offsetY > bodyInfo.marginY + bodyInfo.height
          ) {
            if (hover.mouse) {
              onMouseHover?.(radio, false);
              hover.mouse = false;
            }
          } else {
            // if (!hover.mouse) {
            onMouseHover?.(radio, true);
            hover.mouse = true;
            // }
          }

          markHornPos.forEach((hornPos, index) => {
            const { left, right, bottom } = hornPos;
            let isEnter = true;
            if (offsetX < left.x || offsetX > right.x) isEnter = false;
            if (offsetY < left.y || offsetY > bottom.y) isEnter = false;
            //(y1 - y2)/(x1 - x2) + n
            const leftSlope = (left.y - bottom.y) / (left.x - bottom.x);
            const leftConstNum = offsetY - leftSlope * offsetX;

            const rightSlop = (right.y - bottom.y) / (right.x - bottom.x);
            const rightConstNum = offsetY - rightSlop * offsetX;
            if (offsetY < leftSlope * offsetX + leftConstNum || offsetY < rightSlop * offsetX + rightConstNum)
              isEnter = false;
            if (isEnter != !!hover.marks[index]) {
              onMarkHover?.(dataset.marks[index], index, isEnter);
              hover.marks[index] = isEnter;
            }
          });
        }}
        onMouseOut={() => {
          const hover = hoverRef.current;
          curMouseInfo.inCav = false;
          if (hover.mouse) {
            onMouseHover?.(0, false);
            hover.mouse = false;
          }
          dataset.marks.forEach((timestamp, index) => {
            if (hover.marks[index]) {
              onMarkHover?.(timestamp, index, false);
              hover.marks[index] = false;
            }
          });
        }}
      />
    </>
  );
}

export default ProgressBar;
