import { useDeleteNoteMutation } from '~/api/notes/delete';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from '../modal';

export const NoteDeleteModal: FC<{
  noteId: string;
  onConfirm?: () => void;
  onCancel?: () => void;
}> = ({ noteId, onConfirm, onCancel }) => {
  const { t } = useTranslation();

  const { mutate } = useDeleteNoteMutation();

  return (
    <Modal.Root className="z-[1000] space-y-4 text-center" open={true}>
      <div className="p-[18px]" onClick={(e) => e.stopPropagation()}>
        <Modal.Title className={'text-xl'}>{t('note.modal.delete.label')}</Modal.Title>

        <div className="mt-9 flex justify-center space-x-6 whitespace-nowrap rounded-[42px] text-xs">
          <button
            className="bg-clean text-solid rounded-[inherit]  px-6 py-2"
            onClick={(e) => {
              e.stopPropagation();
              onCancel?.();
            }}
          >
            {t('note.modal.delete.cancel')}
          </button>
          <button
            className="rounded-[inherit] bg-red-400 px-6 py-2 text-white"
            id="note-delte-confirm"
            onClick={() => {
              mutate({ noteId });
              onConfirm?.();
            }}
          >
            {t('note.modal.delete.confirm')}
          </button>
        </div>
      </div>
    </Modal.Root>
  );
};
