import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import ja from './ja.json';
import zhCN from './zh-CN.json';

export const defaultLocale = 'en';
export const defaultNS = 'translation';


function filterNullValue(lang: Record<string, string>) {
  for (let key in lang) {
    const value = lang[key];
    if (value == '') delete lang[key]
  }
}

filterNullValue(ja)
filterNullValue(zhCN)

export const resources = {
  en: {
    translation: en,
  },
  ja: {
    translation: {
      ...en,
      ...ja,
    },
  },
  zhCN: {
    translation: {
      ...en,
      ...zhCN,
    },
  },
};
let lang = 'en';

Object.keys(resources).forEach((i) => {
  if (i.slice(0, 2) == navigator.language.slice(0, 2)) lang = i;
});

lang = localStorage.getItem('userLang') || lang;
i18n
  .use(initReactI18next)
  // .use(LanguageDetector)
  .init({
    lng: lang,
    resources,
    defaultNS,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
