import { EntryInfo, entryInfoReq } from '~/api/note2s/entryInfo';
import { guideVersion } from '~/components/userGuide/UserGuide';
import { sleep } from '~/utils/sleep';
import { isMobile, isPC } from '~/utils/utils';
import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useEffect } from 'react';
import { useIsUserLogin } from './user';

const value = localStorage.getItem('entryInfo');
const entryInfoAtom = atomWithStorage<EntryInfo>(
  'entryInfo',
  value
    ? (JSON.parse(value) as any)
    : {
        quote: {
          author: 'James Althcher',
          content:
            "Always make sure you're doing little experiments every day to move yourself forward on the things you're interested in.",
        },
        visited: true,
        announcement: '',
      }
);
let lock = false;
let updateTime = -1;

export function useEntryInfo() {
  const res = useAtom(entryInfoAtom);
  const isUserLogin = useIsUserLogin();
  useEffect(() => {
    if (lock) return;
    lock = true;
    let isContinue = true;
    async function exec() {
      const entryInfo = await entryInfoReq({ gt: guideVersion });
      //@ts-ignore
      if (entryInfo?.error) {
        await sleep(2000);
        exec();
        return;
      }

      res[1]({
        ...entryInfo,
      });
      updateTime = Date.now() + 12 * 60 * 60 * 1000;

      while (Date.now() < updateTime && isContinue) {
        await sleep(Math.max((updateTime - Date.now()) / 2, 1000));
      }
      if (isContinue) exec();
    }
    if (!isMobile() && isUserLogin) exec();

    return () => {
      isContinue = false;
      lock = false;
    };
  }, [isUserLogin]);

  return res;
}
