import { request } from '~/lib/axios';
import { reqParamsToBody } from './utils';

type RedemptionProps = {
  redemptionCode: string;
  verifyCode: string;
};

type RedeemResult = {
  type: string;
  delta: number;
};

function redemption(props: RedemptionProps) {
  return request.post<RedeemResult>('/v1/redemption/fulfill', reqParamsToBody(props), {
    notShowErr: true,
  });
}

export type RedemptionVerifyHint = {
  hint: string;
  description: string;
  state: string;
};

function info(props: { redemptionCode: string }) {
  return request.post<RedemptionVerifyHint>('/v1/redemption/info', reqParamsToBody(props));
}

export const redemptionReq = {
  redemption,
  info,
};
