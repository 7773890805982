export const IconClass = ({ className }: { className?: string }) => {
  return (
    <svg className={className} fill="none" viewBox="0 0 12 12">
      <path
        d="M5.99988 10.5L2.49988 8.6V5.6L0.499878 4.5L5.99988 1.5L11.4999 4.5V8.5H10.4999V5.05L9.49988 5.6V8.6L5.99988 10.5ZM5.99988 6.35L9.42488 4.5L5.99988 2.65L2.57488 4.5L5.99988 6.35ZM5.99988 9.3625L8.49988 8.0125V6.125L5.99988 7.5L3.49988 6.125V8.0125L5.99988 9.3625Z"
        fill="currentColor"
      />
    </svg>
  );
};
