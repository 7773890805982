import { IconUpload } from '@hidock/ui';
import { clsxm } from '@hidock/utils';
import { UserType } from '~/api/users/types';
import { emitter } from '~/lib/mitt';
import { getIsLogin, getMaxUploadSize, useUserInfo } from '~/store/user';
import { parseDoubleBracketSyntax } from '~/utils/utils';
import { useCallback, useEffect, useRef, type FC } from 'react';
import { useDropzone, type DropzoneOptions } from 'react-dropzone';
import { toast } from 'react-hot-toast';
import { Translation, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { ClassValue, VariantProps } from 'tailwind-variants';
import { tv } from 'tailwind-variants';
import defaultTheme from 'tailwindcss/defaultTheme';
import { useMediaQuery } from 'usehooks-ts';
import { Button } from '../Button';
import { setLoginModalVisible } from '../modal';
import { myMessage } from '../MyToast/MyToast';
import { NoteUserGuideTargetId } from '../userGuide/config';

const uploadButton = tv({
  slots: {
    container: 'rounded-full bg-gray inline-block cursor-pointer',
    icon: '',
  },
  variants: {
    size: {
      sm: {
        container: 'p-[10px]',
        icon: 'w-[29px]',
      },
      md: {
        container: 'p-3',
        icon: 'w-[50px]',
      },
      lg: {
        container: 'p-4',
        icon: 'w-[72px]',
      },
    },
  },
  defaultVariants: {
    size: 'lg',
  },
});

export const UploadButton: FC<VariantProps<typeof uploadButton> & { describe?: string }> = ({ size, describe }) => {
  const navigate = useNavigate();

  const pendingFileRef = useRef<File | null>();
  const { t } = useTranslation();

  useEffect(() => {
    const callback = () => {
      const file = pendingFileRef.current;
      if (file) {
        pendingFileRef.current = null;
        navigate('/audio/upload', { state: { file } });
      }
    };

    emitter.on('signed-in', callback);

    return () => {
      emitter.off('signed-in', callback);
    };
  }, [navigate]);

  const onDrop = useCallback<NonNullable<DropzoneOptions['onDrop']>>(
    async (acceptedFiles) => {
      const file = acceptedFiles[0];
      if (!file) return;

      const maxUploadSize = await getMaxUploadSize();

      if (maxUploadSize && file.size / 1024 / 1024 > maxUploadSize) {
        const content = t('note.error.file_size_exceed');
        myMessage.error(parseDoubleBracketSyntax(content, { size: maxUploadSize + 'mb' }));
        return;
      }

      pendingFileRef.current = null;

      if (!getIsLogin()) {
        setLoginModalVisible(true);
        pendingFileRef.current = file;
      } else {
        pendingFileRef.current = null;
        navigate('/audio/upload', { state: { file } });
      }
    },
    [navigate, t]
  );

  return (
    <UploadButtonBase
      size={size}
      onDrop={onDrop}
      describe={describe}
      className=" flex h-[100px] w-[100px] items-center justify-center  bg-gradient-to-r from-[#24DCEB] to-[#00A2AF]"
      containerClassName={' w-[400px] h-[300px] 2xl:w-[500px]  border border-dashed bg-transparent '}
      iconClassName={'text-white w-[80px]'}
    />
  );
};

export const UploadButtonBase: FC<
  VariantProps<typeof uploadButton> & {
    className?: string;
    iconClassName?: ClassValue;
    containerClassName?: ClassValue;
    onDrop: NonNullable<DropzoneOptions['onDrop']>;
    describe?: string;
  }
> = ({ className, size, onDrop, iconClassName, containerClassName, describe }) => {
  const xl2 = !useMediaQuery(`(max-width: ${defaultTheme.screens['2xl']})`);
  const { container, icon } = uploadButton({
    // size: xl2 ? 'lg' : 'md',
    size: 'lg',
  });
  const { data: userInfo } = useUserInfo();
  const { t } = useTranslation();
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      'audio/mp3': ['.mp3'],
      'audio/opus': ['.opus'],
      'audio/aac': ['.aac'],
      'audio/wav': ['.wav'],
      'audio/m4a': ['.m4a'],
      'audio/wma': ['.wma'],
      'audio/flac': ['.flac'],
      'audio/ape': ['.ape'],
      'audio/ogg': ['.ogg'],
      'video/mpeg': ['.mpeg'],
      'video/mp4': ['.mp4'],
      'video/mov': ['.mov'],
      'video/wmv': ['.wmv'],
    },
    onDropRejected(err) {
      myMessage.error(t('err.12005'), 2000);
    },
  });

  const showText = [UserType.Trail, UserType.free].includes(userInfo?.type || '')
    ? t('misc.upload.support.describe2')
    : t('misc.upload.support.describe');

  return (
    <Button
      id={NoteUserGuideTargetId.upload}
      layoutId="upload-button"
      {...getRootProps({
        // className: container({ className }),
      })}
      className=" w-full"
    >
      <input {...getInputProps()} id="audio-upload" />
      <div
        className={clsxm(
          ' flex aspect-[276/250] w-auto flex-col justify-center rounded-[14px] bg-white',
          containerClassName
        )}
      >
        <div className="flex justify-center">
          <button className={container({ className })}>
            <IconUpload className={clsxm(icon(), iconClassName)} />
          </button>
        </div>
        <div>
          <p className=" m-[16px_auto_0] text-center text-[18px] font-[600] leading-[16px] ">{t('misc.upload')}</p>
          <p className=" m-[12px] whitespace-pre-wrap text-center text-[14px] font-[400] not-italic leading-[16px] text-[#686868]">
            {showText}
          </p>
        </div>
      </div>
    </Button>
  );
};
