import { request } from '~/lib/axios';
import { reqParamsToBody } from '../utils';

export function checkOptimize(params: { deviceSn: string }) {
  return request.post<boolean>('/v2/device/optimize/check', reqParamsToBody(params), {
    notShowErr: true,
  });
}

export function recordOptimize(params: { deviceSn: string }) {
  return request.post('/v2/device/optimize', reqParamsToBody(params));
}

export const sdOptimizeReq = {
  checkOptimize,
  recordOptimize,
};
