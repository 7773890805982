import { request } from '~/lib/axios';
import { useEffect, useState } from 'react';
import { reqParamsToBody } from '../utils';

type Params = {
  signatures: string;
};

export type newNoteInfo = {
  noteId: string;
  state: string;
  title: string;
  signature: string;
  language?: string;
  template?: string;
};

export function fileInfo(params: Params) {
  return request.post<newNoteInfo[]>('/v2/device/file/info', reqParamsToBody(params));
}

export function useFileInfo(params: Params) {
  const useSt = useState<any>(null);

  useEffect(() => {
    fileInfo(params).then((r) => {
      useSt[1](r);
    });
  }, []);
  return useSt;
}

function estimateTime(params: { ticket: string }) {
  return request.post<number>('/v2/note/estimate', reqParamsToBody(params), {
    notShowErr: true,
  });
}

export const fileInfoReq = {
  fileInfo,
  estimateTime,
};
