import { sleep } from '~/utils/sleep';
import { useEffect, useState } from 'react';
import loadImgObj from './loadImg';

const loadImgs = Object.values(loadImgObj).sort((a, b) => (a > b ? 1 : -1));

export default function () {
  const [visibleIdx, setVisibleIdx] = useState(0);
  useEffect(() => {
    let isRun = true;
    async function exec() {
      while (true) {
        for (let i = 0; i < loadImgs.length; i++) {
          if (!isRun) return;
          setVisibleIdx(i);
          await sleep(34);
        }
        for (let i = loadImgs.length - 1; i >= 0; i--) {
          if (!isRun) return;
          setVisibleIdx(i);
          await sleep(34);
        }
      }
    }
    exec();
    return () => {
      isRun = false;
    };
  }, []);

  return (
    <div className=" relative h-[128px] w-[128px]" id="coffee-load-picture">
      {loadImgs.map((src, idx) => {
        return (
          <img
            src={src}
            style={{ visibility: visibleIdx == idx ? 'visible' : 'hidden' }}
            key={src}
            className=" absolute left-0 top-0 h-[128px] w-[128px]"
          />
        );
      })}
    </div>
  );
}
