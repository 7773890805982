import { clsxm } from '@hidock/utils';
import type { FC, PropsWithChildren } from 'react';

const Root: FC<PropsWithChildren<{ className?: string }>> = ({ className, children }) => {
  return (
    <div
      className={clsxm(
        'relative',
        'mx-6 rounded-[32px] p-[6px]',
        'text-xs font-medium text-white',
        'bg-primary',
        'lg:mx-auto lg:w-full lg:max-w-[594px]',
        'mt-16 flex flex-col self-stretch',
        className
      )}
    >
      {children}
    </div>
  );
};

export const ProcessContainer = {
  Root,
};
