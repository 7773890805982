import { request } from '~/lib/axios';
import { reqParamsToBody } from '../utils';

export type deviceAccessibilityUpdateParams = {
  deviceSn: string;
  name: string;
  accessibility: string;
};

export function deviceAccessibilityUpdateReq(params: deviceAccessibilityUpdateParams) {
  return request.post('/v1/user/device/accessibility/set', reqParamsToBody(params));
}
