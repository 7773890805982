import { request } from '~/lib/axios';

export function avatorUpload(file: File) {
  const formData = new FormData();
  formData.append('file', file);
  return request.post('/v1/user/avatar/upload', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
