import i18n from '~/i18n';
import { request } from '~/lib/axios';
import { emitter } from '~/lib/mitt';
import { generalErrorHandle } from '~/utils/utils';
import { useMemoizedFn } from 'ahooks';
import { useEffect, useRef } from 'react';
import { defineQuery, useAPIQuery } from '../helper';
import { reqParamsToBody } from '../utils';

type Params = {
  ticket: string;
};

export const enum NOTE_STATE {
  ERROR = 'error',
  CREATED = 'created',
  TRANSCRIBED = 'transcribed',
  SUMMARIZED = 'summarized',
  SAVED = 'saved',
}

export const enum NOTE_RESULT {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILED = 'failed',
  SAVED = 'saved',
}

export const getByTicket = (params: Params) =>
  defineQuery(['note.get', params.ticket], async () => {
    async function exec() {
      const data = await request.post<{
        id: string;
        state: NOTE_STATE;
        result: NOTE_RESULT;
        error?: {
          code: number;
          message: string;
        };
      }>('/v1/note/get', reqParamsToBody(params), { notShowErr: true });
      return data;
    }
    while (true) {
      try {
        return exec();
      } catch (err) {
        console.log('err', err);
      }
    }
  });

export const useGetTicketInfo = (ticket: string) => {
  const timerRef = useRef<any>(null);
  const readyRef = useRef(true);
  function cancelHandle() {
    clearTimeout(timerRef.current);
    // 谢谢你，useEffect执行两次
    readyRef.current = false;
  }
  const cancelHandleRef = useRef(cancelHandle);
  cancelHandleRef.current = cancelHandle;
  const {
    data,
    refetch: fetchTicketInfo,
    isLoading,
  } = useAPIQuery(getByTicket({ ticket }), {
    enabled: false,
  });

  function refreshAndAwatingResultNote() {
    if (!ticket) return;

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    readyRef.current = true;

    const loop = () => {
      if (!readyRef.current) return;
      fetchTicketInfo().then((res) => {
        //到底要不要加入报错提示
        //   const {data} = res;
        //   //@ts-ignore
        // if (data?.error && !data.id && errorRef.current.findIndex(i => i.error == data.error) == -1){
        //   //@ts-ignore
        //   errorRef.current.push(data);
        //   generalErrorHandle({
        //     //@ts-ignore
        //     error: data.error,
        //     //@ts-ignore
        //     message: data.message
        //   }, i18n.t);
        // }

        if ([NOTE_STATE.SAVED, NOTE_STATE.SUMMARIZED].includes(res.data?.state || '') || !readyRef.current) return;
        clearTimeout(timerRef.current);
        timerRef.current = setTimeout(loop, 2000);
      });
    };

    loop();

    return () => {
      cancelHandleRef.current();
    };
  }

  // const errorRef = useRef<{error:number,message:string}[]>([])
  // 2. 用ticket去轮询拿noteId（和state）
  useEffect(refreshAndAwatingResultNote, [ticket, fetchTicketInfo]);

  useEffect(() => {
    const clear = () => {
      clearTimeout(timerRef.current);
      readyRef.current = false;
    };
    emitter.on('before-sign-in', clear);

    return () => {
      emitter.off('before-sign-in', clear);
    };
  }, []);

  return {
    data,
    isLoading,
    cancelHandleRef,
    fetchTicketInfo,
    /**轮询请求笔记信息，直到笔记状态为saved或summarized*/
    refreshAndAwatingResultNote: useMemoizedFn(refreshAndAwatingResultNote),
  };
};
