import { atom, useAtom } from 'jotai';
import { Dispatch, memo, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { number } from 'zod';
import closeImg from './close.png';
import congratulationImg from './congratulation.png';
import errorImg from './error.png';
import infoImg from './info.png';
import css from './toast.module.css';
import warnImg from './warn.png';

let addMessage: null | ((type: MessageType, text: string, timer?: number) => void) = null;

export const myMessage: Record<MessageType, (text: string, timer?: number) => void> = {
  error: (text, timer) => {
    addMessage?.('error', text, timer);
  },
  info: (text, timer) => {
    addMessage?.('info', text, timer);
  },
  warn: (text, timer) => {
    addMessage?.('warn', text, timer);
  },
  success: (text, timer) => {
    addMessage?.('success', text, timer);
  },
};

type MessageType = 'error' | 'info' | 'warn' | 'success';

type ToastItemProps = {
  type: MessageType;
  text: string;
  index: number;
  onDelete?: () => void;
  timer?: number;
};
const ToastItem = memo(function (props: ToastItemProps) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'misc.toast',
  });
  const { type, text, index, onDelete, timer } = props;
  useEffect(() => {
    if (!timer) return;
    const t = setTimeout(() => {
      onDelete?.();
    }, timer);

    return () => {
      clearTimeout(t);
    };
  }, []);
  const TypeItem = {
    error: () => {
      return (
        <div className="bg-green-grey flex min-h-[80px] w-full items-center pr-[4px] shadow-md">
          <div className="mr-[15px] h-full w-[5px] bg-[#FA5B65]" />
          <img className="mr-[13px] mt-[10px] h-[60px] w-[60px]" src={errorImg} />
          <div className="flex h-full flex-1 items-center">
            <div>
              <p className="text-[20px] font-bold text-[#FA5B65]">{t('error')}</p>
              <span className="text-[16px]" id="my-toast-error-text">
                {text}
              </span>
            </div>
          </div>
        </div>
      );
    },
    info: () => {
      return (
        <div className="bg-green-grey flex min-h-[80px] w-full items-center pr-[4px] shadow-md">
          <div className="h-full w-[5px] bg-[#08B9FF]" />
          <img className="ml-[20px] mr-[20px] w-[50px]" src={infoImg} />
          <div className="flex h-full flex-1 items-center">
            <div>
              <p className="text-[20px] font-bold text-[#08B9FF]">{t('info')}</p>
              <span className="text-[16px]">{text}</span>
            </div>
          </div>
        </div>
      );
    },
    warn: () => {
      return (
        <div className="bg-green-grey flex min-h-[80px] w-full items-center pr-[4px] shadow-md">
          <div className="h-full w-[5px] bg-[#FFC700]" />
          <img className="ml-[15px] mr-[18px] h-[60px] w-[60px]" src={warnImg} />
          <div className="flex h-full flex-1 items-center">
            <div>
              <p className="text-[20px] font-bold text-[#FFC700]">{t('warn')}</p>
              <span className="text-[16px]">{text}</span>
            </div>
          </div>
        </div>
      );
    },
    success: () => {
      return (
        <div className="bg-green-grey flex min-h-[80px] w-full items-center pr-[4px] shadow-md">
          <div className="h-full w-[5px] bg-[#11C145]" />
          <img className="ml-[25px] mr-[20px] w-[45px]" src={congratulationImg} />
          <div className="flex h-full flex-1 items-center">
            <div>
              <p className="text-[20px] font-bold text-[#11C145]">{t('success')}</p>
              <span className="text-[16px]">{text}</span>
            </div>
          </div>
        </div>
      );
    },
  };

  return (
    <div
      className={` pointer-events-auto fixed left-[50%] z-[99999999999999]  min-h-[80px] w-[600px] max-w-[90vw] -translate-x-1/2 transform overflow-hidden rounded-[8px] bg-[#FFFFFF] ${css['box-shadow']}`}
      style={{
        top: 10 + Math.min(5, index) * 3,
      }}
      //防止触发用户信息界面关闭事件
      onPointerDown={(e) => e.stopPropagation()}
    >
      <div
        className=" absolute right-[5px] top-[5px] flex h-[24px] w-[24px] cursor-pointer items-center justify-center rounded-full hover:bg-[#E8EAEA]"
        id="my-toast-error-close"
        onClick={() => {
          onDelete?.();
        }}
      >
        <img src={closeImg} className="w-[11px]" />
      </div>
      {TypeItem[type]()}
    </div>
  );
});

export function MyToast() {
  const [message, setMessage] = useState<(ToastItemProps & { isDelete?: boolean })[]>([]);

  useEffect(() => {
    addMessage = (type, text, timer) => {
      setMessage((message) => {
        message.push({
          type,
          text,
          timer,
          index: message.length,
        });
        return [...message];
      });
    };
    return () => {
      addMessage = null;
    };
  }, [setMessage]);

  return (
    <>
      {message
        .filter((i) => !i.isDelete)
        .map((item, index) => {
          return (
            <ToastItem
              {...item}
              key={item.index}
              index={index}
              onDelete={() => {
                message[item.index].isDelete = true;
                setMessage((message) => {
                  return [...message];
                });
              }}
            />
          );
        })}
    </>
  );
}
