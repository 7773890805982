import { clsxm } from '@hidock/utils';
import { templateReq } from '~/api/templateReq';
import { showConfirmAtom } from '~/components/deleteConfrim';
import OverflowHiddenText from '~/components/general/Text';
import { useFocus } from '~/hooks/useFocus';
import { useMyNavigate } from '~/hooks/useNavigate';
import { sleep } from '~/utils/sleep';
import { useMemoizedFn, useRequest } from 'ahooks';
import { Tooltip } from 'antd';
import { getDefaultStore } from 'jotai';
import { CSSProperties, SVGProps, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';

const PopUpContent = styled.div`
  .my-item {
  }
`;

const Svg = {
  downArrow(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        version="1.1"
        width="11.666666030883789"
        height="6.666666507720947"
        viewBox="0 0 11.666666030883789 6.666666507720947"
        {...props}
      >
        <g>
          <path
            d="M0.244077,0.244077C0.569514,-0.0813592,1.09715,-0.0813592,1.42259,0.244077C1.42259,0.244077,5.83333,4.65483,5.83333,4.65483C5.83333,4.65483,10.2441,0.244077,10.2441,0.244077C10.5695,-0.0813592,11.0972,-0.0813592,11.4226,0.244077C11.748,0.569514,11.748,1.09715,11.4226,1.42259C11.4226,1.42259,6.42259,6.42259,6.42259,6.42259C6.09715,6.74802,5.56952,6.74802,5.24407,6.42259C5.24407,6.42259,0.244077,1.42259,0.244077,1.42259C-0.0813592,1.09715,-0.0813592,0.569514,0.244077,0.244077C0.244077,0.244077,0.244077,0.244077,0.244077,0.244077Z"
            fillRule="evenodd"
            fill="#767676"
          />
        </g>
      </svg>
    );
  },
  leftArrow(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        fill="none"
        version="1.1"
        width="11"
        height="18"
        viewBox="0 0 11 18"
        {...props}
      >
        <g transform="matrix(0,-1,1,0,-18,18)">
          <path d="M9,18L16.7942,26.25L1.20577,26.25L9,18Z" fill="#D8D8D8" />
        </g>
        <g transform="matrix(0,-1,1,0,-18,18)">
          <path
            d="M9,18L16.7942,26.25L1.20577,26.25L9,18Z"
            fill="white"
            className=" origin-[center_right] scale-[1.2]"
          />
        </g>
      </svg>
    );
  },
  rightArrow(props: SVGProps<SVGSVGElement>) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        fill="none"
        version="1.1"
        width="6.666666507720947"
        height="11.666666030883789"
        viewBox="0 0 6.666666507720947 11.666666030883789"
        {...props}
      >
        <g transform="matrix(0,-1,1,0,-11.666666030883789,11.666666030883789)">
          <path
            d="M0.244077,11.91074303088379C0.569514,11.58530683088379,1.09715,11.58530683088379,1.42259,11.91074303088379C1.42259,11.91074303088379,5.83333,16.32149603088379,5.83333,16.32149603088379C5.83333,16.32149603088379,10.2441,11.91074303088379,10.2441,11.91074303088379C10.5695,11.58530683088379,11.0972,11.58530683088379,11.4226,11.91074303088379C11.748,12.236180030883789,11.748,12.763816030883788,11.4226,13.089256030883789C11.4226,13.089256030883789,6.42259,18.08925603088379,6.42259,18.08925603088379C6.09715,18.41468603088379,5.56952,18.41468603088379,5.24407,18.08925603088379C5.24407,18.08925603088379,0.244077,13.089256030883789,0.244077,13.089256030883789C-0.0813592,12.763816030883788,-0.0813592,12.236180030883789,0.244077,11.91074303088379C0.244077,11.91074303088379,0.244077,11.91074303088379,0.244077,11.91074303088379Z"
            fill="#767676"
          />
        </g>
      </svg>
    );
  },
};

export function TemplateSelect(props: {
  select?: string;
  defaultSelect?: string;
  onChange?: (value: string) => void;
  onChaneDefault?: (value: string) => void;
  style?: CSSProperties;
  className?: string;
  isOptional?: boolean;
}) {
  const { t } = useTranslation();
  const listQuery = useRequest(() => templateReq.list({ pageSize: 100 }), {
    cacheKey: `templateReq.list`,
  });
  const navigate = useMyNavigate();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { data: list } = listQuery;
  const [open, setOpen] = useState(false);
  const [contentCss, setContentCss] = useState<CSSProperties>({});
  const [leftArrowCss, setLeftArrowCss] = useState<CSSProperties>({});

  const selectData = useMemo(() => {
    return list?.content.find((i) => i.code == props.select);
  }, [props.select, list]);

  const leftArrowRect = {
    width: 11,
    height: 18,
  };
  const contentRect = {
    width: 300,
  };

  enum DomId {
    leftArrow = 'leftArrow',
    popUpContent = 'popUpContent',
  }

  const updatePopUpPos = useMemoizedFn(() => {
    const container = containerRef.current;
    const content = contentRef.current;
    if (container && content && open) {
      const rect = container.getBoundingClientRect();
      const leftArrowDom = content.querySelector(`#${DomId.leftArrow}`) as HTMLDivElement;
      const popUpContent = content.querySelector(`#${DomId.popUpContent}`) as HTMLDivElement;
      const innerW = window.innerWidth;
      const innerH = window.innerHeight;
      const leftArrowStyle = {
        left: rect.x + rect.width + 10 - 11 + 3,
        top: rect.y + rect.height / 2 - leftArrowRect.height / 2,
      };

      const contentStyle = {
        left: rect.x + rect.width + 10,
        top: Math.min(rect.y - 20, innerH - content.clientHeight),
      };

      let state = contentStyle.left + contentRect.width > innerW;
      if (state) {
        Object.assign(leftArrowStyle, {
          transformOrigin: 'center center',
          transform: 'rotate(180deg)',
          left: (rect.x + rect.width / 2) * 2 - leftArrowStyle.left - leftArrowRect.width,
        });
        Object.assign(contentStyle, {
          left: (rect.x + rect.width / 2) * 2 - contentStyle.left - contentRect.width,
        });
      }

      const generalMaxH = 550;
      let maxH = Math.min(innerH, generalMaxH);
      //为了保证item元素一个窗口不能把元素显示完整，所以最大高度相对于原来扣除item的高度 + 上下边距 的整数倍
      maxH = generalMaxH - Math.ceil((generalMaxH - maxH) / 43) * 43;
      setContentCss({
        visibility: 'visible',
        position: 'fixed',
        ...contentStyle,
      });
      setLeftArrowCss({
        ...leftArrowStyle,
        position: 'fixed',
      });
    }
  });

  useEffect(() => {
    if (open) {
      updatePopUpPos();
    } else {
      setContentCss({});
      setLeftArrowCss({});
    }
  }, [open]);

  useEffect(() => {
    function handle() {
      updatePopUpPos();
    }
    window.addEventListener('resize', handle);
    return () => {
      window.removeEventListener('resize', handle);
    };
  }, []);

  useEffect(() => {
    const container = (document.querySelector('#device-file-list-container') as HTMLDivElement) || null;
    function handler() {
      setOpen(false);
    }
    container?.addEventListener('scroll', handler);

    return () => {
      container?.removeEventListener('scroll', handler);
    };
  }, []);

  useEffect(() => {
    function mouseDownHandle() {
      setOpen(false);
    }
    window.addEventListener('mousedown', mouseDownHandle);

    return () => {
      window.removeEventListener('mousedown', mouseDownHandle);
    };
  }, []);

  return (
    <>
      {/* 入口按钮 */}
      <div
        className={clsxm(
          ' relative h-[34px] w-[210px] rounded-[8px] border border-[rgb(217,217,217)] text-left',
          props.className
        )}
        style={{
          pointerEvents: props.isOptional ? 'auto' : 'none',
          ...props.style,
        }}
        ref={containerRef}
        onMouseUp={() => {
          setOpen(!open);
        }}
        onMouseDown={(e) => {
          if (open) e.stopPropagation();
        }}
      >
        {props.isOptional ? (
          <div
            className={clsxm(
              ' flex h-full w-full cursor-pointer  items-center rounded-[8px] bg-white px-[15px] py-[5px] font-bold text-[rgb(26,26,26)]'
            )}
          >
            <OverflowHiddenText
              text={selectData?.title || props.select || t('note.template.select.default2')}
              overflow="hidden"
              className="w-[166px] flex-1"
            />
            <Svg.downArrow className="" />
          </div>
        ) : (
          <div
            className={clsxm(
              ' flex h-full w-full cursor-pointer items-center  justify-center rounded-[8px] bg-[#EDEDED] px-[15px] py-[5px] font-bold text-[rgb(26,26,26)]'
            )}
          >
            <OverflowHiddenText
              text={selectData?.title || props.select || t('note.template.select.default2')}
              overflow="hidden"
              className="w-[166px] flex-1"
            />
          </div>
        )}
      </div>

      {/* 弹窗 */}
      {open && (
        <div
          className={clsxm(
            ' rounded-[12px] border-[0.5px] border-[#9C9C9C] bg-white p-[10px] shadow-[0px_0px_20px_0px_rgba(0,0,0,0.2)]',
            ' flex max-h-[550px] flex-col text-[14px] font-[600]'
          )}
          style={{
            visibility: 'hidden',
            position: 'fixed',
            width: contentRect.width,
            ...contentCss,
          }}
          ref={contentRef}
          onMouseUp={(e) => {
            e.stopPropagation();
          }}
          onMouseDown={(e) => {
            e.stopPropagation();
          }}
        >
          <PopUpContent
            id={DomId.popUpContent}
            className={clsxm(
              'scrollbar  scrollbar-thumb-gray-500 scrollbar-track-gray-200 flex-1 overflow-x-hidden overflow-y-scroll'
            )}
          >
            {list?.content.map((item) => {
              return (
                <div
                  className={clsxm(
                    ' my-[3px] flex h-[40px] w-full cursor-pointer items-center rounded-[8px] p-[10px] text-left hover:bg-[#F2F2F2]'
                  )}
                  key={item.code}
                  style={{
                    color: item.hasTemplateAccess ? undefined : '#A1AAAD',
                  }}
                  onClick={(e) => {
                    if (!item.hasTemplateAccess) {
                      const store = getDefaultStore();
                      store.set(showConfirmAtom, {
                        isShow: true,
                        title: t('device.audio-manage.template-select.modal.title'),
                        message: t('device.audio-manage.template-select.modal.message'),
                        confirmText: t('device.audio-manage.template-select.modal.confirmText'),
                        cancelText: t('device.audio-manage.template-select.modal.cancelText'),
                        confirm() {
                          navigate('/notes?userInfo=true', {
                            state: {
                              tab: 'plans',
                            },
                          });
                          store.set(showConfirmAtom, showConfirmAtom.init);
                        },
                        cancel() {
                          store.set(showConfirmAtom, showConfirmAtom.init);
                          setOpen(true);
                        },
                      });
                    } else {
                      props.onChange?.(item.code);
                    }

                    setOpen(false);
                  }}
                >
                  <OverflowHiddenText text={item.title} overflow="hidden" className=" mr-[10px] flex-1 text-left" />
                  {
                    <div
                      className=" mr-[8px] w-[60px] overflow-hidden text-ellipsis whitespace-pre rounded-[6px] bg-[rgba(0,162,175,1)] p-[2px_4px] text-center text-[14px] font-bold leading-[22px] text-white"
                      style={
                        !item.default
                          ? {
                              visibility: 'hidden',
                              pointerEvents: 'none',
                            }
                          : undefined
                      }
                      title={t('note.template.select.default')}
                    >
                      {t('note.template.select.default')}
                    </div>
                  }
                </div>
              );
            })}
          </PopUpContent>
          <div className=" my-[6px] h-[1px] bg-[#DDDDE1]" />

          <div
            className=" relative h-[40px] cursor-pointer rounded-[8px] p-[10px] text-left leading-[20px] hover:bg-[#F2F2F2]"
            onClick={() => {
              navigate('/notes?userInfo=true', {
                state: {
                  tab: 'tempalteLibrary',
                },
              });
            }}
          >
            {t('note.template.select.my-template')}
            <Svg.rightArrow className=" absolute right-[10px] top-1/2 h-[12px] w-[7px] -translate-y-1/2" />
          </div>
          {/* 弹窗指向来自哪个按钮的那个箭头 */}
          {
            <Svg.leftArrow
              id={DomId.leftArrow}
              style={{
                position: 'fixed',
                ...leftArrowRect,
                ...leftArrowCss,
              }}
            />
          }
        </div>
      )}
    </>
  );
}
