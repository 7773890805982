import { cancelReq } from '~/api/notes/cancel';
import { useUploadAudioMutation } from '~/api/notes/upload';
import { ProcessingBlock } from '~/components/ProcessingBlock';
import { generalErrorHandle } from '~/utils/utils';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { string } from 'zod';

export const Uploading: FC<{
  file?: File | null;
  onUploaded?: (ticket?: string) => void;
  onError?: () => void;
  onCancel?: () => void;
  className?: string;
}> = ({ file, onUploaded, onError, onCancel, className }) => {
  const { t } = useTranslation();
  const uploadedRef = useRef(false);
  const isCancelRef = useRef<boolean>(false);
  const { mutateAsync } = useUploadAudioMutation({
    onSuccess(ticket) {
      if (isCancelRef.current) {
        cancelReq({
          ticket,
        }).then((res) => {
          //@ts-ignore
          if (res?.error) generalErrorHandle(res, t);
        });
        console.log('cancel uploading');
        return;
      }
      if (typeof ticket == 'string') onUploaded?.(ticket);
      else onError?.();
    },
    onError() {
      onError?.();
    },
  });

  useEffect(() => {
    if (!file || uploadedRef.current) return;
    uploadedRef.current = true;
    const formData = new FormData();
    formData.append('file', file);
    console.log('file upload info', formData);

    mutateAsync({
      formData,
    });
  }, [file, mutateAsync]);

  return (
    <ProcessingBlock
      className={className}
      label={t('note.processing.uploading') + ' ...'}
      onCancel={() => {
        onCancel?.();
        isCancelRef.current = true;
      }}
    />
  );
};
