import { request } from '~/lib/axios';
import { reqParamsToBody } from './utils';

function support(params: {
  content: string;
  email: string;
  title: string;
  files: File[];
  os: string;
  browser: string;
}) {
  const form = new FormData();
  Object.entries(params).forEach(([key, data]) => {
    if (data instanceof Array) {
      for (let i = 0; i < data.length; i++) form.append(key, data[i]);
    } else {
      form.append(key, data);
    }
  });
  return request.post('/v1/support/submit', form);
}

export type Question = {
  title: string;
  url: string;
};

function helpQuestion(props: { category?: string; language?: string; count?: number }) {
  return request.post<Question[]>('/v1/help/questions', reqParamsToBody(props));
}

export const helpReq = {
  support,
  helpQuestion,
};
