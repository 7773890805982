import { request } from '~/lib/axios';
import { reqParamsToBody } from '../utils';

type Params = {
  noteId: string;
  title: string;
};

export const updateTitle = (params: Params) => {
  return request.post('/v2/note/title/update', reqParamsToBody(params));
};
