import { TagVariant } from '~/components/note/Tag';
import { request } from '~/lib/axios';
import { Scope } from '~/pages/notes/Header/widgets/noteSearchPanel/config.ts';
import { reqParamsToBody } from '../utils';

type Params = {
  keyword: string;
};

export type SearchContent = {
  id: string;
  tagId: null;
  tagName: null;
  state: null;
  title: string;
  memberCount: null;
  language: string;
  createTime: number;
  score: null;
  summary: null;
  conciseSummary: null;
  type: TagVariant;
  html: null;
  json: null;
  markdown: null;
  shortId: null;
  marks: null;
};

export function SearchReq(params: Params) {
  return request.post<SearchContent[]>('/v2/note/search', reqParamsToBody(params));
}

export function NoteSearchReq(params: { keyword: string; scope: Scope; pageIndex: number; pageSize: number }) {
  return request.post('/v2/note/find', reqParamsToBody(params));
}
