/**
 *
 * @param totalSeconds 单位是秒
 * @param hasHours 是否一定要显示小时
 * @returns
 */
export function formatSecondsToProgress(totalSeconds: number, hasHours?: boolean) {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  const seconds = totalSeconds - hours * 3600 - minutes * 60;

  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');
  const formattedHours = String(hours).padStart(2, '0');

  if (hasHours) {
    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  if (hours > 0) {
    return `${hours}:${formattedMinutes}:${formattedSeconds}`;
  }

  return `${formattedMinutes}:${formattedSeconds}`;
}
