import { Transcription } from '~/api/notes/transcription';
import { reqParamsToBody } from '~/api/utils';
import { request } from '~/lib/axios';

type Parmas = {
  noteId: string;
};

export type Transcription2 = {
  id: string;
  beginTime: number;
  endTime: number;
  duration: number;
  sentence: string;
  highlighted: boolean;
  speaker: string | null;
  tokens: string;
};

export const transcription2 = (params: Parmas) => {
  return request.post<Transcription2[]>('/v2/note/transcription/list', reqParamsToBody(params));
};
