import { reqParamsToBody } from '~/api/utils';
import { request } from '~/lib/axios';
import { sleep } from '~/utils/sleep';
import { useEffect, useState } from 'react';

type Params = {
  noteId: string;
};

export const Resample = async (params: Params) => {
  const data = await request.post<string>('/v2/note/audio/resample', reqParamsToBody(params));
  return data;
};

export function useResample(params: Params) {
  const res = useState<number[]>([]);
  useEffect(() => {
    async function exec() {
      const info = await Resample(params);
      //@ts-ignore
      if (info.error == 90000) {
        await sleep(2000);
        exec();
      } else {
        res[1](
          info.split('').map((v: string) => {
            const num = v.charCodeAt(0);
            const aAscll = 'a'.charCodeAt(0);
            if (num >= aAscll) return num - aAscll + 10;
            return num + 0 - 48;
          })
        );
      }
    }
    exec();
  }, []);
  return res;
}
