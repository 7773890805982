import { clsxm } from '@hidock/utils';
import { showConfirmAtom } from '~/components/deleteConfrim';
import i18n from '~/i18n';
import { parseDoubleBracketSyntax } from '~/utils/utils';
import { useKeyPress } from 'ahooks';
import { useSetAtom } from 'jotai';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SpeakerList } from '../note-result2';
import { colors } from '../pubilcInfo';
import { abbreviationName, getStrHash } from '../utils';
import css from './index.module.css';
import RightArrow from './right-arrow';

type Props = {
  speakerList: SpeakerList;
  domRect: DOMRect;
  onSelect: (name: string) => void;
  inputPlaceholder: string;
  defaultName?: string;
};

function ModeSpeaker(props: Props) {
  const { speakerList, domRect, onSelect, inputPlaceholder } = props;
  const { t } = useTranslation();
  const setShowConfirm = useSetAtom(showConfirmAtom);
  const width = 248;
  const height = 248;
  // const avatorRect = avator.getBoundingClientRect();
  const isDown = window.innerHeight > 50 + height + domRect.bottom + 5;
  const [inputFocus, setInputFocus] = useState(false);

  const reviseHandle = () => {
    if (name.trim()) onSelect?.(name);
  };

  useKeyPress('enter', () => {
    reviseHandle();
  });

  const [name, setName] = useState(props.defaultName || '');
  const input = (
    <div className={`relative`} key={'input'}>
      <input
        className="focus:text-whtie w-[100%] rounded-[8px] border border-[rgba(245,245,245,0.5)] bg-[#E5E5E5] p-[10px_6px] text-[16px] font-[600] text-black outline-none placeholder:text-[#9C9C9C]"
        placeholder={inputPlaceholder}
        maxLength={30}
        onChange={(e) => setName(e.currentTarget.value)}
        value={name}
        onBlur={() => setInputFocus(false)}
        onFocus={() => setInputFocus(true)}
      />
      <RightArrow
        className=" absolute right-[10px] top-[43%] h-[10px] scale-[2]"
        onClick={reviseHandle}
        style={{
          stroke: inputFocus ? '#3A3A3A' : '#ACACAC',
        }}
      />
    </div>
  );
  const selects = (
    <div
      className={clsxm(
        `w-full max-h-[${height}px]`,
        `scrollbar scrollbar-thumb-gray-500 scrollbar-track-gray-200 overflow-y-scroll`
      )}
      key={'select'}
    >
      {speakerList.map(({ speaker: name, color }, idx) => {
        if (!name) return null;
        return (
          <>
            <div className=" my-[8px] h-[1px] bg-[#DDDDE1]" />
            <div
              key={name}
              className=" flex w-full cursor-pointer items-center"
              onClick={() => {
                setShowConfirm({
                  isShow: true,
                  message: parseDoubleBracketSyntax(i18n.t('note.rename-confirm.content'), {
                    target1: props.defaultName,
                    target2: name,
                  }),
                  confirm: () => {
                    onSelect?.(name);
                    setShowConfirm((state) => {
                      return {
                        ...state,
                        isShow: false,
                      };
                    });
                  },
                  cancel: () => setShowConfirm((state) => ({ ...state, isShow: false })),
                  cancelText: t('misc.modal.cancel'),
                  confirmText: t('misc.modal.confirm'),
                });
              }}
            >
              <div
                className={` mr-[4px] flex aspect-square w-[38px] items-center justify-center rounded-full text-[16px] font-[700]`}
                style={{
                  color: 'rgba(58,58,58,1)',
                  backgroundColor: color,
                  // boxShadow:"5px 5px 10px rgba(0,0,0,0.2),-5px -5px 10px rgba(0,0,0,0.2),-5px 5px 10px rgba(0,0,0,0.2),5px -5px 10px rgba(0,0,0,0.2)"
                }}
              >
                {abbreviationName(name).toUpperCase()}
              </div>
              <span className=" text-[16px] font-[600] text-[#686868]">{name}</span>
            </div>
            {idx == speakerList.length - 1 && <div className=" my-[8px] h-[1px] bg-[#DDDDE1]" />}
          </>
        );
      })}
    </div>
  );
  return (
    <div
      className=" fixed  z-10 box-border rounded-[8px] bg-white p-[10px] text-white shadow-[0px_0px_20px_0px_rgba(0,0,0,0.3)] "
      style={{
        width,
        top: isDown ? domRect.bottom - 20 : domRect.top,
        left: domRect.left - 10,
        transform: isDown ? '' : 'translate(0,-100%)',
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {isDown ? [input, selects] : [selects, input]}
    </div>
  );
}

export default ModeSpeaker;
