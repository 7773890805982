import { clsxm } from '@hidock/utils';
import { noteTemplate, templateReq } from '~/api/templateReq';
import UpgradeTip from '~/components/upgradeTip';
import { sleep } from '~/utils/sleep';
import { useRequest } from 'ahooks';
import { t } from 'i18next';
import { atom, useAtom, useSetAtom } from 'jotai';
import { CSSProperties, ReactNode, SVGProps, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from 'styled-components';
import { Config } from 'tailwindcss';
import { userInfoTabAtom } from '..';
import { MyModal } from '../../general/myModal';
import { globalAddEl } from '../../globalLayout';
import { showGlobalLoadingAtom } from '../../globalLoad';
import { myMessage } from '../../MyToast/MyToast';
import tags from './svg';

export const returnOpenTemplateInfoAtom = atom({
  code: '',
  open: false,
});

function TemplateInfoModal(props: { code: string; setDefaultHandle?: () => Promise<boolean>; onClose?: () => void }) {
  const { code } = props;
  const infoQuery = useRequest(() => templateReq.info({ code }));
  const { data } = infoQuery;
  const setGlobalLoading = useSetAtom(showGlobalLoadingAtom);
  const [loading, setLoading] = useState(false);
  const setUserInfoTab = useSetAtom(userInfoTabAtom);
  const setReturnOpenTemplate = useSetAtom(returnOpenTemplateInfoAtom);
  useEffect(() => {
    setGlobalLoading(infoQuery.loading);
  }, [infoQuery.loading]);

  if (infoQuery.loading) return null;

  function getButtonInfo() {
    let cssProps: CSSProperties = {
      background: '#00A2AF',
    };
    let textEl: ReactNode = undefined;
    let onClick: (() => void) | undefined = undefined;

    if (loading) {
      textEl = <img src="/loading.svg" />;
    }

    if (!data?.template.hasTemplateAccess) {
      textEl = <span>{t('user.preferences.summary_template.view.upgrade_tip')}</span>;
      onClick = () => {
        setUserInfoTab('plans');
        props.onClose?.();
        setReturnOpenTemplate({
          code,
          open: false,
        });
      };
    } else if (data?.template.default) {
      cssProps.background = '#E1E3E5';
      cssProps.pointerEvents = 'none';
      cssProps.color = '#1A1A1A';
      textEl = <span>{t('user.preferences.summary_template.view.current_default_template')}</span>;
    } else {
      onClick = async () => {
        setLoading(true);
        Promise.all([sleep(500), props.setDefaultHandle?.()]);
        infoQuery.mutate((res) => {
          if (!res) return res;
          res.template.default = true;
          return {
            ...res,
          };
        });
        setLoading(false);
      };

      textEl = <span>{t('note.template.set-as-default')}</span>;
    }

    return {
      css: cssProps,
      textEl,
      onClick,
    };
  }

  const buttonInfo = getButtonInfo();

  return (
    <MyModal className=" flex w-[960px] flex-col rounded-[24px] bg-[#F4F4F4] px-[25px]" onClose={props.onClose}>
      <h1 className="mt-[50px] h-[40px] text-[28px] font-bold leading-[40px]">{data?.template.title}</h1>
      <div className=" mt-[20px] bg-white py-[20px] pr-[6px]">
        <div
          className={clsxm(
            ' h-[520px] pl-[20px] pr-[13px] ',
            'scrollbar  scrollbar-thumb-gray-500 scrollbar-track-gray-200 overflow-x-hidden overflow-y-scroll'
          )}
        >
          <p className=" whitespace-pre-wrap text-[18px] text-[#767676]">{data?.template.description}</p>
          {data?.fields.map((field) => {
            return (
              <div key={field.id} className=" text-[#1A1A1A]">
                <h2 className=" mt-[20px] text-[24px] font-[600] leading-[32px]">{field.name}</h2>
                <p className=" mt-[10px] whitespace-pre-wrap text-[18px] font-[400] leading-[28px]">{field.prompt}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div className=" mb-[36px] mt-[36px] flex w-full justify-center">
        {
          <button
            className="flex justify-center whitespace-pre rounded-full px-[24.5px] py-[10px] text-[16px] font-[600]  text-white"
            style={buttonInfo.css}
            onClick={buttonInfo.onClick}
          >
            {buttonInfo.textEl}
          </button>
        }
      </div>
    </MyModal>
  );
}

function TabBar(props: { onChange?: (category: string) => void }) {
  const config = [
    {
      name: 'all',
      color: 'black',
      'bg-select': '#00A2AF',
      bg: '#ECF7F7',
      border: '#00A2AF',
    },
    {
      name: 'class',
      color: '#F9733E',
      bg: '#FFF2ED',
      border: '#F9733E',
    },
    {
      name: 'meeting',
      color: '#00A2AF',
      bg: '#EFF7F7',
      border: '#00A2AF',
    },
    {
      name: 'life',
      color: '#E058E2',
      bg: '#FAF0FA',
      border: '#E058E2',
    },
    {
      name: 'media',
      color: '#F32EA4',
      bg: '#FFF5FB',
      border: '#F32EA4',
    },
  ] as const;
  const [select, setSelect] = useState<string>('all');

  return (
    <div className=" mb-[30px] mt-[20px] flex">
      {config.map((item) => {
        const isSelect = item.name == select;

        return (
          <button
            className=" mr-[15px] flex h-[36px] w-[100px] items-center justify-center rounded-[10px] border"
            style={{
              borderColor: isSelect ? item.border : 'transparent',
              // boxShadow: !isSelect ? '' : `0px 0px 5px 0px ${item.color}`,
              background: item.name == 'all' && isSelect ? item['bg-select'] : item.bg,
              color: item.name == 'all' && isSelect ? 'white' : item.color,
            }}
            onClick={() => {
              setSelect(item.name);
              props.onChange?.(item.name);
            }}
          >
            <img src={tags[isSelect && item.name == 'all' ? item.name + '-white' : item.name]} className=" mr-[10px]" />
            <span className=" text-[14px] font-[700] leading-[28px]">
              {t(`user.preferences.summary_template.type.${item.name}`)}
            </span>
          </button>
        );
      })}
    </div>
  );
}

const Decription = styled.p`
  max-width: 300px; /* 容器的最大宽度 */
  overflow: hidden; /* 隐藏超出的内容 */
  text-overflow: ellipsis; /* 使用省略号来标记被修剪的文本 */
  display: -webkit-box; /* 使用弹性盒子布局 */
  -webkit-line-clamp: 4; /* 显示的行数 */
  -webkit-box-orient: vertical; /* 设置垂直堆叠 */
`;
const ListContainer = styled.div`
  .btn-container {
    transition: 0.3s ease-in-out;
    transform: translate(0, 100%);
  }

  &:hover {
    .btn-container {
      transform: translate(0, 0);
    }
  }
`;
export function TemplateLibrary() {
  const { t } = useTranslation();
  const [select, setSelect] = useState<string>('all');
  const setGlobalLoading = useSetAtom(showGlobalLoadingAtom);
  const listQuery = useRequest(
    () => {
      let params: {
        pageSize?: number | undefined;
        type?: string | undefined;
      } = {
        pageSize: 100,
      };
      if (select && select != 'all') params.type = select;
      return templateReq.list(params);
    },
    {
      cacheKey: 'templateReq.list',
      refreshDeps: [select],
    }
  );
  const { data } = listQuery;
  const list = data?.content || [];
  const [returnOpenTemplateInfo, setReturnOpenTemplateInfo] = useAtom(returnOpenTemplateInfoAtom);

  const header = (
    <div className="mb-[20px] text-[26px] font-[600] leading-[18.72px]">{t('user.info.template.library')}</div>
  );

  return (
    <div className=" box-content flex flex-1  flex-col">
      {header}
      <TabBar
        onChange={(select) => {
          setSelect(select);
        }}
      />
      <p className=" text-[18px] font-[600] leading-[18.72px] text-black">
        {t('user.preferences.summary_template.title2')}
      </p>
      <div
        className={clsxm(
          ' ml-[-5px] mt-[12px] grid flex-1 grid-cols-3 gap-[10px] p-[10px] pl-[5px]',
          'scrollbar scrollbar-thumb-gray-500 scrollbar-track-gray-200 overflow-x-hidden overflow-y-scroll',
          'w-[890px]'
        )}
      >
        {list.map((item) => {
          async function setDefaultHandle() {
            const res = await templateReq.setDefault({ code: item.code });
            if (!res) {
              listQuery.refresh();
              item.default = true;
              listQuery.mutate((data) => {
                if (!data) return data;
                data.content = [...data.content];
                return {
                  ...data,
                };
              });
              myMessage.success(t('note.template.set-as-default.success'), 2000);
              return true;
            }
            return false;
          }

          function clickHandle() {
            const order = globalAddEl(
              <TemplateInfoModal code={item.code} setDefaultHandle={setDefaultHandle} onClose={() => order.remove()} />,
              { isFullScreen: false }
            );
          }

          if (returnOpenTemplateInfo.open && returnOpenTemplateInfo.code == item.code) {
            returnOpenTemplateInfo.open = false;
            clickHandle();
            setReturnOpenTemplateInfo(returnOpenTemplateInfoAtom.init);
          }

          function getButton() {
            if (!item.hasTemplateAccess) {
              return null;
            } else if (!item.default) {
              return (
                <button
                  className=" mx-[4px] my-[5px] h-[32px] min-w-[160px] whitespace-pre rounded-[8px] bg-[#00A2AF] px-[9px]"
                  onClick={async () => {
                    setGlobalLoading(true);
                    await setDefaultHandle();
                    setGlobalLoading(false);
                  }}
                  title={t('user.preferences.summary_template.set_default')}
                >
                  {t('user.preferences.summary_template.set_default')}
                </button>
              );
            } else {
              return (
                <button
                  className=" mx-[4px] my-[5px] h-[32px] min-w-[160px] cursor-default whitespace-pre rounded-[8px] bg-[#A1AAAD] px-[9px]"
                  title={t('user.preferences.summary_template.default')}
                >
                  {t('user.preferences.summary_template.default')}
                </button>
              );
            }
          }

          return (
            <>
              <ListContainer
                className={clsxm(
                  ' relative h-[265px] w-[280px] overflow-hidden rounded-[10px] px-[14px] py-[16px]',
                  'border-[1.5px]',
                  'bg-[#F7F8F9] hover:shadow-[0px_0px_6px_0px_rgba(0,0,0,0.2)]'
                )}
                key={item.code}
                style={{
                  borderColor: item.default ? '#00A2AF' : 'transparent',
                }}
              >
                <div className=" h-full w-full cursor-pointer" onClick={clickHandle}>
                  <h2 className=" flex flex-wrap items-center text-[18px] font-[600]">
                    <span
                      className=" max-w-[180px] overflow-hidden text-ellipsis whitespace-pre leading-[1.5]"
                      title={item.title}
                    >
                      {item.title}
                    </span>
                    {/* <img src="/userInfo/pro.png" className=" ml-[5px] w-[41px] aspect-[41.5/23]" /> */}
                  </h2>

                  <div
                    className=" my-[12px] flex flex-wrap overflow-hidden"
                    style={{
                      height: item.tags?.length ? 52 : 0,
                    }}
                  >
                    {item.tags?.map((item, idx) => {
                      return (
                        <button
                          className=" mb-[4px] mr-[5px] box-border h-[24px] rounded-[8px] bg-[#FDFFFF] px-[4px] py-[4.5px] text-[12px] font-[600] text-[#686868]"
                          key={idx}
                        >
                          {item}
                        </button>
                      );
                    })}
                  </div>

                  <Decription className="h-[75px] overflow-hidden whitespace-pre-wrap text-[14px] text-[#404040]">
                    {item.description}
                  </Decription>
                </div>

                <div className=" btn-container absolute bottom-[1.5px] left-[1.5px] flex min-h-[90px] w-[calc(100%-3px)] flex-wrap justify-center rounded-b-[10px] bg-[rgba(247,248,249,0.9)] px-[8px] pt-[41px] text-[14px] font-[600] text-white">
                  {getButton()}
                  <button
                    className=" mx-[4px] mb-[16px] mt-[5px] h-[32px] min-w-[160px] whitespace-pre rounded-[8px] bg-[#00A2AF] px-[9px]"
                    title={t('user.preferences.summary_template.view')}
                    onClick={clickHandle}
                  >
                    {t('user.preferences.summary_template.view')}
                  </button>
                </div>
              </ListContainer>
            </>
          );
        })}
      </div>
    </div>
  );
}
