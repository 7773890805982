import { atom, useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

const select = atom({
  tagId: '',
  folderId: '',
});

const showType = atomWithStorage<'tiling' | 'list'>('noteShowType', 'tiling');

export const noteStore = {
  select,
  showType,
};
