import { clsxm } from '@hidock/utils';
import type { FC } from 'react';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import remarkGfm from 'remark-gfm';

export const Markdown: FC<{ className?: string; markdown: string }> = ({ className, markdown }) => {
  return (
    <ReactMarkdown
      className={clsxm('prose max-w-full', 'prose-sm', 'prose-headings:mt-0', className)}
      remarkPlugins={[remarkGfm]}
    >
      {markdown}
    </ReactMarkdown>
  );
};
