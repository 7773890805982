import { LogLevel, PublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';

const loginRequest = {
  scopes: ['Notes.ReadWrite', 'Notes.Create', 'Notes.ReadWrite.All'],
};

async function getMsalInstance() {
  const msalInstance = new PublicClientApplication({
    auth: {
      clientId: 'cf755288-081e-4577-9c45-953943ed8492',
      authority: 'https://login.microsoftonline.com/consumers',
      redirectUri: `${location.protocol}//${location.host}/`,
    },
    cache: {
      cacheLocation: 'sessionStorage', // This configures where your cache will be stored
      storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
          }
        },
      },
    },
  });
  await msalInstance.initialize();
  return msalInstance;
}

async function createPage(
  token: string,
  shareInfo: {
    title: string;
    domHTML: string;
  }
) {
  const notebookRes = await axios({
    method: 'GET',
    url: 'https://graph.microsoft.com/v1.0/me/onenote/notebooks',
    // auth: access_token,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    data: {
      displayName: 'HiNotes',
    },
  });
  const noteBooks = notebookRes.data.value;

  //@ts-ignore
  let hinotesBook = noteBooks.find((i) => i.displayName.toLocaleLowerCase() == 'hinotes');
  console.log('hinotesBook', hinotesBook);

  if (!hinotesBook) {
    hinotesBook = (
      await axios({
        method: 'POST',
        url: 'https://graph.microsoft.com/v1.0/me/onenote/notebooks',
        // auth: access_token,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: {
          displayName: 'HiNotes',
        },
      })
    ).data;
  }

  const sectionsRes = await axios({
    method: 'GET',
    url: `https://graph.microsoft.com/v1.0/me/onenote/notebooks/${hinotesBook.id}/sections`,
    // url:`/user/${hinotesBook.id}/onenote/sections`,
    // auth: access_token,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  console.log('sectionsRes', sectionsRes);
  const sections = sectionsRes.data.value;
  //@ts-ignore
  let shareSention = sections.find((i) => {
    let state =
      i.displayName.toLocaleLowerCase() == 'hinotes' && i.parentNotebook.displayName.toLocaleLowerCase() == 'hinotes';
    return state;
  });

  if (!shareSention) {
    shareSention = (
      await axios({
        method: 'POST',
        url: `https://graph.microsoft.com/v1.0/me/onenote/notebooks/${hinotesBook.id}/sections`,
        // auth: access_token,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        data: {
          displayName: 'HiNotes',
          position: 'After',
        },
      })
    ).data;
  }
  console.log('shareSention', shareSention);

  const createPageRes = await axios({
    method: 'POST',
    url: `https://graph.microsoft.com/v1.0/me/onenote/sections/${shareSention.id}/pages`,
    headers: {
      'Content-Type': 'application/xhtml+xml',
      Authorization: `Bearer ${token}`,
    },
    data: `<!DOCTYPE html>
        <html>
          <head>
            <title>${shareInfo.title}</title>
            <meta name="created" content="${new Date().toLocaleDateString()}" />
          </head>
          <body>
          ${shareInfo.domHTML}
        </html>`,
  });

  console.log('createPageRes', createPageRes);

  // return pageRes;
}

export async function oneNoteShare(title: string, domHTML: string) {
  // async function login() {
  //     const loginInfo = await msalInstance.loginPopup(loginRequest);
  //     console.log('popup', loginInfo);
  // }

  // async function shareHandle() {
  //     let response = await msalInstance.acquireTokenSilent({ ...loginRequest, });
  //     if (!response) await login();
  //     response = await msalInstance.acquireTokenSilent({ ...loginRequest, });
  //     const { accessToken } = response;
  //     createPage(accessToken, {
  //         domHTML,
  //         title
  //     });
  // }
  const msalInstance = await getMsalInstance();
  try {
    const loginInfo = await msalInstance.loginPopup(loginRequest);
    const { accessToken } = loginInfo;
    await createPage(accessToken, {
      domHTML,
      title,
    });
    return true;
  } catch (err) {
    return false;
  }
}
