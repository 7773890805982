import { ReactNode } from 'react';
import { Accept, useDropzone } from 'react-dropzone';

export function UploadFile(props: { children?: ReactNode; onDrop?: (files: File[]) => void; accept?: Accept }) {
  const { accept = {} } = props;
  const { getInputProps, getRootProps } = useDropzone({
    onDrop: props.onDrop,
    multiple: true,
    maxFiles: 6,
    accept: {
      'image/bmp': ['.bmp'],
      'image/gif': ['.gif'],
      'image/jpeg': ['.jpeg', '.jpg'],
      'image/png': ['.png'],
      'image/webp': ['.webp'],
      ...accept,
    },
  });

  return (
    <>
      {/* 切换照片El */}
      <input className=" hidden" {...getInputProps()} />
      <div {...getRootProps({ className: 'inline-block' })}>{props.children}</div>
    </>
  );
}
