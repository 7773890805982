//@ts-ignore
import { Gapless5 } from '@regosen/gapless-5';
import { segmentReq } from '~/api/note2s/audio/segment';
import { formatSecondsToProgress } from '~/pages/record/utils';
import { parseDoubleBracketSyntax } from '~/utils/utils';
import { atom, useAtom } from 'jotai';
import {
  Dispatch,
  forwardRef,
  SetStateAction,
  useEffect,
  useImperativeHandle,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ProgressBar, { Player, ProgressProps } from '../progressBar/progressBar';
import css from './css.module.css';

type Props = ProgressProps & {
  onState?: (state: string) => void;
  setPlayer: Dispatch<SetStateAction<Player>>;
  noteId: string;
};

export default forwardRef(function (props: Props, ref) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'note.info.player',
  });
  const { player, dataset, onState, onClick, setPlayer, noteId } = props;
  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    const audio = audioRef.current!;
    //@ts-ignore
    audio.playbackRate = my?.playRate || 1;
    audio.addEventListener('loadedmetadata', () => {
      setPlayer((player) => ({
        ...player,
        duration: audio.duration * 1000,
      }));
    });
    audio.ontimeupdate = function (e) {
      setPlayer((player) => {
        return {
          ...player,
          currentTime: audio.currentTime * 1000,
        };
      });
    };
    audio.addEventListener('ended', function () {
      setPlayer((player) => {
        player.playState = 'pause';
        return { ...player };
      });
    });
  }, [audioRef.current]);

  useEffect(() => {
    const audio = audioRef.current!;
    if (player.playState == 'pause') audio.pause();
    else audio.play();
  }, [player.playState]);
  const seakAudioPos = (time: number) => {
    const audio = audioRef.current!;
    audio.currentTime = time / 1000;
  };
  useImperativeHandle(ref, () => {
    return {
      seakAudioPos,
    };
  }, [seakAudioPos]);

  const progresContainerRef = useRef<HTMLDivElement | null>(null);

  return (
    <div className="flex h-[100px] w-[100%] flex-shrink items-center">
      <audio ref={audioRef} src={`/v2/note/audio/stream?noteId=${noteId}`}></audio>
      <div
        onClick={() => {
          onState?.(player.playState == 'pause' ? 'start' : 'pause');
        }}
        className="flex h-[60px] w-[60px] scale-100 cursor-pointer items-center justify-center rounded-[50%] bg-[#0BF7E9] duration-300 hover:scale-[1.10]"
        style={{
          background: 'linear-gradient(120deg,#27E8F7 0%, #00A2AF 100%)',
        }}
      >
        <img
          src={player.playState == 'pause' ? '/play2.png' : '/pause.png'}
          className="ml-[3px] aspect-square w-[22px]"
        />
      </div>

      <div className={` mx-[15px] flex h-full flex-col justify-center`}>
        <p className="whitespace-pre text-[12px] font-[700] text-[#989898]">{`${t(
          'duration'
        )} ${formatSecondsToProgress((player.duration / 1000) | 0, true)}`}</p>
        <p className="whitespace-nowrap text-[16px] font-[700] text-black ">
          {parseDoubleBracketSyntax(t('mark'), {
            markLen: dataset.marks.length,
          })}
        </p>
      </div>
      <div style={{ height: 100, flex: 1 }} className=" relative" ref={progresContainerRef}>
        <ProgressBar
          {...props}
          containerRef={progresContainerRef}
          onClick={(radio) => {
            const time = radio * player.duration;
            seakAudioPos(time);
            onClick?.(radio);
          }}
        />
      </div>
    </div>
  );
});
