import { request } from '~/lib/axios';
import { reqParamsToBody } from '../utils';

export type devceBindParams = {
  deviceSn: string;
  name: string;
  accessibility: string;
  firmwareVersion: string;
};

export function devceBindReq(params: devceBindParams) {
  return request.post<null>('/v1/user/device/bind', reqParamsToBody(params));
}
