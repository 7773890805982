export const IconCall = ({ className }: { className?: string }) => {
  return (
    <svg className={className} viewBox="0 0 13 13" fill="none">
      <path
        d="M11.8981 8.90362L8.95376 7.58425L8.94563 7.5805C8.79278 7.51512 8.62603 7.48888 8.46049 7.50416C8.29494 7.51944 8.13581 7.57575 7.99751 7.668C7.98122 7.67875 7.96557 7.69044 7.95063 7.703L6.42938 8.99987C5.46563 8.53175 4.47063 7.54425 4.00251 6.593L5.30126 5.04862C5.31376 5.033 5.32563 5.01737 5.33688 5.0005C5.42715 4.86256 5.48192 4.70445 5.49631 4.54023C5.5107 4.37601 5.48428 4.21078 5.41938 4.05925V4.05175L4.09626 1.10237C4.01047 0.904414 3.86296 0.739508 3.67575 0.632273C3.48854 0.525037 3.27166 0.481224 3.05751 0.507374C2.21061 0.618815 1.43324 1.03473 0.870589 1.67743C0.307935 2.32014 -0.00152558 3.14568 5.6554e-06 3.99987C5.6554e-06 8.96237 4.03751 12.9999 9.00001 12.9999C9.8542 13.0014 10.6797 12.6919 11.3224 12.1293C11.9651 11.5666 12.3811 10.7893 12.4925 9.94237C12.5187 9.72829 12.475 9.51147 12.3679 9.32427C12.2607 9.13706 12.096 8.98951 11.8981 8.90362ZM9.00001 11.9999C6.87898 11.9976 4.8455 11.154 3.34571 9.65417C1.84592 8.15438 1.00232 6.12089 1.00001 3.99987C0.997654 3.38955 1.21754 2.79925 1.61859 2.33919C2.01964 1.87913 2.57444 1.58079 3.17938 1.49987C3.17913 1.50237 3.17913 1.50488 3.17938 1.50737L4.49188 4.44487L3.20001 5.99112C3.18689 6.00621 3.17498 6.0223 3.16438 6.03925C3.07033 6.18357 3.01515 6.34975 3.0042 6.52166C2.99325 6.69358 3.0269 6.86541 3.10188 7.0205C3.66813 8.17862 4.83501 9.33675 6.00563 9.90237C6.16186 9.97665 6.33468 10.0091 6.50722 9.99665C6.67976 9.98416 6.8461 9.92713 6.99001 9.83112C7.00605 9.82031 7.02149 9.80863 7.03626 9.79612L8.55563 8.49987L11.4931 9.8155H11.5C11.4201 10.4213 11.1222 10.9772 10.662 11.3793C10.2019 11.7813 9.61104 12.0019 9.00001 11.9999Z"
        fill="#22C55E"
      />
    </svg>
  );
};
