import { passwordUpdateReq } from '~/api/notes/passwordUpdate';
import { useLogoutMutation } from '~/api/users/logout';
import { generalErrorHandle } from '~/utils/utils';
import { atom, useAtom } from 'jotai';
import { DetailedHTMLProps, InputHTMLAttributes, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { myMessage } from '../MyToast/MyToast';

export function ChangePasswordMobile() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [data, setData] = useState({
    oldPassword: '',
    password: '',
    password2: '',
  });
  const changeHandle = () => {
    passwordUpdateReq(data).then((res: any) => {
      if (!res) myMessage.success(t('misc.change-success'));
    });
  };

  function Input(
    key: keyof typeof data,
    props: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> = {}
  ) {
    return (
      <input
        {...props}
        className="mt-[10px] w-[289px] flex-1 rounded-full border border-[#D9D9D9] pl-[13px] text-[14px] leading-[45px] outline-0 placeholder:text-[14px]"
        value={data[key]}
        onChange={(e) => setData({ ...data, [key]: e.target.value })}
      />
    );
  }

  return (
    <div className=" fixed bottom-0 left-0 right-0 top-0 z-10 flex items-center justify-center bg-[rgba(0,0,0,0.3)]">
      <div className=" relative z-30 flex justify-center rounded-[12px] bg-white px-[18px]">
        <div
          className=" absolute right-5 top-5 z-20 mt-[3px] flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-[50%] px-[18px] hover:bg-[#E8EAEA]"
          onClick={() => navigate(-2)}
        >
          <img src="/connectDevice/wrong.png" className="aspect-square h-[16px] w-[16px] max-w-[30px]" />
        </div>
        <div className=" flex-shrink-1 flex flex-col">
          <p className=" mt-[50px] inline-block text-[16px] font-[600]">{t('misc.oldPassword')}</p>
          {Input('oldPassword', {
            type: 'password',
          })}
          <p className=" mt-[40px] inline-block text-[16px] font-[600]">{t('misc.pasword')}</p>
          {Input('password', {
            placeholder: t('user.resetPassword.password.placeholder'),
            type: 'password',
          })}
          {Input('password2', {
            placeholder: t('user.resetPassword.confirm.placeholder'),
            type: 'password',
          })}
          <button
            className=" mx-auto mb-[39px] mt-[40px] box-border block h-[45px] w-[166px] rounded-full border border-[#E8EAEA] bg-[#E8EAEA]"
            onClick={() => changeHandle()}
          >
            {t('misc.change')}
          </button>
        </div>
      </div>
    </div>
  );
}

function ChangePassword() {
  const { t } = useTranslation();
  const initData = {
    oldPassword: '',
    password: '',
    password2: '',
  };
  const navigate = useNavigate();
  const { mutate: logout } = useLogoutMutation();
  const [data, setData] = useState(initData);

  const changeHandle = () => {
    passwordUpdateReq(data).then((res: any) => {
      //如果报错有请求中间件会报错
      if (!res) {
        myMessage.success(t('misc.change-success'), 2000);
        logout({});
      }
    });
  };
  function Input(
    key: keyof typeof data,
    props: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> = {}
  ) {
    return (
      <input
        {...props}
        className="mt-[16px] h-[58px] w-[646px] rounded-[26px] border border-[#ACACAC] pl-[15px] text-[18px] outline-0 placeholder:text-[18px]"
        value={data[key]}
        onChange={(e) => setData({ ...data, [key]: e.target.value })}
      />
    );
  }

  return (
    <div className=" fixed bottom-0 left-0 right-0 top-0 z-[1000] flex items-center justify-center bg-[rgba(0,0,0,0.3)]">
      <div className=" relative z-30 flex h-[660px] w-[970px] justify-center rounded-[28px] bg-white">
        <div
          className=" absolute right-5 top-5 z-20 mt-[3px] flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-[50%] hover:bg-[#E8EAEA]"
          onClick={() => navigate(-1)}
        >
          <img src="/connectDevice/wrong.png" className="h-[16px] w-[16px]" />
        </div>
        <div className=" flex flex-col">
          <p className=" mt-[136px] inline-block text-[24px] font-[600]">{t('misc.oldPassword')}</p>
          {Input('oldPassword', {
            type: 'password',
          })}
          <p className=" mt-[40px] inline-block text-[24px] font-[600]">{t('misc.pasword')}</p>
          {Input('password', {
            placeholder: t('user.resetPassword.password.placeholder'),
            type: 'password',
          })}
          {Input('password2', {
            placeholder: t('user.resetPassword.confirm.placeholder'),
            type: 'password',
          })}
          <button
            className=" mx-auto mt-[40px] box-border block h-[48px] w-[188px] rounded-full border border-[#E8EAEA] bg-[#E8EAEA]"
            onClick={() => changeHandle()}
          >
            {t('misc.change')}
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
