import { useMutation } from '@tanstack/react-query';
import i18n from '~/i18n';
import { request } from '~/lib/axios';
import { generalErrorHandle } from '~/utils/utils';
import type { AxiosProgressEvent } from 'axios';
import type { SharedMutationHookOptions } from '../helper';
import { defineMutation } from '../helper';

export const upload = defineMutation(
  async ({ formData, onProgress }: { formData: FormData; onProgress?: (e: AxiosProgressEvent) => void }) => {
    formData.append('tzOffset', new Date().getTimezoneOffset() + '');
    const data = await request.post<string>('/v1/note/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress(progressEvent) {
        onProgress?.(progressEvent);
      },
      //@ts-ignore
      notShowErr: true,
    });
    //@ts-ignore
    if (data.error) generalErrorHandle(data, i18n.t);

    return data;
  }
);

export const useUploadAudioMutation = (options?: SharedMutationHookOptions<string>) => {
  // 1.上传后能拿到ticket
  const { mutateAsync, data: ticket = '', isLoading: isUploading } = useMutation(upload, options);

  return {
    ticket,
    mutateAsync,
    isUploading,
  };
};
