import { clsxm } from '@hidock/utils';
import { useFocus } from '~/hooks/useFocus';
import { useFocusWithin } from 'ahooks';
import { useMemo, useRef, useState } from 'react';
import { ClassValue } from 'tailwind-variants';

type Option = {
  id: string;
  parentId?: string;
  label: string;
  data: any;
  children?: Option[];
  level?: number;
};

type MyTreeSelectProps = {
  containerClass?: ClassValue;
  selectKey?: string;
  options: Option[];
  onChange?: (option: Option) => void;
  defalutLabel?: string;
};

const utils = {
  treeToArray(options: Option[]) {
    const arr: Option[] = [];
    function dfs(opt: Option, idx: number) {
      opt.level = idx;
      arr.push(opt);
      opt.children?.map((i) => dfs(i, idx + 1));
    }
    options.map((i) => dfs(i, 0));

    return arr;
  },
  /**扁平化树形图立体化 */
  arrayToTree(options: Option[]) {
    const map = new Map<string, Option>();

    options.map((option) => {
      map.set(option.id, option);
    });

    let root: Option[] = [];
    for (const option of options) {
      const parentNode = map.get(option.parentId || '');
      if (!parentNode) {
        root.push(option);
        continue;
      }
      if (!parentNode.children) parentNode.children = [];
      parentNode.children.push(option);
    }

    return root;
  },
};

export function MyTreeSelect(props: MyTreeSelectProps) {
  const [show, setShow] = useState(false);
  const targetHandle = useFocus((state) => {
    if (state == 'focus') setShow(true);
    else setShow(false);
  });
  let { arr, targetOpt } = useMemo(() => {
    let roots = utils.arrayToTree(props.options);
    let arr = utils.treeToArray(roots);
    const targetOpt = arr.find((i) => i.id == props.selectKey);
    return {
      arr,
      targetOpt,
    };
  }, [props.options, props.selectKey]);

  const containerRef = useRef<HTMLDivElement | null>(null);

  console.log('arr', arr);
  return (
    <div
      className={clsxm(
        '  relative mt-[6px] flex h-[44px] w-full cursor-pointer items-center rounded-[8px] border border-[rgb(172,172,172)] pl-[12px]',
        props.containerClass
      )}
      ref={containerRef}
    >
      <div
        className=" flex h-full w-full items-center"
        onClick={() => {
          targetHandle();
        }}
      >
        <span className="w-[80%] overflow-hidden text-ellipsis whitespace-nowrap text-[rgba(104,104,104,1)]">
          {targetOpt?.label || props.defalutLabel || 'please select'}
        </span>
        <img className=" absolute right-[19px] top-[19px] w-[10px]" src="/share/expand.png" />
      </div>
      <div
        style={{
          boxShadow: '0 0 16px rgba(0,0,0,0.16)',
          display: show ? '' : 'none',
        }}
        className={clsxm(
          ' absolute left-0 top-full box-border max-h-[300px] w-full rounded-[8px] bg-white p-[6px] ',
          'scrollbar  scrollbar-thumb-gray-500 scrollbar-track-gray-200 overflow-x-hidden overflow-y-scroll'
        )}
      >
        {arr.map((item) => {
          return (
            <div
              className="flex h-[30px] items-center p-[0_6px] hover:bg-[rgba(240,240,240,1)]"
              style={{
                paddingLeft: (item.level || 0) * 20,
              }}
              onClick={() => {
                props.onChange?.(item);
                setShow(false);
              }}
            >
              <img src="/share/file.png" className="w-[16px] pr-[6px]" />
              <span className="overflow-hidden text-ellipsis whitespace-nowrap text-[12px] text-[rgba(104,104,104,1)]">
                {item.label}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
