export const IconX = ({ className }: { className?: string }) => {
  return (
    <svg className={className} fill="none" viewBox="0 0 12 12">
      <path
        d="M9.49976 3.20476L8.79476 2.49976L5.99976 5.29476L3.20476 2.49976L2.49976 3.20476L5.29476 5.99976L2.49976 8.79476L3.20476 9.49976L5.99976 6.70476L8.79476 9.49976L9.49976 8.79476L6.70476 5.99976L9.49976 3.20476Z"
        fill="currentColor"
      />
    </svg>
  );
};
