import { clsxm } from '@hidock/utils';
import { settingSaveReq } from '~/api/device/settingSave';
import { UserType } from '~/api/users/types';
import { Loading } from '~/components/Loading';
import i18n from '~/i18n';
import { showUpgradeTipAtom } from '~/layouts/RootLayout';
import { useUserInfo } from '~/store/user';
import { loadingTimeControl, parseDoubleBracketSyntax } from '~/utils/utils';
import { useAtom, useSetAtom } from 'jotai';
import type { CSSProperties, ReactNode } from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { ClassValue } from 'tailwind-variants';
import { showConfirmAtom } from '../deleteConfrim';
import { globalAddEl } from '../globalLayout';
import { myMessage } from '../MyToast/MyToast';
import {
  deviceStatusAtom,
  fileInfosAtom,
  latestDeviceInfoAtom,
  useDeviceConfigInfo,
  useDeviceInfo,
} from './deviceState';
import type { BtnState } from './deviceType';
import { uploadFileQueue } from './ShowFile/deviceManage';
import { deviceTool, jensen } from './utils';

const Switch = (props: {
  state: boolean;
  onClick?: (state: boolean) => void;
  className?: ClassValue;
  style?: CSSProperties;
  title?: string;
}) => {
  const { state, onClick, style = {}, title = '' } = props;

  return (
    <div
      title={title}
      className={clsxm('relative h-[26px] w-[50px] rounded-[19px]', props.className)}
      style={{
        backgroundColor: state ? '#00A2AF' : '#D9D9D9',
        ...style,
      }}
      onClick={() => onClick?.(!state)}
    >
      <div
        className=" absolute top-[50%] aspect-square h-[80%] -translate-y-1/2 transform rounded-[50%] bg-[#F5F5F5] transition-[0.2s]"
        style={
          !state
            ? {
                left: 3,
              }
            : {
                left: 'calc(100% - 3px)',
                transform: 'translate(-100%,-50%)',
              }
        }
      />
    </div>
  );
};

const ButtonEl = (props: { content: ReactNode; style: CSSProperties; clickHandle?: () => void }) => {
  const { content, style = {}, clickHandle } = props;
  return (
    <button
      id="device-config-button"
      className="flex h-[40px] min-w-[164px] items-center justify-center rounded-full p-[8px_12px] text-[16px] text-white"
      onClick={clickHandle}
      style={style}
    >
      {content}
    </button>
  );
};

type Props = {
  setBtnState: (btnState: BtnState) => void;
};

function ConfigPage(props: Props) {
  const [deviceInfo] = useDeviceInfo();
  const [latestDeviceInfo] = useAtom(latestDeviceInfoAtom);
  const [deviceStatus, setDeviceState] = useAtom(deviceStatusAtom);
  const [deviceConfig, setDeviceConfig] = useDeviceConfigInfo();
  const [deviceSyncLoading, setDeviceSyncLoading] = useState(false);
  const setFileInfos = useSetAtom(fileInfosAtom);
  const { t } = useTranslation();
  const isTipUpgrade = deviceTool.isUpgradable(deviceInfo.versionNumber, latestDeviceInfo, deviceStatus);
  const [deviceTime, setDeviceTime] = useState('');
  const navigate = useNavigate();
  const { data: userInfo } = useUserInfo();
  const setShowUpgradeTip = useSetAtom(showUpgradeTipAtom);
  const isPro = userInfo?.type == UserType.pro;
  const setShowConfirm = useSetAtom(showConfirmAtom);
  useEffect(() => {
    //@ts-expect-error
    if (!navigator.usb) return;
    jensen.getTime().then((res) => {
      setDeviceTime(res.time);
    });
  }, []);

  const autoUploadSwitchHandle = (state: boolean) => {
    if (!isPro) {
      setShowUpgradeTip(true);
      return;
    }
    settingSaveReq({
      deviceSn: deviceInfo.sn,
      setting: 'file.auto-upload',
      value: state ? 'on' : 'off',
    }).then((res) => {
      if (res == null) {
        setDeviceConfig((deviceConfig) => {
          return {
            ...deviceConfig,
            autoUpload: state,
          };
        });
      } else {
        myMessage.success(t('wu.configurations.open.auto-record.failed'), 2000);
      }
    });
  };

  const config = [
    {
      title: t('wu.configurations.device-sn'),
      describe: deviceInfo.sn,
    },
    {
      title: t('wu.configurations.auto-record'),
      describe: t('wu.configurations.auto-record.hint'),
      control: (
        <Switch
          state={deviceConfig.autoRecord}
          style={{
            // cursor:'not-allowed'
            cursor: deviceInfo.versionNumber >= 327714 ? 'pointer' : 'auto',
            // cursor:'not-allowed'
          }}
          onClick={() => {
            if (deviceInfo.versionNumber < 327714) return;
            const targetState = !deviceConfig.autoRecord;
            jensen.setAutoRecord(targetState).then((state) => {
              if (state.result == 'success')
                setDeviceConfig((state) => {
                  return {
                    ...state,
                    autoRecord: targetState,
                  };
                });
            });
          }}
        />
      ),
    },
    {
      title: t('wu.configurations.recording-notification'),
      describe: t('wu.configurations.recording-notification.hint'),
      control: (
        <Switch
          onClick={() => {
            if (deviceInfo.versionNumber < 327714) return;
            const targetState = !deviceConfig.recordNotice;
            jensen.setAutoPlay(targetState).then((state) => {
              if (state.result == 'success')
                setDeviceConfig((state) => {
                  return {
                    ...state,
                    recordNotice: targetState,
                  };
                });
            });
          }}
          state={deviceConfig.recordNotice}
          style={{
            cursor: deviceInfo.versionNumber >= 327714 ? 'pointer' : 'auto',
            // cursor:'not-allowed'
          }}
        />
      ),
    },
    {
      title: t('wu.configurations.auto-upload'),
      describe: t('wu.configurations.auto-upload.hint'),
      control: (
        <Switch
          state={deviceConfig.autoUpload && userInfo?.type == UserType.pro}
          onClick={autoUploadSwitchHandle}
          className=" cursor-pointer"
        />
      ),
    },
    {
      title: t('wu.configurations.bluetooth-tone'),
      describe: t('wu.configurations.bluetooth-tone.hint'),
      control: (
        <Switch
          title={
            jensen.getModel() === 'hidock-h1'
              ? deviceInfo.versionNumber < 327940
                ? t('wu.configurations.firmware.version.tips').replace('{version}', 'v5.1.4')
                : ''
              : deviceInfo.versionNumber < 393476
                ? t('wu.configurations.firmware.version.tips').replace('{version}', 'v6.1.4')
                : ''
          }
          state={deviceConfig.blueToothTone}
          onClick={() => {
            const isCan =
              (jensen.getModel() === 'hidock-h1' && deviceInfo.versionNumber >= 327940) ||
              (jensen.getModel() === 'hidock-h1e' && deviceInfo.versionNumber >= 393476);
            if (!isCan) return;
            const targetState = !deviceConfig.blueToothTone;
            jensen.setBluetoothPromptPlay(targetState).then((state) => {
              if (state.result === 'success')
                setDeviceConfig((state) => {
                  return {
                    ...state,
                    blueToothTone: targetState,
                  };
                });
            });
          }}
          className=" cursor-pointer"
          style={
            // h1e 6.1.4 393476
            // h1 5.1.4 327940
            (jensen.getModel() === 'hidock-h1' && deviceInfo.versionNumber >= 327940) ||
            (jensen.getModel() === 'hidock-h1e' && deviceInfo.versionNumber >= 393476)
              ? {
                  cursor: 'pointer',
                }
              : {
                  cursor: 'not-allowed',
                  // pointerEvents: 'none',
                }
          }
        />
      ),
    },
    {
      title: t('wu.configurations.firmware-version'),
      describe: `v ${deviceInfo.version}`,
      control: (
        <ButtonEl
          content={parseDoubleBracketSyntax(t('wu.configurations.firmware-version.btn'), {
            version: latestDeviceInfo?.versionCode,
          })}
          style={{
            backgroundColor: '#00A2AF',
            display: isTipUpgrade ? '' : 'none',
          }}
          clickHandle={() => {
            navigate('/notes/device/update');
          }}
        />
      ),
    },
    {
      title: t('wu.configurations.update-device-time'),
      describe: deviceTime
        ? parseDoubleBracketSyntax(t('wu.configurations.sync-device-time-describe'), { time: deviceTime })
        : '',
      control: (
        <ButtonEl
          content={
            deviceSyncLoading ? (
              <span className=" flex h-full w-full items-center">
                <img src="/connectDevice/time.png" className="mr-[1.5em] w-[1.5em]" />{' '}
                {t('wu.configurations.update-device-time.btn.loading')}
              </span>
            ) : (
              t('wu.configurations.update-device-time.btn')
            )
          }
          style={{ backgroundColor: '#00A2AF' }}
          clickHandle={async () => {
            if (deviceSyncLoading) return;
            console.log('deviceSyncLoading', deviceSyncLoading);
            setDeviceSyncLoading(true);
            const result = await loadingTimeControl(jensen.setTime(new Date(), 5), 500, 5000);
            const getTimeRes = await loadingTimeControl(jensen.getTime(5), 500);
            if (getTimeRes != 'timeout' && getTimeRes?.time) setDeviceTime(getTimeRes.time);
            // const result = (await Promise.all([jensen.setTime(new Date(), 5),sleep(500)])[0];
            console.log('result', result);
            if (result == 'timeout' || result == null || result.result == 'failed')
              myMessage.error(t('wu.configurations.sync-device-time-failed'), 2000);
            setDeviceSyncLoading(false);
          }}
        />
      ),
    },
    {
      title: t('wu.configurations.factory-reset'),
      describe: '',
      control: (
        <ButtonEl
          content={t('wu.configurations.factory-reset.btn')}
          style={
            deviceInfo.versionNumber >= 327705
              ? { backgroundColor: 'red' }
              : { backgroundColor: '#686868', cursor: 'not-allowed' }
          }
          clickHandle={() => {
            // v5.1.8
            if (jensen.getModel() === 'hidock-h1' && deviceInfo.versionNumber < 327944) {
              const text = i18n.t('wu.configurations.firmware.version.tips').replace('{version}', 'v5.1.8');
              myMessage.info(text, 2000);
              return;
            }
            // v6.1.4
            if (jensen.getModel() === 'hidock-h1e' && deviceInfo.versionNumber < 393476) {
              const text = i18n.t('wu.configurations.firmware.version.tips').replace('{version}', 'v6.1.4');
              myMessage.info(text, 2000);
              return;
            }
            const { t } = i18n;
            setShowConfirm({
              isShow: true,
              message: t('translation:wu.reset.secondConfirm.message'),
              confirm() {
                if (uploadFileQueue.isExecuting()) {
                  myMessage.error(t('device.set-up.reset.message.has-file-up'), 2000);
                  return;
                }
                uploadFileQueue.pause();
                const loadingEl = (
                  <div className="fixed inset-0 left-0 top-0 z-[9999] flex items-center justify-center backdrop-blur">
                    <Loading />
                  </div>
                );
                const order = globalAddEl(loadingEl);
                jensen.restoreFactorySettings(30).then((res) => {
                  order.remove();
                  if (res.result == 'success') {
                    myMessage.success(i18n.t('wu.reset.success'), 2000);
                    setFileInfos([]);
                    uploadFileQueue.init();
                  } else if (res.result == 'failed') {
                    myMessage.error(i18n.t('wu.reset.failed'), 2000);
                  } else {
                    myMessage.error(i18n.t('wu.reset.timeout'), 2000);
                  }
                });
                setShowConfirm((state) => {
                  return {
                    ...state,
                    isShow: false,
                  };
                });
              },
              cancel() {
                setShowConfirm((state) => {
                  return {
                    ...state,
                    isShow: false,
                  };
                });
              },
              cancelText: t('translation:wu.reset.secondConfirm.No'),
              confirmText: t('translation:wu.reset.secondConfirm.Yes'),
            });
          }}
        />
      ),
    },
  ];

  const ItemContainer = (title: string, describe: string, control: React.ReactNode, index: number) => {
    return (
      <div
        className="box-border flex items-center rounded-[8px] p-[24px_30px]"
        key={index}
        style={{
          backgroundColor: index % 2 ? 'white' : '#F0F0F0',
        }}
      >
        <div className="w-[250px] text-[20px] font-[600] leading-[20px] text-black">{title}</div>
        <div className="flex flex-1 text-[16px] leading-[16px] text-[rgba(104,104,104,1)]" id="device-config-describe">
          {describe}
        </div>
        <div>{control}</div>
      </div>
    );
  };

  return (
    <div className="p-[50px_50px]">
      <div
        className={clsxm(
          'box-border h-[500px]  w-full',
          'scrollbar  scrollbar-thumb-gray-500 scrollbar-track-gray-200 overflow-x-hidden overflow-y-scroll'
        )}
      >
        {config.map((item, index) => {
          const { title, describe, control } = item;
          return ItemContainer(title, describe, control, index);
        })}
      </div>
    </div>
  );
}

export default ConfigPage;
