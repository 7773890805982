import { DiviceSettingKind } from '~/components/deviceConnection/deviceType';
import { request } from '~/lib/axios';
import { reqParamsToBody } from '../utils';

type Params = {
  deviceSn: string;
  version: string;
};

export type settingInfo = [
  {
    setting: DiviceSettingKind;
    value: string;
  },
];

export function settingReq(params: Params) {
  return request.post<settingInfo>('/v2/device/settings', reqParamsToBody(params));
}
