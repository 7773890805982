import { IconRecord } from '@hidock/ui';
import { enableInstantRecord } from '~/pages/index';
import { getIsLogin } from '~/store/user';
import { useCallback, type FC } from 'react';
import { useNavigate } from 'react-router-dom';
import type { VariantProps } from 'tailwind-variants';
import { tv } from 'tailwind-variants';
import { Button } from '../Button';
import { setLoginModalVisible } from '../modal';
import { NoteUserGuideTargetId } from '../userGuide/config';

const recordButton = tv({
  slots: {
    container: [
      'inline-block',
      'rounded-full bg-gradient-to-r from-[#00D8DE] to-[#00A2AF] cursor-pointer',
      'shadow-[0px_12px_16px_0px_rgba(18,90,103,0.20),1px_1px_0.5px_0px_rgba(255,255,255,0.32)_inset,-1px_-1px_0.5px_0px_rgba(0,0,0,0.07)_inset]',
    ],
    icon: '',
  },
  variants: {
    size: {
      sm: {
        container: 'p-[10px]',
        icon: 'w-[29px]',
      },
      md: {
        container: 'p-3',
        icon: 'w-[38px]',
      },
      lg: {
        container: 'p-4',
        icon: 'w-[72px]',
      },
    },
  },
  defaultVariants: {
    size: 'lg',
  },
});

export const RecordButton: FC<VariantProps<typeof recordButton>> = ({ size }) => {
  const navigate = useNavigate();

  return (
    <RecordButtonBase
      size={size}
      onClick={useCallback(() => {
        if (!getIsLogin()) {
          enableInstantRecord();
          setLoginModalVisible(true);
          return;
        }
        navigate('/audio/record');
      }, [navigate])}
    />
  );
};

export const RecordButtonBase: FC<
  VariantProps<typeof recordButton> & {
    className?: string;
    onClick: () => void;
  }
> = ({ className, size, onClick }) => {
  const { container, icon } = recordButton({ size });

  return (
    <Button
      layout
      id={NoteUserGuideTargetId.record}
      layoutId="record-button"
      className={container({ className })}
      onClick={onClick}
    >
      <IconRecord className={icon()} />
    </Button>
  );
};
