import { ControlKind } from '~/components/userInfo/generalConfigPage';
import { request } from '~/lib/axios';
import { reqParamsToBody } from './utils';

function list(params: { group?: string } = {}) {
  return request.post<{
    definitions: {
      id: string;
      groupCode: string;
      code: string;
      title: string;
      hint: string;
      type: ControlKind.select | ControlKind.switch;
      options: string;
      defaultValue: string;
    }[];
    settings: {
      id: string;
      userId: string;
      groupCode: string;
      code: string;
      currentValue: string;
    }[];
  }>('/v1/user/setting/list', reqParamsToBody(params));
}

function save(params: { group: string; code: string; value: string }) {
  return request.post('/v1/user/setting/save', reqParamsToBody(params));
}

export const userSettingReq = {
  list,
  save,
};
