import { clsxm } from '@hidock/utils';
import { Tooltip } from 'antd';
import { ReactNode, useEffect, useRef, useState } from 'react';

export default function OverflowHiddenText(props: {
  overflow: 'hidden';
  className?: string;
  text?: string | ReactNode;
}) {
  const { text } = props;
  const spanRef = useRef<HTMLSpanElement | null>(null);
  const span2Ref = useRef<HTMLSpanElement | null>(null);
  const [hasOverflow, setHasOverflow] = useState(false);
  useEffect(() => {
    const span = spanRef.current!;
    const span2 = span2Ref.current!;
    if (span.offsetWidth < span2.offsetWidth) {
      setHasOverflow(true);
    } else {
      setHasOverflow(false);
    }
  }, [text]);

  return (
    <>
      <Tooltip
        title={hasOverflow ? text : ''}
        className={clsxm('mx-[5px] text-center  overflow-hidden text-ellipsis whitespace-nowrap', props.className)}
      >
        <span ref={spanRef}>{text}</span>
      </Tooltip>
      <span
        style={{
          position: 'fixed',
          left: -99999,
          top: -99999,
          width: 'auto',
          visibility: 'hidden',
          pointerEvents: 'none',
        }}
        className={props.className}
        ref={span2Ref}
      >
        {text}
      </span>
    </>
  );
}
