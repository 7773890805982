import { clsxm } from '@hidock/utils';
import { useFocus } from '~/hooks/useFocus';
import { throttle } from '~/utils/utils';
import { Tooltip } from 'antd';
import {
  CSSProperties,
  DetailedHTMLProps,
  FC,
  HTMLAttributes,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { ClassValue } from 'tailwind-variants';

export function Button(props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return (
    <div
      {...props}
      className={clsxm([
        props.className,
        'flex h-[34px] min-w-[144px] cursor-pointer items-center justify-center rounded-full text-[16px]',
      ])}
    >
      {props.children}
    </div>
  );
}

export function CloseBtn(props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return (
    <div
      {...props}
      className={clsxm(
        'absolute right-[30px] top-[30px] z-20 flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-[50%] hover:bg-[#E8EAEA]',
        props.className
      )}
    >
      <img src="/connectDevice/wrong.png" className="h-[16px] w-[16px]" />
    </div>
  );
}

export function Select(props: {
  data: { label: string; value: string }[];
  defaultSelect: string;
  onChange?: (data: { label: string; value: string }) => void;
  showNull?: ReactNode;
  btnClassName?: ClassValue;
}) {
  const { data, onChange } = props;
  const [select, setSelect] = useState(props.defaultSelect);
  const [show, setShow] = useState(false);
  const focusTarget = useFocus((state) => {
    if (state == 'blur') setShow(false);
  });

  return (
    <span className=" relative z-[3]">
      <button
        className={clsxm(
          ' flex h-[34px] min-w-[110px] items-center justify-center rounded-full px-[14px] text-[20px]',
          props.btnClassName
        )}
        onClick={() => {
          if (!show) {
            setShow(true);
            focusTarget();
          } else {
            setShow(false);
          }
        }}
      >
        {data.find((i) => i.value == select)?.label || select || props.showNull || 'select'}
        <img className={`ml-[10px] h-[20px] w-[20px] ${show ? 'rotate-90' : ''}`} src="/User/down-arrow.png" />
      </button>
      <div
        className=" absolute left-1/2 top-full z-[10000] box-border min-w-[120px] -translate-x-1/2 cursor-pointer overflow-hidden rounded-[10px] bg-white shadow-[0px_4px_10px_0px_rgba(0,0,0,0.3)]"
        style={{ display: show ? '' : 'none' }}
        onClick={() => focusTarget()}
      >
        {data.map((item, idx) => {
          return (
            <div
              className="flex h-[28px] w-full items-center whitespace-nowrap px-[14px] pr-[14px] text-[14px]"
              style={{ background: idx % 2 == 1 ? '#F5F5F5' : '' }}
              key={item.value}
              onClick={() => {
                setSelect(item.value);
                setShow(false);
                onChange?.(item);
              }}
            >
              {item.label}
            </div>
          );
        })}
      </div>
    </span>
  );
}

export function Select2(props: {
  select?: string;
  showNull?: ReactNode;
  onChange?: (info: { label: string; value: string }) => void;
  style?: CSSProperties;
  onClose?: () => void;
  btnClassName?: ClassValue;
  tooltip?: string;
  btnTextClass?: string;
  data: {
    name: string;
    children: {
      label: string;
      value: string;
    }[];
  }[];
  btnComp?: FC<{
    text: ReactNode;
    showEvent: () => void;
    className?: ClassValue;
    style?: CSSProperties;
    isoptional?: boolean;
  }>;
  isoptional?: boolean;
}) {
  const [selectLetter, setSelectLetter] = useState('A');
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [show, setShow] = useState(false);
  const [keyword, setKeyword] = useState('');
  const { t } = useTranslation();

  const searchHeader = (
    <div className=" flex w-full justify-center pb-[25px] pt-[29px]">
      <div className=" box-border flex h-[40px] w-[400px] rounded-[10px] bg-[#F0F0F0] px-[20px] py-[10px]">
        <img src="/device/search.png" className=" h-[20px] w-[20px]" />
        <input
          className=" ml-[18px] flex-1 bg-transparent text-[18px] font-bold leading-[21.06px] outline-none placeholder:text-[#BDBDBD]"
          placeholder={t('user.info.search')}
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
      </div>
    </div>
  );

  const data = useMemo(() => {
    const temp: typeof props.data = [];
    props.data.forEach((item) => {
      const children = item.children.filter((i) => new RegExp(keyword, 'i').test(i.label));
      if (children.length) {
        temp.push({
          name: item.name,
          children,
        });
      }
    });
    return temp;
  }, [props.data, keyword]);

  const letterSelect = () => {
    return (
      <div className=" fixed right-[31px] top-[104px] z-[5] flex w-[14px] flex-col items-center text-[18px] leading-[21px]">
        {letters.map((letter) => {
          return (
            <span
              className=" cursor-pointer"
              style={{
                color: selectLetter == letter ? '#00A2AF' : '',
                fontWeight: selectLetter == letter ? 'bold' : '',
              }}
              key={letter}
              onClick={() => {
                const target = containerRef.current!;
                let arr = Array.from(target.querySelectorAll<HTMLDivElement>('#letter'));
                const letterEl = arr.find((i) => i?.dataset?.letter == letter)!;
                target.scrollTo(0, letterEl.offsetTop);
              }}
            >
              {letter}
            </span>
          );
        })}
      </div>
    );
  };

  const letters = data.map((i) => i.name);
  const selectContent = data.map((item) => {
    return (
      <div
        className=" box-border w-full pb-[12px] pl-[40px] pr-[30px]"
        key={item.name}
        style={{ background: props.select?.[0] == item.name ? '#F7F9FA' : '' }}
      >
        <p className=" pb-[21px] pt-[24px] text-left text-[24px] font-[600]" data-letter={item.name} id="letter">
          {item.name}
        </p>
        <div className="flex flex-wrap">
          {item.children.map((item) => {
            if (item.value == props.select) {
              return (
                <div
                  className="mb-[12px] mr-[10px] cursor-pointer rounded-[6px] bg-[#EFF6F6] p-[12px_15px] text-[18px] text-[#00A2AF]"
                  key={item.value}
                >
                  {item.label}
                </div>
              );
            }

            return (
              <div
                className=" mb-[12px] mr-[10px] cursor-pointer rounded-[6px] bg-[#E8EAEA] p-[12px_15px] text-[18px]"
                key={item.value}
                onClick={() => {
                  props.onChange?.(item);
                  setShow(false);
                }}
              >
                {item.label}
              </div>
            );
          })}
        </div>
      </div>
    );
  });

  const scrollEvent = useCallback(
    throttle(
      () => {
        const container = containerRef.current!;
        let arr = Array.from(container.querySelectorAll<HTMLDivElement>('#letter'));
        for (const item of arr) {
          if (item.offsetTop + 1 > container.scrollTop) {
            //@ts-ignore
            setSelectLetter(item.dataset.letter);
            break;
          }
        }
      },
      100,
      {
        immediatelyExec: false,
      }
    ),
    []
  );

  /** 获取选中后的目标信息 */
  const selectItem = useMemo(() => {
    for (const arr of data) for (const item of arr.children) if (props.select == item.value) return item;
    return null;
  }, [props.data, props.select]);

  const showSelect = (
    <div className=" fixed left-1/2 top-1/2 z-[4] box-border h-[690px] w-[871px] -translate-x-1/2 -translate-y-1/2 overflow-hidden rounded-[28px] bg-white leading-[18.72px] shadow-[0px_4px_50Px_0_rgba(0,0,0,0.4)]">
      {searchHeader}
      <div
        className=" absolute right-[30px] top-[30px] z-20 flex h-[36px] w-[36px] cursor-pointer items-center justify-center rounded-[50%] hover:bg-[#E8EAEA]"
        onClick={() => {
          props.onClose?.();
          setShow(false);
        }}
      >
        <img src="/connectDevice/wrong.png" className="h-[16px] w-[16px]" />
      </div>
      {letterSelect()}
      <div className=" relative h-[594px] overflow-y-scroll" ref={containerRef} onScroll={scrollEvent}>
        {selectContent}
      </div>
    </div>
  );

  const langName = selectItem?.label || props.select || props.showNull;

  return (
    <>
      <Tooltip title={props.tooltip}>
        {props.btnComp ? (
          <props.btnComp
            text={langName}
            showEvent={() => {
              setShow(!show);
            }}
            className={props.btnClassName}
            style={props.style}
            isoptional={props.isoptional}
          />
        ) : (
          <button
            className={clsxm(
              ' flex h-[34px] min-w-[110px] flex-shrink-0 items-center justify-end rounded-full px-[14px] text-[20px]',
              props.btnClassName
            )}
            onClick={() => {
              setShow(!show);
            }}
          >
            <p className={props.btnTextClass} style={props.style}>
              {langName}
            </p>
            <img className={`ml-[10px] h-[20px] w-[20px]  ${show ? 'rotate-90' : ''}`} src="/User/down-arrow.png" />
          </button>
        )}
      </Tooltip>
      {show && showSelect}
    </>
  );
}

export function BarItem(props: { color: string; title: string; describe: string; endfix?: ReactNode }) {
  const { color, title, describe, endfix } = props;
  // style={{ backgroundColor: idx % 2 == 0 ? '#F0F0F0' : '' }}
  return (
    <div className=" flex items-center w-[100%] rounded-[12px] p-[21px_30px]" style={{ background: color }}>
      <div>
        <p className=" text-[20px] font-[600]">{title}</p>
        <p className=" mt-[10px] text-[14px] leading-[18.72px] text-[#686868]">{describe}</p>
      </div>
      <div className="flex-1" />
      {endfix}
    </div>
  );
}

export const Switch = (props: {
  state: boolean;
  onClick?: (state: boolean) => void;
  className?: ClassValue;
  style?: CSSProperties;
}) => {
  const { state, onClick, style = {} } = props;

  return (
    <div
      className={clsxm('relative h-[26px] w-[50px] rounded-[19px]', props.className)}
      style={{
        backgroundColor: state ? '#00A2AF' : '#D9D9D9',
        ...style,
      }}
      onClick={() => onClick?.(!state)}
    >
      <div
        className=" absolute top-[50%] aspect-square h-[80%] -translate-y-1/2 transform rounded-[50%] bg-[#F5F5F5] transition-[0.2s]"
        style={
          !state
            ? {
                left: 3,
              }
            : {
                left: 'calc(100% - 3px)',
                transform: 'translate(-100%,-50%)',
              }
        }
      />
    </div>
  );
};
