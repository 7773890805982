import { clsxm } from '@hidock/utils';
import { Divider } from '~/components/Divider';
import { Hero } from '~/components/hero';
import { useIsLargeScreen } from '~/hooks/useIsLargeScreen';
import { Footer } from '~/pages/index';
import { Outlet } from 'react-router-dom';

export const LayoutPolicy = () => {
  const isLg = useIsLargeScreen();

  return (
    <div className="pb-10 pt-[88px]">
      <Hero size={isLg ? 'lg' : 'sm'} />
      <Divider className="mb-6 mt-11" />

      <main
        className={clsxm(
          'mx-auto px-6',
          'prose',
          'prose-h2:text-base prose-h2:font-bold prose-h2:opacity-80 prose-h2:mt-4',
          'prose-p:text-base prose-p:font-semibold prose-p:opacity-70'
        )}
      >
        <Outlet />
      </main>
      <Footer />
    </div>
  );
};
