import { userSettingReq } from '~/api/userSetting';
import i18n from '~/i18n';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BarItem, Select, Select2 } from './general';
import { ControlKind, GeneralPage, GeneralPageProps } from './generalConfigPage';

export function Language() {
  const [options, setOptions] = useState<GeneralPageProps['config']>([]);
  const { t } = useTranslation();

  useEffect(() => {
    userSettingReq.list({ group: 'language' }).then((res) => {
      const { definitions, settings } = res;
      const defaultValueHash: Record<string, string> = {};
      settings.forEach((item) => {
        defaultValueHash[`${item.groupCode} ${item.code}`] = item.currentValue;
      });

      const temp: typeof options = [];

      temp.push({
        name: 'local-language',
        title: 'Deiplay Language',
        hint: '',
        control: {
          type: ControlKind.select,
          data: {
            defaultValue: i18n.language,
            options: [
              {
                label: 'English',
                value: 'en',
              },
              {
                label: '日本语',
                value: 'ja',
              },
              {
                label: '繁体中文',
                value: 'zhCN',
              },
            ],
          },
        },
      });

      for (const definition of definitions) {
        let { title, type, code, groupCode, hint, defaultValue } = definition;
        const name = `${groupCode} ${code}`;
        if (defaultValue == 'auto') defaultValue = '';
        if (type == ControlKind.switch) {
          temp.push({
            title: title,
            hint: hint || '',
            name,
            control: {
              type: type,
              data: {
                defaultValue: defaultValueHash[name] || defaultValue,
              },
            },
          });
        }

        if (type == ControlKind.select) {
          const options: any = JSON.parse(definition.options);
          if (defaultValue == 'auto') defaultValue = '';
          options.options.forEach((item: any) => {
            item.children = item.children.map((i: any) => {
              return {
                label: i.name,
                value: i.value,
              };
            });
          });
          temp.push({
            title,
            hint: hint || '',
            name,
            control: {
              type: ControlKind.select2,
              data: {
                defaultValue: defaultValueHash[name] || defaultValue,
                options: options.options,
              },
            },
          });
        }
      }

      setOptions(temp);
    });
  }, []);

  if (!options.length) return null;

  return (
    <GeneralPage
      title={t('user.info.language')}
      config={options.map((item) => {
        const word = item.name.split(' ').join('.');
        const k = `user.info.${word}.`;
        return {
          ...item,
          //@ts-ignore
          title: t(k + 'title') == k + 'title' ? item.hint : t(k + 'title'),
          //@ts-ignore
          hint: t(k + 'hint') == k + 'hint' ? item.hint : t(k + 'hint'),
        };
      })}
      onChange={(key, value) => {
        if (key == 'local-language') {
          localStorage.setItem('userLang', value);
          i18n.changeLanguage(value);
        }

        const [group, code] = key.split(' ');
        userSettingReq.save({
          group,
          code,
          value,
        });
      }}
    />
  );
}
