import { reqParamsToBody } from '~/api/utils';
import { request } from '~/lib/axios';

type Params = {
  noteId: string;
  sentenceId: string;
  name: string;
};

// type ChangeName = {
//     noteId:string,
//     sentenceId:string,
//     name:string
// }

export const changeName = (params: Params) => {
  return request.post<
    {
      id: string;
      userId: string;
      noteId: string;
      name: string;
      code: string;
      color: string;
    }[]
  >('/v2/note/speaker/change', reqParamsToBody(params));
};
