import { request } from '~/lib/axios';
import { reqParamsToBody } from '../utils';

type Params = {
  deviceSn: string;
  version: string;
};

export function deviceSettingReq(params: Params) {
  return request.post('/v2/device/settings', reqParamsToBody(params));
}
