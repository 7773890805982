import { reqParamsToBody } from '~/api/utils';
import { request } from '~/lib/axios';

type Params = {
  noteId: string;
  sentenceId: string;
  index: number;
  token: string;
};

export function updateSentenceReq(params: Params) {
  return request.post('/v2/note/sentence/update', reqParamsToBody(params));
}
