/* eslint-disable @typescript-eslint/ban-ts-comment */
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { sendEmailActivateCode } from '~/api/users/send-activate-code';
import { signUpWithEmail } from '~/api/users/signin';
import { useSignInWithEmailMutation } from '~/api/users/signin-google';
import { parseDoubleBracketSyntax2 } from '~/utils/utils';
import { atom } from 'jotai';
import { CheckCircle2, KeyRound, Mail } from 'lucide-react';
import type { FC } from 'react';
import { useEffect, useRef, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as z from 'zod';
import { Modal } from '.';
import { AvatarPlaceHolder } from '../AvatarPlacehoder';
import { myMessage } from '../MyToast/MyToast';
import { Spinner } from '../note';

// eslint-disable-next-line prefer-regex-literals
const passwordRegExp = new RegExp(
  '^(?:(?=.*[0-9])(?=.*[a-zA-Z])|(?=.*[0-9])(?=.*[!@#$%^&*()_+{}\\[\\]:;<>,.?~\\\\/-])|(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+{}\\[\\]:;<>,.?~\\\\/-])).{6,}$'
);

export const isEmailRegisterModalVisible = atom(false);
const registerSchema = z
  .object({
    email: z.string().email().min(1, { message: 'Email is required' }),
    password: z
      .string()
      .regex(passwordRegExp, 'Must be a combination of numbers, letters, and symbols with a minimum of 6 characters.'),
    passwordConfirm: z.string(),
    verificationCode: z.string().min(1, { message: 'Verification code is required' }),
  })
  .refine((data) => data.password === data.passwordConfirm, {
    message: "Passwords don't match",
    path: ['passwordConfirm'], // path of error
  });

const signInSchema = z.object({
  email: z.string().email().min(1, { message: 'Email is required' }),
  password: z.string().min(6, { message: 'Password must contain at least 6 character(s)' }).max(30),
});

export const EmailRegisterModal: FC<{
  isSignIn?: boolean;
  onClose: () => void;
  onSignedUp: () => void;
}> = ({ isSignIn = false, onClose, onSignedUp }) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<z.infer<typeof isSignIn extends true ? typeof signInSchema : typeof registerSchema>>({
    resolver: zodResolver(isSignIn ? signInSchema : registerSchema),
    defaultValues: {},
  });
  const [token, setToken] = useState<string | null>('');
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  const { mutateAsync: sendActivateCode } = useMutation(sendEmailActivateCode);
  const { mutateAsync: signUp, isLoading: isSigningUp } = useMutation(signUpWithEmail);
  const { mutateAsync: signIn, isLoading: isSigningIn } = useSignInWithEmailMutation();

  const isLoading = isSigningUp || isSigningIn;

  const onRegisterClick = async (values: z.infer<typeof registerSchema>) => {
    if (isLoading) return;
    const res = await signUp({
      email: values.email,
      password: values.password,
      password2: values.passwordConfirm,
      activateCode: values.verificationCode,
    });

    // @ts-expect-error x
    if (!res?.error) {
      onSignedUp();
      setToken(null);
    }
  };

  const onSignInClick = async (values: z.infer<typeof registerSchema>) => {
    if (isLoading) return;
    if (!token) {
      myMessage.error(t('user.recaptcha.tip'), 2000);
      return;
    }

    const res = await signIn({ ...values, captcha: token });
    // eslint-disable-next-line @typescript-eslint/prefer-ts-expect-error
    // @ts-ignore
    if (res?.error) {
      recaptchaRef.current?.reset();
    } else {
      onClose();
    }
  };

  function policyStatementEl() {
    const contentKey = isSignIn ? 'user.policy.signing-in' : 'user.policy';
    const policyConent = parseDoubleBracketSyntax2(t(contentKey));
    const ElHash = {
      '.privacy-policy'(text: string) {
        return (
          <Link className="mx-1 underline" to={'/privacy-policy'}>
            {text}
          </Link>
        );
      },
      '.term-of-use'(text: string) {
        return (
          <Link className="mx-1 underline" to={'/term-of-service'}>
            {text}
          </Link>
        );
      },
    };
    console.log('policyConent', policyConent);
    return policyConent.map((text) => {
      //@ts-ignore
      if (text.type == 'BracketContent') return ElHash[text.content]?.(t(contentKey + text.content)) || text.content;
      if (text.type == 'text') return text.content;
    });
  }
  return (
    <Modal.Root open modal={false}>
      <div className="relative lg:max-w-[380px]">
        <AvatarPlaceHolder className="absolute left-1/2 -translate-x-1/2 -translate-y-1/2" />
      </div>
      <div className="mt-2 text-2xl font-extrabold text-black">
        {isSignIn ? t('user.auth.email') : t('user.register.email')}
      </div>
      <form
        className="text-solid/70 mt-4 w-full min-w-[280px] space-y-4 text-[13px] font-semibold"
        onSubmit={handleSubmit(isSignIn ? onSignInClick : onRegisterClick)}
      >
        <div>
          <div className="flex items-center">
            <Mail className="mr-1 w-4" /> {t('user.email')}
          </div>
          <input
            className="border-gray text-solid mt-1 w-full rounded-xl border p-2 text-sm"
            placeholder="Enter email"
            {...register('email')}
          />
          {errors.email && <p className="mt-1 text-xs text-red-400">{errors.email.message}</p>}
        </div>
        <div>
          <div className="flex items-center">
            <KeyRound className="mr-1 w-4" /> {t('misc.pasword')}
          </div>
          <input
            type="password"
            className="border-gray text-solid mt-1 w-full rounded-xl border p-2 text-sm"
            placeholder="Enter password"
            {...register('password')}
          />
          {errors.password ? (
            <p className="mt-1 max-w-[300px] text-xs text-red-400">{errors.password.message}</p>
          ) : (
            <div className="mt-1">{t('user.register.tip')}</div>
          )}
        </div>

        {!isSignIn && (
          <>
            <div>
              <div className="flex items-center">
                <KeyRound className="mr-1 w-4" /> {t('user.register.confirm.title')}
              </div>
              <input
                type="password"
                className="border-gray text-solid mt-1 w-full rounded-xl border p-2 text-sm"
                placeholder={t('user.register.confirm.placeholder')}
                {...register('passwordConfirm')}
              />
              {errors.passwordConfirm && <p className="mt-1 text-xs text-red-400">{errors.passwordConfirm.message}</p>}
            </div>
            <div className="relative">
              <div className="flex items-center">
                <CheckCircle2 className="mr-1 w-4" /> {t('user.register.verificationCode')}
              </div>
              <div className="border-gray flex rounded-xl border p-2">
                <input
                  className=" text-solid w-full text-sm"
                  placeholder={t('user.register.verificationCode')}
                  {...register('verificationCode')}
                />
                <ReCAPTCHA
                  badge="inline"
                  size="invisible"
                  ref={recaptchaRef}
                  sitekey={import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY}
                  onChange={setToken}
                  onExpired={() => setToken(null)}
                >
                  <VerificationCodeSender
                    className="cursor-pointer"
                    onGetVerificationCodeClick={async () => {
                      if (isLoading) return false;
                      if (!recaptchaRef.current) return false;
                      const email = getValues('email');
                      if (!email) {
                        myMessage.error(t('misc.emailEmpty'), 2000);
                        return false;
                      }

                      if (!token) {
                        const token = await recaptchaRef.current.executeAsync();

                        setToken(token);
                      }
                      const res = await sendActivateCode({ email });

                      // @ts-expect-error x
                      if (!res?.error) return true;
                      return false;
                    }}
                  />
                </ReCAPTCHA>
              </div>

              {errors.verificationCode && (
                <p className="mt-1 text-xs text-red-400">{errors.verificationCode.message}</p>
              )}
            </div>
          </>
        )}

        {isSignIn && (
          <ReCAPTCHA
            ref={recaptchaRef}
            className="z-[99999]"
            sitekey={import.meta.env.VITE_GOOGLE_RECAPTCHA_KEY}
            onChange={setToken}
            onExpired={() => setToken(null)}
          />
        )}
        <button className="bg-primary flex w-full cursor-pointer items-center justify-center rounded-xl px-2 py-3 font-semibold text-white">
          {isSignIn ? t('user.sign-in') : t('misc.register')}

          {isLoading && <Spinner className="ml-2 w-3 fill-white" />}
        </button>
        <button
          className="bg-zinc w-full rounded-xl px-2 py-3 font-semibold"
          onClick={() => {
            if (isLoading) return;
            onClose();
          }}
        >
          {t('note.processing.cancel')}
        </button>
      </form>
      <p className="text-solid mt-2 text-center opacity-30">
        <p className="scale-[0.916] text-xs">{policyStatementEl()}</p>
      </p>
    </Modal.Root>
  );
};

const DEFAULT_COUNTDOWN = 60;
const VerificationCodeSender: FC<{
  className?: string;
  onGetVerificationCodeClick: () => Promise<boolean>;
}> = ({ className, onGetVerificationCodeClick }) => {
  const { t } = useTranslation();
  const [isSent, setIsSent] = useState(false);
  const [countdown, setCountdown] = useState(DEFAULT_COUNTDOWN);
  const timerRef = useRef<any>();

  useEffect(() => {
    if (!isSent) return;

    setCountdown(DEFAULT_COUNTDOWN);
    timerRef.current = setInterval(() => {
      setCountdown((countdown) => {
        if (countdown <= 0) {
          clearInterval(timerRef.current);
          setIsSent(false);
          return DEFAULT_COUNTDOWN;
        }

        return countdown - 1;
      });
    }, 1_000);

    return () => {
      timerRef.current && clearInterval(timerRef.current);
    };
  }, [isSent]);

  return (
    <div className={className}>
      {isSent ? (
        <div
          className="text-solid whitespace-nowrap rounded-md px-2 py-1 text-[10px]"
          style={{ background: 'rgba(40, 48, 64, 0.10)' }}
        >
          Email sent{` (${countdown})`}
        </div>
      ) : (
        <div
          className="text-primary whitespace-nowrap rounded-md px-2 py-1 text-[10px]"
          style={{ background: 'rgba(0, 255, 204, 0.20)' }}
          onClick={async () => {
            setIsSent(await onGetVerificationCodeClick());
          }}
        >
          {t('user.register.verificationCode.btn')}
        </div>
      )}
    </div>
  );
};
