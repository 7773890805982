import type { Note, RecentlyNote } from '~/api/notes/types';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import type { TagVariant } from './Tag';
import { Tag } from './Tag';

export function NoteTable(props: { notes: Note[] | RecentlyNote[]; showTitle: boolean; fixedHeader?: boolean }) {
  const { notes, fixedHeader } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();

  const containerRef = useRef<HTMLDivElement | null>(null);

  const domsRef = useRef<Map<string, HTMLDivElement>>(new Map());

  function setColWidth(key: string, dom: HTMLDivElement) {
    //69
    const gridColWiths = [
      {
        ratio: 41.8,
      },
      {
        ratio: 9.4,
        fixLen: 69,
      },
      {
        ratio: 9.4,
      },
      {
        ratio: 28.8,
      },
    ];

    if (!window.document.contains(dom)) return;
    const totalWidth = dom.clientWidth;
    const chidrens = Array.from(dom.childNodes);
    let len = gridColWiths.length;
    if (window.screen.width < 1536) len--;

    let totalRatio = 0;
    let allFixLen = 0;
    for (let i = 0; i < chidrens.length && i < len; i++) {
      const gridColWidth = gridColWiths[i];
      totalRatio += gridColWidth.ratio;
      allFixLen += gridColWidth.fixLen ? gridColWidth.fixLen : 0;
    }

    for (let i = 0; i < chidrens.length && i < len; i++) {
      const target = chidrens[i];
      //@ts-ignore
      target.style.width =
        ((totalWidth - allFixLen) * gridColWiths[i].ratio) / totalRatio + (gridColWiths[i].fixLen || 0) + 'px';
    }
  }

  useEffect(() => {
    function exec() {
      for (const [key, dom] of domsRef.current.entries()) {
        setColWidth(key, dom);
      }
    }
    exec();
    window.addEventListener('resize', exec);

    return () => {
      window.removeEventListener('resize', exec);
    };
  }, []);

  const tHeader = (
    <div
      style={{ position: fixedHeader ? 'sticky' : 'unset', top: 0, zIndex: 2, backgroundColor: '#eff0f1' }}
      className="flex text-[20px] font-bold leading-[48px] text-[#3D3D3D]"
      ref={(dom) => {
        if (dom) {
          setColWidth('header', dom);
          domsRef.current.set('header', dom);
        }
      }}
    >
      <p className=" flex-shrink-0 pl-[20px] pr-[15px]">{t('note.table.header.title')}</p>
      <p className=" flex-shrink-0">{t('note.table.header.date')}</p>
      <p className=" flex-shrink-0">{t('note.table.header.duration')}</p>
      <p className=" hidden flex-shrink-0 2xl:block">{t('note.table.header.tags')}</p>
    </div>
  );

  const tBody = notes.map((note) => {
    const mins = (note.duration / 1000 / 60) | 0;
    const seconds = (note.duration / 1000) % 60 | 0;

    let time = '';

    if (mins) time = `${mins} ${t('misc.mins')}`;
    else time = `${seconds} ${t('misc.secs')}`;

    return (
      <div
        key={note.id}
        className=" overflow-hidden border-t  border-[#D8D8D8] pb-[20px] pt-[23px] hover:bg-[#DFE2E4] "
      >
        <div
          className="flex w-full cursor-pointer items-center"
          ref={(dom) => {
            if (dom) {
              domsRef.current.set(note.id, dom);
              setColWidth(note.id, dom);
            }
          }}
          onClick={() => navigate('/notes/' + note.id, {})}
        >
          <div className="box-border flex flex-shrink-0 items-center whitespace-pre pl-[20px] pr-[15px] ">
            {
              <img
                src="/userRole/membershipPro.png"
                className=" mr-[9.5px] aspect-[2/1] h-[20px] w-[40px]"
                style={{
                  visibility: note.level === 'pro' ? 'visible' : 'hidden',
                }}
              />
            }
            <div className="mr-[13px] w-[70px]">
              <Tag
                variant={(note?.type || '') as TagVariant}
                className={' h-[24px]'}
                labelClassName={'w-[40px] flex justify-center items-center'}
              />
            </div>
            <p className="overflow-hidden text-ellipsis text-[24px] font-bold leading-[48px]  text-[#3D3D3D]">
              {note.title}
            </p>
          </div>

          <div className="flex-shrink-0 text-[18px] leading-[48px] text-[#686868]">
            {new Date(note.createTime).toLocaleString('zh-CN', { hour12: false }).slice(0, -3)}
          </div>

          <div className="flex-shrink-0 text-[18px] leading-[48px] text-[#686868]">{time}</div>

          <div className="hidden flex-shrink-0 flex-nowrap overflow-hidden overflow-ellipsis whitespace-pre text-[18px] leading-[48px] text-[#686868] 2xl:block">
            {note.tags
              .split(',')
              .map((i) => (i ? `#${i}` : ''))
              .map((i) => (
                <span className=" mr-[10px]" key={i}>
                  {i}
                </span>
              ))}
          </div>
        </div>
      </div>
    );
  });

  return (
    <div className=" mt-[20px]" ref={containerRef}>
      {props.showTitle && tHeader}
      {tBody}
    </div>
  );
}
