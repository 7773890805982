import { clsxm } from '@hidock/utils';
import { useGoogleLogin } from '@react-oauth/google';
import { useSignInWithGoogleMutation } from '~/api/users/signin-google';
import { setAppLoading } from '~/App';
import { showRetrivePasswordAtom } from '~/pages/retrievePassword/RetrivePassword';
import { parseDoubleBracketSyntax, parseDoubleBracketSyntax2 } from '~/utils/utils';
import { atom, getDefaultStore, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Modal } from './Base';
import { EmailRegisterModal } from './EmailRegisterModal';

const isLoginModalVisibleAtom = atom(false);
const loginModalModeAtom = atom('');
export const getIsLoginModalVisible = () => getDefaultStore().get(isLoginModalVisibleAtom);
export const setLoginModalVisible = (visible: boolean, mode = '') => {
  const store = getDefaultStore();
  store.set(isLoginModalVisibleAtom, visible);
  store.set(loginModalModeAtom, mode || '');
};

let signinCallbacks: Function[] = [];
export const addSignInCallbacks = (fn: Function) => signinCallbacks.push(fn);
export const flushSignInCallbacks = () => signinCallbacks.forEach((cb) => cb());

export const LoginModal = () => {
  const [emailModalMode, setEmailModalMode] = useState('');
  const { t } = useTranslation();
  const [visible, setVisible] = useAtom(isLoginModalVisibleAtom);
  const mode = useAtomValue(loginModalModeAtom);
  const { mutate } = useSignInWithGoogleMutation();
  const login = useGoogleLogin({
    onSuccess(res) {
      if (!res.access_token) return;
      setLoginModalVisible(false);
      setAppLoading(true);

      mutate({ credential: res.access_token });
    },
  });
  const setShowRetrivePassword = useSetAtom(showRetrivePasswordAtom);

  function policyStatementEl() {
    const contentKey = 'user.policy';
    const policyConent = parseDoubleBracketSyntax2(t(contentKey));
    const ElHash = {
      '.privacy-policy'(text: string) {
        return (
          <Link className="mx-1 underline" to={'/privacy-policy'} key={'privacy-policy'}>
            {text}
          </Link>
        );
      },
      '.term-of-use'(text: string) {
        return (
          <Link className="mx-1 underline" to={'/term-of-service'} key={'term-of-use'}>
            {text}
          </Link>
        );
      },
    };

    return policyConent.map((text) => {
      //@ts-ignore
      if (text.type == 'BracketContent') return ElHash[text.content]?.(t(contentKey + text.content)) || text.content;
      if (text.type == 'text') return text.content;
    });
  }
  //防止笔记打开时，列表还能被用户滚动
  useEffect(() => {
    if (visible) document.body.style.overflow = 'hidden';
    else document.body.style.overflow = '';
  }, [visible]);

  return (
    <>
      {!emailModalMode && (
        <Modal.Root
          open={visible}
          onOpenChange={(open) => {
            if (!open) {
              signinCallbacks = [];
            }

            setVisible(open);
          }}
        >
          <div className="relative flex flex-col items-center space-y-4">
            <section
              className={clsxm(
                'border-gray relative flex aspect-square w-[64px] items-center justify-center rounded-full ',
                'border-2 bg-white',
                'absolute -top-[24px] left-1/2 -translate-x-1/2 -translate-y-1/2'
              )}
            >
              <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="28" cy="27.9998" r="21" stroke="#283040" strokeWidth="4.66667" />
                <circle cx="28" cy="21" r="7" fill="#283040" />
                <path
                  d="M28 30.3335C20.268 30.3335 14 36.6015 14 44.3335C19.0556 48.6113 31.7333 54.6002 42 44.3335C42 36.6015 35.732 30.3335 28 30.3335Z"
                  fill="#283040"
                />
              </svg>
            </section>

            {mode === '' && <Modal.Title className="text-2xl font-extrabold">{t('user.login.title')}</Modal.Title>}
            {mode === 'save' && (
              <div className="text-solid text-center">
                <h2 className="text-xl font-bold">{t('user.login.title.email')}</h2>
              </div>
            )}
            <section className="border-gray flex flex-col justify-center">
              <div className="relative">
                <div
                  className=" flex w-[200px] cursor-pointer items-center justify-center rounded-xl border border-neutral-200 px-2 py-3"
                  onClick={() => {
                    login();
                  }}
                >
                  <svg
                    className="mr-1"
                    width="21"
                    height="21"
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.6496 10.3713C17.6496 9.75677 17.5998 9.30832 17.4918 8.84326H10.4744V11.617H14.5935C14.5105 12.3063 14.062 13.3444 13.0654 14.042L13.0514 14.1348L15.2702 15.8537L15.424 15.869C16.8357 14.5652 17.6496 12.6468 17.6496 10.3713Z"
                      fill="#4285F4"
                    />
                    <path
                      d="M10.4741 17.6793C12.4921 17.6793 14.1863 17.0149 15.4237 15.8689L13.0652 14.0418C12.434 14.482 11.5869 14.7893 10.4741 14.7893C8.49762 14.7893 6.82008 13.4855 6.22208 11.6833L6.13443 11.6908L3.82729 13.4763L3.79712 13.5602C5.0262 16.0017 7.55084 17.6793 10.4741 17.6793Z"
                      fill="#34A853"
                    />
                    <path
                      d="M6.22219 11.6832C6.06441 11.2182 5.97309 10.7198 5.97309 10.205C5.97309 9.69004 6.06441 9.19178 6.21389 8.72672L6.20971 8.62768L3.87366 6.81348L3.79723 6.84983C3.29067 7.86302 3 9.00079 3 10.205C3 11.4091 3.29067 12.5469 3.79723 13.56L6.22219 11.6832Z"
                      fill="#FBBC05"
                    />
                    <path
                      d="M10.4741 5.62073C11.8776 5.62073 12.8243 6.22697 13.3641 6.73359L15.4735 4.67401C14.178 3.46983 12.4921 2.73071 10.4741 2.73071C7.55084 2.73071 5.0262 4.40825 3.79712 6.84982L6.21378 8.72671C6.82008 6.92459 8.49762 5.62073 10.4741 5.62073Z"
                      fill="#EB4335"
                    />
                  </svg>
                  {t('user.auth.google')}
                </div>
              </div>
              <div
                className="text-primary border-gray mt-2 flex cursor-pointer justify-center rounded-xl border px-4 py-3 text-sm"
                onClick={() => {
                  setEmailModalMode('sign-in');
                }}
              >
                <svg
                  className="mr-1"
                  width="21"
                  height="20"
                  viewBox="0 0 21 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.24459 5.33333C5.33043 5.33333 4.49974 6.15174 4.49974 7.31494V12.6851C4.49974 13.8483 5.33043 14.6667 6.24459 14.6667H14.8189C15.7359 14.6667 16.5492 13.8509 16.4974 12.7154C16.497 12.7053 16.4968 12.6952 16.4968 12.6851V7.31494C16.4968 6.15174 15.6661 5.33333 14.7519 5.33333H6.24459ZM3.1664 7.31494C3.1664 5.53562 4.47929 4 6.24459 4H14.7519C16.5172 4 17.8301 5.53562 17.8301 7.31494V12.6707C17.9038 14.4708 16.5761 16 14.8189 16H6.24459C4.47929 16 3.1664 14.4644 3.1664 12.6851V7.31494Z"
                    fill="#00A2AF"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.92038 7.00508C6.10191 6.68475 6.50875 6.57224 6.82908 6.75377L9.94827 8.52147C10.3094 8.71565 10.6914 8.71565 11.0525 8.52147L14.1717 6.75377C14.492 6.57224 14.8988 6.68475 15.0804 7.00508C15.2619 7.3254 15.1494 7.73224 14.8291 7.91378L11.7041 9.68477L11.6934 9.69068C10.9326 10.1037 10.0682 10.1037 9.30733 9.69068L9.29668 9.68477L6.17168 7.91378C5.85135 7.73224 5.73884 7.3254 5.92038 7.00508Z"
                    fill="#00A2AF"
                  />
                </svg>
                {t('user.auth.email')}
              </div>

              <div
                className="text-primary mt-8 cursor-pointer text-center text-sm"
                onClick={() => setEmailModalMode('sign-up')}
              >
                {t('user.register.email')}
              </div>

              <div
                className="mt-3 cursor-pointer text-center text-[13px] text-[#686868] underline"
                onClick={() => {
                  setShowRetrivePassword(true);
                  setVisible(false);
                }}
              >
                {t('user.retrieve-password')}
              </div>
            </section>
            <p className="text-solid text-center opacity-30">
              <p className="scale-[0.916] text-xs">{policyStatementEl()}</p>
            </p>
          </div>
        </Modal.Root>
      )}
      {emailModalMode && (
        <EmailRegisterModal
          isSignIn={emailModalMode === 'sign-in'}
          onClose={() => setEmailModalMode('')}
          onSignedUp={() => setEmailModalMode('sign-in')}
        />
      )}
    </>
  );
};
