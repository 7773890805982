import { MyModal } from '~/components/general/myModal';
import { Loading } from '~/components/Loading';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export function InputModify(props: {
  onConfirm?: (name: string) => void;
  onCancel?: () => void;
  title?: string;
  placeholder?: string;
}) {
  const { onCancel, onConfirm } = props;
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const { t } = useTranslation();

  return (
    <MyModal className=" flex h-[284px] w-[464px] flex-col rounded-[16px] bg-white p-[24px]">
      <p className=" mb-[20px] text-[26px] font-[600] leading-[48px]">{props.title}</p>
      <input
        className=" h-[60px] w-full rounded-[6px] bg-[#F5FAFA] text-center text-[20px] font-[600] text-black outline-none placeholder:text-center  placeholder:text-[#BDBDBD]"
        placeholder={props.placeholder}
        maxLength={20}
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <div className=" mt-[60px] flex justify-end">
        <button
          className=" mr-[16px] flex h-[48px] w-[144px] items-center justify-center rounded-[8px] bg-[#E3E3E3]"
          onClick={() => onCancel?.()}
        >
          {t('user.createFolder.cancel')}
        </button>
        <button
          className=" flex h-[48px] w-[144px] items-center justify-center rounded-[8px] bg-[#00A2AF] text-white"
          onClick={async () => {
            if (loading || !name.trim()) return;
            setLoading(true);
            await onConfirm?.(name);
            setLoading(false);
          }}
        >
          {loading ? <Loading className=" h-[30px] w-[30px]" /> : t('user.createFolder.save')}
        </button>
      </div>
    </MyModal>
  );
}
