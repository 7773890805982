/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useMutation } from '@tanstack/react-query';
import { setAppLoading } from '~/App';
import { flushSignInCallbacks, setLoginModalVisible } from '~/components/modal';
import { request } from '~/lib/axios';
import { emitter } from '~/lib/mitt';
import { queryClient } from '~/main';
import { setAccessToken, setGi } from '~/store/user';
import { API } from '..';
import type { SharedMutationHookOptions } from '../helper';
import { defineMutation } from '../helper';
import { reqParamsToBody } from '../utils';

export const signInWithGoogle = defineMutation(async (body: { credential: string }) => {
  const data = await request.post<string>('/v1/oauth2/signin/google', body, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

  setAccessToken(data);
  setLoginModalVisible(false);
  requestAnimationFrame(() => {
    queryClient.invalidateQueries(API.users.info().key);
    queryClient.invalidateQueries(API.notes.list().rootKey);
  });
  flushSignInCallbacks();

  emitter.emit('signed-in');
  emitter.emit('signed-in-with-google');

  return data;
});

export const signInWithEmail = defineMutation(async (params: { email: string; password: string; captcha: string }) => {
  if (import.meta.env.VITE_REQUIRE_CAPTCHA != '1') params.captcha = '';
  const data = await request.post<string>('/v1/user/signin', reqParamsToBody(params));

  // @ts-expect-error x
  if (data.errror) return;

  setAccessToken(data);
  setLoginModalVisible(false);
  requestAnimationFrame(() => {
    queryClient.invalidateQueries(API.users.info().key);
    queryClient.invalidateQueries(API.notes.list().rootKey);
  });
  flushSignInCallbacks();

  emitter.emit('signed-in');
  emitter.emit('signed-in-with-google');

  return data;
});

export const useSignInWithGoogleMutation = (options?: SharedMutationHookOptions) =>
  useMutation(signInWithGoogle, {
    ...options,
    onMutate(variable) {
      emitter.emit('before-sign-in');
      options?.onMutate?.(variable);
      setAccessToken('');
      setGi('');
      setAppLoading(true);
    },
    onError() {
      setAppLoading(false);
    },
  });

export const useSignInWithEmailMutation = (options?: SharedMutationHookOptions) =>
  useMutation(signInWithEmail, {
    ...options,
    onMutate(variable) {
      emitter.emit('before-sign-in');
      options?.onMutate?.(variable);
      setAccessToken('');
      setGi('');
    },
    onSettled() {},
  });
