export enum UserType {
  Trail = 'trial',
  free = 'free',
  Membership = 'membership',
  kickstarter = 'kickstarter',
  pro = 'pro',
}

export type User = {
  id: string;
  type: UserType;
  avatar: string;
  email: string;
  name: string;
  nation: string;
  language: string;
  gender: string;
  gi: string | null;
  timeLimit: number;
  countLimit: number;
  totalNoteCount: number;
  totalNoteDuration: number;
  professionalRole: null | string;
  region: null | string;
  accessToken?: string;
  kickstarter: boolean;
  limitations: Limitation[];
  membershipEndDate: number;
};

type Limitation = {
  tag: 'max.record.time' | 'max.upload.size' | 'max.note.count' | 'total.note.duration';
  frequency: string;
  limitation: number;
  unitName: string;
};
