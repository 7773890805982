import { create } from './create';
import { deleteTag } from './delete';
import { list } from './list';
import { update } from './update';

export const tags = {
  list,
  update,
  delete: deleteTag,
  create,
};
