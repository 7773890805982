export const IconEmail = ({ className }: { className?: string }) => {
  return (
    <svg className={className} viewBox="0 0 12 13" fill="none">
      <g opacity="0.7">
        <path
          d="M10 2.5H2C1.45 2.5 1.005 2.95 1.005 3.5L1 9.5C1 10.05 1.45 10.5 2 10.5H10C10.55 10.5 11 10.05 11 9.5V3.5C11 2.95 10.55 2.5 10 2.5ZM10 9.5H2V4.5L6 7L10 4.5V9.5ZM6 6L2 3.5H10L6 6Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};
