import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import type {
  FetchQueryOptions,
  MutationFunction,
  MutationOptions,
  QueryFunction,
  QueryKey,
  UseInfiniteQueryOptions,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { AxiosCustomError } from '~/lib/axios/type';
import { queryClient } from '~/main';

/**
 * A query consists of a deps key (array) and an executable function.
 */
export type DefinedQuery<TQueryKey extends QueryKey, TQueryFnData, TData = TQueryFnData> = Readonly<{
  key: TQueryKey;
  fn: QueryFunction<TQueryFnData, TQueryKey>;
  rootKey: QueryKey;
  transform?: (data: TQueryFnData) => TData;
}>;
export type DefinedMutation<T, V> = MutationFunction<T, V>;
export type DefinedQueryOptions<TQueryFnData, TData> = {
  // 用于获取 rootKey 的索引，react-query 支持索引靠前的数组能够作为代表。
  // 若是需要全局同步状态的话，可以使用该索引来获取更加关键的 key 值。
  // 参考：https://tanstack.com/query/latest/docs/react/guides/query-invalidation
  keyIndex?: number;
  transform?: (data: TQueryFnData) => TData;
};

/**
 * Defines the key/fn pair for query hooks.
 * @param key The query key.
 * @param fn The query function.
 * @param options The query options. 目前主要用于支持根 key 的索引值
 */
export function defineQuery<
  TQueryKey extends string | QueryKey,
  TQueryFn extends QueryFunction<any>,
  TQueryFnData = Awaited<ReturnType<TQueryFn>>,
  TQueryFnParams = Parameters<TQueryFn>,
  TTransformedData = TQueryFnData,
>(key: TQueryKey, fn: TQueryFn, options?: DefinedQueryOptions<TQueryFnData, TTransformedData>) {
  const queryKey = typeof key === 'string' ? [key] : key;

  return Object.freeze({
    key: queryKey,
    fn,
    rawFn: fn,
    transform: options?.transform,
    rootKey: options && options.keyIndex ? queryKey.slice(0, options.keyIndex) : queryKey,
    fetch: (options?: FetchQueryOptions) => {
      return queryClient.fetchQuery(queryKey as QueryKey, fn, options);
    },
  }) as DefinedQuery<QueryKey, TQueryFnData, TTransformedData> & {
    rawFn: (params?: TQueryFnParams) => TQueryFnData | Promise<TQueryFnData>;
    fetch: (options?: FetchQueryOptions) => Promise<void>;
  };
}

/**
 * Defines the function for mutation hooks.
 * @param fn The mutation function.
 */
export function defineMutation<TData = unknown, TVariable = unknown>(
  fn: MutationFunction<TData, TVariable>
): DefinedMutation<TData, TVariable> {
  return fn;
}

/**
 * A shared hook options object.
 */
export type SharedMutationHookOptions<TData = unknown, TVariables = unknown, TContext = unknown> = MutationOptions<
  TData,
  AxiosCustomError,
  TVariables,
  TContext
>;

export type SharedQueryHookOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = undefined,
  TQueryKey extends QueryKey = QueryKey,
> = Omit<UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>, 'queryKey' | 'queryFn'>;
export type SharedInfiniteQueryOptions<
  TQueryFnData = unknown,
  TError = unknown,
  TData = undefined,
  TQueryKey extends QueryKey = QueryKey,
> = Omit<UseInfiniteQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>, 'queryKey' | 'queryFn'>;
type SafeReturnType<T> = T extends (...args: any[]) => infer R ? R : never;

export const useAPIQuery = <
  TDefinedQuery extends DefinedQuery<QueryKey, any>,
  TError = AxiosCustomError,
  TQueryFnData = Awaited<ReturnType<TDefinedQuery['fn']>>,
  TData = SafeReturnType<TDefinedQuery['transform']>,
  TOptions = SharedQueryHookOptions<TQueryFnData, TError, TData, QueryKey>,
>(
  query: TDefinedQuery,
  options?: TOptions
): UseQueryResult<TData, TError> => {
  return useQuery<TQueryFnData, TError, TData>(
    query.key as QueryKey,
    query.fn,
    // @ts-expect-error ignore
    options
  );
};

export const useAPIInfiniteQuery = <
  TDefinedQuery extends DefinedQuery<QueryKey, any>,
  TError = AxiosCustomError,
  TQueryFnData = Awaited<ReturnType<TDefinedQuery['fn']>>,
  TData = SafeReturnType<TDefinedQuery['transform']>,
  TOptions = SharedInfiniteQueryOptions<TQueryFnData, TError, TData, QueryKey>,
>(
  query: TDefinedQuery,
  options: TOptions
) => {
  return useInfiniteQuery<TQueryFnData, TError, TData>(
    query.key,
    query.fn,
    // @ts-expect-error ignore
    options
  );
};
