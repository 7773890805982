import { clsxm } from '@hidock/utils';
import { useHover } from 'ahooks';
import { useRef } from 'react';

//菜单容器
function ContextMenu(props: { children: React.ReactNode | React.ReactNode[]; className?: string }) {
  const { children } = props;
  return (
    <div className={clsxm(' w-[236px] rounded-[8px] shadow-[0px_0px_30px_0px,rgba(0,0,0,0.3)]', props.className)}>
      {children}
    </div>
  );
}

//分割线UI
function Divider() {
  return <div className=" my-[4px] h-[1px] bg-[#DDDDE1]" />;
}

type MenuItemProps = {
  subMenu?: React.ReactNode;
  title: string;
  imgSrc?: string;
  hoverImgSrc?: string;
  onClick?: () => void;
};

//菜单元素
function MenuItem(props: MenuItemProps) {
  let { subMenu, title, imgSrc, hoverImgSrc, onClick } = props;
  hoverImgSrc = hoverImgSrc || imgSrc;
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isHover = useHover(containerRef);

  return (
    <div className=" relative font-[600]" ref={containerRef}>
      <div
        className=" flex h-[40px] cursor-pointer items-center rounded-[2px] text-[#686868] hover:text-white"
        style={
          isHover
            ? {
                background: '#00A2AF',
                color: 'white',
              }
            : undefined
        }
        onClick={() => onClick?.()}
      >
        {imgSrc && <img className=" ml-[19px] w-[19px]" src={isHover ? hoverImgSrc : imgSrc} />}
        <p className="mx-[20px] flex-1 overflow-hidden text-ellipsis whitespace-pre text-[14px]" title={title}>
          {title}
        </p>
        {subMenu && (
          <img
            className=" mr-[12px] w-[5px]"
            src={isHover ? '/notes/right-arrow.png' : '/notes/right-arrow-grey.svg'}
          />
        )}
      </div>
      {isHover && <div className=" absolute left-full top-0">{subMenu}</div>}
    </div>
  );
}
//次级菜单容器
function SubMenu(props: { children: React.ReactNode | React.ReactNode[] }) {
  const { children } = props;
  return (
    <div className=" w-[160px] rounded-[8px] border-[0.5px] border-[#9C9C9C] bg-white p-[6px] shadow-[0px_0px_20px_rgba(0,0,0,0.3)] backdrop-blur">
      {children}
    </div>
  );
}

export const TabBar = {
  SubMenu,
  MenuItem,
  Divider,
  ContextMenu,
};
