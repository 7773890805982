import { noteTemplate } from '~/api/templateReq.js';
//@ts-ignore
import { share_docs as share_docss } from './index.js';

type share_docs_info = (
  data: any,
  title: any,
  fields?: noteTemplate.detail['fields'],
  time?: number
) => Promise<{
  status: boolean;
  message: any;
}>;
export const share_docs = share_docss as share_docs_info;
